/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { GetState } from "../reducers"

export enum CollegeActionList {
    ADD_COLLEGE = 'ADD_COLLEGE',
    FETCH_COLLEGE = 'FETCH_COLLEGE',
    UPDATE_COLLEGE = 'UPDATE_COLLEGE',
    DELETE_COLLEGE = 'DELETE_COLLEGE',
}

export enum CollegeStatusList {
    ACTIVE = 'Active',
    INACTIVE = 'Inactive',
}

export type UserStatus = CollegeStatusList.ACTIVE | CollegeStatusList.INACTIVE

export interface CollegeType {
    id?: number
    name: string
    description: string
    address: string
    contact: string
    examCenterName: string
    examCenterCode: string
    logoUrl: string
    createdBy?: string
    createTimestamp?: string
}


export interface AddCollegeAction {
    type: CollegeActionList.ADD_COLLEGE
    data: CollegeType
}

export interface FetchCollegeAction {
    type: CollegeActionList.FETCH_COLLEGE
    data: Array<CollegeType>
}

export interface UpdateCollegeAction {
    type: CollegeActionList.UPDATE_COLLEGE
    data: CollegeType
}

export interface DeleteCollegeAction {
    type: CollegeActionList.DELETE_COLLEGE
    data: number
}

export type CollegeActions = AddCollegeAction | FetchCollegeAction | UpdateCollegeAction | DeleteCollegeAction

export const addCollege = (data: CollegeType) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("User Added")
        return api.post<APIResponse<CollegeType>>('college/', data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<AddCollegeAction>({
                    type: CollegeActionList.ADD_COLLEGE,
                    data: response.data.data

                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'College Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })
    }
}

export const updateUser = (data: CollegeType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("User Updated")
        return api.put<APIResponse<CollegeType>>(`college/?id=${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<UpdateCollegeAction>({
                    type: CollegeActionList.UPDATE_COLLEGE,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'College Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchUser = () => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<CollegeType[]>>('college/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchCollegeAction>({
                    type: CollegeActionList.FETCH_COLLEGE,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteUser = (id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)
        // return Promise.resolve("User Deleted")

        return api.delete<APIResponse<CollegeType>>(`college/?id=${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteCollegeAction>({
                    type: CollegeActionList.DELETE_COLLEGE,
                    data: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'College Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}

