/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { GetState } from "../reducers"

export enum PracticalExamActionList {
    ADD_PRACTICALEXAM = 'ADD_PRACTICALEXAM',
    FETCH_PRACTICALEXAM = 'FETCH_PRACTICALEXAM',
    UPDATE_PRACTICALEXAM = 'UPDATE_PRACTICALEXAM',
    DELETE_PRACTICALEXAM = 'DELETE_PRACTICALEXAM',
}

export interface PracticalExamType {
    id?: number
    examId: number
    degreeId: number
    disciplineId: number
    semester: number
    regulation: string
    disciplineCourseId: number
    examDate: string
    noOfStudents: number
    noOfSessions: number
    intExaminerId: number
    extExaminerId: number
}

export interface AddPracticalExamAction {
    type: PracticalExamActionList.ADD_PRACTICALEXAM
    data: PracticalExamType
}

export interface FetchPracticalExamAction {
    type: PracticalExamActionList.FETCH_PRACTICALEXAM
    data: Array<PracticalExamType>
}

export interface UpdatePracticalExamAction {
    type: PracticalExamActionList.UPDATE_PRACTICALEXAM
    data: PracticalExamType
}

export interface DeletePracticalExamAction {
    type: PracticalExamActionList.DELETE_PRACTICALEXAM
    data: number
}

export type PracticalExamActions = AddPracticalExamAction | FetchPracticalExamAction | UpdatePracticalExamAction | DeletePracticalExamAction

export const addPracticalExam = (data: PracticalExamType) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.post<APIResponse<PracticalExamType>>('practicalExamination/', data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<AddPracticalExamAction>({
                    type: PracticalExamActionList.ADD_PRACTICALEXAM,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Practical Exam Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })
    }
}

export const updatePracticalExam = (data: PracticalExamType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.put<APIResponse<PracticalExamType>>(`practicalExamination/?id=${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<UpdatePracticalExamAction>({
                    type: PracticalExamActionList.UPDATE_PRACTICALEXAM,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Practical ExamUpdated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchPracticalExam = () => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<PracticalExamType[]>>('practicalExamination/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchPracticalExamAction>({
                    type: PracticalExamActionList.FETCH_PRACTICALEXAM,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deletePracticalExam = (id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.delete<APIResponse<PracticalExamType>>(`practicalExamination/?id=${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeletePracticalExamAction>({
                    type: PracticalExamActionList.DELETE_PRACTICALEXAM,
                    data: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Practical Exam Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}
