
import { PracticalExamActionList, PracticalExamType } from "../actions/practicalExaminationAction";
import { Action } from "../actions/types";

const initialValue: Array<PracticalExamType> = [];

const PracticalExamReducer = (state: Array<PracticalExamType> = initialValue, action: Action) => {
    switch (action.type) {
        case PracticalExamActionList.ADD_PRACTICALEXAM:
            return [
                ...state,
                action.data,
            ];

        case PracticalExamActionList.UPDATE_PRACTICALEXAM:
            const updated = [...state].slice();
            const index = updated.findIndex(u => u.id === action.data.id);

            if (index > -1) {
                updated[index] = {
                    ...action.data,
                };
            }

            return updated;

        case PracticalExamActionList.FETCH_PRACTICALEXAM:
            return action.data;

        case PracticalExamActionList.DELETE_PRACTICALEXAM:
            return state.filter(s => s.id !== action.data);

        default:
            return state;
    }
}

export default PracticalExamReducer;



