import React, { FC } from "react";

interface Props {
    title:string,
    footer?:string
    children: string | JSX.Element | JSX.Element[]
}
const PageContainer:FC<Props> = (props) =>{
    const {title,children} = props;
    return(
        <div className="content-wrapper">
        {
         // Content Header (Page header) 
        }
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>{title}</h1>
              </div>
              {/* <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><a href="#">Home</a></li>
                  <li className="breadcrumb-item active">Blank Page</li>
                </ol>
              </div> */}
            </div>
          </div>
        </section>
        <section className="content">
          {children}
        </section>
      </div>
    )
}
export default PageContainer;