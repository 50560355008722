import { FC } from "react";
import Card from "../components/custom/Card";
import PageContainer from "../components/custom/PageContainer";
import MultiSelect from "../components/custom/form/MultiSelect";

const TablePage: FC = () => {
    return (
        <PageContainer title="Table Page">

            <Card title="Form Page">
                <form>
                    <MultiSelect />
                </form>
            </Card>


        </PageContainer>
    )
}
export default TablePage;