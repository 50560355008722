/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { GetState } from "../reducers"

export enum DegreeActionList {
    ADD_DEGREE = 'ADD_DEGREE',
    FETCH_DEGREE = 'FETCH_DEGREE',
    UPDATE_DEGREE = 'UPDATE_DEGREE',
    DELETE_DEGREE = 'DELETE_DEGREE',
}

export interface DegreeType {
    id?: number
    name: string
    yearsCount: number
    semesterCount: number
}

export interface AddDegreeAction {
    type: DegreeActionList.ADD_DEGREE
    data: DegreeType
}

export interface FetchDegreeAction {
    type: DegreeActionList.FETCH_DEGREE
    data: Array<DegreeType>
}

export interface UpdateDegreeAction {
    type: DegreeActionList.UPDATE_DEGREE
    data: DegreeType
}

export interface DeleteDegreeAction {
    type: DegreeActionList.DELETE_DEGREE
    data: number
}

export type DegreeActions = AddDegreeAction | FetchDegreeAction | UpdateDegreeAction | DeleteDegreeAction

export const addDegree = (data: DegreeType) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.post<APIResponse<DegreeType>>('degree/', data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<AddDegreeAction>({
                    type: DegreeActionList.ADD_DEGREE,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Degree Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })
    }
}

export const updateDegree = (data: DegreeType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.put<APIResponse<DegreeType>>(`degree/?id=${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<UpdateDegreeAction>({
                    type: DegreeActionList.UPDATE_DEGREE,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Degree Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchDegree = () => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<DegreeType[]>>('degree/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchDegreeAction>({
                    type: DegreeActionList.FETCH_DEGREE,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteDegree = (id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.delete<APIResponse<DegreeType>>(`degree/?id=${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteDegreeAction>({
                    type: DegreeActionList.DELETE_DEGREE,
                    data: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Degree Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}
