import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from '../components/custom/Card';
import CollapseCard from '../components/custom/CollapseCard';
import PageContainer from '../components/custom/PageContainer';
import { RegulationType, addRegulation, deleteRegulation, fetchRegulation, updateRegulation } from '../redux/actions/regulationAction';
import { StoreState } from '../redux/reducers';
import { AppDispatch } from '../redux/store';
const Regulation = () => {

    const dispatch = useDispatch<AppDispatch>()

    const regulationList = useSelector<StoreState, RegulationType[]>(state => state.regulation)


    // const [regulationList, setRegulationList] = useState<Regulation[]>([]);
    const [editFlag, setEditFlag] = useState(false);
    const [title, setTitle] = useState('');
    const [id, setId] = useState<number>(0);
    const [createdBy, setCreatedBy] = useState<string>('');
    const [createTimestamp, setCreateTimestamp] = useState<string>('');
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [titleError, setTitleError] = useState('');

    useEffect(() => {
        dispatch(fetchRegulation())
    }, [])

    const addHandler = () => {
        if (title.length === 0) {
            setTitleError('Title is required');
        }

        if (title.length > 10) {
            setTitleError('Title must be within 10 characters');
        }
        const addData = { title };
        dispatch(addRegulation(addData)).then(text => {
            toast.success(text)
            clearHandler()
        }).catch(text => {
            toast.error(text)
        })
    }



    const editHandler = (reg: RegulationType) => {
        setEditFlag(true);
        setId(reg.id ? reg.id : 0);
        setTitle(reg.title);
        setCreatedBy(createdBy);
        setCreateTimestamp(createTimestamp);
    }

    const updateHandler = () => {
        if (title.length === 0) {
            setTitleError('Title is required');
        }

        if (title.length > 10) {
            setTitleError('Title must be within 10 characters');
        }

        const editData = { id, title };
        dispatch(updateRegulation(editData, id)).then(text => {
            toast.success(text)
            clearHandler()
        }).catch(text => {
            toast.error(text)
        })
    }

    const clearHandler = () => {
        setTitle('');
        setId(0);
        setCreatedBy('');
        setCreateTimestamp('');
        setTitleError('');
        setEditFlag(false);
    }



    const deleteHandler = (id: number) => {
        setId(id);
        openDeleteConfirmation(); // Open the delete confirmation modal
    }

    const confirmDeleteHandler = () => {
        if (id !== undefined) {
            dispatch(deleteRegulation(id)).then(text => {
                toast.success(text)
                closeDeleteConfirmation();
            }).catch(text => {
                toast.error(text);
            })
        }
    }

    const openDeleteConfirmation = () => {
        setShowDeleteConfirmation(true);
    }

    const closeDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    }

    return (
        <PageContainer title="Regulation">
            <Card title={editFlag ? "Edit Regulation" : "Add Regulation"}>
                <div className='container m-0'>
                    <div className="row">
                        <div className="col-6">
                            <label className="mt-3 mb-3">Title <span className="text-danger">*</span> : </label>
                            <input className="form-control" placeholder="Enter title..." type="text" name="title" onChange={(e) => setTitle(e.target.value)} value={title} />
                            {titleError && <div className="text-danger">{titleError}</div>}</div>


                        <div className=" col mt-5 m-4 d-flex justify-content-end">
                            <button className="btn btn-sm btn-secondary" onClick={clearHandler} style={{ marginRight: '10px' }}>
                                Clear
                            </button>
                            {editFlag ? <button className="btn btn-sm btn-success" onClick={updateHandler}>Update</button> :
                                <button className="btn btn-sm btn-success" onClick={addHandler}>Add</button>}
                        </div>
                    </div>
                </div>
            </Card>
            <CollapseCard title="Regulation">
                <div className="container-fluid" style={{ width: "100%" }}>
                    <table className="table table-success table-striped">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Title</th>
                                <th>Options</th>
                            </tr>
                        </thead>
                        <tbody>
                            {regulationList.map((reg, index) => (
                                <tr key={reg.id}>
                                    <td>{index + 1}</td>
                                    <td>{reg.title}</td>
                                    <td>
                                        <button className="btn btn-sm btn-primary" style={{ marginRight: '10px' }} onClick={() => editHandler(reg)}>Edit</button>
                                        <button className="btn btn-sm btn-danger" onClick={() => deleteHandler(reg.id ? reg.id : 0)}>Delete</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </CollapseCard>
            {/* Deletion Confirmation Modal */}
            <Modal show={showDeleteConfirmation} onHide={closeDeleteConfirmation}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this item?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeDeleteConfirmation}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={confirmDeleteHandler}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </PageContainer>
    );
}

export default Regulation;

interface Regulation {
    id: number;
    title: string;
    createdBy: string;
    createTimestamp: string;
}
