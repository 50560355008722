import axios from 'axios';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { FC, useState } from 'react';
import * as Feather from 'react-feather';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LocalFormatDate } from '../components/common/functions';
import Card from '../components/custom/Card';
import CollapseCard from '../components/custom/CollapseCard';
import PageContainer from '../components/custom/PageContainer';
import { apiUrl } from '../config';
import { CourseType } from '../redux/actions/courseActions';
import { DegreeType } from '../redux/actions/degreeAction';
import { DisciplineType } from '../redux/actions/disciplineAction';
import { DropDownType } from '../redux/actions/dropDownAction';
import { ExamType } from '../redux/actions/examAction';
import { ExamFacultyType } from '../redux/actions/examFacultyAction';
import { FacultyType } from '../redux/actions/facultyActions';
import { PracticalExamType } from '../redux/actions/practicalExaminationAction';
import { RegulationType } from '../redux/actions/regulationAction';
import { StoreState } from '../redux/reducers';
import imgPath from './img/collegeLogo.png';

interface Props {
    title: string;
}

const PracticalExaminationReport: FC<Props> = (props: Props) => {

    const [practicalExamList, setPracticalExamList] = useState<PracticalExamType[]>([]);

    const examFacultyList = useSelector<StoreState, ExamFacultyType[]>(state => state.examFaculty);
    const facultyList = useSelector<StoreState, FacultyType[]>(state => state.faculty);
    const examList = useSelector<StoreState, ExamType[]>(state => state.exam);
    const degreeList = useSelector<StoreState, DegreeType[]>(state => state.degree);
    const disciplineList = useSelector<StoreState, DisciplineType[]>(state => state.discipline);
    const dropDownList = useSelector<StoreState, DropDownType[]>(state => state.dropDown)
    const regulationList = useSelector<StoreState, RegulationType[]>(state => state.regulation)
    const courseList = useSelector<StoreState, CourseType[]>(state => state.course)

    const [examId, setExamId] = useState<string>('')
    const [examIdError, setExamIdError] = useState<string>('')

    const [degreeId, setDegreeId] = useState<string>('')
    const [degreeIdError, setDegreeIdError] = useState<string>('')

    const [disciplineId, setDisciplineId] = useState<string>('')
    const [disciplineIdError, setDisciplineIdError] = useState<string>('')

    const [semester, setSemester] = useState<string>('')
    const [semesterError, setSemesterError] = useState<string>('')

    const [regulation, setRegulation] = useState<string>('')
    const [regulationError, setRegulationError] = useState<string>('')

    // const [disciplineCourseId, setDisciplineCourseId] = useState<string>('')
    // const [disciplineCourseIdError, setDisciplineCourseIdError] = useState<string>('')

    // const [examDate, setExamDate] = useState<string>('')
    // const [examDateError, setExamDateError] = useState<string>('')

    // const [noStudent, setNoStudent] = useState<string>('')
    // const [noStudentError, setNoStudentError] = useState<string>('')

    // const [noSession, setNoSession] = useState<string>('')
    // const [noSessionError, setNoSessionError] = useState<string>('')

    // const [intExaminerId, setIntExaminerId] = useState<string>('')
    // const [intExaminerIdError, setIntExaminerIdError] = useState<string>('')

    // const [extExaminerId, setExtExaminerId] = useState<string>('')
    // const [extExaminerIdError, setExtExaminerIdError] = useState<string>('')

    // const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    // const [apiErrorMessage, setApiErrorMessage] = useState('');

    const [searchPerformed, setSearchPerformed] = useState(false);

    //ID values
    const getDiscipline = (discipline: any) => {
        const disciplineName = disciplineList.find((dl) => dl.id === parseInt(discipline))?.name
        return disciplineName
    }

    const getExam = (exam: any) => {
        const examName = examList.find((el) => el.id === parseInt(exam))?.title
        return examName
    }

    const getDegree = (degree: any) => {
        const degreeName = degreeList.find((dg) => dg.id === parseInt(degree))?.name
        return degreeName
    }
    const getCourse = (course: any) => {
        const courseName = courseList.find((cs) => cs.id === parseInt(course))?.name
        return courseName
    }

    const getFacultyName = (disciplineCourseId: number) => {
        const disciplineCourse = facultyList.find((dc) => dc.id === disciplineCourseId);
        if (disciplineCourse) {
            return `${disciplineCourse.code} - ${disciplineCourse.name}`;
        }
        return 'N/A';
    }

    const filterStudent = () => {
        if (
            examId !== '' &&
            degreeId !== '' &&
            disciplineId !== '' &&
            semester !== '' &&
            regulation !== ''
        ) {
            axios.get(`${apiUrl}/practicalExamination/?examId=${examId}&degreeId=${degreeId}&disciplineId=${disciplineId}&semester=${semester}&regulation=${regulation}`)
                .then((response) => {
                    setPracticalExamList(response.data.data);
                    console.log(response.data.data);
                })
                .catch((error) => console.log(error));
            setSearchPerformed(true);
        } else {
            toast.error('Please select all filters');
        }
    };

    const renderDownloadButtons = () => {
        if (searchPerformed) {
            return (
                <div className="text-end mb-3">
                    <button
                        type="button"
                        onClick={handleDownloadPDF}
                        className="m-1 btn btn-sm btn-primary"
                    >
                        <Feather.Download /> PDF
                    </button>
                    {/* <CSVLink
                        data={csvData}
                        filename="details_of_course.csv"
                        className="m-1 btn btn-sm btn-primary"
                    >
                        <Feather.Download /> CSV
                    </CSVLink> */}
                </div>
            );
        }
        return null;
    };

    const handleDownloadPDF = () => {
        const doc = new jsPDF('p', 'mm', 'a4');

        doc.addImage(imgPath, 'PNG', 25, 10, 170, 40);

        doc.setFontSize(14)
        doc.setFont('Arial', 'normal', 'bold');
        doc.setTextColor(0, 0, 0)
        doc.text(`${getExam(examId)}`, 110, 54, { align: "center" });

        doc.setFontSize(10);
        doc.text(`Degree & Discipline : ${getDegree(degreeId)} - ${getDiscipline(disciplineId)}`, 15, 65);
        doc.text(`Semester & Regulation : ${semester} - ${regulation}`, 15, 70);

        autoTable(doc, {
            startY: 75,
            headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0] },
            bodyStyles: { textColor: [0, 0, 0], lineColor: [0, 0, 0] },
            head: [
                [
                    { content: 'S.No.', styles: { halign: 'center' } },
                    { content: 'Course', styles: { halign: 'center' } },
                    { content: 'Exam Date', styles: { halign: 'center' } },
                    { content: 'No Of Students', styles: { halign: 'center' } },
                    { content: 'No Of Session', styles: { halign: 'center' } },
                    { content: 'Internal Examiner', styles: { halign: 'center' } },
                    { content: 'Exaternal Examiner', styles: { halign: 'center' } },

                ],
            ],
            body: practicalExamList?.map((pe, index) => [
                { content: index + 1, styles: { halign: 'center' } },
                { content: getCourse(pe.disciplineCourseId), styles: { halign: 'center' } },
                { content: LocalFormatDate(pe.examDate), styles: { halign: 'center' } },
                { content: pe.noOfStudents, styles: { halign: 'center' } },
                { content: pe.noOfSessions, styles: { halign: 'center' } },
                { content: getFacultyName(pe.intExaminerId), styles: { halign: 'center' } },
                { content: getFacultyName(pe.extExaminerId), styles: { halign: 'center' } }
            ]),
            theme: 'grid',
        });

        // Save the PDF
        doc.save('practical_examination_report.pdf');
    };

    return (
        <>
            <PageContainer title="Practical Examination Report">
                <Card title="Filters">
                    <div className="row">
                        <div className="col-md-4">
                            <label className="mt-2 mb-2">Exam <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                value={examId}
                                onChange={(e) => {
                                    setExamId(e.target.value)
                                    setExamIdError('')
                                }}
                            >
                                <option value="">Select Exam</option>
                                {examList.map((ex) => (
                                    <option value={ex.id}>
                                        {ex.title}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-4">
                            <label className="mt-2 mb-2">Degree <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                value={degreeId}
                                onChange={(e) => {
                                    setDegreeId(e.target.value)
                                    setDegreeIdError('')
                                }}
                            >
                                <option value="">Select Degree</option>
                                {degreeList.map((deg) => (
                                    <option value={deg.id}>
                                        {deg.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-4">
                            <label className="mt-2 mb-2">Discipline <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                value={disciplineId}
                                onChange={(e) => {
                                    setDisciplineId(e.target.value)
                                    setDisciplineIdError('')
                                }}
                            >
                                <option value="">Select Disicpline</option>
                                {disciplineList.map((dis) => (
                                    <option value={dis.id}>
                                        {dis.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-4">
                            <label className="mt-2 mb-2">Semester <span className="text-danger">*</span> :</label>
                            <select
                                className="form-control"
                                name="selectedSemester"
                                value={semester}
                                onChange={(e) => {
                                    setSemester(e.target.value)
                                    setSemesterError('')
                                }}
                            >
                                <option value="" selected> Select Semester</option>
                                <option value='1'>1</option>
                                <option value='2'>2</option>
                                <option value='3'>3</option>
                                <option value='4'>4</option>
                                <option value='5'>5</option>
                                <option value='6'>6</option>
                                <option value='7'>7</option>
                                <option value='8'>8</option>
                            </select>
                        </div>
                        <div className="col-md-4">
                            <label className="mt-2 mb-2">Regulation <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                value={regulation}
                                onChange={(e) => {
                                    setRegulation(e.target.value)
                                    setRegulationError('')
                                }}
                            >
                                <option value="">Select Regulation</option>
                                {regulationList.map((reg) => (
                                    <option value={reg.title}>
                                        {reg.title}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-4">
                            <button
                                type="button"
                                className="btn btn-sm btn-primary"
                                onClick={filterStudent}
                                style={{ width: "32%", marginTop: "45px" }}
                            >
                                Search
                            </button>
                        </div>
                    </div>
                </Card>
                <CollapseCard title="Practical Examination">
                    <div className="container-fluid table-container" style={{ width: "150%" }}>
                        <table className="table table-success table-striped" style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <th>S.No.</th>
                                    <th>Exam</th>
                                    <th>Degree</th>
                                    <th>Discipline</th>
                                    <th>Semester</th>
                                    <th>Regulation</th>
                                    <th>Course</th>
                                    <th>Exam Date</th>
                                    <th>No of Students</th>
                                    <th>No of Sessions</th>
                                    <th>Internal Examiner</th>
                                    <th>External Examiner</th>
                                </tr>
                            </thead>
                            <tbody>
                                {practicalExamList?.map((pe, index) => (
                                    <tr key={pe.id}>
                                        <td className="report-td">{index + 1}</td>
                                        <td>{getExam(pe.examId)}</td>
                                        <td>{getDegree(pe.degreeId)}</td>
                                        <td>{getDiscipline(pe.disciplineId)}</td>
                                        <td>{pe.semester}</td>
                                        <td>{pe.regulation}</td>
                                        <td>{getCourse(pe.disciplineCourseId)}</td>
                                        <td>{LocalFormatDate(pe.examDate)}</td>
                                        <td>{pe.noOfStudents}</td>
                                        <td>{pe.noOfSessions}</td>
                                        <td>{getFacultyName(pe.intExaminerId)}</td>
                                        <td>{getFacultyName(pe.extExaminerId)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div>
                        {renderDownloadButtons()}
                    </div>
                </CollapseCard>
            </PageContainer>
        </>
    );
}

export default PracticalExaminationReport;


