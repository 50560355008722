import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from '../components/custom/Card';
import CollapseCard from '../components/custom/CollapseCard';
import PageContainer from '../components/custom/PageContainer';
import { apiUrl } from '../config';
import { CourseType } from '../redux/actions/courseActions';
import { DegreeType } from '../redux/actions/degreeAction';
import { DisciplineType } from '../redux/actions/disciplineAction';
import { DisciplineCourseType } from '../redux/actions/disciplineCourseAction';
import { ExamType } from '../redux/actions/examAction';
import { ExamFacultyType } from '../redux/actions/examFacultyAction';
import { FacultyType } from '../redux/actions/facultyActions';
import { StoreState } from '../redux/reducers';

function calculateGrade(totalConversion: number): number {
    if (totalConversion >= 91 && totalConversion <= 100) {
        return 10;
    } else if (totalConversion >= 81 && totalConversion <= 90) {
        return 9;
    } else if (totalConversion >= 71 && totalConversion <= 80) {
        return 8;
    } else if (totalConversion >= 61 && totalConversion <= 70) {
        return 7;
    } else if (totalConversion >= 56 && totalConversion <= 60) {
        return 6;
    } else if (totalConversion >= 50 && totalConversion <= 55) {
        return 5;
    } else {
        return 0;
    }
}

function calculateGradeText(totalConversion: number): string {
    if (totalConversion >= 91 && totalConversion <= 100) {
        return 'O';
    } else if (totalConversion >= 81 && totalConversion <= 90) {
        return 'A+';
    } else if (totalConversion >= 71 && totalConversion <= 80) {
        return 'A';
    } else if (totalConversion >= 61 && totalConversion <= 70) {
        return 'B+';
    } else if (totalConversion >= 56 && totalConversion <= 60) {
        return 'B';
    } else if (totalConversion >= 50 && totalConversion <= 55) {
        return 'C';
    } else {
        return 'U';
    }
}

const RevaluationMark = () => {
    const [registerList, setRegisterList] = useState<RevaluationMarkType[]>([]);
    const [selectDummyNoList, setSelectedDummyNoList] = useState<RevaluationMarkType[]>([]);
    const [dataList, setDataList] = useState<DummyNoItem[]>([]);

    const [editFlag, setEditFlag] = useState(false);
    const [examId, setExamId] = useState<number>(0);
    const [degreeId, setDegreeId] = useState<number>(0);
    const [disciplineId, setDisciplineId] = useState<number>(0);
    const [batch, setBatch] = useState<number>(0);
    const [disciplineCourseId, setDisciplineCourseId] = useState<number>(0);
    const [id, setId] = useState<number>(0);
    const [createdBy, setCreatedBy] = useState<string>('');
    const [createTimestamp, setCreateTimestamp] = useState<string>('');
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [examIdError, setExamIdError] = useState('');
    const [degreeIdError, setDegreeIdError] = useState('');
    const [disciplineIdError, setDisciplineIdError] = useState('');
    const [batchError, setBatchError] = useState('');
    const [currentSemesterError, setCurrentSemesterError] = useState('');
    const [registerNoError, setRegisterNoError] = useState('');
    const [disciplineCourseIdError, setDisciplineCourseIdError] = useState('');

    const [intExaminer, setIntExaminer] = useState<string>('')
    const [intExaminerError, setIntExaminerError] = useState<string>('')

    const [extExaminer, setExtExaminer] = useState<string>('')
    const [extExaminerError, setExtExaminerError] = useState<string>('')

    const [chiefExaminer, setChiefExaminer] = useState<string>('')
    const [chiefExaminerError, setChiefExaminerError] = useState<string>('')

    const [newMark, setNewMark] = useState<string>('')
    const [newMarkError, setNewMarkError] = useState<string>('')

    const [last10Years, setLast10Years] = useState<number[]>([]);
    const disciplineCourseList = useSelector<StoreState, DisciplineCourseType[]>(state => state.disciplineCourse)
    const examList = useSelector<StoreState, ExamType[]>(state => state.exam)
    const courseList = useSelector<StoreState, CourseType[]>(state => state.course)
    const degreeList = useSelector<StoreState, DegreeType[]>(state => state.degree)
    const disciplineList = useSelector<StoreState, DisciplineType[]>(state => state.discipline)
    const examFacultyList = useSelector<StoreState, ExamFacultyType[]>(state => state.examFaculty);
    const facultyList = useSelector<StoreState, FacultyType[]>(state => state.faculty);

    const [searchPerformed, setSearchPerformed] = useState(false);
    const [convertion, setConvertion] = useState(0);

    const [editNewMark, setEditNewMark] = useState<string>('')


    //filter View 
    const [studentListFilter, setFilteredStudentList] = useState<RevaluationMarkType[]>([]);
    const [examFilter, setExamFilter] = useState<string>('')
    const [degreeFilter, setDegreeFilter] = useState<string>('')
    const [disciplineFilter, setDisciplineFilter] = useState<string>('')
    const [courseFilter, setCourseFilter] = useState<string>('')
    const [dummyNumberFilter, setDummyNumberFilter] = useState('');
    const [studentarrearList, setStudentarrearList] = useState<StudentArrearType[]>([]);

    useEffect(() => {
        axios.get(`${apiUrl}/studentarrear/`)
            .then((response) => {
                setStudentarrearList(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching student arrear', error);
            });
    }, [])

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const years = Array.from({ length: 10 }, (_, index) => currentYear - index);
        setLast10Years(years);
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        axios.get<{ data: RevaluationMarkType[] }>(`${apiUrl}/revaluationMark/`)
            .then((response) => {
                setRegisterList(response.data.data);
            })
            .catch((error) => console.log(error));
    }

    const getDisciplineCourseId = (selectedCourseId: string) => {
        return disciplineCourseList.find(f => f.id === parseInt(selectedCourseId))?.courseId;
    };

    useEffect(() => {
        const filteredStudents = registerList.filter(s => {

            return (
                (examFilter === '' || s.examId?.toString() === examFilter) &&
                (degreeFilter === '' || s.degreeId?.toString() === degreeFilter) &&
                (disciplineFilter === '' || s.disciplineId?.toString() === disciplineFilter) &&
                (courseFilter === '' || getDisciplineCourseId(s.disciplineCourseId?.toString()) === parseInt(courseFilter)) &&
                (dummyNumberFilter === '' || s.dummyNumber?.toString() === dummyNumberFilter)
            );
        });

        setFilteredStudentList(filteredStudents);
    }, [registerList, disciplineCourseList, examFilter, degreeFilter, disciplineFilter, courseFilter, dummyNumberFilter]);

    const handleArrear = async (dataItem: any, semester: number) => {

        const studentArrearData = {
            disciplineId: parseInt(dataItem.disciplineId),
            degreeId: parseInt(dataItem.degreeId),
            registerNo: dataItem.registerNumber,
            semesterNo: semester,
            disciplineCourseId: parseInt(dataItem.disciplineCourseId),
            status: 'Pending',
        };

        const arrearResponse = await axios.post(`${apiUrl}/studentarrear/`, studentArrearData);
        if (arrearResponse.data.error) {
            console.log(arrearResponse.data.message, { position: 'top-right' });
        } else {
            console.log(arrearResponse.data.message, { position: 'top-right' });
        }

    };

    const handleDeleteArrear = async (dataItem: any, semester: number) => {

        let deleteArrearIfTheStudentPass = studentarrearList.find(sa =>
            sa.disciplineId === parseInt(dataItem.disciplineId) &&
            sa.degreeId === parseInt(dataItem.degreeId) &&
            sa.disciplineCourseId === parseInt(dataItem.disciplineCourseId) &&
            sa.registerNo === dataItem.registerNumber &&
            sa.semesterNo === semester
        )?.id;

        console.log(deleteArrearIfTheStudentPass)
        const response = await axios.delete(`${apiUrl}/studentarrear/?id=${deleteArrearIfTheStudentPass}`);
        if (response.data.error) {
            console.log(response.data.message, { position: 'top-right' });
        } else {
            console.log(response.data.message, { position: 'top-right' });
        }
    };

    const deleteHandler = (id: number) => {
        setId(id);
        openDeleteConfirmation();
    }

    const confirmDeleteHandler = () => {
        axios
            .delete(`${apiUrl}/revaluationMark/?id=${id}`)
            .then((response) => {
                toast.success(response.data.message, { position: 'top-right' });
                fetchData();
                closeDeleteConfirmation();
            })
            .catch((error) => toast.error(error.response.message));
    }

    const editClickHandler = (sm: RevaluationMarkType) => {
        let courseId = disciplineCourseList.find(f => f.degreeId === sm.degreeId && f.disciplineId === sm.disciplineId && f.id === sm.disciplineCourseId)?.courseId
        setExamId(sm.examId);
        setDegreeId(sm.degreeId);
        setDisciplineId(sm.disciplineId);
        setDisciplineCourseId(courseId === undefined ? 0 : courseId);//courseId
        console.log(disciplineCourseId)
        setNewMark(sm.newMark ? sm.newMark?.toString() : '');
        setIntExaminer(sm.intExaminerId ? sm.intExaminerId?.toString() : '');
        setExtExaminer(sm.extExaminerId ? sm.extExaminerId?.toString() : '');
        setChiefExaminer(sm.chiefExaminerId ? sm.chiefExaminerId?.toString() : '');
        setId(sm.id);


        axios
            .get(`${apiUrl}/revaluationMark/?degreeId=${sm.degreeId}&disciplineId=${sm.disciplineId}&disciplineCourseId=${sm.disciplineCourseId}&id=${sm.id === 0 ? null : sm.id}`)
            .then((response) => {
                setSelectedDummyNoList(response.data.data);
                console.log(response.data.data);
                setSearchPerformed(true);
            })
            .catch((error) => {
                console.log(error);
                setSearchPerformed(false);
            });
    }

    const updateHandler = async () => {
        let error = false;
        // console.log(dataList);
        if (dataList.length === 0) {
            error = true;
            toast.error('Data Required');
            return;
        }

        const editData = [];

        for (const sm of dataList) {
            try {
                setConvertion(sm.extConversion);
                const securedMark = sm.newMark;
                const intConversion = sm.intConvertedMark;
                // console.log(convertion)
                const extConversion = sm.extConversion;
                const convertedExternalMark = parseInt(securedMark) === -1 ? 0 : Math.round((parseFloat(securedMark) / 100) * extConversion);
                const totalConvertedMark = intConversion! + convertedExternalMark;

                const grade = calculateGrade(totalConvertedMark);
                const gradeText = calculateGradeText(totalConvertedMark);

                // console.log(securedMark);
                if (securedMark === '') {
                    error = true;
                    toast.error(`New mark required for Dummy No ${sm.dummyNumber} `, { position: 'top-right' });
                    continue;
                } else if (securedMark === null || parseFloat(securedMark) < -1 || isNaN(parseFloat(securedMark))) {
                    error = true;
                    toast.error(`Invalid New mark for Dummy No ${sm.dummyNumber} `, { position: 'top-right' });
                    continue;
                }


                editData.push({
                    id: sm.id,
                    smId: sm.smId,
                    newMark: securedMark,
                    extMark: securedMark,
                    extConvertedMark: convertedExternalMark,
                    totConvertedMark: totalConvertedMark,
                    intExaminerId: intExaminer,
                    extExaminerId: extExaminer,
                    chiefExaminerId: chiefExaminer,
                    grade: grade,
                    gradeText: gradeText,
                    dummyNumber: sm.dummyNumber,
                });
            } catch (error) {
                console.log(error);
            }
        }

        if (!error && editData.length > 0) {
            try {
                const response = await axios.put(`${apiUrl}/revaluationMark/`, editData);
                console.log(response.data.message);
                if (response.data.error) {
                    toast.error(response.data.message, { position: 'top-right' });
                } else {
                    toast.success(response.data.message, { position: 'top-right' });
                    fetchData();
                    clearHandler();
                }

                for (let i = 0; i < editData.length && i < dataList.length; i++) {
                    const editItem = editData[i];
                    const dataItem = dataList[i];

                    let disCourseId = disciplineCourseList.find(dc => dc.disciplineId === disciplineId && dc.degreeId === degreeId && dc.courseId === disciplineCourseId)?.id

                    let getSemesterForCheck = studentarrearList.find(sa =>
                        sa.disciplineId === disciplineId &&
                        sa.degreeId === degreeId &&
                        sa.disciplineCourseId === disCourseId &&
                        sa.registerNo === dataItem.registerNumber
                    )?.semesterNo || 0

                    let getSemesterFromDisCourse = disciplineCourseList.find(dc => dc.id === disCourseId)?.semesterNo

                    if (editItem.grade === 0 && editItem.gradeText === 'U') {
                        await handleArrear(dataItem, getSemesterForCheck);
                    } else if (editItem.grade > 0 && editItem.gradeText !== 'U') {
                        console.log('Delete')
                        await handleDeleteArrear(dataItem, getSemesterForCheck);
                    }
                }
            } catch (error: any) {
                console.error(error.response.data.message);
                toast.error(error.response.data.message, { position: 'top-right' });
            }
        }
    };

    const clearHandler = () => {
        setDegreeId(0);
        setDisciplineId(0);
        setBatch(0);
        setDisciplineCourseId(0);
        setExamIdError('');
        setDegreeIdError('');
        setDisciplineIdError('');
        setBatchError('');
        setCurrentSemesterError('');
        setRegisterNoError('');
        setDisciplineCourseIdError('');
        setId(0);
        setCreatedBy('');
        setCreateTimestamp('');
        setEditFlag(false);
        setSearchPerformed(false);
        setNewMark('');
        setIntExaminer('');
        setExtExaminer('');
        setChiefExaminer('');
        setDataList([]);
    }

    const openDeleteConfirmation = () => {
        setShowDeleteConfirmation(true);
    }

    const closeDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    }

    const getExamName = (examId: number) => {
        const exam = examList.find((ex) => ex.id === examId);
        if (exam) {
            return exam.title;
        }
        return 'N/A';
    }

    const getDegreeName = (degreeId: number) => {
        const degree = degreeList.find((deg) => deg.id === degreeId);
        if (degree) {
            return degree.name;
        }
        return 'N/A';
    }

    const getDisciplineName = (disciplineId: number) => {
        const discipline = disciplineList.find((dis) => dis.id === disciplineId);
        if (discipline) {
            return discipline.name;
        }
        return 'N/A';
    }

    const getDisciplineCourse = (disciplineCourseId: number) => {
        const CourseId = disciplineCourseList?.find((dc) => dc?.id?.toString() === disciplineCourseId?.toString())?.courseId

        const courseObj = courseList?.find((dc) => dc.id?.toString() === CourseId?.toString())

        if (courseObj) {
            return courseObj?.name ? courseObj?.name : '';
        }
        return 'N/A';
    }

    const getFacultyName = (disciplineCourseId: number) => {
        const disciplineCourse = facultyList.find((dc) => dc.id === disciplineCourseId);
        if (disciplineCourse) {
            return `${disciplineCourse.code} - ${disciplineCourse.name}`;
        }
        return 'N/A';
    }

    const filterStudent = () => {
        if (
            degreeId !== 0 &&
            disciplineId !== 0 &&
            disciplineCourseId !== 0
        ) {
            let disCourseId: any = disciplineCourseList?.find(f => f.degreeId === degreeId && f.disciplineId === disciplineId && (f.courseId === disciplineCourseId || f.id === disciplineCourseId))?.id

            axios
                .get(`${apiUrl}/revaluationMark/?degreeId=${degreeId}&disciplineId=${disciplineId}&disciplineCourseId=${disCourseId}&id=null`)
                .then((response) => {
                    setSelectedDummyNoList(response.data.data);
                    console.log(response.data.data);
                })
                .catch((error) => console.log(error));
            setSearchPerformed(true);
        } else {
            toast.error('Please select all filters');
        }
    };

    useEffect(() => {
        const data = selectDummyNoList?.map((sm, i) => {
            return {
                id: sm.id,
                smId: sm.smId,
                dummyNumber: sm.dummyNumber,
                registerNumber: sm.registerNumber,
                questionPaperCode: sm.questionPaperCode,
                oldMark: (sm.oldMark) ? parseFloat(sm.oldMark) : 0,
                newMark: sm.newMark || editNewMark,
                intConvertedMark: sm.intConvertedMark,
                extConversion: sm.extConversion,
            }
        });

        console.log(data);

        setDataList(data);
    }, [selectDummyNoList, editNewMark])


    return (
        <PageContainer title="Revaluation Mark">
            <Card title={"Update Mark"}>
                <div className="row">
                    <div className="col-4">
                        <label htmlFor="" className="mt-3 mb-3">Degree <span className="text-danger">*</span> : </label>
                        <select name="" id="" className="form-control" value={degreeId.toString()} onChange={(e) => {
                            setDegreeId(Number(e.target.value))
                            setDegreeIdError('')
                        }}>
                            <option value="" selected>Select Degree</option>
                            {degreeList?.map((deg) => (
                                <option key={deg.id} value={deg.id}>
                                    {deg.name}
                                </option>
                            ))}
                        </select>
                        {degreeIdError && <div className="text-danger">{degreeIdError}</div>}
                    </div>

                    <div className="col-4">
                        <label className="mt-3 mb-3">Discipline <span className="text-danger">*</span> : </label>
                        <select name="" id="" className="form-control" value={disciplineId.toString()} onChange={(e) => {
                            setDisciplineId(Number(e.target.value))
                            setDisciplineIdError('')
                        }}>
                            <option value="" selected>Select Discipline</option>
                            {disciplineList?.map((disc) => (
                                <option key={disc.id} value={disc.id}>
                                    {disc.name}
                                </option>
                            ))}
                        </select>
                        {disciplineIdError && <div className="text-danger">{disciplineIdError}</div>}
                    </div>

                    <div className="col-4">
                        <label className="mt-3 mb-3">Course <span className="text-danger">*</span> : </label>
                        <select name="" id="" className="form-control" value={disciplineCourseId.toString()} onChange={(e) => {
                            setDisciplineCourseId(Number(e.target.value))
                            setDisciplineCourseIdError('')
                        }}>
                            <option value="" selected>Select Course</option>
                            {courseList?.map((course) => (
                                <option key={course.id} value={course.id}>
                                    {course.name}
                                </option>
                            ))}
                        </select>
                        {disciplineCourseIdError && <div className="text-danger">{disciplineCourseIdError}</div>}
                    </div>

                    <div className="col-4">
                        <label className="mt-3 mb-3">Assistant Examiner <span className="text-danger">*</span> : </label>
                        <select
                            name=""
                            id=""
                            className="form-control"
                            value={intExaminer}
                            onChange={(e) => {
                                setIntExaminer(e.target.value);
                                setIntExaminerError('');
                            }}
                        >
                            <option value="" selected>
                                Select Assistant Examiner
                            </option>
                            {Array.from(new Set(examFacultyList?.filter(f => f.type === 'assist.examiner')?.map(examFaculty => examFaculty.facultyId)))
                                ?.map(facultyId => {
                                    const faculty = facultyList.find(faculty => faculty.id === facultyId);
                                    return (
                                        <option key={facultyId} value={facultyId}>
                                            {faculty?.name}
                                        </option>
                                    );
                                })}
                        </select>
                        {intExaminerError && <div className="text-danger">{intExaminerError}</div>}
                    </div>

                    <div className="col-4">
                        <label htmlFor="" className="mt-3 mb-3">Examiner <span className="text-danger">*</span> : </label>
                        <select
                            name=""
                            id=""
                            className="form-control"
                            value={extExaminer}
                            onChange={(e) => {
                                setExtExaminer(e.target.value);
                                setExtExaminerError('');
                            }}
                        >
                            <option value="" selected>
                                Select Examiner
                            </option>
                            {Array.from(new Set(examFacultyList?.filter(f => f.type === 'examiner')?.map(examFaculty => examFaculty.facultyId)))
                                ?.map(facultyId => {
                                    const faculty = facultyList.find(faculty => faculty.id === facultyId);
                                    return (
                                        <option key={facultyId} value={facultyId}>
                                            {faculty?.name}
                                        </option>
                                    );
                                })}
                        </select >
                        {extExaminerError && <div className="text-danger">{extExaminerError}</div>}
                    </div >

                    <div className="col-4">
                        <label htmlFor="" className="mt-3 mb-3">Chief Examiner <span className="text-danger">*</span> : </label>
                        <select
                            name=""
                            id=""
                            className="form-control"
                            value={chiefExaminer}
                            onChange={(e) => {
                                setChiefExaminer(e.target.value);
                                setChiefExaminerError('');
                            }}
                        >
                            <option value="" selected>
                                Select Chief Examiner
                            </option>
                            {Array.from(new Set(examFacultyList?.filter(f => f.type === 'chief.examiner')?.map(examFaculty => examFaculty.facultyId)))
                                .map(facultyId => {
                                    const faculty = facultyList.find(faculty => faculty.id === facultyId);
                                    return (
                                        <option key={facultyId} value={facultyId}>
                                            {faculty?.name}
                                        </option>
                                    );
                                })}
                        </select >
                        {chiefExaminerError && <div className="text-danger">{chiefExaminerError}</div>}
                    </div >

                    <div className="col-12">
                        <button
                            type="button"
                            className="btn btn-sm btn-primary"
                            onClick={filterStudent}
                            style={{ width: "10%", marginTop: "60px", float: "right" }}
                        >
                            Search
                        </button>
                    </div>


                    <div className="container-fluid" style={{ marginTop: "30px" }}>
                        <table className="table table-success table-striped">
                            <thead>
                                <tr>
                                    <th>S.No.</th>
                                    <th>Dummy Number</th>
                                    <th>Question Paper Code</th>
                                    <th>Old Mark</th>
                                    <th>New Mark</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataList?.map((sm, i) => (
                                    <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td>{sm.dummyNumber}</td>
                                        <td>{sm.questionPaperCode}</td>
                                        <td>{sm.oldMark}</td>
                                        <td>
                                            <input
                                                type="number"
                                                style={{ width: "30%" }}
                                                className="form-control"
                                                value={sm.newMark}
                                                onChange={(e) => {
                                                    const enteredValue = e.target.value;
                                                    console.log(enteredValue);
                                                    const isValid = enteredValue === '' || (parseInt(enteredValue) >= 0 && parseInt(enteredValue) <= 100);

                                                    if (isValid) {
                                                        setDataList((prev) => {
                                                            let updated = [...prev];
                                                            updated[i].newMark = enteredValue;
                                                            return updated;
                                                        });
                                                        setNewMarkError('');
                                                    } else {
                                                        setNewMarkError(`Mark must be within 0 to 100`);
                                                    }
                                                }}
                                                placeholder="Enter Secured Mark..."
                                            />
                                            {newMarkError && <div className="text-danger">{newMarkError}</div>}
                                        </td>
                                    </tr>
                                ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="m-4 d-flex justify-content-end">
                    <button className="btn btn-sm btn-secondary" onClick={clearHandler} style={{ marginRight: '10px' }}>
                        Clear
                    </button>
                    <button className="btn btn-sm btn-success" onClick={updateHandler}>Update</button>
                </div>
            </Card>
            <CollapseCard title="Revaluation Mark">
                {/* <div className='container'> */}
                <div className="row m-2 mb-3">
                    <div className="col-4">
                        <label htmlFor="" className="mt-2 mb-2">Exam : </label>
                        <select name="" id="" className="form-control" value={examFilter} onChange={(e) => {
                            setExamFilter(e.target.value)
                        }}>
                            <option value="" selected>Select Exam</option>
                            {examList?.map((ex) => (
                                <option key={ex.id} value={ex.id}>
                                    {ex.title}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col-4" >
                        <label htmlFor="" className="mt-2 mb-2">Degree : </label>
                        <select name="" id="" className="form-control" value={degreeFilter} onChange={(e) => {
                            setDegreeFilter(e.target.value)
                        }}>
                            <option value="" selected>Select Degree</option>
                            {degreeList?.map((deg) => (
                                <option key={deg.id} value={deg.id}>
                                    {deg.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col-4">
                        <label className="mt-2 mb-2">Discipline : </label>
                        <select name="" id="" className="form-control" value={disciplineFilter} onChange={(e) => {
                            setDisciplineFilter(e.target.value)
                        }}>
                            <option value="" selected>Select Discipline</option>
                            {disciplineList?.map((disc) => (
                                <option key={disc.id} value={disc.id}>
                                    {disc.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-4">
                        <label className="mt-2 mb-2">Course  </label>
                        <select name="" id="" className="form-control" value={courseFilter} onChange={(e) => {
                            setCourseFilter(e.target.value)
                        }}>
                            <option value="" selected>Select Course</option>
                            {courseList?.map((course) => (
                                <option key={course.id} value={course.id}>
                                    {course.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col-4">
                        <label className="mt-2 mb-2">Dummy Number :</label>
                        <div className="d-flex">
                            <input
                                type="number"
                                style={{ width: "45%" }}
                                className="form-control"
                                value={dummyNumberFilter}
                                onChange={(e) => {
                                    const enteredValue = e.target.value;
                                    setDummyNumberFilter(enteredValue);
                                }}
                                placeholder="From"
                            />
                        </div>
                    </div>
                </div>
                <table className="table table-primary table-striped" style={{ width: "100%", overflow: "auto" }}>
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Exam</th>
                            <th>Degree</th>
                            <th>Discipline</th>
                            <th>Discipline Course</th>
                            <th>Dummy Number</th>
                            <th>Mark</th>
                            <th>Internal Examiner</th>
                            <th>External Examiner</th>
                            <th>Chief Examiner</th>
                            <th>Action</th>
                            {/* <th>Options</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {studentListFilter?.map((register, index) => (
                            <tr key={register.id}>
                                <td>{index + 1}</td>
                                <td>{getExamName(register.examId)}</td>
                                <td>{getDegreeName(register.degreeId)}</td>
                                <td>{getDisciplineName(register.disciplineId)}</td>
                                <td>{getDisciplineCourse(register.disciplineCourseId)}</td>
                                <td>{register.dummyNumber}</td>
                                <td>{register.newMark}</td>
                                <td>{getFacultyName(register.intExaminerId)}</td>
                                <td>{getFacultyName(register.extExaminerId)}</td>
                                <td>{getFacultyName(register.chiefExaminerId)}</td>
                                <td>
                                    <button className="btn btn-primary" onClick={() => editClickHandler(register)}>Edit</button>
                                </td>
                            </tr>
                            // </thead>
                            // <tbody>
                            //     {registerList?.map((register, index) => (
                            //         <tr key={register.id}>
                            //             <td>{index + 1}</td>
                            //             <td>{getExamName(register.examId)}</td>
                            //             <td>{getDegreeName(register.degreeId)}</td>
                            //             <td>{getDisciplineName(register.disciplineId)}</td>
                            //             <td>{getDisciplineCourse(register.disciplineCourseId)}</td>
                            //             <td>{register.dummyNumber}</td>
                            //             <td>{register.newMark}</td>
                            //             <td>{getFacultyName(register.intExaminerId)}</td>
                            //             <td>{getFacultyName(register.extExaminerId)}</td>
                            //             <td>{getFacultyName(register.chiefExaminerId)}</td>
                            //             <td>
                            //                 <button className="btn btn-primary" onClick={() => editClickHandler(register)}>Edit</button>
                            //             </td>
                            //         </tr>
                        ))}
                    </tbody>
                </table>
            </CollapseCard>
        </PageContainer >

    );
}
export default RevaluationMark;

interface RevaluationMarkType {
    id: number;
    smId: number;
    examId: number;
    degreeId: number;
    disciplineId: number;
    batch: number;
    semester: number;
    registerNumber: string;
    disciplineCourseId: number;
    oldMark: string;
    newMark: string;
    intExaminerId: number;
    extExaminerId: number;
    chiefExaminerId: number;
    extMark: number;
    extConvertedMark: number;
    totConvertedMark: number;
    grade: number;
    gradeText: string;
    dummyNumber: number;
    intConvertedMark: number;
    extConversion: number;
    questionPaperCode: string;
}

interface DummyNoItem {
    id: number;
    smId: number;
    dummyNumber: number;
    registerNumber: string;
    questionPaperCode: string;
    // extMark: number;
    oldMark: number;
    intConvertedMark: number;
    extConversion: number;
    newMark: string;
}

interface StudentArrearType {
    id?: number;
    disciplineId: number;
    degreeId: number;
    registerNo: string;
    semesterNo: number;
    disciplineCourseId: number;
    attemptCount: number;
    clearedSemester: number;
    status: string;
    createdBy: string;
    createTimestamp: string;
}