import axios from 'axios';
import jsPDF from 'jspdf';
import autoTable, { RowInput } from 'jspdf-autotable';
import { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import * as Feather from 'react-feather';
import Card from '../components/custom/Card';
import CollapseCard from '../components/custom/CollapseCard';
import PageContainer from '../components/custom/PageContainer';
// import Option from '../components/custom/';
// import { YearOptions } from '../config/functions';
// import Discipline from './Discipline';
import { toast } from 'react-toastify';
import { apiUrl } from '../config';
import imgPath from './img/collegeLogo.png';
import { useSelector } from 'react-redux';
import { StoreState } from '../redux/reducers';
import { ExamType } from '../redux/actions/examAction';
import { DegreeType } from '../redux/actions/degreeAction';
import { DisciplineType } from '../redux/actions/disciplineAction';
interface Props {
    title: string;
}

interface ExamFeesReportType {
    regNo: string
    examName: string
    depRegNo: string
    name: string
    regulation: number
    dateOfBirth: string
    discipline: string | number
    sem: number
    fees: number
    paymentStatus: string
}

const ExamFeesReport = (props: Props) => {
    const { title } = props;
    const fileName = 'exam_fees_report';
    const [examFeesReportData, setExamFeesReportData] = useState<ExamFeesReportType[]>([]);
    const [disciplineList, setDisciplineList] = useState<Discipline[]>([]);


    const selectExam = useSelector<StoreState, ExamType[]>(state => state.exam)
    const [exam, setExam] = useState<string>('')

    const selectDiscipline = useSelector<StoreState, DisciplineType[]>(state => state.discipline)
    const [discipline, setDiscipline] = useState<string>('')

    const selectDegree = useSelector<StoreState, DegreeType[]>(state => state.degree)
    const [degree, setDegree] = useState<string>('')

    const [selectpaidStatus, setSelectPaidStatus] = useState<ExamRegister[]>([])
    const [paymentStatus, setPaymentStatus] = useState<string>('')

    // useEffect(() => {
    //     axios.get(`${apiUrl}/exam/`)
    //         .then((response) => {
    //             setSelectExam(response.data.data);
    //         })
    //         .catch((error) => {
    //             console.error('Error fetching Exam', error);
    //         });
    // }, [])

    // useEffect(() => {
    //     axios.get(`${apiUrl}/discipline/`)
    //         .then((response) => {
    //             setSelectDiscipline(response.data.data);
    //         })
    //         .catch((error) => {
    //             console.error('Error fetching discipline course', error);
    //         });
    // }, [])

    // useEffect(() => {
    //     axios.get(`${apiUrl}/degree/`)
    //         .then((response) => {
    //             setSelectDegree(response.data.data);
    //         })
    //         .catch((error) => {
    //             console.error('Error fetching degree', error);
    //         });
    // }, [])

    // useEffect(() => {
    //     axios.get(`${apiUrl}/examregister/`)
    //         .then((response) => {
    //             setSelectPaidStatus(response.data.data);
    //         })
    //         .catch((error) => {
    //             console.error('Error fetching exam register', error);
    //         });
    // }, [])

    // const handleFilterChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    //   const selectedYear = e.target.value;
    //   setAcademicYear(selectedYear);
    //   // setFilteredData(getFilteredData(selectedYear));
    // };

    const CSVdata: string[][] = [
        [
            'S.No.',
            'Dept.',
            'Sem.',
            'Reg. No.',
            'D.No.',
            'Name of the Student',
            'Total Fee (in rupees)',
            'Paid / Not Paid'
        ],
        // ['1', '2023-2024', 'Computer Science', '3', 'CB20S31', '1', 'John Doe', '15000', 'Paid'],
        // ['2', '2023-2024', 'Electrical Engineering', '3', 'CB20S32', '2', 'Jane Doe', '18000', 'Not Paid'],
        // ['3', '2022-2023', 'Mechanical Engineering', '3', 'CB20S33', '3', 'Alice Doe', '12000', 'Paid'],
        // ['4', '2022-2023', 'Civil Engineering', '3', 'CB20S34', '4', 'Bob Doe', '5000', 'Not Paid'],
    ];



    examFeesReportData.forEach((e, i) => {
        let obj = disciplineList.find(d => d.id?.toString() === e.discipline?.toString())
        let depName = obj?.name

        CSVdata.push([(i + 1)?.toString(), e.discipline?.toString(), e.sem?.toString(), e.regNo, e.depRegNo, e.name, e.fees?.toString(), e.paymentStatus, ''])
    })

    // const [filteredData, setFilteredData] = useState(() => getFilteredData('2023-2024'));

    // function getFilteredData(year: string) {
    //     return data.filter((row, index) => index === 0 || row[1] === year); // Assuming Academic Year is always at index 1
    // }

    const handleDownloadPDF = () => {
        const doc = new jsPDF('p', 'mm', 'a4');
        // var table = '#' + id;
        doc.addImage(imgPath, 'PNG', 25, 10, 170, 40);

        doc.setFontSize(14);
        doc.setFont('Arial', 'normal', 'bold');
        doc.setTextColor(0, 0, 0);
        doc.text(title, 110, 53, { align: 'center' });

        doc.setFontSize(11);
        doc.setFont('Arial', 'normal');

        examFeesReportData.forEach((ex) => {
            doc.text(`Exam Name : ${ex.examName}`, 15, 63);
            doc.text(`Department Name : ${ex.discipline}`, 15, 70);
        })

        const filteredRows = CSVdata;

        const headWithoutAcademicYear = filteredRows[0].filter((col) => col !== 'Dept.');
        //derived from csv data
        const bodyWithoutAcademicYear = filteredRows.slice(1).map((row) => {
            const examfeesIndex = row.findIndex((col) => col === examFeesReportData.toString());
            return [
                ...row.slice(0, examfeesIndex),
                ...row.slice(examfeesIndex + 1),
                row[examfeesIndex],
            ];

        });

        const grandTotal = examFeesReportData.reduce((prev, curr) => prev + curr.fees, 0);

        //new table body
        const body: RowInput[] = examFeesReportData.map((e, i) => {
            return [
                { content: (i + 1).toString() },
                { content: e.sem },
                { content: e.regNo },
                { content: e.depRegNo },
                { content: e.name, styles: { halign: 'left' } },
                { content: e.fees },
                { content: e.paymentStatus }
            ]
        })
        body.push(
            [
                { content: 'Grand Total (in rupees)', styles: { fontStyle: 'bold', halign: 'right', }, colSpan: 5 },
                { content: grandTotal.toString(), styles: { halign: 'left', cellPadding: { left: 13, top: 2 } }, colSpan: 2 }
            ]
        )
        //table
        autoTable(doc, {
            startY: 75,
            headStyles: {
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
                lineWidth: 0.3,
                lineColor: [0, 0, 0],
                halign: 'center',
                fontSize: 10
            },
            bodyStyles: { textColor: [0, 0, 0], lineColor: [0, 0, 0], halign: 'center', fontSize: 9 },
            didDrawPage: function () {
                var str = doc.getNumberOfPages();
                doc.setFontSize(10);
                doc.text(str.toString(), 260, 10);
            },
            head: [headWithoutAcademicYear],
            body: body,
            // columnStyles: {
            //     0: { cellWidth: 13 },
            //     // 1: { cellWidth: 58 },
            //     1: { cellWidth: 12 },
            //     2: { cellWidth: 30 },
            //     3: { cellWidth: 20 },
            //     4: { cellWidth: 60 },
            //     5: { cellWidth: 20 },
            //     6: { cellWidth: 20 },
            // },
            theme: 'grid',

        });

        // autoTable(doc, {
        //     startY: (doc as any).lastAutoTable.finalY,
        //     headStyles: {
        //         fillColor: [255, 255, 255],
        //         textColor: [0, 0, 0],
        //         lineWidth: 0.3,
        //         lineColor: [0, 0, 0],
        //         halign: 'center',
        //     },
        //     body: [[
        //         { content: 'Grand Total (in rupees)', styles: { fontStyle: 'bold', halign: 'right', cellWidth: 147 } },
        //         { content: grandTotal.toString(), styles: { halign: 'left', cellWidth: 35 } }
        //     ]],
        //     bodyStyles: { textColor: [0, 0, 0], lineColor: [0, 0, 0], halign: 'center', },
        //     columnStyles: {
        //         0: {}
        //     },
        //     theme: 'grid',
        // });

        doc.save(fileName + '.pdf');
    };

    const handleSearch = () => {
        if (exam !== '' && degree !== '' && discipline !== '') {
            // let years = academicYear.split('-')
            axios.get<{ data: ExamFeesReportType[] }>(`${apiUrl}/examregister/report/?exam=${exam}&degree=${degree}&discipline=${discipline}&paymentStatus=${paymentStatus}`)
                .then((response) => {
                    setExamFeesReportData(response.data.data);
                })
                .catch((error) => console.log(error));
        } else {
            toast.error('Select Exam,Degree and Discipline Filters', { position: 'top-right', autoClose: 3000 });
            setExamFeesReportData([])
        }
    }

    const fetchData = () => {
        axios.get<{ data: Discipline[] }>(`${apiUrl}/discipline/`)
            .then((response) => {
                setDisciplineList(response.data.data);
            })
            .catch((error) => console.log(error));
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <PageContainer title={title}>
            <Card title="Filter">
                <div className="row">

                    <div className='col-3'>
                        <label className="form-label">Exam <span className="text-danger">*</span> :</label>
                        <select name="" id="" className='form-control' onChange={(e) => setExam(e.target.value)} value={exam}>
                            <option value="">Select</option>
                            {selectExam.map((ex) => {
                                return <option value={ex.id}>{ex.title}</option>
                            })
                            }
                        </select>
                    </div>

                    <div className='col-3'>
                        <label className="form-label">Degree <span className="text-danger">*</span> :</label>
                        <select name="" id="" className='form-control' value={degree} onChange={(e) => setDegree(e.target.value)}>
                            <option value="">Select</option>
                            {selectDegree.map((deg) => {
                                return <option value={deg.id}>{deg.name}</option>
                            })
                            }
                        </select>
                    </div>

                    <div className='col-3'>
                        <label className="form-label">Discipline <span className="text-danger">*</span> :</label>
                        <select name="" id="" className='form-control' value={discipline} onChange={(e) => setDiscipline(e.target.value)}>
                            <option value="">Select</option>
                            {selectDiscipline.map((disc) => {
                                return <option value={disc.id}>{disc.name}</option>
                            })
                            }
                        </select>
                    </div>
                    <div className='col-3'>
                        <label className="form-label">Payment Status :</label>
                        <select name="" id="" className='form-control' value={paymentStatus} onChange={(e) => setPaymentStatus(e.target.value)}>
                            {/* {
            selectpaidStatus.map((sp)=>{
              return <option value={sp.paymentStatus}>{sp.paymentStatus}</option>
            })
              } */}
                            <option value="">Select</option>
                            <option value="Paid">Paid</option>
                            <option value="Not Paid">Not Paid</option>
                        </select>
                    </div>

                </div>
                <div className="row">
                    {/* <div className="col-lg-12 col-sm-12"> */}
                    <div className=" col-12 m-2 text-end">
                        <button className='btn btn-sm btn-primary' onClick={handleSearch} style={{ width: "10%", marginTop: "55px", float: "right" }}>Search</button>
                    </div>
                    {/* </div> */}
                </div>
            </Card>
            <CollapseCard title="Exam Fees Report">
                {/* <div className="container-fluid table-container"> */}
                <table className="table table-success table-striped" style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <th>S.No.</th>
                            <th>Exam Name</th>
                            <th>Dept.</th>
                            <th>Reg.No.</th>
                            <th>D.No.</th>
                            <th>Sem.</th>
                            <th className='text-truncate'>Name of the Student</th>
                            <th className='text-truncate'>Total Fee(in rupees)</th>
                            <th className='text-truncate'>Payment Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {examFeesReportData.map((row, i) => {
                            // let obj = disciplineList.find(d => d.id?.toString() === row.disciplineId?.toString())
                            // let depName = obj?.name

                            // let examTitle = selectExam.find(e => e.id?.toString() === row.examName?.toString())
                            // let examName=examTitle?.title
                            return (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{row.examName}</td>
                                    <td>{row.discipline}</td>
                                    <td>{row.regNo}</td>
                                    <td>{row.depRegNo}</td>
                                    <td>{row.sem}</td>
                                    <td>{row.name}</td>
                                    <td>{row.fees}</td>
                                    <td>{row.paymentStatus}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                {/* </div> */}
                <div className="row">
                    {examFeesReportData.length > 0 && <div className="col-md-6">
                        <div className="text-start mb-3">
                            <button type="button" onClick={handleDownloadPDF} className={`m-1 btn btn-sm btn-primary`}>
                                <Feather.Download /> PDF
                            </button>
                            <CSVLink data={CSVdata} filename={fileName + '.csv'}>{' '}
                                <button type="button" className={`m-1 btn btn-sm btn-primary`}>
                                    <Feather.Download /> CSV
                                </button>
                            </CSVLink>
                        </div>
                    </div>}
                </div>
            </CollapseCard>
        </PageContainer >
    );
};

export default ExamFeesReport;


// interface Exam {
//     id: number;
//     title: string;
// }

// interface Degree {
//     id: number;
//     name: string;
// }

interface Discipline {
    id: number;
    name: string;
}

interface ExamRegister {
    id: number;
    registerNo: string;
    currentSemester: string;
    examId: string;
    totalCourse: string;
    totalFees: string;
    paymentStatus: string;
    paymentParticulars: string;
    disciplineCourseId: string;
    createdBy: string;
    createTimestamp: string;
    name: string;
    title: string;
    shortName: string;
    examFees: string;
    courseId: string;
}
