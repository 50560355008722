import axios from 'axios';
import { FC, useMemo, useState } from 'react';
import Card from '../components/custom/Card';
import CollapseCard from '../components/custom/CollapseCard';
import PageContainer from '../components/custom/PageContainer';
// import './styles/tableReport.css';

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { CSVLink } from 'react-csv';
import * as Feather from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { apiUrl } from '../config';
import { YearOptions } from '../config/functions';
import { CourseType } from '../redux/actions/courseActions';
import { DegreeType } from '../redux/actions/degreeAction';
import { DisciplineType } from '../redux/actions/disciplineAction';
import { DisciplineCourseType } from '../redux/actions/disciplineCourseAction';
import { ExamType } from '../redux/actions/examAction';
import { StoreState } from '../redux/reducers';
import { AppDispatch } from '../redux/store';
import imgPath from './img/collegeLogo.png';

interface PassingResult {
    id: number;
    semesterNo: number;
    rg: number;
    paymentStatus: string;
    disciplineName: string;
    courseTitle: string;
    degreeName: string;
    semester: number;
    // courseName: string;
    courseCode: number;
    // examId: string;
    date: string;
    session: string;
}

interface Props {
    title: string;
}

type FontStyle = 'normal' | 'bold' | 'italic' | 'underline' | string;

const PassingResultAnalysis: FC<Props> = (props: Props) => {
    const { title } = props;
    const fileName = "passing_result_analysis";

    const dispatch = useDispatch<AppDispatch>()

    // const StudentMark = useSelector<StoreState, StudentMarkType[]>(state => state.studentMark)

    const examList = useSelector<StoreState, ExamType[]>(state => state.exam)
    const courseList = useSelector<StoreState, CourseType[]>(state => state.course)
    const degreeList = useSelector<StoreState, DegreeType[]>(state => state.degree)
    const disciplineList = useSelector<StoreState, DisciplineType[]>(state => state.discipline)
    const disciplineCourseList = useSelector<StoreState, DisciplineCourseType[]>(state => state.disciplineCourse)


    // useEffect(() => {
    //     dispatch(fetchStudentMarkAction())
    // }, [])

    const [passingResultList, setPassingResultList] = useState<PassingResultType[]>([]);
    // const [id, setId] = useState("");

    console.log(passingResultList);

    const [exam, setExam] = useState<string>('')
    const [examError, setExamError] = useState<string>('')
    const [degree, setDegree] = useState<string>('')
    const [degreeError, setDegreeError] = useState<string>('')
    const [discipline, setDiscipline] = useState<string>('')
    const [disciplineError, setDisciplineError] = useState<string>('')
    const [disciplineCourse, setDisciplineCourse] = useState<string>('')
    const [disciplineCourseError, setDisciplineCourseError] = useState<string>('')
    const [batch, setBatch] = useState<string>('')
    const [batchError, setBatchError] = useState<string>('')
    const [sem, setSem] = useState<string>('')
    const [semError, setSemError] = useState<string>('')
    const yearList = YearOptions();

    const handleSearch = () => {

        let disciplineCourseId = disciplineCourseList.find(f => f.degreeId?.toString() === degree && f.disciplineId?.toString() === discipline && f.courseId?.toString() === disciplineCourse)?.id

        if (exam !== '' && degree !== '' && discipline !== '' && (disciplineCourseId ? disciplineCourseId?.toString() : '') !== '' && batch !== '' && sem !== '') {
            // let years = academicYear.split('-')
            axios.get<{ data: PassingResultType[] }>(`${apiUrl}/studentMark/passingResult/?examId=${exam}&degreeId=${degree}&disciplineId=${discipline}&disciplineCourseId=${disciplineCourseId}&batch=${batch}&semester=${sem}`)
                .then((response) => {
                    const result = response.data.data
                    if (result.length > 0) {
                        setPassingResultList(result)
                    } else {
                        toast.error('No records found');
                    }
                })
                .catch((error) => {
                    toast.error(error.response.data.message);
                    console.log(error.response);

                    // console.error('Error fetching passing result', error);
                });
        } else {
            toast.error('Select All Filters', { position: 'top-right', autoClose: 3000 });

        }
    }

    //PDF Generate
    const row: any = [];

    const getMarkPercentage = useMemo(() => {
        let markPercentage = 0;
        passingResultList.forEach((ps) => {
            let mr = ps.totalMark;

            markPercentage = markPercentage + mr;

        });

        return markPercentage;
    }, [])

    const totalStudent = getMarkPercentage;


    //Total Student
    const totalStud = passingResultList.length

    console.log(totalStud);

    //Pass Student    
    const passMark = 50

    const passStud = passingResultList.filter((ps) => ps.totalMark >= passMark)

    const noStudPass = passStud.length;

    console.log(noStudPass);


    const getPercentage = Math.round((noStudPass / totalStud) * 100)

    console.log(getPercentage);

    //Add One Mark
    const addOne = passingResultList.filter((ps) => ps.totalMark == 49).length
    const addOnePass = addOne + noStudPass
    const addMarkOne = Math.round((addOnePass / totalStud) * 100);
    console.log(addOnePass);

    //Add Two Mark
    const addTwo = passingResultList.filter((ps) => ps.totalMark == 48).length
    const addTwoPass = addTwo + addOne + noStudPass
    const addMarkTwo = Math.round((addTwoPass / totalStud) * 100);
    console.log(addTwoPass);

    //Add Three Mark
    const addThree = passingResultList.filter((ps) => ps.totalMark == 47).length
    const addThreePass = addThree + addTwoPass
    const addMarkThree = Math.round((addThreePass / totalStud) * 100);

    //Add Four Mark
    const addFour = passingResultList.filter((ps) => ps.totalMark == 46).length
    const addFourPass = addFour + addThreePass
    const addMarkFour = Math.round((addFourPass / totalStud) * 100)

    //Add Five Mark
    const addFive = passingResultList.filter((ps) => ps.totalMark == 45).length
    const addFivePass = addFive + addFourPass
    const addMarkFive = Math.round((addFivePass / totalStud) * 100);

    // //Add Six Mark
    // const addSix = passingResultList.filter((ps) => ps.totalMark == 44).length
    // const addSixPass = addSix + noStudPass
    // const addMarkSix = addSix !== 0 ? Math.round((addSixPass / totalStud) * 100) : getPercentage;

    // //Add Seven Mark
    // const addSeven = passingResultList.filter((ps) => ps.totalMark == 43).length
    // const addSevenPass = addSeven + noStudPass
    // const addMarkSeven = addSeven !== 0 ? Math.round((addSevenPass / totalStud) * 100) : getPercentage;

    // //Add Eight Mark
    // const addEight = passingResultList.filter((ps) => ps.totalMark == 42).length
    // const addEightPass = addEight + noStudPass
    // const addMarkEight = addEight !== 0 ? Math.round((addEightPass / totalStud) * 100) : getPercentage;


    // //Add Nine Mark
    // const addNine = passingResultList.filter((ps) => ps.totalMark == 41).length
    // const addNinePass = addNine + noStudPass
    // const addMarkNine = addNine !== 0 ? Math.round((addNinePass / totalStud) * 100) : getPercentage;

    // //Add Ten Mark
    // const addTen = passingResultList.filter((ps) => ps.totalMark == 40).length
    // const addTenPass = addTen + noStudPass
    // const addMarkTen = addTen !== 0 ? Math.round((addTenPass / totalStud) * 100) : getPercentage;


    //body row:
    const rowValue = [
        { content: getPercentage },
        { content: addMarkOne },
        { content: addMarkTwo },
        { content: addMarkThree },
        { content: addMarkFour },
        { content: addMarkFive },
        // { content: addMarkSix },
        // { content: addMarkSeven },
        // { content: addMarkEight },
        // { content: addMarkNine },
        // { content: addMarkTen },
    ]

    row.push(rowValue);

    const getExam = examList.find((el) => el.id === parseInt(exam) ? el.title : '')?.title
    // const examName=getExam?.title
    console.log(getExam);
    const getDegree = degreeList.find((deg) => deg.id === parseInt(degree) ? deg.name : '')?.name

    const getDiscipline = disciplineList.find((dis) => dis.id === parseInt(discipline) ? dis.name : '')?.name

    const getCourseName = courseList.find((c) => c.id === parseInt(disciplineCourse) ? c.name : '')?.name

    const getCourseCode = courseList.find((c) => c.id === parseInt(disciplineCourse) ? c.shortName : '')?.shortName

    const date = new Date()
    const dd = date.getDate()
    const mm = date.getMonth() + 1
    const yyyy = date.getFullYear()
    const generatToday = dd + '-' + mm + '-' + yyyy
    // console.log(dd + '-' + mm + '-' + yyyy);

    const handleDownloadPDF = () => {
        const doc = new jsPDF('p', 'mm', 'a4');

        doc.addImage(imgPath, 'PNG', 25, 15, 170, 30);

        doc.setFontSize(12);
        doc.setFont('Arial', 'normal', 'bold')
        doc.text(`B.E. / B.Tech. DEGREE EXAMINATIONS - ${getExam}`, 43, 50,)

        doc.text(`Results Abstraction Sheet`, 82, 56)

        doc.text(`Degree & Discipline : ${getDegree} - ${getDiscipline}`, 15, 70)
        doc.text(`Course Name : ${getCourseName}`, 15, 77)
        doc.text(`Course Code : ${getCourseCode}`, 15, 85)
        doc.text(`Report Date : ${generatToday}`, 140, 85)

        autoTable(doc, {
            startY: 95,
            headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0] },
            bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0], halign: 'center' },
            head: [
                [
                    { content: 'Actual Pass %', styles: { halign: 'center' } },
                    { content: 'Add 1 Mark', styles: { halign: 'center' } },
                    { content: 'Add 2 Mark', styles: { halign: 'center' } },
                    { content: 'Add 3 Mark', styles: { halign: 'center' } },
                    { content: 'Add 4 Mark', styles: { halign: 'center' } },
                    { content: 'Add 5 Mark', styles: { halign: 'center' } },
                    // { content: 'Add 6 Mark', styles: { halign: 'center' } },
                    // { content: 'Add 7 Mark', styles: { halign: 'center' } },
                    // { content: 'Add 8 Mark', styles: { halign: 'center' } },
                    // { content: 'Add 9 Mark', styles: { halign: 'center' } },
                    // { content: 'Add 10 Mark', styles: { halign: 'center' } },

                    // { content: 'QP', styles: { halign: 'center' } },
                ],
            ],
            body: row,
            theme: 'grid',
            tableWidth: 'auto',
        });

        doc.setFontSize(11);
        doc.text('CONTROLLER OF EXAMINATIONS', 130, (doc as any).lastAutoTable.finalY + 40)


        doc.save(fileName + '.pdf');
    };

    const csvData = [
        [
            'Degree',
            'Discipline',
            'Exam',
            'Mark',
            'Course Code',
            'Course Name',
            // 'Date',
        ],
        ...passingResultList.map((row) => [
            row.degreeId,
            row.disciplineId,
            row.examId,
            row.totalMark,
            row.courseCode,
            row.courseName,
            // row.date,
        ]),
    ];

    return (
        <>
            <PageContainer title="Passing Report Analysis">
                <Card title=''>
                    <div className="row m-2">
                        <div className='col-sm-6 col-md-4 col-lg-4 mb-2'>

                            <label htmlFor="">Exam <span className="text-danger">*</span> :</label>
                            <select
                                className="form-control"
                                value={exam}
                                onChange={(e) => {
                                    setExam(e.target.value)
                                    setExamError('')
                                }}>
                                <option value="">Select Exam</option>
                                {examList.map((ex) => (
                                    <option value={ex.id}>{ex.title}</option>
                                ))
                                }
                            </select>

                            {examError && <div className="text-danger">{examError}</div>}

                        </div>
                        <div className='col-sm-6 col-md-4 col-lg-4 mb-2'>

                            <label htmlFor="">Degree<span className="text-danger">*</span> :</label>
                            <select
                                className="form-control"
                                value={degree}
                                onChange={(e) => {
                                    setDegree(e.target.value)
                                    setDegreeError('')
                                }}>
                                <option value="">Select Degree</option>
                                {degreeList.map((dl) => (
                                    <option value={dl.id}>{dl.name}</option>
                                ))
                                }
                            </select>
                            {degreeError && <div className="text-danger">{degreeError}</div>}

                        </div>
                        <div className='col-sm-6 col-md-4 col-lg-4 mb-2'>
                            <label htmlFor="">Discipline<span className="text-danger">*</span> :</label>
                            <select
                                className="form-control"
                                value={discipline}
                                onChange={(e) => {
                                    setDiscipline(e.target.value)
                                    setDisciplineError('')
                                }}>
                                <option value="">Select Discipline</option>
                                {disciplineList.map((dl) => (
                                    <option value={dl.id}>{dl.name}</option>
                                ))
                                }
                            </select>
                            {disciplineError && <div className="text-danger">{disciplineError}</div>}
                        </div>
                        <div className='col-sm-6 col-md-4 col-lg-4'>

                            <label htmlFor="">Course<span className="text-danger">*</span> :</label>
                            <select
                                className="form-control"
                                value={disciplineCourse}
                                onChange={(e) => {
                                    setDisciplineCourse(e.target.value)
                                    setDisciplineCourseError('')
                                }}>
                                <option value="">Select Course</option>
                                {courseList.map((dl) => (
                                    <option value={dl.id}>{dl.name}</option>
                                ))
                                }
                            </select>
                            {disciplineCourseError && <div className="text-danger">{disciplineCourseError}</div>}
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4">
                            <label htmlFor="">Batch<span className="text-danger">*</span> :</label>
                            <select
                                className="form-control"
                                value={batch}
                                onChange={(e) => {
                                    setBatch(e.target.value)
                                    setBatchError('')
                                }}>
                                <option value="">Select Batch</option>
                                {yearList.map((y, i) => (
                                    <option key={i} value={y}>{y}</option>
                                ))
                                }
                            </select>
                            {batchError && <div className="text-danger">{batchError}</div>}

                        </div>

                        <div className="col-sm-6 col-md-4 col-lg-4">
                            <label htmlFor="">Semester<span className="text-danger">*</span> :</label>
                            <select
                                className="form-control"
                                value={sem}
                                onChange={(e) => {
                                    setSem(e.target.value)
                                    setSemError('')
                                }}>
                                <option value="">Select Semester</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                            </select>
                            {semError && <div className="text-danger">{semError}</div>}
                        </div>

                    </div>
                    <div className="text-right m-4">
                        {/* <div className=" col-12 m-2"> */}
                        <button className='btn btn-sm btn-primary' onClick={handleSearch} >Search</button>
                    </div>
                </Card>

                <CollapseCard title="Passing Results">
                    <div className="container-fluid ">
                        <table className="table table-success table-striped">
                            <thead>
                                <tr>
                                    <th>S.No.</th>
                                    <th>Degree</th>
                                    <th>Discipline</th>
                                    <th>Exam</th>
                                    <th>Mark</th>
                                    <th>Course Code</th>
                                    <th>Course Name</th>
                                    {/* <th>Date</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {passingResultList.map((row, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{row.degreeId}</td>
                                        <td>{row.disciplineId}</td>
                                        <td>{row.examId}</td>
                                        <td>{row.totalMark}</td>
                                        <td>{row.courseCode}</td>
                                        <td>{row.courseName}</td>
                                        {/* <td>{LocalFormatDate(row.date?.toString())}</td> */}
                                    </tr>
                                ))
                                }

                            </tbody>
                        </table>
                    </div>
                    <div className="text-end mb-3">
                        {passingResultList.length > 0 && < button
                            type="button"
                            onClick={handleDownloadPDF}
                            className="m-1 btn btn-sm btn-primary"
                        >
                            <Feather.Download /> PDF
                        </button>}
                        {passingResultList.length > 0 && <CSVLink
                            data={csvData}
                            filename="details_of_course.csv"
                            className="m-1 btn btn-sm btn-primary"
                        >
                            <Feather.Download /> CSV
                        </CSVLink>}
                    </div>
                </CollapseCard>
            </PageContainer >
        </>
    );
}

export default PassingResultAnalysis

export interface PassingResultType {
    id?: number
    examId: string
    degreeId: string
    disciplineId: number
    semester: string
    courseCode: string
    courseName: string
    totalMark: number
    batch: string
}