import React, { FC, useState } from "react";
import * as Feather from 'react-feather';
import { Link } from 'react-router-dom';

export interface MenuItemTypes {
    key: string;
    label: string;
    isTitle?: boolean;
    Icon?: Feather.Icon;
    url?: string;
    badge?: {
        variant: string;
        text: string;
    };
    parentKey?: string;
    target?: string;
    children?: MenuItemTypes[];
}

const MENU_ITEMS: MenuItemTypes[] = [

    // {
    //     key: 'adminDashboard',
    //     label: 'Dashboard',
    //     isTitle: false,
    //     Icon: Feather.Dribbble,
    //     url: '/adminDashboard',
    // },
    {
        key: 'examregister',
        label: 'Exam Register',
        isTitle: false,
        Icon: Feather.PenTool,
        url: '/examregister',
    },
    {
        key: 'examtimetable',
        label: 'Exam Timetable',
        isTitle: false,
        Icon: Feather.Calendar,
        url: '/examtimetable',
    },
    {
        key: 'hallplan',
        label: 'Hall Seating Plan',
        isTitle: false,
        Icon: Feather.Grid,
        url: '/hallplan',
    },
    {
        key: 'examattendance',
        label: 'Exam Attendance',
        isTitle: false,
        Icon: Feather.CheckSquare,
        url: '/examattendance',
    },
    {
        key: 'studentarrear',
        label: 'Student Arrear',
        isTitle: false,
        Icon: Feather.FileText,
        url: '/studentarrear',
    },

    {
        key: 'assessment',
        label: 'Student Assessment',
        isTitle: false,
        Icon: Feather.Activity,
        url: '/assessment',
    },
    // {
    //     key: 'attendence',
    //     label: 'Student Attendance',
    //     isTitle: false,
    //     Icon: Feather.BarChart2,
    //     url: '/attendence',
    // },

    {
        key: 'packinggalley',
        label: 'Packing Galley',
        isTitle: false,
        Icon: Feather.Package,
        url: '/packinggalley',
    },
    {
        key: 'passingboard',
        label: 'Passing Board',
        isTitle: false,
        Icon: Feather.Clipboard,
        url: '/passingboard',
    },
    {
        key: 'dummynogeneration',
        label: 'Dummy No. Generation',
        isTitle: false,
        Icon: Feather.Hash,
        url: '/dummynogeneration',
    },
    {
        key: 'result',
        label: 'Result',
        isTitle: false,
        Icon: Feather.Award,
        children: [
            {
                key: 'studentmark',
                label: 'Theory',
                url: '/studentmark',
                Icon: Feather.Repeat,
                parentKey: 'result',
            },
            {
                key: 'studentmarkpracticle',
                label: 'Practical',
                url: '/studentmarkpracticle',
                Icon: Feather.Repeat,
                parentKey: 'result',
            },
            {
                key: 'universityGrade',
                label: 'university Grade',
                url: '/universityGrade',
                Icon: Feather.Repeat,
                parentKey: 'result',
            },
        ]
    },
    {
        key: 'revaluation',
        label: 'Revaluation',
        isTitle: false,
        Icon: Feather.Edit,
        children: [
            {
                key: 'revaluationapply',
                label: 'Apply',
                url: '/revaluationapply',
                Icon: Feather.Repeat,
                parentKey: 'result',
            },
            {
                key: 'revaluationmark',
                label: 'Mark Entry',
                url: '/revaluationmark',
                Icon: Feather.Repeat,
                parentKey: 'result',
            },
        ]
    },
    // {
    //     key: 'studentMark',
    //     label: 'Result',
    //     isTitle: false,
    //     Icon: Feather.FileText,
    //     url: '/studentMark',
    // },
    {
        key: 'master',
        label: 'Master',
        isTitle: false,
        Icon: Feather.Book,
        children: [
            {
                key: 'college',
                label: 'College',
                isTitle: false,
                Icon: Feather.Home,
                url: '/college',
                parentKey: 'master',
            },
            {
                key: 'course',
                label: 'Course',
                isTitle: false,
                Icon: Feather.BookOpen,
                url: '/course',
                parentKey: 'master',
            },
            {
                key: 'degree',
                label: 'Degree',
                isTitle: false,
                Icon: Feather.Bookmark,
                url: '/degree',
                parentKey: 'master',
            },
            {
                key: 'discipline',
                label: 'Discipline',
                isTitle: false,
                Icon: Feather.Award,
                url: '/discipline',
                parentKey: 'master',
            },
            {
                key: 'regulation',
                label: 'Regulation',
                isTitle: false,
                Icon: Feather.Calendar,
                url: '/regulation',
                parentKey: 'master',
            },
            {
                key: 'disciplinecourse',
                label: 'Discipline Course',
                isTitle: false,
                Icon: Feather.Codesandbox,
                url: '/disciplinecourse',
                parentKey: 'master',
            },
            {
                key: 'exam',
                label: 'Exam',
                isTitle: false,
                Icon: Feather.Book,
                url: '/exam',
                parentKey: 'master',
            },
            {
                key: 'student',
                label: 'Student',
                isTitle: false,
                Icon: Feather.Users,
                url: '/student',
                parentKey: 'master',
            },
            {
                key: 'phase',
                label: 'Phase',
                isTitle: false,
                Icon: Feather.Lock,
                url: '/phase',
                parentKey: 'master',
            },
            {
                key: 'faculty',
                label: 'Faculty',
                isTitle: false,
                Icon: Feather.UserPlus,
                url: '/faculty',
                parentKey: 'master',
            },
            {

                key: 'dropDownMaster',
                label: 'DropDown Master',
                isTitle: false,
                Icon: Feather.ChevronsDown,
                url: '/dropdownMaster',
                parentKey: 'master',
            },
            {
                key: 'hall',
                label: 'Hall',
                isTitle: false,
                Icon: Feather.Target,
                url: '/hall',
                parentKey: 'master',
            },
            {
                key: 'user',
                label: 'User',
                isTitle: false,
                Icon: Feather.User,
                url: '/user',
                parentKey: 'master',
            },
            {
                key: 'examFaculty',
                label: 'Exam Faculty',
                isTitle: false,
                Icon: Feather.Disc,
                url: '/examFaculty',
            },

            {
                key: 'practicalExamination',
                label: 'Practical Examination',
                isTitle: false,
                Icon: Feather.Disc,
                url: '/practicalExamination',
            },
            {
                key: 'updateCurrentSemester',
                label: 'Update Semester',
                isTitle: false,
                Icon: Feather.Disc,
                url: '/updateCurrentSemester',
            },
        ]
    },
    {
        key: 'report',
        label: 'Report',
        isTitle: false,
        Icon: Feather.Printer,
        children: [
            {
                key: 'coursereport',
                label: 'Details of Course',
                url: '/coursereport',
                Icon: Feather.Repeat,
                parentKey: 'report',
            },
            {
                key: 'examreport',
                label: 'Exam Fees',
                url: '/feesreport',
                Icon: Feather.DollarSign,
                parentKey: 'report',
            },
            {
                key: 'studentassessmentreport',
                label: 'Students Assessment ',
                url: '/assessmentreport',
                Icon: Feather.DollarSign,
                parentKey: 'report',
            },
            {
                key: 'studentattendancereport',
                label: 'Student Attendance ',
                url: '/attendancereport',
                Icon: Feather.DollarSign,
                parentKey: 'report',
            },
            {
                key: 'examregistrationreport',
                label: 'Exam Registration',
                url: '/registrationreport',
                Icon: Feather.DollarSign,
                parentKey: 'report',
            },
            {
                key: 'candidatesreport',
                label: 'Registered Candidates ',
                url: '/candidatesreport',
                Icon: Feather.DollarSign,
                parentKey: 'report',
            },
            {
                key: 'attendancesheetreport',
                label: 'Attendance Sheet ',
                url: '/attendancesheetreport',
                Icon: Feather.DollarSign,
                parentKey: 'report',
            },
            {
                key: 'choicereport',
                label: 'Exam Timetable',
                url: '/choicereport',
                Icon: Feather.DollarSign,
                parentKey: 'report',
            },
            {
                key: 'overallseatarrangementreport',
                label: 'Overall Seating Arrangement',
                url: '/overallseatarrangementreport',
                Icon: Feather.DollarSign,
                parentKey: 'report',
            },
            {
                key: 'seatingarrangementreport',
                label: 'Seating Arrangement',
                url: '/seatingarrangementreport',
                Icon: Feather.DollarSign,
                parentKey: 'report',
            },
            {
                key: 'hallticketreport',
                label: 'Hall Ticket',
                url: '/hallticketreport',
                Icon: Feather.DollarSign,
                parentKey: 'report',
            },
            {
                key: 'marksheetreport',
                label: 'Marksheet',
                url: '/marksheetreport',
                Icon: Feather.DollarSign,
                parentKey: 'report',
            },
            {
                key: 'coverreport',
                label: 'Cover Report',
                url: '/coverreport',
                Icon: Feather.DollarSign,
                parentKey: 'report',
            },
            {
                key: 'cumulativecoverreport',
                label: 'Cumulative Cover Report',
                url: '/cumulativecoverreport',
                Icon: Feather.DollarSign,
                parentKey: 'report',
            },
            {
                key: 'dispatchreport',
                label: 'Dispatch Report',
                url: '/dispatchreport',
                Icon: Feather.DollarSign,
                parentKey: 'report',
            },
            {
                key: 'passingResultAnalysis',
                label: 'Passing Result Analysis',
                url: '/passingResultAnalysis',
                Icon: Feather.DollarSign,
                parentKey: 'report',
            },
            {
                key: 'attendanceResultReport',
                label: 'Attendance Result',
                url: '/attendanceResultReport',
                Icon: Feather.DollarSign,
                parentKey: 'report',
            },
            {
                key: 'dummynoreport',
                label: 'Dummy No. Report',
                url: '/dummynoreport',
                Icon: Feather.DollarSign,
                parentKey: 'report',
            },
            {
                key: 'markReport',
                label: 'Mark Report',
                url: '/markReport',
                Icon: Feather.DollarSign,
                parentKey: 'report',
            },
            {
                key: 'practicalMarkReport',
                label: 'Practical Mark Report',
                url: '/practicalMarkReport',
                Icon: Feather.DollarSign,
                parentKey: 'report',
            },
            {
                key: 'practicalexaminationreport',
                label: 'Practical Examination Report',
                url: '/practicalexaminationreport',
                Icon: Feather.DollarSign,
                parentKey: 'report',
            },
            {
                key: 'examfacultyreport',
                label: 'Exam Faculty Report',
                url: '/examfacultyreport',
                Icon: Feather.DollarSign,
                parentKey: 'report',
            },

        ]
    }
];


const SideNavBar: FC = () => {

    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            {
                // Brand Logo 
            }
            {/* <Link to="../../index3.html" className="brand-link">
          <img src="img/AdminLTELogo.png" alt="AdminLTE Logo" className="brand-image img-circle elevation-3" style={{opacity: .8 }}/>
          <span className="brand-text font-weight-light">AdminLTE 3</span>
        </Link> */}

            {
                // Sidebar 
            }
            <div className="sidebar">
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                        <img src="img/user.jpg" className="img-circle elevation-2" alt="User" />
                    </div>
                    <div className="info">
                        <Link to="#" className="d-block">Controller Of Exam</Link>
                    </div>
                </div>
                {/* 
            SidebarSearch Form 
          <div className="form-inline">
            <div className="input-group" data-widget="sidebar-search">
              <input className="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search" />
              <div className="input-group-append">
                <button className="btn btn-sidebar">
                  <i className="fas fa-search fa-fw"></i>
                </button>
              </div>
            </div>
          </div> */}

                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" data-accordion="false">
                        {MENU_ITEMS?.map((menuItem, index) => {
                            const { Icon } = menuItem
                            return (
                                <React.Fragment key={menuItem.key}>
                                    {menuItem.children ?
                                        // <li key={index} className="nav-item">
                                        //     <Link to={"#"} className="nav-link" onClick={handleToggleMenuClick}>
                                        //         {Icon && <Icon style={{ marginRight: "5px" }} />}
                                        //         <p>{menuItem.label}<i className="right fas fa-angle-left"></i></p>
                                        //     </Link>
                                        //     <ul className="nav nav-treeview">
                                        //         {
                                        //             menuItem.children?.map((childItem, index2) => {
                                        //                 return (
                                        //                     <li className="nav-item" key={index2}>
                                        //                         <Link to={childItem.url || '#'} className="nav-link">
                                        //                             <i className="fas fa-angle-right nav-icon"></i><p>{childItem.label}</p>
                                        //                         </Link>
                                        //                     </li>
                                        //                 )
                                        //             })
                                        //         }
                                        //     </ul>
                                        // </li>
                                        <MenuItem
                                            menuItem={menuItem}
                                        />

                                        :
                                        <li className="nav-item">
                                            <Link to={menuItem.url || ''} className="nav-link">
                                                {Icon && <Icon style={{ marginRight: "5px" }} />}
                                                <p>
                                                    {menuItem.label}
                                                    {/* <span className="right badge badge-danger">New</span> */}
                                                </p>
                                            </Link>
                                        </li>}
                                </React.Fragment>
                            )
                        })
                        }
                        <li className="nav-item">
                            <Link to={"/logout"} className="nav-link">
                                <Feather.LogOut style={{ marginRight: "5px" }} />
                                <p>
                                    Logout
                                    {/* <span className="right badge badge-danger">New</span> */}
                                </p>
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </aside >
    )
}

const MenuItem = (props: { menuItem: MenuItemTypes }) => {
    const { Icon } = props.menuItem
    const [showSubMenu, setShowSubMenu] = useState<boolean>(false)

    const handleToggleMenuClick = () => {
        // let browser = window.navigator.userAgent
        // let condition = browser.match(/chrome|chromium|crios/i) && !browser.match(/Edg/i)
        // if (!condition) {
        //     setShowSubMenu(!showSubMenu)
        // }
        setShowSubMenu(!showSubMenu)

    }

    return <>
        <li className="nav-item">
            <Link to={"#"} className="nav-link" onClick={handleToggleMenuClick}>
                {Icon && <Icon style={{ marginRight: "5px" }} />}
                <p>{props.menuItem.label}<i className={!showSubMenu ? "right fas fa-angle-left" : 'right fas fa-angle-down'}></i></p>
            </Link>
            <ul className="nav nav-treeview" style={{ display: showSubMenu ? 'block' : 'none' }}>
                {
                    props.menuItem.children?.map((childItem, index2) => {
                        return (
                            <li className="nav-item" key={index2}>
                                <Link to={childItem.url || '#'} className="nav-link">
                                    <i className="fas fa-angle-right nav-icon"></i><p>{childItem.label}</p>
                                </Link>
                            </li>
                        )
                    })
                }
            </ul>
        </li>
    </>
}
export default SideNavBar;