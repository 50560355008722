import axios from "axios";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import * as Feather from 'react-feather';
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Card from "../components/custom/Card";
import CollapseCard from "../components/custom/CollapseCard";
import PageContainer from "../components/custom/PageContainer";
import { apiUrl } from '../config';
import { LocalFormatDate } from "../config/functions";
import { DegreeType } from "../redux/actions/degreeAction";
import { DisciplineType } from "../redux/actions/disciplineAction";
import { StoreState } from "../redux/reducers";
import imgPath from './img/collegeLogo.png';

interface Props {
    title: string;
}

interface ExamTimetableReportType {
    date: string
    session: string
    sem: string
    courseName: string
    courseCode: string
    degreeName: string
    disciplineName: string
}

interface Exam {
    id: number;
    title: string;
}

interface Regulation {
    id: number,
    title: number
}

export const ExamTimetableReport = (props: Props) => {
    const fileName = "exam_timetable_report";
    const [examTimetableData, setExamTimetableData] = useState<ExamTimetableReportType[]>([]);

    const [RegulationList, setRegulationList] = useState<Regulation[]>([]);
    const [ExamList, setExamList] = useState<Exam[]>([])

    const [selectedRegulation, setSelectedRegulation] = useState<string>('');
    const [selectedExamId, setSelectedExamId] = useState<string>('')

    const disciplineList = useSelector<StoreState, DisciplineType[]>(state => state.discipline)
    const [selectedDisciplineId, setSelectedDisciplineId] = useState<string>('');
    const [disciplineIdError, setDisciplineIdError] = useState('');

    const degreeList = useSelector<StoreState, DegreeType[]>(state => state.degree);
    const [selectedDegreeId, setSelectedDegreeId] = useState('');
    const [degreeIdError, setDegreeIdError] = useState('');


    const handleSearch = () => {
        if (selectedExamId !== '' && selectedRegulation !== '') {
            const degId = selectedDegreeId ? selectedDegreeId : null;
            const disId = selectedDisciplineId ? selectedDisciplineId : null;
            axios.get(`${apiUrl}/examTimetable/report/?examId=${selectedExamId}&regulation=${selectedRegulation}&degreeId=${degId}&disciplineId=${disId}`)
                .then((response) => {
                    setExamTimetableData(response.data.data);
                })
                .catch((error) => {
                    console.error('Error fetching examTimetableData', error);
                });
        } else {
            toast.error('Select Exam and Regulation Filters', { position: 'top-right', autoClose: 3000 });

            setExamTimetableData([])
        }
    }

    // const timeTable = [
    //     {
    //         "degreeDiscipline": "B.E Aeronautical Engineering",
    //         "semester": "01",
    //         "courseCode": "HS3151",
    //         "courseTitle": "Professional English-1",
    //         "examDate": "12-06-24",
    //         "Session": "FN"
    //     },
    //     {
    //         "degreeDiscipline": "B.E Aeronautical Engineering",
    //         "semester": "01",
    //         "courseCode": "CY3151",
    //         "courseTitle": "Engineering chemistry",
    //         "examDate": "14-06-24",
    //         "Session": "AN"
    //     },
    //     {
    //         "degreeDiscipline": "B.E Aeronautical Engineering",
    //         "semester": "01",
    //         "courseCode": "PH3151",
    //         "courseTitle": "Engineering physics",
    //         "examDate": "15-06-24",
    //         "Session": "FN"
    //     },
    //     {
    //         "degreeDiscipline": "B.E Civil Engineering",
    //         "semester": "01",
    //         "courseCode": "HS3151",
    //         "courseTitle": "Professional English-1",
    //         "examDate": "12-06-24",
    //         "Session": "FN"
    //     },
    //     {
    //         "degreeDiscipline": "B.E Civil Engineering",
    //         "semester": "01",
    //         "courseCode": "CY3151",
    //         "courseTitle": "Engineering chemistry",
    //         "examDate": "14-06-24",
    //         "Session": "AN"
    //     },
    //     {
    //         "degreeDiscipline": "B.E Civil Engineering",
    //         "semester": "01",
    //         "courseCode": "PH3151",
    //         "courseTitle": "Engineering physics",
    //         "examDate": "15-06-24",
    //         "Session": "FN"
    //     },
    //     {
    //         "degreeDiscipline": "B.E Computer Science and Engineering",
    //         "semester": "01",
    //         "courseCode": "HS3151",
    //         "courseTitle": "Professional English-1",
    //         "examDate": "12-06-24",
    //         "Session": "FN"
    //     },
    //     {
    //         "degreeDiscipline": "B.E Computer Science and Engineering",
    //         "semester": "01",
    //         "courseCode": "CY3151",
    //         "courseTitle": "Engineering chemistry",
    //         "examDate": "14-06-24",
    //         "Session": "AN"
    //     },
    //     {
    //         "degreeDiscipline": "B.E Computer Science and Engineering",
    //         "semester": "01",
    //         "courseCode": "PH3151",
    //         "courseTitle": "Engineering physics",
    //         "examDate": "15-06-24",
    //         "Session": "FN"
    //     }
    // ]

    // const degree = [
    //     {
    //         "degreeDiscipline": "B.E Aeronautical Engineering"
    //     },
    //     {
    //         "degreeDiscipline": "B.E Civil Engineering"
    //     },
    //     {
    //         "degreeDiscipline": "B.E Computer Science and Engineering"
    //     }
    // ]


    const csvData = [
        ['S.No', 'Discipline Name', 'Semester', 'Course Code', 'Course Title', 'Exam Date', 'Session']
    ]

    examTimetableData.forEach((e, i) => {
        csvData.push([(i + 1).toString(), e.disciplineName, e.sem, e.courseCode, e.courseName, e.date, e.session])
    })

    const uniqueDegreeDiscipline = examTimetableData.map(m => m.disciplineName).filter(function (v, i, self) {
        // It returns the index of the first
        // instance of each value
        return i === self.indexOf(v);
    });

    const handleDownloadPDF = () => {
        const doc = new jsPDF('p', 'mm', 'a4');
        const examObj = ExamList.find(f => f.id?.toString() === selectedExamId)
        doc.addImage(imgPath, 'PNG', 20, 10, 170, 40);

        doc.setFont('Arial', 'normal', 'bold');
        doc.setFontSize(10)
        doc.text(`TIME TABLE-B.E. / B.Tech. DEGREE EXAMINATIONS -${examObj?.title}`, 35, 60)
        doc.text(`(REGULATIONS-${selectedRegulation})`, 78, 67)
        doc.text(`CHOICE BASED CREDIT SYSTEM (CBCS)`, 64, 74)

        for (let d = 0; d < uniqueDegreeDiscipline.length; d++) {
            autoTable(doc, {
                startY: d === 0 ? 78 : (doc as any).lastAutoTable.finalY + 10,
                headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0], fontSize: 8 },
                bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0], fontSize: 8 },

                head: [
                    [
                        { content: `Degree & Discipline Name: ${uniqueDegreeDiscipline[d]}`, colSpan: 5 }
                    ],
                    [
                        { content: "Semester", styles: { halign: 'center' } },
                        { content: "Course Code", styles: { halign: 'center' } },
                        { content: "Course Title", styles: { halign: 'center' } },
                        { content: "Exam Date", styles: { halign: 'center' } },
                        { content: "Session", styles: { halign: 'center' } },
                    ],
                ],

                body: examTimetableData?.filter((tt) => tt.disciplineName === uniqueDegreeDiscipline[d]).map((t) => (
                    [
                        { content: t.sem, styles: { halign: 'center' } },
                        { content: t.courseCode, styles: { halign: 'center' } },
                        { content: t.courseName },
                        { content: LocalFormatDate(t.date), styles: { halign: 'center' } },
                        { content: t.session, styles: { halign: 'center' } }
                    ]
                ))
            })

        }

        doc.setFontSize(9)
        doc.setFont('Arial', 'normal', 'bold');
        doc.text("FN: FORENOON (10.00 AM to 01.00 PM)", 15, (doc as any).lastAutoTable.finalY + 10)
        doc.text("AN: AFTERNOON (02.00 PM to 05.00 PM)", 15, (doc as any).lastAutoTable.finalY + 15)

        doc.text('CONTROLLER OF EXAMINATIONS', 132, (doc as any).lastAutoTable.finalY + 35)

        doc.save(fileName + '.pdf');

    }

    useEffect(() => {
        axios.get(`${apiUrl}/regulation/`).then((response) => {
            setRegulationList(response.data.data);
        }).catch((error) => {
            console.error('Error fetching regulation', error);
        });
    }, [])

    useEffect(() => {
        axios.get(`${apiUrl}/exam/`)
            .then((response) => {
                setExamList(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching Exam', error);
            });
    }, [])

    return (
        <>
            <PageContainer title="Exam Timetable Report">
                <Card title="filter">
                    <div className="row">
                        <div className="col-md-4">
                            <label className="mt-3 mb-3">Exam <span className="text-danger">*</span> :</label>
                            <select
                                className="form-control"
                                value={selectedExamId || ''}
                                onChange={(e) => setSelectedExamId(e.target.value)}
                            >
                                <option value="">Select Exam</option>
                                {ExamList.map((ex) => (
                                    <option key={ex.id} value={ex.id}>
                                        {ex.title}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-4">
                            <label className="mt-3 mb-3">Regulation <span className="text-danger">*</span> :</label>
                            <select
                                className="form-control"
                                value={selectedRegulation || ''}
                                onChange={(e) => setSelectedRegulation(e.target.value)}
                            >
                                <option value="">Select Regulation</option>
                                {RegulationList.map((reg) => (
                                    <option key={reg.id} value={reg.title}>
                                        {reg.title}

                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-4">
                            <label className="mt-3 mb-3">Degree : </label>
                            <select
                                className="form-control"
                                style={{ width: "100%", marginRight: "20px" }}
                                name="selectedDegree"
                                value={selectedDegreeId}
                                onChange={(e) => setSelectedDegreeId(e.target.value)}
                            >
                                <option value="" selected>Select Degree</option>
                                {degreeList.map((course) => (
                                    <option key={course.id} value={course.id}>
                                        {course.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="col-md-4">
                            <label className="mt-3 mb-3">Discipline : </label>
                            <select
                                className="form-control"
                                style={{ width: "100%", marginRight: "20px", }}
                                name="selectedDiscipline"
                                value={selectedDisciplineId}
                                onChange={(e) => setSelectedDisciplineId(e.target.value)}
                            >
                                <option value="" selected>Select Discipline</option>
                                {disciplineList.map((course) => (
                                    <option key={course.id} value={course.id}>
                                        {course.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {/* <div className="col-lg-12 col-sm-12"> */}
                        <div className=" col-md-4 ">
                            <button className='btn btn-sm btn-primary' onClick={handleSearch} style={{ width: "32%", marginTop: "60px" }}>Search</button>
                        </div>
                        {/* </div> */}
                    </div>

                </Card>
                < CollapseCard title="" >
                    <div className="container-fluid table-container" style={{ width: "150%" }}>
                        <table className="table table-success table-striped" style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Discipline Name</th>
                                    <th>Semester</th>
                                    <th>Course Code</th>
                                    <th>Course Title</th>
                                    <th>Exam Date</th>
                                    <th>Session</th>
                                </tr>
                            </thead>
                            <tbody>
                                {examTimetableData.map((tt, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{tt.disciplineName}</td>
                                        <td>{tt.sem}</td>
                                        <td>{tt.courseCode}</td>
                                        <td>{tt.courseName}</td>
                                        <td>{LocalFormatDate(tt.date)}</td>
                                        <td>{tt.session}</td>
                                    </tr>
                                ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="text-end mb-3">
                        {examTimetableData.length > 0 && <button
                            type="button"
                            onClick={handleDownloadPDF}
                            className="m-1 btn btn-sm btn-primary"
                        >
                            <Feather.Download /> PDF
                        </button>}
                        {examTimetableData.length > 0 && <CSVLink
                            data={csvData}
                            filename="exam_timetable_report.csv"
                            className="m-1 btn btn-sm btn-primary"
                        >
                            <Feather.Download /> CSV
                        </CSVLink>}
                    </div>
                </CollapseCard >
            </PageContainer >
        </>
    )
}
