import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { GetState } from "../reducers"


export interface StudentMarkType {
    id?: number
    examId: number
    degreeId: number
    disciplineId: number
    semester: number
    batch?: string
    registerNumber: string
    dummyNumber?: string
    disciplineCourseId: number
    questionPaperCode: string
    intMark?: number
    extMark?: number
    intConvertedMark?: number
    extConvertedMark?: number
    totConvertedMark?: number
    adjustedMark?: number
    grade?: number
    gradeText?: string
    unvGrade?: number
    unvGradeText?: string
    intExaminerId?: number
    extExaminerId?: number
    chiefExaminerId?: number
}

export enum StudentMarkActionList {
    ADD_STUDENTMARK = 'ADD_STUDENTMARK',
    EDIT_STUDENTMARK = 'EDIT_STUDENTMARK',
    FETCH_STUDENTMARK = 'FETCH_STUDENTMARK',
    DELETE_STUDENTMARK = 'DELETE_STUDENTMARK'
}

export interface AddStudentMarkAction {
    type: StudentMarkActionList.ADD_STUDENTMARK;
    data: StudentMarkType
}

export interface EditStudentMarkAction {
    type: StudentMarkActionList.EDIT_STUDENTMARK
    data: StudentMarkType
    id: number | undefined
}

export interface FetchStudentMarkAction {
    type: StudentMarkActionList.FETCH_STUDENTMARK;
    data: StudentMarkType[]

}
export interface DeleteStudentMarkAction {
    type: StudentMarkActionList.DELETE_STUDENTMARK
    data: number | undefined
}

export type StudentMarkActions = AddStudentMarkAction | EditStudentMarkAction | FetchStudentMarkAction | DeleteStudentMarkAction;


export const addStudentMarkAction = (data: StudentMarkType) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.post<APIResponse<StudentMarkType>>('studentMark/', data, config).then(response => {
            if (response.status === 200 && response.data.data) {

                dispatch<AddStudentMarkAction>({
                    type: StudentMarkActionList.ADD_STUDENTMARK,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'StudentMark Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })
    }
}

export const editStudentMarkAction = (data: StudentMarkType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.put<APIResponse<StudentMarkType>>(`studentMark/?id=${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<EditStudentMarkAction>({
                    type: StudentMarkActionList.EDIT_STUDENTMARK,
                    data: response.data.data,
                    id: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Brand Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchStudentMarkAction = () => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<StudentMarkType[]>>('studentMark/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchStudentMarkAction>({
                    type: StudentMarkActionList.FETCH_STUDENTMARK,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteStudentMarkAction = (id: number | undefined) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.delete<APIResponse<StudentMarkType>>(`studentMark/?id=${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteStudentMarkAction>({
                    type: StudentMarkActionList.DELETE_STUDENTMARK,
                    data: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'StudentMark Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}