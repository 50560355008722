import axios from 'axios';
import { FC, useEffect, useState } from 'react';
import Card from '../components/custom/Card';
import CollapseCard from '../components/custom/CollapseCard';
import PageContainer from '../components/custom/PageContainer';
// import './styles/tableReport.css';

import jsPDF from 'jspdf';
import autoTable, { RowInput } from 'jspdf-autotable';
import { CSVLink } from 'react-csv';
import * as Feather from 'react-feather';
import { toast } from 'react-toastify';
import { apiUrl } from '../config';
import imgPath from './img/collegeLogo.png';

interface Props {
    title: string;
}

interface ExamRegister {
    id: number;
    registerNo: string;
    regulation: number;
    degreeName: string;
    disciplineName: string;
    dateOfBirth: string;
    examName: string;
    name: string;
    contactNumber: number;
    currentSemester: number;
    totalCourse: string;
    totalFees: string;
    disciplineCourseDetails: {
        courseName: string;
        courseCode: string;
    }[];
    // disciplineName: string;
    // degreeName: string;
}

const ExamRegistrationReport: FC<Props> = (props: Props) => {

    const { title } = props;
    const fileName = "exam_registration_report";

    const [examRegisterList, setExamRegisterList] = useState<ExamRegister[]>([]);
    const [degree, setDegree] = useState('');
    const [discipline, setDiscipline] = useState('');
    const [semester, setSemester] = useState('');
    const [exam, setExam] = useState('')

    const [selectedExam, setSelectedExam] = useState<Exam[]>([])
    const [selectedDegree, setSelectedDegree] = useState<Degree[]>([]);
    const [selectedDiscipline, setSelectedDiscipline] = useState<Discipline[]>([]);
    const [selectedStudent, setSelectedStudent] = useState<Student[]>([]);

    const [searchPerformed, setSearchPerformed] = useState<boolean>(false);

    const handleSearch = () => {
        if (degree !== '' && discipline !== '' && semester !== '') {
            axios.get(`${apiUrl}/examregister/?examId=${exam}&degreeId=${degree}&disciplineId=${discipline}&currentSemester=${semester}`)
                .then((response) => {
                    if (response.data.error) {
                        setExamRegisterList([])
                        toast.error('no data found')
                    } else {
                        setExamRegisterList(response.data);
                        console.log(response.data);
                    }
                })
                .catch((error) => console.log(error));
        } else toast.error('Select All Filters')
    }

    const renderDownloadButtons = () => {
        if (searchPerformed) {
            return (
                <div className="text-end mb-3">
                    <button
                        type="button"
                        onClick={handleDownloadPDF}
                        className="m-1 btn btn-sm btn-primary"
                    >
                        <Feather.Download /> PDF
                    </button>
                    <CSVLink
                        data={csvData}
                        filename="details_of_course.csv"
                        className="m-1 btn btn-sm btn-primary"
                    >
                        <Feather.Download /> CSV
                    </CSVLink>
                </div>
            );
        }
        return null;
    };

    useEffect(() => {
        axios.get(`${apiUrl}/exam/`)
            .then((response) => {
                setSelectedExam(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching Exam', error);
            });
    }, [])

    useEffect(() => {
        axios.get(`${apiUrl}/degree/`)
            .then((response) => {
                setSelectedDegree(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching degree', error);
            });
    }, [])

    useEffect(() => {
        axios.get(`${apiUrl}/discipline/`)
            .then((response) => {
                setSelectedDiscipline(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching discipline', error);
            });
    }, [])

    useEffect(() => {
        axios.get(`${apiUrl}/student/`)
            .then((response) => {
                setSelectedStudent(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching student', error);
            });
    }, [])

    const handleDownloadPDF = () => {
        const doc = new jsPDF('p', 'mm', 'a4');

        // doc.addImage(imgPath, 'PNG', 25, 10, 170, 40);

        doc.setFontSize(16);
        doc.setFont('Arial', 'normal', 'bold');
        doc.setTextColor(0, 0, 0);
        // doc.text(title, 107, 10, { align: 'center' });
        let finalY = 0;

        examRegisterList.sort((a, b) => b.currentSemester - a.currentSemester).forEach((row, index) => {

            if (index !== 0) {
                //  && index !== examRegisterList.length - 1
                doc.addPage();
            }

            doc.setFontSize(16);
            doc.text(title, 107, 10, { align: 'center' });

            const headerRows: RowInput[] = [];
            headerRows.push(
                [
                    // { content: "College Name & Code", styles: { halign: "center", } },
                    // { content: "Parisutham Institute of Technology and Science & 8213 ", styles: { halign: "center", fontStyle: 'normal' } },
                    { content: "Register Number" },
                    { content: row.registerNo, styles: { fontStyle: 'normal' } },
                    { content: "Name of the Candidate" },
                    { content: row.name, styles: { fontStyle: 'normal' } },
                ],
                [
                    { content: "Date of Birth" },
                    { content: row.dateOfBirth, styles: { fontStyle: 'normal' } },
                    { content: "Regulations" },
                    { content: row.regulation, styles: { fontStyle: 'normal' } },
                ],
                [
                    { content: "Degree & Discipline" },
                    { content: `${row.degreeName} - ${row.disciplineName}`, styles: { fontStyle: 'normal' }, colSpan: 3 },
                ],
            );

            //head
            const firstTableHeight = autoTable(doc, {
                didDrawPage: (data) => {
                    doc.setFontSize(12);
                    doc.setFont('normal');
                    doc.addImage(imgPath, 'PNG', 25, 13, 170, 34);
                    doc.text(`Registration for ${row.examName}`, data.settings.margin.left + 45, 48);
                    doc.rect(14, 12, 182, 71,)
                    doc.setLineWidth(10);
                    doc.setDrawColor(0, 0, 0);
                    doc.stroke();
                },
                startY: 52,
                headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0], fontSize: 8 },
                bodyStyles: { textColor: [0, 0, 0], lineColor: [0, 0, 0] },
                head: headerRows,
                theme: 'grid',
            });

            const secondTableHeaderRows: RowInput[] = [
                [
                    { content: "Sem.", styles: { halign: "center" } },
                    { content: "Course Code", styles: { halign: "center" } },
                    { content: "Course Title", styles: { halign: "center" } },
                ],
            ];
            const bodyData: RowInput[] = row.disciplineCourseDetails
                ? row.disciplineCourseDetails.slice(0, 15).map((course, index) => [
                    { content: row.currentSemester, styles: { halign: 'center' } },
                    { content: course.courseCode, styles: { halign: 'center' } },
                    { content: course.courseName, styles: { halign: 'left' } },
                ])
                : [];
            const emptyRowsCount = 15 - bodyData.length;
            for (let i = 0; i < emptyRowsCount; i++) {
                bodyData.push([
                    { content: '', styles: { halign: 'left' } },
                    { content: '', styles: { halign: 'left' } },
                    { content: '', styles: { halign: 'left' } },
                ]);
            }
            const headTableFinalY = (doc as any).lastAutoTable.finalY
            //left
            const secondLeftTableHeight = autoTable(doc, {
                willDrawCell: (data) => {
                    const fullHeight = 170;
                    doc.setLineWidth(0.2)
                    doc.line(14, headTableFinalY, 14, headTableFinalY + fullHeight)
                    doc.line(27, headTableFinalY, 27, headTableFinalY + fullHeight)
                    doc.line(47, headTableFinalY, 47, headTableFinalY + fullHeight)
                    // doc.line(74, headTableFinalY, 74, headTableFinalY + fullHeight)
                    // doc.line(94, headTableFinalY, 94, headTableFinalY + fullHeight)
                    // doc.line(196, topTablefinalY, 196, topTablefinalY + fullHeight)
                },
                startY: headTableFinalY,
                headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0], fontSize: 9 },
                bodyStyles: { textColor: [0, 0, 0], lineColor: [0, 0, 0] },
                head: secondTableHeaderRows,
                body: bodyData,
                theme: 'plain',
                margin: { top: 10 },
                tableWidth: 91,
                columnStyles: {
                    0: { cellWidth: 13, minCellHeight: 9.8, fontSize: 8.2 },
                    1: { cellWidth: 20, minCellHeight: 9.8, fontSize: 8.2 },
                    2: { cellWidth: 58, minCellHeight: 9.8, fontSize: 8.2 },
                }
            });

            const secondRightTableHeaderRows: RowInput[] = [
                [
                    { content: "Sem.", styles: { halign: "center" } },
                    { content: "Course Code", styles: { halign: "center" } },
                    { content: "Course Title", styles: { halign: "center" } },
                ],
            ];
            const secondRightBodyData = row.disciplineCourseDetails
                ? row.disciplineCourseDetails.slice(15, 30).map((course, index) => [
                    { content: row.currentSemester, styles: { halign: 'left' } as { halign: 'left' } },
                    { content: course.courseCode, styles: { halign: 'left' } as { halign: 'left' } },
                    { content: course.courseName, styles: { halign: 'left' } as { halign: 'left' } },
                ])
                : [];
            const secondRightEmptyRowsCount = 15 - secondRightBodyData.length;
            for (let i = 0; i < secondRightEmptyRowsCount; i++) {
                secondRightBodyData.push([
                    { content: '', styles: { halign: 'left' } },
                    { content: '', styles: { halign: 'left' } },
                    { content: '', styles: { halign: 'left' } },
                ]);
            }

            const fullHeight = Math.round((doc as any).lastAutoTable.finalY) - (headTableFinalY);
            //right
            const secondRightTableHeight = autoTable(doc, {
                willDrawCell: () => {
                    doc.setLineWidth(0.2)
                    doc.line(105, headTableFinalY, 105, headTableFinalY + fullHeight)
                    doc.line(118, headTableFinalY, 118, headTableFinalY + fullHeight)
                    doc.line(138, headTableFinalY, 138, headTableFinalY + fullHeight)
                    // doc.line(165, headTableFinalY, 165, headTableFinalY + fullHeight)
                    // doc.line(185, headTableFinalY, 185, headTableFinalY + fullHeight)
                    doc.line(196, headTableFinalY, 196, headTableFinalY + fullHeight)
                },
                startY: headTableFinalY,
                headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0], fontSize: 9 },
                bodyStyles: { textColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 8 },
                head: secondRightTableHeaderRows,
                body: secondRightBodyData,
                theme: 'plain',
                margin: { top: 10, left: 105 },
                tableWidth: 91,
                columnStyles: {
                    0: { cellWidth: 13, minCellHeight: 9.8 },
                    1: { cellWidth: 20, minCellHeight: 9.8 },
                    2: { cellWidth: 58, minCellHeight: 9.8 },
                }
            });
            const thirdTableContent: RowInput[] = [];
            thirdTableContent.push(
                [
                    { content: 'Contact Number: ' + row.contactNumber, colSpan: 8, styles: { halign: 'left' } },
                    { content: 'No. of Courses' + '\n' + row.totalCourse, colSpan: 2, styles: { halign: 'center' } },
                    { content: 'Total fees(in rupees) ' + '\n' + row.totalFees, colSpan: 2, styles: { halign: 'center' } },
                ],
                [
                    { content: 'Hereby, I declare that the particulars furnished by me in this application are correct.' + '\n\n\n\n' + 'Signature of Candidate with Date', colSpan: 9, styles: { halign: 'center', valign: 'bottom' } },
                    { content: 'Signature of the Principal with Date and Seal', colSpan: 3, styles: { halign: 'center', valign: 'bottom' } },
                ],
            );

            const thirdTableHeight = autoTable(doc, {
                startY: (doc as any).lastAutoTable.finalY,
                bodyStyles: { textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0], fontSize: 9 },
                body: thirdTableContent,
                theme: 'grid',
                didDrawPage: (data) => {
                    finalY = data.cursor?.y || 0;
                },
            });

            const nextStartY = finalY + 5;

            if (index !== examRegisterList.length - 1) {
                doc.setPage(index + 2);
                // doc.setLineWidth(0.3);
                // doc.line(10,10,200,10);
                autoTable(doc, { startY: nextStartY, head: [], body: [] });
            }
        });
        /*
            
              doc.save(fileName + '.pdf');
            }
            
            const csvData = [
              [
                "S.No", "exam","Year", "collegeName","code",
                "registerNumber","candidate", "DOB", "Degree","Discipline",
                "Regulations","Sem.", "courseCode","courseTitle","contactNumber",
                "noOfCourses","totalFees",
              ],
              ...filteredData.map((row, index) => [
                row["S.No"],
                row["exam"],
                row["Year"],
                row["collegeName"],
                row["code"],
                row["registerNumber"],
                row["candidate"],
                row["DOB"],
                row["Degree"],
                row["Discipline"],
                row["Regulations"],
                row["Sem."], 
                row["courseCode"],
                row["courseTitle"],
                row["contactNumber"],
                row["noOfCourses"],
                row["totalFees"],
              ]),
         ];
                return (
                    <>
                     <PageContainer title="Exam Registration Form">
                        <Card title="filters">
                            <div className="row">
                                <div className="col-3">
                                       <label>Degree</label> 
                                       <select
                                         className="form-control"
                                         value={degree}
                                         onChange={(e)=> setDegree(e.target.value)}
                                       >
                                       <option value="">-- Select Degree --</option> 
                                       <option value="B.E.">B.E.</option>
                                       <option value="B.Tech">B.Tech</option>
                                       <option value="B.E.">B.E.</option> 
                                       <option value="B.Sc.">B.Sc.</option>
                                       </select>
                                </div>
                                <div className="col-3">
                                    <label>Discipline</label>
                                    <select
                                       className="form-control"
                                       value={discipline}
                                       onChange={(e)=> setDiscipline(e.target.value)}
                                    >
                                    <option value="">-- Select Discipline --</option> 
                                    <option value="Computer Science">Computer Science</option>
                                    <option value="Electrical Engineering">Electrical Engineering</option>
                                    <option value="Mechanical Engineering">Mechanical Engineering</option>
                                    <option value="Physics">Physics</option>
                                    </select>
                                </div>
                            </div>
                        </Card>
                     <CollapseCard title="Exam Register">
                    <div className="container-fluid " style={{width:"180%"}}>
                      <table className="table table-success table-striped">
                        <thead>
                            <tr>
                               <th>S.No.</th> 
                               <th>Exam</th> 
                               <th>Year</th>
                               <th>Collage Name</th>
                               <th>Code</th>
                               <th>Register Number</th>
                               <th>Name of the Candidate</th>
                               <th>Date of Birth</th>
                               <th>Degree</th>
                               <th>Discipline</th>
                               <th>Regulations</th>
                               <th>Sem.</th>
                               <th>Course Code</th>
                               <th>Course Title</th>
                               <th>Contact Number</th>
                               <th>No.of Courses</th> 
                               <th>Total fees(Payable)</th> 
                            </tr>
                        </thead>
                        <tbody>
                        {filteredData.map((row, index) => (
                          <tr key={index}>
                            <td>{row["S.No"]}</td>
                            <td>{row["exam"]}</td>
                            <td>{row["Year"]}</td>
                            <td>{row["collegeName"]}</td>
                            <td>{row["code"]}</td>
                            <td>{row["registerNumber"]}</td>
                            <td>{row["candidate"]}</td>
                            <td>{row["DOB"]}</td>
                            <td>{row["Degree"]}</td>
                            <td>{row["Discipline"]}</td>
                            <td>{row["Regulations"]}</td>
                            <td>{row["Sem."]}</td> 
                            <td>{row["courseCode"]}</td>
                            <td>{row["courseTitle"]}</td>
                            <td>{row["contactNumber"]}</td> 
                           <td>{row["noOfCourses"]}</td>
                           <td>{row["totalFees"]}</td>
        */
        doc.save(fileName + '.pdf');
    }

    const csvData = [
        [
            'registerNo',
            'regulation',
            'degreeId',
            'disciplineId',
            'dateOfBirth',
            'name',
            'contactNumber',
            'currentSemester',
            'totalCourse',
            'totalFees',
            'disciplineName',
            'degreeName',
        ],
        ...examRegisterList.map((row) => [
            row.registerNo,
            row.regulation,
            // row.degreeId,
            // row.disciplineId,
            row.dateOfBirth,
            row.name,
            row.contactNumber,
            row.currentSemester,
            row.totalCourse,
            row.totalFees,
            row.disciplineName,
            row.degreeName,
        ]),
    ];

    // const getUniqueSemesters = () => {
    //   const uniqueSemesters = Array.from(new Set(selectedStudent.map((semester) => semester.currentSemester)));
    //   return uniqueSemesters;
    // };

    const dropDownSemester = () => {
        for (let i = 0; i >= 8; i++) {
            return i;
        }
    }


    console.log(examRegisterList);


    return (
        <>
            <PageContainer title="Exam Registration Report">
                <Card title="filters">
                    <div className="row">
                        <div className="col-3">
                            <label>Exam <span className="text-danger">*</span> :</label>
                            <select
                                className="form-control"
                                value={exam}
                                onChange={(e) => setExam(e.target.value)}
                            >
                                <option value="" selected>Select Exam</option>
                                {selectedExam.map((ex) => (
                                    <option key={ex.id} value={ex.id}>
                                        {ex.title}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-3">
                            <label>Degree <span className="text-danger">*</span> :</label>
                            <select
                                className="form-control"
                                value={degree}
                                onChange={(e) => setDegree(e.target.value)}
                            >
                                <option value="" selected>Select Degree</option>
                                {selectedDegree.map((course) => (
                                    <option key={course.id} value={course.id}>
                                        {course.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-3">
                            <label>Discipline <span className="text-danger">*</span> :</label>
                            <select
                                className="form-control"
                                value={discipline}
                                onChange={(e) => setDiscipline(e.target.value)}
                            >
                                <option value="" selected>Select Discipline</option>
                                {selectedDiscipline.map((course) => (
                                    <option key={course.id} value={course.id}>
                                        {course.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-3">
                            <label>Semester <span className="text-danger">*</span> :</label>
                            <select
                                className="form-control"
                                value={semester}
                                onChange={(e) => setSemester(e.target.value)}
                            >
                                <option value="" disabled>Select Semester</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                            </select>
                        </div>
                        {/* <div className="row"> */}
                        {/* <div className="col-lg-12 col-sm-12"> */}
                        <div className=" col-lg-12 mt-5 mb-5 text-end" >
                            <button className='btn btn-sm btn-primary' style={{ float: "right", width: "10%" }} onClick={handleSearch}>Search</button>
                        </div>
                        {/* </div> */}
                        {/* </div> */}
                    </div>
                </Card>
                <CollapseCard title="Exam Register Report">
                    <div className="container-fluid " style={{ width: "180%" }}>
                        <table className="table table-success table-striped">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>register No</th>
                                    <th>regulation</th>
                                    <th>current Semester</th>
                                    <th>degree Name</th>
                                    <th>discipline Name</th>
                                    <th>date Of Birth</th>
                                    <th>name</th>
                                    <th>contact Number</th>
                                    <th>total Course</th>
                                    <th>total Fees(in rupees)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {examRegisterList.sort((a, b) => b.currentSemester - a.currentSemester)?.map((row, index) => (
                                    <tr key={row.id}>
                                        <td>{index + 1}</td>
                                        <td>{row.registerNo}</td>
                                        <td>{row.regulation}</td>
                                        <td>{row.currentSemester}</td>
                                        <td>{row.degreeName}</td>
                                        <td>{row.disciplineName}</td>
                                        <td>{row.dateOfBirth}</td>
                                        <td>{row.name}</td>
                                        <td>{row.contactNumber}</td>
                                        <td>{row.totalCourse}</td>
                                        <td>{row.totalFees}</td>
                                    </tr>

                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            {examRegisterList.length > 0 && <div className="text-end mb-3">
                                <button
                                    type="button"
                                    onClick={handleDownloadPDF}
                                    className="m-1 btn btn-sm btn-primary"
                                >
                                    <Feather.Download /> PDF
                                </button>
                                <CSVLink
                                    data={csvData}
                                    filename="details_of_course.csv"
                                    className="m-1 btn btn-sm btn-primary"
                                >
                                    <Feather.Download /> CSV
                                </CSVLink>
                            </div>
                            }
                        </div>
                    </div>

                </CollapseCard>
            </PageContainer>
        </>
    )
}

export default ExamRegistrationReport;


interface Student {
    id: number;
    registerNo: string;
    currentSemester: string;
    degreeId: string;
    disciplineId: string;
}

interface Discipline {
    id: number;
    name: string;
}

interface Degree {
    id: number;
    name: string;
}

interface Exam {
    id: number;
    title: string;
}
