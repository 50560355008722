
import { PhaseActionList, PhaseType } from "../actions/phaseAction";
import { Action } from "../actions/types";

const initialValue: Array<PhaseType> = [];

const phaseReducer = (state: Array<PhaseType> = initialValue, action: Action) => {
    switch (action.type) {
        case PhaseActionList.ADD_PHASE:
            return [
                ...state,
                action.data,
            ];

        case PhaseActionList.UPDATE_PHASE:
            const updated = [...state].slice();
            const index = updated.findIndex(u => u.id === action.data.id);

            if (index > -1) {
                updated[index] = {
                    ...action.data,
                };
            }

            return updated;

        case PhaseActionList.FETCH_PHASE:
            return action.data;

        case PhaseActionList.DELETE_PHASE:
            return state.filter(s => s.id !== action.data);

        default:
            return state;
    }
}

export default phaseReducer;



