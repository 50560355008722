import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LocalFormatDate } from '../components/common/functions';
import Card from '../components/custom/Card';
import CollapseCard from '../components/custom/CollapseCard';
import PageContainer from '../components/custom/PageContainer';
import { CourseType } from '../redux/actions/courseActions';
import { DegreeType } from '../redux/actions/degreeAction';
import { DisciplineType, fetchDiscipline } from '../redux/actions/disciplineAction';
import { DropDownType, fetchDropDown } from '../redux/actions/dropDownAction';
import { ExamType } from '../redux/actions/examAction';
import { ExamFacultyType, fetchExamFaculty } from '../redux/actions/examFacultyAction';
import { FacultyType, fetchFaculty } from '../redux/actions/facultyActions';
import { PracticalExamType, addPracticalExam, deletePracticalExam, updatePracticalExam } from '../redux/actions/practicalExaminationAction';
import { RegulationType, fetchRegulation } from '../redux/actions/regulationAction';
import { StoreState } from '../redux/reducers';
import { AppDispatch } from '../redux/store';


const PracticalExamination = () => {

    const dispatch = useDispatch<AppDispatch>();

    const practicalExamList = useSelector<StoreState, PracticalExamType[]>(state => state.practicalExam);




    const examFacultyList = useSelector<StoreState, ExamFacultyType[]>(state => state.examFaculty);
    const facultyList = useSelector<StoreState, FacultyType[]>(state => state.faculty);
    const examList = useSelector<StoreState, ExamType[]>(state => state.exam);
    const degreeList = useSelector<StoreState, DegreeType[]>(state => state.degree);
    const disciplineList = useSelector<StoreState, DisciplineType[]>(state => state.discipline);
    const dropDownList = useSelector<StoreState, DropDownType[]>(state => state.dropDown)
    const regulationList = useSelector<StoreState, RegulationType[]>(state => state.regulation)
    const courseList = useSelector<StoreState, CourseType[]>(state => state.course)

    useEffect(() => {
        dispatch(fetchExamFaculty())
        dispatch(fetchDropDown())
        dispatch(fetchRegulation())
        dispatch(fetchFaculty())
        dispatch(fetchDiscipline())
    }, [])
    const [editFlag, setEditFlag] = useState(false);

    const [id, setId] = useState<number>(0)
    const [examId, setExamId] = useState<string>('')
    const [examIdError, setExamIdError] = useState<string>('')

    const [degreeId, setDegreeId] = useState<string>('')
    const [degreeIdError, setDegreeIdError] = useState<string>('')

    const [disciplineId, setDisciplineId] = useState<string>('')
    const [disciplineIdError, setDisciplineIdError] = useState<string>('')

    const [semester, setSemester] = useState<string>('')
    const [semesterError, setSemesterError] = useState<string>('')

    const [regulation, setRegulation] = useState<string>('')
    const [regulationError, setRegulationError] = useState<string>('')

    const [disciplineCourseId, setDisciplineCourseId] = useState<string>('')
    const [disciplineCourseIdError, setDisciplineCourseIdError] = useState<string>('')

    const [examDate, setExamDate] = useState<string>('')
    const [examDateError, setExamDateError] = useState<string>('')

    const [noStudent, setNoStudent] = useState<string>('')
    const [noStudentError, setNoStudentError] = useState<string>('')

    const [noSession, setNoSession] = useState<string>('')
    const [noSessionError, setNoSessionError] = useState<string>('')

    const [intExaminerId, setIntExaminerId] = useState<string>('')
    const [intExaminerIdError, setIntExaminerIdError] = useState<string>('')

    const [extExaminerId, setExtExaminerId] = useState<string>('')
    const [extExaminerIdError, setExtExaminerIdError] = useState<string>('')

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [apiErrorMessage, setApiErrorMessage] = useState('');

    //add practicalExam
    const addHandler = () => {
        let error = false

        if (examId.length === 0) {
            setExamIdError('Exam required');
            error = true;
        }
        if (degreeId.length === 0) {
            setDegreeIdError('Degree required');
            error = true;
        }
        if (disciplineId.length === 0) {
            setDisciplineIdError('Discipline required');
            error = true;
        }
        if (semester.length === 0) {
            setSemesterError('Semester required');
            error = true;
        }
        if (regulation.length === 0) {
            setRegulationError('Regulation required');
            error = true;
        }
        if (disciplineCourseId.length === 0) {
            setDisciplineCourseIdError('Course required');
            error = true;
        }
        if (examDate.length === 0) {
            setExamDateError('Exam Date required');
            error = true;
        }
        if (noStudent.length === 0) {
            setNoStudentError('No of Students required');
            error = true;
        }
        if (noSession.length === 0) {
            setNoSessionError('No of Session required');
            error = true;
        }
        if (intExaminerId.length === 0) {
            setIntExaminerIdError('Internal Examiner required');
            error = true;
        }
        if (extExaminerId.length === 0) {
            setExtExaminerIdError('External Examiner required');
            error = true;
        }

        const addData = {
            examId: parseInt(examId),
            degreeId: parseInt(degreeId),
            disciplineId: parseInt(disciplineId),
            semester: parseInt(semester),
            regulation: regulation,
            disciplineCourseId: parseInt(disciplineCourseId),
            examDate: examDate,
            noOfStudents: parseInt(noStudent),
            noOfSessions: parseInt(noSession),
            intExaminerId: parseInt(intExaminerId),
            extExaminerId: parseInt(extExaminerId)
        };

        if (!error) {
            dispatch(addPracticalExam(addData)).then(text => {
                toast.success(text)
                clearHandler();
            }).catch(text => {
                toast.error(text)
            })
        }
    };


    //edit practicalExam
    const editHandler = (pe: PracticalExamType) => {
        setEditFlag(true);
        setId(pe.id ? pe.id : 0);
        setExamId(pe.examId?.toString());
        setDegreeId(pe.degreeId?.toString());
        setDisciplineId(pe.disciplineId?.toString());
        setSemester(pe.semester?.toString());
        setRegulation(pe.regulation);
        setDisciplineCourseId(pe.disciplineCourseId?.toString())
        setExamDate(pe.examDate)
        setNoStudent(pe.noOfStudents?.toString())
        setNoSession(pe.noOfSessions?.toString())
        setIntExaminerId(pe.intExaminerId?.toString())
        setExtExaminerId(pe.extExaminerId?.toString())
    };

    //update practicalExam
    const updateHandler = () => {
        let error = false

        if (examId.length === 0) {
            setExamIdError('Exam required');
            error = true;
        }
        if (degreeId.length === 0) {
            setDegreeIdError('Degree required');
            error = true;
        }
        if (disciplineId.length === 0) {
            setDisciplineIdError('Discipline required');
            error = true;
        }
        if (semester.length === 0) {
            setSemesterError('Semester required');
            error = true;
        }
        if (regulation.length === 0) {
            setRegulationError('Regulation required');
            error = true;
        }
        if (disciplineCourseId.length === 0) {
            setDisciplineCourseIdError('Course required');
            error = true;
        }
        if (examDate.length === 0) {
            setExamDateError('Exam Date required');
            error = true;
        }
        if (noStudent.length === 0) {
            setNoStudentError('No of Students required');
            error = true;
        }
        if (noSession.length === 0) {
            setNoSessionError('No of Session required');
            error = true;
        }
        if (intExaminerId.length === 0) {
            setIntExaminerIdError('Internal Examiner required');
            error = true;
        }
        if (extExaminerId.length === 0) {
            setExtExaminerIdError('External Examiner required');
            error = true;
        }

        const editData = {
            id: id,
            examId: parseInt(examId),
            degreeId: parseInt(degreeId),
            disciplineId: parseInt(disciplineId),
            semester: parseInt(semester),
            regulation: regulation,
            disciplineCourseId: parseInt(disciplineCourseId),
            examDate: examDate,
            noOfStudents: parseInt(noStudent),
            noOfSessions: parseInt(noSession),
            intExaminerId: parseInt(intExaminerId),
            extExaminerId: parseInt(extExaminerId)
        };


        if (!error) {
            dispatch(updatePracticalExam(editData, id)).then(text => {
                toast.success(text)
                clearHandler();
            }).catch(text => {
                toast.error(text)
            })
        }
    };

    //clear practicalExam
    const clearHandler = () => {
        setId(0)
        setExamId('')
        setExamIdError('')
        setDegreeId('')
        setDegreeIdError('')
        setDisciplineId('')
        setDisciplineIdError('')
        setSemester('')
        setSemesterError('')
        setRegulation('')
        setRegulationError('')
        setDisciplineCourseId('')
        setDisciplineCourseIdError('')
        setExamDate('')
        setExamDateError('')
        setNoStudent('')
        setNoStudentError('')
        setNoSession('')
        setNoSessionError('')
        setIntExaminerId('')
        setIntExaminerIdError('')
        setExtExaminerId('')
        setExtExaminerIdError('')
        setEditFlag(false);
    };


    //delete practicalExam
    const confirmDeleteHandler = (id: number) => {
        setId(id);
        openDeleteConfirmation();
    };

    const deleteHandler = () => {
        if (id !== undefined) {
            dispatch(deletePracticalExam(id)).then(text => {
                toast.success(text)
                closeDeleteConfirmation();
            }).catch(text => {
                toast.error(text);
            })
        }
    }

    const openDeleteConfirmation = () => {
        setShowDeleteConfirmation(true);
    };

    const closeDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    };


    //ID values
    const getDiscipline = (discipline: number) => {
        const disciplineName = disciplineList.find((dl) => dl.id?.toString() === discipline?.toString())?.name
        return disciplineName
    }

    const getExam = (exam: number) => {
        const examName = examList.find((el) => el.id?.toString() === exam?.toString())?.title
        return examName
    }

    const getDegree = (degree: number) => {
        const degreeName = degreeList.find((dg) => dg.id?.toString() === degree?.toString())?.name
        return degreeName
    }
    const getCourse = (course: number) => {
        const courseName = courseList.find((cs) => cs.id?.toString() === course?.toString())?.name
        return courseName
    }

    const getFacultyName = (disciplineCourseId: number) => {
        const disciplineCourse = facultyList.find((dc) => dc.id?.toString() === disciplineCourseId?.toString())

        // console.log(disciplineCourse);
        if (disciplineCourse) {
            return `${disciplineCourse.code} - ${disciplineCourse.name}`;
        } else {
            return 'N/A';
        }

    }


    return (
        <PageContainer title="Practical Examination">
            <Card title={editFlag ? "Edit Practical Examination" : "Add Practical Examination"}>
                <div className='container m-0'>
                    <div className="row">

                        <div className="col-4">
                            <label className="mt-2 mb-2">Exam <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                value={examId}
                                onChange={(e) => {
                                    setExamId(e.target.value)
                                    setExamIdError('')
                                }}
                            >
                                <option value="">Select Exam</option>
                                {examList.map((ex) => (
                                    <option value={ex.id}>
                                        {ex.title}
                                    </option>
                                ))}
                            </select>
                            {examIdError && <div className="text-danger">{examIdError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-2 mb-2">Degree <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                value={degreeId}
                                onChange={(e) => {
                                    setDegreeId(e.target.value)
                                    setDegreeIdError('')
                                }}
                            >
                                <option value="">Select Degree</option>
                                {degreeList.map((deg) => (
                                    <option value={deg.id}>
                                        {deg.name}
                                    </option>
                                ))}
                            </select>
                            {degreeIdError && <div className="text-danger">{degreeIdError}</div>}
                        </div>


                        <div className="col-4">
                            <label className="mt-2 mb-2">Discipline <span className="text-danger">*</span> :</label>
                            <select
                                className="form-control"
                                value={disciplineId}
                                onChange={(e) => {
                                    setDisciplineId(e.target.value)
                                    setDisciplineIdError('')
                                }}
                            >
                                <option value="">Select Disicpline</option>
                                {disciplineList.map((dis) => (
                                    <option value={dis.id}>
                                        {dis.name}
                                    </option>
                                ))}
                            </select>
                            {disciplineIdError && <div className="text-danger">{disciplineIdError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-2 mb-2">Semester <span className="text-danger">*</span> :</label>
                            <select
                                className="form-control"
                                name="selectedSemester"
                                value={semester}
                                onChange={(e) => {
                                    setSemester(e.target.value)
                                    setSemesterError('')
                                }}
                            >
                                <option value="" selected> Select Semester</option>
                                <option value='1'>1</option>
                                <option value='2'>2</option>
                                <option value='3'>3</option>
                                <option value='4'>4</option>
                                <option value='5'>5</option>
                                <option value='6'>6</option>
                                <option value='7'>7</option>
                                <option value='8'>8</option>
                            </select>
                            {semesterError && <div className="text-danger">{semesterError}</div>}
                        </div>
                        <div className="col-4">
                            <label className="mt-2 mb-2">Regulation <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                value={regulation}
                                onChange={(e) => {
                                    setRegulation(e.target.value)
                                    setRegulationError('')
                                }}
                            >
                                <option value="">Select Regulation</option>
                                {regulationList.map((reg) => (
                                    <option value={reg.title}>
                                        {reg.title}
                                    </option>
                                ))}
                            </select>
                            {regulationError && <div className="text-danger">{regulationError}</div>}
                        </div>
                        <div className="col-4">
                            <label className="mt-2 mb-2">Course <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                value={disciplineCourseId}
                                onChange={(e) => {
                                    setDisciplineCourseId(e.target.value)
                                    setDisciplineCourseIdError('')
                                }}
                            >
                                <option value="">Select Course</option>
                                {courseList.map((cs) => (
                                    <option value={cs.id}>
                                        {cs.name}
                                    </option>
                                ))}
                            </select>
                            {disciplineCourseIdError && <div className="text-danger">{disciplineCourseIdError}</div>}
                        </div>
                        <div className="col-4">
                            <label className="mt-2 mb-2">Exam Date <span className="text-danger">*</span> : </label>
                            <input
                                className="form-control"
                                type="date"
                                name="examDate"
                                onChange={(e) => {
                                    setExamDate(e.target.value);
                                    setExamDateError('');
                                }}
                                value={examDate}
                            />
                            {examDateError && <div className="text-danger">{examDateError}</div>}
                        </div>
                        <div className="col-4">
                            <label className="mt-2 mb-2">No of Students <span className="text-danger">*</span> : </label>
                            <input
                                className="form-control"
                                type="text"
                                name="noOfStudent"
                                onChange={(e) => {
                                    setNoStudent(e.target.value);
                                    setNoStudentError('');
                                }}
                                value={noStudent}
                            />
                            {noStudentError && <div className="text-danger">{noStudentError}</div>}
                        </div>
                        <div className="col-4">
                            <label className="mt-2 mb-2">No of Sessions <span className="text-danger">*</span> : </label>
                            <input
                                className="form-control"
                                type="number"
                                name="noSessions"
                                onChange={(e) => {
                                    setNoSession(e.target.value);
                                    setNoSessionError('');
                                }}
                                value={noSession}
                            />
                            {noSessionError && <div className="text-danger">{noSessionError}</div>}
                        </div>
                        <div className="col-4">
                            <label className="mt-2 mb-2">Internal Examiner <span className="text-danger">*</span> : </label>
                            <select
                                name=""
                                id=""
                                className="form-control"
                                value={intExaminerId}
                                onChange={(e) => {
                                    setIntExaminerId(e.target.value);
                                    setIntExaminerIdError('');
                                }}
                            >
                                <option value="" selected>
                                    Select Internal Examiner
                                </option>
                                {examFacultyList.map((examFaculty) => {
                                    if (examFaculty.type === 'internal') {
                                        const faculty = facultyList.find(faculty => faculty.id === examFaculty.facultyId);
                                        return (
                                            <option key={examFaculty.id} value={examFaculty.facultyId}>
                                                {faculty?.name}
                                            </option>
                                        );
                                    }
                                    return null;
                                })}
                            </select>
                            {intExaminerIdError && <div className="text-danger">{intExaminerIdError}</div>}
                        </div>
                        <div className="col-4">
                            <label className="mt-2 mb-2">External Examiner <span className="text-danger">*</span> : </label>
                            <select
                                name=""
                                id=""
                                className="form-control"
                                value={extExaminerId}
                                onChange={(e) => {
                                    setExtExaminerId(e.target.value);
                                    setExtExaminerIdError('');
                                }}
                            >
                                <option value="" selected>
                                    Select External Examiner
                                </option>
                                {examFacultyList.map((examFaculty) => {
                                    if (examFaculty.type === 'external') {
                                        const faculty = facultyList.find(faculty => faculty.id === examFaculty.facultyId);
                                        return (
                                            <option key={examFaculty.id} value={examFaculty.facultyId}>
                                                {faculty?.name}
                                            </option>
                                        );
                                    }
                                    return null;
                                })}
                            </select>
                            {extExaminerIdError && <div className="text-danger">{extExaminerIdError}</div>}
                        </div>
                    </div>
                </div>
                <div className=" col mt-3 m-3 d-flex justify-content-end">
                    <button className="btn btn-sm btn-secondary" onClick={clearHandler} style={{ marginRight: '10px' }}>
                        Clear
                    </button>
                    {editFlag ? <button className="btn btn-sm btn-success" onClick={updateHandler}>Update</button> :
                        <button className="btn btn-sm btn-success" onClick={addHandler}>Add</button>}
                </div>
            </Card>
            <CollapseCard title="Practical Examination list">
                <div className="container-fluid" style={{ width: '100%' }}>
                    <table className="table table-success table-striped">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Exam</th>
                                <th>Degree</th>
                                <th>Discipline</th>
                                <th>Semester</th>
                                <th>Regulation</th>
                                <th>Course</th>
                                <th>Exam Date</th>
                                <th>No of Students</th>
                                <th>No of Sessions</th>
                                <th>Internal Examiner</th>
                                <th>External Examiner</th>
                                <th>Options</th>
                            </tr>
                        </thead>
                        <tbody>
                            {practicalExamList.map((pe, index) => (
                                <tr key={pe.id}>
                                    <td>{index + 1}</td>
                                    <td>{getExam(pe.examId)}</td>
                                    <td>{getDegree(pe.degreeId)}</td>
                                    <td>{getDiscipline(pe.disciplineId)}</td>
                                    <td>{pe.semester}</td>
                                    <td>{pe.regulation}</td>
                                    <td>{getCourse(pe.disciplineCourseId)}</td>
                                    <td>{LocalFormatDate(pe.examDate)}</td>
                                    <td>{pe.noOfStudents}</td>
                                    <td>{pe.noOfSessions}</td>
                                    <td>{getFacultyName(pe.intExaminerId)}</td>
                                    <td>{getFacultyName(pe.extExaminerId)}</td>
                                    <td>
                                        <button
                                            className="btn btn-sm btn-primary"
                                            style={{ marginRight: '10px' }}
                                            onClick={() => editHandler(pe)}
                                        >
                                            Edit
                                        </button>
                                        <button
                                            className="btn btn-sm btn-danger"
                                            onClick={() => confirmDeleteHandler(pe.id ? pe.id : 0)}
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </CollapseCard>
            {/* Deletion Confirmation Modal */}
            <Modal show={showDeleteConfirmation} onHide={closeDeleteConfirmation}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeDeleteConfirmation}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={deleteHandler}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </PageContainer >
    );
};

export default PracticalExamination;

