/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { GetState } from "../reducers"

export enum HallActionList {
    ADD_HALL = 'ADD_HALL',
    FETCH_HALL = 'FETCH_HALL',
    UPDATE_HALL = 'UPDATE_HALL',
    DELETE_HALL = 'DELETE_HALL',
}

export interface HallType {
    id?: number
    name: string
    seatingCapacity:string
}

export interface AddHallAction {
    type: HallActionList.ADD_HALL
    data: HallType
}

export interface FetchHallAction {
    type: HallActionList.FETCH_HALL
    data: Array<HallType>
}

export interface UpdateHallAction {
    type: HallActionList.UPDATE_HALL
    data: HallType
}

export interface DeleteHallAction {
    type: HallActionList.DELETE_HALL
    data: number
}

export type HallActions = AddHallAction | FetchHallAction | UpdateHallAction | DeleteHallAction

export const addHall = (data: HallType) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.post<APIResponse<HallType>>('hall/', data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<AddHallAction>({
                    type: HallActionList.ADD_HALL,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Hall Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })
    }
}

export const updateHall = (data: HallType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.put<APIResponse<HallType>>(`hall/?id=${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<UpdateHallAction>({
                    type: HallActionList.UPDATE_HALL,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Hall Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchHall = () => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<HallType[]>>('hall/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchHallAction>({
                    type: HallActionList.FETCH_HALL,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteHall = (id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.delete<APIResponse<HallType>>(`hall/?id=${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteHallAction>({
                    type: HallActionList.DELETE_HALL,
                    data: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Hall Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}
