import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from '../components/custom/Card';
import CollapseCard from '../components/custom/CollapseCard';
import PageContainer from '../components/custom/PageContainer';
// import { DegreeType, addDegree, fetchDegree } from '../redux/actions/degreeAction';
import { DegreeType, addDegree, deleteDegree, fetchDegree, updateDegree } from '../redux/actions/degreeAction';
import { StoreState } from '../redux/reducers';
import { AppDispatch } from '../redux/store';

const Degree = () => {
    const dispatch = useDispatch<AppDispatch>()
    const degreeList = useSelector<StoreState, DegreeType[]>(state => state.degree)

    // const [degreeList, setDegreeList] = useState<Degree[]>([]);


    const [editFlag, setEditFlag] = useState(false);
    const [name, setName] = useState<string>('');
    const [nameError, setNameError] = useState<string>('');

    const [yearsCount, setYearsCount] = useState<string>('');
    const [yearsCountError, setYearsCountError] = useState<string>('');

    const [semesterCount, setSemesterCount] = useState<string>('');
    const [semesterCountError, setSemesterCountError] = useState<string>('');

    const [disciplineId, setDisciplineId] = useState('');
    const [disciplineIdError, setDisciplineIdError] = useState('');

    const [id, setId] = useState<number>(0);
    const [createdBy, setCreatedBy] = useState<string>('');
    const [createTimestamp, setCreateTimestamp] = useState<string>('');
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [apiErrorMessage, setApiErrorMessage] = useState<string>('');


    const [selectedDisciplineId, setSelectedDisciplineId] = useState('');
    const [selectedDiscipline, setSelectedDiscipline] = useState<Discipline[]>([]);




    useEffect(() => {
        dispatch(fetchDegree())
    }, [])



    const clearHandler = () => {
        setName('');
        setYearsCount('');
        setSemesterCount('');
        setId(0);
        setCreatedBy('');
        setCreateTimestamp('');
        setEditFlag(false);
        setNameError('');
        setYearsCountError('');
        setSemesterCountError('');
    }


    //add
    const addHandler = () => {
        if (
            name.length === 0 ||
            yearsCount.length === 0 ||
            semesterCount.length === 0 ||
            name.length > 30 ||
            yearsCount.length > 11 ||
            semesterCount.length > 11
        ) {
            if (name.length === 0) {
                setNameError('Name is required');
            }
            if (yearsCount.length === 0) {
                setYearsCountError('Years count is required');
            }
            if (semesterCount.length === 0) {
                setSemesterCountError('Semester count is required');
            }
            if (name.length > 30) {
                setNameError('Name must be within 30 characters');
            }
            if (yearsCount.length > 11) {
                setYearsCountError('YearsCount must be within 11 characters');
            }
            if (semesterCount.length > 11) {
                setSemesterCountError('semesterCount must be within 11 characters');
            }
        }

        const addData = {
            name: name,
            yearsCount: parseInt(yearsCount),
            semesterCount: parseInt(semesterCount),
            createdBy,
            createTimestamp
        }

        dispatch(addDegree(addData)).then(text => {
            toast.success(text)
            clearHandler();
        }).catch(text => {
            toast.error(text)
        })

    }


    //edit
    const editHandler = (deg: DegreeType) => {
        setEditFlag(true);
        setId(deg.id ? deg.id : 0);
        setName(deg.name);
        setYearsCount(deg.yearsCount?.toString());
        setSemesterCount(deg.semesterCount?.toString());
    };


    //update
    const updateHandler = () => {
        if (
            name.length === 0 ||
            yearsCount.length === 0 ||
            semesterCount.length === 0 ||
            name.length > 30 ||
            yearsCount.length > 11 ||
            semesterCount.length > 11
        ) {
            if (name.length === 0) {
                setNameError('Name is required');
            }
            if (yearsCount.length === 0) {
                setYearsCountError('Years count is required');
            }
            if (semesterCount.length === 0) {
                setSemesterCountError('Semester count is required');
            }
            if (name.length > 30) {
                setNameError('Name must be within 30 characters');
            }
            if (yearsCount.length > 11) {
                setYearsCountError('YearsCount must be within 11 characters');
            }
            if (semesterCount.length > 11) {
                setSemesterCountError('semesterCount must be within 11 characters');
            }
            // return;
        }

        const editData = {
            id: id,
            name: name,
            yearsCount: parseInt(yearsCount),
            semesterCount: parseInt(semesterCount),
            createdBy,
            createTimestamp
        }

        dispatch(updateDegree(editData, id)).then(text => {
            toast.success(text)
            clearHandler();
        }).catch(text =>
            toast.error(text)
        )

    }

    //delete
    const deleteHandler = (id: number) => {
        setId(id);
        openDeleteConfirmation(); // Open the delete confirmation modal
    }

    const confirmDeleteHandler = () => {
        if (id !== undefined) {
            dispatch(deleteDegree(id)).then(text => {
                toast.success(text)
                closeDeleteConfirmation();
            }).catch(text => {
                toast.error(text);
            })
        }
    }

    const openDeleteConfirmation = () => {
        setShowDeleteConfirmation(true);
    }

    const closeDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    }

    return (
        <PageContainer title="Degree">
            <Card title={editFlag ? "Edit Degree" : "Add Degree"}>
                <div className='container m-0'>
                    <div className="row">
                        <div className="col-4">
                            {apiErrorMessage && <div className="text-danger">{apiErrorMessage}</div>}
                            <label className="mt-3 mb-3">Name <span className='text-danger'>*</span> : </label>
                            <input className="form-control" placeholder="Enter name..." type="text" name="name" onChange={(e) => {
                                setName(e.target.value)
                                setNameError('');
                                setApiErrorMessage('');
                            }} value={name} />
                            {nameError && <div className="text-danger">{nameError}</div>}</div>


                        <div className="col-4">
                            <label className="mt-3 mb-3">years Count <span className='text-danger'>*</span> : </label>
                            <input className="form-control" placeholder="Enter years Count..." type="number" name="yearsCount" onChange={(e) => {
                                setYearsCount(e.target.value)
                                setYearsCountError('');
                                setApiErrorMessage('');
                            }} value={yearsCount} />
                            {yearsCountError && <div className="text-danger">{yearsCountError}</div>}</div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">semester Count <span className='text-danger'>*</span> : </label>
                            <input className="form-control" placeholder="Enter semester Count..." type="number" name="semesterCount" onChange={(e) => {
                                setSemesterCount(e.target.value)
                                setSemesterCountError('');
                                setApiErrorMessage('');
                            }} value={semesterCount} />
                            {semesterCountError && <div className="text-danger">{semesterCountError}</div>}</div>

                        <div className=" col mt-5 m-4 d-flex justify-content-end  ">
                            <button className="btn btn-sm btn-secondary" onClick={clearHandler} style={{ marginRight: '10px' }}>
                                Clear
                            </button>
                            {editFlag ? <button className="btn btn-sm  btn-success" onClick={updateHandler}>Update</button> :
                                <button className="btn btn-sm btn-success" onClick={addHandler}>Add</button>}
                        </div>
                    </div></div>
            </Card>
            <CollapseCard title="Degree">
                <div className="container-fluid" style={{ width: "100%" }}>
                    <table className="table table-success table-striped">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Name</th>
                                <th>years Count </th>
                                <th>semester Count </th>
                                <th>Options</th>
                            </tr>
                        </thead>
                        <tbody>
                            {degreeList.map((deg, index) => (
                                <tr key={deg.id}>
                                    <td>{index + 1}</td>
                                    <td>{deg.name}</td>
                                    <td>{deg.yearsCount}</td>
                                    <td>{deg.semesterCount}</td>
                                    <td>
                                        <button className="btn btn-sm btn-primary" style={{ marginRight: '10px' }} onClick={() => editHandler(deg)}>Edit</button>
                                        <button className="btn btn-sm btn-danger" onClick={() => deleteHandler(deg.id ? deg.id : 0)}>Delete</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </CollapseCard>
            {/* Deletion Confirmation Modal */}
            <Modal show={showDeleteConfirmation} onHide={closeDeleteConfirmation}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this item?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeDeleteConfirmation}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={confirmDeleteHandler}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Add the ToastContainer for displaying toasts */}
            <ToastContainer position="top-right" />
        </PageContainer>
    );
}


export default Degree;

interface Degree {
    id: number;
    name: string;
    yearsCount: string;
    semesterCount: string;
    disciplineId: string;
    createdBy: string;
    createTimestamp: string;
}

interface Discipline {
    id: number;
    name: string;
}




