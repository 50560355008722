import { useEffect, useMemo, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from '../components/custom/Card';
import CollapseCard from '../components/custom/CollapseCard';
import PageContainer from '../components/custom/PageContainer';
import { CourseType, fetchCourse } from '../redux/actions/courseActions';
import { DegreeType, fetchDegree } from '../redux/actions/degreeAction';
import { DisciplineType, fetchDiscipline } from '../redux/actions/disciplineAction';
import { DisciplineCourseType, addDisciplineCourse, deleteDisciplineCourse, fetchDisciplineCourse, updateDisciplineCourse } from '../redux/actions/disciplineCourseAction';
import { DropDownType, fetchDropDown } from '../redux/actions/dropDownAction';
import { RegulationType, fetchRegulation } from '../redux/actions/regulationAction';
import { StoreState } from '../redux/reducers';
import { AppDispatch } from '../redux/store';

const DisciplineCourse = () => {

    const dispatch = useDispatch<AppDispatch>()

    const disciplineCourseList = useSelector<StoreState, DisciplineCourseType[]>(state => state.disciplineCourse)

    // console.log(disciplineCourseList);

    const dropDownList = useSelector<StoreState, DropDownType[]>(state => state.dropDown)

    const degreeList = useSelector<StoreState, DegreeType[]>(state => state.degree)

    const disciplineList = useSelector<StoreState, DisciplineType[]>(state => state.discipline)

    const courseList = useSelector<StoreState, CourseType[]>(state => state.course)

    const regulationList = useSelector<StoreState, RegulationType[]>(state => state.regulation)



    // const [courseList, setCourseList] = useState<DisciplineCourse[]>([]);

    const [editFlag, setEditFlag] = useState(false);

    const [disciplineId, setDisciplineId] = useState<string>('');
    const [disciplineIdError, setDisciplineIdError] = useState<string>('');

    const [degreeId, setDegreeId] = useState<string>('');
    const [degreeIdError, setDegreeIdError] = useState<string>('');

    const [courseId, setCourseId] = useState<string>('');
    const [courseIdError, setCourseIdError] = useState<string>('');

    const [courseHead, setCourseHead] = useState<string>('');
    const [courseHeadError, setCourseHeadError] = useState<string>('');

    const [courseCategory, setCourseCategory] = useState<string>('');
    const [courseCategoryError, setCourseCategoryError] = useState<string>('');

    const [courseType, setCourseType] = useState<string>('');
    const [courseTypeError, setCourseTypeError] = useState<string>('');

    const [courseSeqNo, setCourseSeqNo] = useState<string>('');
    const [courseSeqNoError, setCourseSeqNoError] = useState<string>('');

    const [semesterNo, setSemesterNo] = useState<string>('');
    const [semesterNoError, setSemesterNoError] = useState<string>('');

    const [contactHours, setContactHours] = useState<string>('');
    const [contactHoursError, setContactHoursError] = useState<string>('');

    const [credit, setCredit] = useState<string>('');
    const [creditError, setCreditError] = useState<string>('');

    const [lecture, setLecture] = useState<string>('');
    const [lectureError, setLectureError] = useState<string>('');

    const [tutorial, setTutorial] = useState<string>('');
    const [tutorialError, setTutorialError] = useState<string>('');

    const [practical, setPractical] = useState<string>('');
    const [practicalError, setPracticalError] = useState<string>('');

    const [intConversion, setIntConversion] = useState<string>('');
    const [intConversionError, setIntConversionError] = useState<string>('');

    const [extConversion, setExtConversion] = useState<string>('');
    const [extConversionError, setExtConversionError] = useState<string>('');

    const [regulation, setRegulation] = useState<string>('');
    const [regulationError, setRegulationError] = useState<string>('');

    const [examFees, setExamFees] = useState<string>('');
    const [examFeesError, setExamFeesError] = useState<string>('');

    const [createdBy, setCreatedBy] = useState<string>('');
    const [createTimestamp, setCreateTimestamp] = useState<string>('');

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [apiErrorMessage, setApiErrorMessage] = useState<string>('');

    //view data common filter
    const [selectDegree, setSelectDegree] = useState<string>('')
    const [selectDegreeError, setSelectDegreeError] = useState<string>('')

    const [selectDiscipline, setSelectDiscipline] = useState<string>('')
    const [selectDisciplineError, setSelectDisciplineError] = useState<string>('')

    const [selectCourseType, setSelectCourseType] = useState<string>('')
    const [selectCourseTypeError, setSelectCourseTypeError] = useState<string>('')

    const [selectRegulation, setSelectRegulation] = useState<string>('')
    const [selectRegulationError, setSelectRegulationError] = useState<string>('')

    const [id, setId] = useState<number>(0);

    const [filteredList, setFilteredList] = useState<DisciplineCourseType[]>([])

    console.log(selectRegulation);

    useEffect(() => {
        dispatch(fetchDisciplineCourse())
        dispatch(fetchDropDown())
        dispatch(fetchDegree())
        dispatch(fetchCourse())
        dispatch(fetchDiscipline())
        dispatch(fetchRegulation())
    }, [])

    //add disciplineCourse
    const addHandler = () => {
        let error = false

        if (disciplineId.length === 0) {
            setDisciplineIdError('discipline required');
            error = true
        }
        if (degreeId.length === 0) {
            setDegreeIdError('degree required');
            error = true
        }
        if (courseId.length === 0) {
            setCourseIdError('course required');
            error = true
        }
        if (courseHead.length === 0) {
            setCourseHeadError('courseHead required');
            error = true
        }
        if (courseCategory.length === 0) {
            setCourseCategoryError('courseCategory required');
            error = true
        }
        if (courseType.length === 0) {
            setCourseTypeError('courseType required');
            error = true
        }
        if (courseSeqNo.length === 0) {
            setCourseSeqNoError('courseSeqNo required');
            error = true
        }
        if (semesterNo.length === 0) {
            setSemesterNoError('semesterNo required');
            error = true
        }
        if (contactHours.length === 0) {
            setContactHoursError('contactPeriods required');
            error = true
        }
        if (credit.length === 0) {
            setCreditError('credit required');
            error = true
        }
        if (lecture.length === 0) {
            setLectureError('lecture required');
            error = true
        }
        if (tutorial.length === 0) {
            setTutorialError('tutorial required');
            error = true
        }
        if (practical.length === 0) {
            setPracticalError('practical required');
            error = true
        }
        if (examFees.length === 0) {
            setExamFeesError('exam Fees required');
            error = true
        } else if (examFees.length > 11) {
            setExamFeesError('examFees must be within 11 characters');
            error = true
        } else if (parseFloat(examFees) <= 0) {
            setExamFeesError('Invalid Exam Fees');
            error = true
        }

        if (disciplineId.length > 11) {
            setDisciplineIdError('discipline must be within 11 characters');
            error = true
        }
        if (degreeId.length > 11) {
            setDegreeIdError('degree must be within 11 characters');
            error = true
        }
        if (courseId.length > 11) {
            setCourseIdError('course must be within 11 characters');
            error = true
        }
        if (courseHead.length > 30) {
            setCourseHeadError('courseHead must be within 30 characters');
            error = true
        }
        if (courseCategory.length > 30) {
            setCourseCategoryError('courseCategory must be within 30 characters');
            error = true
        }
        if (courseType.length > 30) {
            setCourseTypeError('courseType must be within 30 characters');
            error = true
        }
        if (courseSeqNo.length > 11) {
            setCourseSeqNoError('courseSeqNo must be within 11 characters');
            error = true
        }
        if (semesterNo.length > 11) {
            setSemesterNoError('semesterNo must be within 11 characters');
            error = true
        }
        if (contactHours.length > 11) {
            setContactHoursError('contactHours must be within 11 characters');
            error = true
        }
        if (credit.length > 11) {
            setCreditError('credit must be within 11 characters');
            error = true
        }
        if (lecture.length > 30) {
            setLectureError('lecture must be within 30 characters');
            error = true
        }
        if (tutorial.length > 30) {
            setTutorialError('tutorial must be within 30 characters');
            error = true
        }
        if (practical.length > 30) {
            setPracticalError('practical must be within 30 characters');
            error = true
        }
        if (intConversion === '') {
            setIntConversionError('Internal Conversion Rate required');
            error = true
        } else if (parseInt(intConversion) <= 0) {
            setIntConversionError('Invalid Internal Conversion Rate');
            error = true
        } else if (parseInt(intConversion) > 100) {
            setIntConversionError('Internal Conversion Rate must be within 1-100');
            error = true
        }

        if (extConversion === '') {
            setExtConversionError('External Conversion Rate required');
            error = true
        } else if (parseInt(extConversion) <= 0) {
            setExtConversionError('Invalid External Conversion Rate');
            error = true
        } else if (parseInt(extConversion) > 100) {
            setExtConversionError('External Conversion Rate must be within 1-100');
            error = true
        }
        if (regulation.length === 0) {
            setRegulationError('Regulation required');
            error = true
        }

        const addData: DisciplineCourseType = {
            disciplineId: parseInt(disciplineId),
            degreeId: parseInt(degreeId),
            courseId: parseInt(courseId),
            courseHead: courseHead,
            courseCategory: courseCategory,
            courseType: courseType,
            courseSeqNo: parseInt(courseSeqNo),
            semesterNo: parseInt(semesterNo),
            contactHours: parseInt(contactHours),
            credit: parseInt(credit),
            lecture: lecture,
            tutorial: tutorial,
            practical: practical,
            intConversion: parseInt(intConversion),
            extConversion: parseInt(extConversion),
            regulation: regulation,
            examFees: parseInt(examFees),
        };

        if (!error) {
            dispatch(addDisciplineCourse(addData)).then(text => {
                toast.success(text)
                clearHandler()
            }).catch(text => {
                toast.error(text)
            })
        }
    }

    //edit
    const editHandler = (dc: DisciplineCourseType) => {
        setEditFlag(true);
        setId(dc.id ? dc.id : 0);
        setDegreeId(dc.degreeId?.toString())
        setDisciplineId(dc.disciplineId?.toString())
        setCourseId(dc.courseId?.toString())
        // setSelectedCourseId(editCourseId.toString());
        setCourseHead(dc.courseHead);
        setCourseCategory(dc.courseCategory);
        setCourseType(dc.courseType);
        setCourseSeqNo(dc.courseSeqNo?.toString());
        setSemesterNo(dc.semesterNo?.toString());
        setContactHours(dc.contactHours?.toString());
        setCredit(dc.credit?.toString());
        setLecture(dc.lecture);
        setTutorial(dc.tutorial);
        setPractical(dc.practical);
        setIntConversion(dc.intConversion?.toString());
        setExtConversion(dc.extConversion?.toString());
        setRegulation(dc.regulation);
        setExamFees(dc.examFees?.toString());
        setCreatedBy(createdBy);
        setCreateTimestamp(createTimestamp);
    }

    //update
    const updateHandler = () => {
        let error = false

        if (disciplineId.length === 0) {
            setDisciplineIdError('discipline required');
        }
        if (degreeId.length === 0) {
            setDegreeIdError('degree required');

        }
        if (courseId.length === 0) {
            setCourseIdError('course required');
        }
        if (courseHead.length === 0) {
            setCourseHeadError('course Head required');

        }
        if (courseCategory.length === 0) {
            setCourseCategoryError('course Category required');

        }
        if (courseType.length === 0) {
            setCourseTypeError('course Type required');

        }
        if (courseSeqNo === '') {
            setCourseSeqNoError('Course Seq.No required');
        } else if (parseInt(courseSeqNo) <= 0) {
            setCourseSeqNoError('Invalid Course Seq.No');
        }

        if (semesterNo.length === 0) {
            setSemesterNoError('semester No required');

        }
        if (contactHours.length === 0) {
            setContactHoursError('contact Hours required');

        }
        if (credit.length === 0) {
            setCreditError('credit required');

        }
        if (lecture.length === 0) {
            setLectureError('lecture required');

        }
        if (tutorial.length === 0) {
            setTutorialError('tutorial required');
        }

        if (practical.length === 0) {
            setPracticalError('practical required');
        }

        if (intConversion === '') {
            setIntConversionError('Internal Conversion Rate required');
            error = true
        } else if (parseInt(intConversion) <= 0) {
            setIntConversionError('Invalid Internal Conversion Rate');
            error = true
        } else if (parseInt(intConversion) > 100) {
            setIntConversionError('Internal Conversion Rate must be within 1-100');
            error = true
        }

        if (extConversion === '') {
            setExtConversionError('External Conversion Rate required');
            error = true
        } else if (parseInt(extConversion) <= 0) {
            setExtConversionError('Invalid External Conversion Rate');
            error = true
        } else if (parseInt(extConversion) > 100) {
            setExtConversionError('External Conversion Rate must be within 1-100');
            error = true
        }

        if (regulation.length === 0) {
            setRegulationError('Regulation required');

        }
        if (examFees.length === 0) {
            setExamFeesError('examFees required');

        }
        if (disciplineId.length > 11) {
            setDisciplineIdError('disciplineId must be within 11 characters');

        }
        if (degreeId.length > 11) {
            setDegreeIdError('degreeId must be within 11 characters');

        }
        if (courseId.length > 11) {
            setCourseIdError('courseId must be within 11 characters');

        }
        if (courseHead.length > 30) {
            setCourseHeadError('courseHead must be within 30 characters');

        }
        if (courseCategory.length > 30) {
            setCourseCategoryError('courseCategory must be within 30 characters');

        }
        if (courseType.length > 30) {
            setCourseTypeError('courseType must be within 30 characters');

        }
        if (courseSeqNo.length > 11) {
            setCourseSeqNoError('courseSeqNo must be within 11 characters');

        }
        if (semesterNo.length > 11) {
            setSemesterNoError('semesterNo must be within 11 characters');

        }
        if (contactHours.length > 11) {
            setContactHoursError('contactHours must be within 11 characters');

        }
        if (credit.length > 11) {
            setCreditError('credit must be within 11 characters');

        }
        if (lecture.length > 30) {
            setLectureError('lecture must be within 30 characters');

        } if (tutorial.length > 30) {
            setTutorialError('tutorial must be within 30 characters');

        } if (practical.length > 30) {
            setPracticalError('practical must be within 30 characters');

        } if (regulation.length > 10) {
            setRegulationError('regulation must be within 10 characters');

        }
        if (examFees.length > 11) {
            setExamFeesError('examFees must be within 11 characters');

        }
        const editData: DisciplineCourseType = {
            id: id,
            disciplineId: parseInt(disciplineId),
            degreeId: parseInt(degreeId),
            courseId: parseInt(courseId),
            courseHead: courseHead,
            courseCategory: courseCategory,
            courseType: courseType,
            courseSeqNo: parseInt(courseSeqNo),
            semesterNo: parseInt(semesterNo),
            contactHours: parseInt(contactHours),
            credit: parseInt(credit),
            lecture: lecture,
            tutorial: tutorial,
            practical: practical,
            intConversion: parseInt(intConversion),
            extConversion: parseInt(extConversion),
            regulation: regulation,
            examFees: parseInt(examFees),
        };

        if (!error) {
            dispatch(updateDisciplineCourse(editData, id)).then(text => {
                toast.success(text)
                clearHandler()
            }).catch(text => {
                toast.error(text)
            })
        }

    }

    const clearHandler = () => {
        setDisciplineId('');
        setDisciplineIdError('');
        setDegreeId('');
        setDegreeIdError('');
        setCourseId('');
        setCourseIdError('');
        setRegulation('');
        setRegulationError('');
        setCourseHead('');
        setCourseHeadError('');
        setCourseCategory('');
        setCourseCategoryError('');
        setCourseType('');
        setCourseTypeError('');
        setCourseSeqNo('');
        setCourseSeqNoError('');
        setSemesterNo('');
        setSemesterNoError('');
        setContactHours('');
        setContactHoursError('');
        setCredit('');
        setCreditError('');
        setLecture('');
        setLectureError('');
        setTutorial('');
        setTutorialError('');
        setPractical('');
        setPracticalError('');
        setIntConversion('')
        setIntConversionError('')
        setExtConversion('')
        setExtConversionError('')
        setExamFees('');
        setExamFeesError('');
        setId(0);
        setCreatedBy('');
        setCreateTimestamp('');
        setEditFlag(false);
    }


    const deleteHandler = (id: number) => {
        setId(id);
        openDeleteConfirmation(); // Open the delete confirmation modal
    }

    const confirmDeleteHandler = () => {
        if (id !== undefined) {
            dispatch(deleteDisciplineCourse(id)).then(text => {
                toast.success(text)
                closeDeleteConfirmation();
            }).catch(text => {
                toast.error(text);
            })
        }
    }

    const openDeleteConfirmation = () => {
        setShowDeleteConfirmation(true);
    }

    const closeDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    }


    useEffect(() => {
        getDegree(degreeId)
        getDiscipline(disciplineId)
        getCourse(courseId)
        // getRegulation(regulationId)
    }, [degreeId, disciplineId, courseId])

    const getDegree = (degreeId: string) => {
        const degree = degreeList.find((disc) => disc.id === parseInt(degreeId));
        if (degree) {
            return degree.name;
        }
        return 'N/A';
    };

    const getDiscipline = (disciplineId: string) => {
        const discipline = disciplineList.find((disc) => disc.id === parseInt(disciplineId));
        if (discipline) {
            return discipline.name;
        }
        return 'N/A';
    };
    // console.log(getDegree);

    const getCourse = (courseId: string) => {
        const course = courseList.find((cs) => cs.id === parseInt(courseId));
        if (course) {
            return course.name;
        }
        return 'N/A';
    };

    // const getRegulation = (regulationId: string) => {
    //     const regulation = regulationList.find((disc) => disc.id === parseInt(regulationId));
    //     if (regulation) {
    //         return regulation.title;
    //     }
    //     return 'N/A';
    // };

    const disciplineCourse = useMemo(() => {
        if (selectDegree !== "" && selectDiscipline !== '' && selectCourseType !== '' && selectRegulation !== '') {
            const filterList = disciplineCourseList.filter(dl => dl.degreeId?.toString() === selectDegree && dl.disciplineId?.toString() === selectDiscipline && dl.courseType === selectCourseType && dl.regulation?.toString() === selectRegulation)
            return filterList;
        } else {
            return disciplineCourseList;
            // setFilteredList(disciplineCourseList)
        }

    }, [selectDegree, selectDiscipline, selectCourseType, selectRegulation, disciplineCourseList])

    useEffect(() => {
        setFilteredList(disciplineCourseList)
    }, [disciplineCourseList])

    useEffect(() => {
        // if (selectDegree !== "" && selectDiscipline !== '' && selectCourseType !== '' && selectRegulation !== '') {
        const filterData = disciplineCourseList.filter(dl =>
            dl.degreeId?.toString() === (selectDegree !== '' ? selectDegree : dl.degreeId?.toString()) &&
            dl.disciplineId?.toString() === (selectDiscipline !== '' ? selectDiscipline : dl.disciplineId?.toString()) &&
            dl.courseType === (selectCourseType !== '' ? selectCourseType : dl.courseType) &&
            dl.regulation?.toString() === (selectRegulation !== '' ? selectRegulation : dl.regulation?.toString()))
        return setFilteredList(filterData);
        // } else {
        //     // return disciplineCourseList;
        //     return setFilteredList(disciplineCourseList)
        // }

    }, [selectDegree, selectDiscipline, selectCourseType, selectRegulation, disciplineCourseList])

    return (
        <PageContainer title="Discipline Course">
            <Card title={editFlag ? 'Edit Discipline Course' : 'Add Discipline Course'}>
                <div className='container m-0'>
                    <div className="row">
                        <div className="col-sm-6 col-md-4 col-lg-4">
                            <label className="mt-1 mb-3">Degree <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                style={{ width: "100%", marginRight: "20px" }}
                                name="selectedDegree"
                                value={degreeId}
                                onChange={(e) => {
                                    setDegreeId(e.target.value)
                                    setDegreeIdError('')
                                }}
                            >
                                <option value="" selected>Select Degree</option>
                                {degreeList.map((deg) => (
                                    <option key={deg.id} value={deg.id}>
                                        {deg.name}
                                    </option>
                                ))}
                            </select>
                            {degreeIdError && <div className="text-danger">{degreeIdError}</div>}</div>

                        <div className="col-sm-6 col-md-4 col-lg-4">
                            <label className="mt-1 mb-3">Discipline <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                style={{ width: "100%", marginRight: "20px", }}
                                name="selectedDiscipline"
                                value={disciplineId}
                                onChange={(e) => {
                                    setDisciplineId(e.target.value)
                                    setDisciplineIdError('')
                                }}
                            >
                                <option value="" selected>Select Discipline</option>
                                {disciplineList.map((dis) => (
                                    <option key={dis.id} value={dis.id}>
                                        {dis.name}
                                    </option>
                                ))}
                            </select>
                            {disciplineIdError && <div className="text-danger">{disciplineIdError}</div>}</div>

                        <div className="col-sm-6 col-md-4 col-lg-4">
                            <label className="mt-1 mb-3">Course <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                style={{ width: "100%", marginRight: "20px" }}
                                name="selectedCourse"
                                value={courseId}
                                onChange={(e) => {
                                    setCourseId(e.target.value)
                                    setCourseIdError('')
                                }}
                            >
                                <option value="" selected>Select Course</option>
                                {courseList.map((c) => (
                                    <option key={c.id} value={c.id}>
                                        {c.name}
                                    </option>
                                ))}
                            </select>
                            {courseIdError && <div className="text-danger">{courseIdError}</div>}</div>

                        <div className="col-sm-6 col-md-4 col-lg-4">
                            <label className="my-2">Regulation <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                style={{ width: "100%", marginRight: "20px" }}
                                name="regulation"
                                value={regulation}
                                onChange={(e) => {
                                    setRegulation(e.target.value)
                                    setRegulationError('')
                                }}
                            >
                                <option value="" selected>Select Regulation</option>
                                {regulationList.map((reg) => (
                                    <option key={reg.id} value={reg.title}>
                                        {reg.title}
                                    </option>
                                ))}
                            </select>
                            {regulationError && <div className="text-danger">{regulationError}</div>}</div>


                        <div className="col-sm-6 col-md-4 col-lg-4">
                            <label className="my-2">Course Head <span className="text-danger">*</span> : </label>
                            {/* <input className="form-control" placeholder="Enter Course Head..." type="text" name="CourseHead" onChange={(e) => {
                        setCourseHead(e.target.value);
                        setCourseHeadError('');
                        setApiErrorMessage('');
                    }} value={courseHead} /> */}
                            <select
                                className="form-control"
                                name="CourseHead"
                                onChange={(e) => {
                                    setCourseHead(e.target.value);
                                    setCourseHeadError('');
                                    setApiErrorMessage('');
                                }}
                                value={courseHead}
                            >
                                <option value="">Select Course Head</option>

                                {
                                    dropDownList.filter(dl => dl.category === 'Course Head').map((dd) => {
                                        return <option value={dd.title}>{dd.title}</option>
                                    })
                                }
                                {/* <option value="Engg">Engg</option>
                                <option value="HSMC">HSMC</option>
                                <option value="BSC">BSC</option>
                                <option value="ESC">ESC</option>
                                <option value="PCC">PCC</option>
                                <option value="PEC">PEC</option>
                                <option value="OEC">OEC</option>
                                <option value="EEC">EEC</option>
                                <option value="AC">AC</option> */}
                            </select>
                            {courseHeadError && <div className="text-danger">{courseHeadError}</div>}</div>


                        <div className="col-sm-6 col-md-4 col-lg-4">
                            <label className="my-2">Course Category <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                name="CourseCategory"
                                onChange={(e) => {
                                    setCourseCategory(e.target.value);
                                    setCourseCategoryError('');
                                    setApiErrorMessage('');
                                }}
                                value={courseCategory}
                            >
                                <option value="">Select Course Category</option>

                                {
                                    dropDownList.filter(dl => dl.category === 'Course Category').map((dd) => {
                                        return <option value={dd.title}>{dd.title}</option>
                                    })
                                }
                                {/* <option value="Core">Core</option>
                                <option value="Prof.Elective">Prof.Elective</option>
                                <option value="Open Elective">Open Elective</option> */}
                            </select>
                            {courseCategoryError && <div className="text-danger">{courseCategoryError}</div>}</div>


                        <div className="col-sm-6 col-md-4 col-lg-4">
                            <label className="my-2">Course Type <span className="text-danger">*</span> : </label>
                            {/* <input className="form-control" placeholder="Enter Course Type..." type="text" name="CourseType" onChange={(e) => {
                        setCourseType(e.target.value);
                        setCourseTypeError('');
                        setApiErrorMessage('');
                    }} value={courseType} /> */}
                            <select
                                className="form-control"
                                name="CourseType"
                                onChange={(e) => {
                                    setCourseType(e.target.value);
                                    setCourseTypeError('');
                                    setApiErrorMessage('');
                                }}
                                value={courseType}
                            >
                                <option value="">Select Course Type</option>
                                {dropDownList.filter(dl => dl.category === 'Course Type').map((dd) => {
                                    return <option value={dd.title}>{dd.title}</option>
                                })
                                }

                            </select>

                            {courseTypeError && <div className="text-danger">{courseTypeError}</div>}
                        </div>


                        <div className="col-sm-6 col-md-4 col-lg-4">
                            <label className="my-2">Course Seq No <span className="text-danger">*</span> : </label>
                            <input className="form-control" placeholder="Enter Course Seq No..." type="number" name="CourseSeqNo" onChange={(e) => {
                                setCourseSeqNo(e.target.value);
                                setCourseSeqNoError('');
                                setApiErrorMessage('');
                            }} value={courseSeqNo} />
                            {courseSeqNoError && <div className="text-danger">{courseSeqNoError}</div>}
                        </div>

                        <div className="col-sm-6 col-md-4 col-lg-4">
                            <label className="my-2">Semester No <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                value={semesterNo}
                                onChange={(e) => {
                                    setSemesterNo(e.target.value);
                                    setSemesterNoError('');
                                    setApiErrorMessage('');
                                }}>
                                <option value="">Select Semester</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                            </select>
                            {semesterNoError && <div className="text-danger">{semesterNoError}</div>}
                        </div>

                        <div className="col-sm-6 col-md-4 col-lg-4">
                            <label className="my-2">Contact Periods<span className="text-danger">*</span> : </label>
                            <input className="form-control" placeholder="Enter Contact Periods..." type="number" name="ContactHours" onChange={(e) => {
                                setContactHours(e.target.value);
                                setContactHoursError('');
                                setApiErrorMessage('');
                            }} value={contactHours} />
                            {contactHoursError && <div className="text-danger">{contactHoursError}</div>}</div>

                        <div className="col-sm-6 col-md-4 col-lg-4">
                            <label className="my-2">Credit <span className="text-danger">*</span> : </label>
                            <input className="form-control" placeholder="Enter Credit..." type="number" name="Credit" onChange={(e) => {
                                setCredit(e.target.value);
                                setCreditError('');
                                setApiErrorMessage('');
                            }} value={credit} />
                            {creditError && <div className="text-danger">{creditError}</div>}</div>

                        <div className="col-sm-6 col-md-4 col-lg-4">
                            <label className="my-2">Lecture <span className="text-danger">*</span> : </label>
                            <input className="form-control" placeholder="Enter Lecture..." type="text" name="Lecture" onChange={(e) => {
                                setLecture(e.target.value);
                                setLectureError('');
                                setApiErrorMessage('');
                            }} value={lecture} />
                            {lectureError && <div className="text-danger">{lectureError}</div>}</div>

                        <div className="col-sm-6 col-md-4 col-lg-4">
                            <label className="my-2">Tutorial  <span className="text-danger">*</span> : </label>
                            <input className="form-control" placeholder="Enter Tutorial..." type="text" name="Tutorial" onChange={(e) => {
                                setTutorial(e.target.value);
                                setTutorialError('');
                                setApiErrorMessage('');
                            }} value={tutorial} />
                            {tutorialError && <div className="text-danger">{tutorialError}</div>}</div>



                        <div className="col-sm-6 col-md-4 col-lg-4">
                            <label className="my-2">Practical <span className="text-danger">*</span> : </label>
                            <input className="form-control" placeholder="Enter Practical..." type="text" name="Practical" onChange={(e) => {
                                setPractical(e.target.value);
                                setPracticalError('');
                                setApiErrorMessage('');
                            }} value={practical} />
                            {practicalError && <div className="text-danger">{practicalError}</div>}</div>

                        <div className="col-sm-6 col-md-4 col-lg-4">
                            <label className="my-2">Internal Conversion Rate <span className="text-danger">*</span> : </label>
                            <input className="form-control" placeholder="Enter Practical..." type="text" name="Practical" onChange={(e) => {
                                setIntConversion(e.target.value);
                                setIntConversionError('');
                                setApiErrorMessage('');
                            }} value={intConversion} />
                            {intConversionError && <div className="text-danger">{intConversionError}</div>}
                        </div>

                        <div className="col-sm-6 col-md-4 col-lg-4">
                            <label className="my-2">External Conversion Rate <span className="text-danger">*</span> : </label>
                            <input className="form-control" placeholder="Enter Practical..." type="text" name="Practical" onChange={(e) => {
                                setExtConversion(e.target.value);
                                setExtConversionError('');
                                setApiErrorMessage('');
                            }} value={extConversion} />
                            {extConversionError && <div className="text-danger">{extConversionError}</div>}
                        </div>


                        <div className="col-sm-6 col-md-4 col-lg-4">
                            <label className="my-2">Exam Fees <span className="text-danger">*</span> : </label>
                            <input className="form-control" placeholder="Enter Exam Fees..." type="text" name="ExamFees" onChange={(e) => {
                                setExamFees(e.target.value);
                                setExamFeesError('');
                                setApiErrorMessage('');
                            }} value={examFees} />
                            {examFeesError && <div className="text-danger">{examFeesError}</div>}</div>

                    </div>
                    <div className="m-3 d-flex justify-content-end">
                        <button className="btn btn-sm btn-secondary" onClick={clearHandler} style={{ marginRight: '10px' }}>Clear</button>
                        {editFlag ? <button className="btn btn-sm btn-success" onClick={updateHandler} >Update</button> :
                            <button className="btn btn-sm btn-success" onClick={addHandler}>Add</button>}
                    </div>
                </div>
            </Card>
            <CollapseCard title="Discipline Course">
                <div className="row">
                    <div className="col-3">
                        <label className="">Degree : </label>
                        <select
                            className="form-control"
                            // style={{ width: "100%", marginRight: "20px" }}
                            name="selectedDegree"
                            value={selectDegree}
                            onChange={(e) => {
                                setSelectDegree(e.target.value)
                                // setDegreeIdError('')
                            }}
                        >
                            <option value="" selected>Select Degree</option>
                            {degreeList.map((deg) => (
                                <option key={deg.id} value={deg.id}>
                                    {deg.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-3">
                        <label className="">Discipline : </label>
                        <select
                            className="form-control"
                            // style={{ width: "100%", marginRight: "20px", }}
                            name="selectDiscipline"
                            value={selectDiscipline}
                            onChange={(e) => {
                                setSelectDiscipline(e.target.value)
                                // setDisciplineIdError('')
                            }}
                        >
                            <option value="" selected>Select Discipline</option>
                            {disciplineList.map((dis) => (
                                <option key={dis.id} value={dis.id}>
                                    {dis.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-3">
                        <label className="">Course Type : </label>
                        <select
                            className="form-control"
                            name="selectCourseType"
                            onChange={(e) => {
                                setSelectCourseType(e.target.value);
                                setSelectCourseTypeError('')
                                setApiErrorMessage('');
                            }}
                            value={selectCourseType}
                        >
                            <option value="">Select Course Type</option>

                            {dropDownList.filter(dl => dl.category === 'Course Type').map((dd) => {
                                return <option value={dd.title}>{dd.title}</option>
                            })
                            }
                        </select>
                    </div>
                    <div className="col-3">
                        <label className="">Regulation : </label>
                        <select
                            className="form-control"
                            name="selectRegulation"
                            onChange={(e) => {
                                setSelectRegulation(e.target.value);
                                setSelectRegulationError('')
                                setApiErrorMessage('');
                            }}
                            value={selectRegulation}
                        >
                            <option value="">Select Regulation</option>
                            {regulationList.map((rl) => {
                                return <option value={rl.title}>{rl.title}</option>
                            })
                            }
                        </select>
                    </div>
                    {/* <div className='col-12 mb-3 mt-3'>
                        <button className='btn btn-sm btn-primary' style={{ float: "right" }} onClick={hanldeSearch}>Search</button>
                    </div> */}
                </div>

                <div className="container-fluid mt-3" style={{ width: "150%" }}>

                    <table className="table table-success table-striped">
                        <thead>
                            <tr>
                                <th>S.No.</th>
                                <th>Degree</th>
                                <th>Discipline</th>
                                <th>Course</th>
                                <th>Course Head</th>
                                <th>Course Category</th>
                                <th>Course Type</th>
                                <th>Course Seq No</th>
                                <th>Semester No</th>
                                <th>Contact Periods</th>
                                <th>Credit</th>
                                <th>Lecture</th>
                                <th>Tutorial</th>
                                <th>Practical</th>
                                <th>Int.Conv.Rate</th>
                                <th>Ext.Conv.Rate</th>
                                <th>Regulation</th>
                                <th>Exam Fees</th>
                                {/* <th>Created By</th> */}
                                {/* <th>Create Timestamp</th> */}
                                <th>Options</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredList.map((dc, index) => (
                                <tr key={dc.id}>
                                    <td>{index + 1}</td>
                                    <td>{getDegree(dc.degreeId?.toString())}</td>
                                    <td>{getDiscipline(dc.disciplineId?.toString())}</td>
                                    <td>{getCourse(dc.courseId?.toString())}</td>
                                    <td>{dc.courseHead}</td>
                                    <td>{dc.courseCategory}</td>
                                    <td>{dc.courseType}</td>
                                    <td>{dc.courseSeqNo}</td>
                                    <td>{dc.semesterNo}</td>
                                    <td>{dc.contactHours}</td>
                                    <td>{dc.credit}</td>
                                    <td>{dc.lecture}</td>
                                    <td>{dc.tutorial}</td>
                                    <td>{dc.practical}</td>
                                    <td>{dc.intConversion}</td>
                                    <td>{dc.extConversion}</td>
                                    <td>{dc.regulation}</td>
                                    <td>{dc.examFees}</td>
                                    {/* <td>{dc.createdBy}</td> */}
                                    {/* <td>{dc.createTimestamp}</td> */}
                                    <td className='d-flex'>
                                        <button className="btn btn-sm btn-primary" style={{ marginRight: "10px" }} onClick={() => editHandler(dc)}>Edit</button>
                                        <button className="btn btn-sm btn-danger" onClick={() => deleteHandler(dc.id ? dc.id : 0)}>Delete</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </CollapseCard>
            {/* Deletion Confirmation Modal */}
            <Modal show={showDeleteConfirmation} onHide={closeDeleteConfirmation}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this item?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeDeleteConfirmation}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={confirmDeleteHandler}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Add the ToastContainer for displaying toasts */}
            <ToastContainer position="top-right" />
        </PageContainer >
    );
}

export default DisciplineCourse;


