import axios from 'axios';
import jsPDF from 'jspdf';
import autoTable, { RowInput } from 'jspdf-autotable';
import { ChangeEventHandler, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { CSVLink } from 'react-csv';
import * as Feather from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from '../components/custom/Card';
import CollapseCard from '../components/custom/CollapseCard';
import PageContainer from '../components/custom/PageContainer';
import { apiUrl } from '../config';
import { CourseType } from '../redux/actions/courseActions';
import { DegreeType } from '../redux/actions/degreeAction';
import { DisciplineType } from '../redux/actions/disciplineAction';
import { DisciplineCourseType } from '../redux/actions/disciplineCourseAction';
import { ExamType } from '../redux/actions/examAction';
import { ExamAttendanceType, addExamAttendance, deleteExamAttendance, fetchExamAttendance, updateExamAttendance } from '../redux/actions/examAttendanceActions';
import { StoreState } from '../redux/reducers';
import { AppDispatch } from '../redux/store';
import imgPath from './img/collegeLogo.png';


const ExamAttendance = () => {
    const dispatch = useDispatch<AppDispatch>();
    const fileName = "attendance_result_report";
    const examAttendanceList = useSelector<StoreState, ExamAttendanceType[]>((state: any) => state.examAttendance);

    const [filterExamList, setFilterExamList] = useState<ExamAttendanceType[]>([])

    console.log(filterExamList);

    const [editFlag, setEditFlag] = useState(false);
    const [examId, setExamId] = useState<string>('');
    const [degreeId, setDegreeId] = useState<string>('');
    const [disciplineId, setDisciplineId] = useState<string>('');
    const [batch, setBatch] = useState<string>('');
    const [registerNo, setRegisterNo] = useState<string>('');
    const [date, setDate] = useState<string>('');
    const [session, setSession] = useState<string>('');
    const [answerBookNo, setAnswerBookNo] = useState<string>('');
    const [attendance, setAttendance] = useState<string>('');
    const [id, setId] = useState<number>(0);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [examIdError, setExamIdError] = useState<string>('');
    const [degreeIdError, setDegreeIdError] = useState<string>('');
    const [disciplineIdError, setDisciplineIdError] = useState<string>('');
    const [batchError, setBatchError] = useState<string>('');
    const [registerNoError, setRegisterNoError] = useState<string>('');
    const [dateError, setDateError] = useState<string>('');
    const [sessionError, setSessionError] = useState<string>('');
    const [answerBookNoError, setAnswerBookNoError] = useState<string>('');
    const [attendanceError, setAttendanceError] = useState<string>('');
    const [disciplineCourseId, setDisciplineCourseId] = useState<string>('')
    const [disciplineCourseIdError, setDisciplineCourseIdError] = useState<string>('')

    // const [selectedExam, setSelectedExam] = useState<Exam[]>([]);
    const examList = useSelector<StoreState, ExamType[]>(state => state.exam)
    const degreeList = useSelector<StoreState, DegreeType[]>(state => state.degree)
    const disciplineList = useSelector<StoreState, DisciplineType[]>(state => state.discipline)
    const disciplineCourseList = useSelector<StoreState, DisciplineCourseType[]>(state => state.disciplineCourse)
    const courseList = useSelector<StoreState, CourseType[]>(state => state.course)

    const [studentList, setStudentList] = useState<Student[]>([]);

    //not in stud type has only 2 cols regno and name
    const [filteredStudentList, setFilteredStudentList] = useState<Student[]>([]);
    console.log(filteredStudentList);

    const [last10Years, setLast10Years] = useState<number[]>([]);

    const [attendanceMap, setAttendanceMap] = useState<{ [key: number]: string }>({});
    const [answerBookNoMap, setAnswerBookNoMap] = useState<{ [key: number]: string }>({});
    const [editModalShow, setEditModalShow] = useState(false);

    //view attendance table
    const [selectExam, setSelectExam] = useState<string>('')
    const [selectExamError, setSelectExamError] = useState<string>('')

    const [selectDegree, setSelectDegree] = useState<string>('')
    const [selectDegreeError, setSelectDegreeError] = useState<string>('')

    const [selectDiscipline, setSelectDiscipline] = useState<string>('')
    const [selectDisciplineError, setSelectDisciplineError] = useState<string>('')

    const [selectCourse, setSelectCourse] = useState<string>('')
    const [selectCourseError, setSelectCourseError] = useState<string>('')

    console.log(selectCourse);

    const [selectBatch, setSelectBatch] = useState<string>('')
    const [selectBatchError, setSelectBatchError] = useState<string>('')

    const [selectedCourseCode, setSelectedCourseCode] = useState<string>('');
    const [selectedCourseName, setSelectedCourseName] = useState<string>('');

    const [selectDate, setSelectDate] = useState<string>('')
    const [selectDateError, setSelectDateError] = useState<string>('')

    const [selectSession, setSelectSession] = useState<string>('')
    const [selectSessionError, setSelectSessionError] = useState<string>('')

    const [isPracticalCourse, setIsPracticalCourse] = useState<boolean>(false);
    const [fromRegNo, setFromRegNo] = useState('');
    const [toRegNo, setToRegNo] = useState('');

    const [courseType, setCourseType] = useState<string>('');

    const handleFromRegNoChange = (e: any) => {
        setFromRegNo(e.target.value);
    };

    const handleToRegNoChange = (e: any) => {
        setToRegNo(e.target.value);
    };

    const handleCheckboxChange = (studentId: number, checked: boolean) => {
        setAttendanceMap((prevAttendanceMap) => ({
            ...prevAttendanceMap,
            [studentId]: checked ? 'PR' : 'AB',
        }));
    };

    const handleAnswerBookNoChange = (studentId: number, value: string) => {
        setAnswerBookNoMap((prevAnswerBookNoMap) => ({
            ...prevAnswerBookNoMap,
            [studentId]: value,
        }));
    };

    // Format the date as "DD-MM-YYYY"
    const formattedDate = selectDate
        ? new Date(selectDate).toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        }).split('/').join('-')
        : '';

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const years = Array.from({ length: 10 }, (_, index) => currentYear - index);
        setLast10Years(years);
    }, []);

    const handleCourse: ChangeEventHandler<HTMLSelectElement> = (e) => {
        let cid = e.target.value
        setSelectCourse(cid)
        // console.log(cid);
        let obj = courseList.find(f => f.id!.toString() === cid.toString())
        console.log(obj);
        setSelectedCourseCode(obj?.shortName ? obj?.shortName : '')
        setSelectedCourseName(obj?.name ? obj?.name : '')
    }

    useEffect(() => {
        let disCourseObj = disciplineCourseList.find(f => f.degreeId?.toString() === selectDegree && f.disciplineId?.toString() === selectDiscipline && f.courseId?.toString() === selectCourse)

        setCourseType(disCourseObj?.courseType ? disCourseObj?.courseType : '')
    }, [disciplineCourseList, selectDegree, selectDiscipline, selectCourse])

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        axios.get<{ data: Student[] }>(`${apiUrl}/student/`)
            .then((response) => {
                console.log(response);

                setStudentList(response.data.data);
            })
            .catch((error) => console.log(error));
    }

    console.log(studentList)
    console.log(filteredStudentList);

    // useEffect(() => {
    //     dispatch(fetchExamAttendance())
    // }, [])

    useEffect(() => {
        setFilterExamList(examAttendanceList)
    }, [examAttendanceList])

    const filterList = () => {
        let disCourseId = disciplineCourseList.filter(dc => dc.courseId === parseInt(selectCourse)).map(dc => dc.id)
        if (selectExam === '' || selectDegree === '' || selectDiscipline === '' || selectCourse === '') {
            // || selectBatch === '' || selectDate === '' || selectSession === ''
            toast.error('Select all filters')
        } else {
            const filterList = examAttendanceList?.filter(ea => {
                return (
                    (selectExam === '' || ea.examId?.toString() === selectExam) &&
                    (selectDegree === '' || ea.degreeId?.toString() === selectDegree) &&
                    (selectDiscipline === '' || ea.disciplineId?.toString() === selectDiscipline) &&
                    (disCourseId.length === 0 || disCourseId.includes(ea.disciplineCourseId)) &&
                    (selectBatch === '' || ea.batch === selectBatch) &&
                    (selectDate === '' || ea.date === selectDate) &&
                    (selectSession === '' || ea.session === selectSession)
                )
            })
            setFilterExamList(filterList);
        }
    }

    const resetData = () => {
        setSelectExam('')
        setSelectDegree('')
        setSelectDiscipline('')
        setSelectBatch('')
        setSelectDate('')
        setSelectSession('')
        setFilterExamList(examAttendanceList)
    }

    const getDisciplineCourseType = (degreeId: string, disciplineId: string, disciplineCourseId: string) => {
        const selectedCourseIdInt = parseInt(disciplineCourseId);
        const selectedDisciplineIdInt = parseInt(disciplineId);
        const selectedDegreeIdInt = parseInt(degreeId);
        const course = courseList.find((c) => c.id === selectedCourseIdInt);
        const discipline = disciplineList.find((c) => c.id === selectedDisciplineIdInt);
        const degree = degreeList.find((c) => c.id === selectedDegreeIdInt);

        if (course && discipline && degree) {
            const foundCourse = disciplineCourseList.find((dc) => dc.courseId === course.id && dc.disciplineId === discipline.id && dc.degreeId === degree.id);
            if (foundCourse) {
                return foundCourse.courseType;
            }
        }
        return 'N/A';
    };

    // const filterCourseList = () => {
    //     if (examId !== '' && degreeId !== '' && disciplineId !== '' && batch !== '' && disciplineCourseId !== '') {
    //         const filteredCourses = studentList.filter((course) => {
    //             if (
    //                 (degreeId === null || course.degreeId.toString() === degreeId) &&
    //                 (disciplineId === null || course.disciplineId.toString() === disciplineId) &&
    //                 (batch === '' || course.batch === batch)
    //             ) {
    //                 setIsPracticalCourse(disciplineCourseType === 'Practical'); 
    //                 console.log(isPracticalCourse);
    //                 return false;
    //             }
    //             return true;
    //         });
    //         setFilteredStudentList(filteredCourses);
    //     } else {
    //         toast.error('Select All Filters', { position: 'top-right', autoClose: 3000 });
    //         setFilteredStudentList([]);
    //     }
    // };

    const filterCourseList = () => {
        const disciplineCourseType = getDisciplineCourseType(degreeId, disciplineId, disciplineCourseId);
        console.log(disciplineCourseType);
        let requiredFilters: string[] = [];
        if (disciplineCourseType === 'Practical' || disciplineCourseType === 'practical') {
            setIsPracticalCourse(true);
            requiredFilters = [examId, degreeId, disciplineId, batch, disciplineCourseId]
        } else if (disciplineCourseType === 'Theory' || disciplineCourseType === 'theory' || disciplineCourseType.toLowerCase() === 'theory cum practical') {
            setIsPracticalCourse(false);
            requiredFilters = [examId, degreeId, disciplineId, batch, disciplineCourseId, session, date]
        }

        // const requiredFilters = isPracticalCourse ? [examId, degreeId, disciplineId, batch, disciplineCourseId] : [examId, degreeId, disciplineId, batch, disciplineCourseId, session, date];
        console.log(isPracticalCourse);
        console.log(requiredFilters);
        const hasAllFilters = requiredFilters.length > 0 && requiredFilters.every(filter => filter !== '');

        let disId = disciplineCourseList.find((dc) => dc.degreeId === parseInt(degreeId) && dc.disciplineId === parseInt(disciplineId) && dc.courseId === parseInt(disciplineCourseId))?.id

        console.log(disId);

        if (hasAllFilters) {
            axios.get<{ data: Student[] }>(`${apiUrl}/student/selectStuDetailForAttendance/?examId=${examId}&degreeId=${degreeId}&disciplineId=${disciplineId}&disciplineCourseId=${disId}&batch=${batch}`).then((response) => {
                console.log(response);

                setFilteredStudentList(response.data.data);
            })
                .catch((error) => console.log(error));


        } else {
            toast.error('Select All Filters', { position: 'top-right', autoClose: 3000 });
            setFilteredStudentList([]);
            setIsPracticalCourse(false);
        }
    };

    const addHandler = () => {
        // if (
        //     examId.length === 0 || date.length === 0 && isPracticalCourse === false || session.length === 0 && isPracticalCourse === false || degreeId.length === 0 ||
        //     disciplineId.length === 0 || disciplineCourseId.length === 0 || batch.length === 0 ||
        //     examId.length > 11 || date.length > 10 || session.length > 3 || degreeId.length > 11 ||
        //     disciplineId.length > 11 || disciplineCourseId.length > 11 || batch.length > 10
        // ) {
        if (examId === '') {
            setExamIdError('Exam is required');
        }
        if (date === '' && isPracticalCourse === false) {
            setDateError('Date is required');
        }
        if (session === '' && isPracticalCourse === false) {
            setSessionError('Session is required');
        }
        if (degreeId === '') {
            setDegreeIdError('Degree is required');
        }
        if (disciplineId === '') {
            setDisciplineIdError('Discipline is required');
        }
        if (disciplineCourseId === '') {
            setDisciplineCourseIdError('Discipline Course is required');
        }
        if (batch === '') {
            setBatchError('Batch is required');
        }
        if (registerNo === '') {
            setRegisterNoError('Register No is required');
        }

        if (examId.length > 11) {
            setExamIdError('Exam must be within 11 characters');
        }
        if (date.length > 10) {
            setDateError('Date must be within 10 characters');
        }
        if (session.length > 3) {
            setSessionError('Session must be within 3 characters');
        }
        if (degreeId.length > 11) {
            setDegreeIdError('Degree must be within 11 characters');
        }
        if (disciplineId.length > 11) {
            setDisciplineIdError('Discipline must be within 11 characters');
        }
        if (disciplineCourseId.length > 11) {
            setDisciplineCourseIdError('Discipline Course must be within 11 characters');
        }
        if (batch.length > 10) {
            setBatchError('Batch must be within 10 characters');
        }
        // return;
        // }

        const getDisciplineCourseName = (degreeId: string, disciplineId: string, disciplineCourseId: string) => {
            const selectedCourseIdInt = parseInt(disciplineCourseId);
            const selectedDisciplineIdInt = parseInt(disciplineId);
            const selectedDegreeIdInt = parseInt(degreeId);
            const course = courseList.find((c) => c.id === selectedCourseIdInt);
            const discipline = disciplineList.find((c) => c.id === selectedDisciplineIdInt);
            const degree = degreeList.find((c) => c.id === selectedDegreeIdInt);

            if (course && discipline && degree) {
                const foundCourse = disciplineCourseList.find((dc) => dc.courseId === course.id && dc.disciplineId === discipline.id && dc.degreeId === degree.id);
                if (foundCourse) {
                    return foundCourse.id;
                }
            }
            return 'N/A';
        };

        const disciplineCourseName = getDisciplineCourseName(degreeId, disciplineId, disciplineCourseId);

        const data = filteredStudentList.map((student, index) => ({
            examId: parseInt(examId),
            degreeId: parseInt(degreeId),
            disciplineId: parseInt(disciplineId),
            batch: batch,
            registerNo: student.registerNo,
            date: date,
            session: session,
            disciplineCourseId: parseInt(disciplineCourseName ? disciplineCourseName?.toString() : ''),
            answerBookNo: answerBookNoMap[student.id] || '',
            // attendance: attendanceMap[student.id] === 'PR' ? 'PR' : 'AB',
            attendance: (attendanceMap[index] === undefined || attendanceMap[student.id] === 'PR') ? 'PR' : 'AB'
        }));

        dispatch(addExamAttendance(data)).then(text => {
            toast.success(text);
            setExamIdError('');
            setDateError('');
            setSessionError('');
            setDegreeIdError('');
            setDisciplineIdError('');
            setDisciplineCourseIdError('');
            setBatchError('');
            setRegisterNoError('');
            setAnswerBookNoError('');
            setAttendanceError('');
            dispatch(fetchExamAttendance());
            clearHandler();
        }).catch((text) => {
            toast.error(text);
        });
    };

    const openEditModal = (
        id: number,
        examId: string,
        degreeId: string,
        disciplineId: string,
        disciplineCourseId: string,
        batch: string,
        registerNo: string,
        answerBookNo: string,
        attendance: string,
        date: string,
        session: string
    ) => {
        setId(id);
        setExamId(examId);
        setDegreeId(degreeId);
        setDisciplineId(disciplineId);
        setDisciplineCourseId(disciplineCourseId);
        setBatch(batch);
        setRegisterNo(registerNo);
        setAnswerBookNo(answerBookNo);
        setAttendance(attendance);
        setDate(date);
        setSession(session);
        console.log(id, examId, degreeId, disciplineId, disciplineCourseId);
        setEditModalShow(true);
    };

    const saveEdit = () => {
        let error = false
        // if (
        //     answerBookNo.length === 0 || attendance.length === 0 ||
        //     answerBookNo.length > 10 || attendance.length > 3
        // ) {
        //     if (answerBookNo.length === 0) {
        //         setAnswerBookNoError('Answer Book No is required');
        //     }
        //     if (attendance.length === 0) {
        //         setAttendanceError('Attendance is required');
        //     }
        //     if (answerBookNo.length > 10) {
        //         setAnswerBookNoError('Answer Book No must be within 10 characters');
        //     }
        //     if (attendance.length > 3) {
        //         setAttendanceError('Attendance is must be within 3 characters');
        //     }
        //     return;
        // }
        if (attendance !== '' && attendance.length > 3) {
            setAttendanceError('Attendance is must be within 3 characters');
            error = true
        }

        if (answerBookNo !== '' && answerBookNo.length > 10) {
            setAnswerBookNoError('Answer Book No must be within 10 characters');
            error = true
        }

        const data = {
            id: id,
            examId: parseInt(examId),
            degreeId: parseInt(degreeId),
            disciplineId: parseInt(disciplineId),
            batch: batch,
            registerNo: registerNo,
            date: date,
            session: session,
            disciplineCourseId: parseInt(disciplineCourseId),
            answerBookNo: answerBookNo,
            attendance: attendance,
        };

        if (!error) {
            dispatch(updateExamAttendance(data, id)).then(text => {
                toast.success(text);
                setExamIdError('');
                setDateError('');
                setSessionError('');
                setDegreeIdError('');
                setDisciplineIdError('');
                setDisciplineCourseIdError('');
                setBatchError('');
                setRegisterNoError('');
                setAnswerBookNoError('');
                setAttendanceError('');
                dispatch(fetchExamAttendance());
                setEditModalShow(false)
            }).catch(text => {
                toast.error(text)
            })
        }
    };

    const clearHandler = () => {
        setId(0);
        setEditFlag(false);
        setExamId('');
        setDate('');
        setSession('');
        setDegreeId('');
        setDisciplineId('');
        setDisciplineCourseId('');
        setBatch('');
        setRegisterNo('');
        setAnswerBookNo('');
        setAttendance('');
        setExamIdError('');
        setDateError('');
        setSessionError('');
        setDegreeIdError('');
        setDisciplineIdError('');
        setDisciplineCourseIdError('');
        setBatchError('');
        setRegisterNoError('');
        setAnswerBookNoError('');
        setAttendanceError('');
        setFilteredStudentList([]);
        setAttendanceMap({});
        setAnswerBookNoMap({});
    }
    const confirmDeleteHandler = (id: number) => {
        setId(id);
        openDeleteConfirmation();
    };

    const deleteHandler = () => {
        if (id !== undefined) {
            dispatch(deleteExamAttendance(id)).then(text => {
                toast.success(text)
                closeDeleteConfirmation();
            }).catch(text => {
                toast.error(text);
            })
        }
    }
    const openDeleteConfirmation = () => {
        setShowDeleteConfirmation(true);
    };

    const closeDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    };

    const getExamName = (examId: string) => {
        const exam = examList.find((c) => c.id === parseInt(examId));
        if (exam) {
            return exam.title;
        }
        return 'N/A';
    }

    const getDegreeName = (degreeId: string) => {
        const degree = degreeList.find((c) => c.id === parseInt(degreeId));
        if (degree) {
            return degree.name;
        }
        return 'N/A';
    }

    const getDisciplineName = (disciplineId: string) => {
        const discipline = disciplineList.find((c) => c.id === parseInt(disciplineId));
        if (discipline) {
            return discipline.name;
        }
        return 'N/A';
    }

    const getCourseName = (courseId: string) => {
        const disciplineCourse = disciplineCourseList.find((dc) => dc.id === parseInt(courseId));
        if (disciplineCourse) {
            const course = courseList.find((c) => c.id === disciplineCourse.courseId);
            if (course) {
                return course.name;
            }
        }
        return 'N/A';
    };

    const formatDate = (inputDate: string) => {
        const date = new Date(inputDate);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedMonth = month < 10 ? `0${month}` : month;

        return `${formattedDay}-${formattedMonth}-${year}`;
    };

    // const filterViewCourseList = () => {
    //     if (courseType === 'Theory' || courseType === 'theory') {

    //         if (selectDegree !== '' && selectDiscipline !== '' && selectBatch !== '' && selectExam !== '' && selectSession !== '' && selectDate !== '' && selectCourse !== '') {

    //             const filteredCourses = studentList.filter((course) => {
    //                 if (
    //                     (selectDegree === null || course.degreeId.toString() === selectDegree) &&
    //                     (selectDiscipline === null || course.disciplineId.toString() === selectDiscipline) &&
    //                     (selectBatch === '' || course.batch === selectBatch)
    //                 ) {
    //                     return true;
    //                 }
    //                 return false;
    //             });
    //             setFilteredStudentList(filteredCourses);
    //         } else {
    //             toast.error('Select All Filters', { position: 'top-right', autoClose: 3000 });
    //             setFilteredStudentList([])
    //         }
    //     } else if (courseType === 'Practical' || courseType === 'practical') {
    //         if (selectDegree !== '' && selectDiscipline !== '' && selectBatch !== '' && selectExam !== '' && selectCourse !== '') {

    //             const filteredCourses = studentList.filter((course) => {
    //                 if (
    //                     (selectDegree === null || course.degreeId.toString() === selectDegree) &&
    //                     (selectDiscipline === null || course.disciplineId.toString() === selectDiscipline) &&
    //                     (selectBatch === '' || course.batch === selectBatch)
    //                 ) {
    //                     return true;
    //                 }
    //                 return false;
    //             });
    //             setFilteredStudentList(filteredCourses);
    //         } else {
    //             toast.error('Select All Filters', { position: 'top-right', autoClose: 3000 });
    //             setFilteredStudentList([])
    //         }
    //     }
    // };

    const getSelectExam = (selectExam: any) => {
        const examName = examList.find((el) => el.id?.toString() === selectExam)?.title
        return examName;
        // console.log(examName);
    }

    const handleDownloadPDF = () => {

        const doc = new jsPDF('p', 'mm', 'a4');
        if (courseType === 'Theory' || courseType === 'theory' || courseType.toLowerCase() === 'theory cum practical') {
            doc.addImage(imgPath, 'PNG', 25, 15, 170, 30);

            doc.setFontSize(14);
            doc.setFont('Arial', 'normal', 'bold');
            doc.setTextColor(0, 0, 0);
            doc.text('EXAMINATION ATTENDANCE SHEET', 107, 50, { align: "center" });

            doc.rect(7, 7, 195, 285);

            const selectedDegreeName = degreeList?.find((degree) => degree.id === (selectDegree ? parseInt(selectDegree) : null))?.name || 'N/A';
            const selectedDisciplineName = disciplineList?.find((discipline) => discipline.id === (selectDiscipline ? parseInt(selectDiscipline) : null))?.name || 'N/A';
            doc.setFontSize(12);

            const firstTable = autoTable(doc, {
                didDrawPage: (data) => {
                    doc.setFontSize(10);
                    doc.setFont('normal');
                    doc.text(`${getSelectExam(selectExam)}`, data.settings.margin.left + 90, 56, { align: 'center' });
                },
                startY: 60,
                headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 9, halign: 'left' },
                bodyStyles: { textColor: [0, 0, 0] },
                head: [
                    [
                        // { content: `College Code & Name: 8213, PARISUTHAM INSTITUTE OF TECHNOLOGY AND SCIENCE `},
                        // { content: `Date of Examination: ${date}`},
                        { content: `Degree & Discipline: ${selectedDegreeName} - ${selectedDisciplineName}` },
                        { content: `Date of Examination: ${formattedDate}` },
                        // { content: `Course Code & Name:  ${selectedCourseCode} – ${selectedCourseName}` }
                    ],
                    [
                        // { content: `Degree & Discipline: ${selectedDegreeId} ${selectedDisciplineId}`},
                        { content: `Course Code & Name:  ${selectedCourseCode} – ${selectedCourseName}` },
                        { content: `Session: ${selectSession}` },
                    ],
                    [
                        // {content: `Course Code & Name:  ${selectedCourseCode} – ${selectedCourseName}`}
                    ]
                ],
                theme: 'plain',
            });
            //2nd Table
            //attendance_sheet_report
            // autoTable(doc, {
            //     startY: (doc as any).lastAutoTable.finalY,
            //     margin: { left: 8 },
            //     tableWidth: 193,
            //     headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0], fontSize: 8, halign: 'center' },
            //     bodyStyles: { textColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 8 },
            //     head: [
            //         [
            //             { content: 'S.No.' },
            //             { content: 'Register Number' },
            //             { content: 'Name of the Candidate' },
            //             { content: 'Answer Booklet No.', colSpan: 8 },
            //             { content: 'AB*' },
            //             { content: 'Signature of the Candidate' },
            //             // { content: 'Photo' },
            //         ]
            //     ],
            //     theme: 'grid',
            //     body: filterExamList.map((row, index) => [
            //         { content: index + 1, styles: { halign: 'center' } },
            //         { content: row.registerNo, styles: { halign: 'center' } },
            //         { content: row.name, styles: { halign: 'left' } },
            //         { content: "", styles: { halign: 'center', cellWidth: 6 } },
            //         { content: "", styles: { halign: 'center', cellWidth: 6 } },
            //         { content: "", styles: { halign: 'center', cellWidth: 6 } },
            //         { content: "", styles: { halign: 'center', cellWidth: 6 } },
            //         { content: "", styles: { halign: 'center', cellWidth: 6 } },
            //         { content: "", styles: { halign: 'center', cellWidth: 6 } },
            //         { content: "", styles: { halign: 'center', cellWidth: 6 } },
            //         { content: "", styles: { halign: 'center', cellWidth: 6 } },
            //         { content: "", styles: { halign: 'center' } },
            //         // { content: "", styles: { halign: 'center' } },
            //         //     {
            //         //     didDrawPage: (data: any) => {
            //         //          doc.addImage(imgProfile, 'PNG', 25, 15, 10, 10).toString()
            //         //     }       
            //         //    }
            //     ]),
            // });
            //attendance_result_report
            autoTable(doc, {
                startY: (doc as any).lastAutoTable.finalY,
                margin: { left: 8 },
                tableWidth: 193,
                headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0], fontSize: 8, halign: 'center' },
                bodyStyles: { textColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 8 },
                head: [
                    [
                        { content: 'S.No.' },
                        { content: 'Register Number' },
                        { content: 'Name of the Candidate' },
                        { content: 'Answer Booklet No.' },
                        { content: 'AB*' },
                        // { content: 'Signature of the Candidate' },
                        // { content: 'Photo' },
                    ]
                ],
                theme: 'grid',
                body: filterExamList.map((row, index) => [
                    { content: index + 1, styles: { halign: 'center' } },
                    { content: row.registerNo, styles: { halign: 'center' } },
                    { content: row.name, styles: { halign: 'left' } },
                    { content: row.answerBookNo, styles: { halign: 'center' } },
                    { content: row.attendance, styles: { halign: 'center' } },
                    // { content: "", styles: { halign: 'center' } },
                    //     {
                    //     didDrawPage: (data: any) => {
                    //          doc.addImage(imgProfile, 'PNG', 25, 15, 10, 10).toString()
                    //     }       
                    //    }
                ]),
            });

            doc.setFontSize(8);
            doc.text('*Hall Superintendent should mark ‘AB’ for Absent', 10, (doc as any).lastAutoTable.finalY + 5)

            const absentList = filterExamList.filter((at) => at.attendance === 'AB').length

            const presentList = filterExamList.filter((at) => at.attendance === 'PR').length

            console.log(presentList + "Hello");


            const additionalRowTopPart: RowInput[] = [
                [
                    { content: `TOTAL PRESENT :${presentList}`, styles: { fontStyle: 'bold', halign: 'left', lineWidth: 0.3, lineColor: [0, 0, 0], cellWidth: 100, } },
                    { content: `TOTAL ABSENT :${absentList}`, styles: { fontStyle: 'bold', halign: 'left', lineWidth: 0.3, lineColor: [0, 0, 0], cellWidth: 90 } },
                ],
                [
                    { content: 'Certified that the following particulars have been Verified:', styles: { cellPadding: { top: 6 } } },
                ],
            ]
            // Additional Rows
            const additionalRowsmiddlePart: RowInput[] = [
                // [
                //     { content: 'TOTAL PRESENT :', styles: { fontStyle: 'bold', halign: 'left', lineWidth: 0.3, lineColor: [0, 0, 0], cellWidth: 100, } },
                //     { content: 'TOTAL ABSENT :', styles: { fontStyle: 'bold', halign: 'left', lineWidth: 0.3, lineColor: [0, 0, 0], cellWidth: 90 } },
                // ],
                // [
                //     { content: 'Certified that the following particulars have been Verified:', styles: { cellPadding: { top: 6 } } },
                //     // { content: '*Hall Superintendent should mark ‘AB’ for Absent ', styles: { cellPadding: { left: 5, top: 6 } } },
                // ],
                [
                    { content: '1. ', styles: { cellWidth: 10, cellPadding: { top: 1, left: 3.5 } } },
                    { content: 'The Register No. in the attendance sheet with that in the hall ticket.', styles: { cellPadding: { top: 1 } } },
                ],
                [
                    { content: '2. ', styles: { cellWidth: 10, cellPadding: { left: 3.5 } } },
                    { content: 'The identification of the candidate with the photo given in the hall ticket.', styles: { cellPadding: {} } },
                ],
                [
                    { content: '3. ', styles: { cellWidth: 10, cellPadding: { left: 3.5 } } },
                    { content: 'The answer booklet number entered in the attendance sheet by the candidate with the Serial No. on the Answer Booklet.', styles: { cellPadding: { right: 12 } } },
                ],
                // [
                //     { content: 'Signature of the Hall Superintendent with Name and Designation', styles: { cellPadding: { top: 30, left: 2 }, fontSize: 8 } },
                //     { content: 'Signature of the Chief Superintendent with Name and Designation', styles: { cellPadding: { top: 30, right: 2 }, fontSize: 8 } },
                // ],
            ];
            const additionalRowLastPart: RowInput[] = [
                [
                    { content: 'Signature of the Hall Superintendent with Name and Designation', styles: { cellPadding: { top: 30, left: 2 }, fontSize: 8 } },
                    { content: 'Signature of the Chief Superintendent with Name and Designation', styles: { cellPadding: { top: 30, right: 2 }, fontSize: 8 } },
                ],
            ];
            //bottom table part-1(total)
            autoTable(doc, {
                startY: (doc as any).lastAutoTable.finalY + 10,
                margin: { left: 10 },
                tableWidth: 193,
                headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 8 },
                bodyStyles: { textColor: [0, 0, 0], fontStyle: 'bold', fontSize: 9 },
                body: additionalRowTopPart,
                theme: 'plain',

            });
            //bottom table part-2(text)
            autoTable(doc, {
                startY: (doc as any).lastAutoTable.finalY,
                margin: { left: 10 },
                tableWidth: 193,
                headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 8 },
                bodyStyles: { textColor: [0, 0, 0], fontStyle: 'bold', fontSize: 9 },
                body: additionalRowsmiddlePart,
                theme: 'plain',

            });
            //bottom table part-3(signature)
            autoTable(doc, {
                startY: (doc as any).lastAutoTable.finalY,
                margin: { left: 10 },
                tableWidth: 193,
                headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 8 },
                bodyStyles: { textColor: [0, 0, 0], fontStyle: 'bold', fontSize: 9 },
                body: additionalRowLastPart,
                theme: 'plain',

            });

            doc.save(fileName + '.pdf');
        } else if (courseType === 'Practical' || courseType === 'practical') {
            const recordLength = filterExamList.length / 25
            for (let i = 0; i < recordLength; i++) {
                let startLimit = 25 * i
                let endLimit = 25 * i + 25
                if (i > 0) {
                    doc.addPage(); // Add a new page for each hall report except the first one
                }
                doc.addImage(imgPath, 'PNG', 25, 9, 170, 30);
                doc.setFontSize(12);
                doc.setFont('Arial', 'normal', 'bold');
                doc.setTextColor(0, 0, 0);
                doc.text('EXAMINATION ATTENDANCE SHEET', 107, 40, { align: 'center' });

                doc.rect(7, 7, 195, 285);

                const selectedDegreeName = degreeList.find((degree) => degree.id === (selectDegree ? parseInt(selectDegree) : null))?.name || 'N/A';
                const selectedDisciplineName = disciplineList.find((discipline) => discipline.id === (selectDiscipline ? parseInt(selectDiscipline) : null))?.name || 'N/A';
                doc.setFontSize(12);

                const firstTable = autoTable(doc, {
                    didDrawPage: (data) => {
                        doc.setFontSize(10);
                        doc.setFont('normal');
                        doc.text(`${getSelectExam(selectExam)}`, data.settings.margin.left + 71, 46);
                    },
                    startY: 49,
                    headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 8, halign: 'left' },
                    bodyStyles: { textColor: [0, 0, 0] },
                    head: [
                        [
                            { content: `Degree & Discipline: ${selectedDegreeName} - ${selectedDisciplineName}` },
                            { content: `Date of Examination: ${formattedDate}` },
                        ],
                        [
                            { content: `Course Code & Name:  ${selectedCourseCode} – ${selectedCourseName}` },
                            { content: `Session: ${session}` },
                        ],
                    ],
                    theme: 'plain',
                });
                //2nd Table
                //attendance_sheet_report
                // autoTable(doc, {
                //     startY: (doc as any).lastAutoTable.finalY,
                //     margin: { left: 8 },
                //     tableWidth: 193,
                //     headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0], fontSize: 8, halign: 'center' },
                //     bodyStyles: { textColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 8 },
                //     head: [
                //         [
                //             { content: 'S.No.' },
                //             { content: 'Register Number' },
                //             { content: 'Name of the Candidate' },
                //             { content: 'Answer Booklet No.', colSpan: 8 },
                //             { content: 'AB*' },
                //             { content: 'Signature of the Candidate' },
                //             // { content: 'Photo' },
                //         ]
                //     ],
                //     theme: 'grid',
                //     body: filterExamList?.slice(startLimit, endLimit).map((row, index) => [
                //         { content: startLimit + index + 1, styles: { halign: 'center' } },
                //         { content: row.registerNo, styles: { halign: 'center' } },
                //         { content: row.name, styles: { halign: 'left' } },
                //         { content: "", styles: { halign: 'center', cellWidth: 6 } },
                //         { content: "", styles: { halign: 'center', cellWidth: 6 } },
                //         { content: "", styles: { halign: 'center', cellWidth: 6 } },
                //         { content: "", styles: { halign: 'center', cellWidth: 6 } },
                //         { content: "", styles: { halign: 'center', cellWidth: 6 } },
                //         { content: "", styles: { halign: 'center', cellWidth: 6 } },
                //         { content: "", styles: { halign: 'center', cellWidth: 6 } },
                //         { content: "", styles: { halign: 'center', cellWidth: 6 } },
                //         { content: "", styles: { halign: 'center' } },
                //     ]),
                // });
                //attendance_result_report
                autoTable(doc, {
                    startY: (doc as any).lastAutoTable.finalY,
                    margin: { left: 8 },
                    tableWidth: 193,
                    headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0], fontSize: 8, halign: 'center' },
                    bodyStyles: { textColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 8 },
                    head: [
                        [
                            { content: 'S.No.' },
                            { content: 'Register Number' },
                            { content: 'Name of the Candidate' },
                            { content: 'Answer Booklet No.' },
                            { content: 'AB*' },
                            // { content: 'Signature of the Candidate' },
                            // { content: 'Photo' },
                        ]
                    ],
                    theme: 'grid',
                    body: filterExamList.map((row, index) => [
                        { content: index + 1, styles: { halign: 'center' } },
                        { content: row.registerNo, styles: { halign: 'center' } },
                        { content: row.name, styles: { halign: 'left' } },
                        { content: row.answerBookNo, styles: { halign: 'center' } },
                        { content: row.attendance, styles: { halign: 'center' } },
                        // { content: "", styles: { halign: 'center' } },
                        //     {
                        //     didDrawPage: (data: any) => {
                        //          doc.addImage(imgProfile, 'PNG', 25, 15, 10, 10).toString()
                        //     }       
                        //    }
                    ]),
                });

                doc.setFontSize(8);
                doc.text('*Hall Superintendent should mark ‘AB’ for Absent', 10, (doc as any).lastAutoTable.finalY + 5)

                const absentList = filterExamList.filter((at) => at.attendance === 'AB').length

                const presentList = filterExamList.filter((at) => at.attendance === 'PR').length

                console.log(presentList + "Hello");


                const additionalRowTopPart: RowInput[] = [
                    [
                        { content: `TOTAL PRESENT :${presentList}`, styles: { fontStyle: 'bold', halign: 'left', lineWidth: 0.3, lineColor: [0, 0, 0], cellWidth: 100, } },
                        { content: `TOTAL ABSENT :${absentList}`, styles: { fontStyle: 'bold', halign: 'left', lineWidth: 0.3, lineColor: [0, 0, 0], cellWidth: 90 } },
                    ],
                    [
                        { content: 'Certified that the following particulars have been Verified:', styles: { cellPadding: { top: 6 } } },
                    ],
                ]
                // Additional Rows
                const additionalRowsmiddlePart: RowInput[] = [
                    [
                        { content: '1. ', styles: { cellWidth: 10, cellPadding: { top: 1, left: 3.5 } } },
                        { content: 'The Register No. in the attendance sheet with that in the hall ticket.', styles: { cellPadding: { top: 1 } } },
                    ],
                    [
                        { content: '2. ', styles: { cellWidth: 10, cellPadding: { left: 3.5 } } },
                        { content: 'The identification of the candidate with the photo given in the hall ticket.', styles: { cellPadding: {} } },
                    ],
                    [
                        { content: '3. ', styles: { cellWidth: 10, cellPadding: { left: 3.5 } } },
                        { content: 'The answer booklet number entered in the attendance sheet by the candidate with the Serial No. on the Answer Booklet.', styles: { cellPadding: { right: 12 } } },
                    ],
                ];

                const additionalRowLastPart: RowInput[] = [
                    [
                        { content: 'INTERNAL EXAMINER', styles: { halign: 'left', cellPadding: { top: 8, left: 10 }, fontSize: 8 } },
                        { content: 'EXTERNAL EXAMINER', styles: { halign: 'right', cellPadding: { top: 8, right: 10 }, fontSize: 8 } },
                    ],
                ];
                //bottom table part-1(total)
                autoTable(doc, {
                    startY: (doc as any).lastAutoTable.finalY + 8,
                    margin: { left: 10 },
                    tableWidth: 193,
                    headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 8 },
                    bodyStyles: { textColor: [0, 0, 0], fontStyle: 'bold', fontSize: 8 },
                    body: additionalRowTopPart,
                    theme: 'plain',

                });
                //bottom table part-2(text)
                autoTable(doc, {
                    startY: (doc as any).lastAutoTable.finalY,
                    margin: { left: 10 },
                    tableWidth: 193,
                    headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 8 },
                    bodyStyles: { textColor: [0, 0, 0], fontStyle: 'bold', fontSize: 8 },
                    body: additionalRowsmiddlePart,
                    theme: 'plain',

                });
                //bottom table part-3(signature)
                autoTable(doc, {
                    startY: (doc as any).lastAutoTable.finalY,
                    margin: { left: 10 },
                    tableWidth: 193,
                    headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 8 },
                    bodyStyles: { textColor: [0, 0, 0], fontStyle: 'bold', fontSize: 8 },
                    body: additionalRowLastPart,
                    theme: 'plain',

                });

            }
            doc.save(fileName + '.pdf');

        }
    };




    const csvData = [
        [
            'S.No',
            'Register Number',
            'Name of the Candidate',
            'AB*'
        ],
        ...filterExamList.map((student, index) => [
            index + 1,
            student.registerNo,
            student.name,
            student.attendance
        ]),
    ];

    return (
        <PageContainer title="Exam Attendance">
            <Card title="Add Exam Attendance">
                <div className="row">
                    <div className="col-md-4">
                        <label className="mt-2 mb-2">Degree <span className="text-danger">*</span> :</label>
                        <select
                            className="form-control"
                            value={degreeId || ''}
                            onChange={(e) => setDegreeId(e.target.value)}
                        >
                            <option value="">Select Degree</option>
                            {degreeList.map((dl) => (
                                <option key={dl.id} value={dl.id}>
                                    {dl.name}
                                </option>
                            ))}
                        </select>
                        {degreeIdError && <div className="text-danger">{degreeIdError}</div>}
                    </div>
                    <div className="col-md-4">
                        <label className="mt-2 mb-2">Discipline <span className="text-danger">*</span> :</label>
                        <select
                            className="form-control"
                            value={disciplineId || ''}
                            onChange={(e) => setDisciplineId(e.target.value)}
                        >
                            <option value="">Select Discipline</option>
                            {disciplineList.map((dpl) => (
                                <option key={dpl.id} value={dpl.id}>
                                    {dpl.name}
                                </option>
                            ))}
                        </select>
                        {disciplineIdError && <div className="text-danger">{disciplineIdError}</div>}
                    </div>
                    <div className="col-4">
                        <label className="mt-2 mb-2">Batch <span className="text-danger">*</span> : </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px" }}
                            name="selectedBatch"
                            value={batch}
                            onChange={(e) => setBatch(e.target.value)}
                        >
                            <option value="" selected>Select Batch</option>
                            {last10Years.map((year) => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                        </select>
                        {batchError && <div className="text-danger">{batchError}</div>}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <label className="mt-2 mb-2">Course <span className="text-danger">*</span> :</label>
                        <select
                            className="form-control"
                            value={disciplineCourseId}
                            onChange={(e) => {
                                setDisciplineCourseId(e.target.value)
                                setDisciplineCourseIdError('')
                            }}
                        >
                            <option value="">Select Course </option>
                            {courseList.map((course) => (
                                <option key={course.id} value={course.id}>
                                    {course.name}
                                </option>
                            ))}
                        </select>
                        {disciplineCourseIdError && <div className="text-danger">{disciplineCourseIdError}</div>}
                    </div>
                    <div className="col-md-4">
                        <label className="mt-2 mb-2">Session {isPracticalCourse ? "" : <span className="text-danger">*</span>} :</label>
                        <select
                            className="form-control"
                            value={session || ''}
                            onChange={(e) => {
                                setSession(e.target.value)
                                setSessionError('')
                            }}
                        >
                            <option value="">Select Session</option>
                            <option value="AN">AN</option>
                            <option value="FN">FN</option>
                        </select>
                        {sessionError && <div className="text-danger">{sessionError}</div>}
                    </div>
                    <div className="col-md-4">
                        <label className="mt-2 mb-2" htmlFor="examId">Exam <span className="text-danger">*</span> : </label>
                        <select
                            className="form-control"
                            name="examIdFilter"
                            value={examId}
                            onChange={(e) => {
                                setExamId(e.target.value)
                                setExamIdError('')
                            }}
                        >
                            <option value="" selected>Select Exam</option>
                            {examList.map((el) => (
                                <option key={el.id} value={el.id}>
                                    {el.title}
                                </option>
                            ))}
                        </select>
                        {examIdError && <div className="text-danger">{examIdError}</div>}
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <label className="mt-2 mb-2">Date {isPracticalCourse ? "" : <span className="text-danger">*</span>} :</label>
                        <input
                            className="form-control"
                            placeholder="Enter date..."
                            type="date"
                            name="date"
                            onChange={(e) => {
                                setDate(e.target.value)
                                setDateError('')
                            }}
                            value={date}
                        />
                        {dateError && <div className="text-danger">{dateError}</div>}
                    </div>

                    <div className="col-md-6 d-flex">
                        <div>
                            <label className="mt-2 mb-2">From Reg.No:</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder='From'
                                value={fromRegNo}
                                onChange={handleFromRegNoChange}
                            />
                        </div>
                        <span className='m-2 mt-5'>-</span>
                        <div> <label className="mt-2 mb-2">To Reg.No:</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder='To'
                                value={toRegNo}
                                onChange={handleToRegNoChange}
                            /></div>
                    </div>
                </div>
                <div className="col-12">
                    <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        onClick={filterCourseList}
                        style={{ width: "10%", marginTop: "50px", marginBottom: "50px", float: "right" }}
                    >
                        Search
                    </button>
                </div>
                <table className="table table-success table-striped">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Register Number</th>
                            <th>Name of the Candidate</th>
                            <th>Attendance</th>
                            {/* <th>Answer Booklet No</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {filteredStudentList?.sort((a, b) => parseInt(a.registerNo) - parseInt(b.registerNo))?.map((student, index) => {
                            console.log(student)
                            console.log(attendanceMap[student.id])
                            return (

                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{student.registerNo}</td>
                                    <td>{student.name}</td>
                                    <td>
                                        <input
                                            style={{ width: '10%', margin: "0px 30px" }}
                                            className="form-control"
                                            type="checkbox"
                                            value="PR"
                                            checked={(attendanceMap[index] === undefined || attendanceMap[index] === 'PR') ? true : false}
                                            onChange={(e) => handleCheckboxChange(index, e.target.checked)}
                                        />
                                    </td>
                                    {/* <td>
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Enter Answer Book No"
                                            value={answerBookNoMap[student.id] || ''}
                                            onChange={(e) => handleAnswerBookNoChange(student.id, e.target.value)}
                                            disabled={(attendanceMap[student.id] === undefined || attendanceMap[student.id] === 'PR') ? false : true}
                                        />
                                    </td> */}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <div className="m-4 d-flex justify-content-end">
                    <button className="btn btn-sm btn-secondary" onClick={clearHandler} style={{ marginRight: '10px' }}>
                        Clear
                    </button>
                    {/* {editFlag ? <button className="btn btn-success" onClick={updateHandler}>Update</button> :} */}
                    <button className="btn btn-sm btn-success" onClick={addHandler}>Add</button>
                </div>

            </Card>
            <CollapseCard title="Exam Attendance List">
                <div className="row m-1">
                    <div className="col-3">
                        <label className="mt-2 mb-2" htmlFor="examId">Exam  <span className="text-danger">*</span></label>
                        <select
                            className="form-control"
                            name="examIdFilter"
                            value={selectExam}
                            onChange={(e) => {
                                setSelectExam(e.target.value)
                                setSelectExamError('')
                            }}
                        >
                            <option value="" selected>Select Exam</option>
                            {examList?.map((el) => (
                                <option key={el.id} value={el.id}>
                                    {el.title}
                                </option>
                            ))}
                        </select>
                        {/* {selectExamError && <div className="text-danger">{selectExamError}</div>} */}
                    </div>
                    <div className="col-3">
                        <label className="mt-2 mb-2">Degree  <span className="text-danger">*</span></label>
                        <select
                            className="form-control"
                            value={selectDegree || ''}
                            onChange={(e) => {
                                setSelectDegree(e.target.value)
                                setSelectDegreeError('')
                            }}
                        >
                            <option value="">Select Degree</option>
                            {degreeList?.map((dl) => (
                                <option key={dl.id} value={dl.id}>
                                    {dl.name}
                                </option>
                            ))}
                        </select>
                        {/* {selectDegreeError && <div className="text-danger">{selectDegreeError}</div>} */}
                    </div>
                    <div className="col-3">
                        <label className="mt-2 mb-2">Discipline  <span className="text-danger">*</span></label>
                        <select
                            className="form-control"
                            value={selectDiscipline || ''}
                            onChange={(e) => {
                                setSelectDiscipline(e.target.value)
                                setSelectDisciplineError('')
                            }}
                        >
                            <option value="">Select Discipline</option>
                            {disciplineList?.map((dl) => (
                                <option key={dl.id} value={dl.id}>
                                    {dl.name}
                                </option>
                            ))}
                        </select>
                        {/* {selectDisciplineError && <div className="text-danger">{selectDisciplineError}</div>} */}
                    </div>
                    <div className="col-3">
                        <label className="mt-2 mb-2">Course  <span className="text-danger">*</span></label>
                        <select
                            className="form-control"
                            value={selectCourse || ''}
                            onChange={handleCourse}
                        >
                            <option value="">Select Course</option>
                            {courseList?.map((cl) => (
                                <option key={cl.id} value={cl.id}>
                                    {cl.name}
                                </option>
                            ))}
                        </select>
                        {/* // {selectCourseError && <div className="text-danger">{selectCourseError}</div>} */}
                    </div>
                    <div className="col-3">
                        <label className="mt-2 mb-2">Batch  </label>
                        <select
                            className="form-control"
                            name="selectedBatch"
                            value={selectBatch}
                            onChange={(e) => {
                                setSelectBatch(e.target.value)
                                setSelectBatchError('')
                            }}
                        >
                            <option value="" selected>Select Batch</option>
                            {last10Years?.map((year) => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                        </select>
                        {/* {selectBatchError && <div className="text-danger">{selectBatchError}</div>} */}
                    </div>
                    <div className="col-3">
                        <label className="mt-2 mb-2">Date  </label>
                        <input type="date"
                            className='form-control'
                            value={selectDate}
                            onChange={(e) => {
                                setSelectDate(e.target.value)
                                setSelectDateError('')
                            }}
                        />
                        {/* {selectDateError && <div className="text-danger">{selectDateError}</div>} */}
                    </div>
                    <div className="col-3">
                        <label className="mt-2 mb-2">Session  </label>
                        <select
                            className="form-control"
                            value={selectSession || ''}
                            onChange={(e) => {
                                setSelectSession(e.target.value)
                                setSelectSessionError('')
                            }}
                        >
                            <option value="">Select Session</option>
                            <option value="AN">AN</option>
                            <option value="FN">FN</option>
                        </select>
                        {/* {selectSessionError && <div className="text-danger">{selectSessionError}</div>} */}
                    </div>
                    {/* <div className="col-3">
                        <button className='btn btn-sm btn-primary mt-5 mb-3' onClick={filterList}>Search</button>
                    </div> */}
                    <div className="m-1 mb-3 mt-1 d-flex justify-content-end">
                        <button
                            type="button"
                            className="btn btn-sm btn-primary mt-5 mb-3"
                            style={{ marginRight: '10px' }}
                            onClick={filterList}
                        >
                            Search
                        </button>

                        <button
                            type="button"
                            className="btn btn-sm btn-secondary mt-5 mb-3"
                            onClick={resetData}
                        >Reset
                        </button>
                    </div>
                </div>
                <div style={{ margin: '0% 5% 2% 0%', float: 'right' }}>
                    {filterExamList?.length > 0 && <button
                        type="button"
                        onClick={handleDownloadPDF}
                        className="m-1 btn btn-sm btn-primary"
                    >
                        <Feather.Download /> PDF
                    </button>}
                    {filterExamList?.length > 0 && <CSVLink
                        data={csvData}
                        filename="details_of_course.csv"
                        className="m-1 btn btn-sm btn-primary"
                    >
                        <Feather.Download /> CSV
                    </CSVLink>}
                </div>
                <div className="container-fluid mt-3">
                    <table className="table table-success table-striped">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Exam</th>
                                <th>Degree</th>
                                <th>Discipline</th>
                                <th>Course</th>
                                <th>Batch</th>
                                <th>Register No</th>
                                <th>Name</th>
                                <th>Attendance</th>
                                <th>Date</th>
                                <th>Session</th>
                                <th>Options</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filterExamList.map((row, index: any) => (
                                <tr key={row.id}>
                                    <td>{index + 1}</td>
                                    <td>{getExamName(row.examId?.toString())}</td>
                                    <td>{getDegreeName(row.degreeId?.toString())}</td>
                                    <td>{getDisciplineName(row.disciplineId?.toString())}</td>
                                    <td>{getCourseName(row.disciplineCourseId?.toString())}</td>
                                    <td>{row.batch}</td>
                                    <td>{row.registerNo}</td>
                                    <td>{row.name}</td>
                                    <td>{row.attendance}</td>
                                    <td className='text-truncate'>{formatDate(row.date)}</td>
                                    <td>{row.session}</td>
                                    <td className='d-flex'>
                                        <button
                                            className="btn btn-sm btn-primary me-1"
                                            style={{ marginRight: '2px' }}
                                            onClick={() =>
                                                openEditModal(
                                                    row.id ? row.id : 0,
                                                    getExamName(row.examId?.toString()),
                                                    getDegreeName(row.degreeId?.toString()),
                                                    getDisciplineName(row.disciplineId?.toString()),
                                                    getCourseName(row.disciplineCourseId?.toString()),
                                                    row.batch,
                                                    row.registerNo,
                                                    row.answerBookNo,
                                                    row.attendance,
                                                    formatDate(row.date),
                                                    row.session
                                                )
                                            }
                                        >
                                            Edit
                                        </button>
                                        <button
                                            className="btn btn-sm btn-danger"
                                            onClick={() => confirmDeleteHandler(row.id ? row.id : 0)}
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}

                        </tbody>

                    </table>
                </div>
            </CollapseCard>

            <Modal show={editModalShow} onHide={() => setEditModalShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Exam Attendance</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-3">
                            <label className="mt-2 mb-2">Exam:</label>
                            <input className="form-control" type="text" value={examId} readOnly />
                        </div>
                        <div className="col-6">
                            <label className="mt-2 mb-2">Degree:</label>
                            <input className="form-control" type="text" value={degreeId} readOnly />
                        </div>
                        <div className="col-6">
                            <label className="mt-2 mb-2">Discipline:</label>
                            <input className="form-control" type="text" value={disciplineId} readOnly />
                        </div>
                        <div className="col-6">
                            <label className="mt-2 mb-2">Discipline Course:</label>
                            <input className="form-control" type="text" value={disciplineCourseId} readOnly />
                        </div>
                        <div className="col-6">
                            <label className="mt-2 mb-2">Batch:</label>
                            <input className="form-control" type="text" value={batch} readOnly />
                        </div>
                        <div className="col-6">
                            <label className="mt-2 mb-2">Register No:</label>
                            <input className="form-control" type="text" value={registerNo} readOnly />
                        </div>
                        <div className="col-6">
                            <label className="mt-2 mb-2">Date:</label>
                            <input className="form-control" type="text" value={date} readOnly />
                        </div>
                        <div className="col-6">
                            <label className="mt-2 mb-2">Session:</label>
                            <input className="form-control" type="text" value={session} readOnly />
                        </div>
                        <div className="col-6">
                            <label className="mt-2 mb-2">Attendance:</label><br />
                            <input
                                type="checkbox"
                                name="attendance"
                                onChange={(e) => setAttendance(e.target.checked ? 'PR' : 'AB')}
                                checked={attendance === 'PR'}
                            />
                            {attendanceError && <div className="text-danger">{attendanceError}</div>}
                        </div>
                        <div className="col-6">
                            <label className="mt-2 mb-2">Answer Book No:</label>
                            <input
                                className="form-control"
                                placeholder="Enter answer book no..."
                                type="text"
                                name="answerbookno"
                                onChange={(e) => setAnswerBookNo(e.target.value)}
                                value={answerBookNo}
                            />
                            {answerBookNoError && <div className="text-danger">{answerBookNoError}</div>}
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setEditModalShow(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={saveEdit}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Deletion Confirmation Modal */}
            <Modal show={showDeleteConfirmation} onHide={closeDeleteConfirmation}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeDeleteConfirmation}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={deleteHandler}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </PageContainer>
    );
}

export default ExamAttendance;

interface Student {
    id: number;
    registerNo: string;
    regulation: number;
    departmentRegisterNumber: string;
    name: string;
    fatherName: string;
    motherName: string;
    guardianName: string;
    bloodGroup: string;
    gender: string;
    dateOfBirth: string;
    aadharNumber: string;
    address: string;
    pincode: number;
    emailId: string;
    contactNumber: string;
    parentContactNumber: number;
    community: string;
    caste: string;
    religion: string;
    state: string;
    nationality: string;
    motherTongue: string;
    degreeId: number;
    disciplineId: number;
    modeOfAdmission: string;
    batch: string;
    entryLevel: string;
    dateOfJoin: string;
    noOfYears: number;
    currentSemester: number;
    photoUrl: string;
    createdBy: string;
    createTimestamp: string;
}