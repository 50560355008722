/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { GetState } from "../reducers"

export enum DisciplineCourseActionList {
    ADD_DISCIPLINECOURSE = 'ADD_DISCIPLINECOURSE',
    FETCH_DISCIPLINECOURSE = 'FETCH_DISCIPLINECOURSE',
    UPDATE_DISCIPLINECOURSE = 'UPDATE_DISCIPLINECOURSE',
    DELETE_DISCIPLINECOURSE = 'DELETE_DISCIPLINECOURSE',
}

export interface DisciplineCourseType {
    id?: number
    disciplineId: number
    degreeId: number
    courseId: number
    courseHead: string
    courseCategory: string
    courseType: string
    courseSeqNo: number
    semesterNo: number
    contactHours: number
    credit: number
    lecture: string
    tutorial: string
    practical: string
    intConversion: number
    extConversion: number
    regulation: string
    examFees: number
}

export interface AddDisciplineCourseAction {
    type: DisciplineCourseActionList.ADD_DISCIPLINECOURSE
    data: DisciplineCourseType
}

export interface FetchDisciplineCourseAction {
    type: DisciplineCourseActionList.FETCH_DISCIPLINECOURSE
    data: Array<DisciplineCourseType>
}

export interface UpdateDisciplineCourseAction {
    type: DisciplineCourseActionList.UPDATE_DISCIPLINECOURSE
    data: DisciplineCourseType
}

export interface DeleteDisciplineCourseAction {
    type: DisciplineCourseActionList.DELETE_DISCIPLINECOURSE
    data: number
}

export type DisciplineCourseActions = AddDisciplineCourseAction | FetchDisciplineCourseAction | UpdateDisciplineCourseAction | DeleteDisciplineCourseAction

export const addDisciplineCourse = (data: DisciplineCourseType) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.post<APIResponse<DisciplineCourseType>>('disciplineCourse/', data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<AddDisciplineCourseAction>({
                    type: DisciplineCourseActionList.ADD_DISCIPLINECOURSE,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Discipline Course Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })
    }
}

export const updateDisciplineCourse = (data: DisciplineCourseType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.put<APIResponse<DisciplineCourseType>>(`disciplineCourse/?id=${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<UpdateDisciplineCourseAction>({
                    type: DisciplineCourseActionList.UPDATE_DISCIPLINECOURSE,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Discipline Course Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchDisciplineCourse = () => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<DisciplineCourseType[]>>('disciplineCourse/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchDisciplineCourseAction>({
                    type: DisciplineCourseActionList.FETCH_DISCIPLINECOURSE,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteDisciplineCourse = (id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.delete<APIResponse<DisciplineCourseType>>(`disciplineCourse/?id=${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteDisciplineCourseAction>({
                    type: DisciplineCourseActionList.DELETE_DISCIPLINECOURSE,
                    data: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Discipline Course Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}
