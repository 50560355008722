import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Email } from '../components/common/functions';
import Card from '../components/custom/Card';
import CollapseCard from '../components/custom/CollapseCard';
import PageContainer from '../components/custom/PageContainer';
import { DisciplineType } from '../redux/actions/disciplineAction';
import { DropDownType } from '../redux/actions/dropDownAction';
import { FacultyType, addFaculty, deleteFaculty, updateFaculty } from '../redux/actions/facultyActions';
import { StoreState } from '../redux/reducers';
import { AppDispatch } from '../redux/store';


const Faculty = () => {

    const dispatch = useDispatch<AppDispatch>();
    const facultyList = useSelector<StoreState, FacultyType[]>(state => state.faculty);
    const disciplineList = useSelector<StoreState, DisciplineType[]>(state => state.discipline);
    const dropDownList = useSelector<StoreState, DropDownType[]>(state => state.dropDown);

    // useEffect(() => {
    //     dispatch(fetchFaculty())
    //     dispatch(fetchDropDown())
    // }, [])

    const [editFlag, setEditFlag] = useState(false);
    const [code, setCode] = useState('');
    const [name, setName] = useState('');
    const [designation, setDesignation] = useState('');
    const [institute, setInstitute] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [discipline, setDiscipline] = useState('');
    const [disciplineError, setDisciplineError] = useState('');
    const [emailId, setEmailId] = useState('');
    const [emailIdError, setEmailIdError] = useState('');
    const [experience, setExperience] = useState('')
    const [experienceError, setExperienceError] = useState('')
    const [id, setId] = useState<number>(0);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [nameError, setNameError] = useState('');
    const [codeError, setCodeError] = useState('');
    const [designationError, setDesignationError] = useState('');
    const [instituteError, setInstituteError] = useState('');
    const [contactNumberError, setContactNumberError] = useState('');

    const clearHandler = () => {
        setCode('');
        setName('');
        setDesignation('');
        setInstitute('');
        setContactNumber('');
        setDiscipline('');
        setDisciplineError('');
        setEmailId('');
        setEmailIdError('');
        setExperience('');
        setExperienceError('');
        setId(0);
        setNameError('');
        setCodeError('');
        setDesignationError('');
        setInstituteError('');
        setContactNumberError('');
        setEditFlag(false);
    };

    const addHandler = () => {
        let error = false

        if (code.length === 0) {
            setCodeError('Code required');
            error = true;
        }
        if (name.length === 0) {
            setNameError('Name required');
            error = true;
        }
        if (designation.length === 0) {
            setDesignationError('Designation required');
            error = true;
        }
        if (institute.length === 0) {
            setInstituteError('Institute required');
            error = true;
        }
        if (contactNumber.length === 0) {
            setContactNumberError('Contact Number required');
            error = true;
        }
        if (code.length > 10) {
            setCodeError('Code must be within 10 characters');
            error = true;
        }
        if (name.length > 100) {
            setNameError('Name must be within 100 characters');
            error = true;
        }
        if (designation.length > 30) {
            setDesignationError('Designation must be within 30 characters');
            error = true;
        }
        if (institute.length > 100) {
            setInstituteError('Institute must be within 100 characters');
            error = true;
        }
        if (contactNumber.length < 10 || contactNumber.length > 10) {
            setContactNumberError('Contact number must be  10 characters');

        }
        if (discipline.length === 0) {
            setDisciplineError('Disicipline required');
            error = true;
        }

        if (emailId.length === 0) {
            setEmailIdError('Email Id required');
        } else if (!Email.test(emailId)) {
            setEmailIdError('Invalid email');
        }

        if (experience.length === 0) {
            setExperienceError('Experience required');
        }

        const addData = {
            code,
            name,
            designation,
            discipline: discipline,
            institute,
            emailId: emailId,
            contactNumber: contactNumber,
            experience: parseInt(experience)
        };


        if (!error) {
            dispatch(addFaculty(addData)).then(text => {
                toast.success(text)
                clearHandler();
            }).catch(text => {
                toast.error(text)
            })
        }
    };

    const confirmDeleteHandler = (id: number) => {
        setId(id);
        openDeleteConfirmation();
    };

    const deleteHandler = () => {
        if (id !== undefined) {
            dispatch(deleteFaculty(id)).then(text => {
                toast.success(text)
                closeDeleteConfirmation();
            }).catch(text => {
                toast.error(text);
            })
        }
    }

    const editHandler = (
        id: number,
        code: string,
        name: string,
        designation: string,
        discipline: string,
        institute: string,
        emailId: string,
        contactNumber: string,
        experience: number
    ) => {
        clearHandler()
        setEditFlag(true);
        setId(id);
        setCode(code);
        setName(name);
        setDesignation(designation);
        setDiscipline(discipline?.toString());
        setInstitute(institute);
        setEmailId(emailId);
        setContactNumber(contactNumber);
        setExperience(experience?.toString());
    };

    const updateHandler = () => {
        let error = false

        if (code.length === 0) {
            setCodeError('Code required');
            error = true;
        }
        if (name.length === 0) {
            setNameError('Name required');
            error = true;
        }
        if (designation.length === 0) {
            setDesignationError('Designation required');
            error = true;
        }
        if (institute.length === 0) {
            setInstituteError('Institute required');
            error = true;

        }
        if (contactNumber.length === 0) {
            setContactNumberError('Contact Number required');
            error = true;
        }
        if (code.length > 10) {
            setCodeError('Code must be within 10 characters');
            error = true;
        }
        if (name.length > 100) {
            setNameError('Name must be within 100 characters');
            error = true;
        }
        if (designation.length > 30) {
            setDesignationError('Designation must be within 30 characters');
            error = true;
        }
        if (institute.length > 100) {
            setInstituteError('Institute must be within 100 characters');
            error = true;
        }
        if (contactNumber.length > 10 || contactNumber.length < 10) {
            setContactNumberError('Contact Number must be within 10 characters');
            error = true;
        }
        if (discipline.length === 0) {
            setDisciplineError('Disicipline required');
            error = true;
        }

        if (emailId.length === 0) {
            setEmailIdError('Email Id required');
        } else if (!Email.test(emailId)) {
            setEmailIdError('Invalid email');
        }

        if (experience.length === 0) {
            setExperienceError('Experience required');
            error = true
        }
        else if (experience.length > 10) {
            setExperienceError('Experience must be within 10 chars')
            error = true
        }

        const editData = {
            id,
            code,
            name,
            designation,
            discipline: discipline,
            institute,
            emailId,
            contactNumber: contactNumber,
            experience: parseInt(experience)
        };

        if (!error) {
            dispatch(updateFaculty(editData, id)).then(text => {
                toast.success(text)
                clearHandler();
            }).catch(text => {
                toast.error(text)
            })
        }
    };

    const openDeleteConfirmation = () => {
        setShowDeleteConfirmation(true);
    };

    const closeDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    };

    const getDiscipline = (discipline: any) => {
        const disciplineName = disciplineList.find((dl) => dl.id === parseInt(discipline))?.name
        return disciplineName
    }

    return (
        <PageContainer title="Faculty">
            <Card title={editFlag ? "Edit Faculty" : "Add Faculty"}>
                <div className='container m-0'>
                    <div className="row">

                        <div className="col-4">
                            <label className="mt-3 mb-3">Code <span className="text-danger">*</span> : </label>
                            <input className="form-control" placeholder="Enter code..." type="text" name="code" onChange={(e) => {
                                setCode(e.target.value)
                                setCodeError('')
                            }} value={code} />
                            {codeError && <div className="text-danger">{codeError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Name <span className="text-danger">*</span> : </label>
                            <input className="form-control" placeholder="Enter name..." type="text" name="name" onChange={(e) => {
                                setName(e.target.value)
                                setNameError('')
                            }} value={name} />
                            {nameError && <div className="text-danger">{nameError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Designation <span className="text-danger">*</span> :</label>
                            <select
                                className="form-control"
                                value={designation}
                                onChange={(e) => {
                                    setDesignation(e.target.value)
                                    setDesignationError('')
                                }}
                            >
                                <option value="">Select designation</option>
                                {dropDownList.filter(dd => dd.category === 'Designation').map((dis) => (
                                    <option value={dis.title}>
                                        {dis.title}
                                    </option>
                                ))}
                            </select>
                            {designationError && <div className="text-danger">{designationError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Discipline<span className="text-danger">*</span> :</label>
                            <select
                                className="form-control"
                                value={discipline}
                                onChange={(e) => {
                                    setDiscipline(e.target.value)
                                    setDisciplineError('')
                                }}
                            >
                                <option value="">Select disciplines</option>
                                {dropDownList.filter(dd => dd.category === 'Discipline').map((dis) => (
                                    <option value={dis.title}>
                                        {dis.title}
                                    </option>
                                ))}
                            </select>
                            {disciplineError && <div className="text-danger">{disciplineError}</div>}
                        </div>


                        <div className="col-4">
                            <label className="mt-3 mb-3">Institute <span className="text-danger">*</span> : </label>
                            <input className="form-control" placeholder="Enter institute..." type="text" name="institute" onChange={(e) => {
                                setInstitute(e.target.value)
                                setInstituteError('')
                            }} value={institute} />
                            {instituteError && <div className="text-danger">{instituteError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">E-Mail<span className="text-danger">*</span> : </label>
                            <input className="form-control" placeholder="Enter e-mail..." type="email" name="emailId" onChange={(e) => {
                                setEmailId(e.target.value)
                                setEmailIdError('')
                            }} value={emailId} />
                            {emailIdError && <div className="text-danger">{emailIdError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Contact Number <span className="text-danger">*</span> : </label>
                            <input className="form-control" placeholder="Enter contact number..." type="number" name="contactNumber" onChange={(e) => {
                                setContactNumber(e.target.value)
                                setContactNumberError('')
                            }} value={contactNumber} />
                            {contactNumberError && <div className="text-danger">{contactNumberError}</div>}
                        </div>
                        <div className="col-4">
                            <label className="mt-3 mb-3">Total Experience<span className="text-danger">*</span> : </label>
                            <input className="form-control" placeholder="Enter experience..." type="number" name="experience" onChange={(e) => {
                                setExperience(e.target.value)
                                setExperienceError('')
                            }} value={experience} />
                            {experienceError && <div className="text-danger">{experienceError}</div>}
                        </div>
                    </div>
                </div>
                <div className=" col mt-5 m-4 d-flex justify-content-end">
                    <button className="btn btn-sm btn-secondary" onClick={clearHandler} style={{ marginRight: '10px' }}>
                        Clear
                    </button>
                    {editFlag ? <button className="btn btn-sm  btn-success" onClick={updateHandler}>Update</button> :
                        <button className="btn btn-sm btn-success" onClick={addHandler}>Add</button>}
                </div>
            </Card>
            <CollapseCard title="Faculty">
                <div className="container-fluid" style={{ width: '100%' }}>
                    <table className="table table-success table-striped">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Code</th>
                                <th>Name</th>
                                <th>Designation</th>
                                <th>Discipline</th>
                                <th>Institute</th>
                                <th>E-Mail</th>
                                <th>Contact Number</th>
                                <th>Experience</th>
                                <th>Options</th>
                            </tr>
                        </thead>
                        <tbody>
                            {facultyList.map((faculty, index) => (
                                <tr key={faculty.id}>
                                    <td>{index + 1}</td>
                                    <td>{faculty.code}</td>
                                    <td>{faculty.name}</td>
                                    <td>{faculty.designation}</td>
                                    <td>{faculty.discipline}</td>
                                    <td>{faculty.institute}</td>
                                    <td>{faculty.emailId}</td>
                                    <td>{faculty.contactNumber}</td>
                                    <td>{faculty.experience}</td>
                                    <td>
                                        <button
                                            className="btn btn-sm btn-primary"
                                            style={{ marginRight: '10px' }}
                                            onClick={() =>
                                                editHandler(
                                                    faculty.id ? faculty.id : 0,
                                                    faculty.code,
                                                    faculty.name,
                                                    faculty.designation,
                                                    faculty.discipline,
                                                    faculty.institute,
                                                    faculty.emailId,
                                                    faculty.contactNumber,
                                                    faculty.experience,
                                                )
                                            }
                                        >
                                            Edit
                                        </button>
                                        <button
                                            className="btn btn-sm btn-danger"
                                            onClick={() => confirmDeleteHandler(faculty.id ? faculty.id : 0)}
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </CollapseCard>
            {/* Deletion Confirmation Modal */}
            <Modal show={showDeleteConfirmation} onHide={closeDeleteConfirmation}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeDeleteConfirmation}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={deleteHandler}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </PageContainer>
    );
};

export default Faculty;
