/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { GetState } from "../reducers"

export enum ExamFacultyActionList {
    ADD_EXAMFACULTY = 'ADD_EXAMFACULTY',
    FETCH_EXAMFACULTY = 'FETCH_EXAMFACULTY',
    UPDATE_EXAMFACULTY = 'UPDATE_EXAMFACULTY',
    DELETE_EXAMFACULTY = 'DELETE_EXAMFACULTY',
}

export interface ExamFacultyType {
    id?: number
    examId: number
    degreeId: number
    disciplineId: number
    facultyId: number
    type: string
}

export interface AddExamAction {
    type: ExamFacultyActionList.ADD_EXAMFACULTY
    data: ExamFacultyType
}

export interface FetchExamFacultyAction {
    type: ExamFacultyActionList.FETCH_EXAMFACULTY
    data: Array<ExamFacultyType>
}

export interface UpdateExamFacultyAction {
    type: ExamFacultyActionList.UPDATE_EXAMFACULTY
    data: ExamFacultyType
}

export interface DeleteExamFacultyAction {
    type: ExamFacultyActionList.DELETE_EXAMFACULTY
    data: number
}

export type ExamFacultyActions = AddExamAction | FetchExamFacultyAction | UpdateExamFacultyAction | DeleteExamFacultyAction

export const addExamFaculty = (data: ExamFacultyType) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.post<APIResponse<ExamFacultyType>>('examFaculty/', data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<AddExamAction>({
                    type: ExamFacultyActionList.ADD_EXAMFACULTY,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Exam Faculty Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })
    }
}

export const updateExamFaculty = (data: ExamFacultyType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.put<APIResponse<ExamFacultyType>>(`examFaculty/?id=${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<UpdateExamFacultyAction>({
                    type: ExamFacultyActionList.UPDATE_EXAMFACULTY,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Exam Faculty Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchExamFaculty = () => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<ExamFacultyType[]>>('examFaculty/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchExamFacultyAction>({
                    type: ExamFacultyActionList.FETCH_EXAMFACULTY,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteExamFaculty = (id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.delete<APIResponse<ExamFacultyType>>(`examFaculty/?id=${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteExamFacultyAction>({
                    type: ExamFacultyActionList.DELETE_EXAMFACULTY,
                    data: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Exam Faculty Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}
