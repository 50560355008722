import React, { FC } from "react";
import {Link} from 'react-router-dom';
const TopNavBar:FC = () =>{
    return(
        <nav className="main-header navbar navbar-expand navbar-blue navbar-light">
        { 
        // Left navbar links 
      }
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link className="nav-link" data-widget="pushmenu" to="#" role="button"><i className="fas fa-bars"></i></Link>
          </li>
           {/*
          <li className="nav-item d-none d-sm-inline-block">
            <a href="../../index3.html" className="nav-link">Home</a>
          </li>
          <li className="nav-item d-none d-sm-inline-block">
            <a href="#" className="nav-link">Contact</a>
          </li>
           */}
        </ul>
        <h5 style={{textTransform:"uppercase"}}>Parisutham Institute of Technology & Science</h5>
    
        { 
        // Right navbar links 
      }
        <ul className="navbar-nav ml-auto">
         
          <li className="nav-item">
            <a className="nav-link" data-widget="fullscreen" href="#" role="button">
              <i className="fas fa-expand-arrows-alt"></i>
            </a>
          </li>
          {/* <li className="nav-item">
            <a className="nav-link" data-widget="control-sidebar" data-slide="true" href="#" role="button">
              <i className="fas fa-th-large"></i>
            </a>
          </li> */}
        </ul>
      </nav>
    )
}
export default TopNavBar;