import { DisciplineCourseActionList, DisciplineCourseType } from "../actions/disciplineCourseAction";
import { Action } from "../actions/types";

const initialValue: Array<DisciplineCourseType> = [];

const disciplineCourseReducer = (state: Array<DisciplineCourseType> = initialValue, action: Action) => {
    switch (action.type) {
        case DisciplineCourseActionList.ADD_DISCIPLINECOURSE:
            return [
                ...state,
                action.data,
            ];

        case DisciplineCourseActionList.UPDATE_DISCIPLINECOURSE:
            const updated = [...state].slice();
            const index = updated.findIndex(u => u.id === action.data.id);

            if (index > -1) {
                updated[index] = {
                    ...action.data,
                };
            }

            return updated;

        case DisciplineCourseActionList.FETCH_DISCIPLINECOURSE:
            return action.data;

        case DisciplineCourseActionList.DELETE_DISCIPLINECOURSE:
            return state.filter(s => s.id !== action.data);

        default:
            return state;
    }
}

export default disciplineCourseReducer;



