import axios from 'axios';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from '../components/custom/Card';
import CollapseCard from '../components/custom/CollapseCard';
import PageContainer from '../components/custom/PageContainer';
import { apiUrl } from '../config';
import { CollegeType } from '../redux/actions/collegeActions';

const College = () => {
    const [collegeList, setCollegeList] = useState<CollegeType[]>([]);

    const [editFlag, setEditFlag] = useState(false);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [address, setAddress] = useState('');
    const [contact, setContact] = useState('');
    const [examCenterName, setExamCenterName] = useState('');
    const [examCenterCode, setExamCenterCode] = useState('');
    const [logoUrl, setLogoUrl] = useState<any | null>({ name: '' });
    const [id, setId] = useState<number>(0);
    const [createdBy, setCreatedBy] = useState<string>('');
    const [createTimestamp, setCreateTimestamp] = useState<string>('');
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [nameError, setNameError] = useState('');
    const [descriptionError, setDescriptionError] = useState('');
    const [addressError, setAddressError] = useState('');
    const [contactError, setContactError] = useState('');
    const [examCenterNameError, setExamCenterNameError] = useState('');
    const [examCenterCodeError, setExamCenterCodeError] = useState('');
    const [logoUrlError, setLogoUrlError] = useState('');
    const [apiErrorMessage, setApiErrorMessage] = useState('');

    const [imageFlag, setImageFlag] = useState<boolean>(false)


    useEffect(() => {
        fetchData();
        // fetchImage();
    }, []);

    const fetchData = () => {
        axios.get<{ data: CollegeType[] }>(`${apiUrl}/college/`)
            .then((response) => {
                setCollegeList(response.data.data);
            })
            .catch((error) => console.log(error));
    }


    const addHandler = () => {
        if (name.length === 0) {
            setNameError('Name is required');
            return;
        }
        if (description.length === 0) {
            setDescriptionError('description is required');
            return;
        }
        if (address.length === 0) {
            setAddressError('address is required');
            return;
        }
        if (contact.length === 0) {
            setContactError('contact is required');
            return;
        }
        if (examCenterName.length === 0) {
            setExamCenterNameError('examCenterName is required');
            return;
        }
        if (examCenterCode.length === 0) {
            setExamCenterCodeError('examCenterCode is required');
            return;
        }
        if (logoUrl.length === 0) {
            setLogoUrlError('logoUrl is required');
            return;
        }
        if (name.length > 50) {
            setNameError('Name must be within 50 characters');
            return;
        }
        if (description.length > 100) {
            setDescriptionError('description must be within 100 characters');
            return;
        }
        if (address.length > 100) {
            setAddressError('address must be within 100 characters');
            return;
        }
        if (contact.length > 50) {
            setContactError('contact must be within 50 characters');
            return;
        }
        if (examCenterName.length > 50) {
            setExamCenterNameError('examCenterName must be within 50 characters');
            return;
        }
        if (examCenterCode.length > 10) {
            setExamCenterCodeError('examCenterCode must be within 10 characters');
            return;
        }



        //   const data = { name, description, address, contact, examCenterName, examCenterCode, logoUrl, createdBy, createTimestamp };
        // console.log(data);

        const data = new FormData()
        data.append('name', name)
        data.append('description', description)
        data.append('address', address)
        data.append('contact', contact)
        data.append('examCenterName', examCenterName)
        data.append('examCenterCode', examCenterCode)
        data.append('logoUrl', logoUrl ? logoUrl : '')

        // console.log(data);

        axios.post(`${apiUrl}/college/`, data)
            .then((response) => {
                if (response.data.error) {
                    toast.error(response.data.message, { position: 'top-right' });
                    setApiErrorMessage(response.data.message);
                } else {
                    toast.success(response.data.message, { position: 'top-right' });
                    setNameError('');
                    setDescriptionError('');
                    setAddressError('');
                    setContactError('');
                    setExamCenterNameError('');
                    setExamCenterCodeError('');
                    setLogoUrlError('');
                    setApiErrorMessage('');
                    fetchData();
                    clearHandler();
                }
            })
            .catch((error) => toast.error(error.response.data.message));
    }


    const deleteHandler = (id: number) => {
        setId(id);
        openDeleteConfirmation(); // Open the delete confirmation modal
    }

    const confirmDeleteHandler = () => {
        axios
            .delete(`${apiUrl}/college/?id=${id}`)
            .then((response) => {
                toast.success(response.data.message, { position: 'top-right' });
                fetchData();
                closeDeleteConfirmation();
            })
            .catch((error) => toast.error(error.response.message));
    }

    const editHandler = (id: number, name: string, description: string, address: string, contact: string, examCenterName: string, examCenterCode: string, logoUrl: string, createdBy: string, createTimestamp: string) => {
        setEditFlag(true);
        setId(id);
        setName(name);
        setDescription(description);
        setAddress(address);
        setContact(contact);
        setExamCenterName(examCenterName);
        setExamCenterCode(examCenterCode);
        setLogoUrl(logoUrl ? logoUrl : '');
        setCreatedBy(createdBy);
        setCreateTimestamp(createTimestamp);
    }

    const updateHandler = () => {
        if (name.length === 0) {
            setNameError('Name is required');
            return;
        }
        if (description.length === 0) {
            setDescriptionError('description is required');
            return;
        }
        if (address.length === 0) {
            setAddressError('address is required');
            return;
        }
        if (contact.length === 0) {
            setContactError('contact is required');
            return;
        }
        if (examCenterName.length === 0) {
            setExamCenterNameError('examCenterName is required');
            return;
        }
        if (examCenterCode.length === 0) {
            setExamCenterCodeError('examCenterCode is required');
            return;
        }
        // if (logoUrl.length === 0) {
        //     setLogoUrlError('logoUrl is required');
        //     return;
        // }
        if (name.length > 30) {
            setNameError('Name must be within 30 characters');
            return;
        }
        if (description.length > 30) {
            setDescriptionError('description must be within 100 characters');
            return;
        }
        if (address.length > 30) {
            setAddressError('address must be within 100 characters');
            return;
        }
        if (contact.length > 30) {
            setContactError('contact must be within 50 characters');
            return;
        }
        if (examCenterName.length > 30) {
            setExamCenterNameError('examCenterName must be within 50 characters');
            return;
        }
        if (examCenterCode.length > 30) {
            setExamCenterCodeError('examCenterCode must be within 10 characters');
            return;
        }


        const data = new FormData()
        // data.append('id',id)
        data.append('name', name)
        data.append('description', description)
        data.append('address', address)
        data.append('contact', contact)
        data.append('examCenterName', examCenterName)
        data.append('examCenterCode', examCenterCode)
        data.append('logoUrl', logoUrl)
        if (imageFlag) {
            data.append('oldImageUrl', logoUrl)
        }
        // const data = { id, name, description, address, contact, examCenterName, examCenterCode, logoUrl, createdBy, createTimestamp };
        axios.post(`${apiUrl}/college/?id=${id}`, data)
            .then((response) => {
                if (response.data.error) {
                    toast.error(response.data.message, { position: 'top-right' });
                    setApiErrorMessage(response.data.message);
                } else {
                    console.log(response.data);
                    toast.success(response.data.message, { position: 'top-right' });
                    setNameError('');
                    setDescriptionError('');
                    setAddressError('');
                    setContactError('');
                    setExamCenterNameError('');
                    setExamCenterCodeError('');
                    setLogoUrlError('');
                    setApiErrorMessage('');
                    fetchData();
                    setEditFlag(false);
                    clearHandler();
                }
            })
            .catch((error) => toast.error(error.response.data.message));
    }

    const clearHandler = () => {
        setName('');
        setDescription('');
        setAddress('');
        setContact('');
        setExamCenterName('');
        setExamCenterCode('');
        setLogoUrl({ name: '' });
        setId(0);
        setCreatedBy('');
        setCreateTimestamp('');
        setEditFlag(false);
    }

    const openDeleteConfirmation = () => {
        setShowDeleteConfirmation(true);
    }

    const closeDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    }

    return (
        <PageContainer title="College">
            <Card title={editFlag ? "Edit College" : "Add College"}>
                <div className="container m-0">
                    <div className="row">
                        <div className="col-4">
                            <label className="mt-3 mb-3">Name <span className='text-danger'>*</span> :  </label>
                            <input className="form-control" placeholder="Enter name..." type="text" name="name" onChange={(e) => {
                                setName(e.target.value);
                                setNameError('');
                                setApiErrorMessage('');
                            }} value={name} />
                            {nameError && <div className="text-danger">{nameError}</div>}
                        </div>


                        <div className="col-4">
                            <label className="mt-3 mb-3">Description <span className='text-danger'>*</span> : </label>
                            <input className="form-control" placeholder="Enter description..." type="text" name="description" onChange={(e) => {
                                setDescription(e.target.value);
                                setDescriptionError('');
                                setApiErrorMessage('');
                            }} value={description} />
                            {descriptionError && <div className="text-danger">{descriptionError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Contact <span className='text-danger'>*</span> : </label>
                            <input className="form-control" placeholder="Enter Contact..." type="number" name="Contact" onChange={(e) => {
                                setContact(e.target.value);
                                setContactError('');
                                setApiErrorMessage('');
                            }} value={contact} />
                            {contactError && <div className="text-danger">{contactError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Exam Center Name <span className='text-danger'>*</span> : </label>
                            <input className="form-control" placeholder="Enter Exam Center Name..." type="text" name="examCenterName" onChange={(e) => {
                                setExamCenterName(e.target.value);
                                setExamCenterNameError('');
                                setApiErrorMessage('');
                            }} value={examCenterName} />
                            {examCenterNameError && <div className="text-danger">{examCenterNameError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Exam Center Code <span className='text-danger'>*</span> : </label>
                            <input className="form-control" placeholder="Enter Exam Center Code..." type="text" name="examCenterCode" onChange={(e) => {
                                setExamCenterCode(e.target.value);
                                setExamCenterCodeError('');
                                setApiErrorMessage('');
                            }} value={examCenterCode} />
                            {examCenterCodeError && <div className="text-danger">{examCenterCodeError}</div>}
                        </div>

                        <div className='col-4 custom-file'>
                            <label className="mt-3 mb-3">Logo Url : </label>
                            {/* <input type="file"  className='form-control'/> */}
                            <div className="input-group">
                                <div className="custom-file">
                                    <input
                                        type="file"
                                        className="custom-file-input"
                                        name="logoUrl"
                                        onChange={(e) => {
                                            const selectedFile = e.target.files ? e.target.files[0] : null;
                                            if (selectedFile) {
                                                setLogoUrl(selectedFile);
                                            } else {
                                                console.log('No file selected.');
                                            }
                                            setLogoUrlError('');
                                            setApiErrorMessage('');
                                        }}
                                        aria-describedby="inputGroupFileAddon01"
                                    />
                                    <label className="custom-file-label" htmlFor="inputGroupFile01">
                                        {logoUrl.name !== "" ? logoUrl.name : 'Choose file...'}
                                    </label>
                                </div>
                            </div>

                            {logoUrlError && <div className="text-danger">{logoUrlError}</div>}
                        </div>

                        <div className="col-8">
                            <label className="mt-3 mb-3">Address <span className='text-danger'>*</span> : </label>
                            <textarea className="form-control" placeholder="Enter Address..." name="address" onChange={(e) => {
                                setAddress(e.target.value);
                                setAddressError('');
                                setApiErrorMessage('');
                            }} value={address}></textarea>
                            {addressError && <div className="text-danger">{addressError}</div>}</div>

                    </div>
                </div>
                <div className="m-4 d-flex justify-content-end">
                    <button className="btn btn-sm btn-secondary" onClick={clearHandler} style={{ marginRight: '10px' }}>
                        Clear
                    </button>
                    {editFlag ? <button className="btn btn-sm btn-success" onClick={updateHandler}>Update</button> :
                        <button className="btn btn-sm btn-success" onClick={addHandler}>Add</button>}
                </div>
            </Card>
            <CollapseCard title="College">
                <table className="table table-primary table-striped">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Address</th>
                            <th>Exam Center</th>
                            <th>Created</th>
                            <th>Options</th>
                        </tr>
                    </thead>
                    <tbody>
                        {collegeList.map((college, index) => (
                            <tr key={college.id}>
                                <td>{index + 1}</td>
                                <td><img src={apiUrl + "/" + college.logoUrl} alt={college.name} className="img-thumbnail w-100px" width={100} height={100} /> <br />{college.name}</td>
                                <td>{college.description}</td>
                                <td>{college.address}
                                    <br />{college.contact}</td>
                                <td>{college.examCenterName}<br />{college.examCenterCode}</td>
                                <td>{college.createdBy} <br />{college.createTimestamp}</td>
                                <td>
                                    <button className="btn btn-sm btn-primary" style={{ marginRight: '10px' }} onClick={() => editHandler((college?.id !== undefined ? college?.id : 0), college.name, college.description, college.address, college.contact, college.examCenterName, college.examCenterCode, college.logoUrl, college?.createdBy!, college?.createTimestamp!)}>Edit</button>
                                    <button className="btn btn-sm btn-danger" onClick={() => deleteHandler((college?.id !== undefined ? college?.id : 0))}>Delete</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </CollapseCard>
            {/* Deletion Confirmation Modal */}
            <Modal show={showDeleteConfirmation} onHide={closeDeleteConfirmation}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this item?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeDeleteConfirmation}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={confirmDeleteHandler}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Add the ToastContainer for displaying toasts */}
            <ToastContainer position="top-right" />
        </PageContainer>
    );
}

export default College;

