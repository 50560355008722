/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { GetState } from "../reducers"

export enum RegulationActionList {
    ADD_REGULATION = 'ADD_REGULATION',
    FETCH_REGULATION = 'FETCH_REGULATION',
    UPDATE_REGULATION = 'UPDATE_REGULATION',
    DELETE_REGULATION = 'DELETE_REGULATION',
}

export interface RegulationType {
    id?: number
    title: string
}

export interface AddRegulationAction {
    type: RegulationActionList.ADD_REGULATION
    data: RegulationType
}

export interface FetchRegulationAction {
    type: RegulationActionList.FETCH_REGULATION
    data: Array<RegulationType>
}

export interface UpdateRegulationAction {
    type: RegulationActionList.UPDATE_REGULATION
    data: RegulationType
}

export interface DeleteRegulationAction {
    type: RegulationActionList.DELETE_REGULATION
    data: number
}

export type RegulationActions = AddRegulationAction | FetchRegulationAction | UpdateRegulationAction | DeleteRegulationAction

export const addRegulation = (data: RegulationType) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.post<APIResponse<RegulationType>>('regulation/', data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<AddRegulationAction>({
                    type: RegulationActionList.ADD_REGULATION,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Regulation Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })
    }
}

export const updateRegulation = (data: RegulationType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.put<APIResponse<RegulationType>>(`regulation/?id=${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<UpdateRegulationAction>({
                    type: RegulationActionList.UPDATE_REGULATION,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Regulation Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchRegulation = () => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<RegulationType[]>>('regulation/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchRegulationAction>({
                    type: RegulationActionList.FETCH_REGULATION,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteRegulation = (id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.delete<APIResponse<RegulationType>>(`regulation/?id=${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteRegulationAction>({
                    type: RegulationActionList.DELETE_REGULATION,
                    data: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Regulation Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}
