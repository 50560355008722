import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from '../components/custom/Card';
import CollapseCard from '../components/custom/CollapseCard';
import FormInput from '../components/custom/FormInput';
import PageContainer from '../components/custom/PageContainer';
import Option from '../components/Option';
import { YearOptions } from '../config/functions';
import { CourseType, fetchCourse } from '../redux/actions/courseActions';
import { DegreeType, fetchDegree } from '../redux/actions/degreeAction';
import { DisciplineType, fetchDiscipline } from '../redux/actions/disciplineAction';
import { DisciplineCourseType } from '../redux/actions/disciplineCourseAction';
import { ExamType, fetchExam } from '../redux/actions/examAction';
import { PassingBoardType, addPassingBoard, deletePassingBoard, updatePassingBoard } from '../redux/actions/passingBoardActions';
import { StoreState } from '../redux/reducers';
import { AppDispatch } from '../redux/store';

import axios from 'axios';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { CSVLink } from 'react-csv';
import * as Feather from 'react-feather';
import { apiUrl } from '../config';
import imgPath from './img/collegeLogo.png';
import { MarkSheetReportType } from './MarkSheetReport';
import { StudentGrade } from './StudentMarkPractical';

const PassingBoard = () => {

    const dispatch = useDispatch<AppDispatch>();

    const passingBoardList = useSelector<StoreState, PassingBoardType[]>(state => state.passingBoard);

    const [passingList, setPassingList] = useState<PassingBoardType[]>([])


    const [editFlag, setEditFlag] = useState(false);
    const [examId, setExamId] = useState<string>('');
    const [degreeId, setDegreeId] = useState<string>('');
    const [disciplineId, setDisciplineId] = useState<string>('');
    const [semester, setSemester] = useState<string>('');
    const [batch, setBatch] = useState<string>('');
    const [batchError, setBatchError] = useState<string>('');
    const [disciplineCourseId, setDisciplineCourseId] = useState<string>('');
    const [adjustingMark, setAdjustingMark] = useState<string>('');
    const [id, setId] = useState<number>(0);
    const [createdBy, setCreatedBy] = useState<string>('');
    const [createTimestamp, setCreateTimestamp] = useState<string>('');
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [examIdError, setExamIdError] = useState<string>('');
    const [degreeIdError, setDegreeIdError] = useState<string>('');
    const [disciplineIdError, setDisciplineIdError] = useState<string>('');
    const [semesterError, setSemesterError] = useState<string>('');
    const [disciplineCourseIdError, setDisciplineCourseIdError] = useState<string>('');
    const [adjustingMarkError, setAdjustingMarkError] = useState<string>('');

    const examList = useSelector<StoreState, ExamType[]>(state => state.exam)
    const courseList = useSelector<StoreState, CourseType[]>(state => state.course)
    const degreeList = useSelector<StoreState, DegreeType[]>(state => state.degree)
    const disciplineList = useSelector<StoreState, DisciplineType[]>(state => state.discipline)
    const disciplineCourseList = useSelector<StoreState, DisciplineCourseType[]>(state => state.disciplineCourse)
    const [formTypeEdit, setFormTypeEdit] = useState<boolean>(false)
    const yearList = YearOptions();

    const [selectExam, setSelectExam] = useState<string>('')
    const [selectDegree, setSelectDegree] = useState<string>('')
    const [selectDiscipline, setSelectDiscipline] = useState<string>('')
    const [selectBatch, setSelectBatch] = useState<string>('')
    const [selectSemester, setSelectSemester] = useState<string>('')
    const [selectDisciplineCourse, setSelectDisciplineCourse] = useState<string>('')
    console.log(disciplineCourseId);
    console.log(disciplineCourseList);

    const handleFetchDataForMarksheetAndUpdateStudentGrades = () => {
        if (examId !== '' && degreeId !== '' && disciplineId !== '' && batch !== '' && semester !== '') {
            // let years = academicYear.split('-')
            axios.get<{ data: MarkSheetReportType[] }>(`${apiUrl}/student/marksheet/?degreeId=${degreeId}&disciplineId=${disciplineId}&examId=${examId}&batch=${batch}&sem=${semester}`).then(async (response) => {
                if (response.status === 200 && response.data.data) {
                    const result = response.data.data;
                    const AddGradeData: StudentGrade[] = [];
                    // setMarksheetData(response.data.data);
                    let totalCreditsRegistered = 0;
                    let totalCreditsEarned = 0;
                    let totalGradePointsEarned = 0;
                    let GPA = 0;
                    let cumulativeCredits = 0;
                    let sem = 0;

                    result.length > 0 && result.forEach(h => {
                        h?.courseDetails?.forEach(cd => {
                            sem = cd.sem;
                            totalCreditsRegistered += parseInt(cd.credit)
                            totalGradePointsEarned += (cd.unvGrade)
                            if (cd.unvGrade > 0) {
                                totalCreditsEarned += parseInt(cd.credit)
                                cumulativeCredits += parseInt(cd.credit)
                                GPA += (cd.unvGrade) * parseInt(cd.credit)
                            }
                        })
                        GPA = GPA / totalCreditsEarned;
                        let obj = {
                            registeNo: h.registerNo,
                            batch: batch.toString(),
                            sem: sem,
                            cRegistered: totalCreditsRegistered.toString(),
                            cEarned: totalCreditsEarned.toString(),
                            gpEarned: totalGradePointsEarned.toString(),
                            gpAverage: GPA.toFixed(2),
                        }

                        AddGradeData.push(obj)
                    });
                    console.log(AddGradeData);

                    if (AddGradeData) {
                        await axios.post(`${apiUrl}/studentGrade/bulkInsert/`, AddGradeData).then((response) => {
                            if (response.status === 200) {
                                toast.success('Grades Updated');
                                // dispatch(fetchStudentMarkAction());
                                // resetStates();
                                // handleDownloadPDF(editData);
                            }
                        }).catch((error) => {
                            if (error!.response) {
                                toast.error(error.response.data.message);
                            }
                        });
                    }

                }
            })
                .catch((error) => {
                    console.error('Error fetching marksheet data', error);
                });
        } else {
            toast.error('Select All Filters', { position: 'top-right', autoClose: 3000 });
        }
    }

    const addHandler = () => {

        if (examId.length === 0) {
            setExamIdError('Exam required');
        }
        if (degreeId.length === 0) {
            setDegreeIdError('Degree required');
        }
        if (disciplineId.length === 0) {
            setDisciplineIdError('Discipline required');
        }
        if (semester.length === 0) {
            setSemesterError('Semester required');
        }
        if (batch === '') {
            setBatchError('Batch required');
        }
        if (disciplineCourseId === '') {
            setDisciplineCourseIdError('Discipline Course required');
        }
        if (adjustingMark === '') {
            setAdjustingMarkError('Adjusting Mark required');
        }

        if (examId.length > 11) {
            setExamIdError('Exam must be within 11 characters');
        }
        if (degreeId.length > 11) {
            setDegreeIdError('Degree must be within 11 characters');
        }
        if (disciplineId.length > 11) {
            setDisciplineIdError('Discipline must be within 11 characters');
        }
        if (semester.length > 11) {
            setSemesterError('Semester must be within 11 characters');
        }
        if (disciplineCourseId.length > 11) {
            setDisciplineCourseIdError('Discipline Course must be within 11 characters');
        }
        if (adjustingMark.length > 11) {
            setAdjustingMarkError('Adjusting Mark must be within 11 characters');
        }

        let disCourseId = disciplineCourseList.find(f => f.degreeId?.toString() === degreeId && f.disciplineId?.toString() === disciplineId && f.courseId?.toString() === disciplineCourseId)?.id

        const data = {
            examId,
            degreeId,
            disciplineId,
            batch,
            semester,
            disciplineCourseId: disCourseId ? disCourseId?.toString() : '0',
            adjustingMark
        }

        if (disCourseId) {
            dispatch(addPassingBoard(data)).then(text => {
                toast.success(text);
                handleFetchDataForMarksheetAndUpdateStudentGrades()
                setExamIdError('');
                setDegreeIdError('');
                setDisciplineIdError('');
                setSemesterError('');
                setBatchError('');
                setDisciplineCourseIdError('');
                setAdjustingMarkError('');
                clearHandler();
            }).catch(text => {
                toast.error(text)
            })
        } else toast.error('Unable to add,discipline course Id ')
    };

    const editHandler = (
        id: number,
        examId: string,
        degreeId: string,
        disciplineId: string,
        semesterId: string,
        batch: string,
        disciplineCourseId: string,
        adjustingMark: string
    ) => {
        setFormTypeEdit(true)
        setEditFlag(true);
        setId(id);
        setExamId(examId);
        setDegreeId(degreeId);
        setDisciplineId(disciplineId);
        setSemester(semesterId);
        setBatch(batch)
        const disciplineCourse = disciplineCourseList.find((dc) => dc.id === parseInt(disciplineCourseId));

        setDisciplineCourseId(disciplineCourse?.courseId ? disciplineCourse?.courseId?.toString() : '');
        setAdjustingMark(adjustingMark);
    };
    // console.log(disciplineCourseId);

    const updateHandler = () => {
        let error = false
        let pobj = passingBoardList.find(f => f.id === id)
        if (pobj?.adjustingMark !== adjustingMark) {

            if (examId === '') {
                setExamIdError('Exam required');
                error = true
            }
            if (degreeId === '') {
                setDegreeIdError('Degree required');
                error = true
            }
            if (disciplineId === '') {
                setDisciplineIdError('Discipline required');
                error = true
            }
            if (semester === '') {
                setSemesterError('Semester required');
                error = true
            }
            if (batch === '') {
                setBatchError('Batch required');
                error = true
            }
            if (disciplineCourseId === '') {
                setDisciplineCourseIdError('Discipline Course required');
                error = true
            }
            if (adjustingMark === '') {
                setAdjustingMarkError('Adjusting Mark required');
                error = true
            } else if (parseInt(adjustingMark) <= 0) {
                setAdjustingMarkError('Invalid Adjusting Mark');
                error = true
            }

            if (examId.length > 11) {
                setExamIdError('Exam must be within 11 characters');
                error = true
            }
            if (degreeId.length > 11) {
                setDegreeIdError('Degree must be within 11 characters');
                error = true
            }
            if (disciplineId.length > 11) {
                setDisciplineIdError('Discipline must be within 11 characters');
                error = true
            }
            if (semester.length > 11) {
                setSemesterError('Semester must be within 11 characters');
                error = true
            }
            if (disciplineCourseId.length > 11) {
                setDisciplineCourseIdError('Discipline Course must be within 11 characters');
                error = true
            }
            if (adjustingMark.length > 11) {
                setAdjustingMarkError('Adjusting Mark must be within 11 characters');
                error = true
            }

            let disCourseId = disciplineCourseList.find(f => f.degreeId?.toString() === degreeId?.toString() && f.disciplineId?.toString() === disciplineId?.toString() && f.courseId?.toString() === disciplineCourseId?.toString())?.id
            console.log(degreeId);
            console.log(disciplineId);
            console.log(disciplineCourseId);
            console.log(disCourseId);

            const data = {
                examId,
                degreeId,
                disciplineId,
                batch,
                semester,
                disciplineCourseId: disCourseId ? disCourseId.toString() : '',
                adjustingMark
            }
            if (disCourseId) {
                if (!error) {
                    dispatch(updatePassingBoard(data, id)).then(text => {
                        toast.success(text);
                        handleFetchDataForMarksheetAndUpdateStudentGrades()
                        setExamIdError('');
                        setDegreeIdError('');
                        setDisciplineIdError('');
                        setSemesterError('');
                        setBatchError('');
                        setDisciplineCourseIdError('');
                        setAdjustingMarkError('');
                        clearHandler();
                    }).catch(text => {
                        toast.error(text)
                    })
                }
            } else toast.error('Unable to add,discipline course Id ')

        } else toast.error('Old Data given')
    };


    const confirmDeleteHandler = (id: number) => {
        setId(id);
        openDeleteConfirmation();
    };

    const deleteHandler = () => {
        if (id !== undefined) {
            dispatch(deletePassingBoard(id)).then(text => {
                toast.success(text)
                handleFetchDataForMarksheetAndUpdateStudentGrades()
                closeDeleteConfirmation();
                clearHandler();
            }).catch(text => {
                toast.error(text);
            })
        }
    }

    const clearHandler = () => {
        setId(0);
        setEditFlag(false);
        setFormTypeEdit(false);
        setExamId('');
        setDegreeId('');
        setDisciplineId('');
        setSemester('');
        setDisciplineCourseId('');
        setAdjustingMark('');
        setExamIdError('');
        setDegreeIdError('');
        setDisciplineIdError('');
        setBatch('');
        setBatchError('');
        setSemesterError('');
        setDisciplineCourseIdError('');
        setAdjustingMarkError('');
    }

    const openDeleteConfirmation = () => {
        setShowDeleteConfirmation(true);
    };

    const closeDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    };

    const getExamName = (examId: string) => {
        const exam = examList.find((c) => c.id === parseInt(examId));
        if (exam) {
            return exam.title;
        }
        return 'N/A';
    }

    const getDegreeName = (degreeId: string) => {
        const degree = degreeList.find((c) => c.id === parseInt(degreeId));
        if (degree) {
            return degree.name;
        }
        return 'N/A';
    }

    const getDisciplineName = (disciplineId: string) => {
        const discipline = disciplineList.find((c) => c.id === parseInt(disciplineId));
        if (discipline) {
            return discipline.name;
        }
        return 'N/A';
    }

    const getCourseName = (courseId: string) => {
        const disciplineCourse = disciplineCourseList.find((dc) => dc.id === parseInt(courseId));
        if (disciplineCourse) {
            const course = courseList.find((c) => c.id?.toString() === disciplineCourse.courseId?.toString());
            if (course) {
                return course.name;
            }
        }
        return 'N/A';
    };

    useEffect(() => {
        fetchExam()
        fetchDegree()
        fetchDiscipline()
        fetchCourse()
    }, [])


    useEffect(() => {
        setPassingList(passingBoardList)
    }, [passingBoardList])

    const handleView = () => {

        console.log(selectExam);

        console.log(disciplineCourseList);

        let disCourseId = disciplineCourseList.find(f => f.degreeId?.toString() === selectDegree && f.disciplineId?.toString() === selectDiscipline && f.courseId?.toString() === selectDisciplineCourse)

        let courseId = disCourseId?.id;

        console.log(courseId ? courseId : '')

        const filterList = passingBoardList.filter(pb =>
            pb.examId?.toString() === (selectExam !== '' ? selectExam : pb.examId?.toString()) &&
            pb.degreeId?.toString() === (selectDegree !== '' ? selectDegree : pb.degreeId?.toString()) &&
            pb.disciplineId?.toString() === (selectDiscipline !== '' ? selectDiscipline : pb.disciplineId?.toString()) &&
            pb.batch?.toString() === (selectBatch !== '' ? selectBatch : pb.batch?.toString()) &&
            pb.semester?.toString() === (selectSemester !== '' ? selectSemester : pb.semester?.toString()) &&
            pb.disciplineCourseId?.toString() === (selectDisciplineCourse !== '' ? selectDisciplineCourse : pb.disciplineCourseId?.toString())
        )

        setPassingList(filterList);

    }

    const resetData = () => {
        setSelectExam('')
        setSelectDegree('')
        setSelectDiscipline('')
        setSelectBatch('')
        setSelectSemester('')
        setSelectDisciplineCourse('')
        setPassingList(passingBoardList)
    }



    const handleDownloadPDF = () => {
        const doc = new jsPDF('p', 'mm', 'a4');
        doc.addImage(imgPath, 'PNG', 25, 10, 170, 40);

        doc.setFontSize(16);
        doc.setFont('Arial', 'normal', 'bold');
        doc.setTextColor(0, 0, 0);
        doc.text('PASSING BOARD', 100, 55, { align: 'center' });

        autoTable(doc, {
            startY: 70,
            headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0] },
            bodyStyles: { textColor: [0, 0, 0], lineColor: [0, 0, 0] },
            head: [
                [
                    { content: 'S.No.', styles: { halign: 'center' } },
                    { content: 'Exam', styles: { halign: 'center' } },
                    { content: 'Degree', styles: { halign: 'center' } },
                    { content: 'Discipline', styles: { halign: 'center' } },
                    { content: 'Discipline Course', styles: { halign: 'center' } },
                    { content: 'Semester', styles: { halign: 'center' } },
                    { content: 'Batch', styles: { halign: 'center' } },
                    { content: 'Adjusting Mark', styles: { halign: 'center' } },
                ],
            ],
            body: passingList.map((pl, index) => [
                { content: index + 1, styles: { halign: 'center' } },
                { content: getExamName(pl.examId), styles: { halign: 'center' } },
                { content: getDegreeName(pl.degreeId), styles: { halign: 'center' } },
                { content: getDisciplineName(pl.disciplineId), styles: { halign: 'center' } },
                { content: getCourseName(pl.disciplineCourseId), styles: { halign: 'center' } },
                { content: pl.semester, styles: { halign: 'center' } },
                { content: pl.batch, styles: { halign: 'center' } },
                { content: pl.adjustingMark, styles: { halign: 'center' } },
            ]),
            theme: 'grid',
        });
        doc.save('passing_board' + '.pdf');
    }

    const csvData = [
        [
            'S.No.',
            'Exam',
            'Degree',
            'Discipline',
            'Disicipline Course',
            'Semester',
            'Batch',
            'Adjusted Mark',
        ],
        ...passingList.map((row, i) => [
            (i + 1),
            getExamName(row.examId),
            getDegreeName(row.degreeId),
            getDisciplineName(row.disciplineId),
            getCourseName(row.disciplineCourseId),
            row.semester,
            row.batch,
            row.adjustingMark,
        ]),
    ];

    return (
        <PageContainer title="Passing Board">
            <Card title={editFlag ? "Edit Passing Board" : "Add Passing Board"}>
                <div className='container m-0'>
                    <div className="row">
                        <div className="col-sm-6 col-md-4 col-lg-3">
                            <label className="my-2">Exam <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                style={{ width: "100%", marginRight: "20px", }}
                                name="exam"
                                value={examId}
                                onChange={(e) => setExamId(e.target.value)}
                                disabled={formTypeEdit}
                            >
                                <option value="" selected>Select Exam</option>
                                {examList.map((course) => (
                                    <option key={course.id} value={course.id}>
                                        {course.title}
                                    </option>
                                ))}
                            </select>
                            {examIdError && <div className="text-danger">{examIdError}</div>}
                        </div>

                        <div className="col-sm-6 col-md-4 col-lg-3">
                            <label className="my-2">Degree <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                style={{ width: "100%", marginRight: "20px" }}
                                name="selectedDegree"
                                value={degreeId}
                                onChange={(e) => setDegreeId(e.target.value)}
                                disabled={formTypeEdit}
                            >
                                <option value="" selected>Select Degree</option>
                                {degreeList.map((course) => (
                                    <option key={course.id} value={course.id}>
                                        {course.name}
                                    </option>
                                ))}
                            </select>
                            {degreeIdError && <div className="text-danger">{degreeIdError}</div>}
                        </div>

                        <div className="col-sm-6 col-md-4 col-lg-3">
                            <label className="my-2">Discipline <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                style={{ width: "100%", marginRight: "20px", }}
                                name="selectedDiscipline"
                                value={disciplineId}
                                onChange={(e) => setDisciplineId(e.target.value)}
                                disabled={formTypeEdit}
                            >
                                <option value="" selected>Select Discipline</option>
                                {disciplineList.map((course) => (
                                    <option key={course.id} value={course.id}>
                                        {course.name}
                                    </option>
                                ))}
                            </select>
                            {disciplineIdError && <div className="text-danger">{disciplineIdError}</div>}
                        </div>

                        <div className="col-sm-6 col-md-4 col-lg-3">
                            <FormInput
                                name="Batch"
                                label="Batch"
                                labelClassName="required"
                                value={batch}
                                onChange={(e) => {
                                    setBatch(e.target.value)
                                    setBatchError('')
                                }}
                                placeholder="Select Batch"
                                containerClass=""
                                type="select"
                                errorText={batchError}
                                disabled={formTypeEdit}
                            >
                                <option value="">Select Batch</option>
                                {yearList.map((y, i) => {
                                    return <Option key={i} value={y}>{y}</Option>
                                })
                                }
                            </FormInput>
                        </div>

                        <div className="col-sm-6 col-md-4 col-lg-3">
                            <label className="my-2">Semester <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                style={{ width: "100%", marginRight: "20px", }}
                                name="semester"
                                value={semester}
                                onChange={(e) => setSemester(e.target.value)}
                                disabled={formTypeEdit}
                            >
                                <option value="" selected>Select Semester</option>
                                <option value="1" selected>1</option>
                                <option value="2" selected>2</option>
                                <option value="3" selected>3</option>
                                <option value="4" selected>4</option>
                                <option value="5" selected>5</option>
                                <option value="6" selected>6</option>
                                <option value="7" selected>7</option>
                                <option value="8" selected>8</option>
                            </select>
                            {semesterError && <div className="text-danger">{semesterError}</div>}
                        </div>

                        <div className="col-sm-6 col-md-4 col-lg-3">
                            <label className="my-2">Discipline Course <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                style={{ width: "100%", marginRight: "20px" }}
                                name="disciplineCourseId"
                                value={disciplineCourseId}
                                onChange={(e) => setDisciplineCourseId(e.target.value)}
                                disabled={formTypeEdit}
                            >
                                <option value="" selected>Select Course</option>
                                {courseList.map((course) => (
                                    <option key={course.id} value={course.id}>
                                        {course.name}
                                    </option>
                                ))}
                            </select>
                            {disciplineCourseIdError && <div className="text-danger">{disciplineCourseIdError}</div>}
                        </div>

                        <div className="col-sm-6 col-md-4 col-lg-3">
                            <label className="my-2">Adjusting Mark <span className="text-danger">*</span> : </label>
                            <input
                                className="form-control"
                                placeholder="Enter adjusting mark..."
                                type="number"
                                name="adjustingMark"
                                onChange={(e) => setAdjustingMark(e.target.value)}
                                value={adjustingMark}
                            />
                            {adjustingMarkError && <div className="text-danger">{adjustingMarkError}</div>}
                        </div>
                    </div>
                </div>
                <div className=" col m-2 d-flex justify-content-end">
                    <button className="btn btn-sm btn-secondary" onClick={clearHandler} style={{ marginRight: '10px' }}>
                        Clear
                    </button>
                    {editFlag ? <button className="btn btn-sm btn-success" onClick={updateHandler}>Update</button> :
                        <button className="btn btn-sm btn-success" onClick={addHandler}>Add</button>}
                </div>
            </Card>
            <CollapseCard title="Passing Board">
                <div className="row m-1">
                    <div className="col-3">
                        <label className="my-2">Exam</label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px", }}
                            name="exam"
                            value={selectExam}
                            onChange={(e) => setSelectExam(e.target.value)}
                        >
                            <option value="" selected>Select Exam</option>
                            {examList.map((course) => (
                                <option key={course.id} value={course.id}>
                                    {course.title}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-3">
                        <label className="my-2">Degree  </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px" }}
                            name="selectDegree"
                            value={selectDegree}
                            onChange={(e) => setSelectDegree(e.target.value)}
                        >
                            <option value="" selected>Select Degree</option>
                            {degreeList.map((course) => (
                                <option key={course.id} value={course.id}>
                                    {course.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-3">
                        <label className="my-2">Discipline  </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px", }}
                            name="selectDiscipline"
                            value={selectDiscipline}
                            onChange={(e) => setSelectDiscipline(e.target.value)}
                        >
                            <option value="" selected>Select Discipline</option>
                            {disciplineList.map((course) => (
                                <option key={course.id} value={course.id}>
                                    {course.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-3">
                        <label className="my-2">Batch </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px" }}
                            name="selectBatch"
                            value={selectBatch}
                            onChange={(e) => setSelectBatch(e.target.value)}
                        >
                            <option value="" selected>Select Batch</option>
                            {yearList.map((y, i) => {
                                return <option key={i} value={y}>{y}</option>
                            }
                            )}
                        </select>

                        {/* <FormInput
                            name="Batch"
                            label="Batch"
                            labelClassName="required"
                            value={selectBatch}
                            onChange={(e) => {
                                setSelectBatch(e.target.value)
                            }}
                            placeholder="Select Batch"
                            containerClass=""
                            type="select"
                        >
                            <option value="">Select Batch</option>
                            {yearList.map((y, i) => {
                                return <Option key={i} value={y}>{y}</Option>
                            })
                            }
                        </FormInput> */}
                    </div>
                    <div className="col-3">
                        <label className="my-2">Semester</label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px", }}
                            name="selectSemester"
                            value={selectSemester}
                            onChange={(e) => setSelectSemester(e.target.value)}
                        >
                            <option value="" selected>Select Semester</option>
                            <option value="1" selected>1</option>
                            <option value="2" selected>2</option>
                            <option value="3" selected>3</option>
                            <option value="4" selected>4</option>
                            <option value="5" selected>5</option>
                            <option value="6" selected>6</option>
                            <option value="7" selected>7</option>
                            <option value="8" selected>8</option>
                        </select>
                    </div>
                    <div className="col-3">
                        <label className="my-2">Discipline Course  </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px" }}
                            name="selectDisciplineCourse"
                            value={selectDisciplineCourse}
                            onChange={(e) => setSelectDisciplineCourse(e.target.value)}
                            disabled={formTypeEdit}
                        >
                            <option value="" selected>Select Course</option>
                            {courseList.map((course) => (
                                <option key={course.id} value={course.id}>
                                    {course.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    {/* <div>
                        <div className=" col-12 text-end mt-5 mb-2">
                            <button className='btn btn-sm btn-primary' onClick={handleView}>Search</button>
                        </div>
                    </div> */}

                    <div className="mt-1 mb-1 d-flex justify-content-end">
                        <button
                            type="button"
                            className="btn btn-sm btn-primary mt-5 mb-3"
                            style={{ marginRight: '10px' }}
                            onClick={handleView}
                        >
                            Search
                        </button>

                        <button
                            type="button"
                            className="btn btn-sm btn-secondary mt-5 mb-3"
                            onClick={resetData}
                        >Reset
                        </button>
                    </div>


                </div>

                <div style={{ margin: '0% 5% 0% 0%', float: 'right' }}>
                    {passingList.length > 0 && <div className="text-end mb-3">
                        <button
                            type="button"
                            onClick={handleDownloadPDF}
                            className="m-1 btn btn-sm btn-primary"
                        >
                            <Feather.Download /> PDF
                        </button>
                        <CSVLink
                            data={csvData}
                            filename="passing_board.csv"
                            className="btn btn-sm btn-primary"
                        >
                            <Feather.Download /> CSV
                        </CSVLink>
                    </div>
                    }
                </div>

                <div className="container-fluid my-3" style={{ width: '100%' }}>
                    <table className="table table-success table-striped">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Exam</th>
                                <th>Degree</th>
                                <th>Discipline</th>
                                <th>Discipline Course</th>
                                <th>Semester</th>
                                <th>Batch</th>
                                <th>Adjusting Mark</th>
                                <th>Options</th>
                            </tr>
                        </thead>
                        <tbody>
                            {passingList.map((row, index) => (
                                <tr key={row.id}>
                                    <td>{index + 1}</td>
                                    <td>{getExamName(row.examId)}</td>
                                    <td>{getDegreeName(row.degreeId)}</td>
                                    <td>{getDisciplineName(row.disciplineId)}</td>
                                    <td>{getCourseName(row.disciplineCourseId)}</td>
                                    <td>{row.semester}</td>
                                    <td>{row.batch}</td>
                                    <td>{row.adjustingMark}</td>
                                    <td>
                                        <button
                                            className="btn btn-sm btn-primary"
                                            style={{ marginRight: '10px' }}
                                            onClick={() =>
                                                editHandler(
                                                    row.id ? row.id : 0,
                                                    row.examId,
                                                    row.degreeId,
                                                    row.disciplineId,
                                                    row.semester,
                                                    row.batch,
                                                    row.disciplineCourseId,
                                                    row.adjustingMark
                                                )
                                            }
                                        >
                                            Edit
                                        </button>
                                        <button
                                            className="btn btn-sm btn-danger"
                                            onClick={() => confirmDeleteHandler(row.id ? row.id : 0)}
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                </div>
            </CollapseCard>
            {/* Deletion Confirmation Modal */}
            < Modal show={showDeleteConfirmation} onHide={closeDeleteConfirmation} >
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeDeleteConfirmation}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={deleteHandler}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </PageContainer>
    );
};

export default PassingBoard;