import axios from "axios";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { FC, useEffect, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import * as Feather from 'react-feather';
import { toast } from "react-toastify";
import Card from "../components/custom/Card";
import CollapseCard from "../components/custom/CollapseCard";
import PageContainer from "../components/custom/PageContainer";
import { apiUrl } from "../config";
import { LocalFormatDate } from "../config/functions";
import imgPath from './img/collegeLogo.png';


interface Props {
    title: string,
}

export const OverallSeatArrangement: FC<Props> = (props: Props) => {
    const title = props;

    const fileName = "Overall_seating_arrangement"

    const [exam, setExam] = useState<Exam[]>([])

    const [examName, setExamName] = useState<string>('')
    const [date, setDate] = useState<string>('')
    const [session, setSession] = useState<string>('')


    const [hallSeat, setHallSeat] = useState<HallPlan[]>([])

    console.log(hallSeat);

    useEffect(() => {
        axios.get(`${apiUrl}/exam/`)
            .then((response) => {
                setExam(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching discipline course', error);
            });
    }, [])

    useEffect(() => {
        axios.get(`${apiUrl}/hall_seating_plan/`)
            .then((response) => {
                setHallSeat(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching discipline course', error);
            });
    }, [])


    const overallSeatings = useMemo(() => {
        const seatings = [];
        for (let hs of hallSeat) {
            const registerNos = hs.registerNo.split(',');
            const data = {
                "departNo": hs.degree + '-' + hs.disciplineShort,
                "semester": hs.semester,
                "startRegNo": registerNos[0],
                "endRegNo": registerNos[registerNos.length - 1],
                "venue": hs.venue
            }
            seatings.push(data);
            // return asad[i].registerNo[asad[i].registerNo.length - 1];
        }
        return seatings;
    }, [hallSeat])


    console.log(overallSeatings);


    useEffect(() => {
        let data: any = []
        overallSeatings.forEach((os) => {
            if (data && data.length !== 0) {
                const index = data.findIndex((d: any) => d[0].content === os.departNo)
                if (index !== -1) {
                    data[index][0].rowSpan = data[index][0].rowSpan + 1;
                    let d = [
                        { content: os.startRegNo + '-' + os.endRegNo },
                        { content: os.semester },
                        { content: os.venue }
                    ]
                    data.push(d);
                }
                if (index == -1) {
                    let d = [
                        { content: os.departNo, rowSpan: 0 },
                        { content: os.semester },
                        { content: os.startRegNo + '-' + os.endRegNo },
                        { content: os.venue }
                    ]
                    data.push(d);
                    data.push([{ content: '', colSpan: 3 }])
                }
            }
            else {
                let d = [
                    { content: os.departNo, rowSpan: 0 },
                    { content: os.semester },
                    { content: os.startRegNo + '-' + os.endRegNo },
                    { content: os.venue }
                ]
                data.push(d);
            }

        })

        console.log(data);

    }, [overallSeatings])

    // console.log(asad[1].registerNo[0]);

    // console.log(asad[1].registerNo[asad[1].registerNo.length-1]);

    // for(let i=0;i<asad.length;i++){
    //     return asad[i].registerNo[0]
    // }


    const handleSearch = () => {
        if (examName != "" && date != "" && session != "") {
            axios.get(`${apiUrl}/hallSeatingPlan/?examId=${examName}&date=${date}&session=${session}`)
                .then((response) => {
                    if (response.data.error) {
                        setHallSeat([])
                        toast.error('No record founds')
                        return
                    }
                    setHallSeat(response.data.data);
                })
                .catch((error) => {
                    console.error('Error fetching discipline course', error);
                });
        }
    }

    const overallSeat: any = {
        CSE: [
            {
                "departNo": "I-CSE",
                "startRegNo": '821322104001',
                "endRegNo": '821322104013',
                "venue": 'CLASSROOM ECE 1'
            },
            {
                "departNo": "I-CSE",
                "startRegNo": '821322104014',
                "endRegNo": '821322104025',
                "venue": 'CLASSROOM ECE 2'
            },
            {
                "departNo": "I-CSE",
                "startRegNo": '821322104026',
                "endRegNo": '821322104038',
                "venue": 'CLASSROOM ECE 3'
            },
            {
                "departNo": "I-CSE",
                "startRegNo": '821322104039',
                "endRegNo": '821322104052',
                "venue": 'CLASSROOM ECE 4'
            }
        ],
        ECE: [
            {
                "departNo": "I-ECE",
                "startRegNo": '821322106001',
                "endRegNo": '821322106013',
                "venue": 'CLASSROOM ECE 1'
            },
            {
                "departNo": "I-ECE",
                "startRegNo": '821322106014',
                "endRegNo": '821322106025',
                "venue": 'CLASSROOM ECE 2'
            }
        ],

        MECH: [
            {
                "departNo": "I-MECH",
                "startRegNo": '821322114001',
                "endRegNo": '821322114002',
                "venue": 'CLASSROOM ECE 3'
            },
        ],

        CIVIL: [
            {
                "departNo": "I-CIVIL",
                "startRegNo": '821322103001',
                "endRegNo": '821322103002',
                "venue": 'CLASSROOM ECE 4'
            },
        ],
        AERO: [
            {
                "departNo": "I-AERO",
                "startRegNo": '821322101001',
                "endRegNo": '821322101006',
                "venue": 'DRAWING HALL 1'
            },
        ],
        EEE: [
            {
                "departNo": "I-EEE",
                "startRegNo": '821322105001',
                "endRegNo": '821322105002',
                "venue": 'CLASSROOM ECE 1'
            },
        ]
    }

    const examTitle = exam.find((ex) => ex.id?.toString() === examName?.toString() ? ex.title : '')

    const examLabel = examTitle?.title

    const d = new Date()

    const curYear = d.getFullYear()


    const handleDownloadPDF = () => {
        const doc = new jsPDF('p', 'mm', 'a4');

        doc.addImage(imgPath, 'PNG', 20, 10, 170, 40);
        doc.setFontSize(12);
        doc.setFont('Arial', 'normal', 'bold')

        doc.text(`${examLabel}`, 80, 55)

        doc.text(`Overall Seating Arrangement - ${LocalFormatDate(date)} ${session}`, 60, 65)

        const bdata: any = [];

        // console.log(overallSeat);
        // Object.keys(overallSeat).forEach((os, ind) => {
        //     overallSeat[os].forEach((dt: any, i: number) => {
        //         let data = [
        //             { content: dt.departNo },
        //             { content: dt.startRegNo + '-' + dt.endRegNo },
        //             { content: dt.venue },
        //             { content: '', colSpan: 8 }
        //         ]
        //         // return data
        //         bdata.push(data)
        //     })
        // })
        // const overAll=[];


        const dateess = overallSeatings.sort((e1, e2) =>
            e1.departNo.toLowerCase().localeCompare(e2.departNo.toLowerCase())
        );

        // const body =  dateess.map((dt,i) => {
        //     let data = [
        //         { content: dt.departNo },
        //         { content: dt.startRegNo + '-' + dt.endRegNo },
        //         { content: dt.venue }
        //     ]
        //     return data;
        // })
        let body: any = []
        overallSeatings.forEach((os) => {
            if (body && body.length !== 0) {
                const index = body.findIndex((d: any) => d[0].content === os.departNo)
                if (index !== -1) {
                    body[index][0].rowSpan = body[index][0].rowSpan + 1;
                    let d = [
                        { content: os.semester, styles: { halign: 'center' } },
                        { content: os.startRegNo + ' - ' + os.endRegNo, styles: { halign: 'center' } },
                        { content: os.venue, styles: { halign: 'center' } }
                    ]
                    body.push(d);
                }
                if (index === -1) {
                    body.push([{ content: '', colSpan: 3 }])
                    let d = [
                        { content: os.departNo, rowSpan: 1, styles: { halign: 'center', valign: "middle" } },
                        { content: os.semester, styles: { halign: 'center' } },
                        { content: os.startRegNo + ' - ' + os.endRegNo, styles: { halign: 'center' } },
                        { content: os.venue, styles: { halign: 'center' } }
                    ]
                    body.push(d);
                }
            }
            else {
                let d = [
                    { content: os.departNo, rowSpan: 1, styles: { halign: 'center', valign: "middle" } },
                    { content: os.semester, styles: { halign: 'center' } },
                    { content: os.startRegNo + ' - ' + os.endRegNo, styles: { halign: 'center' } },
                    { content: os.venue, styles: { halign: 'center' } }
                ]
                body.push(d);
            }
        })

        // overallSeatings.forEach((os) => {
        //     for(let s in os){           
        //         if(s=os.departNo){
        //             let data = [
        //                 { content: os.departNo},
        //                 { content: os.startRegNo + '-' + os.endRegNo },
        //                 { content: os.venue }
        //             ]
        //             // console.log(s);
        //             return data
        //         }   
        //     bdata.push(onData)
        //     // console.log(s);       
        //     }
        //     // bdata.push(data)
        //     // return data;
        // })

        // var resArr = [];
        // overallSeatings.filter(function(item){
        // //   var i = bdata.find(x => (x == item.departNo));
        // var dt=overallSeatings.find((os)=>os.departNo===item.departNo);
        // console.log(dt);
        //   if(dt){
        //     bdata.push(item);
        //   }
        //   return null;
        // });




        //   console.log(dateess);


        autoTable(doc, {
            startY: 70,
            headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0] },
            bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0] },

            head: [
                [
                    { content: "DEPARTMENT", styles: { halign: 'center' } },
                    { content: "SEMESTER", styles: { halign: 'center' } },
                    { content: "REGISTER NUMBER", styles: { halign: 'center' } },
                    { content: "VENUE", styles: { halign: 'center' } }
                ]
            ],
            body,
            theme: 'grid'
        })
        // }
        doc.save(fileName + '.pdf')
    }

    const overAll = overallSeatings.sort((e1, e2) =>
        e1.departNo.toLowerCase().localeCompare(e2.departNo.toLowerCase())
    );

    const csvData = [
        [
            'S.No.',
            'Department',
            'Semester',
            'Start Register No',
            'End Register No',
            'Venue'
        ],
        ...overAll.map((or, i) => [
            i + 1,
            or.departNo,
            or.semester,
            or.startRegNo,
            or.endRegNo,
            or.venue
        ])
    ]

    return (
        <>
            <PageContainer title="Overall Seating Arrangement">
                <Card title="filter">
                    <div className="row">

                        <div className="col-4">
                            <label htmlFor="">Exam <span className="text-danger">*</span> :</label>
                            <select
                                className="form-control"
                                value={examName}
                                onChange={(e) => setExamName(e.target.value)}>
                                <option value="">Select Exam</option>
                                {exam.map((ex) => (
                                    <option value={ex.id}>{ex.title}</option>
                                ))
                                }
                            </select>
                        </div>
                        <div className="col-4">
                            <label htmlFor="">Date <span className="text-danger">*</span> :</label>
                            <input type="date" className="form-control" value={date} onChange={(e) => setDate(e.target.value)} />
                        </div>
                        <div className="col-4">
                            <label htmlFor="">Session <span className="text-danger">*</span> :</label>
                            <select
                                className="form-control"
                                value={session}
                                onChange={(e) => setSession(e.target.value)}>
                                <option value="">Select Session</option>
                                <option value="AN">AN</option>
                                <option value="FN">FN</option>
                            </select>
                        </div>
                       
                            <div className=" col-12 m-2 text-end">
                                <button className='btn btn-sm btn-primary' onClick={handleSearch} style={{width:"10%", float:"right", marginTop: "30px"}}>Search</button>
                            </div>
                    </div>
                </Card>
                <CollapseCard title="Overall Seating Arrangement">
                    <div className="container-fluid table-container">
                        <table className="table table-success table-striped">
                            <thead>
                                <tr>
                                    <th>Department</th>
                                    <th>Semester</th>
                                    <th>Register Number</th>
                                    <th>Venue</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    overAll.map((hs) => {
                                        return <tr>
                                            <td>{hs.departNo}</td>
                                            <td>{hs.semester}</td>
                                            <td>{hs.startRegNo}-{hs.endRegNo}</td>
                                            {/* <td>{hs.disciplineShort}</td> */}
                                            <td>{hs.venue}</td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            {overAll.length > 0 && <div className="text-end mb-3">
                                <button
                                    type="button"
                                    onClick={handleDownloadPDF}
                                    className="m-1 btn btn-sm btn-primary"
                                >
                                    <Feather.Download /> PDF
                                </button>
                                <CSVLink
                                    data={csvData}
                                    filename="details_of_overAllSeatingArrangement.csv"
                                    className="m-1 btn btn-sm btn-primary"
                                >
                                    <Feather.Download /> CSV
                                </CSVLink>
                            </div>
                            }
                        </div>
                    </div>
                </CollapseCard>

            </PageContainer >

        </>
    )
}


interface Exam {
    id: number;
    title: string;
    monthYear: string;
}

interface HallPlan {
    // id: number;

    // date: string;
    // session: string;
    // hallId: string;
    // examTitle: string;
    examId: string;
    venue: string;
    disciplineShort: string;
    semester: number;
    degree: string;
    registerNo: string;
    endRegisterNo: string;
    // registerList: string
}