import { Dispatch } from "redux";
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api";
import { GetState } from "../reducers";

export enum PackingActionsList {
  ADD_PACKING_BOARD = 'ADD_PACKING_BOARD',
  FETCH_PACKING_BOARD = 'FETCH_PACKING_BOARD',
  UPDATE_PACKING_BOARD = 'UPDATE_PACKING_BOARD',
  DELETE_PACKING_BOARD = 'DELETE_PACKING_BOARD',
};

export interface PackingType {
  id?: number;
  code?: string;
  examId: string;
  date: string;
  session: string;
  degreeId: string;
  disciplineId: string;
  disciplineCourseId: string;
  questionPaperCode: string;
  seqNo: string;
  presentList: string;
  absentList: string;
  malpracticeList: string;
  specialCaseList: string;
  specialPackingList: string;
}

export interface AddPackingActions {
  type: typeof PackingActionsList.ADD_PACKING_BOARD;
  data: PackingType;
}

export interface FetchPackingActions {
  type: typeof PackingActionsList.FETCH_PACKING_BOARD;
  data: Array<PackingType>;
}

export interface UpdatePackingActions {
  type: typeof PackingActionsList.UPDATE_PACKING_BOARD;
  data: PackingType;
}

export interface DeletePackingActions {
  type: typeof PackingActionsList.DELETE_PACKING_BOARD;
  data: number;
}

export type PackingActions =
  | AddPackingActions
  | FetchPackingActions
  | UpdatePackingActions
  | DeletePackingActions;

export const addPacking = (data: PackingType) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authUser.token!;
    const config = getAxiosRequestConfig(token);

    return api.post<APIResponse<PackingType>>('packingGalley/', data, config).then(response => {
      if (response.status === 200 && response.data.data) {
        dispatch<AddPackingActions>({
          type: PackingActionsList.ADD_PACKING_BOARD,
          data: response.data.data
        });
        return Promise.resolve(response.data.message
          ? response.data.message
          : 'Packing Galley Added');
      } else {
        throw { response };
      }
    }).catch(error => {
      return Promise.reject(error.response
        ? error.response.data.message
          ? error.response.data.message
          : 'Unable to add'
        : 'Unable to add');
    });
  };
};

export const updatePacking = (data: PackingType, id: number) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authUser.token!;
    const config = getAxiosRequestConfig(token);

    return api.put<APIResponse<PackingType>>(`packingGalley/?id=${id}`, data, config).then(response => {
      if (response.status === 200 && response.data.data) {
        dispatch<UpdatePackingActions>({
          type: PackingActionsList.UPDATE_PACKING_BOARD,
          data: response.data.data
        });
        return Promise.resolve(response.data.message
          ? response.data.message
          : 'Packing Galley Updated');
      } else {
        throw { response };
      }
    }).catch(error => {
      return Promise.reject(error.response
        ? error.response.data.message
          ? error.response.data.message
          : 'Unable to update'
        : 'Unable to update');
    });
  };
};

export const fetchPacking = () => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authUser.token!;
    const config = getAxiosRequestConfig(token);

    return api.get<APIResponse<PackingType[]>>('packingGalley/', config).then(response => {
      if (response.status === 200 && response.data.data) {
        dispatch<FetchPackingActions>({
          type: PackingActionsList.FETCH_PACKING_BOARD,
          data: response.data.data
        });
        return Promise.resolve(response.data.message
          ? response.data.message
          : 'Fetch Successful');
      } else {
        throw { response };
      }
    }).catch(error => {
      return Promise.reject(error.response
        ? error.response.data.message
          ? error.response.data.message
          : 'Unable to fetch'
        : 'Unable to fetch');
    });
  };
};

export const deletePacking = (disciplineId: number) => {

  return async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authUser.token!;
    const config = getAxiosRequestConfig(token);
    console.log(disciplineId);

    return api.delete<APIResponse<PackingType>>(`packingGalley/?disciplineId=${parseInt(disciplineId?.toString())}`, config).then(response => {
      if (response.status === 200) {
        dispatch<DeletePackingActions>({
          type: PackingActionsList.DELETE_PACKING_BOARD,
          data: disciplineId
        });
        return Promise.resolve(response.data.message
          ? response.data.message
          : 'Packing Galley Deleted');
      } else {
        throw { response };
      }
    }).catch(error => {
      return Promise.reject(error.response
        ? error.response.data.message
          ? error.response.data.message
          : 'Unable to delete'
        : 'Unable to delete');
    });
  };
};
