import { MouseEventHandler, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../components/Modal'
import UserForm from '../components/UserForm'
import PageContainer from '../components/custom/PageContainer'
import { ToastTypes, useToast } from '../components/toast/ToastProvider'
import { FormType, FormTypeList } from '../config/functions'
import { AuthUserType } from '../redux/actions/authUserActions'
import { UserType, deleteUser } from '../redux/actions/userActions'
import { StoreState } from '../redux/reducers'
import { AppDispatch } from '../redux/store'

interface TableBody {
    // "userId": "super",
    // "password": "super",
    // "name": "VJ",
    // "designation": "bus service",
    // "role": "super",
    // "status": "active"
    sno: number
    name: string
    designation: string
    role: string
    status: string
    mobile?: string
    emailId?: string
    onEdit: MouseEventHandler<HTMLButtonElement>
    onDelete: MouseEventHandler<HTMLButtonElement>
    timeStamp: string
    authUser: AuthUserType
}

type HandleEdit = (value: UserType) => void
type HandleDelete = (value: UserType) => void

const User = () => {
    const toast = useToast()
    const dispatch = useDispatch<AppDispatch>()
    const authUser = useSelector<StoreState, AuthUserType>(state => state.authUser)
    const user = useSelector<StoreState, Array<UserType>>(state => state.user)

    const [showForm, setShowForm] = useState<boolean>(false)
    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    const [editData, setEditData] = useState<UserType>()

    const handleAdd = () => {
        setShowForm(true)
    }

    const handleEdit: HandleEdit = (value) => {
        setFormType(FormTypeList.UPDATE)
        setEditData(value)
        setShowForm(true)
    }

    const handleDelete: HandleDelete = (value) => {
        if (value.userId !== undefined) {
            dispatch(deleteUser(value.userId)).then(text => {
                toast(text)
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
        }
    }

    const handleFormClose = () => {
        setShowForm(false)
        setFormType(FormTypeList.ADD)
        setEditData(undefined)
    }

    useEffect(() => {

    }, [editData])

    return <>
        <Modal
            headerText={formType === FormTypeList.ADD ? 'Add User' : 'Edit User'}
            visible={showForm}
            onClose={handleFormClose}
            size='lg'
            centered
        >
            <UserForm
                formType={formType}
                editData={editData}
                onSave={handleFormClose}
            />

        </Modal>
        {/* <VerticalLayout> */}
        <PageContainer title="User">
            <div className='d-flex justify-content-end m-1'>
                <button className='btn btn-sm btn-success' onClick={handleAdd}>ADD</button>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className='table-wrapper'>
                        <table className="table table-primary table-striped">
                            <thead>
                                <tr>
                                    <th className='text-truncate align-middle'>S.No</th>
                                    <th className='text-truncate align-middle'>Name</th>
                                    <th className='text-truncate align-middle'>Designation</th>
                                    {/* <th className='text-truncate align-middle'>Role</th> */}
                                    <th className='text-truncate align-middle'>Status</th>
                                    <th className='text-truncate align-middle'>Mobile</th>
                                    <th className='text-truncate align-middle'>Email</th>
                                    <th className='text-truncate align-middle'>Action</th>

                                </tr>
                            </thead>
                            <tbody>
                                {user.map((u, i) => {
                                    return <UserBody
                                        sno={i + 1}
                                        name={u.name}
                                        designation={u.designation}
                                        role={u.role}
                                        status={u.status}
                                        mobile={u.mobileNumber}
                                        emailId={u.emailId}
                                        key={i}
                                        authUser={authUser}
                                        timeStamp={u.createTimestamp !== undefined ? u.createTimestamp : ''}
                                        onEdit={() => handleEdit(u)}
                                        onDelete={() => handleDelete(u)}
                                    />
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </PageContainer>
        {/* </VerticalLayout> */}
    </>
}

const UserBody = ({ sno, name, role, designation, status, mobile, emailId, onEdit, onDelete, timeStamp, authUser }: TableBody) => {
    // const ShowEditForManager = isInToday(timeStamp)

    return <tr>
        <td className='align-middle'>{sno}</td>
        <td className='align-middle'>{name}</td>
        <td className='text-capitalize align-middle'>{designation}</td>
        {/* <td className='text-capitalize align-middle'>{role}</td> */}
        <td className='text-capitalize align-middle'>{status}</td>
        <td className='align-middle'>{mobile}</td>
        <td className='align-middle'>{emailId}</td>
        <td className='hstack'>
            {/* <div className='hstack gap-2'> */}
            {/* {(authUser.role === 'admin' || authUser.role === 'super' || authUser.role === 'Admin' || authUser.role === 'Super') && <button className='btn btn-xs btn-primary' onClick={onEdit}>Edit</button>}
                {(authUser.role === 'Manager' || authUser.role === 'manager') && ShowEditForManager && <button className='btn btn-xs btn-primary' onClick={onEdit}>Edit</button>} */}
            <button className='btn btn-sm btn-primary me-2' onClick={onEdit} style={{ marginRight: '10px' }}>Edit</button>
            <button className='ms-1 btn btn-sm btn-danger' onClick={onDelete}>Delete</button>
            {/* </div> */}
        </td>
    </tr>
}

export default User
