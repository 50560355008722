import React, { FC } from "react";

const ControlSideNavBar:FC = () =>{
    return(
        <aside className="control-sidebar control-sidebar-dark">
        {
         // Control sidebar content goes here 
        }
      </aside>
    )
}
export default ControlSideNavBar;