import axios from 'axios';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { CSVLink } from 'react-csv';
import * as Feather from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from '../components/custom/Card';
import CollapseCard from '../components/custom/CollapseCard';
import PageContainer from '../components/custom/PageContainer';
import { apiUrl } from '../config';
import { CourseType } from '../redux/actions/courseActions';
import { DegreeType } from '../redux/actions/degreeAction';
import { DisciplineType } from '../redux/actions/disciplineAction';
import { DisciplineCourseType } from '../redux/actions/disciplineCourseAction';
import { DropDownType, fetchDropDown } from '../redux/actions/dropDownAction';
import { StoreState } from '../redux/reducers';
import { AppDispatch } from '../redux/store';
import { StudentType } from './Student';
import imgPath from './img/collegeLogo.png';

const StudentArrear = () => {
    const dispatch = useDispatch<AppDispatch>()
    const [studentarrearList, setStudentarrearList] = useState<StudentArrearType[]>([]);
    const disciplineCourseList = useSelector<StoreState, DisciplineCourseType[]>(state => state.disciplineCourse)
    const dropDownList = useSelector<StoreState, DropDownType[]>(state => state.dropDown)
    console.log(studentarrearList);

    useEffect(() => {
        dispatch(fetchDropDown())
    }, [])

    const [editFlag, setEditFlag] = useState(false);
    const [disciplineId, setDisciplineId] = useState<string>('');
    const [degreeId, setDegreeId] = useState<string>('');
    const [registerNo, setRegisterNo] = useState('');
    const [semesterNo, setSemesterNo] = useState('');
    const [disciplineCourseId, setDisciplineCourseId] = useState('');
    const [attemptCount, setAttemptCount] = useState('');
    const [clearedSemester, setClearedSemester] = useState('');
    const [status, setStatus] = useState('');
    const [id, setId] = useState<number>(0);
    const [createdBy, setCreatedBy] = useState<string>('');
    const [createTimestamp, setCreateTimestamp] = useState<string>('');
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    //error state variable
    const [disciplineIdError, setDisciplineIdError] = useState('');
    const [degreeIdError, setDegreeIdError] = useState('');
    const [registerNoError, setRegisterNoError] = useState('');
    const [semesterNoError, setSemesterNoError] = useState('');
    const [disciplineCourseIdError, setDisciplineCourseIdError] = useState('');
    const [attemptCountError, setAttemptCountError] = useState('');
    const [clearedSemesterError, setClearedSemesterError] = useState('');
    const [statusError, setStatusError] = useState('');
    const [apiErrorMessage, setApiErrorMessage] = useState('');

    // const [selectCourseId, setSelectCourseId] = useState<Course[]>([]);

    //degree state variable
    // const [selectedDegreeId, setSelectedDegreeId] = useState(''); // Default to empty
    //discipline state variable
    // const [selectedDisciplineId, setSelectedDisciplineId] = useState(''); // Default to empty
    // const [selectedDiscipline, setSelectedDiscipline] = useState<Discipline[]>([]);

    const courseList = useSelector<StoreState, CourseType[]>(state => state.course)
    const degreeList = useSelector<StoreState, DegreeType[]>(state => state.degree)
    const disciplineList = useSelector<StoreState, DisciplineType[]>(state => state.discipline)
    const [selectedRegisterNo, setSelectedRegisterNo] = useState<StudentType[]>([]);

    //filter 
    const [selectDegree, setSelectDegree] = useState<string>('')
    const [selectDiscipline, setSelectDiscipline] = useState<string>('')
    const [selectCourse, setSelectCourse] = useState<string>('')

    useEffect(() => {
        axios.get(`${apiUrl}/student/`)
            .then((response) => {
                setSelectedRegisterNo(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching discipline course', error);
            });
    }, []);

    const getStudentArrear = () => {
        if (selectDegree !== '' || selectDiscipline !== '' || selectCourse !== '') {
            let degree = selectDegree !== '' ? selectDegree : null;
            let discipline = selectDiscipline !== '' ? selectDiscipline : null;
            let course = selectCourse !== '' ? selectCourse : null;

            let disciplineCourseId = disciplineCourseList.find(f => f.degreeId?.toString() === degree && f.disciplineId?.toString() === discipline && f.courseId?.toString() === course)?.id

            axios.get<{ data: StudentArrearType[] }>(`${apiUrl}/studentarrear/?degreeId=${degree}&disciplineId=${discipline}&disciplineCourseId=${disciplineCourseId}`)
                .then((response) => {
                    console.log(response.data.data);

                    setStudentarrearList(response.data.data);
                })
                .catch((error) => console.log(error));
        } else {
            axios.get<{ data: StudentArrearType[] }>(`${apiUrl}/studentarrear/`)
                .then((response) => {
                    setStudentarrearList(response.data.data);
                })
                .catch((error) => console.log(error));

        }
    }

    const resetData = () => {
        setSelectDegree('')
        setSelectDiscipline('')
        setSelectCourse('')
        getStudentArrear()
    }

    useEffect(() => {
        getStudentArrear()
    }, [])

    let disCourseId = disciplineCourseList.find(dc => dc.degreeId === parseInt(degreeId) && dc.disciplineId === parseInt(disciplineId) && dc.courseId === parseInt(disciplineCourseId))?.id

    const addHandler = () => {
        let error = false;
        if (disciplineId.length === 0) {
            setDisciplineIdError('discipline is required');
            error = true;
        }
        if (degreeId.length === 0) {
            setDegreeIdError('degree is required');
            error = true;
        }
        if (registerNo.length === 0) {
            setRegisterNoError('register No is required');
            error = true;
        }
        if (semesterNo.length === 0) {
            setSemesterNoError('semester No is required');
            error = true;
        }
        if (disciplineCourseId.length === 0) {
            setDisciplineCourseIdError('disciplineCourse Id is required');
            error = true;
        }
        if (attemptCount.length === 0) {
            setAttemptCountError('attempt Count is required');
            error = true;
        }
        if (clearedSemester.length === 0) {
            setClearedSemesterError('cleared Semester is required');
            error = true;
        }
        if (status.length === 0) {
            setStatusError('status  is required');
            error = true;
        }
        // if (disciplineId.length > 11) {
        //   setDisciplineIdError('disciplineId must be within 11 characters');
        //  error=true;
        // }
        // if (degreeId.length > 11) {
        //   setDegreeIdError('degreeId must be within 11 characters');
        //  error=true;
        // }
        if (registerNo.length > 15) {
            setRegisterNoError('registerNo must be within 15 characters');
            error = true;
        }
        if (semesterNo.length > 11) {
            setSemesterNoError('semesterNo must be within 11 characters');
            error = true;
        }
        // if (disciplineCourseId.length > 11) {
        //     setDisciplineCourseIdError('disciplineCourseId must be within 11 characters');
        //    error=true;
        // }
        if (attemptCount.length > 11) {
            setAttemptCountError('attemptCount must be within 11 characters');
            error = true;
        }
        if (clearedSemester.length > 11) {
            setClearedSemesterError('clearedSemester must be within 11 characters');
            error = true;
        }
        if (status.length > 15) {
            setStatusError('status must be within 15 characters');
            error = true;
        }
        const data: StudentArrearType = { disciplineId: parseInt(disciplineId), degreeId: parseInt(degreeId), registerNo: registerNo, semesterNo: parseInt(semesterNo), disciplineCourseId: disCourseId || 0, attemptCount: parseInt(attemptCount), clearedSemester: parseInt(clearedSemester), status: status, createdBy: createdBy, createTimestamp: createTimestamp };

        if (!error) {
            axios.post(`${apiUrl}/studentarrear/`, data)
                .then((response) => {


                    if (response.data.error) {
                        toast.error(response.data.message, { position: 'top-right' });
                        setApiErrorMessage(response.data.message);
                    } else {
                        toast.success(response.data.message, { position: 'top-right' });
                        setDisciplineId('');
                        setDegreeId('');
                        setRegisterNo('');
                        setSemesterNo('');
                        setDisciplineCourseId('');
                        setAttemptCount('');
                        setClearedSemester('');
                        setStatus('');
                        setApiErrorMessage('');
                        getStudentArrear();
                        // handleSearch();
                        clearHandler();
                    }
                })
                .catch((error) => {
                    toast.error(error.response.data.message);
                    // toast.error(error.messgae)
                });
        }
    }

    const editHandler = (sa: StudentArrearType) => {

        setEditFlag(true);
        setId(sa.id || 0);
        setDegreeId(sa.degreeId?.toString())
        setDisciplineId(sa.disciplineId?.toString())
        setRegisterNo(sa.registerNo);
        setSemesterNo(sa.semesterNo?.toString());
        setDisciplineCourseId(disciplineCourseList.find(dc => dc.id === sa.disciplineCourseId)?.courseId.toString() || '');
        setAttemptCount(sa.attemptCount?.toString());
        setClearedSemester(sa.clearedSemester?.toString());
        setStatus(sa.status);
        setCreatedBy(sa.createdBy);
        setCreateTimestamp(sa.createTimestamp);
    }

    //update
    const updateHandler = () => {
        let error = false;
        if (disciplineId.length === 0) {
            setDisciplineIdError('discipline is required');
            error = true;
        }
        if (degreeId.length === 0) {
            setDegreeIdError('degree is required');
            error = true;
        }
        if (registerNo.length === 0) {
            setRegisterNoError('register No is required');
            error = true;
        }
        if (semesterNo.length === 0) {
            setSemesterNoError('semester No is required');
            error = true;
        }
        if (disciplineCourseId.length === 0) {
            setDisciplineCourseIdError('disciplineCourse is required');
            error = true;
        }
        if (attemptCount.length === 0) {
            setAttemptCountError('attempt Count is required');
            error = true;
        }
        if (clearedSemester.length === 0) {
            setClearedSemesterError('cleared Semester is required');
            error = true;
        }
        if (status.length === 0) {
            setStatusError('status  is required');
            error = true;
        }
        // if (disciplineId.length > 11) {
        //   setDisciplineIdError('disciplineId must be within 11 characters');
        //  error=true;
        // }
        // if (degreeId.length > 11) {
        //   setDegreeIdError('degreeId must be within 11 characters');
        //  error=true;
        // }
        if (registerNo.length > 15) {
            setRegisterNoError('registerNo must be within 15 characters');
            error = true;
        }
        if (semesterNo.length > 11) {
            setSemesterNoError('semesterNo must be within 11 characters');
            error = true;
        }
        // if (disciplineCourseId.length > 11) {
        //     setDisciplineCourseIdError('disciplineCourseId must be within 11 characters');
        //     error = true;
        // }
        if (attemptCount.length > 11) {
            setAttemptCountError('attemptCount must be within 11 characters');
            error = true;
        }
        if (clearedSemester.length > 11) {
            setClearedSemesterError('clearedSemester must be within 11 characters');
            error = true;
        }
        if (status.length > 15) {
            setStatusError('status must be within 15 characters');
            error = true;
        }


        const data: StudentArrearType = { id: id, disciplineId: parseInt(disciplineId), degreeId: parseInt(degreeId), registerNo: registerNo, semesterNo: parseInt(semesterNo), disciplineCourseId: disCourseId || 0, attemptCount: parseInt(attemptCount), clearedSemester: parseInt(clearedSemester), status: status, createdBy: createdBy, createTimestamp: createTimestamp };

        if (!error) {
            axios.put(`${apiUrl}/studentarrear/?id=${id}`, data)
                .then((response) => {
                    if (response.data.error) {
                        toast.error(response.data.message, { position: 'top-right' });
                        setApiErrorMessage(response.data.message);
                    } else {
                        toast.success(response.data.message, { position: 'top-right' });
                        setDisciplineId('');
                        setDegreeId('');
                        setRegisterNo('');
                        setSemesterNo('');
                        setDisciplineCourseId('');
                        setAttemptCount('');
                        setClearedSemester('');
                        setStatus('');
                        setApiErrorMessage('');
                        getStudentArrear();
                        // handleSearch();
                        setEditFlag(false);
                        clearHandler();
                    }
                })
                .catch((error) => console.log(error));
        }
    }

    //reset
    const clearHandler = () => {
        setDisciplineId('');
        setDegreeId('');
        setRegisterNo('');
        setSemesterNo('');
        setDisciplineCourseId('');
        setAttemptCount('');
        setClearedSemester('');
        setStatus('');
        setId(0);
        setCreatedBy('');
        setCreateTimestamp('');
        setEditFlag(false);
    }

    //delete 
    const deleteHandler = (id: number) => {
        setId(id);
        openDeleteConfirmation(); // Open the delete confirmation modal
    }

    const confirmDeleteHandler = () => {
        axios
            .delete(`${apiUrl}/studentarrear/?id=${id}`)
            .then((response) => {
                toast.success(response.data.message, { position: 'top-right' });
                getStudentArrear();
                // handleSearch();
                closeDeleteConfirmation();
            })
            .catch((error) => {
                console.log(error);
                toast.error(error.response.data.message, { position: 'top-right' });
            });
    }

    const openDeleteConfirmation = () => {
        setShowDeleteConfirmation(true);
    };

    const closeDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    };
    //discipline name
    const getDisciplineId = (discipline: any) => {
        const disciplineName = disciplineList.find((disc) => disc.id === discipline);
        if (disciplineName) {
            return disciplineName.name;
        }
        return 'N/A';
    };
    //degree name 
    const getDegreeId = (degree: any) => {
        const degreeName = degreeList.find((disc) => disc.id === degree);
        if (degreeName) {
            return degreeName.name;
        }
        return 'N/A';
    };
    //discipline course name
    const getCourseId = (disciplineCourse: any) => {

        const courseId = disciplineCourseList.find((c) => c.id === disciplineCourse)?.courseId;

        if (courseId) {
            const courseName = courseList.find((disc) => disc.id === courseId);
            if (courseName) {
                return courseName.name;
            }
        }
        return 'N/A';
    };

    const handleDownloadPDF = () => {
        const doc = new jsPDF('p', 'mm', 'a4');
        doc.addImage(imgPath, 'PNG', 25, 10, 170, 40);

        doc.setFontSize(16);
        doc.setFont('Arial', 'normal', 'bold');
        doc.setTextColor(0, 0, 0);
        doc.text('STUDENT ARREAR', 100, 55, { align: 'center' });

        autoTable(doc, {
            startY: 60,
            headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0] },
            bodyStyles: { textColor: [0, 0, 0], lineColor: [0, 0, 0] },
            head: [
                [
                    { content: 'S.No.', styles: { halign: 'center' } },
                    { content: 'Degree', styles: { halign: 'center' } },
                    { content: 'Discipline', styles: { halign: 'center' } },
                    { content: 'Register No', styles: { halign: 'center' } },
                    { content: 'Semester', styles: { halign: 'center' } },
                    { content: 'Discipline Course', styles: { halign: 'center' } },
                    { content: 'Attempt Count', styles: { halign: 'center' } },
                    { content: 'Cleared Status', styles: { halign: 'center' } },
                    { content: 'Status', styles: { halign: 'center' } },
                ],
            ],
            body: studentarrearList.map((sa, index) => [
                { content: index + 1, styles: { halign: 'center' } },
                { content: getDegreeId(sa.degreeId), styles: { halign: 'center' } },
                { content: getDisciplineId(sa.disciplineId), styles: { halign: 'center' } },
                { content: sa.registerNo, styles: { halign: 'center' } },
                { content: sa.semesterNo, styles: { halign: 'center' } },
                { content: getCourseId(sa.disciplineCourseId), styles: { halign: 'center' } },
                { content: sa.attemptCount, styles: { halign: 'center' } },
                { content: sa.clearedSemester, styles: { halign: 'center' } },
                { content: sa.status, styles: { halign: 'center' } },
            ]),
            theme: 'grid',
        });
        doc.save('student_arrear.pdf');
    }

    const csvData = [
        [
            'S.No.',
            'Degree',
            'Discipline',
            'Register No',
            'Semester',
            'Discipline Course',
            'Attempt Count',
            'Cleared Status',
            'Status',
        ],
        studentarrearList?.map((row, i) => [
            (i + 1),
            getDegreeId(row.degreeId),
            getDisciplineId(row.disciplineId),
            row.registerNo,
            row.semesterNo,
            getCourseId(row.disciplineCourseId),
            row.attemptCount,
            row.clearedSemester,
            row.status,
        ]),
    ];

    return (
        <PageContainer title="Student Arrear">
            <Card title={editFlag ? "Edit Student Arrear" : "Add Student Arrear"}>
                <div className='container m-0'>
                    <div className="row">

                        <div className="col-4">
                            <label className="mt-3 mb-3">Degree <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                //  style={{width: "30%", marginRight: "20px"}}
                                name="degreeId"
                                value={degreeId}
                                onChange={(e) => {
                                    setDegreeId(e.target.value)
                                    setDegreeIdError('')
                                }}
                            >
                                <option value="" selected>Select Degree</option>
                                {degreeList.map((deg) => (
                                    <option key={deg.id} value={deg.id}>
                                        {deg.name}
                                    </option>
                                ))}
                            </select>
                            {degreeIdError && <div className="text-danger">{degreeIdError}</div>}
                        </div>

                        <div className="col-4">
                            {apiErrorMessage && <div className="text-danger">{apiErrorMessage}</div>}
                            <label className="mt-3 mb-3">Discipline <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                //  style={{width: "30%", marginRight: "20px",}}
                                name="disciplineId"
                                value={disciplineId}
                                onChange={(e) => {
                                    setDisciplineId(e.target.value)
                                    setDisciplineIdError('')
                                }}
                            >
                                <option value="" selected>Select Discipline</option>
                                {disciplineList.map((disc) => (
                                    <option key={disc.id} value={disc.id}>
                                        {disc.name}
                                    </option>
                                ))}
                            </select>
                            {disciplineIdError && <div className="text-danger">{disciplineIdError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Register No <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                name="registerNo"
                                value={registerNo}
                                onChange={(e) => {
                                    setRegisterNo(e.target.value)
                                    setRegisterNoError('')
                                }}
                            >
                                <option value="" selected>Select Register No</option>
                                {selectedRegisterNo?.filter(f => degreeId !== '' ? f.degreeId.toString() === degreeId : f)?.filter(di => disciplineId !== '' ? di.disciplineId.toString() === disciplineId : di)?.map((s) => (
                                    <option key={s.id} value={s.registerNo}>
                                        {s.registerNo}
                                    </option>
                                ))}
                            </select>
                            {registerNoError && <div className="text-danger">{registerNoError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">semester No <span className='text-danger'>*</span> : </label>
                            <select
                                className="form-control"
                                // style={{ width: "100%", marginRight: "20px", }}
                                name="semesterNo"
                                value={semesterNo}
                                onChange={(e) => {
                                    setSemesterNo(e.target.value)
                                    setSemesterNoError('')
                                }}
                            >
                                <option value={0} selected={parseInt(semesterNo) === 0 ? true : false}>Select Semester</option>
                                <option value={1} selected={parseInt(semesterNo) === 1 ? true : false}>1</option>
                                <option value={2} selected={parseInt(semesterNo) === 2 ? true : false}>2</option>
                                <option value={3} selected={parseInt(semesterNo) === 3 ? true : false}>3</option>
                                <option value={4} selected={parseInt(semesterNo) === 4 ? true : false}>4</option>
                                <option value={5} selected={parseInt(semesterNo) === 5 ? true : false}>5</option>
                                <option value={6} selected={parseInt(semesterNo) === 6 ? true : false}>6</option>
                                <option value={7} selected={parseInt(semesterNo) === 7 ? true : false}>7</option>
                                <option value={8} selected={parseInt(semesterNo) === 8 ? true : false}>8</option>
                            </select>
                            {semesterNoError && <div className="text-danger">{semesterNoError}</div>} </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Course  <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                name="disciplineCourseId"
                                value={disciplineCourseId}
                                onChange={(e) => {
                                    setDisciplineCourseId(e.target.value)
                                    setDisciplineCourseIdError('')
                                }}
                            >
                                <option value="" selected>Select Course </option>
                                {courseList.map((course) => (
                                    <option key={course.id} value={course.id}>
                                        {course.name}
                                    </option>
                                ))}
                            </select>
                            {disciplineCourseIdError && <div className="text-danger">{disciplineCourseIdError}</div>}</div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">attempt Count <span className='text-danger'>*</span> : </label>
                            <input className="form-control" placeholder="Enter attempt Count..." type="text" name="attemptCount" onChange={(e) => {
                                setAttemptCount(e.target.value)
                                setAttemptCountError('');
                                setApiErrorMessage('');
                            }} value={attemptCount} />
                            {attemptCountError && <div className="text-danger">{attemptCountError}</div>} </div>


                        <div className="col-4">
                            <label className="mt-3 mb-3">cleared Semester <span className='text-danger'>*</span> : </label>
                            {/* <input className="form-control" placeholder="Enter cleared Semester..." type="text" name="clearedSemester" onChange={(e) => {
                                setClearedSemester(e.target.value)
                                setClearedSemesterError('');
                                setApiErrorMessage('');
                            }} value={clearedSemester} /> */}
                            <select
                                className="form-control"
                                name="clearedSemester"
                                value={clearedSemester}
                                onChange={(e) => {
                                    setClearedSemester(e.target.value)
                                    setClearedSemesterError('');
                                    setApiErrorMessage('');
                                }}
                            >
                                <option value={0} selected={parseInt(clearedSemester) === 0 ? true : false}>Select Semester</option>
                                <option value={1} selected={parseInt(clearedSemester) === 1 ? true : false}>1</option>
                                <option value={2} selected={parseInt(clearedSemester) === 2 ? true : false}>2</option>
                                <option value={3} selected={parseInt(clearedSemester) === 3 ? true : false}>3</option>
                                <option value={4} selected={parseInt(clearedSemester) === 4 ? true : false}>4</option>
                                <option value={5} selected={parseInt(clearedSemester) === 5 ? true : false}>5</option>
                                <option value={6} selected={parseInt(clearedSemester) === 6 ? true : false}>6</option>
                                <option value={7} selected={parseInt(clearedSemester) === 7 ? true : false}>7</option>
                                <option value={8} selected={parseInt(clearedSemester) === 8 ? true : false}>8</option>
                            </select>
                            {clearedSemesterError && <div className="text-danger">{clearedSemesterError}</div>} </div>


                        <div className="col-4">
                            <label className="mt-3 mb-3">status <span className='text-danger'>*</span> : </label>
                            {/* <input className="form-control" placeholder="Enter status..." type="text" name="status" onChange={(e) => {
                                setStatus(e.target.value)
                                setStatusError('');
                                setApiErrorMessage('');
                            }} value={status} /> */}
                            <select
                                className="form-control"
                                value={status}
                                onChange={(e) => {
                                    setStatus(e.target.value)
                                    setStatusError('');
                                    setApiErrorMessage('');
                                }}
                            >
                                <option value="">Select Status</option>
                                {dropDownList.filter(dl => dl.category === 'Status').map((dd) => {
                                    return <option value={dd.title}>{dd.title}</option>
                                })
                                }

                            </select>
                            {statusError && <div className="text-danger">{statusError}</div>}
                        </div>

                        <div className="col-4">
                            <label htmlFor="" className='mt-2 mb-3'></label><br /><br />
                            <button className="btn btn-sm btn-secondary" onClick={clearHandler} style={{ marginRight: '10px' }}>
                                Clear
                            </button>
                            {editFlag ? <button className="btn btn-sm btn-success" onClick={updateHandler}>Update</button> :
                                <button className="btn btn-sm btn-success" onClick={addHandler}>Add</button>}
                        </div>
                    </div>
                </div>
            </Card>
            <CollapseCard title="Student Arrear">
                <div className="row m-1">
                    <div className="col-3">
                        <label className="mt-2 mb-2">Degree</label>
                        <select
                            className="form-control"
                            //  style={{width: "30%", marginRight: "20px"}}
                            name="selectDegree"
                            value={selectDegree}
                            onChange={(e) => setSelectDegree(e.target.value)}
                        >
                            <option value="" selected>Select Degree</option>
                            {degreeList?.map((deg) => (
                                <option key={deg.id} value={deg.id}>
                                    {deg.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-3">
                        <label className="mt-2 mb-2">Discipline</label>
                        <select
                            className="form-control"
                            //  style={{width: "30%", marginRight: "20px",}}
                            name="selectDiscipline"
                            value={selectDiscipline}
                            onChange={(e) => setSelectDiscipline(e.target.value)}
                        >
                            <option value="" selected>Select Discipline</option>
                            {disciplineList?.map((disc) => (
                                <option key={disc.id} value={disc.id}>
                                    {disc.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-3">
                        <label className="mt-2 mb-2">Discipline Course</label>
                        <select
                            className="form-control"
                            name="selectCourse"
                            value={selectCourse}
                            onChange={(e) => {
                                setSelectCourse(e.target.value)
                            }}
                        >
                            <option value="" selected>Select Course </option>
                            {courseList?.map((course) => (
                                <option key={course.id} value={course.id}>
                                    {course.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    {/* <div className="col-3">
                        <button className='btn btn-sm btn-primary mt-5 mb-3' onClick={getStudentArrear}>Search</button>
                    </div> */}
                    <div className="m-1 mb-1 pt-3 d-flex justify-content-end">
                        <button
                            type="button"
                            className="btn btn-sm btn-primary mt-4 mb-3"
                            style={{ marginRight: '10px' }}
                            onClick={getStudentArrear}
                        >
                            Search
                        </button>

                        <button
                            type="button"
                            className="btn btn-sm btn-secondary mt-4 mb-3"
                            onClick={resetData}
                        >Reset
                        </button>
                    </div>
                </div>
               
                        <div style={{margin: '0% 5% 0% 0%', float: 'right'}}>
                            {studentarrearList?.length > 0 && <div className="text-end mb-3">
                                <button
                                    type="button"
                                    onClick={handleDownloadPDF}
                                    className="m-1 btn btn-sm btn-primary"
                                >
                                    <Feather.Download /> PDF
                                </button>
                                <CSVLink
                                    data={csvData}
                                    filename="student_arrear.csv"
                                    className="btn btn-sm btn-primary"
                                >
                                    <Feather.Download /> CSV
                                </CSVLink>
                            </div>
                            }
                        </div>
                   
                <div className="container-fluid mt-3" style={{ width: "130%" }}>
                    <table className="table table-success table-striped">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>degree</th>
                                <th>discipline</th>
                                <th>register No</th>
                                <th>semester No</th>
                                <th>Discipline Course </th>
                                <th>attempt Count</th>
                                <th>cleared Semester</th>
                                <th>status</th>
                                {/* <th>Created By</th>
                                <th>Create Timestamp</th> */}
                                <th>Options</th>
                            </tr>
                        </thead>
                        <tbody>
                            {studentarrearList?.map((sa, index) => (
                                <tr key={sa.id}>
                                    <td>{index + 1}</td>
                                    <td>{getDegreeId(sa.degreeId)}</td>
                                    <td>{getDisciplineId(sa.disciplineId)}</td>
                                    <td>{sa.registerNo}</td>
                                    <td>{sa.semesterNo}</td>
                                    <td>{getCourseId(sa.disciplineCourseId)}</td>
                                    <td>{sa.attemptCount}</td>
                                    <td>{sa.clearedSemester}</td>
                                    <td>{sa.status}</td>
                                    {/* <td>{sa.createdBy}</td>
                                    <td>{sa.createTimestamp}</td> */}
                                    <td>
                                        <button className="btn btn-sm btn-primary" onClick={() => editHandler(sa)} style={{ marginRight: '10px' }}>Edit</button>
                                        <button className="btn btn-sm btn-danger" onClick={() => deleteHandler(sa.id || 0)} style={{ marginRight: '10px' }}>Delete</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    
                </div>
            </CollapseCard>
            {/* Deletion Confirmation Modal */}
            <Modal show={showDeleteConfirmation} onHide={closeDeleteConfirmation}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this item?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeDeleteConfirmation}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={confirmDeleteHandler}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            <ToastContainer position="top-right" />
        </PageContainer >
    );
}

export default StudentArrear;

interface StudentArrearType {
    id?: number;
    disciplineId: number;
    degreeId: number;
    registerNo: string;
    semesterNo: number;
    disciplineCourseId: number;
    attemptCount: number;
    clearedSemester: number;
    status: string;
    createdBy: string;
    createTimestamp: string;
}