import axios from 'axios';
import jsPDF from 'jspdf';
import autoTable, { RowInput } from 'jspdf-autotable';
import { useState } from 'react';
import * as Feather from 'react-feather';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Card from '../components/custom/Card';
import CollapseCard from '../components/custom/CollapseCard';
import FormInput from '../components/custom/FormInput';
import PageContainer from '../components/custom/PageContainer';
import { apiUrl } from '../config';
import { LocalFormatDate } from '../config/functions';
import { DegreeType } from '../redux/actions/degreeAction';
import { DisciplineType } from '../redux/actions/disciplineAction';
import { ExamType } from '../redux/actions/examAction';
import { StoreState } from '../redux/reducers';
import marksheetImg from './img/pits marksheet 2.png';

export interface MarkSheetReportType {
    id: number
    registerNo: number
    folioNo: string
    umisNo: string
    regulation: number
    departmentRegisterNumber: string
    name: string
    gender: string
    exam: string
    dateOfBirth: string
    degree: string
    discipline: string
    batch: string
    dateOfJoin: string
    noOfYears: number
    totalCourse: number
    currentSemester: number
    photo: string
    courseDetails: [
        {
            sem: number
            courseCode: string
            courseTitle: string
            courseType: string
            date?: string
            session?: string
            credit: string
            extMark: number
            grade: number
            gradeText: string
            unvGrade: number
            unvGradeText: string
        }
    ]
}

export interface CourseDetails {
    sem: number
    courseCode: string
    courseTitle: string
    courseType: string
    date?: string
    session?: string
    credit: string
    extMark: number
    grade: number
    gradeText: string
    unvGrade: number
    unvGradeText: string
}

interface GradeData {
    id: number
    registerNumber: string
    batch: string
    semester: string
    cRegistered: number
    cEarned: number
    gpEarned: number
    gpAverage: number
}

const MarkSheetReport = () => {

    // const { title } = props;
    const fileName = "Marksheet";
    const examList = useSelector<StoreState, ExamType[]>(state => state.exam)
    const degreeList = useSelector<StoreState, DegreeType[]>(state => state.degree)
    const disciplineList = useSelector<StoreState, DisciplineType[]>(state => state.discipline)
    const [marksheetData, setMarksheetData] = useState<MarkSheetReportType[]>([])
    const [gradeData, setGradeData] = useState<GradeData[]>([])

    console.log(marksheetData);

    const [exam, setExam] = useState<string>('')
    const [degree, setDegree] = useState<string>('');
    const [discipline, setDiscipline] = useState<string>('');
    // const [selectBatch, setSelectBatch] = useState<Student[]>([])
    const [batch, setBatch] = useState<string>('')
    const [semester, setSemester] = useState<string>('')
    const [semesterError, setSemesterError] = useState<string>('')
    const dateObj = new Date();

    const dd = dateObj.getDate()
    const mm = dateObj.getMonth() + 1
    const yyyy = dateObj.getFullYear()

    const generateDate = `${dd}-${mm}-${yyyy}`;

    // const data = [
    //   {
    //     "S.No": "1",
    //     "exam": "Nov/Dec",
    //     "Year": "2023",
    //     "collegeName": "Parisutham Institute of Technology and Science",
    //     "code": "123",
    //     "registerNumber": "CB20S31",
    //     "candidate": "John Doe",
    //     "DOB": "11/07/2003",
    //     "Degree": "B.E.",
    //     "Discipline": "Computer Science",
    //     "Regulations": "2017",
    //     "semester": 3,
    //     "courseDetails": [{
    //       "Sem.": "3",
    //       "courseCode": "321",
    //       "courseTitle": "Maths",
    //     }, {
    //       "Sem.": "3",
    //       "courseCode": "322",
    //       "courseTitle": "Physics",
    //     },
    //     {
    //       "Sem.": "4",
    //       "courseCode": "324",
    //       "courseTitle": "OR",
    //     },
    //     {
    //       "Sem.": "3",
    //       "courseCode": "321",
    //       "courseTitle": "Maths",
    //     }, {
    //       "Sem.": "3",
    //       "courseCode": "322",
    //       "courseTitle": "Physics",
    //     },
    //     {
    //       "Sem.": "4",
    //       "courseCode": "324",
    //       "courseTitle": "OR",
    //     },
    //     {
    //       "Sem.": "3",
    //       "courseCode": "321",
    //       "courseTitle": "Maths",
    //     }, {
    //       "Sem.": "3",
    //       "courseCode": "322",
    //       "courseTitle": "Physics",
    //     },
    //     {
    //       "Sem.": "4",
    //       "courseCode": "324",
    //       "courseTitle": "OR",
    //     },
    //     {
    //       "Sem.": "3",
    //       "courseCode": "321",
    //       "courseTitle": "Maths",
    //     }, {
    //       "Sem.": "3",
    //       "courseCode": "322",
    //       "courseTitle": "Physics",
    //     },
    //     {
    //       "Sem.": "4",
    //       "courseCode": "324",
    //       "courseTitle": "OR",
    //     },
    //     {
    //       "Sem.": "3",
    //       "courseCode": "321",
    //       "courseTitle": "Maths",
    //     }, {
    //       "Sem.": "3",
    //       "courseCode": "322",
    //       "courseTitle": "Physics",
    //     },
    //     {
    //       "Sem.": "4",
    //       "courseCode": "324",
    //       "courseTitle": "OR",
    //     }],
    //     "contactNumber": "9751365573",
    //     "noOfCourses": "4",
    //     "totalFees": "1000000",
    //   },

    //   {
    //     "S.No": "2",
    //     "exam": "Nov/Dec",
    //     "Year": "2023",
    //     "collegeName": "Parisutham Institute of Technology and Science",
    //     "code": "123",
    //     "registerNumber": "CB20S32",
    //     "candidate": "Jane Doe",
    //     "DOB": "05/15/2002",
    //     "Degree": "B.Tech",
    //     "Discipline": "Electrical Engineering",
    //     "Regulations": "2017",
    //     "Sem.": "3",
    //     "courseCode": "789",
    //     "courseTitle": "Mechanics",
    //     "contactNumber": "9876543210",
    //     "noOfCourses": "5",
    //     "totalFees": "200000",
    //   },
    //   {
    //     "S.No": "3",
    //     "exam": "Nov/Dec",
    //     "Year": "2023",
    //     "collegeName": "Parisutham Institute of Technology and Science",
    //     "code": "123",
    //     "registerNumber": "CB20S33",
    //     "candidate": "Alice Doe",
    //     "DOB": "03/22/2001",
    //     "Degree": "B.E.",
    //     "Discipline": "Mechanical Engineering",
    //     "Regulations": "2017",
    //     "Sem.": "3",
    //     "courseCode": "345",
    //     "courseTitle": "Thermodynamics",
    //     "contactNumber": "1234567890",
    //     "noOfCourses": "6",
    //     "totalFees": "300000",
    //   },
    //   {
    //     "S.No": "4",
    //     "exam": "Nov/Dec",
    //     "Year": "2023",
    //     "collegeName": "Parisutham Institute of Technology and Science",
    //     "code": "123",
    //     "registerNumber": "CB20S34",
    //     "candidate": "Bob Doe",
    //     "DOB": "09/18/2000",
    //     "Degree": "B.Sc.",
    //     "Discipline": "Physics",
    //     "Regulations": "2017",
    //     "Sem.": "3",
    //     "courseCode": "246",
    //     "courseTitle": "Electromagnetism",
    //     "contactNumber": "555-1234",
    //     "noOfCourses": "7",
    //     "totalFees": "400000",
    //   },
    //   {
    //     "S.No": "5",
    //     "exam": "Nov/Dec",
    //     "Year": "2023",
    //     "collegeName": "Parisutham Institute of Technology and Science",
    //     "code": "123",
    //     "registerNumber": "CB20S34",
    //     "candidate": "Sameera",
    //     "DOB": "11/07/2003",
    //     "Degree": "B.E.",
    //     "Discipline": "Computer Science",
    //     "Regulations": "2017",
    //     "courseDetails": [{
    //       "Sem.": "3",
    //       "courseCode": "321",
    //       "courseTitle": "Maths",
    //     }, {
    //       "Sem.": "3",
    //       "courseCode": "322",
    //       "courseTitle": "Physics",
    //     }],
    //     "contactNumber": "9751365573",
    //     "noOfCourses": "4",
    //     "totalFees": "1000000",
    //   },
    // ];

    // const filteredData = data.filter((row) => {
    //   return (
    //     row["Degree"].includes(degree) &&
    //     row["Discipline"].includes(discipline)
    //   );
    // })

    //Batch DropDown

    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let min = 0;
    let max = 10;

    let last10Years = [];
    for (let i = min; i <= max; i++) {
        let dropYear = year - i;
        last10Years.push(dropYear);
    }

    const handleSearch = () => {
        if (exam !== '' && degree !== '' && discipline !== '' && batch !== '' && semester !== '') {
            // let years = academicYear.split('-')
            axios.get(`${apiUrl}/student/marksheet/?degreeId=${degree}&disciplineId=${discipline}&examId=${exam}&batch=${batch}&sem=${semester}`)
                .then((response) => {
                    if (response.status === 200 && response.data.data) {
                        setMarksheetData(response.data.data);
                        handleFetchStudentGrade()
                    } else setMarksheetData([])
                })
                .catch((error) => {
                    console.error('Error fetching marksheet data', error);
                });
        } else {
            toast.error('Select All Filters', { position: 'top-right', autoClose: 3000 });

            setMarksheetData([])
        }
    }

    const handleFetchStudentGrade = () => {
        if (degree !== '' && discipline !== '' && batch !== '' && semester !== '') {
            // let years = academicYear.split('-')
            axios.get(`${apiUrl}/studentGrade/?degreeId=${degree}&disciplineId=${discipline}&batch=${batch}&sem=${semester}`)
                .then((response) => {
                    if (response.status === 200 && response.data.data) {
                        console.log(response.data.data);
                        setGradeData(response.data.data);
                    } else setGradeData([])
                })
                .catch((error) => {
                    console.error('Error fetching grade data', error);
                });
        }
    }


    const handleDownloadPDF = () => {
        const doc = new jsPDF('p', 'mm', 'a4');
        // doc.setFontSize(18);
        // doc.setFont('Arial', 'normal', 'bold');
        // doc.setTextColor(0, 0, 0);
        // doc.text(title, 107, 10, { align: 'center' });

        marksheetData?.forEach((h, i) => {
            if (i > 0) {
                doc.addPage(); // Add a new page for each Student except the first one
            }

            const headerRows1: RowInput[] = [];
            const headerRows2: RowInput[] = [];
            doc.setFontSize(15);
            doc.setFont('Times', 'normal', 'bold');
            // doc.setTextColor(0, 0, 0);
            // doc.text('title', 107, 10, { align: 'center' });
            // let eobj = examList?.find(f => f?.id?.toString() === exam)
            doc.rect(10, 10, 190, 278);

            headerRows1.push(
                [
                    { content: "Folio No." + (h.folioNo ? h.folioNo : ''), colSpan: 2, styles: { halign: "left", fontStyle: 'bold', fontSize: 8 } },
                    // { content: '', styles: { halign: "left" } },
                    { content: "UMIS No." + (h.umisNo ? h.umisNo : ''), colSpan: 2, styles: { halign: "left", fontStyle: 'bold', fontSize: 8 } },
                    // { content: '', styles: { halign: "left" } }
                ],
                [
                    { content: "NAME OF THE CANDIDATE", styles: { halign: "left", cellWidth: 35 } },
                    { content: h["name"], styles: { halign: "left", fillColor: '#ECEDED' } },
                    { content: "REGISTER NO.", styles: { halign: "left" } },
                    { content: h.registerNo, styles: { halign: "left", fillColor: '#ECEDED' } },
                ],

            );
            headerRows2.push(
                [
                    { content: "DATE OF BIRTH", styles: { halign: "left" } },
                    { content: LocalFormatDate(h.dateOfBirth?.toString()), styles: { halign: "left", fillColor: '#ECEDED' } },
                    { content: "GENDER", styles: { halign: "left", cellWidth: 19 } },
                    { content: h.gender, styles: { halign: "left", fillColor: '#ECEDED' } },
                    { content: "DATE OF PUBLICATION", styles: { halign: "left", cellWidth: 30 } },
                    { content: LocalFormatDate(h.dateOfBirth?.toString()), styles: { halign: "left", fillColor: '#ECEDED' } },
                    // { content: doc.addImage(apiUrl + "/" + h.photo, 'JPEG', 170, 25, 15, 15).toString(), rowSpan: 5, styles: { halign: 'center' } },
                ],
                [
                    { content: "PROGRAMME & DISCIPLINE", styles: { halign: "left", cellWidth: 35 } },
                    { content: `${h.degree} - ${h.discipline}`, colSpan: 3, styles: { halign: "left" } },
                    { content: "REGULATIONS", styles: { halign: "left" } },
                    { content: h.regulation, styles: { halign: "left" } }
                ],
            );
            //Table-1
            const firstTopTableHeight = autoTable(doc, {
                didDrawPage: (data) => {
                    doc.setFontSize(11);
                    doc.setFont('Times', 'bold');
                    // doc.text(eobj?.title!, data.settings.margin.left + 58, 51);
                    // doc.addImage(imgPath, 'PNG', 26, 11, 155, 22);
                    doc.setLineWidth(10);
                    doc.setDrawColor(0, 0, 0);
                    doc.stroke();
                },
                startY: 36,
                headStyles: { fontSize: 7, textColor: [0, 0, 0], lineColor: [0, 0, 0] },
                bodyStyles: { textColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 7 },
                tableWidth: 167,
                // head: headerRows1,
                body: headerRows1,
                theme: 'grid',
                margin: { left: 10, right: 10 },
            });

            doc.addImage(h.photo, 'JPG', 177.5, 36, 22.4, 32)
            doc.rect(177.2, 36, 22.7, 32)

            //Table-2
            const firstBottomTableHeight = autoTable(doc, {
                startY: (doc as any).lastAutoTable.finalY,
                headStyles: { fontSize: 8 },
                bodyStyles: { textColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 7 },
                // head: headerRows2,
                body: headerRows2,
                tableWidth: 167,
                theme: 'grid',
                margin: { left: 10 },
            });

            const contentTableHeaderRows: RowInput[] = [[
                { content: "SEM.NO.", styles: { halign: "center" } },
                { content: "COURSE CODE", styles: { halign: "center" } },
                { content: "TITLE OF THE COURSE", styles: { halign: "center", fillColor: '#ECEDED' } },
                { content: "CREDITS", styles: { halign: "center" } },
                { content: "LETTER GRADE", styles: { halign: "center" } },
                { content: "GRADE POINT", styles: { halign: "center" } },
                { content: "RESULT", styles: { halign: "center" } },
            ],
            ];

            // const contentTableBodyData: RowInput[] = h?.courseDetails?.map((course, index) => [
            //     { content: course.sem, styles: { halign: 'center' } },
            //     { content: course.courseCode, styles: { halign: 'center' } },
            //     { content: course.courseTitle, styles: { halign: 'left' } },
            //     { content: course.credit, styles: { halign: 'center' } },
            //     { content: course.unvGradeText !== null ? course.unvGradeText : '-', styles: { halign: 'center' } },
            //     { content: course.unvGrade !== null ? course.unvGrade : '-', styles: { halign: 'center' } },
            //     {
            //         content: course.extMark === -1
            //             ? (course.unvGradeText === 'U'
            //                 ? 'RA *'
            //                 : (course.unvGrade > 0
            //                     ? 'PASS'
            //                     : ''))
            //             : (course.unvGradeText === 'U'
            //                 ? 'RA'
            //                 : (course.unvGrade > 0
            //                     ? 'PASS'
            //                     : '')),
            //         styles: { halign: 'center' }
            //     },
            // ])

            const groupedCourses = h?.courseDetails?.reduce((acc: { [key: string]: CourseDetails }, course: CourseDetails) => {
                if (!acc[course.courseCode]) {
                    acc[course.courseCode] = { ...course, extMark: course.extMark };
                } else {
                    // acc[course.courseCode].extMark! += course.extMark; // Use extMarkSum for duplicates
                    acc[course.courseCode].credit! += course.credit;
                    if (course.courseType === 'Theory') {
                        acc[course.courseCode].unvGrade! = course.unvGrade;
                        acc[course.courseCode].unvGradeText! = course.unvGradeText;
                    }
                }
                return acc;
            }, {} as { [key: string]: CourseDetails });

            // Now map over the grouped data to generate the table rows
            const contentTableBodyData: RowInput[] = Object.values(groupedCourses).map((course: CourseDetails, index: number) => [
                { content: course.sem, styles: { halign: 'center' } },
                { content: course.courseCode, styles: { halign: 'center' } },
                { content: course.courseTitle, styles: { halign: 'left' } },
                { content: course.credit, styles: { halign: 'center' } },
                { content: course.unvGradeText !== null ? course.unvGradeText : '-', styles: { halign: 'center' } },
                { content: course.unvGrade !== null ? course.unvGrade : '-', styles: { halign: 'center' } },
                {
                    content: course.extMark === -1
                        ? (course.unvGradeText === 'U'
                            ? 'RA *'
                            : (course.unvGrade > 0
                                ? 'PASS'
                                : ''))
                        : (course.unvGradeText === 'U'
                            ? 'RA'
                            : (course.unvGrade > 0
                                ? 'PASS'
                                : '')),
                    styles: { halign: 'center' }
                },
            ]);


            //bottom table
            let topTablefinalY: number = (doc as any).lastAutoTable.finalY
            // let bodyLengthWithoutEmptyRows = bodyData.length;
            const emptyRowsCount = 19 - contentTableBodyData.length;
            for (let i = 0; i < emptyRowsCount; i++) {
                contentTableBodyData.push([
                    { content: '', styles: { halign: 'center', } },
                    { content: '', styles: { halign: 'center', } },
                    { content: '', styles: { halign: 'center', } },
                    { content: '', styles: { halign: 'center', } },
                    { content: '', styles: { halign: 'center', } },
                    { content: '', styles: { halign: 'center', } },
                    { content: '', styles: { halign: 'center', } },

                ]);
            };

            //Table-3
            //2nd left table
            const secondleftTableHeight = autoTable(doc, {
                willDrawCell: (data) => {
                    const fullHeight = 150;
                    // const fullHeight = bodyLengthWithoutEmptyRows <= 10 ? 125 : 140;
                    doc.setLineWidth(0.2)
                    doc.line(20, topTablefinalY, 20, topTablefinalY + fullHeight)
                    doc.line(36, topTablefinalY, 36, topTablefinalY + fullHeight)
                    doc.line(120, topTablefinalY, 120, topTablefinalY + fullHeight)
                    doc.line(140, topTablefinalY, 140, topTablefinalY + fullHeight)
                    doc.line(160, topTablefinalY, 160, topTablefinalY + fullHeight)
                    doc.line(180, topTablefinalY, 180, topTablefinalY + fullHeight)
                },
                startY: topTablefinalY,
                headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0], fontSize: 7 },
                bodyStyles: { textColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 7 },
                // tableLineColor: 'black',
                // tableLineWidth: 0.3,
                head: contentTableHeaderRows,
                body: contentTableBodyData,
                theme: 'plain',
                margin: { top: 10, left: 10, right: 10 },
                tableWidth: 91,
                columnStyles: {
                    0: { cellWidth: 10, minCellHeight: 6.5 },
                    1: { cellWidth: 16, minCellHeight: 6.5 },
                    2: { cellWidth: 84, minCellHeight: 6.5 },
                    3: { cellWidth: 20, minCellHeight: 6.5 },
                    4: { cellWidth: 20, minCellHeight: 6.5 },
                    5: { cellWidth: 20, minCellHeight: 6.5 },
                    6: { cellWidth: 20, minCellHeight: 6.5 }
                },
            });

            // const fullHeight = Math.round((doc as any).lastAutoTable.finalY) - (topTablefinalY);
            // const minCellHeight = (Math.floor(fullHeight / 15)) - 3

            doc.setFontSize(9);
            doc.setFont('Times', 'normal', 'bold');
            //old
            // let totalCreditsRegistered = 0;
            // let totalCreditsEarned = 0;
            // let totalGradePointsEarned = 0;
            // let GPA = 0;
            // let cumulativeCredits = 0;

            // h?.courseDetails?.forEach(cd => {
            //     totalCreditsRegistered += parseInt(cd.credit)
            //     totalGradePointsEarned += (cd.unvGrade)
            //     if (cd.unvGrade > 0) {
            //         totalCreditsEarned += parseInt(cd.credit)
            //         cumulativeCredits += parseInt(cd.credit)
            //         GPA += (cd.unvGrade) * parseInt(cd.credit)
            //     }
            // })
            // GPA = GPA / totalCreditsEarned;
            //old

            //
            // let cumulativeCreditss = 0;
            // let cumulativeGPA = 0;
            // for (let index = 0; index < parseInt(semester); index++) {
            //     const element = studentGradeArray[index];

            //     let totalCreditsRegistered = element.cRegistered;
            //     let totalCreditsEarned = element.cEarned;
            //     let totalGradePointsEarned = element.gpEarned;
            //     let GPA = element.gpAverage;
            //     cumulativeCreditss = +element.cEarned;
            //     cumulativeGPA = +element.gpAverage;
            // }
            //
            let studentGradeArray = gradeData.filter(f => f.registerNumber.toString() === h.registerNo.toString())

            const getCreditsRegistered = (sem: number) => {
                let obj = studentGradeArray.find(fi => fi.semester.toString() === sem.toString())
                return obj ? obj?.cRegistered : ''
            }
            const getCreditsEarned = (sem: number) => {
                let obj = studentGradeArray.find(fi => fi.semester.toString() === sem.toString())
                return obj ? obj?.cEarned : ''
            }
            const getGradePointsEarned = (sem: number) => {
                let obj = studentGradeArray.find(fi => fi.semester.toString() === sem.toString())
                return obj ? obj?.gpEarned : ''
            }
            const getGradePointsAvg = (sem: number) => {
                let obj = studentGradeArray.find(fi => fi.semester.toString() === sem.toString())
                return obj ? obj?.gpAverage : ''
            }
            const getCumulativeGPA = (sem: number) => {
                let cumulativeGPA = 0;
                for (let index = 0; index < sem; index++) {
                    let obj = studentGradeArray.find(fi => fi.semester.toString() === (index + 1).toString())
                    // const element = studentGradeArray[index];
                    cumulativeGPA = (obj ? cumulativeGPA + obj.gpAverage : 0);
                }
                return cumulativeGPA !== 0 ? cumulativeGPA.toFixed(2) : ''
            }
            const getCumulativeCredits = (sem: number) => {
                let cumulativeCreditss = 0;
                for (let index = 0; index < sem; index++) {
                    let obj = studentGradeArray.find(fi => fi.semester.toString() === (index + 1).toString())
                    // const element = studentGradeArray[index];
                    cumulativeCreditss = (obj ? cumulativeCreditss + obj.cEarned : 0);
                }
                return cumulativeCreditss !== 0 ? cumulativeCreditss : ''
            }

            const thirdTableContent: RowInput[] = [];
            thirdTableContent.push(
                [
                    { content: 'Semester', styles: { halign: 'left', fontStyle: 'bold', cellPadding: 1 } },
                    { content: 'I', styles: { halign: 'center', fontStyle: 'bold' } },
                    { content: 'II', styles: { halign: 'center', fontStyle: 'bold' } },
                    { content: 'III', styles: { halign: 'center', fontStyle: 'bold' } },
                    { content: 'IV', styles: { halign: 'center', fontStyle: 'bold' } },
                    { content: 'V', styles: { halign: 'center', fontStyle: 'bold' } },
                    { content: 'VI', styles: { halign: 'center', fontStyle: 'bold' } },
                    { content: 'VII', styles: { halign: 'center', fontStyle: 'bold' } },
                    { content: 'VIII', styles: { halign: 'center', fontStyle: 'bold' } },
                ],
                [
                    { content: 'Credits Registered', styles: { halign: 'left', fontStyle: 'bold', cellPadding: 1 } },
                    { content: getCreditsRegistered(1), styles: { halign: 'center', cellPadding: 1 } }, { content: getCreditsRegistered(2), styles: { halign: 'center', cellPadding: 1 } },
                    { content: getCreditsRegistered(3), styles: { halign: 'center', cellPadding: 1 } },
                    { content: getCreditsRegistered(4), styles: { halign: 'center', cellPadding: 1 } },
                    { content: getCreditsRegistered(5), styles: { halign: 'center', cellPadding: 1 } },
                    { content: getCreditsRegistered(6), styles: { halign: 'center', cellPadding: 1 } },
                    { content: getCreditsRegistered(7), styles: { halign: 'center', cellPadding: 1 } },
                    { content: getCreditsRegistered(8), styles: { halign: 'center', cellPadding: 1 } }

                ],
                [
                    { content: 'Credits Earned', styles: { halign: 'left', fontStyle: 'bold', cellPadding: 1 } },
                    { content: getCreditsEarned(1), styles: { halign: 'center', cellPadding: 1 } },
                    { content: getCreditsEarned(2), styles: { halign: 'center', cellPadding: 1 } },
                    { content: getCreditsEarned(3), styles: { halign: 'center', cellPadding: 1 } },
                    { content: getCreditsEarned(4), styles: { halign: 'center', cellPadding: 1 } },
                    { content: getCreditsEarned(5), styles: { halign: 'center', cellPadding: 1 } },
                    { content: getCreditsEarned(6), styles: { halign: 'center', cellPadding: 1 } },
                    { content: getCreditsEarned(7), styles: { halign: 'center', cellPadding: 1 } },
                    { content: getCreditsEarned(8), styles: { halign: 'center', cellPadding: 1 } }
                ],
                [
                    { content: 'Grade Points Earned', styles: { halign: 'left', fontStyle: 'bold', cellPadding: 1 } },
                    // { content: totalGradePointsEarned, styles: { halign: 'center', cellPadding: 1 } }, { content: '' }, { content: '' }, { content: '' }, { content: '' }, { content: '' }, { content: '' }, { content: '' }
                    { content: getGradePointsEarned(1), styles: { halign: 'center', cellPadding: 1 } },
                    { content: getGradePointsEarned(2), styles: { halign: 'center', cellPadding: 1 } },
                    { content: getGradePointsEarned(3), styles: { halign: 'center', cellPadding: 1 } },
                    { content: getGradePointsEarned(4), styles: { halign: 'center', cellPadding: 1 } },
                    { content: getGradePointsEarned(5), styles: { halign: 'center', cellPadding: 1 } },
                    { content: getGradePointsEarned(6), styles: { halign: 'center', cellPadding: 1 } },
                    { content: getGradePointsEarned(7), styles: { halign: 'center', cellPadding: 1 } },
                    { content: getGradePointsEarned(8), styles: { halign: 'center', cellPadding: 1 } }
                ],
                [
                    { content: 'Grade Points Average(GPA)', styles: { halign: 'left', fontStyle: 'bold', cellPadding: 1 } },
                    // { content: GPA.toFixed(2), styles: { halign: 'center', cellPadding: 1 } }, { content: '' }, { content: '' }, { content: '' }, { content: '' }, { content: '' }, { content: '' }, { content: '' }
                    { content: getGradePointsAvg(1), styles: { halign: 'center', cellPadding: 1 } },
                    { content: getGradePointsAvg(2), styles: { halign: 'center', cellPadding: 1 } },
                    { content: getGradePointsAvg(3), styles: { halign: 'center', cellPadding: 1 } },
                    { content: getGradePointsAvg(4), styles: { halign: 'center', cellPadding: 1 } },
                    { content: getGradePointsAvg(5), styles: { halign: 'center', cellPadding: 1 } },
                    { content: getGradePointsAvg(6), styles: { halign: 'center', cellPadding: 1 } },
                    { content: getGradePointsAvg(7), styles: { halign: 'center', cellPadding: 1 } },
                    { content: getGradePointsAvg(8), styles: { halign: 'center', cellPadding: 1 } }
                ],
                [
                    { content: 'Cumulative Credits Earned', styles: { halign: 'left', fontStyle: 'bold', cellPadding: 1 } },
                    // { content: cumulativeCredits, styles: { halign: 'center', cellPadding: 1 } }, { content: '' }, { content: '' }, { content: '' }, { content: '' }, { content: '' }, { content: '' }, { content: '' }
                    { content: getCumulativeCredits(1), styles: { halign: 'center', cellPadding: 1 } },
                    { content: getCumulativeCredits(2), styles: { halign: 'center', cellPadding: 1 } },
                    { content: getCumulativeCredits(3), styles: { halign: 'center', cellPadding: 1 } },
                    { content: getCumulativeCredits(4), styles: { halign: 'center', cellPadding: 1 } },
                    { content: getCumulativeCredits(5), styles: { halign: 'center', cellPadding: 1 } },
                    { content: getCumulativeCredits(6), styles: { halign: 'center', cellPadding: 1 } },
                    { content: getCumulativeCredits(7), styles: { halign: 'center', cellPadding: 1 } },
                    { content: getCumulativeCredits(8), styles: { halign: 'center', cellPadding: 1 } }
                ],
                [
                    { content: 'Cumulative Grade Points Average(CGPA)', styles: { halign: 'left', fontStyle: 'bold', cellPadding: 1 } },
                    // { content: GPA.toFixed(2), styles: { halign: 'center', cellPadding: 1 } }, { content: '' }, { content: '' }, { content: '' }, { content: '' }, { content: '' }, { content: '' }, { content: '' }
                    { content: getCumulativeGPA(1), styles: { halign: 'center', cellPadding: 1 } },
                    { content: getCumulativeGPA(2), styles: { halign: 'center', cellPadding: 1 } },
                    { content: getCumulativeGPA(3), styles: { halign: 'center', cellPadding: 1 } },
                    { content: getCumulativeGPA(4), styles: { halign: 'center', cellPadding: 1 } },
                    { content: getCumulativeGPA(5), styles: { halign: 'center', cellPadding: 1 } },
                    { content: getCumulativeGPA(6), styles: { halign: 'center', cellPadding: 1 } },
                    { content: getCumulativeGPA(7), styles: { halign: 'center', cellPadding: 1 } },
                    { content: getCumulativeGPA(8), styles: { halign: 'center', cellPadding: 1 } }
                ],
            )

            // thirdTableContent.push(
            //     [
            //         { content: `NOTE :`, styles: { cellWidth: 11, fontSize: 8, fontStyle: 'bold', cellPadding: { left: 1, top: 2 } } },
            //         { content: '', styles: {}, colSpan: 8 },
            //     ],
            //     [
            //         { content: '1. ', styles: { fontSize: 9, cellPadding: { left: 4 } } },
            //         { content: `In case of candidates who have been Readmitted / Transferred,this Hall Ticket is valid only if the candidate’s admission is approved by the Commissioner of Technical Education, Government of Tamil Nadu, Chennai and the Registrar, Anna University, Chennai. If any candidate appears for the examination without the approval, the examination written by the candidate will be invalidated.`, colSpan: 8, styles: { fontSize: 9, cellPadding: { top: 0 } } },
            //     ],
            //     [
            //         { content: '2. ', styles: { fontSize: 9, cellPadding: { left: 4, top: 1.5 } } },
            //         { content: `Correction in the Name, Date of Birth and missing of Photograph or incorrect Photograph if any, is to be intimated to the Controller of Examinations,  immediately.`, colSpan: 8, styles: { fontSize: 9, cellPadding: { top: 1.5 } } },
            //     ],
            //     [
            //         { content: '3. ', styles: { fontSize: 9, cellPadding: { left: 4, top: 1.5 } } },
            //         { content: 'Instructions printed overleaf are to be followed strictly.', colSpan: 9, styles: { fontSize: 9, cellPadding: { top: 1.5 } } }
            //     ],
            //     [
            //         { content: '', styles: {} },
            //         { content: `Generated on : ${generateDate}`, colSpan: 9, styles: { fontSize: 9, cellPadding: { top: 2 } } },

            //     ]
            // );
            //note table
            const thirdTableHeight = autoTable(doc, {
                startY: (doc as any).lastAutoTable.finalY,
                bodyStyles: { textColor: [0, 0, 0], fontSize: 7, lineColor: 'black', cellPadding: 0.6 },
                body: thirdTableContent,
                theme: 'grid',
                tableLineColor: 'black',
                tableLineWidth: 0.2,
                margin: { left: 10, right: 10 },
                columnStyles: {
                    0: { cellWidth: 55, minCellHeight: 4 },
                    1: { cellWidth: 17, minCellHeight: 4 },
                    2: { cellWidth: 17, minCellHeight: 4 },
                    3: { cellWidth: 17, minCellHeight: 4 },
                    4: { cellWidth: 17, minCellHeight: 4 },
                    5: { cellWidth: 17, minCellHeight: 4 },
                    6: { cellWidth: 17, minCellHeight: 4 }
                },
            });

            const fourthTableContent: RowInput[] = [
                [{ content: `Medium Of Instruction - ENGLISH `, styles: { halign: 'center', fontStyle: 'bold' }, colSpan: 8 }]
            ];

            //no. of table
            const fourthTableHeight = autoTable(doc, {
                startY: (doc as any).lastAutoTable.finalY,
                bodyStyles: { textColor: [0, 0, 0], fontSize: 7, lineColor: 'white' },
                body: fourthTableContent,
                theme: 'grid',
                tableLineColor: 'black',
                tableLineWidth: 0.3,
                margin: { left: 10, right: 10 },
            });

            //sign table
            const fifthTableContent: RowInput[] = []
            fifthTableContent.push(
                [
                    { content: 'RA-Reappearance is required', styles: { halign: "left", fontSize: 7, } },
                    { content: 'W-Withdrawal', styles: { halign: "left", fontSize: 7, } },
                    { content: 'SE-Sports Exemption', styles: { halign: "center", fontSize: 7, } },
                    { content: '*Absent for examination', styles: { halign: "right", fontSize: 7, } },
                ],

                // [
                //     { content: 'STATION DATE', styles: { cellPadding: { top: 19 }, halign: "left", fontSize: 8, fontStyle: 'bold', } },

                //     { content: 'Signature of the Candidate', colSpan: 2, styles: { cellPadding: { top: 19 }, halign: "center", fontSize: 8, fontStyle: 'bold' } },

                //     { content: 'Controller of Examinations', styles: { cellPadding: { top: 19 }, halign: "right", fontSize: 8, fontStyle: 'bold' } }
                // ],
            )

            const fifthTableHeight = autoTable(doc, {
                startY: (doc as any).lastAutoTable.finalY,
                // bodyStyles: { textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0] },
                body: fifthTableContent,
                theme: 'plain',
                margin: { left: 10, right: 10 },
            })

            doc.addPage(); // Add a 2nd page
            // const sixthTableContent: RowInput[] = []
            // sixthTableContent.push(
            //     [
            //         { content: 'Grade Classification', colSpan: 4, styles: { halign: "right", fontSize: 8, fontStyle: 'bold' } },
            //         { content: 'R-' + h.regulation, colSpan: 4, styles: { halign: "left", fontSize: 8, fontStyle: 'bold' } },
            //     ],
            //     [
            //         { content: 'Range of marks', styles: { halign: "center", fontSize: 7, } },
            //         { content: '91-100', styles: { halign: "left", fontSize: 7, } },
            //         { content: '81-90', styles: { halign: "left", fontSize: 7, } },
            //         { content: '71-80', styles: { halign: "left", fontSize: 7, } },
            //         { content: '61-70', styles: { halign: "left", fontSize: 7, } },
            //         { content: '57-60', styles: { halign: "left", fontSize: 7, } },
            //         { content: '50-56', styles: { halign: "left", fontSize: 7, } },
            //         { content: '<50', styles: { halign: "left", fontSize: 7, } },
            //     ],
            //     [
            //         { content: 'Letter Grade', styles: { halign: "center", fontSize: 7, } },
            //         { content: 'O', styles: { halign: "left", fontSize: 7, } },
            //         { content: 'A+', styles: { halign: "left", fontSize: 7, } },
            //         { content: 'A', styles: { halign: "left", fontSize: 7, } },
            //         { content: 'B+', styles: { halign: "left", fontSize: 7, } },
            //         { content: 'B', styles: { halign: "left", fontSize: 7, } },
            //         { content: 'C', styles: { halign: "left", fontSize: 7, } },
            //         { content: 'U', styles: { halign: "left", fontSize: 7, } },
            //     ],
            //     [
            //         { content: 'Grade Point', styles: { halign: "center", fontSize: 7, } },
            //         { content: '10', styles: { halign: "left", fontSize: 7, } },
            //         { content: '9', styles: { halign: "left", fontSize: 7, } },
            //         { content: '8', styles: { halign: "left", fontSize: 7, } },
            //         { content: '7', styles: { halign: "left", fontSize: 7, } },
            //         { content: '6', styles: { halign: "left", fontSize: 7, } },
            //         { content: '5', styles: { halign: "left", fontSize: 7, } },
            //         { content: '0', styles: { halign: "left", fontSize: 7, } },
            //     ],
            //     [
            //         { content: 'CBCS - CHOICE BASED CREDIT SYSTEM', colSpan: 8, styles: { halign: "center", fontSize: 8, } },
            //     ],
            //     [
            //         { content: 'FORMULA FOR GPA & CGPA', colSpan: 8, styles: { halign: "center", fontSize: 8, } },
            //     ],
            //     [
            //         { content: 'GPA = ', colSpan: 4, styles: { halign: "center", fontSize: 8, } },
            //         { content: 'CGPA = ', colSpan: 4, styles: { halign: "center", fontSize: 8, } },
            //     ],
            //     [
            //         { content: 'Where Ci', styles: { halign: "center", fontSize: 8, } },
            //         { content: '-', styles: { halign: "center", fontSize: 8, } },
            //         { content: 'is the Credits assigned to the course', colSpan: 6, styles: { halign: "left", fontSize: 8, } },
            //     ],
            //     [
            //         { content: 'GPi', styles: { halign: "center", fontSize: 8, } },
            //         { content: '-', styles: { halign: "center", fontSize: 8, } },
            //         { content: 'is the point corresponding to the grade obtained for each course', colSpan: 6, styles: { halign: "left", fontSize: 8, } },
            //     ],
            //     [
            //         { content: 'n', styles: { halign: "center", fontSize: 8, } },
            //         { content: '-', styles: { halign: "center", fontSize: 8, } },
            //         { content: 'is number of all courses successfully cleared during the particular semester in the case of GPA and during all the semesters in the case of CGPA', colSpan: 6, styles: { halign: "left", fontSize: 8, } },
            //     ],
            //     [

            //         { content: 'The Grading Methodology employed here for the candidate is based on Regulations - ' + h.regulation + '(R-' + h.regulation + ')', colSpan: 8, styles: { halign: "left", fontSize: 9, } },
            //     ],
            // )

            // const sixthTableHeight = autoTable(doc, {
            //     startY: 50,
            //     // bodyStyles: { textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0] },
            //     // head: [
            //     //     { content: 'Grade Classification',  styles: { halign: "left", fontSize: 7, } },
            //     //     { content: 'R-' + h.regulation, styles: { halign: "left", fontSize: 7, } },
            //     // ],
            //     body: sixthTableContent,
            //     bodyStyles: { fontStyle: 'bold' },
            //     theme: 'plain',
            //     margin: { left: 10, right: 10 },
            // })
            doc.setFontSize(10);
            doc.text('GRADE CLASSIFICATION         R - ' + h.regulation, 76, 38)

            doc.addImage(marksheetImg, 'PNG', 30, 40, 150, 120);
            doc.rect(30, 170, 151, 7);

            doc.text('The Grading methodology employed here for the candidate is based on Regulations - ' + h.regulation + ' (R-' + h.regulation + ')', 31, 175).setFontSize(10).setFont('Ariel', 'bold');

            doc.setFontSize(17);
            doc.setFont('Ariel', 'normal', 'bold');
            doc.text('OFFICE SEAL', 90, 205)

        });


        doc.save(fileName + '.pdf');
    }

    return (
        <>
            <PageContainer title="Marksheet">
                <Card title="filters">
                    <div className="row">
                        <div className="col-sm-6 col-md-4 col-lg-3">
                            <label>Exam <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                value={exam}
                                onChange={(e) => setExam(e.target.value)}
                            >
                                <option value="">Select Exam</option>
                                {examList?.map((ex) => (
                                    <option value={ex.id}>{ex.title}</option>
                                ))
                                }
                            </select>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3">
                            <label>Degree <span className="text-danger">*</span> :</label>
                            <select
                                className="form-control"
                                value={degree}
                                onChange={(e) => setDegree(e.target.value)}
                            >
                                <option value="">Select Degree</option>
                                {degreeList?.map((deg) => (
                                    <option value={deg.id}>{deg.name}</option>
                                ))
                                }
                            </select>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3">
                            <label>Discipline <span className="text-danger">*</span> :</label>
                            <select
                                className="form-control"
                                value={discipline}
                                onChange={(e) => setDiscipline(e.target.value)}
                            >
                                <option value="">Select Discipline</option>
                                {disciplineList?.map((dis) => (
                                    <option value={dis.id}>{dis.name}</option>
                                ))
                                }
                            </select>
                        </div>

                        <div className="col-sm-6 col-md-4 col-lg-3">
                            <label>Batch <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                value={batch}
                                onChange={(e) => setBatch(e.target.value)}
                            >
                                <option value=""> Select Batch </option>
                                {last10Years.map((bt) => (
                                    <option value={bt} key={bt}>{bt}</option>
                                ))
                                }
                            </select>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3">
                            <FormInput
                                name="Semester"
                                label="semester"
                                labelClassName="required"
                                value={semester}
                                onChange={(e) => {
                                    setSemester(e.target.value)
                                    setSemesterError('')
                                }}
                                placeholder="Select Sem"
                                containerClass="mt-2"
                                type="select"
                                errorText={semesterError}
                            >
                                <option value="">Select Semester</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                            </FormInput>

                        </div>
                    </div>
                    {/* <div className="col-lg-12 col-sm-12"> */}
                    <div className=" col-12 text-end">
                        <button className='btn btn-sm btn-primary' onClick={handleSearch} style={{ float: "right", width: "10%", marginTop: "30px" }}>Search</button>
                    </div>
                    {/* </div> */}
                </Card>
                <CollapseCard title="Marksheet">
                    <div className="container-fluid ">
                        <table className="table table-success table-striped">
                            <thead>
                                <tr>
                                    <th>S.No.</th>
                                    <th>Register Number</th>
                                    <th>Name of the Candidate</th>
                                    <th>Date of Birth</th>
                                    <th>Degree</th>
                                    <th>Discipline</th>
                                    <th>Semester</th>
                                </tr>
                            </thead>
                            <tbody>
                                {marksheetData?.map((row, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        {/* <img src={apiUrl + "/" + row.photo} alt={row.name}></img> */}
                                        <td>{row.registerNo}</td>
                                        <td>{row.name}</td>
                                        <td>{LocalFormatDate(row.dateOfBirth)}</td>
                                        <td>{row.degree}</td>
                                        <td>{row.discipline}</td>
                                        <td>{row.currentSemester}</td>
                                        {/* <td>{row.totalCourse}</td> */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="text-end mb-3">
                        {marksheetData?.length > 0 && <button
                            type="button"
                            onClick={handleDownloadPDF}
                            className="m-1 btn btn-sm btn-primary"
                        >
                            <Feather.Download /> PDF
                        </button>}
                        {/* <CSVLink
              data={csvData}
              filename="details_of_course.csv"
              className="m-1 btn btn-s btn-primary"
            >
              <Feather.Download /> CSV
            </CSVLink> */}
                    </div>
                </CollapseCard>
            </PageContainer>
        </>
    )


}

export default MarkSheetReport;