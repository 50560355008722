import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from '../components/custom/Card';
import CollapseCard from '../components/custom/CollapseCard';
import PageContainer from '../components/custom/PageContainer';
import { HallType, addHall, deleteHall, fetchHall, updateHall } from '../redux/actions/hallAction';
import { StoreState } from '../redux/reducers';
import { AppDispatch } from '../redux/store';


const Hall = () => {

    const dispatch = useDispatch<AppDispatch>()
    const hallList = useSelector<StoreState, HallType[]>(state => state.hall)

    // const [hallList, setHallList] = useState<Hall[]>([]);
    const [editFlag, setEditFlag] = useState(false);
    const [name, setName] = useState('');
    const [seatingCapacity, setSeatingCapacity] = useState('');
    const [id, setId] = useState<number>(0);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [nameError, setNameError] = useState('');
    const [seatingCapacityError, setSeatingCapacityError] = useState('');

    //fetch hall
    useEffect(() => {
        dispatch(fetchHall())
    }, [])


    const clearHandler = () => {
        setName('');
        setNameError('')
        setSeatingCapacity('');
        setSeatingCapacityError('')
        setId(0);
        setEditFlag(false)
    }

    //add hall
    const addHandler = () => {
        let error = false
        if (name.length === 0) {
            setNameError('Name is required');
            error = true
        }
        if (seatingCapacity.length === 0) {
            setSeatingCapacityError('Seating capacity is required');
            error = true
        }
        if (name.length > 100) {
            setNameError('Name must be within 100 characters');
            error = true

        }
        if (seatingCapacity.length > 11) {
            setSeatingCapacityError('Seating capacity must be within 11 characters');
            error = true

        }
        const addData = { name, seatingCapacity };

        if (!error) {
            dispatch(addHall(addData)).then((text) => {
                toast.success(text)
                clearHandler()
            }).catch(text => {
                toast.error(text)
            })
        }
    }

    const editHandler = (hl: HallType) => {
        clearHandler()
        setEditFlag(true);
        setId(hl.id ? hl.id : 0);
        setName(hl.name);
        setSeatingCapacity(hl.seatingCapacity);

    }

    const updateHandler = () => {
        let error = false

        if (name.length === 0) {
            setNameError('Name is required');
            error = true
        }
        if (seatingCapacity.length === 0) {
            setSeatingCapacityError('Seating capacity is required');
            error = true
        }
        if (name.length > 100) {
            setNameError('Name must be within 100 characters');
            error = true

        }
        if (seatingCapacity.length > 11) {
            setSeatingCapacityError('Seating capacity must be within 11 characters');
            error = true

        }

        const editData = { id, name, seatingCapacity };

        if (!error) {
            dispatch(updateHall(editData, id)).then(text => {
                toast.success(text)
                clearHandler()
            }).catch(text => {
                toast.error(text)
            })
        }

    }

    const deleteHandler = (id: number) => {
        setId(id);
        openDeleteConfirmation(); // Open the delete confirmation modal
    }

    const confirmDeleteHandler = () => {
        if (id !== undefined) {
            dispatch(deleteHall(id)).then(text => {
                toast.success(text)
                closeDeleteConfirmation()
            }).catch(text => {
                toast.error(text)
            })
        }
    };

    const openDeleteConfirmation = () => {
        setShowDeleteConfirmation(true);
    };

    const closeDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    };

    // const deleteHandler = (id: number) => {
    //     setId(id);
    //     openDeleteConfirmation(); // Open the delete confirmation modal
    // }

    return (
        <PageContainer title="Hall">
            <Card title={editFlag ? 'Edit Hall' : 'Add Hall'}>
                <div className='container m-0'>
                    <div className="row">
                        <div className="col-6">
                            <label className="mt-3 mb-3">Name <span className="text-danger">*</span> : </label>
                            <input className="form-control" placeholder="Enter name..." type="text" name="name" onChange={(e) => {
                                setName(e.target.value)
                                setNameError('')
                            }} value={name} />
                            {nameError && <div className="text-danger">{nameError}</div>}</div>

                        <div className="col-6">
                            <label className="mt-3 mb-3">Seating Capacity <span className="text-danger">*</span> : </label>
                            {/* <input className="form-control" placeholder="Enter seating capacity..." type="number" name="seatingCapacity" onChange={(e) => {
                                setSeatingCapacity(e.target.value)
                                setSeatingCapacityError('')
                            }} value={seatingCapacity} /> */}
                            <select className="form-control" placeholder="Enter seating capacity..." name="seatingCapacity" onChange={(e) => {
                                setSeatingCapacity(e.target.value)
                                setSeatingCapacityError('')
                            }} value={seatingCapacity} >
                                <option value="">Select</option>
                                <option value="26">26</option>
                                <option value="52">52</option>
                            </select>
                            {seatingCapacityError && <div className="text-danger">{seatingCapacityError}</div>}</div>

                    </div>

                    <div className="col m-4 d-flex justify-content-end">
                        <button className="btn btn-sm btn-secondary" onClick={clearHandler} style={{ marginRight: '10px' }}>
                            Clear
                        </button>
                        {editFlag ? <button className="btn btn-sm btn-success" onClick={updateHandler}>Update</button> :
                            <button className="btn btn-sm btn-success" onClick={addHandler}>Add</button>}
                    </div>

                </div>
            </Card>
            <CollapseCard title="Hall">
                <div className="container-fluid" style={{ width: "100%" }}>
                    <table className="table table-success table-striped">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Name</th>
                                <th>Seating Capacity</th>
                                <th>Options</th>
                            </tr>
                        </thead>
                        <tbody>
                            {hallList.map((hl, index) => (
                                <tr key={hl.id}>
                                    <td>{index + 1}</td>
                                    <td>{hl.name}</td>
                                    <td>{hl.seatingCapacity}</td>
                                    <td>
                                        <button className="btn btn-sm btn-primary" style={{ marginRight: '10px' }} onClick={() => editHandler(hl)}>Edit</button>
                                        <button className="btn btn-sm btn-danger" onClick={() => deleteHandler(hl.id ? hl.id : 0)}>Delete</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </CollapseCard>
            {/* Deletion Confirmation Modal */}
            <Modal show={showDeleteConfirmation} onHide={closeDeleteConfirmation}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this item?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeDeleteConfirmation}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={confirmDeleteHandler}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </PageContainer>
    );
}

export default Hall;

