/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { GetState } from "../reducers"

export enum DisciplineActionList {
    ADD_DISCIPLINE = 'ADD_DISCIPLINE',
    FETCH_DISCIPLINE = 'FETCH_DISCIPLINE',
    UPDATE_DISCIPLINE = 'UPDATE_DISCIPLINE',
    DELETE_DISCIPLINE = 'DELETE_DISCIPLINE',
}

export interface DisciplineType {
    id?: number
    name: string
    shortName: string
}

export interface AddDisciplineAction {
    type: DisciplineActionList.ADD_DISCIPLINE
    data: DisciplineType
}

export interface FetchDisciplineAction {
    type: DisciplineActionList.FETCH_DISCIPLINE
    data: Array<DisciplineType>
}

export interface UpdateDisciplineAction {
    type: DisciplineActionList.UPDATE_DISCIPLINE
    data: DisciplineType
}

export interface DeleteDisciplineAction {
    type: DisciplineActionList.DELETE_DISCIPLINE
    data: number
}

export type DisciplineActions = AddDisciplineAction | FetchDisciplineAction | UpdateDisciplineAction | DeleteDisciplineAction

export const addDiscipline = (data: DisciplineType) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.post<APIResponse<DisciplineType>>('discipline/', data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<AddDisciplineAction>({
                    type: DisciplineActionList.ADD_DISCIPLINE,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Discipline Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })
    }
}

export const updateDiscipline = (data: DisciplineType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.put<APIResponse<DisciplineType>>(`discipline/?id=${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<UpdateDisciplineAction>({
                    type: DisciplineActionList.UPDATE_DISCIPLINE,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Discipline Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchDiscipline = () => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<DisciplineType[]>>('discipline/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchDisciplineAction>({
                    type: DisciplineActionList.FETCH_DISCIPLINE,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteDiscipline = (id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.delete<APIResponse<DisciplineType>>(`discipline/?id=${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteDisciplineAction>({
                    type: DisciplineActionList.DELETE_DISCIPLINE,
                    data: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Discipline Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}
