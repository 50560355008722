import axios from 'axios';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from '../components/custom/Card';
import CollapseCard from '../components/custom/CollapseCard';
import PageContainer from '../components/custom/PageContainer';
import { apiUrl } from '../config';
import { CourseType } from '../redux/actions/courseActions';
import { DegreeType } from '../redux/actions/degreeAction';
import { DisciplineType } from '../redux/actions/disciplineAction';
import { DisciplineCourseType } from '../redux/actions/disciplineCourseAction';
import { ExamType } from '../redux/actions/examAction';
import { StoreState } from '../redux/reducers';

const RevaluationRegister = () => {
    const [registerList, setRegisterList] = useState<RevaluationRegisterType[]>([]);
    const [selectedRegNoList, setSelectedRegNoList] = useState([]);

    const [editFlag, setEditFlag] = useState(false);
    const [examId, setExamId] = useState<number>(0);
    const [degreeId, setDegreeId] = useState<number>(0);
    const [disciplineId, setDisciplineId] = useState<number>(0);
    const [batch, setBatch] = useState<number>(0);
    const [currentSemester, setCurrentSemester] = useState<number>(0);
    const [registerNo, setRegisterNo] = useState('');
    const [disciplineCourseId, setDisciplineCourseId] = useState<number>(0);
    const [id, setId] = useState<number>(0);
    const [createdBy, setCreatedBy] = useState<string>('');
    const [createTimestamp, setCreateTimestamp] = useState<string>('');
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [examIdError, setExamIdError] = useState('');
    const [degreeIdError, setDegreeIdError] = useState('');
    const [disciplineIdError, setDisciplineIdError] = useState('');
    const [batchError, setBatchError] = useState('');
    const [currentSemesterError, setCurrentSemesterError] = useState('');
    const [registerNoError, setRegisterNoError] = useState('');
    const [disciplineCourseIdError, setDisciplineCourseIdError] = useState('');
    const [apiErrorMessage, setApiErrorMessage] = useState('');

    const [last10Years, setLast10Years] = useState<number[]>([]);
    const disciplineCourseList = useSelector<StoreState, DisciplineCourseType[]>(state => state.disciplineCourse)
    const examList = useSelector<StoreState, ExamType[]>(state => state.exam)
    const courseList = useSelector<StoreState, CourseType[]>(state => state.course)
    const degreeList = useSelector<StoreState, DegreeType[]>(state => state.degree)
    const disciplineList = useSelector<StoreState, DisciplineType[]>(state => state.discipline)

    //filter View 
    const [studentListFilter, setFilteredStudentList] = useState<RevaluationRegisterType[]>([]);
    const [examFilter, setExamFilter] = useState<string>('')
    const [degreeFilter, setDegreeFilter] = useState<string>('')
    const [disciplineFilter, setDisciplineFilter] = useState<string>('')
    const [courseFilter, setCourseFilter] = useState<string>('')
    const [semesterFilter, setSemesterFilter] = useState<string>('')
    const [batchFilter, setBatchFilter] = useState<string>('')


    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const years = Array.from({ length: 10 }, (_, index) => currentYear - index);
        setLast10Years(years);
    }, []);

    useEffect(() => {
        const filterStudent = () => {
            if (degreeId !== 0 && disciplineId !== 0 && batch !== 0) {
                // if (degreeId !== 0 && disciplineId !== 0 && batch !== 0 && currentSemester !== 0) {
                axios
                    .get(`${apiUrl}/student/?degreeId=${degreeId}&disciplineId=${disciplineId}&batch=${batch}`)
                    // .get(`${apiUrl}/student/?degreeId=${degreeId}&disciplineId=${disciplineId}&batch=${batch}&semester=${currentSemester}`)
                    .then((response) => {
                        setSelectedRegNoList(response.data.data);
                        console.log(response.data.data);
                    })
                    .catch((error) => console.log(error));
            }
        };

        filterStudent();
    }, [degreeId, disciplineId, batch]);


    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        axios.get<{ data: RevaluationRegisterType[] }>(`${apiUrl}/revalutionRegister/`)
            .then((response) => {
                setRegisterList(response.data.data);
            })
            .catch((error) => console.log(error));
    }

    const getDisciplineCourseId = (selectedCourseId: string) => {
        return disciplineCourseList.find(f => f.id === parseInt(selectedCourseId))?.courseId;
    };

    useEffect(() => {
        const filteredStudents = registerList.filter(s => {

            return (
                (examFilter === '' || s.examId?.toString() === examFilter) &&
                (degreeFilter === '' || s.degreeId?.toString() === degreeFilter) &&
                (disciplineFilter === '' || s.disciplineId?.toString() === disciplineFilter) &&
                (courseFilter === '' || getDisciplineCourseId(s.disciplineCourseId?.toString()) === parseInt(courseFilter)) &&
                (semesterFilter === '' || s.currentSemester?.toString() === semesterFilter) &&
                (batchFilter === '' || s.batch?.toString() === batchFilter)
            );
        });

        setFilteredStudentList(filteredStudents);
    }, [registerList, disciplineCourseList, examFilter, degreeFilter, disciplineFilter, courseFilter, semesterFilter, batchFilter]);


    const addHandler = () => {
        if (
            examId === 0 ||
            degreeId === 0 ||
            disciplineId === 0 ||
            batch === 0 ||
            currentSemester === 0 ||
            registerNo.length === 0
        ) {
            if (examId === 0) {
                setExamIdError('examId is required');
            }
            if (degreeId === 0) {
                setDegreeIdError('degreeId is required');
            }
            if (disciplineId === 0) {
                setDisciplineIdError('disciplineId is required');
            }
            if (batch === 0) {
                setBatchError('batch is required');
            }
            if (currentSemester === 0) {
                setCurrentSemesterError('currentSemester is required');
            }
            if (registerNo.length === 0) {
                setRegisterNoError('registerNo is required');
            }
            return;
        }

        let disCourseId = disciplineCourseList.find(f => f.degreeId === degreeId && f.disciplineId === disciplineId && f.courseId === disciplineCourseId)?.id

        const data = { id, examId, degreeId, disciplineId, batch, currentSemester, registerNo, disCourseId };

        axios.post(`${apiUrl}/revalutionRegister/`, data)
            .then((response) => {
                if (response.data.error) {
                    toast.error(response.data.message, { position: 'top-right' });
                    setApiErrorMessage(response.data.message);
                } else {
                    toast.success(response.data.message, { position: 'top-right' });
                    setExamIdError('');
                    setDegreeIdError('');
                    setDisciplineIdError('');
                    setBatchError('');
                    setCurrentSemesterError('');
                    setRegisterNoError('');
                    setDisciplineCourseIdError('');
                    setApiErrorMessage('');
                    fetchData();
                    clearHandler();
                }
            })
            .catch((error) => toast.error(error.response.data.message));
    }


    const deleteHandler = (id: number) => {
        setId(id);
        openDeleteConfirmation(); // Open the delete confirmation modal
    }

    const confirmDeleteHandler = () => {
        axios
            .delete(`${apiUrl}/revalutionRegister/?id=${id}`)
            .then((response) => {
                toast.success(response.data.message, { position: 'top-right' });
                fetchData();
                closeDeleteConfirmation();
            })
            .catch((error) => {
                toast.error(error.response.data.message)
                closeDeleteConfirmation();
            });
    }

    const editHandler = (id: number, examId: number, degreeId: number, disciplineId: number, batch: number, currentSemester: number, registerNo: string, disciplineCourseId: number, createdBy: string, createTimestamp: string) => {
        let disCourseId = disciplineCourseList.find(f => f.degreeId === degreeId && f.disciplineId === disciplineId && f.id === disciplineCourseId)?.courseId;
        setEditFlag(true);
        setId(id);
        setExamId(examId);
        setDegreeId(degreeId);
        setDisciplineId(disciplineId);
        setBatch(batch);
        setCurrentSemester(currentSemester);
        setRegisterNo(registerNo);
        setDisciplineCourseId(disCourseId === undefined ? 0 : disCourseId!);
        setCreatedBy(createdBy);
        setCreateTimestamp(createTimestamp);
    }

    const updateHandler = () => {
        if (
            examId === 0 ||
            degreeId === 0 ||
            disciplineId === 0 ||
            batch === 0 ||
            currentSemester === 0 ||
            registerNo.length === 0
        ) {
            if (examId === 0) {
                setExamIdError('examId is required');
            }
            if (degreeId === 0) {
                setDegreeIdError('degreeId is required');
            }
            if (disciplineId === 0) {
                setDisciplineIdError('disciplineId is required');
            }
            if (batch === 0) {
                setBatchError('batch is required');
            }
            if (currentSemester === 0) {
                setCurrentSemesterError('currentSemester is required');
            }
            if (registerNo.length === 0) {
                setRegisterNoError('registerNo is required');
            }
            return;
        }

        let disCourseId = disciplineCourseList.find(f => f.degreeId === degreeId && f.disciplineId === disciplineId && f.courseId === disciplineCourseId)?.id

        const data = { id, examId, degreeId, disciplineId, batch, currentSemester, registerNo, disCourseId, createdBy, createTimestamp };
        axios.put(`${apiUrl}/revalutionRegister/?id=${id}`, data)
            .then((response) => {
                if (response.data.error) {
                    toast.error(response.data.message, { position: 'top-right' });
                    setApiErrorMessage(response.data.message);
                } else {
                    console.log(response.data);
                    toast.success(response.data.message, { position: 'top-right' });
                    setExamIdError('');
                    setDegreeIdError('');
                    setDisciplineIdError('');
                    setBatchError('');
                    setCurrentSemesterError('');
                    setRegisterNoError('');
                    setDisciplineCourseIdError('');
                    setApiErrorMessage('');
                    fetchData();
                    setEditFlag(false);
                    clearHandler();
                }
            })
            .catch((error) => toast.error(error.response.data.message));
    }

    const clearHandler = () => {
        setExamId(0);
        setDegreeId(0);
        setDisciplineId(0);
        setBatch(0);
        setCurrentSemester(0);
        setRegisterNo('');
        setDisciplineCourseId(0);
        setExamIdError('');
        setDegreeIdError('');
        setDisciplineIdError('');
        setBatchError('');
        setCurrentSemesterError('');
        setRegisterNoError('');
        setDisciplineCourseIdError('');
        setId(0);
        setCreatedBy('');
        setCreateTimestamp('');
        setEditFlag(false);
    }

    const openDeleteConfirmation = () => {
        setShowDeleteConfirmation(true);
    }

    const closeDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    }

    const getExamName = (examId: number) => {
        const exam = examList.find((ex) => ex.id === examId);
        if (exam) {
            return exam.title;
        }
        return 'N/A';
    }

    const getDegreeName = (degreeId: number) => {
        const degree = degreeList.find((deg) => deg.id === degreeId);
        if (degree) {
            return degree.name;
        }
        return 'N/A';
    }

    const getDisciplineName = (disciplineId: number) => {
        const discipline = disciplineList.find((dis) => dis.id === disciplineId);
        if (discipline) {
            return discipline.name;
        }
        return 'N/A';
    }

    const getDisciplineCourse = (disciplineCourseId: number) => {
        const CourseId = disciplineCourseList?.find((dc) => dc?.id?.toString() === disciplineCourseId?.toString())?.courseId

        const courseObj = courseList?.find((dc) => dc.id?.toString() === CourseId?.toString())

        if (courseObj) {
            return courseObj?.name ? courseObj?.name : '';
        }
        return 'N/A';
    }



    return (
        <PageContainer title="Revaluation Register">
            <Card title={editFlag ? "Edit Register" : "Add Register"}>
                <div className="container m-0">
                    <div className="row">
                        <div className="col-4">
                            <label htmlFor="" className="mt-3 mb-3">Exam <span className="text-danger">*</span> : </label>
                            <select
                                name=""
                                id=""
                                className="form-control"
                                value={examId.toString()}
                                onChange={(e) => {
                                    setExamId(Number(e.target.value))
                                    setExamIdError('')
                                }}
                            >
                                <option value="" selected>Select Exam</option>
                                {examList?.map((ex) => (
                                    <option key={ex.id} value={ex.id}>
                                        {ex.title}
                                    </option>
                                ))}
                            </select>
                            {examIdError && <div className="text-danger">{examIdError}</div>}
                        </div>

                        <div className="col-4">
                            <label htmlFor="" className="mt-3 mb-3">Degree : </label>
                            <select name="" id="" className="form-control" value={degreeId.toString()} onChange={(e) => {
                                setDegreeId(Number(e.target.value))
                                setDegreeIdError('')
                            }}>
                                <option value="" selected>Select Degree</option>
                                {degreeList?.map((deg) => (
                                    <option key={deg.id} value={deg.id}>
                                        {deg.name}
                                    </option>
                                ))}
                            </select>
                            {degreeIdError && <div className="text-danger">{degreeIdError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Discipline : </label>
                            <select name="" id="" className="form-control" value={disciplineId.toString()} onChange={(e) => {
                                setDisciplineId(Number(e.target.value))
                                setDisciplineIdError('')
                            }}>
                                <option value="" selected>Select Discipline</option>
                                {disciplineList?.map((disc) => (
                                    <option key={disc.id} value={disc.id}>
                                        {disc.name}
                                    </option>
                                ))}
                            </select>
                            {disciplineIdError && <div className="text-danger">{disciplineIdError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Course <span className="text-danger">*</span> : </label>
                            <select name="" id="" className="form-control" value={disciplineCourseId.toString()} onChange={(e) => {
                                setDisciplineCourseId(Number(e.target.value))
                                setDisciplineCourseIdError('')
                            }}>
                                <option value="" selected>Select Course</option>
                                {courseList?.map((course) => (
                                    <option key={course.id} value={course.id}>
                                        {course.name}
                                    </option>
                                ))}
                            </select>
                            {disciplineCourseIdError && <div className="text-danger">{disciplineCourseIdError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Batch <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                style={{ width: "100%", marginRight: "20px" }}
                                name="selectedBatch"
                                value={batch.toString()}
                                onChange={(e) => setBatch(Number(e.target.value))}
                            >
                                <option value="" selected>Select Batch</option>
                                {last10Years.map((year) => (
                                    <option key={year} value={year}>
                                        {year}
                                    </option>
                                ))}
                            </select>
                            {batchError && <div className="text-danger">{batchError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Semester <span className="text-danger">*</span> : </label>
                            <select name="" id="" className="form-control"
                                value={currentSemester.toString()}
                                onChange={(e) => {
                                    setCurrentSemester(Number(e.target.value))
                                    setCurrentSemesterError('')
                                }}>
                                <option value="" selected>Select Semester</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                            </select>
                            {currentSemesterError && <div className="text-danger">{currentSemesterError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Register No <span className="text-danger">*</span> : </label>
                            <select
                                name=""
                                id=""
                                className="form-control"
                                value={registerNo}
                                onChange={(e) => {
                                    setRegisterNo(e.target.value);
                                    setRegisterNoError('');
                                }}
                            >
                                <option value="" selected>Select Register No</option>
                                {selectedRegNoList.map((registerNo) => (
                                    <option key={registerNo} value={registerNo}>
                                        {registerNo}
                                    </option>
                                ))}
                            </select>
                            {registerNoError && <div className="text-danger">{registerNoError}</div>}
                        </div>

                    </div>
                </div>
                <div className="m-4 d-flex justify-content-end">
                    <button className="btn btn-sm btn-secondary" onClick={clearHandler} style={{ marginRight: '10px' }}>
                        Clear
                    </button>
                    {editFlag ? <button className="btn btn-sm btn-success" onClick={updateHandler}>Update</button> :
                        <button className="btn btn-sm btn-success" onClick={addHandler}>Add</button>}
                </div>
            </Card>
            <CollapseCard title="Revaluation Register">
                {/* <div className='container'> */}
                <div className="row m-2 mb-3">
                    <div className="col-4">
                        <label htmlFor="" className="mt-2 mb-2">Exam : </label>
                        <select name="" id="" className="form-control" value={examFilter} onChange={(e) => {
                            setExamFilter(e.target.value)
                        }}>
                            <option value="" selected>Select Exam</option>
                            {examList?.map((ex) => (
                                <option key={ex.id} value={ex.id}>
                                    {ex.title}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col-4" >
                        <label htmlFor="" className="mt-2 mb-2">Degree : </label>
                        <select name="" id="" className="form-control" value={degreeFilter} onChange={(e) => {
                            setDegreeFilter(e.target.value)
                        }}>
                            <option value="" selected>Select Degree</option>
                            {degreeList?.map((deg) => (
                                <option key={deg.id} value={deg.id}>
                                    {deg.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col-4">
                        <label className="mt-2 mb-2">Discipline : </label>
                        <select name="" id="" className="form-control" value={disciplineFilter} onChange={(e) => {
                            setDisciplineFilter(e.target.value)
                        }}>
                            <option value="" selected>Select Discipline</option>
                            {disciplineList?.map((disc) => (
                                <option key={disc.id} value={disc.id}>
                                    {disc.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-4">
                        <label className="mt-2 mb-2">Course  </label>
                        <select name="" id="" className="form-control" value={courseFilter} onChange={(e) => {
                            setCourseFilter(e.target.value)
                        }}>
                            <option value="" selected>Select Course</option>
                            {courseList?.map((course) => (
                                <option key={course.id} value={course.id}>
                                    {course.name}
                                </option>
                            ))}
                        </select>
                    </div>


                    <div className="col-4">
                        <label className="mt-2 mb-2">Semester : </label>
                        <select name="" id="" className="form-control"
                            value={semesterFilter}
                            onChange={(e) => {
                                setSemesterFilter(e.target.value)
                            }}>
                            <option value="" selected>Select Semester</option>
                            <option value="1" selected>1</option>
                            <option value="2" selected>2</option>
                            <option value="3" selected>3</option>
                            <option value="4" selected>4</option>
                            <option value="5" selected>5</option>
                            <option value="6" selected>6</option>
                            <option value="7" selected>7</option>
                            <option value="8" selected>8</option>
                        </select>
                    </div>
                    <div className="col-4">
                        <label className="mt-2 mb-2">Batch  : </label>
                        <select
                            className="form-control"
                            // style={{ width: "100%", marginRight: "20px" }}
                            name="batchFilter"
                            value={batchFilter}
                            onChange={(e) => setBatchFilter(e.target.value)}
                        >
                            <option value="" selected>Select Batch</option>
                            {last10Years?.map((year) => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <table className="table table-primary table-striped" style={{ width: "100%", overflow: "auto" }}>
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Exam</th>
                            <th>Degree</th>
                            <th>Discipline</th>
                            <th>Batch</th>
                            <th>Semester</th>
                            <th>Register No</th>
                            <th>Discipline Course</th>
                            <th>Options</th>
                        </tr>
                    </thead>
                    <tbody>
                        {studentListFilter.map((register, index) => (
                            <tr key={register.id}>
                                <td>{index + 1}</td>
                                <td>{getExamName(register.examId)}</td>
                                <td>{getDegreeName(register.degreeId)}</td>
                                <td>{getDisciplineName(register.disciplineId)}</td>
                                <td>{register.batch}</td>
                                <td>{register.currentSemester}</td>
                                <td>{register.registerNo}</td>
                                <td>{getDisciplineCourse(register.disciplineCourseId)}</td>
                                <td>
                                    <button className="btn btn-sm btn-primary" style={{ marginRight: '10px' }} onClick={() => editHandler((register?.id !== undefined ? register?.id : 0), register.examId, register.degreeId, register.disciplineId, register.batch, register.currentSemester, register.registerNo, register.disciplineCourseId, register?.createdBy!, register?.createTimestamp!)}>Edit</button>
                                    <button className="btn btn-sm btn-danger" onClick={() => deleteHandler((register?.id !== undefined ? register?.id : 0))}>Delete</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </CollapseCard>
            <Modal show={showDeleteConfirmation} onHide={closeDeleteConfirmation}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this item?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeDeleteConfirmation}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={confirmDeleteHandler}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Add the ToastContainer for displaying toasts */}
            <ToastContainer position="top-right" />
        </PageContainer>

    );
}

export default RevaluationRegister;

interface RevaluationRegisterType {
    id: number;
    examId: number;
    degreeId: number;
    disciplineId: number;
    batch: number;
    currentSemester: number;
    registerNo: string;
    disciplineCourseId: number;
    createdBy: string;
    createTimestamp: string;
}

