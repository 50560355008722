
import { DegreeActionList, DegreeType } from "../actions/degreeAction";
import { Action } from "../actions/types";

const initialValue: Array<DegreeType> = [];

const degreeReducer = (state: Array<DegreeType> = initialValue, action: Action) => {
    switch (action.type) {
        case DegreeActionList.ADD_DEGREE:
            return [
                ...state,
                action.data,
            ];

        case DegreeActionList.UPDATE_DEGREE:
            const updated = [...state].slice();
            const index = updated.findIndex(u => u.id === action.data.id);

            if (index > -1) {
                updated[index] = {
                    ...action.data,
                };
            }

            return updated;

        case DegreeActionList.FETCH_DEGREE:
            return action.data;

        case DegreeActionList.DELETE_DEGREE:
            return state.filter(s => s.id !== action.data);

        default:
            return state;
    }
}

export default degreeReducer;



