export const dateFormat = (date: any) => {
        const today = new Date(date);
        const yyyy = today.getFullYear();
        let mm: number | string = today.getMonth() + 1; // Months start at 0!
        let dd: number | string = today.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;
        return dd + '/' + mm + '/' + yyyy;
}

export const LocalFormatDate = (date: string) => {
        const dateObj = new Date(date)
        const day = dateObj.getDate().toString().padStart(2, '0')
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0')
        const year = dateObj.getFullYear()

        return [day, month, year].join('-')//20-12-2023
}

export const ViewFormatDate = (date: string) => {
        console.log(date);

        const today = new Date(date);
        const yyyy = today.getFullYear();
        let mm: number | string = today.getMonth() + 1; // Months start at 0!
        let dd: number | string = today.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        return [yyyy, mm, dd].join('-')
}

export const onlyUnique = (array: any) => {
        return array.filter((arr: any, index: number) => array.indexOf(arr.batch) === arr.batch);
}


export const validateEmail = (email: any) => {
        return String(email)
                .toLowerCase()
                .match(
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
};




export const Email = new RegExp(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/)