import { CourseActionList, CourseType } from "../actions/courseActions"
import { Action } from "../actions/types"

const initialValue: Array<CourseType> = []

const courseReducer = (state: Array<CourseType> = initialValue, action: Action) => {
    switch (action.type) {

        case CourseActionList.ADD_COURSE:
            return [
                ...state,
                action.data,
            ]

        case CourseActionList.UPDATE_COURSE:
            const updated = [...state].slice()
            const index = updated.findIndex(u => u.id === action.data.id)

            if (index > -1) {
                updated[index] = {
                    ...action.data
                }
            }

            return updated

        case CourseActionList.FETCH_COURSE:
            return action.data

        case CourseActionList.DELETE_COURSE:
            return state.filter(s => s.id !== action.data)

        default:
            return state
    }
}

export default courseReducer