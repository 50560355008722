import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import ControlSideNavBar from './components/custom/ControlSideNavBar';
import Footer from './components/custom/Footer';
import SideNavBar from './components/custom/SideNavBar';
import TopNavBar from './components/custom/TopNavBar';
import AssessmentReport from './pages/AssessmentReport';
import AttendanceReport from './pages/AttendanceReport';
import AttendanceResultReport from './pages/AttendanceResultReport';
import AttendanceSheetReport from './pages/AttendanceSheetReport';
import College from './pages/College';
import Course from './pages/Course';
import CoverReport from './pages/CoverReport';
import CumulativeCoverReport from './pages/CumulativeCoverReport';
import CurrentSemesterUpdate from './pages/CurrentSemesterUpdate';
import Degree from './pages/Degree';
import DemoPage from './pages/DemoPage';
import Discipline from './pages/Discipline';
import DisciplineCourse from './pages/DisciplineCourse';
import DisciplineCourseReport from './pages/DisciplineCourseReport';
import DispatchReport from './pages/DispatchReport';
import { DropDownMaster } from './pages/DropDownMaster';
import DummyNoGeneration from './pages/DummyNoGeneration';
import DummyNoReport from './pages/DummyNoReport';
import Exam from './pages/Exam';
import ExamAttendance from './pages/ExamAttendance';
import ExamFaculty from './pages/ExamFaculty';
import ExamFacultyReport from './pages/ExamFacultyReport';
import ExamFeesReport from './pages/ExamFeesReport';
import ExamRegister from './pages/ExamRegister';
import ExamRegistrationReport from './pages/ExamRegistrationReport';
import ExamTimetable from './pages/ExamTimetable';
import { ExamTimetableReport } from './pages/ExamTimetableReport';
import Faculty from './pages/Faculty';
import FormPage from './pages/FormPage';
import Hall from './pages/Hall';
import HallPlan from './pages/HallPlan';
import HallticketReport from './pages/HallticketReport';
import Login from './pages/Login';
import Logout from './pages/Logout';
import MarkAnalysisReport from './pages/MarkAnalysisReport';
import MarkSheetReport from './pages/MarkSheetReport';
import NotFoundPage from './pages/NotFoundPage';
import { OverallSeatArrangement } from './pages/OverallSeatArrangement';
import Packing from './pages/Packing';
import PassingBoard from './pages/PassingBoard';
import PassingResultAnalysis from './pages/PassingResultAnalysisReport';
import Phase from './pages/Phase';
import PracticalExamination from './pages/PracticalExamination';
import PracticalExaminationReport from './pages/PracticalExaminationReport';
import PracticalMarkReport from './pages/PracticalMarkReport';
import CandidatesReport from './pages/RegisteredCandidatesReport';
import Regulation from './pages/Regulation';
import RevaluationMark from './pages/RevaluationMark';
import RevaluationRegister from './pages/RevaluationRegister';
import { SeatingArrangement } from './pages/SeatingArrangement';
import Student from './pages/Student';
import StuAssessment from './pages/StudentAssessment';
import StuAttendence from './pages/StudentAttendence';
import { StudentMark } from './pages/StudentMark';
import StudentMarkPracticle from './pages/StudentMarkPractical';
import Studentarrear from './pages/Studentarrear';
import TablePage from './pages/TablePage';
import UniversityGrades from './pages/UniversityGrades';
import User from './pages/User';
import { AuthUserType } from './redux/actions/authUserActions';
import { fetchCourse } from './redux/actions/courseActions';
import { fetchDegree } from './redux/actions/degreeAction';
import { fetchDiscipline } from './redux/actions/disciplineAction';
import { fetchDisciplineCourse } from './redux/actions/disciplineCourseAction';
import { fetchDropDown } from './redux/actions/dropDownAction';
import { fetchExam } from './redux/actions/examAction';
import { fetchExamAttendance } from './redux/actions/examAttendanceActions';
import { fetchExamFaculty } from './redux/actions/examFacultyAction';
import { fetchFaculty } from './redux/actions/facultyActions';
import { fetchPacking } from './redux/actions/packingActions';
import { fetchPassingBoard } from './redux/actions/passingBoardActions';
import { fetchPracticalExam } from './redux/actions/practicalExaminationAction';
import { fetchRegulation } from './redux/actions/regulationAction';
import { fetchStudentMarkAction } from './redux/actions/studentMarkAction';
import { fetchUser } from './redux/actions/userActions';
import { StoreState } from './redux/reducers';
import { AppDispatch } from './redux/store';

function App() {
    const dispatch = useDispatch<AppDispatch>()
    const authUser = useSelector<StoreState, AuthUserType>((state: any) => state.authUser)

    // const authendicated = true
    const authendicated = typeof authUser.token === 'string' && authUser.token !== undefined && authUser.token !== ''

    useEffect(() => {
        if (authendicated) {
            dispatch(fetchUser())
            dispatch(fetchPassingBoard())//
            dispatch(fetchPacking())//
            dispatch(fetchExamAttendance())//
            dispatch(fetchStudentMarkAction())//
            dispatch(fetchExam())
            dispatch(fetchDegree())
            dispatch(fetchDiscipline())
            dispatch(fetchCourse())
            dispatch(fetchDisciplineCourse())
            dispatch(fetchPracticalExam())//
            dispatch(fetchFaculty())
            dispatch(fetchExamFaculty())
            dispatch(fetchDropDown())
            dispatch(fetchRegulation())

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authendicated])
    return (

        < BrowserRouter >
            <div className="wrapper">

                {authendicated && <TopNavBar />}

                {authendicated && <SideNavBar />}

                <Routes>
                    {/* <Route path='/' element={<College />} /> */}
                    <Route path='/' element={authendicated ? <College /> : <Navigate to='/login' />} />
                    <Route path='/login' element={!authendicated ? <Login /> : <Navigate to='/' />} />
                    <Route path='/logout' element={authendicated ? <Logout /> : <Navigate to='/' />} />
                    <Route path='/user' element={authendicated ? <User /> : <Navigate to='/' />} />
                    <Route path='/table' element={authendicated ? <TablePage /> : <Navigate to='/' />} />
                    <Route path='/form' element={authendicated ? <FormPage /> : <Navigate to='/' />} />
                    <Route path='/college' element={authendicated ? <College /> : <Navigate to='/' />} />
                    {/* <Route path='/adminDashboard' element={authendicated ? <AdminDashboard /> : <Navigate to='/' />} /> */}
                    <Route path='/course' element={authendicated ? <Course /> : <Navigate to='/' />} />
                    <Route path='/degree' element={authendicated ? <Degree /> : <Navigate to='/' />} />
                    <Route path='/discipline' element={authendicated ? <Discipline /> : <Navigate to='/' />} />
                    <Route path='/regulation' element={authendicated ? <Regulation /> : <Navigate to='/' />} />
                    <Route path='/disciplinecourse' element={authendicated ? <DisciplineCourse /> : <Navigate to='/' />} />
                    <Route path='/exam' element={authendicated ? <Exam /> : <Navigate to='/' />} />
                    <Route path='/examregister' element={authendicated ? <ExamRegister /> : <Navigate to='/' />} />
                    <Route path='/examtimetable' element={authendicated ? <ExamTimetable /> : <Navigate to='/' />} />
                    <Route path='/hall' element={authendicated ? <Hall /> : <Navigate to='/' />} />
                    <Route path='/hallplan' element={authendicated ? <HallPlan /> : <Navigate to='/' />} />
                    <Route path='/student' element={authendicated ? <Student /> : <Navigate to='/' />} />
                    <Route path='/studentarrear' element={authendicated ? <Studentarrear /> : <Navigate to='/' />} />
                    <Route path='/assessment' element={authendicated ? <StuAssessment /> : <Navigate to='/' />} />
                    <Route path='/attendence' element={authendicated ? <StuAttendence /> : <Navigate to='/' />} />
                    <Route path='/phase' element={authendicated ? <Phase /> : <Navigate to='/' />} />
                    <Route path='/faculty' element={authendicated ? <Faculty /> : <Navigate to='/' />} />
                    <Route path='/passingboard' element={authendicated ? <PassingBoard /> : <Navigate to='/' />} />
                    <Route path='/packinggalley' element={authendicated ? <Packing /> : <Navigate to='/' />} />
                    <Route path='/examattendance' element={authendicated ? <ExamAttendance /> : <Navigate to='/' />} />
                    <Route path='/demo' element={authendicated ? <DemoPage title='demo page' /> : <Navigate to='/' />} />
                    <Route path='/coursereport' element={authendicated ? <DisciplineCourseReport title="DETAILS OF COURSES" /> : <Navigate to='/' />} />
                    <Route path='/feesreport' element={authendicated ? <ExamFeesReport title='DETAILS OF EXAM FEE' /> : <Navigate to='/' />} />
                    <Route path='/assessmentreport' element={authendicated ? <AssessmentReport title='DETAILS OF ASSESSMENT' /> : <Navigate to='/' />} />
                    <Route path='/attendancereport' element={authendicated ? <AttendanceReport title='DETAILS OF ATTENDANCE' /> : <Navigate to='/' />} />
                    <Route path='/registrationreport' element={authendicated ? <ExamRegistrationReport title='EXAM REGISTRATION FORM' /> : <Navigate to='/' />} />
                    <Route path='/hallticketreport' element={authendicated ? <HallticketReport title='HALL TICKET' /> : <Navigate to='/' />} />
                    <Route path='/attendancesheetreport' element={authendicated ? <AttendanceSheetReport title='EXAMINATION ATTENDANCE SHEET' /> : <Navigate to='/' />} />
                    <Route path='/candidatesreport' element={authendicated ? <CandidatesReport title='Details of No. of Candidates Registered (UG)' /> : <Navigate to='/' />} />
                    <Route path='/choicereport' element={authendicated ? <ExamTimetableReport title='CHOICE BASED CREDIT SYSTEM (CBCS)' /> : <Navigate to='/' />} />
                    <Route path='/overallseatarrangementreport' element={authendicated ? <OverallSeatArrangement title='Overall Seating Arrangement' /> : <Navigate to='/' />} />
                    <Route path='/seatingarrangementreport' element={authendicated ? <SeatingArrangement title='Seating Arrangement' /> : <Navigate to='/' />} />
                    <Route path='/coverreport' element={authendicated ? <CoverReport title="LIST OF ANSWER BOOK (AVAILABLE)" /> : <Navigate to='/' />} />
                    <Route path='/dispatchreport' element={authendicated ? <DispatchReport title="DISPATCH ANSWER PAPER COVERSf" /> : <Navigate to='/' />} />

                    <Route path='/dropdownMaster' element={authendicated ? <DropDownMaster /> : <Navigate to='/' />} />
                    <Route path='/studentmarkpracticle' element={authendicated ? <StudentMarkPracticle /> : <Navigate to='/' />} />
                    <Route path='/studentMark' element={authendicated ? <StudentMark /> : <Navigate to='/' />} />
                    <Route path='/universityGrade' element={authendicated ? <UniversityGrades /> : <Navigate to='/' />} />
                    <Route path='/passingResultAnalysis' element={authendicated ? <PassingResultAnalysis title='PASSING RESULT ANALYSIS' /> : <Navigate to='/' />} />
                    <Route path='/dummynogeneration' element={authendicated ? <DummyNoGeneration /> : <Navigate to='/' />} />

                    <Route path='/attendanceResultReport' element={authendicated ? <AttendanceResultReport title='ATTENDANCE RESULT REPORT' /> : <Navigate to='/' />} />
                    <Route path='/practicalexaminationreport' element={authendicated ? <PracticalExaminationReport title='ATTENDANCE RESULT REPORT' /> : <Navigate to='/' />} />

                    <Route path='/dummynoreport' element={authendicated ? <DummyNoReport /> : <Navigate to='/' />} />

                    <Route path='/examFaculty' element={authendicated ? <ExamFaculty /> : <Navigate to='/' />} />
                    <Route path='/practicalExamination' element={authendicated ? <PracticalExamination /> : <Navigate to='/' />} />
                    <Route path='/examfacultyreport' element={authendicated ? <ExamFacultyReport title="Exam Faculty Report" /> : <Navigate to='/' />} />
                    <Route path='/markReport' element={authendicated ? <MarkAnalysisReport /> : <Navigate to='/' />} />
                    <Route path='/practicalMarkReport' element={authendicated ? <PracticalMarkReport /> : <Navigate to='/' />} />
                    <Route path='/revaluationapply' element={authendicated ? <RevaluationRegister /> : <Navigate to='/' />} />
                    <Route path='/revaluationmark' element={authendicated ? <RevaluationMark /> : <Navigate to='/' />} />
                    <Route path='/marksheetreport' element={authendicated ? <MarkSheetReport /> : <Navigate to='/' />} />
                    <Route path='/updateCurrentSemester' element={authendicated ? <CurrentSemesterUpdate /> : <Navigate to='/' />} />
                    <Route path='/cumulativecoverreport' element={authendicated ? <CumulativeCoverReport /> : <Navigate to='/' />} />


                    <Route path='*' element={<NotFoundPage />} />
                </Routes>

                <ToastContainer />

                {authendicated && <Footer />}

                {authendicated && <ControlSideNavBar />}
            </div>
        </BrowserRouter >

    );
}

export default App;
