import axios from "axios"
import jsPDF from "jspdf"
import autoTable from "jspdf-autotable"
import { useState } from "react"
import { Card } from "react-bootstrap"
import { CSVLink } from "react-csv"
import * as Feather from 'react-feather'
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import Option from "../components/Option"
import CollapseCard from "../components/custom/CollapseCard"
import FormInput from "../components/custom/FormInput"
import PageContainer from "../components/custom/PageContainer"
import { apiUrl } from "../config"
import { LocalFormatDate, YearOptions } from "../config/functions"
import { CourseType } from "../redux/actions/courseActions"
import { DegreeType } from "../redux/actions/degreeAction"
import { DisciplineType } from "../redux/actions/disciplineAction"
import { DisciplineCourseType } from "../redux/actions/disciplineCourseAction"
import { ExamType } from "../redux/actions/examAction"
import { StoreState } from "../redux/reducers"
import imgPath from './img/collegeLogo.png'

interface DummyReportType {
    name: string
    regNo: string
    deptRegNo: string
    dummyRegNo: number
}

const DummyNoReport = () => {

    const examList = useSelector<StoreState, ExamType[]>(state => state.exam)
    const courseList = useSelector<StoreState, CourseType[]>(state => state.course)
    const degreeList = useSelector<StoreState, DegreeType[]>(state => state.degree)
    const disciplineList = useSelector<StoreState, DisciplineType[]>(state => state.discipline)
    const disciplineCourseList = useSelector<StoreState, DisciplineCourseType[]>(state => state.disciplineCourse)

    const [exam, setExam] = useState<string>('')
    const [examError, setExamError] = useState<string>('')
    const [degree, setDegree] = useState<string>('')
    const [degreeError, setDegreeError] = useState<string>('')
    const [discipline, setDiscipline] = useState<string>('')
    const [disciplineError, setDisciplineError] = useState<string>('')
    const [disciplineCourse, setDisciplineCourse] = useState<string>('')
    const [disciplineCourseError, setDisciplineCourseError] = useState<string>('')
    const [batch, setBatch] = useState<string>('')
    const [batchError, setBatchError] = useState<string>('')
    const [sem, setSem] = useState<string>('')
    const [semError, setSemError] = useState<string>('')
    const [session, setSession] = useState<string>('')
    const [sessionError, setSessionError] = useState<string>('')
    const [date, setDate] = useState<string>('')
    const [dateError, setDateError] = useState<string>('')
    const yearList = YearOptions();

    const [dummyReportData, setDummyReportData] = useState<DummyReportType[]>([])

    const handleSearch = () => {
        if (exam !== '' && degree !== '' && discipline !== '' && batch !== '' && sem !== '' && disciplineCourse !== '') {

            let disciplineCourseId = disciplineCourseList.find(f => f.degreeId?.toString() === degree && f.disciplineId?.toString() === discipline && f.courseId?.toString() === disciplineCourse)?.id

            axios.get<{ data: DummyReportType[] }>(`${apiUrl}/studentMark/dummyReport/?examId=${exam}&degreeId=${degree}&disciplineId=${discipline}&batch=${batch}&semester=${sem}&disciplineCourseId=${disciplineCourseId}`)
                .then((response) => {
                    const result = response.data.data
                    if (result.length > 0) {
                        setDummyReportData(result)
                    } else {
                        setDummyReportData([])
                    }
                })
                .catch((error) => {
                    toast.error(error);
                });
        } else {
            toast.error('Select All Filters', { position: 'top-right', autoClose: 3000 });

            setDummyReportData([])
        }
    }

    const handleDownloadPDF = () => {
        const doc = new jsPDF('p', 'mm', 'a4');
        let examName = examList.find(f => f.id?.toString() === exam)?.title
        let degreeName = degreeList.find(d => d.id?.toString() === degree)?.name
        let disciplineName = disciplineList.find(d => d.id?.toString() === discipline)?.name
        let courseName = courseList.find(d => d.id?.toString() === disciplineCourse)?.name
        let courseCode = courseList.find(d => d.id?.toString() === disciplineCourse)?.shortName

        doc.addImage(imgPath, 'PNG', 20, 10, 170, 40);
        doc.setFontSize(13);
        doc.setFont('Arial', 'normal', 'bold')
        doc.text('DUMMY NUMBER REPORT', 75, 50)
        doc.text(`${examName}`, 75, 57);

        doc.setFontSize(10);
        doc.text(`Degree & Discipline : ${degreeName} - ${disciplineName}`, 15, 67)
        doc.text(`Course : ${courseName}`, 15, 75)
        doc.text(`Course Code : ${courseCode}`, 155, 75)
        doc.text(`Batch : ${batch}`, 15, 83)
        doc.text(`Sem : ${sem}`, 155, 83)
        doc.text(`Session : ${session}`, 15, 91)
        doc.text(`Date : ${LocalFormatDate(date)}`, 155, 91)

        //1
        autoTable(doc, {
            startY: 98,
            headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0] },
            bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0], halign: 'center' },
            head: [
                [
                    { content: "S.No.", styles: { halign: 'center' } },
                    { content: "Name", styles: { halign: 'center' } },
                    { content: "Reg.No.", styles: { halign: 'center' } },
                    { content: "Dept.Reg.No.", styles: { halign: 'center' } },
                    { content: "Dummy Reg.No.", styles: { halign: 'center' } }
                ]

            ],
            body: dummyReportData?.map((d, i) => {
                return [(i + 1)?.toString(), d.name, d.regNo, d.deptRegNo, d.dummyRegNo?.toString()]
            }),
            // tableLineWidth: 0.5,
            theme: 'grid',
            tableLineColor: "black",
            // tableWidth: 45,
            margin: { left: 15 }
        })

        doc.save('dummy_number_report .pdf');

    }

    const csvData = [
        ['S.No.', 'Name', 'Reg.No', 'Dept.Reg.No', 'Dummy Reg.No.'],
    ]
    dummyReportData?.forEach((d, i) => {
        csvData.push(
            [(i + 1)?.toString(), d.name, d.regNo, d.deptRegNo, d.dummyRegNo?.toString()]
        )
    })

    return <>
        <PageContainer title='Dummy No. Report'>
            <Card>
                <div className="row m-2">
                    <div className='col-sm-6 col-md-4 col-lg-3'>
                        <FormInput
                            label='Exam'
                            name='Exam'
                            labelClassName="required"
                            type='select'
                            containerClass="mt-2"
                            value={exam}
                            onChange={(e) => {
                                setExam(e.target.value)
                                setExamError('')
                            }}
                            errorText={examError}
                        >
                            <Option value=''>Select</Option>
                            {examList?.map((e, i) => {
                                return <Option value={e.id} key={i}>{e.title}</Option>
                            })}
                        </FormInput>
                    </div>
                    <div className='col-sm-6 col-md-4 col-lg-3'>
                        <FormInput
                            label='Degree'
                            name='Degree'
                            labelClassName="required"
                            type='select'
                            containerClass="mt-2"
                            value={degree}
                            onChange={(e) => {
                                setDegree(e.target.value)
                                setDegreeError('')
                            }}
                            errorText={degreeError}
                        >
                            <Option value=''>Select</Option>
                            {degreeList?.map((e, i) => {
                                return <Option value={e.id} key={i}>{e.name}</Option>
                            })}
                        </FormInput>
                    </div>
                    <div className='col-sm-6 col-md-4 col-lg-3'>
                        <FormInput
                            label='Discipline'
                            name='Discipline'
                            labelClassName="required"
                            type='select'
                            containerClass="mt-2"
                            value={discipline}
                            onChange={(e) => {
                                setDiscipline(e.target.value)
                                setDisciplineError('')
                            }}
                            errorText={disciplineError}
                        >
                            <Option value=''>Select</Option>
                            {disciplineList?.map((e, i) => {
                                return <Option value={e.id} key={i}>{e.name}</Option>
                            })}
                        </FormInput>
                    </div>
                    <div className='col-sm-6 col-md-4 col-lg-3'>
                        <FormInput
                            label='Course'
                            name='Course'
                            labelClassName="required"
                            type='select'
                            containerClass="mt-2"
                            value={disciplineCourse}
                            onChange={(e) => {
                                setDisciplineCourse(e.target.value)
                                setDisciplineCourseError('')
                            }}
                            errorText={disciplineCourseError}
                        >
                            <Option value=''>Select</Option>
                            {courseList?.map((e, i) => {
                                return <Option value={e.id} key={i}>{e.name}</Option>
                            })}
                        </FormInput>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                        <FormInput
                            name="Batch"
                            label="Batch"
                            labelClassName="required"
                            value={batch}
                            onChange={(e) => {
                                setBatch(e.target.value)
                                setBatchError('')
                            }}
                            placeholder="Select Batch"
                            containerClass="mt-2"
                            type="select"
                            errorText={batchError}
                        >
                            <option value="">Select Batch</option>
                            {yearList.map((y, i) => {
                                return <Option key={i} value={y}>{y}</Option>
                            })
                            }
                        </FormInput>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                        <FormInput
                            name="Sem"
                            label="Sem"
                            labelClassName="required"
                            value={sem}
                            onChange={(e) => {
                                setSem(e.target.value)
                                setSemError('')
                            }}
                            placeholder="Select Sem"
                            containerClass="mt-2"
                            type="select"
                            errorText={semError}
                        >
                            <option value="">Select Semester</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                        </FormInput>

                    </div>
                    <div className='col-sm-6 col-md-4 col-lg-3'>
                        <FormInput
                            label='Date'
                            name='Date'
                            labelClassName="required"
                            type='date'
                            containerClass="mt-2"
                            value={date}
                            onChange={(e) => {
                                setDate(e.target.value)
                                setDateError('')
                            }}
                            errorText={dateError}

                        />
                    </div>
                    <div className='col-sm-6 col-md-4 col-lg-3'>
                        <FormInput
                            label='Session'
                            name='Session'
                            labelClassName="required"
                            type='select'
                            containerClass="mt-2"
                            value={session}
                            onChange={(e) => {
                                setSession(e.target.value)
                                setSessionError('')
                            }}
                            errorText={sessionError}
                        >
                            <Option value=''>Select</Option>
                            <Option value='AN'>AN</Option>
                            <Option value='FN'>FN</Option>
                        </FormInput>
                    </div>
                </div>
                {/* <div className="row align-items-end "> */}
                <div className="text-right m-4">
                    {/* <div className=" col-12 m-2"> */}
                    <button className='btn btn-sm btn-primary' onClick={handleSearch} >Search</button>
                </div>
                {/* </div> */}
            </Card>
            <CollapseCard title="">
                {/* <div className="container-fluid table-container"> */}
                <div>
                    <table className="table table-success table-striped" style={{ width: '100%' }}>
                        <thead>
                            <tr>
                                <th>S.No.</th>
                                <th>Name</th>
                                <th>Reg.No.</th>
                                <th>Dept.Reg.No.</th>
                                <th>Dummy Reg.No.</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dummyReportData?.map((row, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td>{row.name}</td>
                                        <td>{row.regNo}</td>
                                        <td>{row.deptRegNo}</td>
                                        <td>{row.dummyRegNo}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="row">
                    <div className="col-6">
                        {dummyReportData.length > 0 && <div className="text-end mb-3">
                            <button
                                type="button"
                                onClick={handleDownloadPDF}
                                className="m-1 btn btn-sm btn-primary"
                            >
                                <Feather.Download /> PDF
                            </button>
                            <CSVLink
                                data={csvData}
                                filename="details_of_course.csv"
                                className="m-1 btn btn-sm btn-primary"
                            >
                                <Feather.Download /> CSV
                            </CSVLink>
                        </div>
                        }
                    </div>
                </div>
            </CollapseCard>
        </PageContainer >
    </>
}
export default DummyNoReport