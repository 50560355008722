import { ChangeEventHandler, MouseEventHandler, useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import { FormType, FormTypeList, addKey, enumToArrayOfObj, isValidEmail } from "../config/functions"
import { Key } from "../redux/actions/types"
import { UserRole, UserRoleList, UserStatus, UserStatusList, UserType, addUser, updateUser } from "../redux/actions/userActions"
import { AppDispatch } from "../redux/store"
import Option, { OptionType } from "./Option"
import FormInput from "./custom/FormInput"
import { ToastTypes, useToast } from "./toast/ToastProvider"

interface Props {
    formType: FormType
    editData?: UserType
    onSave: () => void
}
// "userId": "super",
//"password": "super",
//"name": "VJ",
// "designation": "bus service",
// "role": "super",
// "status": "active"
const STATUS = addKey([
    {
        text: 'Active',
        value: 'Active'
    },
    {
        text: 'Inactive',
        value: 'Inactive'
    }
])

// const ROLES = addKey([
//     {
//         text: 'Super',
//         value: 'super'
//     },
//     {
//         text: 'Admin',
//         value: 'admin'
//     },
//     {
//         text: 'Manager',
//         value: 'manager'
//     }
// ])

const UserForm = ({ formType, onSave, editData }: Props) => {
    const toast = useToast()

    // const roleList = useSelector<StoreState, Array<RoleType & Key>>(state => addKey(state.role))

    const dispatch = useDispatch<AppDispatch>()
    const [userId, setUserId] = useState<string>('')
    const [userIdError, setUserIdError] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [passwordError, setPasswordError] = useState<string>('')
    const [name, setName] = useState<string>('')
    const [nameError, setNameError] = useState<string>('')
    const [designation, setDesignation] = useState<string>('')
    const [designationError, setDesignationError] = useState<string>('')
    const [role, setRole] = useState<UserRole>(UserRoleList.ADMIN)
    const [roleError, setRoleError] = useState<string>('')
    const [status, setStatus] = useState<UserStatus>(UserStatusList.ACTIVE)
    const [statusError, setStatusError] = useState<string>('')
    const [emailId, setEmailId] = useState<string>('')
    const [emailIdError, setEmailIdError] = useState<string>('')
    const [mobile, setMobile] = useState<string>('')
    const [mobileError, setMobileError] = useState<string>('')

    const roleList = useMemo<(OptionType & Key)[]>(() => {
        return addKey(enumToArrayOfObj(UserRoleList))
    }, [])

    const resetStates = () => {
        setUserId('')
        setUserIdError('')
        setPassword('')
        setPasswordError('')
        setName('')
        setNameError('')
        setEmailId('')
        setEmailIdError('')
        setMobile('')
        setMobileError('')
        setDesignation('')
        setDesignationError('')
        setRole(UserRoleList.ADMIN)
        setRoleError('')
        setStatus(UserStatusList.ACTIVE)
        setStatusError('')

    }

    const handleUserId: ChangeEventHandler<HTMLInputElement> = (e) => {
        setUserId(e.target.value)
        setUserIdError('')
    }

    const handlePassword: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPassword(e.target.value)
        setPasswordError('')
    }

    const handleName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setName(e.target.value)
        setNameError('')
    }

    const handleEmailId: ChangeEventHandler<HTMLInputElement> = (e) => {
        setEmailId(e.target.value)
        setEmailIdError('')
    }

    const handleMobile: ChangeEventHandler<HTMLInputElement> = (e) => {
        setMobile(e.target.value)
        setMobileError('')
    }

    const handleRole: ChangeEventHandler<HTMLInputElement> = (e) => {
        setRole(e.target.value as UserRole)
        setRoleError('')
    }

    const handleDesignation: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDesignation(e.target.value)
        setDesignationError('')
    }

    const handleStatus: ChangeEventHandler<HTMLInputElement> = (e) => {
        setStatus(e.target.value as UserStatus)
        setStatusError('')
    }

    const handleClear: MouseEventHandler<HTMLButtonElement> = (e) => {
        resetStates()
    }

    const handleSave = () => {
        let error = false
        const data: UserType = {
            userId: userId,
            password: password,
            name: name,
            emailId: emailId,
            mobileNumber: mobile,
            designation: designation,
            role: role,
            status: status
        }

        if (formType === FormTypeList.ADD) {
            if (data.userId === "") {
                setUserIdError('UserId required')
                error = true
            } else if (data.userId !== undefined && (data.userId).length > 15) {
                setUserIdError('UserId must be within 15 characters')
                error = true
            }

            if (data.password === "") {
                setPasswordError('Password required')
                error = true
            } else if (data.password !== undefined) {
                if ((data.password).length < 6 || (data.password).length > 10) {
                    setPasswordError("Password must be 6 to 10 characters long")
                    error = true
                }
            }
        }

        if (data.name === "") {
            setNameError('Name required')
            error = true
        } else if ((data.name).length > 30) {
            setNameError('Name must be within 30 characters')
            error = true
        }

        if (data.designation === "") {
            setDesignationError('Designation required')
            error = true
        } else if ((data.designation).length > 30) {
            setDesignationError('Designation must be within 30 characters')
            error = true
        }

        if (data.role === "") {
            setRoleError('Role required')
            error = true
        } else if ((data.role).length > 15) {
            setRoleError('Role must be within 15 characters')
            error = true
        }

        if (data.emailId !== undefined && data.emailId !== '' && !isValidEmail(data.emailId)) {
            setEmailIdError('Invalid Email ID')
            error = true
        }
        // if (data.role > 0) {
        //     setRoleError('Role required')
        //     error = true
        // }
        //  else if ([UserRoleList.ADMIN, UserRoleList.MANAGER, UserRoleList.SUPER].indexOf(data.role as UserRole) === -1) {
        //     setRoleError('Role must be ADMIN/MANAGER/SUPER')
        //     error = true
        // }
        // if (data.status === "") {
        //     setStatusError('Status required')
        //     error = true
        // } else 
        if ([UserStatusList.INACTIVE, UserStatusList.ACTIVE].indexOf(data.status as UserStatus) === -1) {
            setStatusError('Status must be ACTIVE/INACTIVE')
            error = true
        }

        if (!error) {
            if (formType === FormTypeList.ADD) {

                dispatch(addUser(data)).then(text => {
                    toast(text)
                    onSave()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            } else if (formType === FormTypeList.UPDATE && editData !== undefined && editData.userId !== undefined) {
                dispatch(updateUser(data, editData.userId)).then(text => {
                    toast(text)
                    onSave()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            }
        }
    }

    useEffect(() => {
        if (formType === FormTypeList.UPDATE && editData !== undefined && editData.userId !== undefined) {

            // setUserId(editData.userId)
            // setPassword(editData.password)
            setName(editData.name)
            setEmailId(editData.emailId !== undefined ? editData.emailId : "")
            setMobile(editData.mobileNumber !== undefined ? editData.mobileNumber : "")
            setDesignation(editData.designation)
            setRole(editData.role as UserRole)
            setStatus(editData.status)

        } else {
            resetStates()
        }
    }, [formType, editData])


    return <div className='container'>
        <div className="row">
            {formType === FormTypeList.ADD && <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='UserId'
                    name='UserId'
                    labelClassName="required"
                    value={userId}
                    onChange={handleUserId}
                    placeholder='UserId'
                    errorText={userIdError}
                    containerClass="mb-2"
                />
            </div>}

            {formType === FormTypeList.ADD && <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Password'
                    name='Password'
                    labelClassName="required"
                    value={password}
                    onChange={handlePassword}
                    placeholder='Password'
                    errorText={passwordError}
                    containerClass="mb-2"
                />
            </div>}
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Name'
                    name='Name'
                    labelClassName="required"
                    value={name}
                    onChange={handleName}
                    placeholder='Name'
                    errorText={nameError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Email'
                    name='Email'
                    value={emailId}
                    onChange={handleEmailId}
                    placeholder='Email'
                    errorText={emailIdError}
                    containerClass="mb-2"
                    type='email'
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Mobile'
                    name='Mobile'
                    value={mobile}
                    onChange={handleMobile}
                    placeholder='Mobile'
                    errorText={mobileError}
                    containerClass="mb-2"
                    type='number'
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Designation'
                    name='Designation'
                    labelClassName="required"
                    value={designation}
                    onChange={handleDesignation}
                    placeholder='Designation'
                    errorText={designationError}
                    containerClass="mb-2"
                />
            </div>
            {/* <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Role'                    
                    name='Role'
                    labelClassName="required"
                    value={role}
                    type='select'
                    onChange={handleRole}
                    errorText={roleError}
                    containerClass="mb-2"
                >
                    <Option value=''>Select</Option>
                    {ROLES.map(r => {
                        return <Option value={r.value} key={r.key}>{r.text}</Option>
                    })}
                </FormInput>
            </div> */}
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Status'
                    name='Status'
                    labelClassName="required"
                    type='select'
                    value={status}
                    onChange={handleStatus}
                    errorText={statusError}
                >
                    <Option value=''>Select</Option>
                    {STATUS.map(s => {
                        return <Option value={s.value} key={s.key}>{s.text}</Option>
                    })}
                </FormInput>
            </div>

        </div>
        <div className="d-flex justify-content-end hstack gap-2">
            <div>
                <button className="btn btn-sm btn-primary" onClick={handleClear}>Clear</button>
            </div>
            <div>
                <button className="btn btn-sm btn-success" onClick={handleSave}>Save</button>
            </div>
        </div>

    </div>
}
export default UserForm