import axios from "axios"
import jsPDF from "jspdf"
import autoTable from "jspdf-autotable"
import { useEffect, useState } from "react"
import { Button, Modal } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import Card from "../components/custom/Card"
import CollapseCard from "../components/custom/CollapseCard"
import PageContainer from "../components/custom/PageContainer"
import { apiUrl } from "../config"
import { CourseType } from "../redux/actions/courseActions"
import { DegreeType } from "../redux/actions/degreeAction"
import { DisciplineType } from "../redux/actions/disciplineAction"
import { DisciplineCourseType } from "../redux/actions/disciplineCourseAction"
import { ExamType } from "../redux/actions/examAction"
import { ExamFacultyType } from "../redux/actions/examFacultyAction"
import { FacultyType } from "../redux/actions/facultyActions"
import { StudentMarkType, deleteStudentMarkAction, editStudentMarkAction, fetchStudentMarkAction } from "../redux/actions/studentMarkAction"
import { StoreState } from "../redux/reducers"
import { AppDispatch } from "../redux/store"
import imgPath from './img/collegeLogo.png'
import { MarkSheetReportType } from "./MarkSheetReport"

export const inWords = (number: number) => {
    if (number === -1) return 'ABSENT';
    const strArr = (number)?.toString()?.split('');
    const a = ['One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Zero']
    let numberToWord = '';
    for (let i in strArr) {
        if (strArr[i] === '1')
            numberToWord = numberToWord + " " + a[0];
        if (strArr[i] === '2')
            numberToWord = numberToWord + " " + a[1];
        if (strArr[i] === '3')
            numberToWord = numberToWord + " " + a[2];
        if (strArr[i] === '4')
            numberToWord = numberToWord + " " + a[3];
        if (strArr[i] === '5')
            numberToWord = numberToWord + " " + a[4];
        if (strArr[i] === '6')
            numberToWord = numberToWord + " " + a[5];
        if (strArr[i] === '7')
            numberToWord = numberToWord + " " + a[6];
        if (strArr[i] === '8')
            numberToWord = numberToWord + " " + a[7];
        if (strArr[i] === '9')
            numberToWord = numberToWord + " " + a[8];
        if (strArr[i] === '0')
            numberToWord = numberToWord + " " + a[9];
    }
    return numberToWord;

}

export const calculateGrade = (totalConversion: number, extConvertedMark: number) => {

    if (extConvertedMark < 18) {//this check is only for practical
        return 0;
    }

    if (totalConversion >= 91 && totalConversion <= 100) {
        return 10;
    } else if (totalConversion >= 81 && totalConversion <= 90) {
        return 9;
    } else if (totalConversion >= 71 && totalConversion <= 80) {
        return 8;
    } else if (totalConversion >= 61 && totalConversion <= 70) {
        return 7;
    } else if (totalConversion >= 56 && totalConversion <= 60) {
        return 6;
    } else if (totalConversion >= 50 && totalConversion <= 55) {
        return 5;
    } else {
        return 0;
    }
};


export const calculateGradeText = (totalConversion: number, extConvertedMark: number) => {

    if (extConvertedMark < 18) {//this check is only for practical
        return 'U';
    }

    if (totalConversion >= 91 && totalConversion <= 100) {
        return 'O';
    } else if (totalConversion >= 81 && totalConversion <= 90) {
        return 'A+';
    } else if (totalConversion >= 71 && totalConversion <= 80) {
        return 'A';
    } else if (totalConversion >= 61 && totalConversion <= 70) {
        return 'B+';
    } else if (totalConversion >= 56 && totalConversion <= 60) {
        return 'B';
    } else if (totalConversion >= 50 && totalConversion <= 55) {
        return 'C';
    } else {
        return 'U';
    }
};

export const handleArrear = async (dataItem: any, semester: number) => {

    const studentArrearData = {
        disciplineId: dataItem.disciplineId,
        degreeId: dataItem.degreeId,
        registerNo: dataItem.registerNumber,
        semesterNo: semester,
        disciplineCourseId: dataItem.disciplineCourseId,
        status: 'Pending',
    };

    await axios.post(`${apiUrl}/studentarrear/`, studentArrearData).then((response) => {
        if (response.status === 200 && response.data.data) {
            toast.success(response.data.message);
        }
    }).catch((error) => {
        if (error!.response) {
            toast.error(error.response.data.message);
        }
    });

};

export const handleDeleteArrear = async (dataItem: any, semester: number, studentarrearList: StudentArrearType[]) => {

    console.log(dataItem)

    let deleteArrearIfTheStudentPass = studentarrearList.find(sa =>
        sa.disciplineId === dataItem.disciplineId &&
        sa.degreeId === dataItem.degreeId &&
        sa.disciplineCourseId === dataItem.disciplineCourseId &&
        sa.registerNo === dataItem.registerNumber &&
        sa.semesterNo === semester
    )?.id;

    console.log(deleteArrearIfTheStudentPass)
    await axios.delete(`${apiUrl}/studentarrear/?id=${deleteArrearIfTheStudentPass}`).then((response) => {
        if (response.status === 200 && response.data.data) {
            toast.success(response.data.message);
        }
    }).catch((error) => {
        if (error!.response) {
            toast.error(error.response.data.message);
        }
    });
};

export const handleUpdateArrear = async (dataItem: any, semester: number, studentarrearList: StudentArrearType[]) => {

    let UpdateArrearAfterAttendArrear = studentarrearList
        .filter(sa =>
            sa.disciplineId === parseInt(dataItem.disciplineId) &&
            sa.degreeId === parseInt(dataItem.degreeId) &&
            sa.disciplineCourseId === parseInt(dataItem.disciplineCourseId) &&
            sa.registerNo === dataItem.registerNumber &&
            sa.semesterNo !== semester
        )
        .map(sa => ({ id: sa.id, attemptCount: sa.attemptCount, status: sa.status }));

    if (UpdateArrearAfterAttendArrear.length > 0) {
        for (const arrear of UpdateArrearAfterAttendArrear) {
            const studentArrearData = {
                attemptCount: arrear.attemptCount + 1,
                clearedSemester: semester,
                status: 'Completed',
            };

            await axios.put(`${apiUrl}/studentarrear/?id=${arrear.id}`, studentArrearData).then((response) => {
                if (response.status === 200 && response.data.data) {
                    toast.success(response.data.message);
                }
            }).catch((error) => {
                if (error!.response) {
                    toast.error(error.response.data.message);
                }
            });
        }
    } else {
        console.log('No matching student arrears found.');
    }
};

export const handleReArrear = async (dataItem: any, semester: number, studentarrearList: StudentArrearType[]) => {
    console.log(dataItem)

    let UpdateArrearAfterAttendArrear = studentarrearList
        .filter(sa =>
            sa.disciplineId === parseInt(dataItem.disciplineId) &&
            sa.degreeId === parseInt(dataItem.degreeId) &&
            sa.disciplineCourseId === parseInt(dataItem.disciplineCourseId) &&
            sa.registerNo === dataItem.registerNumber &&
            sa.semesterNo !== semester
        )
        .map(sa => ({ id: sa.id, attemptCount: sa.attemptCount, status: sa.status }));


    if (UpdateArrearAfterAttendArrear.length > 0) {
        for (const arrear of UpdateArrearAfterAttendArrear) {
            const studentArrearData = {
                attemptCount: arrear.attemptCount + 1,
                clearedSemester: 0,
                status: 'Pending',
            };

            await axios.put(`${apiUrl}/studentarrear/?id=${arrear.id}`, studentArrearData).then((response) => {
                if (response.status === 200 && response.data.data) {
                    toast.success(response.data.message);
                }
            }).catch((error) => {
                if (error!.response) {
                    toast.error(error.response.data.message);
                }
            });
        }
    } else {
        console.log('No matching student arrears found.');
    }
};

export const handleReArrearEdit = async (dataItem: any, semester: number, studentarrearList: StudentArrearType[]) => {

    let UpdateArrearAfterAttendArrear = studentarrearList
        .filter(sa =>
            sa.disciplineId === parseInt(dataItem.disciplineId) &&
            sa.degreeId === parseInt(dataItem.degreeId) &&
            sa.disciplineCourseId === parseInt(dataItem.disciplineCourseId) &&
            sa.registerNo === dataItem.registerNumber &&
            sa.semesterNo !== semester
        )
        .map(sa => ({ id: sa.id, attemptCount: sa.attemptCount }));

    if (UpdateArrearAfterAttendArrear.length > 0) {
        for (const arrear of UpdateArrearAfterAttendArrear) {
            const studentArrearData = {
                attemptCount: arrear.attemptCount,
                clearedSemester: 0,
                status: 'Pending',
            };

            await axios.put(`${apiUrl}/studentarrear/?id=${arrear.id}`, studentArrearData).then((response) => {
                if (response.status === 200 && response.data.data) {
                    toast.success(response.data.message);
                }
            }).catch((error) => {
                if (error!.response) {
                    toast.error(error.response.data.message);
                }
            });
        }
    } else {
        console.log('No matching student arrears found.');
    }
};

export interface StudentGrade {
    registeNo: number
    batch: string
    sem: number
    cRegistered: string
    cEarned: string
    gpEarned: string
    gpAverage: string
}

const StudentMarkPractical = () => {

    const dispatch = useDispatch<AppDispatch>()

    const [dummyNoGenData, setDummyNoGenData] = useState<DummyDataType[]>([])
    const examList = useSelector<StoreState, ExamType[]>(state => state.exam)
    const degreeList = useSelector<StoreState, DegreeType[]>(state => state.degree)
    const disciplineList = useSelector<StoreState, DisciplineType[]>(state => state.discipline)

    const facultyList = useSelector<StoreState, FacultyType[]>(state => state.faculty);
    const studentMarkList = useSelector<StoreState, StudentMarkType[]>(state => state.studentMark)
    const examFacultyList = useSelector<StoreState, ExamFacultyType[]>(state => state.examFaculty);
    const disciplineCourseList = useSelector<StoreState, DisciplineCourseType[]>(state => state.disciplineCourse)
    const courseList = useSelector<StoreState, CourseType[]>(state => state.course)
    const [studentarrearList, setStudentarrearList] = useState<StudentArrearType[]>([]);

    // console.log(facultyList);
    // console.log(examFacultyList.filter(f => f.type=='internal'));

    const [editFlag, setEditFlag] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const [exam, setExam] = useState<string>('')
    const [examError, setExamError] = useState<string>('')

    const [degree, setDegree] = useState<string>('')
    const [degreeError, setDegreeError] = useState<string>('')

    const [discipline, setDiscipline] = useState<string>('')
    const [disciplineError, setDisciplineError] = useState<string>('')

    const [semester, setSemester] = useState<string>('')
    const [semesterError, setSemesterError] = useState<string>('')

    const [registeNo, setRegisterNo] = useState<string>('')
    const [registeNoError, setRegisterNoError] = useState<string>('')

    const [dummyNo, setDummyNo] = useState<string>('')
    const [dummyNoError, setDummyNoError] = useState<string>('')

    const [disciplineCourse, setDisciplineCourse] = useState<string>('')
    const [disciplineCourseError, setDisciplineCourseError] = useState<string>('')

    const [questionPaper, setQuestionPaper] = useState<string>('')
    const [questionPaperError, setQuestionPaperError] = useState<string>('')

    const [securedMark, setSecuredMark] = useState<string>('')
    const [securedMarkError, setSecuredMarkError] = useState<string>('')

    const [intConvertedMark, setIntConvertedMark] = useState<string>('')
    // const [convertedMarkError, setConvertedMarkError] = useState<string>('')

    const [convertedMark, setConvertedMark] = useState<string>('')
    const [convertedMarkError, setConvertedMarkError] = useState<string>('')

    const [adjuestedMark, setAdjustedMark] = useState<string>('')
    const [adjuestedMarkError, setAdjustedMarkError] = useState<string>('')

    const [intExaminer, setIntExaminer] = useState<string>('')
    const [intExaminerError, setIntExaminerError] = useState<string>('')

    const [extExaminer, setExtExaminer] = useState<string>('')
    const [extExaminerError, setExtExaminerError] = useState<string>('')

    const [id, setId] = useState<number>(0)
    const [updateId, setUpdateId] = useState<number>(0)
    const [convertion, setConvertion] = useState<DisciplineCourse | null>(null);

    const [selectDummyNoList, setSelectedDummyNoList] = useState([]);
    const [studentList, setStudentList] = useState<Student[]>([]);

    const [last10Years, setLast10Years] = useState<number[]>([]);
    const [editModalShow, setEditModalShow] = useState(false);

    //filter View 
    const [studentListFilter, setFilteredStudentList] = useState<StudentMarkType[]>([]);
    const [examFilter, setExamFilter] = useState<string>('')
    const [degreeFilter, setDegreeFilter] = useState<string>('')
    const [disciplineFilter, setDisciplineFilter] = useState<string>('')
    const [courseFilter, setCourseFilter] = useState<string>('')
    const [semesterFilter, setSemesterFilter] = useState<string>('')

    //array input update
    const [securedMarks, setSecuredMarks] = useState(Array(selectDummyNoList.length).fill(''));
    const [intExaminers, setIntExaminers] = useState(Array(selectDummyNoList.length).fill(''));
    const [extExaminers, setExtExaminers] = useState(Array(selectDummyNoList.length).fill(''));
    const [questionPapers, setQuestionPapers] = useState(Array(selectDummyNoList.length).fill(''));
    // const [questionPaperError, setQuestionPaperError] = useState('');

    //batch filter
    const [selectedBatch, setSelectedBatch] = useState<Student[]>([]);
    const [selectedBatchId, setSelectedBatchId] = useState('');
    const [batchIdError, setBatchIdError] = useState('');

    const [degreeArrear, setDegreeArrear] = useState<number>(0);
    const [disciplineArrear, setDisciplineArrear] = useState<number>(0);
    const [semesterArrear, setSemesterArrear] = useState<number>(0);
    const [registerNoArrear, setRegisterNoArrear] = useState<string>('');
    const [disciplineCourseArrear, setDisciplineCourseArrear] = useState<number>(0);

    const [marksheetData, setMarksheetData] = useState<MarkSheetReportType[]>([])

    useEffect(() => {
        dispatch(fetchStudentMarkAction())
    }, [])

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const years = Array.from({ length: 10 }, (_, index) => currentYear - index);
        setLast10Years(years);
    }, []);

    const getDisciplineCourseId = (selectedCourseId: string) => {
        console.log(selectedCourseId);
        return disciplineCourseList.find(f => f.id === parseInt(selectedCourseId))?.courseId;
    };

    useEffect(() => {
        axios.get(`${apiUrl}/studentarrear/`)
            .then((response) => {
                setStudentarrearList(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching student arrear', error);
            });
    }, [])

    useEffect(() => {
        // const filteredStudents = studentMarkList.filter((student) => {

        //     return (
        //         (examFilter === '' || student.examId?.toString() === examFilter) &&
        //         (degreeFilter === '' || student.degreeId?.toString() === degreeFilter) &&
        //         (disciplineFilter === '' || student.disciplineId?.toString() === disciplineFilter) &&
        //         (semesterFilter === '' || student.semester?.toString() === semesterFilter)
        //     );
        // })
        const filteredStudents = studentMarkList.filter(s => {
            //console.log(s.disciplineCourseId);
            // console.log(courseFilter);

            return s.examId?.toString() === (examFilter !== '' ? examFilter : s.examId?.toString()) &&
                s.degreeId?.toString() === (degreeFilter !== '' ? degreeFilter : s.degreeId?.toString()) &&
                s.disciplineId?.toString() === (disciplineFilter !== '' ? disciplineFilter : s.disciplineId?.toString()) &&
                // getDisciplineCourseId(s.disciplineCourseId?.toString()) === (courseFilter !== '' ? courseFilter : s.disciplineCourseId?.toString()) &&
                (courseFilter === '' || getDisciplineCourseId(s.disciplineCourseId?.toString()) === parseInt(courseFilter)) &&
                s.semester?.toString() === (semesterFilter !== '' ? semesterFilter : s.semester?.toString())
        })
        const checkFilteredStudents = filteredStudents

        setFilteredStudentList(checkFilteredStudents);
    }, [examFilter, degreeFilter, disciplineFilter, courseFilter, semesterFilter, studentMarkList]);


    const resetStates = () => {
        setExam('')
        setExamError('')
        setDegree('')
        setDegreeError('')
        setDiscipline('')
        setDisciplineError('')
        setSelectedBatchId('')
        setSemester('')
        setSemesterError('')
        setRegisterNo('')
        setRegisterNoError('')
        setDummyNo('')
        setDummyNoError('')
        setDisciplineCourse('')
        setDisciplineCourseError('')
        setQuestionPaper('')
        setQuestionPaperError('')
        setSecuredMark('')
        setSecuredMarkError('')
        setConvertedMark('')
        setConvertedMarkError('')
        setAdjustedMark('')
        setAdjustedMarkError('')
        setIntExaminer('')
        setIntExaminerError('')
        setExtExaminer('')
        setExtExaminerError('')
        setEditFlag(false)
        setSelectedDummyNoList([]);
        setSecuredMarks([]);
        setIntExaminers([]);
        setExtExaminers([]);
        setDummyNoGenData([])
        setMarksheetData([])
    }

    const handleFetchDataForMarksheetAndUpdateStudentGrades = () => {
        if (exam !== '' && degree !== '' && discipline !== '' && selectedBatchId !== '' && semester !== '') {
            // let years = academicYear.split('-')
            axios.get<{ data: MarkSheetReportType[] }>(`${apiUrl}/student/marksheet/?degreeId=${degree}&disciplineId=${discipline}&examId=${exam}&batch=${selectedBatchId}&sem=${semester}`).then(async (response) => {
                if (response.status === 200 && response.data.data) {
                    const result = response.data.data;
                    const AddGradeData: StudentGrade[] = [];
                    setMarksheetData(response.data.data);
                    let totalCreditsRegistered = 0;
                    let totalCreditsEarned = 0;
                    let totalGradePointsEarned = 0;
                    let GPA = 0;
                    let cumulativeCredits = 0;
                    let sem = 0;

                    result.length > 0 && result.forEach(h => {
                        h?.courseDetails?.forEach(cd => {
                            sem = cd.sem;
                            totalCreditsRegistered += parseInt(cd.credit)
                            totalGradePointsEarned += (cd.unvGrade)
                            if (cd.unvGrade > 0) {
                                totalCreditsEarned += parseInt(cd.credit)
                                cumulativeCredits += parseInt(cd.credit)
                                GPA += (cd.unvGrade) * parseInt(cd.credit)
                            }
                        })
                        GPA = GPA / totalCreditsEarned;
                        let obj = {
                            registeNo: h.registerNo,
                            batch: selectedBatchId.toString(),
                            sem: sem,
                            cRegistered: totalCreditsRegistered.toString(),
                            cEarned: totalCreditsEarned.toString(),
                            gpEarned: totalGradePointsEarned.toString(),
                            gpAverage: GPA.toFixed(2),
                        }

                        AddGradeData.push(obj)
                    });
                    console.log(AddGradeData);

                    if (AddGradeData) {
                        await axios.post(`${apiUrl}/studentGrade/bulkInsert/`, AddGradeData).then((response) => {
                            if (response.status === 200) {
                                toast.success('Grades Updated');
                                // dispatch(fetchStudentMarkAction());
                                // resetStates();
                                // handleDownloadPDF(editData);
                            }
                        }).catch((error) => {
                            if (error!.response) {
                                toast.error(error.response.data.message);
                            }
                        });
                    }

                } else setMarksheetData([])
            })
                .catch((error) => {
                    console.error('Error fetching marksheet data', error);
                });
        } else {
            toast.error('Select All Filters', { position: 'top-right', autoClose: 3000 });
            setMarksheetData([])
        }
    }

    const saveHandler = async () => {
        let error = false;

        const editData: any = [];

        const disCourseObj = disciplineCourseList.find(f =>
            f.degreeId?.toString() === degree &&
            f.disciplineId?.toString() === discipline &&
            f.courseId?.toString() === disciplineCourse && f.courseType === 'Practical'
        );

        const addData = dummyNoGenData?.map((sm, index) => {
            const securedMark = sm.extMark;
            const regNo = sm.regNo;
            const extConversion = disCourseObj?.extConversion ? disCourseObj.extConversion : 0;
            const convertedExternalMark = parseInt(securedMark) === -1 ? 0 : Math.round((parseFloat(securedMark) / 100) * extConversion);

            const intMark = sm.intMark;
            const intConvertedMark = sm.intConvertedMark;
            const totalConversion = Math.round(intConvertedMark + convertedExternalMark);

            const grade = calculateGrade(totalConversion, convertedExternalMark);
            const gradeText = calculateGradeText(totalConversion, convertedExternalMark);

            if (sm.studentMarkId !== null) {
                editData.push({
                    id: sm.studentMarkId,
                    extMark: securedMark,
                    extConvertedMark: convertedExternalMark,
                    totConvertedMark: totalConversion,
                    grade: grade,
                    gradeText: gradeText,
                    intMark: intMark,
                    intConvertedMark: intConvertedMark,
                    disciplineCourseId: disCourseObj?.id,
                    degreeId: parseInt(degree),
                    disciplineId: parseInt(discipline),
                    semester: parseInt(semester),
                    registerNumber: regNo,
                    name: sm.name
                });
            }

            return {
                examId: parseInt(exam),
                degreeId: parseInt(degree),
                disciplineId: parseInt(discipline),
                semester: parseInt(semester),
                batch: selectedBatchId,
                registerNumber: regNo,
                disciplineCourseId: disCourseObj?.id,
                questionPaperCode: questionPaper,
                extMark: securedMark,
                extConvertedMark: convertedExternalMark,
                totConvertedMark: totalConversion,
                grade: grade,
                gradeText: gradeText,
                intExaminerId: intExaminer,
                extExaminerId: extExaminer,
                intMark: intMark,
                intConvertedMark: intConvertedMark,
                name: sm.name
            };
        }).filter((data) => data !== null);

        if (!error) {
            try {
                if (editData.length > 0) {
                    // console.log("Hai I am put")
                    await axios.put(`${apiUrl}/studentMark/bulkUpdateForPractical/`, editData).then((response) => {
                        if (response.status === 200 && response.data.data) {
                            handleFetchDataForMarksheetAndUpdateStudentGrades()
                            toast.success(response.data.message);
                            dispatch(fetchStudentMarkAction());
                            handleDownloadPDF(editData);
                            resetStates();
                        }
                    }).catch((error) => {
                        if (error!.response) {
                            toast.error(error.response.data.message);
                        }
                    });

                    for (let i = 0; i < editData.length; i++) {
                        const editItem = editData[i];

                        let getSemesterForCheck = studentarrearList.find(sa =>
                            sa.disciplineId === editItem.disciplineId &&
                            sa.degreeId === editItem.degreeId &&
                            sa.disciplineCourseId === editItem.disciplineCourseId &&
                            sa.registerNo === editItem.registerNumber &&
                            sa.semesterNo === parseInt(semester)
                        )?.semesterNo || 0;

                        let getSemesterFromDisCourse = disciplineCourseList.find(dc => dc.id === editItem.disciplineCourseId)?.semesterNo;

                        if (editItem.grade === 0 && editItem.gradeText === 'U' && getSemesterFromDisCourse === parseInt(semester)) {
                            await handleArrear(editItem, parseInt(semester));
                        } else if (editItem.grade > 0 && editItem.gradeText !== 'U' && getSemesterForCheck === parseInt(semester)) {
                            console.log('Delete')
                            await handleDeleteArrear(editItem, parseInt(semester), studentarrearList);
                        } else if (editItem.grade && editItem.grade > 0 && editItem.gradeText !== 'U' && getSemesterForCheck === 0) {
                            await handleUpdateArrear(editItem, parseInt(semester), studentarrearList);
                        } else if (editItem.grade === 0 && editItem.gradeText === 'U' && getSemesterForCheck === 0) {
                            await handleReArrear(editItem, parseInt(semester), studentarrearList);
                        }
                    }
                } else {
                    // console.log("Hai I am post")
                    await axios.post(`${apiUrl}/studentMark/`, addData).then((response) => {
                        if (response.status === 200 && response.data.data) {
                            handleFetchDataForMarksheetAndUpdateStudentGrades()
                            toast.success(response.data.message);
                            dispatch(fetchStudentMarkAction());
                            handleDownloadPDF(addData);
                            resetStates();
                        }
                    }).catch((error) => {
                        if (error!.response) {
                            toast.error(error.response.data.message);
                        }
                    });

                    for (let i = 0; i < addData.length; i++) {
                        const addItem = addData[i];

                        let getSemesterForCheck = studentarrearList.find(sa =>
                            sa.disciplineId === addItem.disciplineId &&
                            sa.degreeId === addItem.degreeId &&
                            sa.disciplineCourseId === addItem.disciplineCourseId &&
                            sa.registerNo === addItem.registerNumber &&
                            sa.semesterNo === parseInt(semester)
                        )?.semesterNo || 0;

                        let getSemesterFromDisCourse = disciplineCourseList.find(dc => dc.id === addItem.disciplineCourseId)?.semesterNo;

                        if (addItem.grade === 0 && addItem.gradeText === 'U' && getSemesterFromDisCourse === parseInt(semester)) {
                            await handleArrear(addItem, parseInt(semester));
                        } else if (addItem.grade && addItem.grade > 0 && addItem.gradeText !== 'U' && getSemesterForCheck === 0) {
                            await handleUpdateArrear(addItem, parseInt(semester), studentarrearList);
                        } else if (addItem.grade === 0 && addItem.gradeText === 'U' && getSemesterForCheck === 0) {
                            await handleReArrear(addItem, parseInt(semester), studentarrearList);
                        }
                    }
                }
            } catch (error: any) {
                if (error.response) {
                    console.error("Error:", error.response.data);
                    toast.error(error.response.data.message);
                } else {
                    console.error("Error:", error);
                    toast.error("An error occurred while updating/creating the student mark.");
                }
            }
        }
    };

    const AssignDummyNoForStudents = (sdata: FilteredStudentType[]) => {
        const data: DummyDataType[] = [];

        sdata?.forEach((s, i) => {
            data.push({
                name: s.name,
                regNo: s.registerNo,
                studentMarkId: s.studentMarkId,
                extMark: s.extMark,
                intMark: parseInt(s.internalMark),
                intConvertedMark: (parseInt(s.internalMark) / 100) * parseInt(s.intConversionRate)
            });
        });

        return data;
    };

    const filterStudent = async () => {
        if (
            degree !== '' &&
            discipline !== '' &&
            exam !== '' &&
            disciplineCourse !== '' &&
            semester !== '' &&
            selectedBatchId !== '' &&
            // questionPaper !== '' &&
            intExaminer !== '' &&
            extExaminer !== ''

        ) {


            let disCourseId = disciplineCourseList?.find(f => f.degreeId?.toString() === degree && f.disciplineId?.toString() === discipline && f.courseId?.toString() === disciplineCourse && f.courseType === 'Practical')?.id

            let count = 0

            await axios.get(`${apiUrl}/studentAssessment/?degreeId=${degree}&disciplineId=${discipline}&disciplineCourseId=${disCourseId}&batch=${selectedBatchId}`)
                .then((response) => {
                    console.log(response.data.data.count);
                    count = response.data.data.count;
                })
                .catch((error) => console.log(error));



            if (count > 0) {
                await axios.get<{ data: FilteredStudentType[] }>(`${apiUrl}/student/studentPractical/?degreeId=${degree}&disciplineId=${discipline}&batch=${selectedBatchId}&disciplineCourseId=${disCourseId}&sem=${semester}&examId=${exam}`)
                    .then((response) => {
                        const result = response.data.data
                        if (result.length > 0) {
                            setDummyNoGenData(AssignDummyNoForStudents(result))
                            console.log(result)
                            console.log(dummyNoGenData)
                        } else {
                            setDummyNoGenData([])
                        }
                    })
                    .catch((error) => {
                        toast.error(error);
                        console.error('Error fetching students', error);
                    });
            } else {
                toast.error('Kindly fill internal marks in student assessment page!', { autoClose: 5000 })
            }
        } else {
            toast.error('Please select all filters');
        }

    };

    const openDeleteConfirmation = () => {
        setShowDeleteConfirmation(true);
    };

    const closeDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    };

    const deleteHandler = (id: number) => {
        setId(id)
        openDeleteConfirmation()
    }

    const confirmDeleteHandler = () => {
        if (id !== undefined) {
            dispatch(deleteStudentMarkAction(id)).then(text => {
                toast.success(text)
                closeDeleteConfirmation();
            }).catch(text => {
                toast.error(text);
            })
        }
    }

    const openEditModal = (
        data: StudentMarkType
    ) => {
        console.log(data);
        setId(data.id ? data.id : 0);
        setExam(getExamName(data.examId?.toString()));
        setDegree(getDegreeName(data.degreeId.toString()));
        setDiscipline(getDisciplineName(data.disciplineId.toString()));
        setSemester(data.semester.toString());
        setRegisterNo(data.registerNumber);
        setDisciplineCourse(getDisciplineCourse(data.disciplineCourseId.toString()));
        // setQuestionPaper(questionPaperCode),
        setIntConvertedMark(data.intConvertedMark ? data.intConvertedMark.toString() : '');
        setSecuredMark(data.extMark ? data.extMark.toString() : '');
        setIntExaminer(data.intExaminerId ? data.intExaminerId.toString() : '');
        setExtExaminer(data.extExaminerId ? data.extExaminerId.toString() : '');
        // console.log(id, examId, degreeId, disciplineId, disciplineCourseId);
        setEditModalShow(true);

        console.log(data.intConvertedMark ? data.intConvertedMark.toString() : '')
        // console.log(data.disciplineCourseId);
        axios.get(`${apiUrl}/disciplineCourse/?id=${data.disciplineCourseId}`)
            .then((response) => {
                setConvertion(response.data.data);
                console.log(response.data.data);
            })
            .catch((error) => console.log(error));

        setDegreeArrear(data.degreeId);
        setDisciplineArrear(data.disciplineId);
        setSemesterArrear(data.semester);
        setRegisterNoArrear(data.registerNumber);
        setDisciplineCourseArrear(data.disciplineCourseId);
    };


    const saveEdit = () => {
        let error = false
        if (parseInt(securedMark) < -1 || parseInt(securedMark) > 100) {
            setSecuredMarkError('Invaild Mark')
            error = true
        }
        // if (
        //   answerBookNo.length === 0 || attendance.length === 0 ||
        //   answerBookNo.length > 10 || attendance.length > 3
        // ) {
        //   if(answerBookNo.length === 0) {
        //     setAnswerBookNoError('Answer Book No is required');
        //   }
        //   if(attendance.length === 0) {
        //     setAttendanceError('Attendance is required');
        //   }
        //   if(answerBookNo.length > 10) {
        //     setAnswerBookNoError('Answer Book No must be within 10 characters');
        //   }
        //   if(attendance.length > 3) {
        //     setAttendanceError('Attendance is must be within 3 characters');
        //   }

        //   return;
        // }
        console.log(intConvertedMark);

        console.log(convertion);
        const extConversion = convertion ? convertion.extConversion : 0;
        console.log(extConversion);
        //const convertedExternalMark = (parseInt(securedMark) / 100) * extConversion;
        const convertedExternalMark = parseInt(securedMark) === -1 ? 0 : Math.round((parseFloat(securedMark) / 100) * extConversion);
        console.log(convertedExternalMark)
        const totalConversion = parseInt(intConvertedMark) + convertedExternalMark;
        console.log(totalConversion);

        const grade = calculateGrade(totalConversion, convertedExternalMark);
        const gradeText = calculateGradeText(totalConversion, convertedExternalMark);

        const data = {
            id: id,
            examId: parseInt(exam),
            degreeId: parseInt(degree),
            disciplineId: parseInt(discipline),
            semester: parseInt(semester),
            registerNumber: registeNo,
            dummyNumber: dummyNo,
            disciplineCourseId: parseInt(disciplineCourse),
            questionPaperCode: questionPaper,
            extMark: parseInt(securedMark),
            intConvertedMark: parseInt(intConvertedMark),
            extConvertedMark: convertedExternalMark,
            totConvertedMark: totalConversion,
            grade: grade,
            gradeText: gradeText,
            convertedMark: parseInt(convertedMark),
            adjustedMark: parseInt(adjuestedMark),
            intExaminerId: parseInt(intExaminer),
            extExaminerId: parseInt(extExaminer)
        };

        console.log(data);
        if (!error) {
            dispatch(editStudentMarkAction(data, id)).then(text => {
                toast.success(text);
                handleFetchDataForMarksheetAndUpdateStudentGrades()
                dispatch(fetchStudentMarkAction());
                setEditModalShow(false)
            }).catch((text: any) => {
                toast.error(text)
            })

            let getSemesterForCheck = studentarrearList.find(sa =>
                sa.disciplineId === disciplineArrear &&
                sa.degreeId === degreeArrear &&
                sa.disciplineCourseId === disciplineCourseArrear &&
                sa.registerNo === registerNoArrear &&
                sa.semesterNo === semesterArrear
            )?.semesterNo || 0;

            console.log(getSemesterForCheck)

            const arrearData = {
                id: id,
                disciplineId: disciplineArrear,
                degreeId: degreeArrear,
                disciplineCourseId: disciplineCourseArrear,
                registerNumber: registerNoArrear,
                semester: semesterArrear
            }
            console.log(data)

            let getSemesterFromDisCourse = disciplineCourseList.find(dc => dc.id === disciplineCourseArrear)?.semesterNo

            if (data.grade === 0 && data.gradeText === 'U' && getSemesterFromDisCourse === semesterArrear) {

                handleArrear(arrearData, semesterArrear);
            } else if (data.grade && data.grade > 0 && data.gradeText !== 'U' && getSemesterForCheck === semesterArrear) {
                // console.log('Hello')
                handleDeleteArrear(arrearData, semesterArrear, studentarrearList);
            } else if (data.grade && data.grade > 0 && data.gradeText !== 'U' && getSemesterForCheck === 0) {
                // console.log('world')
                handleUpdateArrear(arrearData, semesterArrear, studentarrearList);
            } else if (data.grade === 0 && data.gradeText === 'U' && getSemesterForCheck === 0) {
                handleReArrearEdit(arrearData, semesterArrear, studentarrearList);
            }
        }
    };


    const getExamName = (examId: string) => {
        const exam = examList?.find((ex) => ex.id?.toString() === examId?.toString());
        if (exam) {
            return exam.title;
        }
        return 'N/A';
    }


    const getDegreeName = (degreeId: string) => {
        const degree = degreeList?.find((deg) => deg.id?.toString() === degreeId?.toString());
        if (degree) {
            return degree.name;
        }
        return 'N/A';
    }


    const getDisciplineName = (disciplineId: string) => {
        const discipline = disciplineList?.find((dis) => dis.id?.toString() === disciplineId?.toString());
        if (discipline) {
            return discipline.name;
        }
        return 'N/A';
    }


    const getDisciplineCourse = (disciplineCourseId: string) => {
        const CourseId = disciplineCourseList?.find((dc) => dc?.id?.toString() === disciplineCourseId?.toString())?.courseId

        const courseObj = courseList?.find((dc) => dc.id?.toString() === CourseId?.toString())

        if (courseObj) {
            return courseObj?.name ? courseObj?.name : '';
        }
        return 'N/A';
    }

    const getFacultyName = (disciplineCourseId: number) => {
        const disciplineCourse = facultyList?.find((dc) => dc.id?.toString() === disciplineCourseId?.toString());
        if (disciplineCourse) {
            return `${disciplineCourse.code} - ${disciplineCourse.name}`;
        }
        return 'N/A';
    }

    const handleDownloadPDF = (practicalMark: any) => {

        const doc = new jsPDF('p', 'mm', 'a4');
        let examName = examList.find(f => f.id?.toString() === exam)?.title
        let degreeName = degreeList.find(d => d.id?.toString() === degree)?.name
        let disciplineName = disciplineList.find(d => d.id?.toString() === discipline)?.name
        let courseName = courseList.find(d => d.id?.toString() === disciplineCourse)?.name
        let courseCode = courseList.find(d => d.id?.toString() === disciplineCourse)?.shortName

        doc.addImage(imgPath, 'PNG', 20, 10, 170, 40);
        doc.setFontSize(13);
        doc.setFont('Arial', 'normal', 'bold')
        doc.text('Practical Mark Sheet', 80, 50, { align: 'justify' })
        doc.text(`${examName}`, 75, 60, { align: 'justify' });

        doc.setFontSize(11);
        doc.text(`Degree & Discipline : ${degreeName} - ${disciplineName}`, 15, 70)
        doc.text(`Course : ${courseCode} - ${courseName}`, 15, 80)


        autoTable(doc, {
            startY: 87,
            headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0], fontSize: 9 },
            bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0], halign: 'center', fontSize: 9 },
            head: [
                [
                    { content: "S.No.", styles: { halign: 'center' } },
                    { content: "Register Number", styles: { halign: 'center' } },
                    { content: "Student Name", styles: { halign: 'center' } },
                    { content: "Marks Awarded", styles: { halign: 'center' } },
                    { content: "Mark in Words", styles: { halign: 'center' } }
                ]

            ],
            body: practicalMark?.map((pm: any, i: any) => {
                return [(i + 1)?.toString(), pm.registerNumber, { content: pm.name, styles: { halign: 'left' } }, pm.extMark, inWords(pm.extMark)]
            }),
            theme: 'grid',
            tableLineColor: "black",
            margin: { left: 15 }
        })

        doc.setFontSize(10)
        doc.text(`Date & Session :`, 15, (doc as any).lastAutoTable.finalY + 7)
        doc.setFontSize(10)

        doc.text(`*Absent must be entered as "-1".`, 15, (doc as any).lastAutoTable.finalY + 15)
        doc.text(`*It is the responsibility of the Examiner ascertain the correctness of the above Marks.`, 15, (doc as any).lastAutoTable.finalY + 20)
        doc.setFontSize(11)
        doc.setFont('Arial', 'normal', 'bold');
        doc.text(`INTERNAL EXAMINER`, 15, (doc as any).lastAutoTable.finalY + 40)
        doc.text(`EXTERNAL EXAMINER`, 150, (doc as any).lastAutoTable.finalY + 40)

        doc.save('practical_mark_report.pdf');

    }

    // console.log(dummyNoGenData);


    return (
        <PageContainer title="Student Mark - Practical">
            <Card title={editFlag ? "Edit Student Mark - Practical" : "Add Student Mark - Practical"}>
                <div className="row">
                    <div className="col-4">
                        <label htmlFor="" className="mt-3 mb-3">Exam <span className="text-danger">*</span> : </label>
                        <select name="" id="" className="form-control" value={exam} onChange={(e) => {
                            setExam(e.target.value)
                            setExamError('')
                        }}>
                            <option value="" selected>Select Exam</option>
                            {examList?.map((ex) => (
                                <option key={ex.id} value={ex.id}>
                                    {ex.title}
                                </option>
                            ))}
                        </select>
                        {examError && <div className="text-danger">{examError}</div>}
                    </div>

                    <div className="col-4" >
                        <label htmlFor="" className="mt-3 mb-3">Degree <span className="text-danger">*</span> : </label>
                        <select name="" id="" className="form-control" value={degree} onChange={(e) => {
                            setDegree(e.target.value)
                            setDegreeError('')
                        }}>
                            <option value="" selected>Select Degree</option>
                            {degreeList?.map((deg) => (
                                <option key={deg.id} value={deg.id}>
                                    {deg.name}
                                </option>
                            ))}
                        </select>
                        {degreeError && <div className="text-danger">{degreeError}</div>}
                    </div>

                    <div className="col-4">
                        <label className="mt-3 mb-3">Discipline <span className="text-danger">*</span> : </label>
                        <select name="" id="" className="form-control" value={discipline} onChange={(e) => {
                            setDiscipline(e.target.value)
                            setDisciplineError('')
                        }}>
                            <option value="" selected>Select Discipline</option>
                            {disciplineList?.map((disc) => (
                                <option key={disc.id} value={disc.id}>
                                    {disc.name}
                                </option>
                            ))}
                        </select>
                        {disciplineError && <div className="text-danger">{disciplineError}</div>}
                    </div>

                    <div className="col-4">
                        <label className="mt-3 mb-3">Semester <span className="text-danger">*</span> : </label>
                        <select name="" id="" className="form-control"
                            value={semester}
                            onChange={(e) => {
                                setSemester(e.target.value)
                                setSemesterError('')
                            }}>
                            <option value="" selected>Select Semester</option>
                            <option value="1" selected>1</option>
                            <option value="2" selected>2</option>
                            <option value="3" selected>3</option>
                            <option value="4" selected>4</option>
                            <option value="5" selected>5</option>
                            <option value="6" selected>6</option>
                            <option value="7" selected>7</option>
                            <option value="8" selected>8</option>
                        </select>
                        {semesterError && <div className="text-danger">{semesterError}</div>}
                    </div>

                    <div className="col-4">
                        <label className="mt-3 mb-3">Batch <span className="text-danger">*</span> : </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px" }}
                            name="selectedBatch"
                            value={selectedBatchId}
                            onChange={(e) => setSelectedBatchId(e.target.value)}
                        >
                            <option value="" selected>Select Batch</option>
                            {last10Years.map((year) => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                        </select>
                        {batchIdError && <div className="text-danger">{batchIdError}</div>}
                    </div>

                    {/* <div className="col-4">
                    <label className="mt-3 mb-3">Register Number<span className="text-danger">*</span> : </label>
                    <input type="text" className="form-control" value={registeNo} onChange={(e) => {
                        setRegisterNo(e.target.value)
                        setRegisterNoError('')
                    }} placeholder="Enter Register Number..." />
                    {registeNoError && <div className="text-danger">{registeNoError}</div>}
                </div> */}

                    {/* <div className="col-4">
                    <label className="mt-3 mb-3">Dummy Number<span className="text-danger">*</span> : </label>
                    <input type="text" className="form-control" value={dummyNo} onChange={(e) => {
                        setDummyNo(e.target.value)
                        setDummyNoError('')
                    }} placeholder="Enter Dummy Number..." />
                    {dummyNoError && <div className="text-danger">{dummyNoError}</div>}
                </div> */}

                    <div className="col-4">
                        <label className="mt-3 mb-3">Course <span className="text-danger">*</span> : </label>
                        <select name="" id="" className="form-control" value={disciplineCourse} onChange={(e) => {
                            setDisciplineCourse(e.target.value)
                            setDisciplineCourseError('')
                        }}>
                            <option value="">Select Course</option>
                            {courseList?.map((course) => {

                                const filteredCourses = disciplineCourseList.filter(
                                    (dc) => dc.degreeId === parseInt(degree) && dc.disciplineId === parseInt(discipline) && dc.courseId === course.id && (dc.courseType === 'Practical')
                                );
                                //console.log(filteredCourses);

                                return filteredCourses.map((filteredCourse) => (
                                    <option key={course.id} value={course.id}>
                                        {course.name}
                                    </option>
                                ));
                            })}
                        </select>
                        {disciplineCourseError && <div className="text-danger">{disciplineCourseError}</div>}
                    </div>


                    <div className="col-4">
                        <label className="mt-3 mb-3">Question Paper Code  : </label>
                        <input type="text" className="form-control" value={questionPaper} onChange={(e) => {
                            setQuestionPaper(e.target.value)
                            setQuestionPaperError('')
                        }} placeholder="Enter Question PaperCode..." />
                        {questionPaperError && <div className="text-danger">{questionPaperError}</div>}
                    </div>

                    {/* <div className="col-4">
                    <label className="mt-3 mb-3">Secured Mark<span className="text-danger">*</span> : </label>
                    <input type="text" className="form-control" value={securedMark} onChange={(e) => {
                        setSecuredMark(e.target.value)
                        setSecuredMarkError('')
                    }} placeholder="Enter Secured Mark..." />
                    {securedMarkError && <div className="text-danger">{securedMarkError}</div>}
                </div>

                <div className="col-4">
                    <label className="mt-3 mb-3">Converted Mark<span className="text-danger">*</span> : </label>
                    <input type="text" className="form-control" value={convertedMark} onChange={(e) => {
                        setConvertedMark(e.target.value)
                        setConvertedMarkError('')
                    }} placeholder="Enter Converted Mark..." />
                    {convertedMarkError && <div className="text-danger">{convertedMarkError}</div>}
                </div>

                <div className="col-4">
                    <label className="mt-3 mb-3">Adjusted Mark<span className="text-danger">*</span> : </label>
                    <input type="text" className="form-control" value={adjuestedMark} onChange={(e) => {
                        setAdjustedMark(e.target.value)
                        setAdjustedMarkError('')
                    }} placeholder="Enter Adjusted Mark..." />
                    {adjuestedMarkError && <div className="text-danger">{adjuestedMarkError}</div>}
                </div> */}

                    <div className="col-4">
                        <label className="mt-3 mb-3">Internal Examiner <span className="text-danger">*</span> : </label>
                        {/* <input type="text" className="form-control" value={intExaminer} onChange={(e) => {
                        setIntExaminer(e.target.value)
                        setIntExaminerError('')
                    }} placeholder="Enter Int Examiner..." /> */}
                        <select
                            name=""
                            id=""
                            className="form-control"
                            value={intExaminer}
                            onChange={(e) => {
                                setIntExaminer(e.target.value);
                                setIntExaminerError('');
                            }}
                        >
                            <option value="" selected>
                                Select Internal Examiner
                            </option>
                            {examFacultyList.filter(ef => ef.disciplineId?.toString() === discipline).map((examFaculty) => {
                                if (examFaculty.type === 'internal' || examFaculty.type === 'Internal') {
                                    const faculty = facultyList.find(faculty => faculty.id === examFaculty.facultyId);
                                    return (
                                        <option key={examFaculty.id} value={examFaculty.facultyId}>
                                            {faculty?.name}
                                        </option>
                                    );
                                }
                                return null;
                            })}
                        </select>
                        {intExaminerError && <div className="text-danger">{intExaminerError}</div>}
                    </div>

                    <div className="col-4">
                        <label htmlFor="" className="mt-3 mb-3">External Examiner <span className="text-danger">*</span> : </label>
                        {/* <input type="text" className="form-control" value={extExaminer} onChange={(e) => {
                        setExtExaminer(e.target.value)
                        setExtExaminerError('')
                    }} placeholder="Enter Ext Examiner..." /> */}
                        <select
                            name=""
                            id=""
                            className="form-control"
                            value={extExaminer}
                            onChange={(e) => {
                                setExtExaminer(e.target.value);
                                setIntExaminerError('');
                            }}
                        >
                            <option value="" selected>
                                Select Internal Examiner
                            </option>
                            {examFacultyList.filter(ef => ef.disciplineId?.toString() === discipline).map((examFaculty) => {
                                if (examFaculty.type === 'external' || examFaculty.type === 'External') {
                                    const faculty = facultyList.find(faculty => faculty.id === examFaculty.facultyId);
                                    return (
                                        <option key={examFaculty.id} value={examFaculty.facultyId}>
                                            {faculty?.name}
                                        </option>
                                    );
                                }
                                return null;
                            })}
                        </select>
                        {extExaminerError && <div className="text-danger">{extExaminerError}</div>}
                    </div>

                    <div className="col-12">
                        <button
                            type="button"
                            className="btn btn-sm btn-primary"
                            onClick={filterStudent}
                            style={{ width: "10%", marginTop: "30px", float: "right" }}
                        >
                            Search
                        </button>
                    </div>

                    <div className="container-fluid" style={{ marginTop: "30px" }}>
                        <table className="table table-success table-striped">
                            <thead>
                                <tr>
                                    <th>S.No.</th>
                                    <th>Register Number</th>
                                    {/* <th>Question Paper Code</th> */}
                                    <th>External Mark</th>
                                    {/* <th>Internal Examiner</th>
                                    <th>External Examiner</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dummyNoGenData.map((sm, i) => (
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>{sm.regNo}</td>
                                            {/* <td>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    style={{ width: "30%" }}
                                                    value={securedMarks[i]}
                                                    onChange={(e) => {
                                                        const enteredValue = e.target.value;
                                                        const isValid = enteredValue === '' || (parseInt(enteredValue) >= -1 && parseInt(enteredValue) <= 100);

                                                        if (isValid) {
                                                            const newSecuredMarks = [...securedMarks];
                                                            newSecuredMarks[i] = enteredValue;
                                                            setSecuredMarks(newSecuredMarks);
                                                            setSecuredMarkError('');
                                                        } else {
                                                            setSecuredMarkError('Mark must be within -1 to 100');
                                                        }
                                                    }}
                                                    placeholder="Enter Secured Mark..."
                                                />
                                                {securedMarkError && <div className="text-danger">{securedMarkError}</div>}
                                            </td> */}
                                            <td>
                                                <input
                                                    type="number"
                                                    style={{ width: "30%" }}
                                                    className="form-control"
                                                    value={sm.extMark}
                                                    onChange={(e) => {
                                                        const enteredValue = e.target.value;
                                                        console.log(enteredValue);
                                                        const isValid = enteredValue === '' || (parseInt(enteredValue) >= -1 && parseInt(enteredValue) <= 100);

                                                        if (isValid) {
                                                            setDummyNoGenData((prev) => {
                                                                let updated = [...prev];
                                                                updated[i].extMark = enteredValue;
                                                                return updated;
                                                            });
                                                            setSecuredMarkError('');
                                                        } else {
                                                            setSecuredMarkError(`Mark must be within -1 to 100`);
                                                        }
                                                    }}
                                                    placeholder="Enter Secured Mark..."
                                                />
                                                {securedMarkError && <div className="text-danger">{securedMarkError}</div>}
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>

                    {dummyNoGenData.length > 0 && (
                        <div className="col-12 mt-5 my-3 py-2 hstack">
                            <button
                                className="btn btn-sm btn-primary"
                                style={{ float: "right" }}
                                onClick={saveHandler}
                            >
                                {dummyNoGenData.some(sm => sm.studentMarkId === null) ? 'Add' : 'Update'}
                            </button>
                            <button
                                className="btn btn-sm btn-secondary"
                                style={{ marginRight: '10px', float: "right" }}
                                onClick={resetStates}
                            >
                                Clear
                            </button>
                        </div>
                    )}
                </div>
            </Card>

            <CollapseCard title="Practical Marks">
                {/* <div className='container m-3'> */}
                <div className="row m-2 mb-3">
                    <div className="col-lg-3 col-sm-6 col-md-3">
                        <label htmlFor="" className="mt-2 mb-2">Exam : </label>
                        <select name="" id="" className="form-control" value={examFilter} onChange={(e) => {
                            setExamFilter(e.target.value)
                        }}>
                            <option value="" selected>Select Exam</option>
                            {examList?.map((ex) => (
                                <option key={ex.id} value={ex.id}>
                                    {ex.title}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-md-3" >
                        <label htmlFor="" className="mt-2 mb-2">Degree : </label>
                        <select name="" id="" className="form-control" value={degreeFilter} onChange={(e) => {
                            setDegreeFilter(e.target.value)
                        }}>
                            <option value="" selected>Select Degree</option>
                            {degreeList?.map((deg) => (
                                <option key={deg.id} value={deg.id}>
                                    {deg.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-md-3">
                        <label className="mt-2 mb-2">Discipline : </label>
                        <select name="" id="" className="form-control" value={disciplineFilter} onChange={(e) => {
                            setDisciplineFilter(e.target.value)
                        }}>
                            <option value="" selected>Select Discipline</option>
                            {disciplineList?.map((disc) => (
                                <option key={disc.id} value={disc.id}>
                                    {disc.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-md-3">
                        <label className="mt-2 mb-2">Course : </label>
                        <select name="" id="" className="form-control" value={courseFilter} onChange=
                            {(e) => {
                                setCourseFilter(e.target.value)
                            }}>
                            <option value="" selected>Select Course</option>
                            {courseList?.map((course) => (
                                <option key={course.id} value={course.id}>
                                    {course.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-md-3">
                        <label className="mt-2 mb-2">Semester : </label>
                        <select name="" id="" className="form-control"
                            value={semesterFilter}
                            onChange={(e) => {
                                setSemesterFilter(e.target.value)
                            }}>
                            <option value="" selected>Select Semester</option>
                            <option value="1" selected>1</option>
                            <option value="2" selected>2</option>
                            <option value="3" selected>3</option>
                            <option value="4" selected>4</option>
                            <option value="5" selected>5</option>
                            <option value="6" selected>6</option>
                            <option value="7" selected>7</option>
                            <option value="8" selected>8</option>
                        </select>
                    </div>

                </div>
                {/* </div> */}
                <div className="container-fluid">
                    <table className="table table-success table-striped">
                        <thead>
                            <tr>
                                <th>S.No.</th>
                                <th>Exam</th>
                                <th>Degree</th>
                                <th>Discipline</th>
                                <th>Semester</th>
                                <th>Register Number</th>
                                {/* <th>Dummy Number</th> */}
                                <th>Discipline Course</th>
                                <th>Question Paper Code</th>
                                <th>Secured Mark</th>
                                {/* <th>Converted Mark</th> */}
                                {/* <th>Adjusted Mark</th> */}
                                <th>Internal Examiner</th>
                                <th>External Examiner</th>
                                <th>option</th>
                            </tr>
                        </thead>
                        <tbody>
                            {studentListFilter
                                ?.filter((sm) => sm.dummyNumber === "")
                                ?.map((sm, i) => (
                                    <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td>{getExamName(sm.examId?.toString())}</td>
                                        <td>{getDegreeName(sm.degreeId?.toString())}</td>
                                        <td>{getDisciplineName(sm.disciplineId?.toString())}</td>
                                        <td>{sm.semester}</td>
                                        <td>{sm.registerNumber}</td>
                                        {/* <td>{sm.dummyNumber}</td> */}
                                        <td>{getDisciplineCourse(sm.disciplineCourseId?.toString())}</td>
                                        <td>{sm.questionPaperCode}</td>
                                        <td>{sm.extMark}</td>
                                        {/* <td>{sm.convertedMark}</td> */}
                                        {/* <td>{sm.adjustedMark}</td> */}
                                        <td>{sm.intExaminerId ? getFacultyName(sm.intExaminerId) : ""}</td>
                                        <td>{sm.extExaminerId ? getFacultyName(sm.extExaminerId) : ""}</td>
                                        <td className="d-flex">
                                            <button
                                                className="btn btn-primary"
                                                style={{ marginRight: '10px' }}
                                                onClick={() =>
                                                    openEditModal(
                                                        sm
                                                    )
                                                }
                                            >
                                                Edit
                                            </button>
                                            <button className="btn btn-sm btn-danger" onClick={(e) => deleteHandler(sm.id ? sm.id : 0)}>Delete</button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </CollapseCard>
            <Modal show={editModalShow} onHide={() => setEditModalShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Student Mark - Practical</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-6">
                            <label className="mt-3 mb-3">Exam:</label>
                            <input className="form-control" type="text" value={exam} readOnly />
                        </div>
                        <div className="col-6">
                            <label className="mt-3 mb-3">Degree:</label>
                            <input className="form-control" type="text" value={degree} readOnly />
                        </div>
                        <div className="col-6">
                            <label className="mt-3 mb-3">Discipline:</label>
                            <input className="form-control" type="text" value={discipline} readOnly />
                        </div>
                        <div className="col-6">
                            <label className="mt-3 mb-3">Discipline Course:</label>
                            <input className="form-control" type="text" value={disciplineCourse} readOnly />
                        </div>
                        <div className="col-6">
                            <label className="mt-3 mb-3">Register No:</label>
                            <input className="form-control" type="text" value={registeNo} readOnly />
                        </div>
                        <div className="col-6">
                            <label className="mt-3 mb-3">Secured Mark:</label>
                            <input
                                className="form-control"
                                placeholder="Enter secured mark..."
                                type="number"
                                name="answerbookno"
                                onChange={(e) => {
                                    setSecuredMark(e.target.value)
                                    setSecuredMarkError('')
                                }}
                                value={securedMark}
                            />
                            <span className="text-danger">{securedMarkError}</span>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setEditModalShow(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={saveEdit}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteConfirmation} onHide={closeDeleteConfirmation}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete Form</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeDeleteConfirmation}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={confirmDeleteHandler}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </PageContainer>
    );
}

export default StudentMarkPractical;

interface DisciplineCourse {
    extConversion: any
    id: number;
    degreeId: string;
    disciplineId: string;
    courseId: number;
}

interface Student {
    id: number;
    registerNo: string;
    batch: string;
}

interface FilteredStudentType {
    id: number
    studentMarkId: number
    registerNo: string
    regulation: number
    departmentRegisterNumber: string
    name: string
    extMark: string
    internalMark: string
    intConversionRate: string
}

interface DummyDataType {
    name: string
    studentMarkId: number
    regNo: string
    // dummyRegNo: number
    extMark: string
    intMark: number
    intConvertedMark: number
}

interface StudentArrearType {
    id?: number;
    disciplineId: number;
    degreeId: number;
    registerNo: string;
    semesterNo: number;
    disciplineCourseId: number;
    attemptCount: number;
    clearedSemester: number;
    status: string;
    createdBy: string;
    createTimestamp: string;
}

interface StuAssessmentType {
    id: number;
    registerNo: string;
    degreeId: string;
    disciplineId: string;
    disciplineCourseId: string;
    batch: string;
    mark: string;
    externalMaxMark: string;
    createdBy: string;
    createTimestamp: string;
    phase: string;
    contactHours: string;
    attendedHours: string;
}