import React, { FC } from "react";
import PageContainer from "../components/custom/PageContainer";
import ExpandableTree from "../components/custom/ExpandableTree";
import CollapseCard from "../components/custom/CollapseCard";
import Card from "../components/custom/Card";
import DataTable from "../components/custom/DataTable";

const TablePage:FC = () =>{
    return(
        <PageContainer title="Table Page">
            <CollapseCard title="ExpandableTree">
                <ExpandableTree />
            </CollapseCard>

            <Card title="DataTable">
                <DataTable />
            </Card>

            
        </PageContainer>
    )
}
export default TablePage;