import axios from 'axios';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from '../components/custom/Card';
import CollapseCard from '../components/custom/CollapseCard';
import PageContainer from '../components/custom/PageContainer';
import { apiUrl } from '../config';

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import imgPath from './img/collegeLogo.png';

import { CSVLink } from 'react-csv';
import * as Feather from 'react-feather';

const ExamRegister = () => {
    const [examRegisterList, setExamRegisterList] = useState<ExamRegisterType[]>([]);

    // const 'exam_register': 'exam_registration_report';

    // console.log(examRegisterList);

    const [disciplineCourseId, setDisciplineCourseId] = useState<string[]>([]);
    const [editFlag, setEditFlag] = useState(false);
    const [registerNo, setRegisterNo] = useState('');
    const [currentSemester, setCurrentSemester] = useState('');
    const [examId, setExamId] = useState('');
    const [totalCourse, setTotalCourse] = useState('');
    const [totalFees, setTotalFees] = useState('');
    const [paymentStatus, setPaymentStatus] = useState('');
    const [paymentParticulars, setPaymentParticulars] = useState('');
    const [id, setId] = useState<number>(0);
    const [createdBy, setCreatedBy] = useState<string>('');
    const [createTimestamp, setCreateTimestamp] = useState<string>('');
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [registerNoError, setRegisterNoError] = useState('');
    const [currentSemesterError, setCurrentSemesterError] = useState('');
    const [examIdError, setExamIdError] = useState('');
    const [totalCourseError, setTotalCourseError] = useState('');
    const [totalFeesError, setTotalFeesError] = useState('');
    const [paymentStatusError, setPaymentStatusError] = useState('');
    const [paymentParticularsError, setPaymentParticularsError] = useState('');
    const [degreeError, setDegreeError] = useState('');
    const [disciplineError, setDisciplineError] = useState('');
    const [semesterError, setSemesterError] = useState('');
    const [batchIdError, setBatchIdError] = useState('');


    // const [disciplineCourseIdError, setDisciplineCourseIdError] = useState('');
    const [apiErrorMessage, setApiErrorMessage] = useState('');

    const [selectedDegree, setSelectedDegree] = useState<Degree[]>([]);
    const [selectedDegreeId, setSelectedDegreeId] = useState('');
    const [degree, setDegree] = useState('');

    const [selectedDiscipline, setSelectedDiscipline] = useState<Discipline[]>([]);
    const [selectedDisciplineId, setSelectedDisciplineId] = useState('');
    const [discipline, setDiscipline] = useState('');

    const [selectedSemester, setSelectedSemester] = useState<DisciplineCourse[]>([]);
    const [selectedSemesterId, setSelectedSemesterId] = useState('');
    const [semester, setSemester] = useState('');

    const [selectedBatch, setSelectedBatch] = useState<DisciplineCourse[]>([]);
    const [batchId, setBatchId] = useState('');
    const [batch, setBatch] = useState('');

    const [selectedExamId, setSelectedExamId] = useState('');
    const [exam, setExam] = useState('');
    const [selectedExam, setSelectedExam] = useState<Exam[]>([]);

    const [isLoading, setIsLoading] = useState(false);

    const [tableData, setTableData] = useState<ExamRegisterType[]>([]);


    const [courseDetails, setCourseDetails] = useState<ExamRegisterType[]>([]);

    const [paymentStatusArray, setPaymentStatusArray] = useState<string[]>([]);
    const [paymentParticularsArray, setPaymentParticularsArray] = useState<string[]>([]);

    //for edit model
    const [editModelShow, setEditModelShow] = useState(false);
    const [editId, setEditId] = useState(0);
    const [editRegisterNo, setEditRegisterNo] = useState('');
    const [editCurrentSemester, setEditCurrentSemester] = useState('');
    const [editExamId, setEditExamId] = useState('');
    const [editTotalCourse, setEditTotalCourse] = useState('');
    const [editTotalFees, setEditTotalFees] = useState('');
    const [editPaymentStatus, setEditPaymentStatus] = useState('');
    const [editPaymentParticulars, setEditPaymentParticulars] = useState('');


    const handlePaymentStatusChange = (index: number, event: any) => {
        const { name, value } = event.target;
        const newArray: any = [...tableData];
        newArray[index][name] = value;
        setPaymentStatusArray(newArray);
    };

    const handlePaymentParticularsChange = (index: number, event: any) => {
        const { name, value } = event.target;
        const newArray: any = [...tableData];
        newArray[index][name] = value;
        setPaymentParticularsArray(newArray);
    };

    // useEffect(() => {
    //     setExamRegisterList(examRegisterList);
    // }, [examRegisterList]);

    useEffect(() => {
        fetchData();
    }, [])


    const fetchData = () => {

        if (degree != '' || discipline != '' || semester != '' || batch != '' || exam != '') {

            let selectDegree = degree !== '' ? degree : null;
            let selectDiscipline = discipline !== '' ? discipline : null;
            let selectSemester = semester !== '' ? semester : null;
            let selectBatch = batch !== '' ? batch : null;
            let selectExam = exam !== '' ? exam : null;

            axios.get(`${apiUrl}/examregister/?degree=${selectDegree}&discipline=${selectDiscipline}&semester=${selectSemester}&batch=${selectBatch}&exam=${selectExam}`)
                .then((response) => {
                    setExamRegisterList(response.data.data);
                    // console.log(response.data.data);
                })
                .catch((error) => console.log(error));
        }
        else {
            axios.get(`${apiUrl}/examregister/`)
                .then((response) => {
                    setExamRegisterList(response.data.data)
                })
                .catch((error) => console.log(error));
        }


        // const fetchExamRegister = () => {
        //     let examList = examRegisterList.filter((er) => {
        //         return ((degree === '' || er.degreeId === degree) && (discipline === '' || er.disciplineId === discipline) && (semester === '' || er.semester === semester) && (batch === '' || er.batch === batch) && (exam === '' || er.examId === exam)
        //         )
        //     })
        // setExamRegisterList(examList)
    }


    const resetData = () => {
        setRegisterNo('');
        setCurrentSemester('');
        setSelectedExamId('');
        setSelectedDisciplineId('');
        setSelectedSemesterId('');
        setSelectedDegreeId('');
        setBatch('');
        setTotalCourse('');
        setTotalFees('');
        setPaymentStatus('');
        setPaymentParticulars('');
        setTableData([])
        fetchData();
        setDegree('');
        setDiscipline('');
        setSemester('');
        setExam('');
        setBatch('');
    }

    useEffect(() => {
        axios.get(`${apiUrl}/degree/`)
            .then((response) => {
                setSelectedDegree(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching discipline course', error);
            });
    }, [])

    useEffect(() => {
        axios.get(`${apiUrl}/discipline/`)
            .then((response) => {
                setSelectedDiscipline(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching discipline course', error);
            });
    }, [])

    useEffect(() => {
        axios.get(`${apiUrl}/disciplineCourse/`)
            .then((response) => {
                setSelectedSemester(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching discipline course', error);
            });
    }, [])

    useEffect(() => {
        axios.get(`${apiUrl}/exam/`)
            .then((response) => {
                setSelectedExam(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching Course', error);
            });
    }, []);

    const addHandler = () => {

        setRegisterNoError('');
        setCurrentSemesterError('');
        setExamIdError('');
        setTotalCourseError('');
        setTotalFeesError('');
        setPaymentStatusError('');
        setPaymentParticularsError('');
        setApiErrorMessage('');

        if (tableData.length === 0) {
            toast.error('No records to add. Please add at least one record.', { position: 'top-right' });
            return;
        }

        // const hasErrors = tableData.some((exam, index) => {
        //   if (!paymentStatusArray[index]?.trim()) {
        //     setPaymentStatusError(`Payment Status is required for record ${index + 1}`);
        //     return true; // Stop processing and return true if there's an error
        //   }
        //   if (!paymentParticularsArray[index]?.trim()) {
        //     setPaymentParticularsError(`Payment Particulars is required for record ${index + 1}`);
        //     return true; // Stop processing and return true if there's an error
        //   }
        //   return false; // Continue to the next record
        // });

        // if (hasErrors) {
        //   // Display a general error message if needed
        //   toast.error('Please fill in all required fields.', { position: 'top-right' });
        //   return;
        // }


        // if (courseDetails.length > 0) {
        //   const selectedDisciplineCourseIds = courseDetails.map(course => course.courseId);
        //   console.log("Selected Discipline Course Ids:", selectedDisciplineCourseIds);
        //   setDisciplineCourseId(selectedDisciplineCourseIds);


        // const recordsToAdd = tableData.map((exam, index) => ({
        //   registerNo: exam.registerNo,
        //   currentSemester: exam.currentSemester,
        //   examId: selectedExamId,
        //   totalCourse: exam.totalCourse,
        //   totalFees: exam.totalFees,
        //   paymentStatus: paymentStatusArray[index],
        //   paymentParticulars: paymentParticularsArray[index],
        //   disciplineCourseId: selectedDisciplineCourseIds,  
        //   createdBy,
        //   createTimestamp,
        // }));

        const data = {
            examId: selectedExamId,
            disciplineId: selectedDisciplineId,
            degreeId: selectedDegreeId,
            currentSemester: selectedSemesterId,
            registerDetails: tableData
        }

        axios.post(`${apiUrl}/examregister/`, data)
            .then((response) => {
                if (response.status === 200 && response.data.data) {
                    toast.success(response.data.message)
                }
                resetData()
            })
            .catch((error) => {
                console.log(error)
                toast.error(error.response.data.message)
            });
        // }        
    };

    const openEditModel = (id: number, registerNo: string, currentSemester: string, examId: string, totalCourse: string, totalFees: string, paymentStatus: string, paymentParticulars: string) => {
        setEditId(id);
        setEditRegisterNo(registerNo);
        setEditCurrentSemester(currentSemester);
        setEditExamId(examId);
        setEditTotalCourse(totalCourse);
        setEditTotalFees(totalFees);
        setEditPaymentStatus(paymentStatus);
        setEditPaymentParticulars(paymentParticulars)
        setEditModelShow(true);
    }

    const saveEdit = () => {
        let hasErrors = false;

        if (editPaymentStatus.trim() === '') {
            setPaymentStatusError('Payment Status is required');
            hasErrors = true;
        } else {
            setPaymentStatusError('');
        }

        if (editPaymentParticulars.trim() === '') {
            setPaymentParticularsError('Payment Particular is required');
            hasErrors = true;
        } else {
            setPaymentParticularsError('');
        }

        if (hasErrors) {
            return;
        }

        const getExamId = (examId: string) => {
            console.log('Exam Name to find:', examId);
            console.log('Selected Exam Array:', selectedExam);
            const exam = selectedExam.find((e) => e.title === examId);
            console.log('Found Exam:', exam);
            return exam ? exam.id : 'N/A';
        };

        console.log(editRegisterNo)

        const editData = {
            id: editId,
            registerNo: editRegisterNo,
            currentSemester: editCurrentSemester,
            examId: getExamId(editExamId),
            totalFees: editTotalFees,
            totalCourse: editTotalCourse,
            paymentStatus: editPaymentStatus,
            paymentParticulars: editPaymentParticulars
        }
        axios
            .put(`${apiUrl}/examregister/?id=${editId}`, editData)
            .then((response) => {
                if (response.data.error) {
                    toast.error('Failed to update Exam Register', { position: 'top-right' });
                    setApiErrorMessage(response.data.message);
                } else {
                    toast.success('Successfully Updated!', { position: 'top-right' });
                    setEditModelShow(false);
                    setPaymentStatusError('');
                    setPaymentParticularsError('');
                    fetchData();
                    // fetchExamRegister();
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const fetchCourseDetails = (registerNo: string) => {

        axios.get(`${apiUrl}/examregister/?registerNo=${registerNo}`)
            .then((response) => {
                setCourseDetails(response.data);
                console.log(response.data);
            })
            .catch((error) => {
                console.error('Error fetching course details', error);
            });
    };


    const handleFind = () => {
        setDegreeError('');
        setDisciplineError('');
        setSemesterError('');
        setExamIdError('');
        setBatchIdError('');

        if (!selectedDegreeId) {
            setDegreeError('Degree is required');
        }

        if (!selectedDisciplineId) {
            setDisciplineError('Discipline is required');
        }

        if (!selectedSemesterId) {
            setSemesterError('Semester is required');
        }

        if (!selectedExamId) {
            setExamIdError('Exam ID is required');
        }
        if (!batchId) {
            setBatchIdError('Batch is required');
        }

        if (!degreeError && !disciplineError && !semesterError && !examIdError && !batchIdError) {
            if (selectedDegreeId && selectedDisciplineId && selectedSemesterId && batchId && selectedExamId) {
                setIsLoading(true);
                axios.get(`${apiUrl}/student/?degree=${selectedDegreeId}&discipline=${selectedDisciplineId}&semester=${selectedSemesterId}&batch=${batchId}&exam=${selectedExamId}`)
                    .then((response) => {
                        if (response.status === 200 && response.data.data) {
                            setTableData(response.data.data);
                            if (response.data && response.data.length > 0) {
                                const registerNo = response.data[0].registerNo;
                                fetchCourseDetails(registerNo);
                            }
                        }
                    })
                    .catch((error) => {
                        toast.error(error.response.data.message, { position: 'top-right' });
                        console.error('Error fetching data', error);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            }
        }
    };

    const clearHandler = () => {

        setRegisterNo('');
        setCurrentSemester('');
        setSelectedDegreeId('');
        setSelectedDisciplineId('');
        setSelectedSemesterId('');
        setSelectedExamId('');
        setTotalCourse('');
        setTotalFees('');
        setPaymentStatus('');
        setPaymentParticulars('');

        setRegisterNoError('');
        setCurrentSemesterError('');
        setExamIdError('');
        setTotalCourseError('');
        setTotalFeesError('');
        setPaymentStatusError('');
        setPaymentParticularsError('');
        setApiErrorMessage('');

        setTableData([]);
        setCourseDetails([]);
        setPaymentStatusArray([]);
        setPaymentParticularsArray([]);
        setIsLoading(false);
    }


    const getExamId = (ExamRegister: ExamRegisterType) => {
        const examId = selectedExam.find((exam) => exam.id === parseInt(ExamRegister.examId));
        if (examId) {
            return examId.title;
        }
        return 'N/A';
    }

    const deleteHandler = (id: number) => {
        setId(id);
        openDeleteConfirmation(); // Open the delete confirmation modal
    }

    const confirmDeleteHandler = () => {
        axios
            .delete(`${apiUrl}/examregister/?id=${id}`)
            .then((response) => {
                // fetchExamRegister();
                closeDeleteConfirmation();
                toast.success('Exam Register deleted successfully');
            })
            .catch((error) => {
                console.log(error);
                toast.error('Error deleting discipline', { position: 'top-right' });
            });
    };

    const openDeleteConfirmation = () => {
        setShowDeleteConfirmation(true);
    }

    const closeDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    }

    // const getUniqueSemesters = () => {
    //   const uniqueSemesters = Array.from(new Set(selectedSemester.map((semester) => semester.semesterNo)));
    //   return uniqueSemesters;
    // };

    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let min = 0;
    let max = 10;

    let last10Years = [];
    for (let i = min; i <= max; i++) {
        let dropYear = year - i;
        last10Years.push(dropYear);
    }

    const getExam = (exam: any) => {
        const name = selectedExam.find(se => se.id === exam)?.title
        return name;
    }

    const getDegree = (degree: any) => {
        const degreeName = selectedDegree.find(sd => sd.id === degree)?.name
        return degreeName;
    }

    const getDiscipline = (discipline: any) => {
        const disciplineName = selectedDiscipline.find(sd => sd.id === discipline)?.name
        return disciplineName;
    }

    const handleDownloadPDF = () => {
        const doc = new jsPDF('p', 'mm', 'a4');
        doc.addImage(imgPath, 'PNG', 25, 10, 170, 40);

        doc.setFontSize(16);
        doc.setFont('Arial', 'normal', 'bold');
        doc.setTextColor(0, 0, 0);
        doc.text('EXAM REGISTRATION', 100, 55, { align: 'center' });

        autoTable(doc, {
            startY: 60,
            headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0] },
            bodyStyles: { textColor: [0, 0, 0], lineColor: [0, 0, 0] },
            margin: 5,
            // tableWidth: 194,
            head: [
                [
                    { content: 'S.No.', styles: { halign: 'center', cellWidth: 8 } },
                    { content: 'Register No', styles: { halign: 'center', cellWidth: 25 } },
                    { content: 'Exam', styles: { halign: 'center', cellWidth: 25 } },
                    { content: 'Degree', styles: { halign: 'center', cellWidth: 16 } },
                    { content: 'Discipline', styles: { halign: 'center', cellWidth: 30 } },
                    { content: 'Current Semester', styles: { halign: 'center', cellWidth: 21 } },
                    { content: 'Batch', styles: { halign: 'center', cellWidth: 15 } },
                    { content: 'Total Course', styles: { halign: 'center', cellWidth: 25 } },
                    { content: 'Total Fees', styles: { halign: 'center', cellWidth: 16 } },
                    { content: 'Payment Status', styles: { halign: 'center', cellWidth: 23 } }
                ],
            ],
            body: examRegisterList.map((er, index) => [
                { content: index + 1, styles: { halign: 'center' } },
                { content: er.registerNo, styles: { halign: 'center' } },
                { content: getExam(er.examId), styles: { halign: 'center' } },
                { content: getDegree(er.degreeId), styles: { halign: 'center' } },
                { content: getDiscipline(er.disciplineId), styles: { halign: 'center' } },
                { content: er.currentSemester, styles: { halign: 'center' } },
                { content: er.batch, styles: { halign: 'center' } },
                { content: er.totalCourse, styles: { halign: 'center' } },
                { content: er.totalCourse, styles: { halign: 'center' } },
                { content: er.paymentStatus, styles: { halign: 'center' } }
            ]),
            theme: 'grid',
            // columnStyles: {
            //     0: { cellWidth: 8 },
            //     1: { cellWidth: 25 },
            //     2: { cellWidth: 25 },
            //     3: { cellWidth: 16 },
            //     4: { cellWidth: 30 },
            //     5: { cellWidth: 21 },
            //     6: { cellWidth: 15 },
            //     7: { cellWidth: 25 },
            //     8: { cellWidth: 16 },
            //     9: { cellWidth: 16 },
            //     10: { cellWidth: 30 }

            // },
        });
        doc.save('exam_register' + '.pdf');
    }




    const csvData = [
        [
            'registerNo',
            'regulationId',
            'degreeId',
            'disciplineId',
            'dateOfBirth',
            'name',
            'contactNumber',
            'currentSemester',
            'totalCourse',
            'totalFees',
            'disciplineName',
            'degreeName',
        ],
        ...examRegisterList.map((row) => [
            row.registerNo,
            row.regulation,
            row.degreeId,
            row.disciplineId,
            row.dateOfBirth,
            row.name,
            row.contactNumber,
            row.currentSemester,
            row.totalCourse,
            row.totalFees,
            row.disciplineName,
            row.degreeName,
        ]),
    ];

    return (
        <PageContainer title="Exam Register">
            <Card title="Add Exam Register">
                <div className="container m-0">
                    <div className="row">
                        <div className="col-4">
                            <label className="mt-3 mb-3">Degree<span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                style={{ width: "100%", marginRight: "20px" }}
                                name="selectedDegree"
                                value={selectedDegreeId}
                                onChange={(e) => setSelectedDegreeId(e.target.value)}
                            >
                                <option value="" selected>Select Degree</option>
                                {selectedDegree.map((course) => (
                                    <option key={course.id} value={course.id}>
                                        {course.name}
                                    </option>
                                ))}
                            </select>
                            {/* Display degree error */}
                            {degreeError && <div className="text-danger">{degreeError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Discipline<span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                style={{ width: "100%", marginRight: "20px", }}
                                name="selectedDiscipline"
                                value={selectedDisciplineId}
                                onChange={(e) => setSelectedDisciplineId(e.target.value)}
                            >
                                <option value="" selected>Select Discipline</option>
                                {selectedDiscipline.map((course) => (
                                    <option key={course.id} value={course.id}>
                                        {course.name}
                                    </option>
                                ))}
                            </select>
                            {/* Display discipline error */}
                            {disciplineError && <div className="text-danger">{disciplineError}</div>}
                        </div>
                        <div className="col-4">
                            <label className="mt-3 mb-3">Semester <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                name="selectedSemester"
                                value={selectedSemesterId}
                                onChange={(e) => setSelectedSemesterId(e.target.value)}
                            >
                                <option value="" selected>
                                    Select Semester
                                </option>
                                {/* {getUniqueSemesters().map((semester) => (
                  <option key={semester} value={semester}>
                    {semester}
                  </option>
                ))} */}
                                <option value='1'>1</option>
                                <option value='2'>2</option>
                                <option value='3'>3</option>
                                <option value='4'>4</option>
                                <option value='5'>5</option>
                                <option value='6'>6</option>
                                <option value='7'>7</option>
                                <option value='8'>8</option>

                            </select>
                            {/* Display semester error */}
                            {semesterError && <div className="text-danger">{semesterError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Batch <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                name="batch"
                                value={batchId}
                                onChange={(e) => setBatchId(e.target.value)}
                            >
                                <option value="" selected>Select Batch</option>
                                {last10Years.map((bt) => (
                                    <option value={bt}>{bt}</option>
                                ))
                                }
                            </select>
                            {/* Display semester error */}
                            {batchIdError && <div className="text-danger">{batchIdError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Exam Name <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                // style={{ width: "30%", marginRight: "20px", }}
                                name="selectedExam"
                                value={selectedExamId}
                                onChange={(e) => setSelectedExamId(e.target.value)}
                            >
                                <option value="" selected>Select Exam</option>
                                {selectedExam.map((course) => (
                                    <option key={course.id} value={course.id}>
                                        {course.title}
                                    </option>
                                ))}
                            </select>
                            {/* Display examId error */}
                            {examIdError && <div className="text-danger">{examIdError}</div>}
                        </div>
                    </div>
                    <div className="m-4 d-flex justify-content-end" >
                        <button className="btn btn-sm  btn-primary" onClick={handleFind}>
                            Find
                        </button>
                    </div>
                </div>
                <CollapseCard title="Exam Register">
                    {isLoading ? (
                        <p>Loading...</p>
                    ) : (
                        <table className="table table-primary table-striped">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Register No</th>
                                    <th>Name</th>
                                    <th>Current Semester</th>
                                    <th>Total Course</th>
                                    <th>Total Fees</th>
                                    {/* <th>Payment Status <span className="text-danger">*</span></th>
                <th>Payment Particulars <span className="text-danger">*</span></th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {tableData && tableData.length > 0 ? (
                                    tableData.map((exam, index) => (
                                        <tr key={exam.id}>
                                            <td>{index + 1}</td>
                                            <td>{exam.registerNo}</td>
                                            <td>{exam.name}</td>
                                            <td>{selectedSemesterId}</td>
                                            <td>{exam.totalCourse}</td>
                                            <td>{exam.totalFees}</td>
                                            {/* <td>
              <select
                    className="form-control"
                    name={`paymentStatus`}
                    value={exam.paymentStatus}
                    onChange={(e) => handlePaymentStatusChange(index, e)}
                  >
                    <option value="">Select</option>
                    <option value="Paid">Paid</option>
                    <option value="Not Paid">Not Paid</option>
                  </select>
              </td>
              <td>
           <input
                    className="form-control"
                    placeholder="Enter Payment Particulars..."
                    type="text"
                    name={`paymentParticulars`}
                    onChange={(e) => handlePaymentParticularsChange(index, e)}
                    value={exam.paymentParticulars}
                  />
              </td> */}
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td>No data available</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                    )}
                </CollapseCard>
                <div className="m-4 d-flex justify-content-end">
                    <button className="btn btn-sm btn-secondary" onClick={clearHandler} style={{ marginRight: '10px' }}>
                        Clear
                    </button>
                    {/* {editFlag ? <button className="btn btn-success" onClick={updateHandler}>Update</button> :} */}
                    <button className="btn btn-sm btn-success" onClick={addHandler}>Add</button>
                </div>
            </Card>


            <br />
            <CollapseCard title="View Exam Register">
                {/* <h4 style={{ textAlign: "left", marginTop: "30px" }}>View Exam Register</h4> */}
                <div className="row m-1">
                    <div className="col-4">
                        <label className="mt-2 mb-2">Degree  : </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px" }}
                            name="selectedDegree"
                            value={degree}
                            onChange={(e) => setDegree(e.target.value)}
                        >
                            <option value="" selected>Select Degree</option>
                            {selectedDegree.map((course) => (
                                <option key={course.id} value={course.id}>
                                    {course.name}
                                </option>
                            ))}
                        </select>
                        {/* Display degree error */}
                        {/* {degreeError && <div className="text-danger">{degreeError}</div>} */}
                    </div>

                    <div className="col-4">
                        <label className="mt-2 mb-2">Discipline : </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px", }}
                            name="selectedDiscipline"
                            value={discipline}
                            onChange={(e) => setDiscipline(e.target.value)}
                        >
                            <option value="" selected>Select Discipline</option>
                            {selectedDiscipline.map((course) => (
                                <option key={course.id} value={course.id}>
                                    {course.name}
                                </option>
                            ))}
                        </select>
                        {/* Display discipline error */}
                        {/* {disciplineError && <div className="text-danger">{disciplineError}</div>} */}
                    </div>
                    <div className="col-4">
                        <label className="mt-2 mb-2">Semester  : </label>
                        <select
                            className="form-control"
                            name="selectedSemester"
                            value={semester}
                            onChange={(e) => setSemester(e.target.value)}
                        >
                            <option value="" selected>
                                Select Semester
                            </option>
                            {/* {getUniqueSemesters().map((semester) => (
                  <option key={semester} value={semester}>
                    {semester}
                  </option>
                ))} */}
                            <option value='1'>1</option>
                            <option value='2'>2</option>
                            <option value='3'>3</option>
                            <option value='4'>4</option>
                            <option value='5'>5</option>
                            <option value='6'>6</option>
                            <option value='7'>7</option>
                            <option value='8'>8</option>
                        </select>
                        {/* Display semester error */}
                        {/* {semesterError && <div className="text-danger">{semesterError}</div>} */}
                    </div>
                    <div className="col-4">
                        <label className="mt-2 mb-2">Batch  : </label>
                        <select
                            className="form-control"
                            name="batch"
                            value={batch}
                            onChange={(e) => setBatch(e.target.value)}
                        >
                            <option value="" selected>Select Batch</option>
                            <option value={'2023'}>2023</option>
                            <option value={'2024'}>2024</option>
                            <option value={'2025'}>2025</option>
                        </select>
                        {/* Display semester error */}
                        {/* {semesterError && <div className="text-danger">{semesterError}</div>} */}
                    </div>
                    <div className="col-4">
                        <label className="mt-2 mb-2">Exam Name  : </label>
                        <select
                            className="form-control"
                            // style={{ width: "30%", marginRight: "20px" }}
                            name="selectedExam"
                            value={exam}
                            onChange={(e) => setExam(e.target.value)}
                        >
                            <option value="" selected>Select Exam</option>
                            {selectedExam.map((course) => (
                                <option key={course.id} value={course.id}>
                                    {course.title}
                                </option>
                            ))}
                        </select>
                        {/* Display examId error */}
                        {/* {examIdError && <div className="text-danger">{examIdError}</div>} */}
                    </div>

                    <div className="m-4 d-flex justify-content-end">
                        <button
                            type="button"
                            className="btn btn-sm btn-primary mt-4 mb-2"
                            style={{ marginRight: '10px' }}
                            onClick={fetchData}
                        >
                            Search
                        </button>

                        <button
                            type="button"
                            className="btn btn-sm btn-secondary mt-4 mb-2"
                            onClick={resetData}
                        >Reset
                        </button>
                    </div>
                </div>
                    <div style={{margin: '0% 5% 0% 0%', float: 'right'}}>
                        {examRegisterList.length > 0 && <div className="text-end mb-3">
                            <button
                                type="button"
                                onClick={handleDownloadPDF}
                                className="m-1 btn btn-sm btn-primary"
                            >
                                <Feather.Download /> PDF
                            </button>
                            <CSVLink
                                data={csvData}
                                filename="details_of_course.csv"
                                className="m-1 btn btn-sm btn-primary"
                            >
                                <Feather.Download /> CSV
                            </CSVLink>
                        </div>
                        }
                  </div>
                {examRegisterList.length !== 0 ?
                    <table className="table table-info table-striped">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Register No</th>
                                <th>Degree</th>
                                <th>Discipline</th>
                                <th>Current Semester</th>
                                <th>Batch</th>
                                <th>Exam </th>
                                <th>Total Course</th>
                                <th>Total Fees</th>
                                <th>Payment Status</th>
                                <th>Payment Particulars</th>
                                {/* <th>Created By</th>
                                <th>Create Timestamp</th> */}
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {examRegisterList?.map((ExamRegister, index) => (
                                <tr key={ExamRegister.id}>
                                    <td>{index + 1}</td>
                                    <td>{ExamRegister.registerNo}</td>
                                    <td>{getDegree(ExamRegister.degreeId)}</td>
                                    <td>{getDiscipline(ExamRegister.disciplineId)}</td>
                                    <td>{ExamRegister.currentSemester}</td>
                                    <td>{ExamRegister.batch}</td>
                                    <td>{getExam(ExamRegister.examId)}</td>
                                    <td>{ExamRegister.totalCourse}</td>
                                    <td>{ExamRegister.totalFees}</td>
                                    <td>{ExamRegister.paymentStatus}</td>
                                    <td>{ExamRegister.paymentParticulars}</td>
                                    {/* <td>{ExamRegister.createdBy}</td>
                                    <td>{ExamRegister.createTimestamp}</td> */}
                                    <td>
                                        <button className="btn btn-sm btn-primary" style={{ marginRight: "10px" }} onClick={() => openEditModel(ExamRegister.id, ExamRegister.registerNo, ExamRegister.currentSemester?.toString(), getExamId(ExamRegister), ExamRegister.totalCourse, ExamRegister.totalFees, ExamRegister.paymentStatus, ExamRegister.paymentParticulars)}>Edit</button>
                                        {/* <button className="btn btn-danger" onClick={() => deleteHandler(ExamRegister.id)}>Delete</button> */}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    : <h5 style={{ textAlign: "center", margin: "10px" }}>No Data found</h5>
                }
                
            </CollapseCard>

            <Modal show={editModelShow} onHide={() => setEditModelShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Exam Register</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-6">
                            <label className="mt-3 mb-3">Register No:</label>
                            <input className="form-control" type="text" value={editRegisterNo} readOnly />
                        </div>

                        <div className="col-6">
                            <label className="mt-3 mb-3">Current Semester:</label>
                            <input className="form-control" type="text" value={editCurrentSemester} readOnly />
                        </div>

                        <div className="col-6">
                            <label className="mt-3 mb-3">Exam:</label>
                            <input className="form-control" type="text" value={editExamId} readOnly />
                        </div>

                        <div className="col-6">
                            <label className="mt-3 mb-3">Total Fees:</label>
                            <input className="form-control" type="text" value={editTotalFees} readOnly />
                        </div>

                        <div className="col-6">
                            <label className="mt-3 mb-3">Total Course:</label>
                            <input className="form-control" type="text" value={editTotalCourse} readOnly />
                        </div>

                        <div className="col-6">
                            <label className="mt-3 mb-3">Payment Status <span className="text-danger">*</span> : </label>
                            <select className="form-control" name="paymentStatus"
                                // style={{width: "30%", marginRight: "20px"}} 
                                onChange={(e) => {
                                    setEditPaymentStatus(e.target.value);
                                    setPaymentStatusError('');
                                }}
                                value={editPaymentStatus}>
                                <option value="">Select</option> {/* Default option */}
                                <option value="Paid">Paid</option>
                                <option value="Not Paid">Not Paid</option>
                            </select>
                            {paymentStatusError && <div className="text-danger">{paymentStatusError}</div>}
                        </div>
                        <div className="col-6">
                            <label className="mt-3 mb-3">Payment Particulars <span className="text-danger">*</span> : </label>
                            <input
                                className="form-control"
                                placeholder="Enter Payment Particulars..."
                                type="text"
                                name="paymentParticulars"
                                onChange={(e) => {
                                    setEditPaymentParticulars(e.target.value);
                                    setPaymentParticularsError('');
                                }}
                                value={editPaymentParticulars}
                            />
                            {paymentParticularsError && <div className="text-danger">{paymentParticularsError}</div>}
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setEditModelShow(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={saveEdit}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteConfirmation} onHide={closeDeleteConfirmation}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this item?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeDeleteConfirmation}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={confirmDeleteHandler}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Add the ToastContainer for displaying toasts
      <ToastContainer position="top-right" /> */}
        </PageContainer>
    );
}

export default ExamRegister

interface ExamRegisterType {
    id: number;
    registerNo: string;
    currentSemester: number;
    contactNumber: number;
    examId: string;
    degreeId: number;
    disciplineId: number;
    semester: string;
    batch: string;
    regulation: number;
    examName: string;
    degreeName: string;
    disciplineName: string;
    dateOfBirth: string;
    totalCourse: string;
    totalFees: string;
    paymentStatus: string;
    paymentParticulars: string;
    disciplineCourseId: string;
    createdBy: string;
    createTimestamp: string;
    name: string;
    title: string;
    shortName: string;
    examFees: string;
    courseId: string;
    disciplineCourseDetails: {
        courseName: string;
        courseCode: string;
    }[];
}

interface DisciplineCourse {
    id: number;
    courseId: string;
    degreeId: string;
    disciplineId: string;
    semesterNo: string;
    examFees: string;
}

interface Exam {
    id: number;
    title: string;
}

interface Student {
    id: number;
    registerNo: string;
    currentSemester: string;
    degreeId: string;
    disciplineId: string;
}

interface Course {
    id: number;
    name: string;
    shortName: string;
}

interface Discipline {
    id: number;
    name: string;
}

interface Degree {
    id: number;
    name: string;
}

