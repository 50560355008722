import { Dispatch } from "redux";
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api";
import { GetState } from "../reducers";

export enum PassingBoardActionList {
    ADD_PASSING_BOARD = 'ADD_PASSING_BOARD',
    FETCH_PASSING_BOARD = 'FETCH_PASSING_BOARD',
    UPDATE_PASSING_BOARD = 'UPDATE_PASSING_BOARD',
    DELETE_PASSING_BOARD = 'DELETE_PASSING_BOARD',
};

export interface PassingBoardType {
    id?: number
    examId: string
    degreeId: string
    disciplineId: string
    batch: string
    semester: string
    disciplineCourseId: string
    adjustingMark: string
}

export interface AddPassingBoardAction {
    type: typeof PassingBoardActionList.ADD_PASSING_BOARD;
    data: PassingBoardType;
}

export interface FetchPassingBoardAction {
    type: typeof PassingBoardActionList.FETCH_PASSING_BOARD;
    data: Array<PassingBoardType>;
}

export interface UpdatePassingBoardAction {
    type: typeof PassingBoardActionList.UPDATE_PASSING_BOARD;
    data: PassingBoardType;
}

export interface DeletePassingBoardAction {
    type: typeof PassingBoardActionList.DELETE_PASSING_BOARD;
    data: number;
}

export type PassingBoardActions =
    | AddPassingBoardAction
    | FetchPassingBoardAction
    | UpdatePassingBoardAction
    | DeletePassingBoardAction;

export const addPassingBoard = (data: PassingBoardType) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!;
        const config = getAxiosRequestConfig(token);

        return api.post<APIResponse<PassingBoardType>>('passingBoard/', data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<AddPassingBoardAction>({
                    type: PassingBoardActionList.ADD_PASSING_BOARD,
                    data: response.data.data
                });
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Passing Board Added');
            } else {
                throw { response };
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add');
        });
    };
};

export const updatePassingBoard = (data: PassingBoardType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!;
        const config = getAxiosRequestConfig(token);

        return api.put<APIResponse<PassingBoardType>>(`passingBoard/?id=${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<UpdatePassingBoardAction>({
                    type: PassingBoardActionList.UPDATE_PASSING_BOARD,
                    data: response.data.data
                });
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Passing Board Updated');
            } else {
                throw { response };
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update');
        });
    };
};

export const fetchPassingBoard = () => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!;
        const config = getAxiosRequestConfig(token);

        return api.get<APIResponse<PassingBoardType[]>>('passingBoard/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchPassingBoardAction>({
                    type: PassingBoardActionList.FETCH_PASSING_BOARD,
                    data: response.data.data
                });
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successful');
            } else {
                throw { response };
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch');
        });
    };
};

export const deletePassingBoard = (id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!;
        const config = getAxiosRequestConfig(token);

        return api.delete<APIResponse<PassingBoardType>>(`passingBoard/?id=${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeletePassingBoardAction>({
                    type: PassingBoardActionList.DELETE_PASSING_BOARD,
                    data: id
                });
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Passing Board Deleted');
            } else {
                throw { response };
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete');
        });
    };
};
