import { Dispatch } from "redux";
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api";
import { GetState } from "../reducers";

// Define action types
export enum ExamAttendanceActionsList {
  ADD_EXAM_ATTENDANCE = 'ADD_EXAM_ATTENDANCE',
  FETCH_EXAM_ATTENDANCE = 'FETCH_EXAM_ATTENDANCE',
  UPDATE_EXAM_ATTENDANCE = 'UPDATE_EXAM_ATTENDANCE',
  DELETE_EXAM_ATTENDANCE = 'DELETE_EXAM_ATTENDANCE',
};

// Define examAttendance data type
export interface ExamAttendanceType {
  id?: number;
  examId: number;
  degreeId: number;
  disciplineId: number;
  batch: string;
  registerNo: string;
  name?:string;
  date: string;
  session: string;
  disciplineCourseId: number;
  answerBookNo: string;
  attendance: string;
}

// Define action interfaces
export interface AddExamAttendanceAction {
  type: typeof ExamAttendanceActionsList.ADD_EXAM_ATTENDANCE;
  data: ExamAttendanceType;
}

export interface FetchExamAttendanceAction {
  type: typeof ExamAttendanceActionsList.FETCH_EXAM_ATTENDANCE;
  data: Array<ExamAttendanceType>;
}

export interface UpdateExamAttendanceAction {
  type: typeof ExamAttendanceActionsList.UPDATE_EXAM_ATTENDANCE;
  data: ExamAttendanceType;
}

export interface DeleteExamAttendanceAction {
  type: typeof ExamAttendanceActionsList.DELETE_EXAM_ATTENDANCE;
  data: number;
}

// Define combined action type
export type ExamAttendanceActions =
  | AddExamAttendanceAction
  | FetchExamAttendanceAction
  | UpdateExamAttendanceAction
  | DeleteExamAttendanceAction;

// Action creators
export const addExamAttendance = (data: ExamAttendanceType[]) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authUser.token!;
    const config = getAxiosRequestConfig(token);

    return api.post<APIResponse<ExamAttendanceType>>('examAttendance/', data, config).then((response:any) => {
      if (response.status === 200 && response.data.data) {
        dispatch<AddExamAttendanceAction>({
          type: ExamAttendanceActionsList.ADD_EXAM_ATTENDANCE,
          data: response.data.data
        });
        return Promise.resolve(response.data.message
          ? response.data.message
          : 'Exam Attendance Added');
      } else {
        throw { response };
      }
    }).catch((error:any) => {
      return Promise.reject(error.response
        ? error.response.data.message
          ? error.response.data.message
          : 'Unable to add'
        : 'Unable to add');
    });
  };
};

export const updateExamAttendance = (data: ExamAttendanceType, id: number) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authUser.token!;
    const config = getAxiosRequestConfig(token);

    return api.put<APIResponse<ExamAttendanceType>>(`examAttendance/?id=${id}`, data, config).then((response:any) => {
      if (response.status === 200 && response.data.data) {
        dispatch<UpdateExamAttendanceAction>({
          type: ExamAttendanceActionsList.UPDATE_EXAM_ATTENDANCE,
          data: response.data.data
        });
        return Promise.resolve(response.data.message
          ? response.data.message
          : 'Exam Attendance Updated');
      } else {
        throw { response };
      }
    }).catch((error:any)=> {
      return Promise.reject(error.response
        ? error.response.data.message
          ? error.response.data.message
          : 'Unable to update'
        : 'Unable to update');
    });
  };
};

export const fetchExamAttendance = () => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authUser.token!;
    const config = getAxiosRequestConfig(token);

    return api.get<APIResponse<ExamAttendanceType[]>>('examAttendance/', config).then((response:any)=> {
      if (response.status === 200 && response.data.data) {
        dispatch<FetchExamAttendanceAction>({
          type: ExamAttendanceActionsList.FETCH_EXAM_ATTENDANCE,
          data: response.data.data
        });
        return Promise.resolve(response.data.message
          ? response.data.message
          : 'Fetch Successful');
      } else {
        throw { response };
      }
    }).catch((error:any) => {
      return Promise.reject(error.response
        ? error.response.data.message
          ? error.response.data.message
          : 'Unable to fetch'
        : 'Unable to fetch');
    });
  };
};

export const deleteExamAttendance = (id: number) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const token = getState().authUser.token!;
    const config = getAxiosRequestConfig(token);

    return api.delete<APIResponse<ExamAttendanceType>>(`examAttendance/?id=${id}`, config).then((response:any) => {
      if (response.status === 200) {
        dispatch<DeleteExamAttendanceAction>({
          type: ExamAttendanceActionsList.DELETE_EXAM_ATTENDANCE,
          data: id
        });
        return Promise.resolve(response.data.message
          ? response.data.message
          : 'Exam Attendance Deleted');
      } else {
        throw { response };
      }
    }).catch((error:any) => {
      return Promise.reject(error.response
        ? error.response.data.message
          ? error.response.data.message
          : 'Unable to delete'
        : 'Unable to delete');
    });
  };
};
