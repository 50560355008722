
import { ExamFacultyActionList, ExamFacultyType } from "../actions/examFacultyAction";
import { Action } from "../actions/types";

const initialValue: Array<ExamFacultyType> = [];

const examFacultyReducer = (state: Array<ExamFacultyType> = initialValue, action: Action) => {
    switch (action.type) {
        case ExamFacultyActionList.ADD_EXAMFACULTY:
            return [
                ...state,
                action.data,
            ];

        case ExamFacultyActionList.UPDATE_EXAMFACULTY:
            const updated = [...state].slice();
            const index = updated.findIndex(u => u.id === action.data.id);

            if (index > -1) {
                updated[index] = {
                    ...action.data,
                };
            }

            return updated;

        case ExamFacultyActionList.FETCH_EXAMFACULTY:
            return action.data;

        case ExamFacultyActionList.DELETE_EXAMFACULTY:
            return state.filter(s => s.id !== action.data);

        default:
            return state;
    }
}

export default examFacultyReducer;



