import axios from 'axios';
import { FC, useEffect, useState } from 'react';
import Card from '../components/custom/Card';
import CollapseCard from '../components/custom/CollapseCard';
import PageContainer from '../components/custom/PageContainer';
// import './styles/tableReport.css';

import jsPDF from 'jspdf';
import autoTable, { RowInput } from 'jspdf-autotable';
import * as Feather from 'react-feather';
import { toast } from 'react-toastify';
import { apiUrl } from '../config';
import imgPath from './img/collegeLogo.png';

interface Props {
    title: string;
}

const CoverReport: FC<Props> = (props: Props) => {
    const { title } = props;
    const fileName = 'cover_report';

    const [editFlag, setEditFlag] = useState(false);
    const [examId, setExamId] = useState<string>('');
    const [date, setDate] = useState<string>('');
    const [session, setSession] = useState<string>('');
    const [degreeId, setDegreeId] = useState<string>('');
    const [disciplineId, setDisciplineId] = useState<string>('');
    const [disciplineCourseId, setDisciplineCourseId] = useState<string>('');
    const [questionPaperCode, setQuestionPaperCode] = useState<string>('');
    const [seqNo, setSeqNo] = useState<string>('');
    const [batch, setBatch] = useState<string>('');
    const [last10Years, setLast10Years] = useState<number[]>([]);
    const [searchPerformed, setSearchPerformed] = useState(false);

    const [selectedExam, setSelectedExam] = useState<Exam[]>([]);
    const [selectedDegree, setSelectedDegree] = useState<Degree[]>([]);
    const [selectedDiscipline, setSelectedDiscipline] = useState<Discipline[]>([]);
    const [selectedCourse, setSelectedCourse] = useState<Course[]>([]);
    const [courseList, setCourseList] = useState<DisciplineCourse[]>([]);
    const [examAttendanceList, setExamAttendanceList] = useState<ExamAttendance[]>([]);
    const [filteredExamAttendanceList, setFilteredExamAttendanceList] = useState<ExamAttendance[]>([]);
    const [packingList, setPackingList] = useState<Packing[]>([]);
    const [filteredPackingList, setFilteredPackingList] = useState<Packing[]>([]);


    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const years = Array.from({ length: 10 }, (_, index) => currentYear - index);
        setLast10Years(years);
    }, []);

    useEffect(() => {
        axios.get(`${apiUrl}/exam/`)
            .then((response) => {
                setSelectedExam(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching degree', error);
            });
    }, [])

    useEffect(() => {
        axios.get(`${apiUrl}/degree/`)
            .then((response) => {
                setSelectedDegree(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching degree', error);
            });
    }, [])

    useEffect(() => {
        axios.get(`${apiUrl}/discipline/`)
            .then((response) => {
                setSelectedDiscipline(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching discipline', error);
            });
    }, [])

    useEffect(() => {
        axios.get(`${apiUrl}/course/`)
            .then((response) => {
                setSelectedCourse(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching discipline', error);
            });
    }, [])

    useEffect(() => {
        axios.get(`${apiUrl}/disciplineCourse/`)
            .then((response) => {
                setCourseList(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching discipline', error);
            });
    }, [])

    useEffect(() => {
        axios.get(`${apiUrl}/packingGalley/`)
            .then((response) => {
                setPackingList(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching PackingGalley', error);
            });
    }, [])

   
    
    let disCourseId = courseList.find(dc => dc.disciplineId == disciplineId && dc.degreeId == degreeId && dc.courseId == disciplineCourseId)?.id;
    

    const filterSeatingPlans = () => {
        if (degreeId !== '' && disciplineId !== '' && disciplineCourseId !== '' && examId !== '' && date !== '' && session !== '') {
            try {
                
                let filteredPlans = packingList.filter(pl => 
                     pl.degreeId == degreeId  &&
                     pl.disciplineId == disciplineId &&
                     parseInt(pl.disciplineCourseId) === disCourseId  &&
                     pl.examId == examId &&
                     pl.date == date &&
                     pl.session == session
                );
  
                setFilteredPackingList(filteredPlans);
                setSearchPerformed(true);
            } catch (error) {
                console.error('Error fetching data', error);
                toast.error('Error fetching data', { position: 'top-right', autoClose: 3000 });
            }
        } else {
            toast.error('Select All Filters', { position: 'top-right', autoClose: 3000 });
            setFilteredPackingList([]);
        }
    };
    


    const renderDownloadButtons = () => {
        if (searchPerformed) {
            return (
                <div className="text-end mb-3">
                    <button
                        type="button"
                        onClick={handleDownloadPDF}
                        className="m-1 btn btn-sm btn-primary"
                    >
                        <Feather.Download /> PDF
                    </button>
                </div>
            );
        }
        return null;
    };

    const getCourse = (courseId: string) => {
        const course = selectedCourse.find((c) => c.id == parseInt(courseId));
        if (course) {
            return `${course.shortName} - ${course.name}`;
        }

        return 'N/A';
    };

    const getCourseCode = (courseId: string) => {
        const course = selectedCourse.find((c) => c.id == parseInt(courseId));
        if (course) {
            return `${course.shortName}`;
        }

        return 'N/A';
    };

    const getExamName = (examId: string) => {
        const exam = selectedExam.find((c) => c.id == parseInt(examId));
        if (exam) {
            return exam.title;
        }
        return 'N/A';
    }


    const getDegreeName = (degreeId: string) => {
        const degree = selectedDegree.find((c) => c.id == parseInt(degreeId));
        if (degree) {
            return degree.name;
        }
        return 'N/A';
    }


    const getDisciplineName = (disciplineId: string) => {
        const discipline = selectedDiscipline.find((c) => c.id == parseInt(disciplineId));
        if (discipline) {
            return discipline.name;
        }
        return 'N/A';
    }


    const getCourseName = (courseId: string) => {
        const disciplineCourse = courseList.find((dc) => dc.id === parseInt(courseId));
        if (disciplineCourse) {
            const course = selectedCourse.find((c) => c.id == parseInt(disciplineCourse.courseId));
            if (course) {
                return course.name;
            }
        }
        return 'N/A';
    };

    const formatDate = (inputDate: string) => {
        const date = new Date(inputDate);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedMonth = month < 10 ? `0${month}` : month;

        return `${formattedDay}-${formattedMonth}-${year}`;
    };

    const LocalFormatDate = (date: string) => {
        const dateObj = new Date(date)
        const day = dateObj.getDate().toString().padStart(2, '0')
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0')
        const year = dateObj.getFullYear()

        return [day, month, year].join('/')//02-12-2023
    }


    const handleDownloadPDF = () => {
        const doc = new jsPDF('l', 'mm', 'a4');
        filteredPackingList.forEach((row, index) => {
            if (index !== 0) {
                doc.addPage();
            }

            const headerRows: RowInput[] = [];
            headerRows.push(
                [
                    { content: `Course : ${getCourse(disciplineCourseId)}`, styles: { halign: "center" } },
                    { content: `Exam Date : ${formatDate(date)} / ${session} ${session == 'FN' ? "(10 AM – 1 PM)" : "(2 PM - 5 PM)"}`, styles: { halign: "center" } }
                ],
                [
                    { content: `Question Paper Code : ${row.questionPaperCode}`, styles: { halign: "left", cellPadding: { left: 25.3 } } }
                ]
            )
            autoTable(doc, {
                didDrawPage: (data) => {
                    doc.rect(10, 10, 30, 15);
                    doc.addImage(imgPath, 'PNG', 50, 5, 200, 40);

                    doc.setFontSize(14);
                    doc.setFont('Arial', 'normal', 'bold');
                    doc.setTextColor(0, 0, 0);
                    doc.text(`${getExamName(examId)}`, 150, 45, { align: 'center' });
                    doc.setFontSize(16);
                    doc.text(title, 150, 53, { align: 'center' });
                },
                startY: 57,
                tableLineColor: 'black',
                tableLineWidth: 0.3,
                headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 12, valign: 'middle', halign: 'center' },
                bodyStyles: { textColor: [0, 0, 0], fontSize: 10, halign: 'center', fontStyle: "bold" },
                head: headerRows,
                theme: 'plain',
            })


            const presentRegisterNos = row.presentList.split(',').map(registerNo => registerNo.trim());
            const absentRegisterNos = row.absentList.split(',').map(registerNo => registerNo.trim());
            const malpracticeRegisterNos = row.malpracticeList.split(',').map(registerNo => registerNo.trim());
            const specialCaseRegisterNos = row.specialCaseList.split(',').map(registerNo => registerNo.trim());

            const allRegisterNos = [...presentRegisterNos, ...absentRegisterNos].sort().filter(item => item != '');
            const cellData = allRegisterNos.map(registerNo => {
                const isMalpractice = malpracticeRegisterNos.includes(registerNo);
                const isSpecialCase = specialCaseRegisterNos.includes(registerNo);
                const isAbsent = absentRegisterNos.includes(registerNo);
                const isPresent = presentRegisterNos.includes(registerNo);

                if (isMalpractice && isPresent) {
                    return { content: `${registerNo} (MP)` };
                } else if (isSpecialCase) {
                    return { content: `${registerNo} (SC)` };
                } else if (isAbsent) {
                    return { content: `${registerNo} (AB)` };
                } else {
                    return { content: registerNo };
                }
            });
            const numberOfRows = filteredPackingList.length > 5 ? filteredPackingList.length : 5;
            const numberOfColumns = 5;

            const tableData = [];
            for (let i = 0; i < numberOfRows; i++) {
                const rowData = [];
                for (let j = 0; j < numberOfColumns; j++) {
                    const dataIndex = i * numberOfColumns + j;
                    rowData.push(cellData[dataIndex] || { content: '', styles: { halign: 'left' } });

                }

                tableData.push(rowData);
            }

            function count(list: any) {
                return list.split(',').map((item: any) => item.trim()).filter(Boolean).length;
            }

            autoTable(doc, {
                startY: 75,
                margin: { left: 30 },
                bodyStyles: { textColor: [0, 0, 0], fontSize: 10, halign: 'left', fontStyle: "bold" },
                body: tableData,
                footStyles: { cellPadding: { top: 0 } },
                foot: [
                    [
                        { content: `Total: ${count(row.absentList) + count(row.presentList)}  (AB: ${count(row.absentList)} MP: ${count(row.malpracticeList)} SP: 0 SC: ${count(row.specialCaseList)}) Present: ${count(row.presentList)}`, colSpan: 12, styles: { halign: 'right' } }
                    ]
                ],
                theme: 'plain',

            });

            const styles = {
                valign: 'top'
            }

            // autoTable(doc, {
            //     startY: (doc as any).lastAutoTable.finalY - 10,
            //     margin: { left: 30 },
            //     // styles:styles,
            //     headStyles: { textColor: [0, 0, 0], fontSize: 10, halign: 'left', fontStyle: "bold" },
            //     head: [[
            //         { content: `Total: ${count(row.absentList) + count(row.presentList)}  (AB: ${count(row.absentList)} MP: ${count(row.malpracticeList)} SP: 0 SC: ${count(row.specialCaseList)}) Present: ${count(row.presentList)}`, colSpan: 12, styles: { halign: 'right' } }
            //     ]],

            //     theme: 'plain',
            // });


            // let yPos = (doc as any).lastAutoTable.finalY + 1;
            // autoTable(doc, {
            //     tableLineColor: 'black',
            //     tableLineWidth: 0.3,
            //     tableWidth: 33,
            //     margin: { left: 250 },
            //     startY: yPos,
            //     foot: [
            //         [
            //             { content: `Cover No. ${row.code}`, styles: { halign: 'right' }, colSpan: 12 }
            //         ]
            //     ],
            //     theme: 'plain',

            // });
            const footRows: RowInput[] = [];
            footRows.push(
                // [
                //     { content: '', colSpan: 12 }
                [
                    { content: 'STATION: PITS' },
                    { content: 'Signature of University Representative' },
                    { content: 'Signature of the Chief Superintendent' },
                    { content: `(COLLEGE SEAL)` }

                ],
                [
                    { content: `Date:${LocalFormatDate(date)}`, colSpan: 8, styles: { cellPadding: { bottom: 4, left: 2 } } },
                ],

            );
            autoTable(doc, {
                tableLineColor: 'black',
                tableLineWidth: 0.3,
                startY: (doc as any).lastAutoTable.finalY + 60,
                foot: footRows,
                theme: 'plain',
            });

        });
        // doc.addPage();

       
        doc.save('cover_report.pdf');
    };


    return (
        <PageContainer title="Cover Report">
            <Card title={editFlag ? "Edit Packing Galley" : "Filters"}>
                <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-6">
                        <label className="mt-2 mb-2">Exam <span className="text-danger">*</span> : </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px", }}
                            name="exam"
                            value={examId}
                            onChange={(e) => setExamId(e.target.value)}
                        >
                            <option value="" selected>Select Exam</option>
                            {selectedExam.map((course) => (
                                <option key={course.id} value={course.id}>
                                    {course.title}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-6">
                        <label className="mt-2 mb-2">Date <span className="text-danger">*</span> : </label>
                        <input
                            style={{ width: "100%", marginRight: "20px", }}
                            className="form-control"
                            placeholder="Enter date..."
                            type="date"
                            name="date"
                            onChange={(e) => setDate(e.target.value)}
                            value={date}
                        />
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-6">
                        <label className="mt-2 mb-2">Session <span className="text-danger">*</span> : </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px", }}
                            name="session"
                            value={session}
                            onChange={(e) => setSession(e.target.value)}
                        >
                            <option value="" selected>Select Session</option>
                            <option value="AN" selected>AN</option>
                            <option value="FN" selected>FN</option>
                        </select>
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-6">
                        <label className="mt-2 mb-2">Batch <span className="text-danger">*</span> : </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px" }}
                            name="selectedBatch"
                            value={batch}
                            onChange={(e) => setBatch(e.target.value)}
                        >
                            <option value="" selected>Select Batch</option>
                            {last10Years.map((year) => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6">
                        <label className="mt-2 mb-2">Degree <span className="text-danger">*</span> : </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px" }}
                            name="selectedDegree"
                            value={degreeId}
                            onChange={(e) => setDegreeId(e.target.value)}
                        >
                            <option value="" selected>Select Degree</option>
                            {selectedDegree.map((course) => (
                                <option key={course.id} value={course.id}>
                                    {course.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-6">
                        <label className="mt-2 mb-2">Discipline <span className="text-danger">*</span> : </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px", }}
                            name="selectedDiscipline"
                            value={disciplineId}
                            onChange={(e) => setDisciplineId(e.target.value)}
                        >
                            <option value="" selected>Select Discipline</option>
                            {selectedDiscipline.map((course) => (
                                <option key={course.id} value={course.id}>
                                    {course.name}
                                </option>
                            ))}
                        </select>
                    </div>


                    <div className="col-lg-3 col-md-3 col-sm-6">
                        <label className="mt-2 mb-2">Course <span className="text-danger">*</span> : </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px" }}
                            name="disciplineCourseId"
                            value={disciplineCourseId}
                            onChange={(e) => setDisciplineCourseId(e.target.value)}
                        >
                            <option value="" selected>Select Course</option>
                            {selectedCourse.map((course) => (
                                <option key={course.id} value={course.id}>
                                    {course.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col-12">
                        <button
                            type="button"
                            className="btn btn-sm btn-primary"
                            onClick={filterSeatingPlans}
                            style={{ width: "10%", marginTop: "20px", marginBottom: "10px", float: "right" }}
                        >
                            Search
                        </button>
                    </div>
                </div>

            </Card>
            <CollapseCard title="Cover Report">
                <div className="container-fluid" style={{ width: '150%' }}>
                    <table className="table table-success table-striped">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Code</th>
                                <th>Exam</th>
                                <th>Date</th>
                                <th>Session</th>
                                <th>Degree</th>
                                <th>Discipline</th>
                                <th>Discipline Course</th>
                                <th>Question Paper Code</th>
                                <th>Seq No</th>
                                <th>Present List</th>
                                <th>Absent List</th>
                                <th>Malpractice List</th>
                                <th>Special Case List</th>
                                <th>Special Packing List</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredPackingList.map((row, index) => (
                                <tr key={row.id}>
                                    <td>{index + 1}</td>
                                    <td>{row.code}</td>
                                    <td>{getExamName(row.examId)}</td>
                                    <td>{formatDate(row.date)}</td>
                                    <td>{row.session}</td>
                                    <td>{getDegreeName(row.degreeId)}</td>
                                    <td>{getDisciplineName(row.disciplineId)}</td>
                                    <td>{getCourseName(row.disciplineCourseId)}</td>
                                    <td>{row.questionPaperCode}</td>
                                    <td>{row.seqNo}</td>
                                    <td>{row.presentList}</td>
                                    <td>{row.absentList}</td>
                                    <td>{row.malpracticeList}</td>
                                    <td>{row.specialCaseList}</td>
                                    <td>{row.specialPackingList}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div>
                        {renderDownloadButtons()}
                    </div>
                </div>
            </CollapseCard>

        </PageContainer>
    );
}

export default CoverReport;

interface Discipline {
    id: number;
    name: string;
}

interface Course {
    id: number;
    name: string;
    shortName: string;
}

interface Degree {
    id: number;
    name: string;
}

interface DisciplineCourse {
    id: number;
    degreeId: string;
    disciplineId: string;
    courseId: string;
}

interface Exam {
    id: number;
    title: string;
}

interface ExamAttendance {
    id: number;
    examId: string;
    degreeId: string;
    disciplineId: string;
    batch: string;
    registerNo: string;
    date: string;
    session: string;
    disciplineCourseId: string;
    answerBookNo: string;
    attendance: string;
}

interface Packing {
    id: number;
    code: string;
    examId: string;
    date: string;
    session: string;
    degreeId: string;
    disciplineId: string;
    disciplineCourseId: string;
    questionPaperCode: string;
    seqNo: string;
    presentList: string;
    absentList: string;
    malpracticeList: string;
    specialCaseList: string;
    specialPackingList: string;
}
