/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { GetState } from "../reducers"

export enum PhaseActionList {
    ADD_PHASE = 'ADD_PHASE',
    FETCH_PHASE = 'FETCH_PHASE',
    UPDATE_PHASE = 'UPDATE_PHASE',
    DELETE_PHASE = 'DELETE_PHASE',
}

export interface PhaseType {
    id?: number
    title: string
    fromDate:string
    toDate:string
    batch:string
    semester:number
}

export interface AddPhaseAction {
    type: PhaseActionList.ADD_PHASE
    data: PhaseType
}

export interface FetchPhaseAction {
    type: PhaseActionList.FETCH_PHASE
    data: Array<PhaseType>
}

export interface UpdatePhaseAction {
    type: PhaseActionList.UPDATE_PHASE
    data: PhaseType
}

export interface DeletePhaseAction {
    type: PhaseActionList.DELETE_PHASE
    data: number
}

export type PhaseActions = AddPhaseAction | FetchPhaseAction | UpdatePhaseAction | DeletePhaseAction

export const addPhase = (data: PhaseType) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.post<APIResponse<PhaseType>>('phase/', data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<AddPhaseAction>({
                    type: PhaseActionList.ADD_PHASE,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Phase Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })
    }
}

export const updatePhase = (data: PhaseType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.put<APIResponse<PhaseType>>(`phase/?id=${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<UpdatePhaseAction>({
                    type: PhaseActionList.UPDATE_PHASE,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Phase Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchPhase = () => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<PhaseType[]>>('phase/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchPhaseAction>({
                    type: PhaseActionList.FETCH_PHASE,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deletePhase = (id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.delete<APIResponse<PhaseType>>(`phase/?id=${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeletePhaseAction>({
                    type: PhaseActionList.DELETE_PHASE,
                    data: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Phase Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}
