import axios from "axios";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { FC, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import * as Feather from 'react-feather';
import { toast } from "react-toastify";
import { LocalFormatDate } from "../components/common/functions";
import Card from "../components/custom/Card";
import CollapseCard from "../components/custom/CollapseCard";
import PageContainer from "../components/custom/PageContainer";
import { apiUrl } from '../config';
import imgPath from './img/collegeLogo.png';

interface Props {
    title: string;
}

export const SeatingArrangement: FC<Props> = (props: Props) => {
    const { title } = props;
    const fileName = "seating_arranagement";

    const [seatPlan, setSeatPlan] = useState<HallPlan[]>([])

    console.log(seatPlan);
    


    const [examName, setExamName] = useState<Exam[]>([])
    const [exam, setExam] = useState<string>('')

    const [date, setDate] = useState<string>('')
    const [session, setSession] = useState<string>('')

    const [hallName, setHallName] = useState<Hall[]>([])
    const [hall, setHall] = useState<string>('')

    // console.log(examName);
    // console.log(date);
    // console.log(session);

    // console.log(hall);

    // console.log(hallName);

    useEffect(() => {
        axios.get(`${apiUrl}/exam/`)
            .then((response) => {
                setExamName(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching discipline course', error);
            });
    }, [])


    useEffect(() => {
        axios.get(`${apiUrl}/hall/`)
            .then((response) => {
                setHallName(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching discipline course', error);
            });
    }, [])


    // useEffect(() => {
    //     axios.get(`${apiUrl}/hallSeatingPlan/?examId=${exam}&date=${date}&session=${session}&hallId=${hall}`)
    //         .then((response) => {
    //             setHallName(response.data.data);
    //         })
    //         .catch((error) => {
    //             console.error('Error fetching discipline course', error);
    //         });
    // }, [])

    // console.log(seatPlan);


    const handleSearch = () => {

        if (exam !== "" && date !== "" && session !== "" && hall !== "") {
            axios.get(`${apiUrl}/hallSeatingPlan/?examId=${exam}&date=${date}&session=${session}&hallId=${hall}`)
                .then((response) => {
                    if (response.data.error) {
                        setSeatPlan([]);
                        toast.error('No record found')
                        return
                    }
                    setSeatPlan(response.data.data);
                })
                .catch((error) => {
                    console.error('Error fetching discipline course', error);
                });
        }

    }

    const studentList = seatPlan.map((sp) => sp.studentList.split(','));
    console.log(seatPlan);
    console.log(studentList);

    let oddIndex = 0;
    let evenIndex = 0;
    const oddList = studentList[0] ? studentList[0] : []
    const evenList = studentList[1] ? studentList[1] : [];

    if (studentList.length > 2) {
        for (let index = 2; index < studentList.length; index++) {
            // const element = array[index];
            if (oddList.length > evenList.length) {
                studentList[index].forEach(si => {
                    evenList.push(si)
                })
            } else {
                studentList[index].forEach(si => {
                    oddList.push(si)
                })
            }
        }
    }

    const suffledList: { seatNumber: number, regNumber: string }[] = [];

    // console.log(suffledList);
    // console.log(oddList);
    // console.log(evenList);

    for (let i = 0; i < (oddList?.length + evenList?.length); i++) {
        if (oddList.length > evenList.length) {
            if (i % 2 === 0) {
                suffledList.push({ seatNumber: i, regNumber: oddList[oddIndex] })
                oddIndex++;
            }
            else {
                if (evenList[evenIndex]) {
                    suffledList.push({ seatNumber: i, regNumber: evenList[evenIndex] })
                    evenIndex++;
                }
                else if (!evenList[evenIndex]) {
                    suffledList.push({ seatNumber: i, regNumber: oddList[oddIndex] })
                    oddIndex++;
                }
            }
        }
        else if (oddList.length < evenList.length) {
            if (i % 2 === 0) {
                suffledList.push({ seatNumber: i, regNumber: evenList[evenIndex] })
                evenIndex++;
            }
            else {
                if (oddList[oddIndex]) {
                    suffledList.push({ seatNumber: i, regNumber: oddList[oddIndex] })
                    oddIndex++;
                }
                else if (!oddList[oddIndex]) {
                    suffledList.push({ seatNumber: i, regNumber: evenList[evenIndex] })
                    evenIndex++;
                }
            }
        }
        else {
            if (i % 2 === 0) {
                suffledList.push({ seatNumber: i, regNumber: evenList[evenIndex] })
                evenIndex++;
            }
            else {
                suffledList.push({ seatNumber: i, regNumber: oddList[oddIndex] })
                oddIndex++;
            }

        }

    }

    let totalSeat = 25;

    let seatCount = 0;



    const hallTitle = hallName?.find((hl) => hl.id?.toString() === hall?.toString())?.name
    const hallCapacity = hallName?.find((hl) => hl.id?.toString() === hall?.toString())?.seatingCapacity
    const examTitle = examName?.find((et) => et.id?.toString() === exam?.toString())
    const examTitleName = examTitle?.title

    const perRowCount = hallCapacity ? Math.round(parseInt(hallCapacity) / 4) : 5
    let total = 0;

    const handleDownloadPDF = () => {
        const doc = new jsPDF('p', 'mm', 'a4');

        doc.addImage(imgPath, 'PNG', 20, 10, 170, 40);
        doc.setFontSize(13);
        doc.setFont('Arial', 'normal', 'bold')
        doc.text(`${examTitleName}`, 75, 50);
        doc.text('Seating Arrangement', 80, 57)

        doc.setFontSize(12);
        doc.text(`Venue:${hallTitle}`, 15, 67)
        doc.text(`DATE: ${LocalFormatDate(date)} ${session}`, 150, 67)

        console.log(hallCapacity);

        if (hallCapacity?.toString() === '26') {
            //1
            autoTable(doc, {
                startY: 73,
                headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0] },
                bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0], halign: 'center' },
                head: [
                    [
                        { content: "Seat No.", styles: { cellWidth: 15 } },
                        { content: "Reg.No.", styles: { halign: 'center' } }
                    ]

                ],
                body: suffledList.filter((reg, ind) => ind < 5).map((item, ind) => {
                    seatCount++;
                    let data = [{ content: item.seatNumber + 1 },
                    { content: item.regNumber }]
                    return data
                }),
                // tableLineWidth: 0.5,
                theme: 'grid',
                tableLineColor: "black",
                tableWidth: 45,
                margin: { left: 15 }
            })
            //2
            autoTable(doc, {
                startY: 73,
                headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0] },
                bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0], halign: 'center' },
                head: [
                    [
                        { content: "Seat No.", styles: { cellWidth: 15 } },
                        { content: "Reg.No.", styles: { halign: 'center' } }
                    ]

                ],
                body: suffledList.filter((reg, ind) => ind > 4 && ind < 12).map((item, ind) => {
                    seatCount++;
                    let data = [{ content: item.seatNumber + 1 },
                    { content: item.regNumber }]
                    return data
                }),
                // tableLineWidth: 0.5,
                theme: 'grid',
                tableLineColor: "black",
                tableWidth: 45,
                margin: { left: 60 }
            })

            const secondTableY = (doc as any).lastAutoTable.finalY;
            //3
            autoTable(doc, {
                startY: 73,
                headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0] },
                bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0], halign: 'center' },
                head: [
                    [
                        { content: "Seat No.", styles: { cellWidth: 15 } },
                        { content: "Reg.No.", styles: { halign: 'center' } }
                    ]

                ],
                body: suffledList.filter((reg, ind) => ind > 11 && ind < 19).map((item, ind) => {
                    let data = [{ content: item.seatNumber + 1 },
                    { content: item.regNumber }]
                    return data
                }),
                // tableLineWidth: 0.5,
                theme: 'grid',
                tableLineColor: "black",
                tableWidth: 45,
                margin: { left: 105 }
            })
            //4
            autoTable(doc, {
                startY: 73,
                headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0] },
                bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0], halign: 'center' },
                head: [
                    [
                        { content: "Seat No.", styles: { cellWidth: 15 } },
                        { content: "Reg.No.", styles: { halign: 'center' } }
                    ]

                ],
                body: suffledList.filter((reg, ind) => ind > 18 && ind < 26).map((item, ind) => {
                    let data = [{ content: item.seatNumber + 1 },
                    { content: item.regNumber }]
                    return data
                }),
                // tableLineWidth: 0.5,
                theme: 'grid',
                tableLineColor: "black",
                tableWidth: 45,
                margin: { left: 150 }
            })
            // Total table
            autoTable(doc, {
                startY: secondTableY + 60,
                headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0] },
                bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0], halign: 'center' },
                // head: [
                //     [
                //         { content: 'CSE' },
                //         { content: `ECE` },
                //         { content: "TOTAL" },
                //     ]
                // ],
                body: [
                    ...seatPlan.map((sp) => {

                        console.log(sp.count);

                        console.log(total);

                        total = parseInt(total + sp.count)

                        return [
                            sp.degree + '-' + sp.discipline, sp.count
                        ]
                    }),
                    [
                        // 'Total', total
                        { content: `TOTAL`, styles: { fontStyle: 'bold', fontSize: 11 } },
                        { content: total, styles: { fontStyle: 'bold', fontSize: 11 } }
                    ],
                ],

                // tableLineWidth: 0.5,
                theme: 'grid',
                tableLineColor: "black",
                tableWidth: 70,
            })
        }
        else {
            //52 seating
            //1
            autoTable(doc, {
                startY: 73,
                headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0] },
                bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0], halign: 'center' },
                head: [
                    [
                        { content: "Seat No.", styles: { cellWidth: 15 } },
                        { content: "Reg.No.", styles: { halign: 'center' } }
                    ]
                ],
                body: suffledList.filter((reg, ind) => ind < 13).map((item, ind) => {
                    seatCount++;
                    let data = [{ content: item.seatNumber + 1 },
                    { content: item.regNumber }]
                    return data
                }),
                // tableLineWidth: 0.5,
                theme: 'grid',
                tableLineColor: "black",
                tableWidth: 45,
                margin: { left: 15 }
            })

            const firstTableY = (doc as any).lastAutoTable.finalY;

            //2
            autoTable(doc, {
                startY: 73,
                headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0] },
                bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0], halign: 'center' },
                head: [
                    [
                        { content: "Seat No.", styles: { cellWidth: 15 } },
                        { content: "Reg.No.", styles: { halign: 'center' } }
                    ]

                ],
                body: suffledList.filter((reg, ind) => ind > 12 && ind < 26).map((item, ind) => {
                    seatCount++;
                    let data = [{ content: item.seatNumber + 1 },
                    { content: item.regNumber }]
                    return data
                }),
                // tableLineWidth: 0.5,
                theme: 'grid',
                tableLineColor: "black",
                tableWidth: 45,
                margin: { left: 60 }
            })

            //3
            autoTable(doc, {
                startY: 73,
                headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0] },
                bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0], halign: 'center' },
                head: [
                    [
                        { content: "Seat No.", styles: { cellWidth: 15 } },
                        { content: "Reg.No.", styles: { halign: 'center' } }
                    ]

                ],
                body: suffledList.filter((reg, ind) => ind > 25 && ind < 39).map((item, ind) => {
                    let data = [{ content: item.seatNumber + 1 },
                    { content: item.regNumber }]
                    return data
                }),
                // tableLineWidth: 0.5,
                theme: 'grid',
                tableLineColor: "black",
                tableWidth: 45,
                margin: { left: 105 }
            })
            //4
            autoTable(doc, {
                startY: 73,
                headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0] },
                bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0], halign: 'center' },
                head: [
                    [
                        { content: "Seat No.", styles: { cellWidth: 15 } },
                        { content: "Reg.No.", styles: { halign: 'center' } }
                    ]

                ],
                body: suffledList.filter((reg, ind) => ind > 38 && ind < 52).map((item, ind) => {
                    let data = [{ content: item.seatNumber + 1 },
                    { content: item.regNumber }]
                    return data
                }),
                // tableLineWidth: 0.5,
                theme: 'grid',
                tableLineColor: "black",
                tableWidth: 45,
                margin: { left: 150 }
            })
            // Total table
            autoTable(doc, {
                startY: firstTableY + 50,
                headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0] },
                bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0], halign: 'center' },
                // head: [
                //     [
                //         { content: 'CSE' },
                //         { content: `ECE` },
                //         { content: "TOTAL" },
                //     ]
                // ],
                body: [
                    ...seatPlan.map((sp) => {
                        total = parseInt(total + sp.count)
                        return [
                            sp.degree + '-' + sp.discipline, sp.count
                        ]
                    }),
                    [
                        // 'Total', total
                        { content: `TOTAL`, styles: { fontStyle: 'bold', fontSize: 11 } },
                        { content: total, styles: { fontStyle: 'bold', fontSize: 11 } }
                    ],
                ],

                // tableLineWidth: 0.5,
                theme: 'grid',
                tableLineColor: "black",
                tableWidth: 70,
            })
        }


        console.log(seatPlan);

        doc.setFont('Arial', 'normal', 'bold')
        doc.text('Chief Superintendent', 150, (doc as any).lastAutoTable.finalY + 10)

        doc.save(fileName + '.pdf');
    }

    const csvData = [
        [
            'Seat No',
            'Reg. No.'
        ],
        ...suffledList.map((sl, i) => [
            sl.seatNumber + 1,
            sl.regNumber
        ])
    ]


    return (
        <>
            <PageContainer title="Seating Arrangement">
                <Card title="filter">
                    <div className="row">
                        <div className="col-3">
                            <label htmlFor="">Exam <span className="text-danger">*</span> :</label>
                            <select
                                className="form-control"
                                value={exam}
                                onChange={(e) => setExam(e.target.value)}>
                                <option value="">Select Exam</option>
                                {examName.map((hs, i) => (
                                    <option value={hs.id} key={i}>{hs.title}</option>
                                ))
                                }
                            </select>
                        </div>
                        <div className="col-3">
                            <label htmlFor="">Date <span className="text-danger">*</span> :</label>
                            <input type="date" className="form-control" value={date} onChange={(e) => setDate(e.target.value)} />
                        </div>
                        <div className="col-3">
                            <label htmlFor="">Session <span className="text-danger">*</span> :</label>
                            <select className="form-control"
                                value={session}
                                onChange={(e) => setSession(e.target.value)}>
                                <option value="">Select Session</option>
                                <option value="AN">AN</option>
                                <option value="FN">FN</option>
                            </select>
                        </div>
                        <div className="col-3">
                            <label htmlFor="">Hall <span className="text-danger">*</span> : </label>
                            <select className="form-control"
                                value={hall}
                                onChange={(e) => setHall(e.target.value)}>
                                <option value="">Select Hall</option>
                                {
                                    hallName.map((hs, i) => (
                                        <option value={hs.id} key={i}>{hs.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className=" col-12 m-2 text-end">
                            <button className='btn btn-sm btn-primary' onClick={handleSearch} style={{ float: "right", width: "10%", marginTop: "30px" }}>Search</button>
                        </div>
                    </div>
                </Card>
                <CollapseCard title="Seating Arrangement">
                    <div className="container-fluid table-container" style={{ width: "100%" }}>
                        <table className="table table-success table-striped" style={{ width: "50%" }}>
                            <thead>
                                <tr>
                                    <th className="text-center">Seat.No</th>
                                    <th className="text-center">Reg.No</th>
                                </tr>
                            </thead>
                            <tbody>
                                {suffledList.map((item, ind) => (
                                    <tr key={ind}>
                                        <td className="text-center">{item.seatNumber + 1}</td>
                                        <td className="text-center">{item.regNumber}</td>
                                    </tr>
                                ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="row">
                        <div className="col">
                            {suffledList.length > 0 && <div className="text-end mb-3">
                                <button
                                    type="button"
                                    onClick={handleDownloadPDF}
                                    className="m-1 btn btn-sm btn-primary"
                                >
                                    <Feather.Download /> PDF
                                </button>
                                <CSVLink
                                    data={csvData}
                                    filename="details_of_seatingArrangement.csv"
                                    className="m-1 btn btn-sm btn-primary"
                                >
                                    <Feather.Download /> CSV
                                </CSVLink>
                            </div>
                            }
                        </div>
                    </div>

                </CollapseCard>

            </PageContainer>

        </>
    )
}

interface HallPlan {
    id: number
    total: number
    studentList: string
    examTitle: string
    discipline: string
    degree: string
    count: string
}

interface Exam {
    id: number
    title: string
    monthYear: string
}

interface Hall {
    id: number
    name: string
    seatingCapacity: string
    createdBy: string
    createTimestamp: string
}