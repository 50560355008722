import axios from 'axios';
import { FC, useEffect, useState } from 'react';
import Card from '../components/custom/Card';
import CollapseCard from '../components/custom/CollapseCard';
import PageContainer from '../components/custom/PageContainer';
// import './styles/tableReport.css';

import jsPDF from 'jspdf';
import autoTable, { RowInput } from 'jspdf-autotable';
import * as Feather from 'react-feather';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import FormInput from '../components/custom/FormInput';
import { apiUrl } from '../config';
import { LocalFormatDate } from '../config/functions';
import { CourseType } from '../redux/actions/courseActions';
import { DisciplineCourseType } from '../redux/actions/disciplineCourseAction';
import { StoreState } from '../redux/reducers';
import imgPath from './img/collegeLogo.png';

interface Degree {
    id: number;
    name: string;
}

interface Discipline {
    id: number;
    name: string;
}

interface Exam {
    id: number;
    title: string;
}

export interface HallticketReportType {
    id: number;
    registerNo: number;
    regulation: number;
    departmentRegisterNumber: string;
    name: string;
    exam: string
    dateOfBirth: string;
    degree: string;
    discipline: string;
    batch: string;
    dateOfJoin: string;
    noOfYears: number;
    totalCourse: number;
    currentSemester: number;
    photo: string;
    courseDetails: [
        {
            sem: number,
            courseCode: string,
            courseTitle: string,
            date: string,
            session: string,
        }
    ]
}

interface Props {
    title: string;
}

const HallticketReport: FC<Props> = (props: Props) => {

    const { title } = props;
    const fileName = "Hall_Ticket_report";

    const [hallReport, setHallReport] = useState<HallticketReportType[]>([])

    console.log(hallReport);

    const [selectDegree, setSelectDegree] = useState<Degree[]>([])
    const [degree, setDegree] = useState<string>('');

    const [selectedDiscipline, setSelectDiscipline] = useState<Discipline[]>([])
    const [discipline, setDiscipline] = useState<string>('');

    const [selectExam, setSelectExam] = useState<Exam[]>([])
    const [exam, setExam] = useState<string>('')

    // const [selectBatch, setSelectBatch] = useState<Student[]>([])
    const [batch, setBatch] = useState<string>('')
    const [sem, setSem] = useState<string>('')
    const [semError, setSemError] = useState<string>('')


    //for date for practical
    const disciplineCourseList = useSelector<StoreState, DisciplineCourseType[]>(state => state.disciplineCourse)
    const courseList = useSelector<StoreState, CourseType[]>(state => state.course)


    const dateObj = new Date();

    const dd = dateObj.getDate()
    const mm = dateObj.getMonth() + 1
    const yyyy = dateObj.getFullYear()

    const generateDate = `${dd}-${mm}-${yyyy}`;

    // const generateDate = (genDate: string) => {
    //   const dateObj = new Date(genDate)
    //   const day = dateObj.getDate().toString().padStart(2, '0')
    //   const month = (dateObj.getMonth() + 1).toString().padStart(2, '0')
    //   const year = dateObj.getFullYear()

    //   return [day, month, year].join('-')//20-12-2023
    // }

    useEffect(() => {
        axios.get(`${apiUrl}/degree/`)
            .then((response) => {
                setSelectDegree(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching discipline course', error);
            });
    }, [])

    useEffect(() => {
        axios.get(`${apiUrl}/discipline/`)
            .then((response) => {
                setSelectDiscipline(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching discipline course', error);
            });
    }, [])

    useEffect(() => {
        axios.get(`${apiUrl}/exam/`)
            .then((response) => {
                setSelectExam(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching discipline course', error);
            });
    }, [])

    // const data = [
    //   {
    //     "S.No": "1",
    //     "exam": "Nov/Dec",
    //     "Year": "2023",
    //     "collegeName": "Parisutham Institute of Technology and Science",
    //     "code": "123",
    //     "registerNumber": "CB20S31",
    //     "candidate": "John Doe",
    //     "DOB": "11/07/2003",
    //     "Degree": "B.E.",
    //     "Discipline": "Computer Science",
    //     "Regulations": "2017",
    //     "semester": 3,
    //     "courseDetails": [{
    //       "Sem.": "3",
    //       "courseCode": "321",
    //       "courseTitle": "Maths",
    //     }, {
    //       "Sem.": "3",
    //       "courseCode": "322",
    //       "courseTitle": "Physics",
    //     },
    //     {
    //       "Sem.": "4",
    //       "courseCode": "324",
    //       "courseTitle": "OR",
    //     },
    //     {
    //       "Sem.": "3",
    //       "courseCode": "321",
    //       "courseTitle": "Maths",
    //     }, {
    //       "Sem.": "3",
    //       "courseCode": "322",
    //       "courseTitle": "Physics",
    //     },
    //     {
    //       "Sem.": "4",
    //       "courseCode": "324",
    //       "courseTitle": "OR",
    //     },
    //     {
    //       "Sem.": "3",
    //       "courseCode": "321",
    //       "courseTitle": "Maths",
    //     }, {
    //       "Sem.": "3",
    //       "courseCode": "322",
    //       "courseTitle": "Physics",
    //     },
    //     {
    //       "Sem.": "4",
    //       "courseCode": "324",
    //       "courseTitle": "OR",
    //     },
    //     {
    //       "Sem.": "3",
    //       "courseCode": "321",
    //       "courseTitle": "Maths",
    //     }, {
    //       "Sem.": "3",
    //       "courseCode": "322",
    //       "courseTitle": "Physics",
    //     },
    //     {
    //       "Sem.": "4",
    //       "courseCode": "324",
    //       "courseTitle": "OR",
    //     },
    //     {
    //       "Sem.": "3",
    //       "courseCode": "321",
    //       "courseTitle": "Maths",
    //     }, {
    //       "Sem.": "3",
    //       "courseCode": "322",
    //       "courseTitle": "Physics",
    //     },
    //     {
    //       "Sem.": "4",
    //       "courseCode": "324",
    //       "courseTitle": "OR",
    //     }],
    //     "contactNumber": "9751365573",
    //     "noOfCourses": "4",
    //     "totalFees": "1000000",
    //   },

    //   {
    //     "S.No": "2",
    //     "exam": "Nov/Dec",
    //     "Year": "2023",
    //     "collegeName": "Parisutham Institute of Technology and Science",
    //     "code": "123",
    //     "registerNumber": "CB20S32",
    //     "candidate": "Jane Doe",
    //     "DOB": "05/15/2002",
    //     "Degree": "B.Tech",
    //     "Discipline": "Electrical Engineering",
    //     "Regulations": "2017",
    //     "Sem.": "3",
    //     "courseCode": "789",
    //     "courseTitle": "Mechanics",
    //     "contactNumber": "9876543210",
    //     "noOfCourses": "5",
    //     "totalFees": "200000",
    //   },
    //   {
    //     "S.No": "3",
    //     "exam": "Nov/Dec",
    //     "Year": "2023",
    //     "collegeName": "Parisutham Institute of Technology and Science",
    //     "code": "123",
    //     "registerNumber": "CB20S33",
    //     "candidate": "Alice Doe",
    //     "DOB": "03/22/2001",
    //     "Degree": "B.E.",
    //     "Discipline": "Mechanical Engineering",
    //     "Regulations": "2017",
    //     "Sem.": "3",
    //     "courseCode": "345",
    //     "courseTitle": "Thermodynamics",
    //     "contactNumber": "1234567890",
    //     "noOfCourses": "6",
    //     "totalFees": "300000",
    //   },
    //   {
    //     "S.No": "4",
    //     "exam": "Nov/Dec",
    //     "Year": "2023",
    //     "collegeName": "Parisutham Institute of Technology and Science",
    //     "code": "123",
    //     "registerNumber": "CB20S34",
    //     "candidate": "Bob Doe",
    //     "DOB": "09/18/2000",
    //     "Degree": "B.Sc.",
    //     "Discipline": "Physics",
    //     "Regulations": "2017",
    //     "Sem.": "3",
    //     "courseCode": "246",
    //     "courseTitle": "Electromagnetism",
    //     "contactNumber": "555-1234",
    //     "noOfCourses": "7",
    //     "totalFees": "400000",
    //   },
    //   {
    //     "S.No": "5",
    //     "exam": "Nov/Dec",
    //     "Year": "2023",
    //     "collegeName": "Parisutham Institute of Technology and Science",
    //     "code": "123",
    //     "registerNumber": "CB20S34",
    //     "candidate": "Sameera",
    //     "DOB": "11/07/2003",
    //     "Degree": "B.E.",
    //     "Discipline": "Computer Science",
    //     "Regulations": "2017",
    //     "courseDetails": [{
    //       "Sem.": "3",
    //       "courseCode": "321",
    //       "courseTitle": "Maths",
    //     }, {
    //       "Sem.": "3",
    //       "courseCode": "322",
    //       "courseTitle": "Physics",
    //     }],
    //     "contactNumber": "9751365573",
    //     "noOfCourses": "4",
    //     "totalFees": "1000000",
    //   },
    // ];

    // const filteredData = data.filter((row) => {
    //   return (
    //     row["Degree"].includes(degree) &&
    //     row["Discipline"].includes(discipline)
    //   );
    // })

    //Batch DropDown

    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let min = 0;
    let max = 10;

    let last10Years = [];
    for (let i = min; i <= max; i++) {
        let dropYear = year - i;
        last10Years.push(dropYear);
    }

    const handleSearch = () => {
        if (exam !== '' && degree !== '' && discipline !== '' && batch !== '' && sem !== '') {
            // let years = academicYear.split('-')
            axios.get(`${apiUrl}/student/?degreeId=${degree}&disciplineId=${discipline}&examId=${exam}&batch=${batch}&currentSem=${sem}`)
                .then((response) => {
                    setHallReport(response.data.data);
                })
                .catch((error) => {
                    toast.error('There is no related records', error);
                });
        } else {
            toast.error('Select All Filters', { position: 'top-right', autoClose: 3000 });

            setHallReport([])
        }
    }


    const handleDownloadPDF = () => {
        const doc = new jsPDF('p', 'mm', 'a4');
        // doc.setFontSize(18);
        // doc.setFont('Arial', 'normal', 'bold');
        // doc.setTextColor(0, 0, 0);
        // doc.text(title, 107, 10, { align: 'center' });
        let finalY = 0;

        hallReport.forEach((h, i) => {
            if (i > 0) {
                doc.addPage(); // Add a new page for each hall report except the first one
            }
            // doc.addPage()

            const headerRows1: RowInput[] = [];
            const headerRows2: RowInput[] = [];
            doc.setFontSize(15);
            doc.setFont('Times', 'normal', 'bold');
            doc.setTextColor(0, 0, 0);
            doc.text(title, 107, 10, { align: 'center' });
            let eobj = selectExam.find(f => f.id.toString() === exam)

            headerRows1.push(
                [
                    { content: "Name :", styles: { halign: "left", cellWidth: 35 } },
                    { content: h["name"], styles: { halign: "left" } },
                    { content: "Date of Birth :", styles: { halign: "left" } },
                    { content: LocalFormatDate(h.dateOfBirth?.toString()), styles: { halign: "left" } }
                ],
                [
                    { content: "Register Number :", styles: { halign: "left", cellWidth: 35 } },
                    { content: h.registerNo, styles: { halign: "left" } },
                    { content: "Current Semester :", styles: { halign: "left", } },
                    { content: h.currentSemester, styles: { halign: "left" } },
                    // { content: doc.addImage(apiUrl + "/" + h.photo, 'JPEG', 170, 25, 15, 15).toString(), rowSpan: 5, styles: { halign: 'center' } },
                ],
                // [
                //     { content: "Current Semester", styles: { halign: "center", } },
                //     { content: h.currentSemester, styles: { halign: "center" } },

                // ],
                // [
                //     { content: "Date of Birth", styles: { halign: "center" } },
                //     { content: LocalFormatDate(h.dateOfBirth?.toString()), styles: { halign: "center" } }
                // ],
                // [
                //     { content: "Degree & Discipline", styles: { halign: "center" } },
                //     { content: `${h.degree} ${h.discipline}`, colSpan: 8, styles: { halign: "center" } }
                // ],
                // [
                //     { content: "Examination Centre", styles: { halign: "center" } },
                //     { content: 'Parisutham Institute of Technology and Science', colSpan: 8, styles: { halign: "center" } },
                // ]
            );
            headerRows2.push(
                [
                    { content: "Degree & Discipline :", styles: { halign: "left", cellWidth: 35 } },
                    { content: `${h.degree} - ${h.discipline}`, styles: { halign: "left" } }
                ],
                // [
                //     { content: "Examination Centre", styles: { halign: "center" } },
                //     { content: 'Parisutham Institute of Technology and Science', styles: { halign: "center" } },
                // ]
            );
            //top
            const firstTopTableHeight = autoTable(doc, {
                didDrawPage: (data) => {
                    doc.setFontSize(11);
                    doc.setFont('Times', 'bold');
                    doc.text(eobj?.title!, data.settings.margin.left + 58, 46);
                    doc.addImage(imgPath, 'PNG', 25, 13, 170, 30);
                    // doc.addImage(apiUrl + "/" + h.photo, 'JPG', 160, 57, 15, 15)
                    doc.setLineWidth(10);
                    doc.setDrawColor(0, 0, 0);
                    doc.stroke();
                },
                // didDrawCell: function (data) {
                //     if (data.column.index === 5 && data.cell.section === 'body') {
                //         var td = data.cell.raw;
                //         var img = td.('img')[0];
                //         var dim = data.cell.height - data.cell.padding('vertical');
                //         var textPos = data.cell.textPos;
                //         doc.addImage(img.src, textPos.x, textPos.y, dim, dim);
                //     }
                // },
                startY: 50,
                headStyles: { fontSize: 8 },
                // bodyStyles: { textColor: [0, 0, 0], lineColor: [0, 0, 0] },
                tableWidth: 160,
                head: headerRows1,
                theme: 'plain',
            });

            doc.addImage(h.photo, 'JPG', 174, 48, 22, 30)
            // doc.rect(174, 55, 22, 32)

            const firstBottomTableHeight = autoTable(doc, {
                // didDrawPage: (data) => {
                //     doc.setFontSize(10);
                //     doc.setFont('normal');
                //     doc.text(eobj?.title!, data.settings.margin.left + 50, 51);
                //     doc.addImage(imgPath, 'PNG', 25, 18, 170, 30);
                //     doc.rect(14, 15, 182, 40,)
                //     doc.setLineWidth(10);
                //     doc.setDrawColor(0, 0, 0);
                //     doc.stroke();
                // },
                startY: (doc as any).lastAutoTable.finalY,
                headStyles: { fontSize: 8 },
                // bodyStyles: { textColor: [0, 0, 0], lineColor: [0, 0, 0] },
                head: headerRows2,
                theme: 'plain',
            });

            const secondTableHeaderRows: RowInput[] = [
                [
                    { content: "Sem.", styles: { halign: "center" } },
                    { content: "Course Code", styles: { halign: "center" } },
                    { content: "Course Title", styles: { halign: "center" } },
                    { content: "Date", styles: { halign: "center" } },
                    { content: "Sess.", styles: { halign: "center" } },
                ],
            ];

            // const bodyData: RowInput[] = h?.courseDetails?.slice(0, 15).map((course, index) => [
            //     { content: course.sem, styles: { halign: 'center' } },
            //     { content: course.courseCode, styles: { halign: 'center' } },
            //     { content: course.courseTitle, styles: { halign: 'left' } },
            //     course.date ? { content: LocalFormatDate(course.date), styles: { halign: 'center' } } : { content: '', styles: { halign: 'center' } },
            //     { content: course.session || '', styles: { halign: 'center' } },

            // ])
            const bodyData: RowInput[] = h?.courseDetails?.slice(0, 15).map((course, index) => [
                { content: course.sem, styles: { halign: 'center' } },
                { content: course.courseCode, styles: { halign: 'center' } },
                { content: course.courseTitle, styles: { halign: 'left' } },
                (() => {
                    const matchingCourse = courseList.find(c => c.shortName === course.courseCode);
                    if (matchingCourse) {
                        const matchingDisciplineCourse = disciplineCourseList.find(d => d.courseId === matchingCourse.id);
                        if (matchingDisciplineCourse && matchingDisciplineCourse.courseType !== 'Practical') {
                            return { content: LocalFormatDate(course.date), styles: { halign: 'center' } };
                        }
                    }
                    return { content: '', styles: { halign: 'center' } };
                })(),
                // { content: course.session || '', styles: { halign: 'center' } },
                (() => {
                    const matchingCourse = courseList.find(c => c.shortName === course.courseCode);
                    if (matchingCourse) {
                        const matchingDisciplineCourse = disciplineCourseList.find(d => d.courseId === matchingCourse.id);
                        if (matchingDisciplineCourse && matchingDisciplineCourse.courseType !== 'Practical') {
                            return { content: course.session, styles: { halign: 'center' } };
                        }
                    }
                    return { content: '', styles: { halign: 'center' } };
                })(),
            ]);

            //bottom table
            let topTablefinalY: number = (doc as any).lastAutoTable.finalY
            let bodyLengthWithoutEmptyRows = bodyData.length;
            let leftTablefinalY = 0;
            const emptyRowsCount = 15 - bodyData.length;
            for (let i = 0; i < emptyRowsCount; i++) {
                bodyData.push([
                    { content: '', styles: { halign: 'center', } },
                    { content: '', styles: { halign: 'center', } },
                    { content: '', styles: { halign: 'center', } },
                    { content: '', styles: { halign: 'center', } },
                    { content: '', styles: { halign: 'center', } },

                ]);
            };

            //2nd left table
            const secondleftTableHeight = autoTable(doc, {
                willDrawCell: (data) => {
                    const fullHeight = 160;
                    // const fullHeight = bodyLengthWithoutEmptyRows <= 10 ? 125 : 140;
                    doc.setLineWidth(0.2)
                    doc.line(14, topTablefinalY, 14, topTablefinalY + fullHeight)
                    doc.line(25, topTablefinalY, 25, topTablefinalY + fullHeight)
                    doc.line(41, topTablefinalY, 41, topTablefinalY + fullHeight)
                    doc.line(74, topTablefinalY, 74, topTablefinalY + fullHeight)
                    doc.line(94, topTablefinalY, 94, topTablefinalY + fullHeight)
                    // doc.line(196, topTablefinalY, 196, topTablefinalY + fullHeight)
                },
                startY: topTablefinalY,
                headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0], fontSize: 8 },
                bodyStyles: { textColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 8 },
                // tableLineColor: 'black',
                // tableLineWidth: 0.3,
                head: secondTableHeaderRows,
                body: bodyData,
                theme: 'plain',
                margin: { top: 10 },
                tableWidth: 91,
                columnStyles: {
                    0: { cellWidth: 11, minCellHeight: 6 },
                    1: { cellWidth: 16, minCellHeight: 6 },
                    2: { cellWidth: 33, minCellHeight: 6 },
                    3: { cellWidth: 20, minCellHeight: 6 },
                    4: { cellWidth: 11, minCellHeight: 6 }
                },
            });

            // if (i === emptyRowsCount - 1)
            leftTablefinalY += (doc as any).lastAutoTable.finalY

            const secondRightTableHeaderRows: RowInput[] = [
                [
                    { content: "Sem.", styles: { halign: "center" } },
                    { content: "Course Code", styles: { halign: "center" } },
                    { content: "Course Title", styles: { halign: "center" } },
                    { content: "Date", styles: { halign: "center" } },
                    { content: "Sess.", styles: { halign: "center" } },
                ],
            ];

            const secondRightBodyData: RowInput[] = h.courseDetails
                ? h.courseDetails.slice(15, 30).map((course, index) => [
                    { content: course.sem, styles: { halign: 'center' } },
                    { content: course.courseCode, styles: { halign: 'center' } },
                    { content: course.courseTitle, styles: { halign: 'left' } },
                    { content: course.date, styles: { halign: 'center' } },
                    { content: course.session || '', styles: { halign: 'center' } },

                ])
                : [];

            const secondRightEmptyRowsCount = 15 - secondRightBodyData.length;
            for (let i = 0; i < secondRightEmptyRowsCount; i++) {
                secondRightBodyData.push([
                    { content: '', styles: { halign: 'center' } },
                    { content: '', styles: { halign: 'center' } },
                    { content: '', styles: { halign: 'center' } },
                    { content: '', styles: { halign: 'center' } },
                    { content: '', styles: { halign: 'center' } }
                ]);
            }

            const fullHeight = Math.round((doc as any).lastAutoTable.finalY) - (topTablefinalY);
            const minCellHeight = (Math.floor(fullHeight / 15)) - 3
            // console.log((doc as any).lastAutoTable.finalY);
            // console.log(topTablefinalY);
            // console.log(fullHeight);
            // console.log(minCellHeight);

            //2nd Right table
            const secondRightTableHeight = autoTable(doc, {
                willDrawCell: () => {
                    doc.setLineWidth(0.2)
                    doc.line(105, topTablefinalY, 105, topTablefinalY + fullHeight)
                    doc.line(116, topTablefinalY, 116, topTablefinalY + fullHeight)
                    doc.line(132, topTablefinalY, 132, topTablefinalY + fullHeight)
                    doc.line(165, topTablefinalY, 165, topTablefinalY + fullHeight)
                    doc.line(185, topTablefinalY, 185, topTablefinalY + fullHeight)
                    doc.line(196, topTablefinalY, 196, topTablefinalY + fullHeight)
                },
                startY: topTablefinalY,
                headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0], fontSize: 8 },
                bodyStyles: { textColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 8 },
                head: secondRightTableHeaderRows,
                body: secondRightBodyData,
                theme: 'plain',
                margin: { top: 10, left: 105 },
                tableWidth: 91,
                // tableLineColor: 'black',
                // tableLineWidth: 0.3,
                columnStyles: {
                    0: { cellWidth: 11, minCellHeight: minCellHeight },
                    1: { cellWidth: 16, minCellHeight: minCellHeight },
                    2: { cellWidth: 33, minCellHeight: minCellHeight },
                    3: { cellWidth: 20, minCellHeight: minCellHeight },
                    4: { cellWidth: 11, minCellHeight: minCellHeight }
                },
            });


            doc.setFontSize(9);
            doc.setFont('Times', 'normal', 'bold');
            // doc.rect(14, leftTablefinalY + 0.1, 182, 5.5);
            // doc.text(`No. of Courses Registered : ${h.totalCourse}`, 18, leftTablefinalY + 3.5)

            const thirdTableContent: RowInput[] = [
                [{ content: `No. of Courses Registered : ${h.totalCourse}`, styles: { fontStyle: 'bold' }, colSpan: 8 }]
            ];

            //no. of table
            const thirdTableHeight = autoTable(doc, {
                startY: leftTablefinalY,
                bodyStyles: { textColor: [0, 0, 0], fontSize: 8, lineColor: 'white' },
                body: thirdTableContent,
                theme: 'grid',
                tableLineColor: 'black',
                tableLineWidth: 0.3,
            });

            const fourthTableContent: RowInput[] = [];

            fourthTableContent.push(
                [
                    { content: `NOTE :`, styles: { cellWidth: 11, fontSize: 8, fontStyle: 'bold', cellPadding: { left: 1, top: 2 } } },
                    { content: '', styles: {}, colSpan: 8 },
                ],
                [
                    { content: '1. ', styles: { fontSize: 9, cellPadding: { left: 4 } } },
                    { content: `In case of candidates who have been Readmitted / Transferred,this Hall Ticket is valid only if the candidate’s admission is approved by the Commissioner of Technical Education, Government of Tamil Nadu, Chennai and the Registrar, Anna University, Chennai. If any candidate appears for the examination without the approval, the examination written by the candidate will be invalidated.`, colSpan: 8, styles: { fontSize: 9, cellPadding: { top: 0 } } },
                ],
                [
                    { content: '2. ', styles: { fontSize: 9, cellPadding: { left: 4, top: 1.5 } } },
                    { content: `Correction in the Name, Date of Birth and missing of Photograph or incorrect Photograph if any, is to be intimated to the Controller of Examinations,  immediately.`, colSpan: 8, styles: { fontSize: 9, cellPadding: { top: 1.5 } } },
                ],
                [
                    { content: '3. ', styles: { fontSize: 9, cellPadding: { left: 4, top: 1.5 } } },
                    { content: 'Instructions printed overleaf are to be followed strictly.', colSpan: 9, styles: { fontSize: 9, cellPadding: { top: 1.5 } } }
                ],
                [
                    { content: '', styles: {} },
                    { content: `Generated on : ${generateDate}`, colSpan: 9, styles: { fontSize: 9, cellPadding: { top: 2 } } },

                ]
            );
            //note table
            const fourthTableHeight = autoTable(doc, {
                startY: (doc as any).lastAutoTable.finalY,
                bodyStyles: { textColor: [0, 0, 0], fontSize: 8, lineColor: 'white' },
                body: fourthTableContent,
                theme: 'grid',
                tableLineColor: 'black',
                tableLineWidth: 0.3,
                // didDrawPage: (data) => {
                //     doc.rect(14, leftTablefinalY + 5.3, 182, 48) //as we changed it bcz of line overlay
                //     finalY = data.cursor?.y || 0;
                // },
            });
            //sign table
            const fifthTableContent: RowInput[] = []
            fifthTableContent.push(
                [
                    { content: 'Signature of the Candidate', colSpan: 6, styles: { cellPadding: { top: 17 }, halign: "center", fontSize: 9, } },

                    { content: 'Signature of the Principal with seal', colSpan: 6, styles: { cellPadding: { top: 17 }, halign: "center", fontSize: 9 } },

                    { content: 'Controller of Examinations', colSpan: 6, styles: { cellPadding: { top: 17 }, halign: "center", fontSize: 9 } }
                ],

            )

            const fifthTableHeight = autoTable(doc, {
                startY: (doc as any).lastAutoTable.finalY,
                bodyStyles: { textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0] },
                body: fifthTableContent,
                theme: 'grid',

                didDrawPage: (data) => {
                    // doc.rect(14,245,176,40)
                    finalY = data.cursor?.y || 0;
                },
            })
            //NEW PAGE
            doc.addPage()

            doc.rect(10, 10, 190, 265)
            doc.setFontSize(15)
            doc.text("INSTRUCTIONS TO THE CANDIDATE", 60, 15)
            // doc.setFont('Times', 'normal', '')

            const instructionsBody: RowInput[] = [
                [
                    { content: '1. ', styles: { cellWidth: 7, cellPadding: { left: 2 }, halign: 'center' } },
                    { content: `Admission to the Examination is provisional.`, colSpan: 8, styles: { cellPadding: { top: 0 } } },
                ],
                [
                    { content: '2. ', styles: { cellWidth: 7, cellPadding: { left: 2, top: 2 }, halign: 'center' } },
                    { content: 'The Hall Ticket is issued subject to the candidate satisfying the Attendance and other requirements as per rules, regulations and instructions by the Institution from time to time. If later, it is found that the candidate fails to comply with the above requirements, the Examinations written by the candidate will be treated as invalid.', colSpan: 8, styles: { cellPadding: { top: 2 }, halign: 'justify' } },
                ],
                [
                    { content: '3. ', styles: { cellWidth: 7, cellPadding: { left: 2, top: 2 }, halign: 'center' } },
                    { content: `Candidate will occupy the allotted seat at least 10 minutes before the commencement of the Examination. In no case, candidate shall be allowed to occupy a seat other than the seat allotted to him / her.`, colSpan: 8, styles: { cellPadding: { top: 2 } } },
                ],
                [
                    { content: '4. ', styles: { cellWidth: 7, cellPadding: { left: 2, top: 2 }, halign: 'center' } },
                    { content: `Normally the Candidate will not be permitted to enter the hall after the commencement of the Examination.Only on extraordinary circumstances, the candidate will be permitted during the first thirty minutes of the Examination after obtaining the written permission from the Principal / CS. Under any circumstances the Candidate shall not be permitted to enter the hall after the expiry of first thirty minutes.`, colSpan: 8, styles: { cellPadding: { top: 2 }, fontStyle: 'bold' } },
                ],
                [
                    { content: '5. ', styles: { cellWidth: 7, cellPadding: { left: 2, top: 2 }, halign: 'center' } },
                    { content: `Candidate shall not be allowed to leave the Examination hall before the expiry of 45 minutes from the commencement of Examination. The candidate who leaves the Examination hall during the period allotted for a paper will not be allowed to re – enter the hall within that period.`, colSpan: 8, styles: { cellPadding: { top: 2 }, fontStyle: 'bold' } },
                ],
                [
                    { content: '6. ', styles: { cellWidth: 7, cellPadding: { left: 2, top: 2 }, halign: 'center' } },
                    { content: `Candidate is strictly prohibited from smoking inside the Examination hall.`, colSpan: 8, styles: { cellPadding: { top: 2 } } },
                ],
                [
                    { content: '7. ', styles: { cellWidth: 7, cellPadding: { left: 2, top: 2 }, halign: 'center' } },
                    { content: `Strict silence should be maintained in the Examination hall.`, colSpan: 8, styles: { cellPadding: { top: 2 } } },
                ],
                [
                    { content: '8. ', styles: { cellWidth: 7, cellPadding: { left: 2, top: 2 }, halign: 'center' } },
                    { content: `Candidate is required to bring his / her own pens, pencils and erasers. Candidate should use only blue or black ink  while answering his / her papers.`, colSpan: 8, styles: { cellPadding: { top: 2 } } },
                ],
                [
                    { content: '9. ', styles: { cellWidth: 7, cellPadding: { left: 2, top: 2 }, halign: 'center' } },
                    { content: `Before proceeding to answer the paper, the candidate should write his / her register number, semester,subject and  date of the Examination at the appropriate space provided in the first page of the answer booklet and no - where else in the answer booklet or in any additional attachment like drawing sheet, smith chart etc.`, colSpan: 8, styles: { cellPadding: { top: 2 } } },
                ],
                [
                    { content: '10. ', styles: { cellWidth: 7, cellPadding: { left: 2, top: 2 }, halign: 'center' } },
                    { content: `If a candidate write his / her register number on any part of the answer booklet / sheets other than the one provided  for or puts any special mark or writes anything which may disclose, in any way, the identity of the Candidate, he / she will render himself / herself liable for disciplinary action.`, colSpan: 8, styles: { cellPadding: { top: 2 } } },
                ],
                [
                    { content: '11. ', styles: { cellWidth: 7, cellPadding: { left: 2, top: 2 }, halign: 'center' } },
                    { content: `Writing of wrong register number in the answer booklet will entail rejection of the answer booklet.`, colSpan: 8, styles: { cellPadding: { top: 2 } } },
                ],
                [
                    { content: '12. ', styles: { cellWidth: 7, cellPadding: { left: 2, top: 2 }, halign: 'center' } },
                    { content: `Candidate is not allowed to exceed the prescribed time assigned to each paper.`, colSpan: 8, styles: { cellPadding: { top: 2 } } },
                ],
                [
                    { content: '13. ', styles: { cellWidth: 7, cellPadding: { left: 2, top: 2 }, halign: 'center' } },
                    { content: `Candidate shall not talk / ask questions of any kind during the Examination.`, colSpan: 8, styles: { cellPadding: { top: 2 } } },
                ],
                [
                    { content: '14. ', styles: { cellWidth: 7, cellPadding: { left: 2, top: 2 }, halign: 'center' } },
                    { content: `Candidate shall not carry any written / printed matter, any paper material, cell phone, smart watch, pen drive, iPad, programmable calculator, any unauthorized datasheet / table into the Examination hall and if anything is found in his / her possession he / she shall be liable for disciplinary action.`, colSpan: 8, styles: { cellPadding: { top: 2 }, fontStyle: 'bold' } },
                ],
                [
                    { content: '15. ', styles: { cellWidth: 7, cellPadding: { left: 2, top: 2 }, halign: 'center' } },
                    { content: `No Candidate shall pass any part or whole of answer papers or Question Papers to any other candidate.No candidate shall allow another candidate to copy from his / her answer paper or copy from the answer paper of  another candidate. If found committing such malpractice, the involved candidates shall be liable for disciplinary action.`, colSpan: 8, styles: { cellPadding: { top: 2 } } },
                ],
                [
                    { content: '16. ', styles: { cellWidth: 7, cellPadding: { left: 2, top: 2 }, halign: 'center' } },
                    { content: `Candidate found guilty of using unfair means of any nature shall be liable for disciplinary action.`, colSpan: 8, styles: { cellPadding: { top: 2 } } },
                ],
                [
                    { content: '17. ', styles: { cellWidth: 7, cellPadding: { left: 2, top: 2 }, halign: 'center' } },
                    { content: `Candidate will have to hand over the answer booklet to the Invigilator before leaving the Examination hall.`, colSpan: 8, styles: { cellPadding: { top: 2 } } },
                ],
                [
                    { content: '18. ', styles: { cellWidth: 7, cellPadding: { left: 2, top: 2 }, halign: 'center' } },
                    { content: `Candidate should produce hall ticket on demand by the Invigilator / Chief Superintendent / Anna University Representative / Squad members.`, colSpan: 8, styles: { cellPadding: { top: 2 } } },
                ],
                [
                    { content: '19. ', styles: { cellWidth: 7, cellPadding: { left: 2, top: 2 }, halign: 'center' } },
                    { content: `Candidate shall not write anything in the Hall Ticket.`, colSpan: 8, styles: { cellPadding: { top: 2 }, fontStyle: 'bold' } },
                ],
                [
                    { content: '20. ', styles: { cellWidth: 7, cellPadding: { left: 2, top: 2 }, halign: 'center' } },
                    { content: `Candidate shall write only the Register No. in the space provided in the Question Paper. Any other writings in the Question Paper is prohibited and punishable.`, colSpan: 8, styles: { cellPadding: { top: 2 }, fontStyle: 'bold' } },
                ],
            ]
            const instructionsTable = autoTable(doc, {
                startY: 20,
                bodyStyles: { fontSize: 10, halign: 'justify' },// textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0],
                body: instructionsBody,
                theme: 'plain',
            })
            // doc.setFontSize(11)
            // doc.setFont('Arial', 'normal', '')
            // doc.text("1.  Admission to the Examination is provisional.", 11, 23)

            // doc.text(`2.  The Hall Ticket is issued subject to the candidate satisfying the Attendance and other requirements as \n\t per Rules, Regulations and Instructions by the Institution from time to time. If later, it is found that \n\t the candidate fails to comply with the above requirements, the Examinations written by the candidate \n\t will be treated as invalid.`, 11, 29)

            // doc.text(`3.  Candidate will occupy the \n\t allotted seat at least 10 minutes before the commencement of the Examination. In no case, candidate \n\t shall be allowed to occupy a seat other than the seat allotted to him / her`, 11, 48)

            // doc.setFont('Arial', 'normal', 'bold')
            // doc.text(`4. Normally the Candidate will not be permitted to enter the hall after the commencement of the Examination.\n\t Only on extraordinary circumstances, the candidate will be permitted during the first thirty minutes of the \n\t Examination after obtaining the written permission from the Principal / CS. Under any circumstances \n\t the Candidate shall not be permitted to enter the hall after the expiry of first thirty minutes`, 11, 63)

            // doc.text(`5. Candidate shall not be allowed to leave the Examination hall before the expiry of 45 minutes from \n\t the commencement of Examination. The candidate who leaves the Examination hall during the period \n\t allotted for a paper will not be allowed to re – enter the hall within that period`, 11, 83)

            // doc.setFont('Arial', 'normal', '')
            // // doc.text(`6. Candidate who is suffering from infectious diseases of any kind shall not be admitted to the Examination \n\t hall. `, 11, 98)

            // doc.text(`6. Candidate is strictly prohibited from smoking inside the Examination hall.`, 11, 107)

            // doc.text(`7. Strict silence should be maintained in the Examination hall`, 11, 113)

            // doc.text(`8. Candidate is required to bring his / her own pens, pencils and erasers. Candidate should use only blue\n\t or black ink  while answering his / her papers`, 11, 119)

            // doc.text(`9. Before proceeding to answer the paper, the candidate should write his / her register number, semester,\n\t subject and  date of the Examination at the appropriate space provided in the first page of the answer book \n\t and nowhere else in the answer book or in any additional attachment like drawing sheet, smith chart etc`, 11, 129)

            // doc.text(`10. If a candidate write his / her register number on any part of the answer book / sheets other than the one \n\t provided  for or puts any special mark or writes anything which may disclose, in any way, the identity \n\t of the Candidate, he / she will render himself / herself liable for disciplinary action. `, 11, 144)

            // doc.text(`11. Writing of wrong register number in the answer book will entail rejection of the answer book. `, 11, 159)

            // doc.text(`12. Candidate is not allowed to exceed the prescribed time assigned to each paper.`, 11, 164)

            // doc.text(`13. Candidate shall not talk / ask questions of any kind during the Examination`, 11, 170)

            // doc.setFont('Arial', 'normal', 'bold')
            // doc.text(`14. Candidate shall not carry any written / printed matter, any paper material, cell phone, smart watch, pen \n\t drive, iPad, programmable calculator, any unauthorized datasheet / table into the Examination hall and if \n\t anything is found in his / her possession he / she shall be liable for disciplinary action.`, 11, 175)

            // doc.setFont('Arial', 'normal', '')
            // doc.text(`15. No Candidate shall pass any part or whole of answer papers or Question Papers to any other candidate.\n\t No candidate shall allow another candidate to copy from his / her answer paper or copy from the answer \n\t paper of  another candidate. If found committing such malpractice, the involved candidates shall be liable for \n\t disciplinary action. `, 11, 190)

            // doc.text(`16. Candidate found guilty of using unfair means of any nature shall be liable for disciplinary action.`, 11, 210)

            // doc.text(`17. Candidate will have to hand over the answer book to the Invigilator before leaving the Examination hall.`, 11, 216)

            // doc.text(`18. Candidate should produce hall ticket on demand by the Invigilator / Chief Superintendent / Anna University \n\t Representative / Squad members`, 11, 222)

            // doc.setFont('Arial', 'normal', 'bold')
            // doc.text(`19. Candidate shall not write anything in the Hall Ticket`, 11, 232)

            // doc.text(`20. Candidate shall write only the Register No. in the space provided in the Question Paper. Any other writings \n\t in the Question Paper is prohibited and punishable. `, 11, 238)

            doc.setFontSize(10)
            doc.text("CONTROLLER OF EXAMINATIONS", 130, (doc as any).lastAutoTable.finalY + 18)
        });

        doc.save(fileName + '.pdf');
    }


    // const csvData = [
    //   [
    //     "S.No", "exam", "Year", "collegeName", "code",
    //     "registerNumber", "candidate", "DOB", "Degree", "Discipline",
    //     "Regulations", "Sem.", "courseCode", "courseTitle", "contactNumber",
    //     "noOfCourses", "totalFees",
    //   ],
    //   ...hallReport.map((row, index) => [
    //     row["S.No"],
    //     row["exam"],
    //     row["Year"],
    //     row["collegeName"],
    //     row["code"],
    //     row["registerNumber"],
    //     row["candidate"],
    //     row["DOB"],
    //     row["Degree"],
    //     row["Discipline"],
    //     row["Regulations"],
    //     row["Sem."],
    //     row["courseCode"],
    //     row["courseTitle"],
    //     row["contactNumber"],
    //     row["noOfCourses"],
    //     row["totalFees"],
    //   ]),
    // ];

    return (
        <>
            <PageContainer title="Hall Ticket">
                <Card title="filters">
                    <div className="row">
                        <div className="col-3">
                            <label>Degree <span className="text-danger">*</span> :</label>
                            <select
                                className="form-control"
                                value={degree}
                                onChange={(e) => setDegree(e.target.value)}
                            >
                                <option value="">Select Degree</option>
                                {selectDegree.map((deg) => (
                                    <option value={deg.id}>{deg.name}</option>
                                ))
                                }
                            </select>
                        </div>
                        <div className="col-3">
                            <label>Discipline <span className="text-danger">*</span> :</label>
                            <select
                                className="form-control"
                                value={discipline}
                                onChange={(e) => setDiscipline(e.target.value)}
                            >
                                <option value="">Select Discipline</option>
                                {selectedDiscipline.map((dis) => (
                                    <option value={dis.id}>{dis.name}</option>
                                ))
                                }
                            </select>
                        </div>
                        <div className="col-3">
                            <label>Exam <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                value={exam}
                                onChange={(e) => setExam(e.target.value)}
                            >
                                <option value="">Select Exam</option>
                                {selectExam.map((ex) => (
                                    <option value={ex.id}>{ex.title}</option>
                                ))
                                }
                            </select>
                        </div>
                        <div className="col-3">
                            <label>Batch <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                value={batch}
                                onChange={(e) => setBatch(e.target.value)}
                            >
                                <option value=""> Select Batch </option>
                                {last10Years.map((bt) => (
                                    <option value={bt}>{bt}</option>
                                ))
                                }
                            </select>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3">
                            <FormInput
                                name="Semester"
                                label="semester"
                                labelClassName="required"
                                value={sem}
                                onChange={(e) => {
                                    setSem(e.target.value)
                                    setSemError('')
                                }}
                                placeholder="Select Sem"
                                containerClass="mt-2"
                                type="select"
                                errorText={semError}
                            >
                                <option value="">Select Semester</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                            </FormInput>

                        </div>
                    </div>
                    {/* <div className="col-lg-12 col-sm-12"> */}
                    <div className=" col-12 text-end">
                        <button className='btn btn-sm btn-primary' onClick={handleSearch} style={{ float: "right", width: "10%", marginTop: "30px" }}>Search</button>
                    </div>
                    {/* </div> */}
                </Card>
                <CollapseCard title="Hall Ticket">
                    <div className="container-fluid ">
                        <table className="table table-success table-striped">
                            <thead>
                                <tr>
                                    <th>S.No.</th>
                                    <th>Register Number</th>
                                    <th>Name of the Candidate</th>
                                    <th>Date of Birth</th>
                                    <th>Degree</th>
                                    <th>Discipline</th>
                                    <th>Semester</th>
                                </tr>
                            </thead>
                            <tbody>
                                {hallReport?.map((row, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        {/* <img src={apiUrl + "/" + row.photo} alt={row.name}></img> */}
                                        <td>{row.registerNo}</td>
                                        <td>{row.name}</td>
                                        <td>{LocalFormatDate(row.dateOfBirth)}</td>
                                        <td>{row.degree}</td>
                                        <td>{row.discipline}</td>
                                        <td>{row.currentSemester}</td>
                                        {/* <td>{row.totalCourse}</td> */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="text-end mb-3">
                        {hallReport?.length > 0 && <button
                            type="button"
                            onClick={handleDownloadPDF}
                            className="m-1 btn btn-sm btn-primary"
                        >
                            <Feather.Download /> PDF
                        </button>}
                        {/* <CSVLink
              data={csvData}
              filename="details_of_course.csv"
              className="m-1 btn btn-s btn-primary"
            >
              <Feather.Download /> CSV
            </CSVLink> */}
                    </div>
                </CollapseCard>
            </PageContainer>
        </>
    )


}

export default HallticketReport;