import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { FC, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import * as Feather from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Card from '../components/custom/Card';
import CollapseCard from '../components/custom/CollapseCard';
import PageContainer from '../components/custom/PageContainer';
import { CourseType } from '../redux/actions/courseActions';
import { DegreeType } from '../redux/actions/degreeAction';
import { DisciplineType } from '../redux/actions/disciplineAction';
import { DisciplineCourseType } from '../redux/actions/disciplineCourseAction';
import { StoreState } from '../redux/reducers';
import { AppDispatch } from '../redux/store';
import imgPath from './img/collegeLogo.png';
import './styles/tableReport.css';

interface Props {
    title: string;
}

const DisciplineCourseReport: FC<Props> = (props: Props) => {
    const dispatch = useDispatch<AppDispatch>()
    const { title } = props;

    const courseList = useSelector<StoreState, DisciplineCourseType[]>(state => state.disciplineCourse);
    const [filteredCourses, setFilteredCourses] = useState<DisciplineCourseType[]>([]);

    //for filter dropdown
    const selectedDegree = useSelector<StoreState, DegreeType[]>(state => state.degree)
    const [selectedDegreeId, setSelectedDegreeId] = useState<string>();

    const selectedDiscipline = useSelector<StoreState, DisciplineType[]>(state => state.discipline);
    const [selectedDisciplineId, setSelectedDisciplineId] = useState<string>();
    //for view course
    const selectedCourse = useSelector<StoreState, CourseType[]>(state => state.course);

    const [searchPerformed, setSearchPerformed] = useState(false);


    console.log(selectedDegree);

    console.log(selectedDiscipline);

    console.log(selectedDegreeId);

    console.log(selectedDisciplineId);

    // useEffect(() => {
    //     axios.get(`${apiUrl}/discipline/`)
    //         .then((response) => {
    //             setSelectedDiscipline(response.data.data);
    //         })
    //         .catch((error) => {
    //             console.error('Error fetching discipline course', error);
    //         });
    // }, [])

    // useEffect(() => {
    //     axios.get(`${apiUrl}/course/`)
    //         .then((response) => {
    //             setSelectedCourse(response.data.data);
    //         })
    //         .catch((error) => {
    //             console.error('Error fetching discipline course', error);
    //         });
    // }, [])

    // useEffect(() => {
    //     axios.get(`${apiUrl}/degree/`)
    //         .then((response) => {
    //             setSelectedDegree(response.data.data);
    //         })
    //         .catch((error) => {
    //             console.error('Error fetching discipline course', error);
    //         });
    // }, [])

    // const fetchData = () => {
    //   axios.get<{ data: DisciplineCourse[] }>(`${apiUrl}/disciplineCourse/`)
    //     .then((response) => {
    //       setCourseList(response.data.data);
    //     })
    //     .catch((error) => console.log(error));
    // }

    // useEffect(() => {
    //   fetchData();
    // }, []);


    const filterStudent = () => {
        if (selectedDegreeId !== '' && selectedDisciplineId !== '') {
            console.log(courseList)

            const filtered = courseList.filter(course =>
                course.degreeId.toString() === selectedDegreeId && course.disciplineId.toString() === selectedDisciplineId
            );
            console.log(filtered);
            setFilteredCourses(filtered);
            setSearchPerformed(true);
        } else {
            toast.error('Please select all filters');
        }
    };


    const renderDownloadButtons = () => {
        if (searchPerformed) {
            return (
                <div className="text-end mb-3">
                    <button
                        type="button"
                        onClick={handleDownloadPDF}
                        className="m-1 btn btn-sm btn-primary"
                    >
                        <Feather.Download /> PDF
                    </button>
                    <CSVLink
                        data={csvData}
                        filename="details_of_course.csv"
                        className="m-1 btn btn-sm btn-primary"
                    >
                        <Feather.Download /> CSV
                    </CSVLink>
                </div>
            );
        }
        return null;
    };

    const filterCourseList = () => {
        // Filter the courseList based on the selectedDegreeId and selectedDisciplineId
        const filteredCourses = courseList.filter((course) => {
            if (
                (selectedDegreeId === null || course.degreeId.toString() === selectedDegreeId) &&
                (selectedDisciplineId === null || course.disciplineId.toString() === selectedDisciplineId)
            ) {
                return true;
            }
            return false;
        });

        return filteredCourses;
    };

    const getCourseName = (disciplineCourse: number) => {
        const course = selectedCourse.find((disc) => disc.id === disciplineCourse);
        if (course) {
            return course.name;
        }
        return 'N/A';
    };

    const getCourseShortName = (disciplineCourse: number) => {
        const course = selectedCourse.find((disc) => disc.id === disciplineCourse);
        if (course) {
            return course.shortName;
        }
        return 'N/A';
    };

    const onlyUnique = <V, I>(value: V, index: I, self: V[]) => {
        return self.indexOf(value) === index;
    }

    interface LTP {
        sem: number,
        l: number,
        t: number,
        p: number,
        c: number,
        cp: number
    }

    let disciplineCourses = filterCourseList().sort((a, b) => {
        return a.semesterNo - b.semesterNo
    })

    const data = filterCourseList().sort((a, b) => a.semesterNo - b.semesterNo);

    console.log(data);

    let semnos = data.map(d => d.semesterNo).filter(onlyUnique)

    const ltpData = useMemo<LTP[]>(() => {
        let ltpArray: LTP[] = []
        semnos.forEach(s => {
            let sdata = data.filter(f => f.semesterNo === s)
            let tl = sdata.reduce((prev, curr) => {
                return prev + parseFloat(curr.lecture)
            }, 0)
            let tt = sdata.reduce((prev, curr) => {
                return prev + parseFloat(curr.tutorial)
            }, 0)
            let tp = sdata.reduce((prev, curr) => {
                return prev + parseFloat(curr.practical)
            }, 0)
            let tc = sdata.reduce((prev, curr) => {
                return prev + parseFloat(curr.credit.toString())
            }, 0)
            let cp = sdata.reduce((prev, curr) => {
                return prev + parseFloat(curr.contactHours.toString())
            }, 0)
            ltpArray.push({
                sem: s,
                l: tl,
                t: tt,
                p: tp,
                cp: cp,
                c: tc

            })
        })
        return ltpArray
    }, [data])

    console.log(ltpData);


    let l = 0;
    let t = 0;
    let p = 0;
    let cp = 0;
    let c = 0

    let grandTotal: any = [];

    grandTotal.push(ltpData.map((ld) => {
        l = l + ld.l
        t = t + ld.t
        p = p + ld.p
        cp = cp + ld.cp
        c = c + ld.c

        // console.log(l);
        // console.log(t);

        // console.log(p);
        // console.log(cp);
        // console.log(c);
    }))
    console.log(l);
    console.log(t);

    console.log(p);
    console.log(cp);
    console.log(c);



    const handleDownloadPDF = () => {
        const doc = new jsPDF('p', 'mm', 'a4');

        doc.addImage(imgPath, 'PNG', 25, 10, 170, 40);

        doc.setFontSize(14)
        doc.setFont('Arial', 'normal', 'bold');
        doc.setTextColor(0, 0, 0)
        doc.text(title, 110, 54, { align: "center" });

        const selectedDegreeName = selectedDegree.find((degree) => degree.id === (selectedDegreeId ? parseInt(selectedDegreeId) : null))?.name || 'N/A';

        const selectedDisciplineName = selectedDiscipline.find((discipline) => discipline.id === (selectedDisciplineId ? parseInt(selectedDisciplineId) : null))?.name || 'N/A';

        doc.setFontSize(10);
        doc.text(`Degree & Discipline : ${selectedDegreeName} - ${selectedDisciplineName}`, 15, 65);

        autoTable(doc, {
            startY: 70,
            headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0] },
            bodyStyles: { textColor: [0, 0, 0], lineColor: [0, 0, 0] },
            head: [
                [
                    { content: 'S.No.', rowSpan: 2, styles: { halign: 'center' } },
                    { content: 'Sem.', rowSpan: 2, styles: { halign: 'center' } },
                    { content: 'Course Code', rowSpan: 2, styles: { halign: 'center' } },
                    { content: 'Course Title', rowSpan: 2, styles: { halign: 'center' } },
                    { content: 'Course Type', rowSpan: 2, styles: { halign: 'center' } },
                    { content: 'Periods per week', colSpan: 3, styles: { halign: 'center' } },
                    { content: 'Total Contact Periods', rowSpan: 2, styles: { halign: 'center' } },
                    { content: 'Credits', rowSpan: 2, styles: { halign: 'center' } },

                ],
                [
                    { content: 'L', styles: { halign: 'center' } },
                    { content: 'T', styles: { halign: 'center' } },
                    { content: 'P', styles: { halign: 'center' } },
                ],
            ],
            body: filterCourseList().sort((a, b) => a.semesterNo - b.semesterNo).map((disciplineCourse, index) => [
                { content: index + 1, styles: { halign: 'center' } },
                { content: disciplineCourse.semesterNo, styles: { halign: 'center' } },
                { content: getCourseShortName(disciplineCourse.courseId), styles: { halign: 'center' } },
                { content: getCourseName(disciplineCourse.courseId), styles: { halign: 'left' } },
                { content: disciplineCourse.courseType, styles: { halign: 'center' } },
                { content: disciplineCourse.lecture, styles: { halign: 'center' } },
                { content: disciplineCourse.tutorial, styles: { halign: 'center' } },
                { content: disciplineCourse.practical, styles: { halign: 'center' } },
                // {content: disciplineCourse.credit, styles: { halign: 'center' }},
                { content: disciplineCourse.contactHours, styles: { halign: 'center' } },
                { content: disciplineCourse.credit, styles: { halign: 'center' } },
            ]),
            theme: 'grid',
        });

        // const sumTable = filterCourseList().reduce((a: any, e) => {
        //     a[e.semesterNo] = ++a[e.semesterNo] || 0;
        //     return a;
        // }, {});

        const bodyData: any = [];

        ltpData.map((lr, ind) => {
            return bodyData.push(
                [
                    { content: lr.sem },
                    { content: lr.l },
                    { content: lr.t },
                    { content: lr.p },
                    { content: lr.cp },
                    { content: lr.c }
                ]
            )
        })

        const grandTotal: any = [];
        grandTotal.push(
            [
                { content: 'Grand Total', styles: { cellWidth: 25, fontStyle: 'bold', } },
                { content: l, styles: { cellWidth: 10 } },
                { content: t, styles: { cellWidth: 10 } },
                { content: p, styles: { cellWidth: 10 } },
                { content: cp, styles: { cellWidth: 20 } },
                { content: c, styles: { cellWidth: 16 } }
            ]
        )

        // doc.text('Summary', 15, courseList.length >= 19 ? (doc as any).lastAutoTable.finalY + 55 : (doc as any).lastAutoTable.finalY + 15)

        //2nd table-summary table
        autoTable(doc, {
            startY: courseList.length >= 19 ? (doc as any).lastAutoTable.finalY + 57 : (doc as any).lastAutoTable.finalY + 17,
            headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0], halign: "center" },
            bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0], halign: "center" },
            // didDrawPage: () => {
            //   doc.text('Summary', 15,(doc as any).lastAutoTable.finalY)
            // },
            head: [
                [
                    { content: "Summary", colSpan: 6 },
                ],
                [
                    { content: "Semester", styles: { cellWidth: 25 } },
                    { content: "L", styles: { cellWidth: 10 } },
                    { content: "T", styles: { cellWidth: 10 } },
                    { content: "P", styles: { cellWidth: 10 } },
                    { content: "Total Contact Periods", styles: { cellWidth: 20 } },
                    { content: "Credits", styles: { cellWidth: 16 } },
                ]
            ],
            body: bodyData,
            theme: 'grid',
            tableWidth: 70,
        })

        autoTable(doc, {
            startY: (doc as any).lastAutoTable.finalY,
            headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0] },
            bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0], halign: "center" },
            body: grandTotal,
            theme: 'grid',
            tableWidth: 70,
        })


        // Save the PDF
        doc.save('details_of_course.pdf');
    };

    // CSV data for the CSVLink
    const csvData = [
        [
            'S.No.',
            'Semester No',
            'Course Code',
            'Course',
            'Course Type',
            'Lecture',
            'Tutorial',
            'Practical',
            'Contact Periods',
            'Credit',
        ],
        ...filterCourseList().map((disciplineCourse, index) => [
            index + 1,
            disciplineCourse.semesterNo,
            disciplineCourse.courseSeqNo,
            getCourseName(disciplineCourse.courseId),
            disciplineCourse.courseType,
            disciplineCourse.lecture,
            disciplineCourse.tutorial,
            disciplineCourse.practical,
            disciplineCourse.contactHours,
            disciplineCourse.credit,
        ]),
    ];

    // let disciplineCourses=filterCourseList().sort((a,b)=>{
    //   return a.semesterNo-b.semesterNo
    // })
    // console.log(disciplineCourses);

    return (
        <>
            <PageContainer title="Discipline Course Report">
                <Card title="Filters">
                    <div className="row">
                        <div className="col-md-4">
                            <label className="mt-3 mb-3">Degree <span className="text-danger">*</span> :</label>
                            <select
                                className="form-control"
                                value={selectedDegreeId || ''}
                                onChange={(e) => setSelectedDegreeId(e.target.value)}
                            >
                                <option value="">Select Degree</option>
                                {selectedDegree.map((course) => (
                                    <option key={course.id} value={course.id}>
                                        {course.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-4">
                            <label className="mt-3 mb-3">Discipline <span className="text-danger">*</span> :</label>
                            <select
                                className="form-control"
                                value={selectedDisciplineId || ''}
                                onChange={(e) => setSelectedDisciplineId(e.target.value)}
                            >
                                <option value="">Select Discipline</option>
                                {selectedDiscipline.map((course) => (
                                    <option key={course.id} value={course.id}>
                                        {course.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-4">
                            <button
                                type="button"
                                className="btn btn-sm btn-primary"
                                onClick={filterStudent}
                                style={{ width: "32%", marginTop: "57px" }}
                            >
                                Search
                            </button>
                        </div>
                    </div>
                </Card>
                <CollapseCard title="Discipline Course">
                    <div className="container-fluid table-container" style={{ width: "150%" }}>
                        <table className="table table-success table-striped" style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <th className="center-align" rowSpan={2}>S.No.</th>
                                    <th className="center-align" rowSpan={2}>Sem.</th>
                                    <th className="center-align" rowSpan={2}>Course Code</th>
                                    <th className="center-align" rowSpan={2}>Course Title</th>
                                    <th className="center-align" rowSpan={2}>Course Type</th>
                                    <th className="center-align" colSpan={3}>Periods per week</th>
                                    <th className="center-align" rowSpan={2}>Contact Periods</th>
                                    <th className="narrow" rowSpan={2}>Credits</th>
                                </tr>
                                <tr>
                                    <th className="narrow">Lecture</th>
                                    <th className="narrow">Tutorial</th>
                                    <th className="narrow">Practical</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredCourses.map((disciplineCourse, index) => (
                                    <tr key={disciplineCourse.id}>
                                        <td className="report-td">{index + 1}</td>
                                        <td className="report-td">{disciplineCourse.semesterNo}</td>
                                        <td className="report-td">{getCourseShortName(disciplineCourse.courseId)}</td>
                                        <td className="report-td">{getCourseName(disciplineCourse.courseId)}</td>
                                        <td className="report-td">{disciplineCourse.courseType}</td>
                                        <td className="report-td">{disciplineCourse.lecture}</td>
                                        <td className="report-td">{disciplineCourse.tutorial}</td>
                                        <td className="report-td">{disciplineCourse.practical}</td>
                                        <td className="report-td">{disciplineCourse.contactHours}</td>
                                        <td className="report-td">{disciplineCourse.credit}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div>
                        {renderDownloadButtons()}
                    </div>
                </CollapseCard>
            </PageContainer>
        </>
    );
}

export default DisciplineCourseReport;

interface DisciplineCourse {
    id: number;
    degreeId: number;
    disciplineId: number;
    semesterNo: number;
    courseId: string;
    courseSeqNo: string;
    courseType: string;
    credit: string,
    lecture: string,
    tutorial: string,
    practical: string,
    contactHours: string,
}

interface Discipline {
    id: number;
    name: string;
}

interface Course {
    id: number;
    name: string;
    shortName: string;
}

interface Degree {
    id: number;
    name: string;
}
