import React, { FC } from 'react'
import PageContainer from '../components/custom/PageContainer';
import Card from '../components/custom/Card';
import CollapseCard from '../components/custom/CollapseCard';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { CSVLink } from 'react-csv';
import * as Feather from 'react-feather';


interface Props{
    title : string;
}
const DemoPage:FC<Props> = (props:Props) => {
    const {title} = props;
    const fileName = "sample";
   
    const data =  [
        ['S.No', 'Date', 'Account Name', 'Particulars', 'Cash In', ' Cash Out', 'Balance'],
            ['1', '01/01/2023', 'Test Account1', 'Opening Balance','15000', '13000', '2000'],
            ['2', '01/02/2023', 'Test Account2', 'Opening Balance','18000', '14000', '4000'],
            ['3', '01/03/2023', 'Test Account2', 'Opening Balance','12000', '8000', '4000'],
            ['4', '01/04/2023', 'Test Account1', 'Opening Balance','5000', '3000', '2000']
        ];
    
        const head = data.filter((item, index) => index === 0);
        const body = data.filter((item, index) => index !== 0);

        const handleDownloadPDF = () => {
            const doc = new jsPDF('l', 'mm', 'a4')
            doc.setFontSize(18)
            doc.text(title, 100, 13)
            autoTable(doc, {
                head: head,
                body: body,
                // foot: foot,
                startY: 20,
                didDrawPage: function () {
                    // // Header
                    var str = doc.getNumberOfPages();
                    doc.setFontSize(10);
                    // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                    // var pageSize = doc.internal.pageSize;
                    // var pageHeight = pageSize.height
                    //     ? pageSize.height
                    //     : pageSize.getHeight();
                    doc.text(str.toString(), 260, 10);
                }
            })
            doc.save(fileName + ".pdf")
        }

    return (
        <PageContainer title={title}>
            <CollapseCard title="Sample Table">
                  <table className="table table-hover table-bordered">
                    <thead>
                        <tr>
                            {
                                head.at(0)?.map((col, i) => {
                                    return (
                                        <th key={i}>{col}</th>
                                    )
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            body.map((row, i) => {
                                return (
                                    <tr key={i}>
                                        {row.map((col, i) => {
                                            return (
                                                <td key={i}>{col}</td>
                                            )
                                        })}
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                <div className="text-end mb-3">
                 <button type="button" onClick={handleDownloadPDF} className={`m-1 btn btn-s btn-primary`}><Feather.Download /> PDF</button>
                 <CSVLink data={data} filename={fileName + ".csv"}> <button type="button" className={`m-1 btn btn-s btn-primary`}><Feather.Download /> CSV</button></CSVLink>
             </div>
            </CollapseCard>

        </PageContainer>
    );
}

export default DemoPage