import axios from 'axios';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from '../components/custom/Card';
import CollapseCard from '../components/custom/CollapseCard';
import PageContainer from '../components/custom/PageContainer';
import { apiUrl } from '../config';
import { PhaseType, addPhase, deletePhase, fetchPhase, updatePhase } from '../redux/actions/phaseAction';
import { StoreState } from '../redux/reducers';
import { AppDispatch } from '../redux/store';
import { StudentType } from './Student';

const Phase = () => {
    const dispatch = useDispatch<AppDispatch>()

    const phaseList = useSelector<StoreState, PhaseType[]>(state => state.phase)
    // const [phaseList, setPhaseList] = useState<Phase[]>([]);

    const [editFlag, setEditFlag] = useState(false);
    const [apiErrorMessage, setApiErrorMessage] = useState('');
    const [id, setId] = useState<number>(0);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    // title
    const [title, setTitle] = useState<string>('');
    const [titleError, setTitleError] = useState<string>('');
    // fromDate
    const [fromDate, setFromDate] = useState<string>('');
    const [fromDateError, setFromDateError] = useState<string>('');
    //toDate
    const [toDate, setToDate] = useState<string>('');
    const [toDateError, setToDateError] = useState<string>('');
    //batch
    const [selectedBatch, setSelectedBatch] = useState<StudentType[]>([]);
    const [batch, setBatch] = useState<string>('');
    const [batchError, setBatchError] = useState<string>('');
    //semester
    const [semester, setSemester] = useState<string>('');
    const [semesterError, setSemesterError] = useState<string>('');

    useEffect(() => {
        axios.get(`${apiUrl}/student/`)
            .then((response) => {
                setSelectedBatch(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching student', error);
            });
    }, []);


    useEffect(() => {
        dispatch(fetchPhase())
    }, [])

    const formatDate = (inputDate: string) => {
        const date = new Date(inputDate);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedMonth = month < 10 ? `0${month}` : month;

        return `${formattedDay}-${formattedMonth}-${year}`;
    };

    //add phase
    const addHandler = () => {
        let error = false
        if (title.length === 0) {
            setTitleError('Title is required');
            error = true
        }

        if (fromDate.length === 0) {
            setFromDateError('FromDate is required');
            error = true
        }

        if (toDate.length === 0) {
            setToDateError('ToDate is required');
            error = true
        }

        if (batch.length === 0) {
            setBatchError('Batch is required');
            error = true
        }
        if (semester.length === 0) {
            setSemesterError('Semester is required');
            error = true
        }
        if (title.length > 30) {
            setTitleError('Title be within 30 characters');
            error = true
        }
        if (fromDate.length > 10) {
            setFromDateError('From Date must be within 10 characters');
            error = true
        }
        if (toDate.length > 10) {
            setToDateError('To Date must be within 10 characters');
            error = true
        }
        if (batch.length > 4) {
            setBatchError('Batch must be within 4 characters');
            error = true
        }
        if (semester.length > 11) {
            setSemesterError('Semester must be within 11 characters');
            error = true
        }

        const addData = {
            title,
            fromDate,
            toDate,
            batch,
            semester: parseInt(semester)
        };

        if (!error) {
            dispatch(addPhase(addData)).then((text) => {
                toast.success(text)
                clearHandler()
            }).catch(text => {
                toast.error(text)
            })
        }

    }

    //edit phase
    const editHandler = (ph: PhaseType) => {
        clearHandler()
        setEditFlag(true);
        setId(ph.id ? ph.id : 0);
        setTitle(ph.title);
        setFromDate(ph.fromDate);
        setToDate(ph.toDate);
        setBatch(ph.batch);
        setSemester(ph.semester?.toString());
    }

    //update phase
    const updateHandler = () => {
        let error = false

        if (title.length === 0) {
            setTitleError('Title is required');
            error = true
        }

        if (fromDate.length === 0) {
            setFromDateError('From date is required');
            error = true
        }

        if (toDate.length === 0) {
            setToDateError('To date is required');
            error = true
        }

        if (batch.length === 0) {
            setBatchError('Batch is required');
            error = true
        }
        if (semester.length === 0) {
            setSemesterError('Semester is required');
            error = true
        }
        if (title.length > 30) {
            setTitleError('Title be within 30 characters');
            error = true

        }
        if (fromDate.length > 10) {
            setFromDateError('From Date must be within 10 characters');
            error = true

        }
        if (toDate.length > 10) {
            setToDateError('To Date must be within 10 characters');
            error = true

        }
        if (batch.length > 4) {
            setBatchError('Batch must be within 4 characters');
            error = true
        }
        if (semester.length > 11) {
            setSemesterError('Semester must be within 11 characters');
            error = true
        }

        const editData = {
            id,
            title,
            fromDate,
            toDate,
            batch,
            semester: parseInt(semester)
        };

        if (!error) {
            dispatch(updatePhase(editData, id)).then((text) => {
                toast.success(text)
                clearHandler()
            }).catch(text => {
                toast.error(text)
            })
        }
    }

    const clearHandler = () => {
        setTitle('');
        setFromDate('');
        setToDate('');
        setBatch('');
        setSemester('');
        setTitleError('');
        setFromDateError('');
        setToDateError('');
        setBatchError('');
        setSemesterError('');
        setId(0);
        setEditFlag(false);
    };

    const deleteHandler = (id: number) => {
        setId(id);
        openDeleteConfirmation();
    }

    const confirmDeleteHandler = () => {
        if (id !== undefined) {
            dispatch(deletePhase(id)).then(text => {
                toast.success(text)
                closeDeleteConfirmation()
            }).catch(text => {
                toast.error(text)
            })
        }
    }

    const openDeleteConfirmation = () => {
        setShowDeleteConfirmation(true);
    }

    const closeDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    }

    // const formatDate = (dateString: string | number | Date): string => {
    //     const options: DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
    //     return new Date(dateString).toLocaleDateString('en-GB', options);
    // };

    const getUniqueBatch = () => {
        const uniqueBatch = Array.from(new Set(selectedBatch.map((batch) => batch.batch)));
        return uniqueBatch;
    };

    return (
        <>
            <PageContainer title="Phase">
                <Card title={editFlag ? 'Edit Phase' : 'Add Phase'}>
                    <div className='container m-0'>
                        <div className="row">
                            <div className="col-4">
                                <label className="mt-3 mb-3">Title <span className="text-danger">*</span> : </label>
                                <input className="form-control" placeholder="Enter title..." type="text" name="title" onChange={(e) => {
                                    setTitle(e.target.value);
                                    setTitleError('');
                                }}
                                    value={title} />
                                {titleError && <div className="text-danger">{titleError}</div>}
                            </div>

                            <div className="col-4">
                                <label className="mt-3 mb-3">From Date <span className='text-danger'>*</span> : </label>
                                <input
                                    className="form-control"
                                    placeholder="Enter From Date..."
                                    type="date"
                                    name="dateOfBirth"
                                    onChange={(e) => {
                                        setFromDateError('');
                                        setFromDate(e.target.value); // Set the raw date to the state
                                    }}
                                    value={fromDate}
                                />
                                {fromDateError && <div className="text-danger">{fromDateError}</div>}
                            </div>

                            <div className="col-4">
                                <label className="mt-3 mb-3">To Date <span className='text-danger'>*</span> : </label>
                                <input className="form-control" placeholder="Enter To Date..." type="date" name="dateOfBirth" onChange={(e) => {
                                    setToDate(e.target.value)
                                    setToDateError('');
                                    setApiErrorMessage('');
                                }} value={toDate} />
                                {toDateError && <div className="text-danger">{toDateError}</div>}
                            </div>

                            <div className="col-4">
                                <label className="mt-3 mb-3">batch <span className='text-danger'>*</span> : </label>
                                <select
                                    className="form-control"
                                    style={{ width: "100%", marginRight: "20px" }}
                                    name="selectedBatch"
                                    value={batch}
                                    onChange={(e) => {
                                        setBatch(e.target.value)
                                        setBatchError('')
                                    }}
                                >
                                    <option value="" selected>Select Batch</option>
                                    {getUniqueBatch().map((batch) => (
                                        <option key={batch} value={batch}>
                                            {batch}
                                        </option>
                                    ))}
                                </select>
                                {batchError && <div className="text-danger">{batchError}</div>}
                            </div>

                            <div className="col-4">
                                <label className="mt-3 mb-3">Semester No <span className="text-danger">*</span> : </label>

                                <select
                                    className="form-control"
                                    value={semester}
                                    onChange={(e) => {
                                        setSemester(e.target.value);
                                        setSemesterError('');
                                        setApiErrorMessage('');
                                    }}>
                                    <option value="">Select Semester</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                </select>
                                {semesterError && <div className="text-danger">{semesterError}</div>}
                            </div>
                        </div>
                    </div>
                    <div className="m-4 d-flex justify-content-end">
                        <button className="btn btn-sm btn-secondary" onClick={clearHandler} style={{ marginRight: '10px' }}>Clear</button>
                        {editFlag ? <button className="btn btn-sm btn-success" onClick={updateHandler}>Update</button> :
                            <button className="btn btn-sm btn-success" onClick={addHandler}>Add</button>}
                    </div>
                </Card>
                <CollapseCard title="Phase">
                    <div className="container-fluid">
                        <table className="table table-success table-striped">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>title</th>
                                    <th>from Date</th>
                                    <th>to Date</th>
                                    <th>batch</th>
                                    <th>semester</th>
                                    <th>Options</th>
                                </tr>
                            </thead>
                            <tbody>
                                {phaseList.map((ph, index) => (
                                    <tr key={ph.id}>
                                        <td>{index + 1}</td>
                                        <td>{ph.title}</td>
                                        <td>{formatDate(ph.fromDate)}</td>
                                        <td>{formatDate(ph.toDate)}</td>
                                        <td>{ph.batch}</td>
                                        <td>{ph.semester}</td>
                                        <td>
                                            <button className="btn btn-sm btn-primary" style={{ marginRight: '10px' }} onClick={() => editHandler(ph)}>Edit</button>
                                            <button className="btn btn-sm btn-danger" onClick={() => deleteHandler(ph.id ? ph.id : 0)}>Delete</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </CollapseCard>
                {/* Deletion Confirmation Modal */}
                <Modal show={showDeleteConfirmation} onHide={closeDeleteConfirmation}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Deletion</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to delete this item?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeDeleteConfirmation}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={confirmDeleteHandler}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/* Add the ToastContainer for displaying toasts */}
                <ToastContainer position="top-right" />
            </PageContainer>
        </>
    );
}

export default Phase;


