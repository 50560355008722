/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { GetState } from "../reducers"

export enum ExamActionList {
    ADD_EXAM = 'ADD_EXAM',
    FETCH_EXAM = 'FETCH_EXAM',
    UPDATE_EXAM = 'UPDATE_EXAM',
    DELETE_EXAM = 'DELETE_EXAM',
}

export interface ExamType {
    id?: number
    title: string
    monthYear:string
}

export interface AddExamAction {
    type: ExamActionList.ADD_EXAM
    data: ExamType
}

export interface FetchExamAction {
    type: ExamActionList.FETCH_EXAM
    data: Array<ExamType>
}

export interface UpdateExamAction {
    type: ExamActionList.UPDATE_EXAM
    data: ExamType
}

export interface DeleteExamAction {
    type: ExamActionList.DELETE_EXAM
    data: number
}

export type ExamActions = AddExamAction | FetchExamAction | UpdateExamAction | DeleteExamAction

export const addExam = (data: ExamType) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.post<APIResponse<ExamType>>('exam/', data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<AddExamAction>({
                    type: ExamActionList.ADD_EXAM,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Exam Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })
    }
}

export const updateExam = (data: ExamType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.put<APIResponse<ExamType>>(`exam/?id=${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<UpdateExamAction>({
                    type: ExamActionList.UPDATE_EXAM,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Exam Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchExam = () => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<ExamType[]>>('exam/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchExamAction>({
                    type: ExamActionList.FETCH_EXAM,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteExam = (id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.delete<APIResponse<ExamType>>(`exam/?id=${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteExamAction>({
                    type: ExamActionList.DELETE_EXAM,
                    data: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Exam Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}
