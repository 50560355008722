import axios from 'axios';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from '../components/custom/Card';
import CollapseCard from '../components/custom/CollapseCard';
import PageContainer from '../components/custom/PageContainer';
import { apiUrl } from '../config';

const StuAttendence = () => {
    const [stuAssessmentList, setStuAttendenceList] = useState<StuAttendence[]>([]);
    const [editFlag, setEditFlag] = useState(false);
    const [registerNo, setRegisterNo] = useState('');
    const [disciplineCourseId, setDisciplineCourseId] = useState('');
    const [mark, setMark] = useState('');
    const [id, setId] = useState<number>(0);
    const [createdBy, setCreatedBy] = useState<string>('');
    const [createTimestamp, setCreateTimestamp] = useState<string>('');
    const [contactHours, setContactHours] = useState<string>('');
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const [registerNoError, setRegisterNoError] = useState('');
    const [disciplineCourseIdError, setDisciplineCourseIdError] = useState('');
    const [attendenceError, setAttendenceError] = useState('');
    const [contactHoursError, setContactHoursError] = useState<string>('');


    const [selectCourseId, setSelectCourseId] = useState<Course[]>([]);

    const [selectedRegisterNo, setSelectedRegisterNo] = useState<Student[]>([]);
    const [selectedStudent, setSelectedStudent] = useState<Student | null>(null);

    const [stuAttendenceTable, setStuAttendenceTable] = useState<StuAttendence[]>([])
    const [attendenceArray, setAttendenceArray] = useState<string[]>([]);

    const [courseList, setCourseList] = useState<DisciplineCourse[]>([]);


    //for edit Model
    const [editModelShow, setEditModelShow] = useState(false);
    const [editId, setEditId] = useState(0);
    const [editRegisterNo, setEditRegisterNo] = useState('');
    const [editCourse, setEditCourse] = useState('');
    const [editContactHours, setEditContactHours] = useState('');
    const [editAttendence, setEditAttendence] = useState('');
    const [editPhase, setEditPhase] = useState('');
    const [apiErrorMessage, setApiErrorMessage] = useState('');

    const handelAttendenceChange = (index: number, value: string) => {
        setAttendenceArray(prevArray => {
            const newArray = [...prevArray];
            newArray[index] = value;
            return newArray;
        });
    }


    //degree filter 
    const [selectedDegree, setSelectedDegree] = useState<Degree[]>([]);
    const [selectedDegreeId, setSelectedDegreeId] = useState('');
    const [degreeIdError, setDegreeIdError] = useState('');

    useEffect(() => {
        axios.get(`${apiUrl}/degree/`)
            .then((response) => {
                setSelectedDegree(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching discipline course', error);
            });
    }, [])

    //discipline filter
    const [selectedDiscipline, setSelectedDiscipline] = useState<Discipline[]>([]);
    const [selectedDisciplineId, setSelectedDisciplineId] = useState('');
    const [disciplineIdError, setDisciplineIdError] = useState('');

    useEffect(() => {
        axios.get(`${apiUrl}/discipline/`)
            .then((response) => {
                setSelectedDiscipline(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching discipline course', error);
            });
    }, [])

    //filter for course
    const [selectedCourse, setSelectedCourse] = useState<Course[]>([]);
    const [selectedCourseId, setSelectedCourseId] = useState('');
    const [courseIdError, setCourseIdError] = useState('');

    useEffect(() => {
        axios.get(`${apiUrl}/course/`)
            .then((response) => {
                setSelectedCourse(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching course', error);
            });
    }, []);

    //phase filter 
    const [selectedPhase, setSelectedPhase] = useState<Phase[]>([]);
    const [selectedPhaseId, setSelectedPhaseId] = useState('');
    const [phaseIdError, setPhaseIdError] = useState('');

    useEffect(() => {
        axios.get(`${apiUrl}/phase/`)
            .then((response) => {
                setSelectedPhase(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching phase', error);
            });
    }, []);

    //batch filter
    const [selectedBatch, setSelectedBatch] = useState<Student[]>([]);
    const [selectedBatchId, setSelectedBatchId] = useState('');
    const [batchIdError, setBatchIdError] = useState('');

    useEffect(() => {
        axios.get(`${apiUrl}/student/`)
            .then((response) => {
                setSelectedBatch(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching student', error);
            });
    }, []);

    useEffect(() => {
        axios.get(`${apiUrl}/disciplineCourse/`)
            .then((response) => {
                setCourseList(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching Discipline Course', error);
            });
    }, []);


    const filterStudent = () => {
        if (
            selectedDegreeId !== '' &&
            selectedDisciplineId !== '' &&
            selectedCourseId !== '' &&
            selectedPhaseId !== '' &&
            selectedBatchId !== '' &&
            contactHours !== ''
        ) {
            axios
                .get<{ data: StuAttendence[] }>(`${apiUrl}/studentAttendence/?degreeId=${selectedDegreeId}&disciplineId=${selectedDisciplineId}&batch=${selectedBatchId}&courseId=${selectedCourseId}`)
                .then((response) => {
                    setStuAttendenceTable(response.data.data || []);
                    console.log(response.data.data);
                })
                .catch((error) => console.log(error));
        } else {
            toast.error('Please select all filters');
        }
    };


    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        axios.get<{ data: StuAttendence[] }>(`${apiUrl}/studentAttendence/`)
            .then((response) => {
                setStuAttendenceList(response.data.data);
            })
            .catch((error) => console.log(error));
    }

    const addHandler = () => {
        if (selectedCourseId.length === 0) {
            setCourseIdError('Discipline Course  is required');
            return;
        }
        if (selectedCourseId.length > 11) {
            setCourseIdError('Course  must be within 11 characters');
            return;
        }
        if (selectedPhaseId.length === 0) {
            setPhaseIdError('Phase  is required');
            return;
        }
        if (selectedPhaseId.length > 11) {
            setPhaseIdError('Phase  must be within 11 characters');
            return;
        }
        if (contactHours.length === 0) {
            setContactHoursError('Contact Hours  is required');
            return;
        }
        if (contactHours.length > 11) {
            setContactHoursError('Contact Hours  must be within 11 characters');
            return;
        }

        if (stuAttendenceTable.length === 0) {
            toast.error('No records to add. Please use the filters to fetch records.');
            return;
        }

        const hasErrors = stuAttendenceTable.some((exam, index) => {
            if (!attendenceArray[index]?.trim()) {
                setAttendenceError(`Mark is required`);
                return true;
            }
            return false;
        })

        const getDisciplineCourseId = (courseId: string) => {
            const selectedCourseIdInt = parseInt(courseId);
            const course = selectedCourse.find((c) => c.id === selectedCourseIdInt);
            if (course) {
                const foundCourse = courseList.find((disciplineCourse) => disciplineCourse.courseId === course.id);
                if (foundCourse) {
                    return foundCourse.id;
                }
            }
            return 'N/A';
        };

        const disciplineCoursId = getDisciplineCourseId(selectedCourseId);

        const newAssessments = stuAttendenceTable.map((assessment, index) => ({
            registerNo: assessment.registerNo,
            disciplineCourseId: disciplineCoursId,
            contactHours: contactHours,
            attendedHours: attendenceArray[index],
            phase: selectedPhaseId,
            createdBy,
            createTimestamp,
        }));

        axios.post(`${apiUrl}/studentAttendence/`, newAssessments)
            .then((response) => {
                setDegreeIdError('');
                setDisciplineIdError('');
                setCourseIdError('');
                setPhaseIdError('');
                setBatchIdError('');
                setContactHoursError('');
                fetchData();
                clearHandler();
                toast.success('Student Attendences added successfully');
            })
            .catch((error) => console.log(error));
    }

    const deleteHandler = (id: number) => {
        setId(id);
        openDeleteConfirmation(); // Open the delete confirmation modal
    }

    const confirmDeleteHandler = () => {
        axios
            .delete(`${apiUrl}/studentAttendence/?id=${id}`)
            .then((response) => {
                fetchData();
                closeDeleteConfirmation();
                toast.success('Student Attendence deleted successfully');
            })
            .catch((error) => {
                console.log(error);
                toast.error('Error deleting discipline', { position: 'top-right' });
            });
    };

    const openEditModel = (id: number, registerNo: string, phase: string, course: string, contactHours: string, attendedHours: string) => {
        setEditId(id);
        setEditRegisterNo(registerNo);
        setEditCourse(course);
        setEditContactHours(contactHours);
        setEditAttendence(attendedHours);
        setEditPhase(phase);
        setEditModelShow(true);
    }

    const saveEdit = () => {
        let hasErrors = false;

        if (editAttendence.trim() === '') {
            setAttendenceError('Mark is required');
            hasErrors = true;
        } else {
            setEditAttendence('');
        }

        if (hasErrors) {
            return;
        }

        const getDisciplineCourseId = (courseId: string) => {
            const selectedCourseIdInt = courseId;
            const course = selectedCourse.find((c) => c.name === selectedCourseIdInt);
            if (course) {
                console.log(course);
                const foundCourse = courseList.find((disciplineCourse) => disciplineCourse.courseId === course.id);
                if (foundCourse) {
                    return foundCourse.id;
                }
            }
            return 'N/A';
        };

        const getPhaseId = (phaseId: string) => {
            const course = selectedPhase.find((e) => e.title === phaseId);
            return course ? course.id : 'N/A';
        };

        const editData = {
            id: editId,
            registerNo: editRegisterNo,
            disciplineCourseId: getDisciplineCourseId(editCourse),
            phase: getPhaseId(editPhase),
            contactHours: editContactHours,
            attendedHours: editAttendence,
        }
        axios
            .put(`${apiUrl}/studentAttendence/?id=${editId}`, editData)
            .then((response) => {
                if (response.data.error) {
                    toast.error('Failed to update Student Attendence', { position: 'top-right' });
                    setApiErrorMessage(response.data.message);
                } else {
                    toast.success('Student Attendence updated successfully', { position: 'top-right' });
                    setEditModelShow(false);
                    setAttendenceError('');
                    fetchData();
                }
            })
            .catch((error) => {
                console.error(error);
            });

    }

    const clearHandler = () => {
        setId(0);
        setAttendenceError('');
        setEditFlag(false);
        setSelectedDegreeId('');
        setSelectedDisciplineId('');
        setSelectedCourseId('');
        setSelectedPhaseId('');
        setSelectedBatchId('');
        setDegreeIdError('');
        setDisciplineIdError('');
        setCourseIdError('');
        setPhaseIdError('');
        setBatchIdError('');
        setAttendenceArray([]);
        setStuAttendenceTable([]);
        setContactHours('');
        setContactHoursError('');
    };


    const openDeleteConfirmation = () => {
        setShowDeleteConfirmation(true);
    }

    const closeDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    }

    const getCourseId = (disciplineCourseId: StuAttendence) => {
        const selectedCourseIdInt = parseInt(disciplineCourseId.disciplineCourseId);
        const course = courseList.find((c) => c.id === selectedCourseIdInt);

        if (course) {
            const foundCourse = selectedCourse.find((disciplineCourse) => disciplineCourse.id === course.courseId);
            if (foundCourse) {
                return foundCourse.name;
            }
        }

        return 'N/A';
    };

    const getPhaseId = (disciplineCourse: StuAttendence) => {
        const course = selectedPhase.find((disc) => disc.id === parseInt(disciplineCourse.phase));
        if (course) {
            return course.title;
        }
        return 'N/A';
    };

    const getUniqueBatch = () => {
        const uniqueBatch = Array.from(new Set(selectedBatch.map((batch) => batch.batch)));
        return uniqueBatch;
    };



    return (
        <PageContainer title="Student Attendance">
            <Card title="Add Student Attendance">
                <div className='container m-0'>
                    <div className="row">

                        <div className="col-4">
                            <label className="mt-3 mb-3">Degree<span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                style={{ width: "100%", marginRight: "20px" }}
                                name="selectedDegree"
                                value={selectedDegreeId}
                                onChange={(e) => setSelectedDegreeId(e.target.value)}
                            >
                                <option value="" selected>Select Degree</option>
                                {selectedDegree.map((course) => (
                                    <option key={course.id} value={course.id}>
                                        {course.name}
                                    </option>
                                ))}
                            </select>
                            {degreeIdError && <div className="text-danger">{degreeIdError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Discipline<span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                style={{ width: "100%", marginRight: "20px", }}
                                name="selectedDiscipline"
                                value={selectedDisciplineId}
                                onChange={(e) => setSelectedDisciplineId(e.target.value)}
                            >
                                <option value="" selected>Select Discipline</option>
                                {selectedDiscipline.map((course) => (
                                    <option key={course.id} value={course.id}>
                                        {course.name}
                                    </option>
                                ))}
                            </select>
                            {disciplineIdError && <div className="text-danger">{disciplineIdError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Course<span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                style={{ width: "100%", marginRight: "20px" }}
                                name="selectedCourse"
                                value={selectedCourseId}
                                onChange={(e) => setSelectedCourseId(e.target.value)}
                            >
                                <option value="" selected>Select Course</option>
                                {selectedCourse.map((course) => (
                                    <option key={course.id} value={course.id}>
                                        {course.name}
                                    </option>
                                ))}
                            </select>
                            {courseIdError && <div className="text-danger">{courseIdError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Phase<span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                style={{ width: "100%", marginRight: "20px" }}
                                name="selectedPhase"
                                value={selectedPhaseId}
                                onChange={(e) => setSelectedPhaseId(e.target.value)}
                            >
                                <option value="" selected>Select Phase</option>
                                {/* <option value="0">All Phase</option> */}
                                {selectedPhase.map((course) => (
                                    <option key={course.id} value={course.id}>
                                        {course.title}
                                    </option>
                                ))}
                            </select>
                            {phaseIdError && <div className="text-danger">{phaseIdError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Batch<span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                style={{ width: "100%", marginRight: "20px" }}
                                name="selectedBatch"
                                value={selectedBatchId}
                                onChange={(e) => setSelectedBatchId(e.target.value)}
                            >
                                <option value="" selected>Select Batch</option>
                                {getUniqueBatch().map((batch) => (
                                    <option key={batch} value={batch}>
                                        {batch}
                                    </option>
                                ))}
                            </select>
                            {batchIdError && <div className="text-danger">{batchIdError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Actual Contact Hours <span className="text-danger">*</span> : </label>
                            <input className="form-control" placeholder="Enter Contact Hours..." type="text" name="ContactHours" onChange={(e) => {
                                setContactHours(e.target.value);
                                setContactHoursError('');
                                setApiErrorMessage('');
                            }} value={contactHours} />
                            {contactHoursError && <div className="text-danger">{contactHoursError}</div>}
                        </div>

                        <div className="col-12">
                            <button
                                type="button"
                                className="btn btn-sm btn-primary"
                                onClick={filterStudent}
                                style={{ width: "10%", marginTop: "30px", marginBottom: "30px", float: "right" }}
                            >
                                search
                            </button>
                        </div>
                    </div>
                </div>
                <div className="container-fluid" style={{ width: "100%" }}>
                    <table className="table table-success table-striped">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Register No</th>
                                {/* <th>Phase</th> */}
                                <th>Contact Hours </th>
                                <th>Attendance Hour <span className="text-danger">*</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            {stuAttendenceTable.map((StuAttendence, index) => (
                                <tr key={StuAttendence.id}>
                                    <td>{index + 1}</td>
                                    <td>{StuAttendence.registerNo}</td>
                                    {/* <td>{StuAttendence.phase}</td> */}
                                    <td>{contactHours}</td>
                                    <td>
                                        <input
                                            className="form-control"
                                            placeholder="Enter Attendance Hour..."
                                            type="text"
                                            name={`attendence-${index}`}
                                            onChange={(e) => handelAttendenceChange(index, e.target.value)}
                                            value={attendenceArray[index] || ''} style={{ width: "50%" }}
                                        />
                                        {attendenceError && <div className="text-danger">{attendenceError}</div>}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="col d-flex justify-content-end" style={{ width: "95%" }}>
                    <button className="btn btn-sm btn-secondary" onClick={clearHandler} style={{ marginRight: '10px' }}>
                        Clear
                    </button>
                    {/* {editFlag ? <button className="btn btn-success" onClick={updateHandler}>Update</button> : */}
                    <button className="btn btn-sm btn-success" onClick={addHandler}>Add</button>
                </div>
            </Card>
            <CollapseCard title="Student Attendance">
                <div className="container-fluid" style={{ width: "100%" }}>
                    <table className="table table-success table-striped">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Register No</th>
                                <th>Phase</th>
                                <th>Discipline Course </th>
                                <th>Actual Contact Hours</th>
                                <th>Attendance Hour</th>
                                <th>Created By</th>
                                <th>Create Timestamp</th>
                                <th>Options</th>
                            </tr>
                        </thead>
                        <tbody>
                            {stuAssessmentList.map((StuAttendence, index) => (
                                <tr key={StuAttendence.id}>
                                    <td>{index + 1}</td>
                                    <td>{StuAttendence.registerNo}</td>
                                    <td>{getPhaseId(StuAttendence)}</td>
                                    <td>{getCourseId(StuAttendence)}</td>
                                    <td>{StuAttendence.contactHours}</td>
                                    <td>{StuAttendence.attendedHours}</td>
                                    <td>{StuAttendence.createdBy}</td>
                                    <td>{StuAttendence.createTimestamp}</td>
                                    <td>
                                        <button className="btn btn-sm btn-primary" style={{ marginRight: '10px' }} onClick={() => openEditModel(StuAttendence.id, StuAttendence.registerNo, getPhaseId(StuAttendence), getCourseId(StuAttendence), StuAttendence.contactHours, StuAttendence.attendedHours)}>Edit</button>
                                        <button className="btn btn-sm btn-danger" onClick={() => deleteHandler(StuAttendence.id)}>Delete</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </CollapseCard>
            <Modal show={editModelShow} onHide={() => setEditModelShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Exam Register</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-6">
                            <label className="mt-3 mb-3">Register No:</label>
                            <input className="form-control" type="text" value={editRegisterNo} readOnly />
                        </div>

                        <div className="col-6">
                            <label className="mt-3 mb-3">Discipline Course:</label>
                            <input className="form-control" type="text" value={editCourse} readOnly />
                        </div>

                        <div className="col-6">
                            <label className="mt-3 mb-3">Phase:</label>
                            <input className="form-control" type="text" value={editPhase} readOnly />
                        </div>

                        <div className="col-6">
                            <label className="mt-3 mb-3">Actual Contact Hours:</label>
                            <input className="form-control" type="text" value={editContactHours} readOnly />
                        </div>

                        <div className="col-6">
                            <label className="mt-3 mb-3">Attendance Hour <span className="text-danger">*</span> : </label>
                            <input
                                className="form-control"
                                placeholder="Enter Attendance Hour..."
                                type="text"
                                name="Mark"
                                onChange={(e) => {
                                    setEditAttendence(e.target.value);
                                    setAttendenceError('');
                                }}
                                value={editAttendence}
                            />
                            {attendenceError && <div className="text-danger">{attendenceError}</div>}
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setEditModelShow(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={saveEdit}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Deletion Confirmation Modal */}
            <Modal show={showDeleteConfirmation} onHide={closeDeleteConfirmation}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this item?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeDeleteConfirmation}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={confirmDeleteHandler}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </PageContainer>
    );
}

export default StuAttendence;



interface StuAttendence {
    id: number;
    registerNo: string;
    disciplineCourseId: string;
    contactHours: string;
    attendedHours: string;
    createdBy: string;
    createTimestamp: string;
    phase: string;
}


interface Student {
    id: number;
    registerNo: string;
    batch: string;
}

interface Course {
    id: number;
    name: string;
}

interface Discipline {
    id: number;
    name: string;
}

interface Course {
    id: number;
    name: string;
}

interface Degree {
    id: number;
    name: string;
}

interface Phase {
    id: number;
    title: string;
}

interface DisciplineCourse {
    id: number,
    courseId: number;
}