import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from '../components/custom/Card';
import CollapseCard from '../components/custom/CollapseCard';
import PageContainer from '../components/custom/PageContainer';
import { apiUrl } from '../config';
import { DegreeType } from '../redux/actions/degreeAction';
import { DisciplineType } from '../redux/actions/disciplineAction';
import { RegulationType } from '../redux/actions/regulationAction';
import { StoreState } from '../redux/reducers';


const CurrentSemesterUpdate = () => {
    const [studentList, setStudentList] = useState<StudentType[]>([]);
    const disciplineList = useSelector<StoreState, DisciplineType[]>(state => state.discipline)
    const degreeList = useSelector<StoreState, DegreeType[]>(state => state.degree)
    const regulationList = useSelector<StoreState, RegulationType[]>(state => state.regulation)

    const [batch, setBatch] = useState('');
    const [currentSemester, setCurrentSemester] = useState('');
    const [batchError, setBatchError] = useState('');
    const [currentSemesterError, setCurrentSemesterError] = useState('');

    //filter view
    const [studentListFilter, setFilteredStudentList] = useState<StudentType[]>([]);
    const [semesterFilter, setSemesterFilter] = useState('');
    const [degreeIdFilter, setDegreeIdFilter] = useState('');
    const [disciplineIdFilter, setDisciplineIdFilter] = useState('');
    const [regulationFilter, setRegulationFilter] = useState('')
    const [batchFilter, setBatchFilter] = useState('');

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        axios.get<{ data: StudentType[] }>(`${apiUrl}/student/`)
            .then((response) => {
                console.log(response);

                setStudentList(response.data.data);
            })
            .catch((error) => console.log(error));
    }

    useEffect(() => {

        const filteredStudents = studentList.filter((student) => {
            return (
                (degreeIdFilter === '' || student.degreeId?.toString() === degreeIdFilter) &&
                (disciplineIdFilter === '' || student.disciplineId?.toString() === disciplineIdFilter) &&
                (batchFilter === '' || student.batch?.toString() === batchFilter) &&
                (regulationFilter === '' || student.regulation?.toString() === regulationFilter) &&
                (semesterFilter === '' || student.currentSemester?.toString() === semesterFilter)
            );
        });

        // const checkFilteredStudents = filteredStudents.length > 0 ? filteredStudents : studentList;
        setFilteredStudentList(filteredStudents);
    }, [degreeIdFilter, disciplineIdFilter, batchFilter, regulationFilter, semesterFilter, studentList]);

    //Batch DropDown

    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let min = 0;
    let max = 5;

    let last10Years = [];
    for (let i = min; i <= max; i++) {
        let dropYear = year - i;
        last10Years.push(dropYear);
    }


    //update exam
    const updateHandler = () => {
        if (batch.length === 0 || currentSemester.length === 0) {
            setBatchError('batch is required');
            setCurrentSemesterError('current Semester is required');
        }

        if (batch.length > 4 || currentSemester.length > 11) {
            setBatchError('batch must be within 4 characters');
            setCurrentSemesterError('current semester must be within 11 characters');
        }

        const data = { batch, currentSemester };

        axios.put(`${apiUrl}/student/changeCurrentSem/?batch=${batch}`, data)
            .then((response) => {
                if (response.data.error) {
                    toast.error(response.data.message, { position: 'top-right' });
                } else {
                    toast.success(response.data.message, { position: 'top-right' });
                    fetchData();
                    clearHandler()
                }
            }).catch(error => {
                toast.error(error.response.data.message, { position: 'top-right' })
            });

    }

    const clearHandler = () => {
        setBatch('')
        setBatchError('')
        setCurrentSemester('')
        setCurrentSemesterError('')
    }

    const getDisciplineId = (student: StudentType) => {
        const discipline = disciplineList.find((disc) => disc.id === parseInt(student.disciplineId.toString()));
        if (discipline) {
            return discipline.name;
        }
        return 'N/A';
    };

    const getDegreeId = (student: StudentType) => {
        const degree = degreeList.find((disc) => disc.id === parseInt(student.degreeId.toString()));
        if (degree) {
            return degree.name;
        }
        return 'N/A';
    };

    return (
        <PageContainer title="Current Semester">
            <Card title={'Update Current Semester'}>
                <div className='container m-0'>
                    <div className="row">
                        <div className="col-3">
                            <label>Batch <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                value={batch}
                                onChange={(e) => setBatch(e.target.value)}
                            >
                                <option value=""> Select Batch </option>
                                {last10Years.map((bt) => (
                                    <option value={bt}>{bt}</option>
                                ))
                                }
                            </select>
                        </div>
                        <div className="col-3">
                            <label >Semester <span className="text-danger">*</span> :</label>
                            <select
                                className="form-control"
                                value={currentSemester}
                                onChange={(e) => setCurrentSemester(e.target.value)}
                            >
                                <option value="" >Select Semester</option>
                                <option value="1" >1</option>
                                <option value="2" >2</option>
                                <option value="3" >3</option>
                                <option value="4" >4</option>
                                <option value="5" >5</option>
                                <option value="6" >6</option>
                                <option value="7" >7</option>
                                <option value="8" >8</option>

                            </select>
                        </div>

                        <div className=" col-3 mt-4 pt-2">
                            <button className="btn btn-sm btn-secondary" onClick={clearHandler} style={{ marginRight: '10px' }}>
                                Clear
                            </button>
                            <button className="btn btn-sm btn-success" onClick={updateHandler}>Update</button>
                        </div>
                    </div>
                </div>
            </Card>

            <CollapseCard title="Student">
                {/* <div className='container m-3'> */}
                <div className="row m-2 mb-3">
                    <div className="col">
                        <label className="mt-2 mb-2">degree : </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px" }}
                            name="degreeId"
                            value={degreeIdFilter}
                            onChange={(e) => setDegreeIdFilter(e.target.value)}
                        >
                            <option value="" selected>Select Degree</option>
                            {degreeList.map((deg) => (
                                <option key={deg.id} value={deg.id}>
                                    {deg.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col">
                        <label className="mt-2 mb-2">discipline : </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px" }}
                            name="disciplineId"
                            value={disciplineIdFilter}
                            onChange={(e) => setDisciplineIdFilter(e.target.value)}
                        >
                            <option value="" selected>Select Discipline</option>
                            {disciplineList.map((dis) => (
                                <option key={dis.id} value={dis.id}>
                                    {dis.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col">
                        <label className="mt-2 mb-2">batch : </label>
                        <select name="" id="" className='form-control' value={batchFilter} onChange={(e) => {
                            setBatchFilter(e.target.value)
                        }}>
                            <option value="">Select Batch</option>
                            {last10Years.map((ly => {
                                return <option key={ly} value={ly}>{ly}</option>
                            }))}
                        </select>
                    </div>

                    <div className="col">
                        <label className="mt-2 mb-2">Regulation : </label>
                        <select name="" id="" className='form-control' onChange={(e) => {
                            setRegulationFilter(e.target.value)
                        }} value={regulationFilter}>
                            <option value="">Select Regulation</option>
                            {regulationList.map((sr) => (
                                <option key={sr.id} value={sr.title}>{sr.title}</option>
                            ))
                            }
                        </select>
                    </div>

                    <div className="col">
                        <label className="mt-2 mb-2">Semester : </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px" }}
                            name="selectedBatch"
                            value={semesterFilter}
                            onChange={(e) => setSemesterFilter(e.target.value)}
                        >
                            <option value="" selected>Select Semester</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                        </select>
                    </div>
                </div>
                {/* </div> */}

                <div className="container-fluid" >
                    <table className="table table-success table-striped">
                        <thead>
                            <tr>
                                <th>S.No.</th>
                                <th>register No & Name & D.No</th>
                                <th>Degree & Discipline</th>
                                <th>Batch  </th>
                                <th>Regulation</th>
                                <th>Current Semester</th>

                            </tr>
                        </thead>
                        <tbody>
                            {studentListFilter.map((student, index) => (
                                <tr key={student.id}>
                                    <td>{index + 1}</td>
                                    <td>{student.registerNo}<br />{student.name}<br />{student.departmentRegisterNumber}</td>
                                    <td>{getDegreeId(student)} {getDisciplineId(student)}</td>
                                    <td>{student.batch}</td>
                                    <td>{student.regulation}</td>
                                    <td>{student.currentSemester}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </CollapseCard>
        </PageContainer>
    );
}

export default CurrentSemesterUpdate;


export interface StudentType {
    id: number;
    registerNo: string;
    regulation: number;
    departmentRegisterNumber: string;
    name: string;
    fatherName: string;
    motherName: string;
    guardianName: string;
    bloodGroup: string;
    gender: string;
    dateOfBirth: string;
    aadharNumber: string;
    address: string;
    pincode: number;
    emailId: string;
    contactNumber: string;
    parentContactNumber: number;
    community: string;
    caste: string;
    religion: string;
    state: string;
    nationality: string;
    motherTongue: string;
    degreeId: number;
    disciplineId: number;
    modeOfAdmission: string;
    batch: string;
    entryLevel: string;
    dateOfJoin: string;
    noOfYears: number;
    currentSemester: number;
    photoUrl: string;
    createdBy: string;
    createTimestamp: string;
}