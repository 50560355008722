import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from '../components/custom/Card';
import CollapseCard from '../components/custom/CollapseCard';
import PageContainer from '../components/custom/PageContainer';
import { DisciplineType, addDiscipline, deleteDiscipline, fetchDiscipline, updateDiscipline } from '../redux/actions/disciplineAction';
import { StoreState } from '../redux/reducers';
import { AppDispatch } from '../redux/store';


const Discipline = () => {
    const dispatch = useDispatch<AppDispatch>()

    const disciplineList = useSelector<StoreState, DisciplineType[]>(state => state.discipline)
    // const [disciplineList, setDisciplineList] = useState<Discipline[]>([]);

    const [editFlag, setEditFlag] = useState(false);
    const [name, setName] = useState<string>('');
    const [nameError, setNameError] = useState<string>('');

    const [shortName, setShortName] = useState<string>('');
    const [shortNameError, setShortNameError] = useState<string>('');

    const [id, setId] = useState<number>(0);
    const [createdBy, setCreatedBy] = useState<string>('');
    const [createTimestamp, setCreateTimestamp] = useState<string>('');
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [apiErrorMessage, setApiErrorMessage] = useState('');

    //ADD
    useEffect(() => {
        dispatch(fetchDiscipline())
    }, [])

    const addHandler = () => {
        if (name.length === 0 || shortName.length === 0) {
                setNameError('Name is required');
                setShortNameError('Short Name is required');
        }

        if (name.length > 100 || shortName.length > 10) {
            setNameError('Name must be within 100 characters');
            setShortNameError('Short name must be within 10 characters');
        }
    

        const addData = {
            name: name,
            shortName: shortName,
            createdBy,
            createTimestamp
        }

        dispatch(addDiscipline(addData)).then(text => {
            toast.success(text)
            clearHandler();
        }).catch(text => {
            toast.error(text)
        })
    }





    //EDIT
    const editHandler = (disc: DisciplineType) => {
        setEditFlag(true);
        setId(disc.id ? disc.id : 0);
        setName(disc.name);
        setShortName(disc.shortName);
        setCreatedBy(createdBy);
        setCreateTimestamp(createTimestamp);
    }

    //UPDATE
    const updateHandler = () => {
        if (name.length === 0 || shortName.length === 0) {
            setNameError('Name is required');
            setShortNameError('Short Name is required');
            }

            if (name.length > 100 || shortName.length > 10) {
                setNameError('Name must be within 100 characters');
                setShortNameError('Short name must be within 10 characters');
            }


        const editData = {
            id: id,
            name: name,
            shortName: shortName,
            createdBy,
            createTimestamp
        }


        dispatch(updateDiscipline(editData, id)).then(text => {
            toast.success(text)
            clearHandler();
        }).catch(text => {
            toast.error(text)
        })
    }

    const clearHandler = () => {
        setName('');
        setShortName('');
        setId(0);
        setCreatedBy('');
        setCreateTimestamp('');
        setEditFlag(false);
        setNameError('');
        setShortNameError('');
    }

    //DELETE
    const deleteHandler = (id: number) => {
        setId(id);
        openDeleteConfirmation(); // Open the delete confirmation modal
    }

    const confirmDeleteHandler = () => {
        if (id !== undefined) {
            dispatch(deleteDiscipline(id)).then(text => {
                toast.success(text)
                closeDeleteConfirmation();
            }).catch(text => {
                toast.error(text);
            })
        }
    }


    const openDeleteConfirmation = () => {
        setShowDeleteConfirmation(true);
    }

    const closeDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    }

    return (
        <PageContainer title="Discipline">
            <Card title={editFlag ? "Edit Discipline" : "Add Discipline"}>
                <div className='container m-0'>
                    <div className="row">
                        <div className="col-6">
                            {apiErrorMessage && <div className="text-danger">{apiErrorMessage}</div>}

                            <label className="mt-3 mb-3">Name <span className="text-danger">*</span> : </label>
                            <input className="form-control" placeholder="Enter name..." type="text" name="name" onChange={(e) => {
                                setName(e.target.value);
                                setNameError('');
                                setApiErrorMessage('');
                            }} value={name} />
                            {nameError && <div className="text-danger">{nameError}</div>}</div>


                        <div className="col-6"><label className="mt-3 mb-3">Short Name <span className="text-danger">*</span> : </label>
                            <input className="form-control" placeholder="Enter short name..." type="text" name="shortName" onChange={(e) => {
                                setShortName(e.target.value);
                                setShortNameError('');
                                setApiErrorMessage('');
                            }} value={shortName} />
                            {shortNameError && <div className="text-danger">{shortNameError}</div>}
                        </div>
                    </div>
                    <div className="m-4 d-flex justify-content-end">
                        <button className="btn btn-sm  btn-secondary" onClick={clearHandler} style={{ marginRight: '10px' }}>
                            Clear
                        </button>
                        {editFlag ? <button className="btn btn-sm  btn-success" onClick={updateHandler}>Update</button> :
                            <button className="btn btn-sm btn-success" onClick={addHandler}>Add</button>}
                    </div>
                </div>
            </Card>
            <CollapseCard title="Discipline">
                <div className="container-fluid" style={{ width: "100%" }}>
                    <table className="table table-success table-striped">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Name</th>
                                <th>Short Name</th>
                                <th>Options</th>
                            </tr>
                        </thead>
                        <tbody>
                            {disciplineList.map((disc, index) => (
                                <tr key={disc.id}>
                                    <td>{index + 1}</td>
                                    <td>{disc.name}</td>
                                    <td>{disc.shortName}</td>
                                    <td>
                                        <button className="btn btn-sm btn-primary me-2" style={{ marginRight: "10px" }} onClick={() => editHandler(disc)}>Edit</button>
                                        <button className="btn btn-sm btn-danger me-2" onClick={() => deleteHandler(disc.id ? disc.id : 0)}>Delete</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </CollapseCard>
            {/* Deletion Confirmation Modal */}
            <Modal show={showDeleteConfirmation} onHide={closeDeleteConfirmation}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this item?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeDeleteConfirmation}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={confirmDeleteHandler}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Add the ToastContainer for displaying toasts */}
            <ToastContainer position="top-right" />
        </PageContainer>
    );
}

export default Discipline;

interface Discipline {
    id: number;
    name: string;
    shortName: string;
    createdBy: string;
    createTimestamp: string;
}
