import { CollegeActionList, CollegeType } from "../actions/collegeActions"
import { Action } from "../actions/types"

const initialValue: Array<CollegeType> = []

const collegeReducer = (state: Array<CollegeType> = initialValue, action: Action) => {
    switch (action.type) {

        case CollegeActionList.ADD_COLLEGE:
            return [
                ...state,
                action.data,
            ]

        case CollegeActionList.UPDATE_COLLEGE:
            const updated = [...state].slice()
            const index = updated.findIndex(u => u.id === action.data.id)

            if (index > -1) {
                updated[index] = {
                    ...action.data
                }
            }

            return updated

        case CollegeActionList.FETCH_COLLEGE:
            return action.data

        case CollegeActionList.DELETE_COLLEGE:
            return state.filter(s => s.id !== action.data)

        default:
            return state
    }
}

export default collegeReducer