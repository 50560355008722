import { combineReducers } from "redux";
import { AuthUserActionList } from "../actions/authUserActions";
import { Action } from "../actions/types";
import authUserReducer from "./authUserReducer";
import collegeReducer from "./collegeReducer";
import courseReducer from "./courseReducer";
import degreeReducer from "./degreeReducer";
import disciplineCourseReducer from "./disciplineCourseReducer";
import disciplineReducer from "./disciplineReducer";
import dropdownReducer from "./dropdownReducer";
import examAttendanceReducer from "./examAttendanceReducer";
import examReducer from "./examReducer";
import facultyReducer from "./facultyReducer";
import hallReducer from "./hallReducer";
import packingReducer from "./packingReducer";
import passingBoardReducer from "./passingBoardReducer";
import phaseReducer from "./phaseReducer";
import regulationReducer from "./regulationReducer";
import { studentMarkReducer } from "./studentMarkReducer";
import userReducer from "./userReducer";
import examFacultyReducer from "./examFacultyReducer";
import PracticalExamReducer from "./practicalExamReducer";

const combinedReducers = combineReducers({
    authUser: authUserReducer,
    user: userReducer,
    college: collegeReducer,
    course: courseReducer,
    faculty: facultyReducer,
    passingBoard: passingBoardReducer,
    packing: packingReducer,
    dropDown: dropdownReducer,
    studentMark: studentMarkReducer,
    degree: degreeReducer,
    discipline: disciplineReducer,
    disciplineCourse: disciplineCourseReducer,
    regulation: regulationReducer,
    phase: phaseReducer,
    exam: examReducer,
    hall: hallReducer,
    examAttendance: examAttendanceReducer,
    examFaculty: examFacultyReducer,
    practicalExam: PracticalExamReducer
})

const reducers = (state: StoreState | undefined, action: Action) => {
    if (action.type === AuthUserActionList.LOGOUT_USER) {
        state = undefined
    }
    return combinedReducers(state, action)
}

export type StoreState = ReturnType<typeof combinedReducers>
export type GetState = () => StoreState

export default reducers