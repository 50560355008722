// import { FC } from 'react';

// const Login: FC = () => {
//     return (
//         // <div className="login-box">
//         //     <div className="login-logo">
//         //         <a href="../../index2.html"><b>Admin</b>LTE</a>
//         //     </div>

//         //     <div className="card">
//         //         <div className="card-body login-card-body">
//         //             <p className="login-box-msg">Sign in to start your session</p>

//         //             <form action="../../index3.html" method="post">
//         //                 <div className="input-group mb-3">
//         //                     <input type="email" className="form-control" placeholder="Email" />
//         //                     <div className="input-group-append">
//         //                         <div className="input-group-text">
//         //                             <span className="fas fa-envelope"></span>
//         //                         </div>
//         //                     </div>
//         //                 </div>
//         //                 <div className="input-group mb-3">
//         //                     <input type="password" className="form-control" placeholder="Password" />
//         //                     <div className="input-group-append">
//         //                         <div className="input-group-text">
//         //                             <span className="fas fa-lock"></span>
//         //                         </div>
//         //                     </div>
//         //                 </div>
//         //                 <div className="row">
//         //                     <div className="col-8">
//         //                         <div className="icheck-primary">
//         //                             <input type="checkbox" id="remember" />
//         //                             <label htmlFor="remember" >
//         //                                 Remember Me
//         //                             </label>
//         //                         </div>
//         //                     </div>

//         //                     <div className="col-4">
//         //                         <button type="submit" className="btn btn-primary btn-block">Sign In</button>
//         //                     </div>

//         //                 </div>
//         //             </form>

//         //             <div className="social-auth-links text-center mb-3">
//         //                 <p>- OR -</p>
//         //                 <a href="#" className="btn btn-block btn-primary">
//         //                     <i className="fab fa-facebook mr-2"></i> Sign in using Facebook
//         //                 </a>
//         //                 <a href="#" className="btn btn-block btn-danger">
//         //                     <i className="fab fa-google-plus mr-2"></i> Sign in using Google+
//         //                 </a>
//         //             </div>


//         //             <p className="mb-1">
//         //                 <a href="forgot-password.html">I forgot my password</a>
//         //             </p>
//         //             <p className="mb-0">
//         //                 <a href="register.html" className="text-center">Register a new membership</a>
//         //             </p>
//         //         </div>

//         //     </div>
//         // </div>
//     )
// }
// export default Login;

import { ChangeEventHandler, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import FormInput from '../components/custom/FormInput'
import VerticalForm from '../components/custom/components/VerticalForm'
import AuthLayout from '../components/custom/pages/AuthLayout'
import { ToastTypes, useToast } from '../components/toast/ToastProvider'
import { LoginDataType, loginUser } from '../redux/actions/authUserActions'
import { AppDispatch } from '../redux/store'

const Login = () => {
    const toast = useToast()
    const dispatch = useDispatch<AppDispatch>()

    const [loading, setLoading] = useState<boolean>(false)
    const [mounted, setMounted] = useState<boolean>(false)

    const [username, setUsername] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [passwordError, setPasswordError] = useState<string>('')
    const [usernameError, setUsernameError] = useState<string>('')

    const handleUsername: ChangeEventHandler<HTMLInputElement> = (event) => {
        setUsernameError('')
        setUsername(event.target.value)
    }

    const handlePassword: ChangeEventHandler<HTMLInputElement> = (event) => {
        setPasswordError('')
        setPassword(event.target.value)
    }

    const handleLogin = (event: any) => {
        event.preventDefault()
        const data: LoginDataType = {
            userId: username,
            password: password
        }

        let error = false

        if (data.userId === '') {
            setUsernameError('Username required')
            error = true
        }

        if (data.password === '') {
            setPasswordError('Password required')
            error = true
        }

        if (!error) {
            setLoading(true)
            dispatch(loginUser(data)).then(text => {
                toast(text)
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            }).finally(() => {
                if (mounted) {
                    setLoading(false)
                }
            })
        }
    }

    useEffect(() => {
        setMounted(true)
        return () => setMounted(false)
    }, [])

    return <AuthLayout
        helpText={'User Login'}
    >
        <VerticalForm onSubmit={handleLogin}>
            <FormInput
                label={'Username'}
                type='text'
                name='username'
                value={username}
                placeholder='Enter your Username'
                onChange={handleUsername}
                errorText={usernameError}
                containerClass='mb-3'
            />
            <FormInput
                label={'Password'}
                type='password'
                name='password'
                value={password}
                placeholder='Enter your password'
                onChange={handlePassword}
                errorText={passwordError}
                containerClass='mb-3'
            />

            <div className='text-right d-grid'>
                {loading
                    ? <Button variant='primary' type='button' disabled className='btn-sm p-2'>
                        Log In
                    </Button>
                    : <Button variant='primary' type='submit' className='btn-sm p-2'>
                        Log In
                    </Button>}
            </div>
            {/* <div className="mt-2">
                <Link to="#" className="text-primary-50">
                    Forgot your password?
                </Link>
            </div> */}

        </VerticalForm>
    </AuthLayout>
}

export default Login