import axios from 'axios';
import { FC, useEffect, useState } from 'react';
import Card from '../components/custom/Card';
import CollapseCard from '../components/custom/CollapseCard';
import PageContainer from '../components/custom/PageContainer';
// import './styles/tableReport.css';

import jsPDF from 'jspdf';
import autoTable, { RowInput } from 'jspdf-autotable';
import * as Feather from 'react-feather';
import { toast } from 'react-toastify';
import { apiUrl } from '../config';
import imgPath from './img/collegeLogo.png';



const CumulativeCoverReport = () => {
    const fileName = 'cummulative_cover_report';

    const [editFlag, setEditFlag] = useState(false);
    const [examId, setExamId] = useState<string>('');
    const [date, setDate] = useState<string>('');
    const [session, setSession] = useState<string>('');
    const [degreeId, setDegreeId] = useState<string>('');
    const [disciplineId, setDisciplineId] = useState<string>('');
    const [disciplineCourseId, setDisciplineCourseId] = useState<string>('');
    const [questionPaperCode, setQuestionPaperCode] = useState<string>('');
    const [seqNo, setSeqNo] = useState<string>('');
    const [batch, setBatch] = useState<string>('');
    const [last10Years, setLast10Years] = useState<number[]>([]);
    const [searchPerformed, setSearchPerformed] = useState(false);

    const [selectedExam, setSelectedExam] = useState<Exam[]>([]);
    const [selectedDegree, setSelectedDegree] = useState<Degree[]>([]);
    const [selectedDiscipline, setSelectedDiscipline] = useState<Discipline[]>([]);
    const [selectedCourse, setSelectedCourse] = useState<Course[]>([]);
    const [disciplineCourseList, setDisciplineCourseList] = useState<DisciplineCourse[]>([]);
    const [examAttendanceList, setExamAttendanceList] = useState<ExamAttendance[]>([]);
    const [filteredExamAttendanceList, setFilteredExamAttendanceList] = useState<ExamAttendance[]>([]);
    const [packingList, setPackingList] = useState<Packing[]>([]);
    const [filteredPackingList, setFilteredPackingList] = useState<Packing[]>([]);


    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const years = Array.from({ length: 10 }, (_, index) => currentYear - index);
        setLast10Years(years);
    }, []);

    useEffect(() => {
        axios.get(`${apiUrl}/exam/`)
            .then((response) => {
                setSelectedExam(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching degree', error);
            });
    }, [])

    useEffect(() => {
        axios.get(`${apiUrl}/degree/`)
            .then((response) => {
                setSelectedDegree(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching degree', error);
            });
    }, [])

    useEffect(() => {
        axios.get(`${apiUrl}/discipline/`)
            .then((response) => {
                setSelectedDiscipline(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching discipline', error);
            });
    }, [])

    useEffect(() => {
        axios.get(`${apiUrl}/course/`)
            .then((response) => {
                setSelectedCourse(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching discipline', error);
            });
    }, [])

    useEffect(() => {
        axios.get(`${apiUrl}/disciplineCourse/`)
            .then((response) => {
                setDisciplineCourseList(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching discipline', error);
            });
    }, [])

    useEffect(() => {
        axios.get(`${apiUrl}/packingGalley/`)
            .then((response) => {
                setPackingList(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching PackingGalley', error);
            });
    }, [])

   
    
    let disCourseId = disciplineCourseList.find(dc => dc.disciplineId == disciplineId && dc.degreeId == degreeId && dc.courseId == disciplineCourseId)?.id;
    

    const filterSeatingPlans = () => {
        if (examId !== '' && date !== '' && session !== '' && batch !== '') {
            try {
                let filteredPlans = packingList.filter(pl => 
                    (degreeId === '' || pl.degreeId == degreeId) &&
                    pl.examId == examId &&
                    pl.date == date &&
                    pl.session == session
                );
    
                setFilteredPackingList(filteredPlans);
                setSearchPerformed(true);
            } catch (error) {
                console.error('Error fetching data', error);
                toast.error('Error fetching data', { position: 'top-right', autoClose: 3000 });
            }
        } else {
            toast.error('Select All Filters', { position: 'top-right', autoClose: 3000 });
            setFilteredPackingList([]);
        }
    };
    
    


    const renderDownloadButtons = () => {
        if (searchPerformed) {
            return (
                <div className="text-end mb-3">
                    <button
                        type="button"
                        onClick={handleDownloadPDF}
                        className="m-1 btn btn-sm btn-primary"
                    >
                        <Feather.Download /> PDF
                    </button>
                </div>
            );
        }
        return null;
    };

    const getCourse = (courseId: string) => {
        const course = selectedCourse.find((c) => c.id == parseInt(courseId));
        if (course) {
            return `${course.shortName} - ${course.name}`;
        }

        return 'N/A';
    };

    const getCourseCode = (courseId: string) => {
        const course = selectedCourse.find((c) => c.id == parseInt(courseId));
        if (course) {
            return `${course.shortName}`;
        }

        return 'N/A';
    };

    const getExamName = (examId: string) => {
        const exam = selectedExam.find((c) => c.id == parseInt(examId));
        if (exam) {
            return exam.title;
        }
        return 'N/A';
    }


    const getDegreeName = (degreeId: string) => {
        const degree = selectedDegree.find((c) => c.id == parseInt(degreeId));
        if (degree) {
            return degree.name;
        }
        return 'N/A';
    }


    const getDisciplineName = (disciplineId: string) => {
        const discipline = selectedDiscipline.find((c) => c.id == parseInt(disciplineId));
        if (discipline) {
            return discipline.name;
        }
        return 'N/A';
    }

    const getCourseName = (courseId: string) => {
        const disciplineCourse = disciplineCourseList.find((dc) => dc.id === parseInt(courseId));
        if (disciplineCourse) {
            const course = selectedCourse.find((c) => c.id == parseInt(disciplineCourse.courseId));
            if (course) {
                return course.name;
            }
        }
        return 'N/A';
    };

    const getCourseCodeUsingDisCourse = (dcId: number) => {
        console.log(dcId);
        const dc = disciplineCourseList.find((dc) => dc.id === dcId);
        console.log(`dc: ${dc}`);
    
        if (dc) {
            const course = selectedCourse.find((c) => c.id === parseInt(dc.courseId));
            if (course) {
                return course.shortName;
            }
        }
    
        return 'N/A';
    };
    

    const formatDate = (inputDate: string) => {
        const date = new Date(inputDate);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedMonth = month < 10 ? `0${month}` : month;

        return `${formattedDay}-${formattedMonth}-${year}`;
    };

    const LocalFormatDate = (date: string) => {
        const dateObj = new Date(date)
        const day = dateObj.getDate().toString().padStart(2, '0')
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0')
        const year = dateObj.getFullYear()

        return [day, month, year].join('/')
    }


    const handleDownloadPDF = () => {
        const doc = new jsPDF('l', 'mm', 'a4');

        doc.addImage(imgPath, 'PNG', 50, 5, 200, 40);
        doc.setFont('Arial', 'normal', 'bold');
        doc.setTextColor(0, 0, 0);
        doc.setFontSize(16);
        doc.text(`DESPATCH OF ANSWER PAPER COVERS `, 150, 48, { align: 'center' });
        doc.setFontSize(14);
        doc.text(`(To be submitted to the office of Controller of Examinations) `, 150, 55, { align: 'center' });
        doc.text(`${getExamName(examId)}`, 150, 62, { align: 'center' });
        doc.setFontSize(16);
        doc.text(`FORM A `, 150, 68, { align: 'center' });
        doc.line(10, 73, 280, 73)
        doc.text(`Exam Date : ${formatDate(date)} Exam Session: ${session}`, 10, 83, { align: 'left' })
            
    
        const totalPresentCount = filteredPackingList.reduce((acc, row) => acc + count(row.presentList), 0);
        const totalAbsentCount = filteredPackingList.reduce((acc, row) => acc + count(row.absentList), 0);
        const totalMalpracticeCount = filteredPackingList.reduce((acc, row) => acc + count(row.malpracticeList), 0);
        const totalSpecialCaseCount = filteredPackingList.reduce((acc, row) => acc + count(row.specialCaseList), 0);
        const totalCode = filteredPackingList.reduce((acc, row) => acc + count(row.code), 0);
    
        const allAbsentRegisterNos = filteredPackingList
            .map((row) => row.absentList.split(', '))
            .flat()
            .map((registerNo) => registerNo.trim())
            .filter((registerNo) => registerNo !== '');
    
        const allMalpracticeNos = filteredPackingList
            .map((row) => row.malpracticeList.split(', '))
            .flat()
            .map((registerNo) => registerNo.trim())
            .filter((registerNo) => registerNo !== '');
    
        const allSpecialCaseNos = filteredPackingList
            .map((row) => row.specialCaseList.split(', '))
            .flat()
            .map((registerNo) => registerNo.trim())
            .filter((registerNo) => registerNo !== '');

           
    
            // FORM A
            const bodyRows:any = filteredPackingList.map((row, index) => [
                 [
                    { content: `${index + 1}`, rowSpan: 5 },
                    { content: `${row.questionPaperCode}` },
                    { content: `${getCourseCodeUsingDisCourse(parseInt(row.disciplineCourseId))}` },
                    { content: `${totalPresentCount + totalAbsentCount}` },
                    { content: `${totalPresentCount}` },
                    { content: `${totalAbsentCount}` },
                    { content: `${totalMalpracticeCount}` },
                    { content: `${totalSpecialCaseCount}` },
                    { content: `${totalPresentCount - (totalMalpracticeCount + totalSpecialCaseCount)}` },
                    { content: `${totalCode}` },
                    { content: `` }
                ],
                [
                 { content: `Register Number of Absentees : ${allAbsentRegisterNos.join(', ')}`, colSpan: 11, styles: { halign: 'left' } }
                ],
                [
                 { content: `Register Number of Malpractice : ${allMalpracticeNos.join(', ')}`, colSpan: 11, styles: { halign: 'left' } }
                ],
                [
                  { content: `Register Number of Special Case : ${allSpecialCaseNos.join(', ')}`, colSpan: 11, styles: { halign: 'left' } }
                ],
                [
                 { content: `*Special packing : Nil`, colSpan: 11, styles: { halign: 'left' } }
                 ],
                ]);
    
            autoTable(doc, {
              
                startY: 95,
                headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0], halign: 'center' },
                bodyStyles: { textColor: [0, 0, 0], lineColor: [0, 0, 0], halign: 'center', fontStyle: "bold" },
                head: [
                    [
                        { content: `S.No.` },
                        { content: `Question Paper Code` },
                        { content: `Course Code (s)` },
                        { content: `Total No. of Candidates Registered` },
                        { content: `Total No. of Candidates Present` },
                        { content: `Total No. of Candidates Absent` },
                        { content: `*Malpractice Cases` },
                        { content: `**Special Cases` },
                        { content: `Total No. of scripts to be handed over` },
                        { content: `No. of Answer Paper Covers` },
                        { content: `Remarks` }
                    ]
                ],
                body: bodyRows.flat(),
                theme: 'grid',
            });
    
            autoTable(doc, {
                startY: (doc as any).lastAutoTable.finalY + 30,
                footStyles: { halign: 'center' },
                foot: [
                    [
                        { content: `Signature of Chief Superintendent`, colSpan: 6 },
                        { content: `Signature of AUR`, colSpan: 6 },
                    ]
                ],
                theme: 'plain',
            });
        
    
        doc.addPage();
    
        // FORM B
        const headRows: RowInput[] = [];
        headRows.push(
            [
                { content: `Total No. of scripts to be handed over`, colSpan: 6 },
                { content: `${totalPresentCount - (totalMalpracticeCount + totalSpecialCaseCount)}`, colSpan: 6 },
            ],
            [
                { content: `No. of Answer Paper Covers`, colSpan: 6 },
                { content: `${totalCode}`, colSpan: 6 },
            ],
            [
                { content: `Total No. of Candidates Present`, colSpan: 6 },
                { content: `${totalPresentCount}`, colSpan: 6 },
            ],
            [
                { content: `Total No. of Candidates Absent`, colSpan: 6 },
                { content: `${totalAbsentCount}`, colSpan: 6 },
            ],
            [
                { content: `Total Malpractice Cases`, colSpan: 6 },
                { content: `${totalMalpracticeCount}`, colSpan: 6 },
            ],
            [
                { content: `Total Special Cases`, colSpan: 6 },
                { content: `${totalSpecialCaseCount}`, colSpan: 6 },
            ],
            [
                { content: `Total Special Packing Cases`, colSpan: 6 },
                { content: ``, colSpan: 6 },
            ]
        );
    
        autoTable(doc, {
            didDrawPage: (data) => {
                doc.addImage(imgPath, 'PNG', 50, 5, 200, 40);
                doc.setFont('Arial', 'normal', 'bold');
                doc.setTextColor(0, 0, 0);
                doc.setFontSize(16);
                doc.text(`DESPATCH OF ANSWER PAPER COVERS`, 150, 48, { align: 'center' });
                doc.setFontSize(14);
                doc.text(`(To be submitted to the office of Controller of Examinations)`, 150, 55, { align: 'center' });
                doc.text(`${getExamName(examId)}`, 150, 62, { align: 'center' });
                doc.setFontSize(16);
                doc.text(`FORM B`, 150, 68, { align: 'center' });
                doc.line(10, 73, 280, 73);
                doc.text(`Exam Date : ${formatDate(date)} Exam Session: ${session}`, 10, 83, { align: 'left' });
            },
            startY: 95,
            headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0], halign: 'left' },
            bodyStyles: { textColor: [0, 0, 0], lineColor: [0, 0, 0], halign: 'center' },
            head: headRows,
            theme: 'grid',
        });
    
        autoTable(doc, {
            startY: (doc as any).lastAutoTable.finalY + 30,
            footStyles: { halign: 'center' },
            foot: [
                [
                    { content: `Signature of Chief Superintendent`, colSpan: 6 },
                    { content: `Signature of AUR`, colSpan: 6 },
                ]
            ],
            theme: 'plain',
        });
    
        doc.save(`${fileName}_${date}_${session}.pdf`);
    };
    
    const count = (items: string) => {
        return items.split(',').map(item => item.trim()).filter(Boolean).length;
    };
    
    return (
        <PageContainer title="Cumulative Cover Report">
            <Card title={editFlag ? "Edit Packing Galley" : "Filters"}>
                <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-6">
                        <label className="mt-2 mb-2">Exam <span className="text-danger">*</span> : </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px", }}
                            name="exam"
                            value={examId}
                            onChange={(e) => setExamId(e.target.value)}
                        >
                            <option value="" selected>Select Exam</option>
                            {selectedExam.map((course) => (
                                <option key={course.id} value={course.id}>
                                    {course.title}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-6">
                        <label className="mt-2 mb-2">Date <span className="text-danger">*</span> : </label>
                        <input
                            style={{ width: "100%", marginRight: "20px", }}
                            className="form-control"
                            placeholder="Enter date..."
                            type="date"
                            name="date"
                            onChange={(e) => setDate(e.target.value)}
                            value={date}
                        />
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-6">
                        <label className="mt-2 mb-2">Session <span className="text-danger">*</span> : </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px", }}
                            name="session"
                            value={session}
                            onChange={(e) => setSession(e.target.value)}
                        >
                            <option value="" selected>Select Session</option>
                            <option value="AN" selected>AN</option>
                            <option value="FN" selected>FN</option>
                        </select>
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-6">
                        <label className="mt-2 mb-2">Batch <span className="text-danger">*</span> : </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px" }}
                            name="selectedBatch"
                            value={batch}
                            onChange={(e) => setBatch(e.target.value)}
                        >
                            <option value="" selected>Select Batch</option>
                            {last10Years.map((year) => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6">
                        <label className="mt-2 mb-2">Degree  : </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px" }}
                            name="selectedDegree"
                            value={degreeId}
                            onChange={(e) => setDegreeId(e.target.value)}
                        >
                            <option value="" selected>Select Degree</option>
                            {selectedDegree.map((course) => (
                                <option key={course.id} value={course.id}>
                                    {course.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col-12">
                        <button
                            type="button"
                            className="btn btn-sm btn-primary"
                            onClick={filterSeatingPlans}
                            style={{ width: "10%", marginTop: "20px", marginBottom: "10px", float: "right" }}
                        >
                            Search
                        </button>
                    </div>
                </div>

            </Card>
            <CollapseCard title="Cover Report">
                <div className="container-fluid" style={{ width: '150%' }}>
                    <table className="table table-success table-striped">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Code</th>
                                <th>Exam</th>
                                <th>Date</th>
                                <th>Session</th>
                                <th>Degree</th>
                                <th>Discipline</th>
                                <th>Discipline Course</th>
                                <th>Question Paper Code</th>
                                <th>Seq No</th>
                                <th>Present List</th>
                                <th>Absent List</th>
                                <th>Malpractice List</th>
                                <th>Special Case List</th>
                                <th>Special Packing List</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredPackingList.map((row, index) => (
                                <tr key={row.id}>
                                    <td>{index + 1}</td>
                                    <td>{row.code}</td>
                                    <td>{getExamName(row.examId)}</td>
                                    <td>{formatDate(row.date)}</td>
                                    <td>{row.session}</td>
                                    <td>{getDegreeName(row.degreeId)}</td>
                                    <td>{getDisciplineName(row.disciplineId)}</td>
                                    <td>{getCourseName(row.disciplineCourseId)}</td>
                                    <td>{row.questionPaperCode}</td>
                                    <td>{row.seqNo}</td>
                                    <td>{row.presentList}</td>
                                    <td>{row.absentList}</td>
                                    <td>{row.malpracticeList}</td>
                                    <td>{row.specialCaseList}</td>
                                    <td>{row.specialPackingList}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div>
                        {renderDownloadButtons()}
                    </div>
                </div>
            </CollapseCard>

        </PageContainer>
    );
}

export default CumulativeCoverReport;

interface Discipline {
    id: number;
    name: string;
}

interface Course {
    id: number;
    name: string;
    shortName: string;
}

interface Degree {
    id: number;
    name: string;
}

interface DisciplineCourse {
    id: number;
    degreeId: string;
    disciplineId: string;
    courseId: string;
}

interface Exam {
    id: number;
    title: string;
}

interface ExamAttendance {
    id: number;
    examId: string;
    degreeId: string;
    disciplineId: string;
    batch: string;
    registerNo: string;
    date: string;
    session: string;
    disciplineCourseId: string;
    answerBookNo: string;
    attendance: string;
}

interface Packing {
    id: number;
    code: string;
    examId: string;
    date: string;
    session: string;
    degreeId: string;
    disciplineId: string;
    disciplineCourseId: string;
    questionPaperCode: string;
    seqNo: string;
    presentList: string;
    absentList: string;
    malpracticeList: string;
    specialCaseList: string;
    specialPackingList: string;
}
