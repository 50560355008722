import axios from 'axios';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from '../components/custom/Card';
import CollapseCard from '../components/custom/CollapseCard';
import PageContainer from '../components/custom/PageContainer';
import { apiUrl } from '../config';

const Course = () => {
    const [courseList, setCourseList] = useState<Course[]>([]);
    const [editFlag, setEditFlag] = useState(false);
    const [name, setName] = useState('');
    const [shortName, setShortName] = useState('');
    const [intMaxMark, setIntMaxMark] = useState('');
    const [intPassMark, setIntPassMark] = useState('');
    const [extMaxMark, setExtMaxMark] = useState('');
    const [extPassMark, setExtPassMark] = useState('');
    const [id, setId] = useState<number>(0);
    const [createdBy, setCreatedBy] = useState<string>('');
    const [createTimestamp, setCreateTimestamp] = useState<string>('');
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [nameError, setNameError] = useState('');
    const [shortNameError, setShortNameError] = useState('');
    const [intMaxMarkError, setIntMaxMarkError] = useState('');
    const [intPassMarkError, setIntPassMarkError] = useState('');
    const [extMaxMarkError, setExtMaxMarkError] = useState('');
    const [extPassMarkError, setExtPassMarkError] = useState('');
    const [apiErrorMessage, setApiErrorMessage] = useState('');


    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        axios.get<{ data: Course[] }>(`${apiUrl}/course/`)
            .then((response) => {
                setCourseList(response.data.data);
            })
            .catch((error) => console.log(error));
    }

    const addHandler = () => {
        let error = false
        if (name === '') {
            setNameError('Title is required');
            error = true
        } else if (name.length > 100) {
            setNameError('Title must be within 100 characters');
            error = true
        }
        if (shortName === '') {
            setShortNameError('Code is required');
            error = true
        } else if (shortName.length > 10) {
            setShortNameError('Code must be within 10 characters');
            error = true
        }
        if (intMaxMark === '') {
            setIntMaxMarkError('Internal Maximum Mark is required');
            error = true
        } else if (parseInt(intMaxMark) > 100) {
            setIntMaxMarkError('Internal Maximum Mark must be within 1-100');
            error = true
        } else if (parseInt(intMaxMark) <= 0) {
            setIntMaxMarkError('Invalid Internal Maximum Mark');
            error = true
        } else if (intMaxMark.length > 11) {
            setIntMaxMarkError('Internal Maximum Mark must be within 11 characters');
            error = true
        }
        if (intPassMark === '') {
            setIntPassMarkError('Internal Pass Mark is required');
            error = true
        } else if (parseInt(intPassMark) > 100) {
            setIntPassMarkError('Internal Pass Mark must be within 1-100');
            error = true
        } else if (parseInt(intPassMark) <= 0) {
            setIntPassMarkError('Invalid Internal Pass Mark');
            error = true
        } else if (intPassMark.length > 11) {
            setIntPassMarkError('Internal Pass Mark must be within 11 characters');
            error = true
        }
        if (extMaxMark === '') {
            setExtMaxMarkError('External Maximum Mark  is required');
            error = true
        } else if (parseInt(extMaxMark) > 100) {
            setExtMaxMarkError('External Maximum Mark must be within 1-100');
            error = true
        } else if (parseInt(extMaxMark) <= 0) {
            setExtMaxMarkError('Invalid External Maximum Mark');
            error = true
        } else if (extMaxMark.length > 11) {
            setExtMaxMarkError('External Maximum Mark must be within 11 characters');
            error = true
        }
        if (extPassMark === '') {
            setExtPassMarkError('External Pass Mark  is required');
            error = true
        } else if (parseInt(extPassMark) > 100) {
            setExtPassMarkError('External Pass Mark must be within 1-100');
            error = true
        } else if (parseInt(extPassMark) <= 0) {
            setExtPassMarkError('Invalid External Pass Mark');
            error = true
        } else if (extPassMark.length > 11) {
            setExtPassMarkError('External Pass Mark must be within 11 characters');
            error = true
        }

        const data = { name, shortName, intMaxMark, intPassMark, extMaxMark, extPassMark, createdBy, createTimestamp };

        if (!error) {
            axios.post(`${apiUrl}/course/`, data)
                .then((response) => {
                    console.log(response);
                    if (response.data.error) {
                        toast.error('Failed to add Course', { position: 'top-right' });
                        setApiErrorMessage(response.data.message);
                    } else {
                        toast.success(response.data.message, { position: 'top-right' });
                        setNameError('');
                        setShortNameError('');
                        setIntMaxMarkError('');
                        setIntPassMarkError('');
                        setExtMaxMarkError('');
                        setExtPassMarkError('');
                        setApiErrorMessage('');
                        fetchData();
                        clearHandler();
                    }
                })
                .catch((error) => toast.error(error.response.data.message));
        }
    }

    const deleteHandler = (id: number) => {
        setId(id);
        openDeleteConfirmation(); // Open the delete confirmation modal
    }

    const confirmDeleteHandler = () => {
        axios.delete(`${apiUrl}/course/?id=${id}`)
            .then((response) => {
                toast.success(response.data.message, { position: 'top-right' });
                fetchData();
                closeDeleteConfirmation();
            })
            .catch((error) => {
                toast.error(error.response.data.message, { position: 'top-right' });
            });
    }

    const editHandler = (id: number, name: string, shortName: string, intMaxMark: string, intPassMark: string, extMaxMark: string, extPassMark: string, createdBy: string, createTimestamp: string) => {
        setEditFlag(true);
        setId(id);
        setName(name);
        setShortName(shortName);
        setIntMaxMark(intMaxMark);
        setIntPassMark(intPassMark);
        setExtMaxMark(extMaxMark);
        setExtPassMark(extPassMark);
        setCreatedBy(createdBy);
        setCreateTimestamp(createTimestamp);
    }

    const updateHandler = () => {
        let error = false
        if (name === '') {
            setNameError('Title is required');
            error = true
        } else if (name.length > 100) {
            setNameError('Title must be within 100 characters');
            error = true
        }
        if (shortName === '') {
            setShortNameError('Code is required');
            error = true
        } else if (shortName.length > 10) {
            setShortNameError('Code must be within 10 characters');
            error = true
        }
        if (intMaxMark === '') {
            setIntMaxMarkError('Internal Maximum Mark is required');
            error = true
        } else if (parseInt(intMaxMark) > 100) {
            setIntMaxMarkError('Internal Maximum Mark must be within 1-100');
            error = true
        } else if (parseInt(intMaxMark) <= 0) {
            setIntMaxMarkError('Invalid Internal Maximum Mark');
            error = true
        } else if (intMaxMark.length > 11) {
            setIntMaxMarkError('Internal Maximum Mark must be within 11 characters');
            error = true
        }
        if (intPassMark === '') {
            setIntPassMarkError('Internal Pass Mark is required');
            error = true
        } else if (parseInt(intPassMark) > 100) {
            setIntPassMarkError('Internal Pass Mark must be within 1-100');
            error = true
        } else if (parseInt(intPassMark) <= 0) {
            setIntPassMarkError('Invalid Internal Pass Mark');
            error = true
        } else if (intPassMark.length > 11) {
            setIntPassMarkError('Internal Pass Mark must be within 11 characters');
            error = true
        }
        if (extMaxMark === '') {
            setExtMaxMarkError('External Maximum Mark  is required');
            error = true
        } else if (parseInt(extMaxMark) > 100) {
            setExtMaxMarkError('External Maximum Mark must be within 1-100');
            error = true
        } else if (parseInt(extMaxMark) <= 0) {
            setExtMaxMarkError('Invalid External Maximum Mark');
            error = true
        } else if (extMaxMark.length > 11) {
            setExtMaxMarkError('External Maximum Mark must be within 11 characters');
            error = true
        }
        if (extPassMark === '') {
            setExtPassMarkError('External Pass Mark  is required');
            error = true
        } else if (parseInt(extPassMark) > 100) {
            setExtPassMarkError('External Pass Mark must be within 1-100');
            error = true
        } else if (parseInt(extPassMark) <= 0) {
            setExtPassMarkError('Invalid External Pass Mark');
            error = true
        } else if (extPassMark.length > 11) {
            setExtPassMarkError('External Pass Mark must be within 11 characters');
            error = true
        }

        const data = { id, name, shortName, intMaxMark, intPassMark, extMaxMark, extPassMark, createdBy, createTimestamp };
        if (!error) {
            axios.put(`${apiUrl}/course/?id=${id}`, data)
                .then((response) => {
                    console.log(response);
                    if (response.data.error) {
                        toast.error('Failed to update Course', { position: 'top-right' });
                        setApiErrorMessage(response.data.message);
                    } else {
                        toast.success(response.data.message, { position: 'top-right' });
                        setNameError('');
                        setShortNameError('');
                        setIntMaxMarkError('');
                        setIntPassMarkError('');
                        setExtMaxMarkError('');
                        setExtPassMarkError('');
                        setApiErrorMessage('');
                        fetchData();
                        setEditFlag(false);
                        clearHandler();
                    }
                })
                .catch((error) => toast.error(error.response.data.message));
        }
    }

    const clearHandler = () => {
        setName('');
        setShortName('');
        setIntMaxMark('');
        setIntPassMark('');
        setExtMaxMark('');
        setExtPassMark('');
        setId(0);
        setCreatedBy('');
        setCreateTimestamp('');
        setEditFlag(false);
        setNameError('');
        setShortNameError('');
        setIntMaxMarkError('');
        setIntPassMarkError('');
        setExtMaxMarkError('');
        setExtPassMarkError('');
    }

    const openDeleteConfirmation = () => {
        setShowDeleteConfirmation(true);
    }

    const closeDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    }

    return (
        <PageContainer title="Course">
            <Card title={editFlag ? "Edit Course" : "Add Course"}>
                <div className='container m-0'>
                    <div className="row">
                        <div className="col-4">
                            <label className="mt-3 mb-3">Title <span className="text-danger">*</span> : </label>
                            <input className="form-control" placeholder="Enter title..." type="text" name="name" onChange={(e) => {
                                setName(e.target.value);
                                setNameError('');
                                setApiErrorMessage('');
                            }} value={name} />
                            {nameError && <div className="text-danger">{nameError}</div>}</div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Code <span className="text-danger">*</span> : </label>
                            <input className="form-control" placeholder="Enter Code..." type="text" name="ShortName" onChange={(e) => {
                                setShortName(e.target.value);
                                setShortNameError('');
                                setApiErrorMessage('');
                            }} value={shortName} />
                            {shortNameError && <div className="text-danger">{shortNameError}</div>}</div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Internal Maximum Mark <span className="text-danger">*</span> : </label>
                            <input className="form-control" placeholder="Enter Internal Maximum Mark..." type="number" name="address" onChange={(e) => {
                                setIntMaxMark(e.target.value);
                                setIntMaxMarkError('');
                                setApiErrorMessage('');
                            }} value={intMaxMark} />
                            {intMaxMarkError && <div className="text-danger">{intMaxMarkError}</div>}</div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Internal Pass Mark <span className="text-danger">*</span> : </label>
                            <input className="form-control" placeholder="Enter Internal Pass Mark..." type="number" name="Contact" onChange={(e) => {
                                setIntPassMark(e.target.value);
                                setIntPassMarkError('');
                                setApiErrorMessage('');
                            }} value={intPassMark} />
                            {intPassMarkError && <div className="text-danger">{intPassMarkError}</div>}</div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">External Maximum Mark <span className="text-danger">*</span> : </label>
                            <input className="form-control" placeholder="Enter External Maximum Mark..." type="number" name="examCenterName" onChange={(e) => {
                                setExtMaxMark(e.target.value);
                                setExtMaxMarkError('');
                                setApiErrorMessage('');
                            }} value={extMaxMark} />
                            {extMaxMarkError && <div className="text-danger">{extMaxMarkError}</div>}</div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">External Pass Mark <span className="text-danger">*</span> : </label>
                            <input className="form-control" placeholder="Enter External Pass Mark..." type="number" name="examCenterCode" onChange={(e) => {
                                setExtPassMark(e.target.value);
                                setExtPassMarkError('');
                                setApiErrorMessage('');
                            }} value={extPassMark} />
                            {extPassMarkError && <div className="text-danger">{extPassMarkError}</div>}</div>

                    </div>
                    <div className="m-4 d-flex justify-content-end">
                        <button className="btn btn-sm btn-secondary" onClick={clearHandler} style={{ marginRight: '10px' }}>Clear</button>
                        {editFlag ? <button className="btn btn-sm btn-success" onClick={updateHandler}>Update</button> :
                            <button className="btn btn-sm btn-success" onClick={addHandler}>Add</button>}
                    </div></div>
            </Card>
            <CollapseCard title="Course">
                <div className="container-fluid" style={{ width: "130%" }}>
                    <table className="table table-success table-striped">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>title</th>
                                <th>code</th>
                                <th>Internal Maximum Mark</th>
                                <th>Internal Pass Mark</th>
                                <th>External Maximum Mark</th>
                                <th>External Pass Mark</th>
                                {/* <th>Created By</th>
                            <th>Create Timestamp</th> */}
                                <th>Options</th>
                            </tr>
                        </thead>
                        <tbody>
                            {courseList.map((course, index) => (
                                <tr key={course.id}>
                                    <td>{index + 1}</td>
                                    <td>{course.name}</td>
                                    <td>{course.shortName}</td>
                                    <td>{course.intMaxMark}</td>
                                    <td>{course.intPassMark}</td>
                                    <td>{course.extMaxMark}</td>
                                    <td>{course.extPassMark}</td>
                                    {/* <td>{course.createdBy}</td>
                                <td>{course.createTimestamp}</td> */}
                                    <td>
                                        <button className="btn btn-sm btn-primary" style={{ marginRight: '10px' }} onClick={() => editHandler(course.id, course.name, course.shortName, course.intMaxMark, course.intPassMark, course.extMaxMark, course.extPassMark, course.createdBy, course.createTimestamp)}>Edit</button>
                                        <button className="btn btn-sm btn-danger" onClick={() => deleteHandler(course.id)}>Delete</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </CollapseCard>
            {/* Deletion Confirmation Modal */}
            <Modal show={showDeleteConfirmation} onHide={closeDeleteConfirmation}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this item?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeDeleteConfirmation}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={confirmDeleteHandler}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Add the ToastContainer for displaying toasts */}
            <ToastContainer position="top-right" />
        </PageContainer>
    );
}

export default Course;

interface Course {
    id: number,
    name: string,
    shortName: string,
    intMaxMark: string,
    intPassMark: string,
    extMaxMark: string,
    extPassMark: string,
    createdBy: string,
    createTimestamp: string
}