import axios from 'axios';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { ChangeEventHandler, FC, useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import Card from '../components/custom/Card';
import CollapseCard from '../components/custom/CollapseCard';
import PageContainer from '../components/custom/PageContainer';
import imgPath from './img/collegeLogo.png';
import './styles/tableReport.css';
// import imgProfile from 'http://localhost/programs/exam-app.png';
import { RowInput } from 'jspdf-autotable';
import * as Feather from 'react-feather';
import { toast } from 'react-toastify';
import Option from '../components/Option';
import FormInput from '../components/custom/FormInput';
import { apiUrl } from '../config';
import { YearOptions } from '../config/functions';
import { DisciplineCourseType } from '../redux/actions/disciplineCourseAction';
// import { ExamAttendanceType } from '../redux/actions/examAttendanceActions';
interface Props {
    title: string;
}

const AttendanceResultReport: FC<Props> = (props: Props) => {

    const { title } = props;
    const fileName = "attendance_result_report";
    const [studentList, setStudentList] = useState<Student[]>([]);
    const [selectedBatch, setSelectedBatch] = useState<string>('');

    // const examAttendanceList = useSelector<StoreState, ExamAttendanceType[]>((state: any) => state.examAttendance);

    const [attendanceResult, setAttendanceResult] = useState<ExamAttendanceType[]>([])

    const [disciplineCourseList, setDisciplineCourseList] = useState<DisciplineCourseType[]>([])

    console.log(attendanceResult);

    const [selectedDegree, setSelectedDegree] = useState<Degree[]>([]);
    const [selectedDegreeId, setSelectedDegreeId] = useState<string | null>(null);

    const [selectedDiscipline, setSelectedDiscipline] = useState<Discipline[]>([]);
    const [selectedDisciplineId, setSelectedDisciplineId] = useState<string | null>(null);

    const [CourseList, setCourse] = useState<Course[]>([]);
    const [selectedCourseId, setSelectedCourseId] = useState<string>('');
    const [selectedCourseCode, setSelectedCourseCode] = useState<string>('');
    const [selectedCourseName, setSelectedCourseName] = useState<string>('');

    const [selectedExam, setSelectedExam] = useState<Exam[]>([]);
    const [selectExamId, setSelectExamId] = useState<string>('');
    const [session, setSession] = useState<string>('');

    console.log(session);

    const yearList = YearOptions();

    const [date, setDate] = useState('');

    const handleDateChange = (e: any) => {
        setDate(e.target.value);
    };

    // Format the date as "DD-MM-YYYY"
    const formattedDate = date
        ? new Date(date).toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        }).split('/').join('-')
        : '';

    const handleCourse: ChangeEventHandler<HTMLSelectElement> = (e) => {
        let cid = e.target.value
        setSelectedCourseId(cid)
        console.log(cid);

        let obj = CourseList.find(f => f.id.toString() === cid.toString())
        setSelectedCourseCode(obj?.shortName ? obj?.shortName : '')
        setSelectedCourseName(obj?.name ? obj?.name : '')
    }

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        axios.get<{ data: Student[] }>(`${apiUrl}/student/`)
            .then((response) => {
                console.log(response);

                setStudentList(response.data.data);
            })
            .catch((error) => console.log(error));
    }

    useEffect(() => {
        axios.get(`${apiUrl}/course/`)
            .then((response) => {
                setCourse(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching discipline course', error);
            });
    }, [])

    useEffect(() => {
        axios.get(`${apiUrl}/exam/`)
            .then((response) => {
                setSelectedExam(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching Course', error);
            });
    }, []);

    useEffect(() => {
        axios.get(`${apiUrl}/degree/`)
            .then((response) => {
                setSelectedDegree(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching discipline course', error);
            });
    }, [])

    useEffect(() => {
        axios.get(`${apiUrl}/discipline/`)
            .then((response) => {
                setSelectedDiscipline(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching discipline course', error);
            });
    }, [])

    useEffect(() => {
        axios.get(`${apiUrl}/disciplineCourse/`)
            .then((response) => {
                setDisciplineCourseList(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching discipline course', error);
            });
    }, [])

    const getUniqueBatch = () => {
        const uniqueBatch = Array.from(new Set(studentList.map((batch) => batch.batch)));
        return uniqueBatch;
    };

    const [filteredStudentList, setFilteredStudentList] = useState<Student[]>([]);

    let disCourseId = disciplineCourseList?.find(dc =>
        dc.disciplineId.toString() === selectedDisciplineId &&
        dc.degreeId.toString() === selectedDegreeId &&
        dc.courseId.toString() === selectedCourseId
    )?.id


    const filterCourseList = () => {
        if (selectedDegreeId !== '' && selectedDisciplineId !== '' && selectedBatch !== '') {
            console.log(disCourseId);
            axios.get<{ data: ExamAttendanceType[] }>(`${apiUrl}/examAttendance/attendanceResult/?degreeId=${selectedDegreeId}&disciplineId=${selectedDisciplineId}&batch=${selectedBatch}&disciplineCourseId=${disCourseId}&examId=${selectExamId}&session='${session}'&date=${date}`)
                .then((response) => {
                    const result = response.data.data
                    if (result.length > 0) {
                        setAttendanceResult(result)
                    } else {
                        toast.error('No records found');
                    }
                })
                .catch((error) => {
                    toast.error(error.response.data.data.message);
                    console.log(error.response);

                    // console.error('Error fetching passing result', error);
                });
        } else {
            toast.error('Select All Filters', { position: 'top-right', autoClose: 3000 });
            setFilteredStudentList([])
        }

    };

    const examName = selectedExam.find((se) => se.id === parseInt(selectExamId) ? se.title : '')?.title


    const handleDownloadPDF = () => {
        const doc = new jsPDF('p', 'mm', 'a4');

        doc.addImage(imgPath, 'PNG', 25, 15, 170, 30);

        doc.setFontSize(14);
        doc.setFont('Arial', 'normal', 'bold');
        doc.setTextColor(0, 0, 0);
        doc.text(title, 107, 50, { align: 'center' });

        doc.rect(7, 7, 195, 285);

        const selectedDegreeName = selectedDegree.find((degree) => degree.id === (selectedDegreeId ? parseInt(selectedDegreeId) : null))?.name || 'N/A';
        const selectedDisciplineName = selectedDiscipline.find((discipline) => discipline.id === (selectedDisciplineId ? parseInt(selectedDisciplineId) : null))?.name || 'N/A';
        doc.setFontSize(12);

        const firstTable = autoTable(doc, {
            didDrawPage: (data) => {
                doc.setFontSize(10);
                doc.setFont('normal');
                doc.text(`${examName}`, data.settings.margin.left + 71, 56);
            },
            startY: 60,
            headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 9, halign: 'left' },
            bodyStyles: { textColor: [0, 0, 0] },
            head: [
                [
                    // { content: `College Code & Name: 8213, PARISUTHAM INSTITUTE OF TECHNOLOGY AND SCIENCE `},
                    // { content: `Date of Examination: ${date}`},
                    { content: `Degree & Discipline: ${selectedDegreeName} - ${selectedDisciplineName}` },
                    { content: `Date of Examination: ${formattedDate}` },
                    // { content: `Course Code & Name:  ${selectedCourseCode} – ${selectedCourseName}` }
                ],
                [
                    // { content: `Degree & Discipline: ${selectedDegreeId} ${selectedDisciplineId}`},
                    { content: `Course Code & Name:  ${selectedCourseCode} – ${selectedCourseName}` },
                    { content: `Session: ${session}` },
                ],
                [
                    // {content: `Course Code & Name:  ${selectedCourseCode} – ${selectedCourseName}`}
                ]
            ],
            theme: 'plain',
        });
        //2nd Table
        autoTable(doc, {
            startY: (doc as any).lastAutoTable.finalY,
            margin: { left: 8 },
            tableWidth: 193,
            headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0], fontSize: 8, halign: 'center' },
            bodyStyles: { textColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 8 },
            head: [
                [
                    { content: 'S.No.' },
                    { content: 'Register Number' },
                    { content: 'Name of the Candidate' },
                    { content: 'Answer Booklet No.' },
                    { content: 'AB*' },
                    // { content: 'Signature of the Candidate' },
                    // { content: 'Photo' },
                ]
            ],
            theme: 'grid',
            body: attendanceResult.map((row, index) => [
                { content: index + 1, styles: { halign: 'center' } },
                { content: row.registerNo, styles: { halign: 'center' } },
                { content: row.nameOfCandidate, styles: { halign: 'left' } },
                { content: row.answerBookNo, styles: { halign: 'center' } },
                { content: row.attendance, styles: { halign: 'center' } },
                // { content: "", styles: { halign: 'center' } },
                //     {
                //     didDrawPage: (data: any) => {
                //          doc.addImage(imgProfile, 'PNG', 25, 15, 10, 10).toString()
                //     }       
                //    }
            ]),
        });

        doc.setFontSize(8);
        doc.text('*Hall Superintendent should mark ‘AB’ for Absent', 10, (doc as any).lastAutoTable.finalY + 5)

        const absentList = attendanceResult.filter((at) => at.attendance === 'AB').length

        const presentList = attendanceResult.filter((at) => at.attendance === 'PR').length

        // console.log(presentList);


        const additionalRowTopPart: RowInput[] = [
            [
                { content: `TOTAL PRESENT :${presentList}`, styles: { fontStyle: 'bold', halign: 'left', lineWidth: 0.3, lineColor: [0, 0, 0], cellWidth: 100, } },
                { content: `TOTAL ABSENT :${absentList}`, styles: { fontStyle: 'bold', halign: 'left', lineWidth: 0.3, lineColor: [0, 0, 0], cellWidth: 90 } },
            ],
            [
                { content: 'Certified that the following particulars have been Verified:', styles: { cellPadding: { top: 6 } } },
            ],
        ];
        // Additional Rows
        const additionalRowsmiddlePart: RowInput[] = [

            [
                { content: '1. ', styles: { cellWidth: 10, cellPadding: { top: 1, left: 3.5 } } },
                { content: 'The Register No. in the attendance sheet with that in the hall ticket.', styles: { cellPadding: { top: 1 } } },
            ],
            [
                { content: '2. ', styles: { cellWidth: 10, cellPadding: { left: 3.5 } } },
                { content: 'The identification of the candidate with the photo given in the hall ticket.', styles: { cellPadding: {} } },
            ],
            [
                { content: '3. ', styles: { cellWidth: 10, cellPadding: { left: 3.5 } } },
                { content: 'The answer booklet number entered in the attendance sheet by the candidate with the Serial No. on the Answer Booklet.', styles: { cellPadding: { right: 12 } } },
            ],

        ];
        const additionalRowLastPart: RowInput[] = [
            [
                { content: 'Signature of the Hall Superintendent with Name and Designation', styles: { cellPadding: { top: 30, left: 2 }, fontSize: 8 } },
                { content: 'Signature of the Chief Superintendent with Name and Designation', styles: { cellPadding: { top: 30, right: 2 }, fontSize: 8 } },
            ],
        ];
        //bottom table part-1(total)
        autoTable(doc, {
            startY: (doc as any).lastAutoTable.finalY + 20,
            margin: { left: 10 },
            tableWidth: 193,
            headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 8 },
            bodyStyles: { textColor: [0, 0, 0], fontStyle: 'bold', fontSize: 9 },
            body: additionalRowTopPart,
            theme: 'plain',

        });
        //bottom table part-2(text)
        autoTable(doc, {
            startY: (doc as any).lastAutoTable.finalY,
            margin: { left: 10 },
            tableWidth: 193,
            headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 8 },
            bodyStyles: { textColor: [0, 0, 0], fontStyle: 'bold', fontSize: 9 },
            body: additionalRowsmiddlePart,
            theme: 'plain',

        });
        //bottom table part-3(signature)
        autoTable(doc, {
            startY: (doc as any).lastAutoTable.finalY,
            margin: { left: 10 },
            tableWidth: 193,
            headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 8 },
            bodyStyles: { textColor: [0, 0, 0], fontStyle: 'bold', fontSize: 9 },
            body: additionalRowLastPart,
            theme: 'plain',

        });

        doc.save(fileName + '.pdf');
    };

    const csvData = [
        [
            'S.No',
            'Register Number',
            'Name of the Candidate',
        ],
        ...filteredStudentList.map((student, index) => [
            index + 1,
            student.registerNo,
            student.name
        ]),
    ];
    return (
        <>
            <PageContainer title="EXAMINATION ATTENDANCE REPORT">
                <Card title="filters">
                    <div className="row">
                        <div className="col-sm-6 col-md-4 col-lg-4">
                            <label className="mt-3 mb-3">Select Degree <span className="text-danger">*</span> :</label>
                            <select
                                className="form-control"
                                value={selectedDegreeId || ''}
                                onChange={(e) => setSelectedDegreeId(e.target.value)}
                            >
                                <option value="">Select Degrees</option>
                                {selectedDegree.map((course) => (
                                    <option key={course.id} value={course.id}>
                                        {course.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4">
                            <label className="mt-3 mb-3">Select Discipline <span className="text-danger">*</span> :</label>
                            <select
                                className="form-control"
                                value={selectedDisciplineId || ''}
                                onChange={(e) => setSelectedDisciplineId(e.target.value)}
                            >
                                <option value="">Select Disciplines</option>
                                {selectedDiscipline.map((course) => (
                                    <option key={course.id} value={course.id}>
                                        {course.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4">
                            <FormInput
                                name="Batch"
                                label="Batch"
                                labelClassName="required"
                                value={selectedBatch}
                                onChange={(e) => setSelectedBatch(e.target.value)}
                                placeholder="Select Batch"
                                containerClass="mt-4"
                                type="select"
                            >
                                <option value="">Select Batch</option>
                                {yearList.map((y, i) => {
                                    return <Option key={i} value={y}>{y}</Option>
                                })
                                }
                            </FormInput>
                        </div>

                        <div className="col-sm-6 col-md-4 col-lg-4">
                            <label className="mt-3 mb-3">Select Course Name <span className="text-danger">*</span> :</label>
                            <select
                                className="form-control"
                                value={selectedCourseId}
                                onChange={handleCourse}
                            >
                                <option value="">Select Course Name</option>
                                {CourseList.map((course) => (
                                    <option key={course.id} value={course.id}>
                                        {course.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="col-sm-6 col-md-4 col-lg-4">
                            <label className="mt-3 mb-3" htmlFor="examId">Exam <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                name="examIdFilter"
                                value={selectExamId}
                                onChange={(e) => setSelectExamId(e.target.value)}
                            >
                                <option value="" selected>Select Exam</option>
                                {selectedExam.map((course) => (
                                    <option key={course.title} value={course.id}>
                                        {course.title}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4">
                            <label className="mt-3 mb-3">Date <span className="text-danger">*</span> :</label>
                            <input
                                type="date"
                                id="fromDate"
                                value={date}
                                onChange={handleDateChange}
                                className="form-control"
                            />
                        </div>

                        <div className="col-sm-6 col-md-4 col-lg-4">
                            <label className="mt-3 mb-3">Select Session <span className="text-danger">*</span> :</label>
                            <select
                                className="form-control"
                                value={session || ''}
                                onChange={(e) => setSession(e.target.value)}
                            >
                                <option value="">Select Session</option>
                                <option value="AN">AN</option>
                                <option value="FN">FN</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-12">
                        <button type="button" className="btn btn-sm btn-primary mt-3" onClick={filterCourseList} style={{ width: "10%", marginTop: "55px", float: "right" }}>Search
                        </button>
                    </div>
                </Card>
                <CollapseCard title="Exam Attendance Report">
                    <div className="container-fluid table-container" >
                        <table className="table table-success table-striped">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Register Number</th>
                                    <th>Name of the Candidate</th>

                                </tr>
                            </thead>
                            <tbody>
                                {attendanceResult?.map((student, index) => (
                                    <tr key={student.id}>
                                        <td>{index + 1}</td>
                                        <td>{student.registerNo}</td>
                                        <td>{student.nameOfCandidate}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="text-end mb-3">
                        {attendanceResult?.length > 0 && <button
                            type="button"
                            onClick={handleDownloadPDF}
                            className="m-1 btn btn-sm btn-primary"
                        >
                            <Feather.Download /> PDF
                        </button>}
                        {attendanceResult?.length > 0 && <CSVLink
                            data={csvData}
                            filename="details_of_course.csv"
                            className="m-1 btn btn-sm btn-primary"
                        >
                            <Feather.Download /> CSV
                        </CSVLink>}
                    </div>
                </CollapseCard>
            </PageContainer>
        </>
    );
}

export default AttendanceResultReport

interface Student {
    id: number;
    registerNo: string;
    regulation: number;
    departmentRegisterNumber: string;
    name: string;
    fatherName: string;
    motherName: string;
    guardianName: string;
    bloodGroup: string;
    gender: string;
    dateOfBirth: string;
    aadharNumber: string;
    address: string;
    pincode: number;
    emailId: string;
    contactNumber: string;
    parentContactNumber: number;
    community: string;
    caste: string;
    religion: string;
    state: string;
    nationality: string;
    motherTongue: string;
    degreeId: number;
    disciplineId: number;
    modeOfAdmission: string;
    batch: string;
    entryLevel: string;
    dateOfJoin: string;
    noOfYears: number;
    currentSemester: number;
    photoUrl: string;
    createdBy: string;
    createTimestamp: string;
}

interface ExamAttendanceType {
    id?: number;
    examId: string;
    degreeId: string;
    disciplineId: string;
    batch: string;
    registerNo: string;
    nameOfCandidate: string;
    date: string;
    session: string;
    disciplineCourseId: string;
    answerBookNo: string;
    attendance: string;
}


interface Degree {
    id: number;
    name: string;
}

interface Discipline {
    id: number;
    name: string;
}

interface Course {
    id: number;
    name: string;
    shortName: string;
}

interface Exam {
    id: number;
    title: string;
}