
import { FacultyActionList, FacultyType } from "../actions/facultyActions";
import { Action } from "../actions/types";

const initialValue: Array<FacultyType> = [];

const facultyReducer = (state: Array<FacultyType> = initialValue, action: Action) => {
    switch (action.type) {
        case FacultyActionList.ADD_FACULTY:
            return [
                ...state,
                action.data,
            ];

        case FacultyActionList.UPDATE_FACULTY:
            const updated = [...state].slice();
            const index = updated.findIndex(u => u.id === action.data.id);

            if (index > -1) {
                updated[index] = {
                    ...action.data,
                };
            }

            return updated;

        case FacultyActionList.FETCH_FACULTY:
            return action.data;

        case FacultyActionList.DELETE_FACULTY:
            return state.filter(s => s.id !== action.data);

        default:
            return state;
    }
}

export default facultyReducer;



