
import React, { FC } from "react";

const Footer:FC = () =>{
    return(
        <div className="form-group">
        <label>Multiple</label>
        <select className="select2" multiple={true} data-placeholder="Select a State" style={{width: "100%"}}>
          <option>Alabama</option>
          <option>Alaska</option>
          <option>California</option>
          <option>Delaware</option>
          <option>Tennessee</option>
          <option>Texas</option>
          <option>Washington</option>
        </select>
      </div>
    )
}
export default Footer;
