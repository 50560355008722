import axios from 'axios';
import { FC, useEffect, useState } from 'react';
import Card from '../components/custom/Card';
import CollapseCard from '../components/custom/CollapseCard';
import PageContainer from '../components/custom/PageContainer';
// import './styles/tableReport.css';

import jsPDF from 'jspdf';
import autoTable, { RowInput } from 'jspdf-autotable';
import { CSVLink } from 'react-csv';
import * as Feather from 'react-feather';
import { apiUrl } from '../config';
import { LocalFormatDate } from '../config/functions';
import imgPath from './img/collegeLogo.png';

interface ExamTimetable {
    id: number;
    semesterNo: number;
    rg: number;
    paymentStatus: string;
    disciplineName: string;
    courseTitle: string;
    degreeName: string;
    semester: number;
    // courseName: string;
    courseCode: number;
    // examId: string;
    date: string;
    session: string;
}

interface Props {
    title: string;
}

type FontStyle = 'normal' | 'bold' | 'italic' | 'underline' | string;

const RegisteredCandidatesReport: FC<Props> = (props: Props) => {
    const { title } = props;
    const fileName = "candidate_register_report";

    const [examTimetableList, setExamTimetableList] = useState<ExamTimetable[]>([]);
    // const [id, setId] = useState("");

    const [examIdFilter, setExamIdFilter] = useState<string>("");
    const [fromDateFilter, setFromDateFilter] = useState<string>("");
    const [toDateFilter, setToDateFilter] = useState<string>("");

    const [selectedExam, setSelectedExam] = useState<Exam[]>([]);


    useEffect(() => {
        console.log(examTimetableList);
    }, [examTimetableList]);

    // const fetchData = () => {
    //   axios
    //     .get<{ data: ExamTimetable[] }>(
    //       `${apiUrl}/examTimetable/?examId=${examIdFilter}&date=${fromDateFilter}`
    //     )
    //     .then((response) => {
    //       setExamTimetableList(response.data.data);
    //       setExamIdFilter("");
    //       setFromDateFilter("");
    //       setToDateFilter("");
    //     })
    //     .catch((error) => console.log(error));
    // };

    // console.log(fetchData);

    const handleSearch = () => {
        if (examIdFilter !== '' && fromDateFilter !== '' && toDateFilter !== '') {
            axios.get(`${apiUrl}/examTimetable/?examId=${examIdFilter}&fromDate=${fromDateFilter}&toDate=${toDateFilter}`)
                .then((response) => {
                    setExamTimetableList(response.data.data);
                    // setExamIdFilter("");
                    // setFromDateFilter("");
                    // setToDateFilter("");
                })
                .catch((error) => {
                    console.error('Error fetching Course', error);
                });
        }
    }

    useEffect(() => {
        axios.get(`${apiUrl}/exam/`)
            .then((response) => {
                setSelectedExam(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching Course', error);
            });
    }, []);

    const examName = selectedExam.find((se) => se.id?.toString() === examIdFilter?.toString() ? se.title : '')

    const examTitle = examName?.title

    const handleDownloadPDF = () => {
        const doc = new jsPDF('p', 'mm', 'a4');

        doc.addImage(imgPath, 'PNG', 25, 15, 170, 30);


        doc.setFontSize(14);
        doc.setFont('Arial', 'normal', 'bold');
        doc.setTextColor(0, 0, 0);

        doc.setFontSize(12);
        doc.text(`${examTitle}`, 107, 50, { align: 'center' });
        doc.text(`(${LocalFormatDate(fromDateFilter)} to ${LocalFormatDate(toDateFilter)})`, 107, 56, { align: 'center' });
        doc.text(title, 107, 62, { align: 'center' });

        const rows: RowInput[] = [];
        let prevDateTime: string | null = null; // Track previous date and time

        examTimetableList.sort((a, b) => a.semester - b.semester).forEach((record) => {
            const currentDateTime = `${record.date}: ${record.session}`;

            // Check if the current date and time are different from the previous one
            if (prevDateTime !== currentDateTime) {
                const timeRow: RowInput = [
                    {
                        content: `Exam Date & Session :- ${LocalFormatDate(record.date)}: ${record.session}`,
                        colSpan: 12, styles: { fontStyle: 'bold' }
                    },
                ];

                rows.push(timeRow);
            }

            const valueRow: RowInput = [
                // { content: record. },
                { content: record.courseCode, styles: { halign: 'center' } },
                { content: record.courseTitle },
                { content: record.semester, styles: { halign: 'center' } },
                { content: `${record.degreeName} - ${record.disciplineName}` },
                { content: record.rg, styles: { halign: 'center' } },
                // { content: "08" },
            ];

            rows.push(valueRow);

            // Update previous date and time
            prevDateTime = currentDateTime;
        });

        autoTable(doc, {
            startY: 70,
            headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0], fontSize: 8 },
            bodyStyles: { textColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 8 },
            head: [
                [
                    { content: 'Course Code', styles: { halign: 'center' } },
                    { content: 'Course Title', styles: { halign: 'center' } },
                    { content: 'Sem.', styles: { halign: 'center' } },
                    { content: 'Discipline (s)', styles: { halign: 'center' } },
                    { content: 'RG', styles: { halign: 'center' } },
                    // { content: 'QP', styles: { halign: 'center' } },
                ],
            ],
            body: rows,
            theme: 'grid',
            tableWidth: 'auto',
        });

        doc.setFontSize(10);
        doc.text('CONTROLLER OF EXAMINATIONS', 130, (doc as any).lastAutoTable.finalY + 30)
        doc.text('(auto generated report)', 139, (doc as any).lastAutoTable.finalY + 34)

        doc.save(fileName + '.pdf');
    };

    const csvData = [
        [
            'Course Title',
            'Course Code',
            'Semester',
            'Degree',
            'Discipline',
            'RG',
            // 'QP',
            'Date',
            'Time'
        ],
        ...examTimetableList.map((row) => [
            row.courseTitle,
            row.courseCode,
            row.semester,
            row.degreeName,
            row.disciplineName,
            row.rg,
            // "08",
            row.date,
            row.session,
        ]),
    ];

    let candidate = examTimetableList.sort((a, b) => a.semesterNo - b.semesterNo);

    console.log(candidate);

    return (
        <>
            <PageContainer title="Details of No. of Candidates Registered">
                <Card title="Filters">
                    <div className='row'>
                        <div className="col-md-4">
                            <label htmlFor="examId">Exam <span className="text-danger">*</span> :</label>
                            <select
                                className="form-control"
                                style={{ marginRight: "20px", }}
                                name="examIdFilter"
                                value={examIdFilter}
                                onChange={(e) => setExamIdFilter(e.target.value)}
                            >
                                <option value="" selected>Select Exam</option>
                                {selectedExam.map((course) => (
                                    <option key={course.id} value={course.id}>
                                        {course.title}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="fromDate">From Date <span className="text-danger">*</span> :</label>
                            <input
                                type="date"
                                id="fromDate"
                                value={fromDateFilter}
                                onChange={(e) => setFromDateFilter(e.target.value)}
                                className="form-control"
                            />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="toDate">To Date <span className="text-danger">*</span> :</label>
                            <input
                                type="date"
                                id="toDate"
                                value={toDateFilter}
                                onChange={(e) => setToDateFilter(e.target.value)}
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="row">
                        {/* <div className="col-lg-12 col-sm-12"> */}
                        <div className=" col-12 m-2 text-end">
                            <button className='btn btn-sm btn-primary' onClick={handleSearch}  style={{ float: "right", marginTop: "30px", width: "10%" }}>Search</button>
                        </div>
                        {/* </div> */}
                    </div>
                </Card>
                <CollapseCard title="Candidates Register">
                    <div className="container-fluid ">
                        <table className="table table-success table-striped">
                            <thead>
                                <tr>
                                    <th>S.No.</th>
                                    <th>Course Title</th>
                                    <th>Course Code</th>
                                    <th>Sem</th>
                                    <th>Discipline</th>
                                    <th>RG</th>
                                    {/* <th>QP</th> */}
                                    <th>Date</th>
                                    <th>Session</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    examTimetableList?.sort((a, b) => a.semester - b.semester)?.map((row, index) => (
                                        <tr key={row.id}>
                                            <td>{index + 1}</td>
                                            <td>{row.courseTitle}</td>
                                            <td>{row.courseCode}</td>
                                            <td>{row.semester}</td>
                                            <td>{row.degreeName + ' - ' + row.disciplineName}</td>
                                            <td>{row.rg}</td>
                                            {/* <td>08s</td> */}
                                            <td className='text-truncate'>{LocalFormatDate(row.date)}</td>
                                            <td>{row.session}</td>
                                        </tr>
                                    ))
                                }

                            </tbody>
                        </table>
                    </div>
                    <div className="text-end mb-3">
                        {examTimetableList.length > 0 && < button
                            type="button"
                            onClick={handleDownloadPDF}
                            className="m-1 btn btn-sm btn-primary"
                        >
                            <Feather.Download /> PDF
                        </button>}
                        {examTimetableList.length > 0 && <CSVLink
                            data={csvData}
                            filename="details_of_course.csv"
                            className="m-1 btn btn-sm btn-primary"
                        >
                            <Feather.Download /> CSV
                        </CSVLink>}
                    </div>
                </CollapseCard>
            </PageContainer >
        </>
    );
}

export default RegisteredCandidatesReport

interface Exam {
    id: number;
    title: string;
}