import { PackingActionsList, PackingType } from "../actions/packingActions";
import { Action } from "../actions/types";

const initialValue: Array<PackingType> = [];

const packingReducer = (state: Array<PackingType> = initialValue, action: Action) => {
    switch (action.type) {
        case PackingActionsList.ADD_PACKING_BOARD:
            return [
                ...state,
                action.data,
            ];

        case PackingActionsList.UPDATE_PACKING_BOARD:
            const updated = [...state].slice();
            const index = updated.findIndex(u => u.id === action.data.id);

            if (index > -1) {
                updated[index] = {
                    ...action.data,
                };
            }

            return updated;

        case PackingActionsList.FETCH_PACKING_BOARD:
            return action.data;

        case PackingActionsList.DELETE_PACKING_BOARD:
            return state.filter(s => s.id !== action.data);

        default:
            return state;
    }
}

export default packingReducer;
