/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { GetState } from "../reducers"

export enum CourseActionList {
    ADD_COURSE = 'ADD_COURSE',
    FETCH_COURSE = 'FETCH_COURSE',
    UPDATE_COURSE = 'UPDATE_COURSE',
    DELETE_COURSE = 'DELETE_COURSE',
}

export interface CourseType {
    id?: number
    name: string
    shortName: string
    intMaxMark: string
    intPassMark: string
    extMaxMark: string
    extPassMark: string

    // shortName?: string
    // description: string
    // address: string
    // contact: string
    // examCenterName: string
    // examCenterCode: string
    // logoUrl: string

    createdBy?: string
    createTimestamp?: string   
}


export interface AddCourseAction {
    type: CourseActionList.ADD_COURSE
    data: CourseType
}

export interface FetchCourseAction {
    type: CourseActionList.FETCH_COURSE
    data: Array<CourseType>
}

export interface UpdateCourseAction {
    type: CourseActionList.UPDATE_COURSE
    data: CourseType
}

export interface DeleteCourseAction {
    type: CourseActionList.DELETE_COURSE
    data: number
}

export type CourseActions = AddCourseAction | FetchCourseAction | UpdateCourseAction | DeleteCourseAction

export const addCourse = (data: CourseType) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("User Added")
        return api.post<APIResponse<CourseType>>('course/', data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<AddCourseAction>({
                    type: CourseActionList.ADD_COURSE,
                    data: response.data.data

                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'College Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })
    }
}

export const updateCourse = (data: CourseType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("User Updated")
        return api.put<APIResponse<CourseType>>(`course/?id=${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<UpdateCourseAction>({
                    type: CourseActionList.UPDATE_COURSE,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Course Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchCourse = () => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<CourseType[]>>('course/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchCourseAction>({
                    type: CourseActionList.FETCH_COURSE,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteCourse = (id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)
        // return Promise.resolve("User Deleted")

        return api.delete<APIResponse<CourseType>>(`course/?id=${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteCourseAction>({
                    type: CourseActionList.DELETE_COURSE,
                    data: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Course Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}

