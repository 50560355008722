import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from '../components/custom/Card';
import CollapseCard from '../components/custom/CollapseCard';
import PageContainer from '../components/custom/PageContainer';
import { ExamType, addExam, deleteExam, fetchExam, updateExam } from '../redux/actions/examAction';
import { StoreState } from '../redux/reducers';
import { AppDispatch } from '../redux/store';
const Exam = () => {
    const dispatch = useDispatch<AppDispatch>()

    const examList = useSelector<StoreState, ExamType[]>(state => state.exam)

    const [editFlag, setEditFlag] = useState(false);
    const [title, setTitle] = useState('');
    const [monthYear, setMonthYear] = useState('');
    const [id, setId] = useState<number>(0);
    const [createdBy, setCreatedBy] = useState<string>('');
    const [createTimestamp, setCreateTimestamp] = useState<string>('');
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [titleError, setTitleError] = useState('');
    const [monthYearError, setMonthYearError] = useState('');

    useEffect(() => {
        dispatch(fetchExam())
    }, [])


    //add exam
    const addHandler = () => {
        if (title.length === 0 || monthYear.length === 0) {
            setTitleError('Title is required');
            setMonthYearError('Month Year is required');
        }

        if ( title.length > 100 || monthYear.length > 15) {
            setTitleError('Title must be within 100 characters');
            setMonthYearError('Month Year must be within 15 characters');
        }

        const addData = { title, monthYear, createdBy, createTimestamp };
        dispatch(addExam(addData)).then(text => {
            toast.success(text)
            clearHandler()
        }).catch(text => {
            toast.error(text)
        })
    }

    //edit exam
    const editHandler = (ex: ExamType) => {
        setEditFlag(true);
        setId(ex.id ? ex.id : 0);
        setTitle(ex.title);
        setMonthYear(ex.monthYear);
        setCreatedBy(createdBy);
        setCreateTimestamp(createTimestamp);
    }

    //update exam
    const updateHandler = () => {
        if (title.length === 0 || monthYear.length === 0) {
            setTitleError('Title is required');
            setMonthYearError('Month Year is required');
        }

        if ( title.length > 100 || monthYear.length > 15) {
            setTitleError('Title must be within 100 characters');
            setMonthYearError('Month Year must be within 15 characters');
        }

        const editData = { id, title, monthYear, createdBy, createTimestamp };

        dispatch(updateExam(editData, id)).then(text => {
            toast.success(text)
            clearHandler()
        }).catch(text => {
            toast.error(text)
        })

    }

    //delete exam
    const deleteHandler = (id: number) => {
        setId(id);
        openDeleteConfirmation(); // Open the delete confirmation modal
    }

    const confirmDeleteHandler = () => {
        if (id != undefined) {
            dispatch(deleteExam(id)).then((text) => {
                toast.success(text)
                closeDeleteConfirmation()
            }).catch(text => {
                toast.error(text)
            })
        }
    }




    const clearHandler = () => {
        setTitle('');
        setMonthYear('');
        setId(0);
        setCreatedBy('');
        setCreateTimestamp('');
        setTitleError('');
        setMonthYearError('');
        setEditFlag(false);
    }

    const openDeleteConfirmation = () => {
        setShowDeleteConfirmation(true);
    }

    const closeDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    }





    return (
        <PageContainer title="Exam">
            <Card title={editFlag ? 'Edit Exam' : 'Add Exam'}>
                <div className='container m-0'>
                    <div className="row">
                        <div className="col-6">
                            <label className="mt-3 mb-3">Title <span className="text-danger">*</span> : </label>
                            <input className="form-control" placeholder="Enter title..." type="text" name="title" onChange={(e) => {
                                setTitle(e.target.value)
                                setTitleError('')
                            }} value={title} />
                            {titleError && <div className="text-danger">{titleError}</div>}</div>


                        <div className="col-6">
                            <label className="mt-3 mb-3">Month Year <span className="text-danger">*</span> : </label>
                            <input className="form-control" placeholder="Enter month and year..." type="text" name="monthYear" onChange={(e) => {
                                setMonthYear(e.target.value)
                                setMonthYearError('')
                            }} value={monthYear} />
                            {monthYearError && <div className="text-danger">{monthYearError}</div>}</div>


                        <div className=" col mt-5 m-4 d-flex justify-content-end">
                            <button className="btn btn-sm btn-secondary" onClick={clearHandler} style={{ marginRight: '10px' }}>
                                Clear
                            </button>
                            {editFlag ? <button className="btn btn-sm btn-success" onClick={updateHandler}>Update</button> :
                                <button className="btn btn-sm btn-success" onClick={addHandler}>Add</button>}
                        </div>
                    </div>
                </div>
            </Card>
            <CollapseCard title="Exam">
                <div className="container-fluid" style={{ width: "100%" }}>
                    <table className="table table-success table-striped">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Title</th>
                                <th>Month Year</th>
                                <th>Options</th>
                            </tr>
                        </thead>
                        <tbody>
                            {examList.map((ex, index) => (
                                <tr key={ex.id}>
                                    <td>{index + 1}</td>
                                    <td>{ex.title}</td>
                                    <td>{ex.monthYear}</td>
                                    <td>
                                        <button className="btn btn-sm btn-primary" style={{ marginRight: '10px' }} onClick={() => editHandler(ex)}>Edit</button>
                                        <button className="btn btn-sm btn-danger" onClick={() => deleteHandler(ex.id ? ex.id : 0)}>Delete</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </CollapseCard>
            {/* Deletion Confirmation Modal */}
            <Modal show={showDeleteConfirmation} onHide={closeDeleteConfirmation}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this item?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeDeleteConfirmation}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={confirmDeleteHandler}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </PageContainer>
    );
}

export default Exam;

interface Exam {
    id: number;
    title: string;
    monthYear: string;
    createdBy: string;
    createTimestamp: string;
}
