import { StudentMarkActionList, StudentMarkType } from "../actions/studentMarkAction";
import { Action } from "../actions/types";

const initialValue: Array<StudentMarkType> = []

export const studentMarkReducer = (state: StudentMarkType[] = initialValue, action: Action) => {

    switch (action.type) {
        case StudentMarkActionList.ADD_STUDENTMARK:
            return [...state, action.data];

        case StudentMarkActionList.FETCH_STUDENTMARK:
            return action.data

        case StudentMarkActionList.EDIT_STUDENTMARK: {
            const updated = [...state]
            const index = updated.findIndex((u) => {
                return u.id === action.data.id
            })

            if (index > -1) {
                updated[index] = {
                    ...action.data
                }
            }

            return updated;
        }
        case StudentMarkActionList.DELETE_STUDENTMARK:
            return state.filter(i => i.id !== action.data)
        default:
            return state;
    }
}