import { DisciplineActionList, DisciplineType } from "../actions/disciplineAction";
import { Action } from "../actions/types";

const initialValue: Array<DisciplineType> = [];

const disciplineReducer = (state: Array<DisciplineType> = initialValue, action: Action) => {
    switch (action.type) {
        case DisciplineActionList.ADD_DISCIPLINE:
            return [
                ...state,
                action.data,
            ];

        case DisciplineActionList.UPDATE_DISCIPLINE:
            const updated = [...state].slice();
            const index = updated.findIndex(u => u.id === action.data.id);

            if (index > -1) {
                updated[index] = {
                    ...action.data,
                };
            }

            return updated;

        case DisciplineActionList.FETCH_DISCIPLINE:
            return action.data;

        case DisciplineActionList.DELETE_DISCIPLINE:
            return state.filter(s => s.id !== action.data);

        default:
            return state;
    }
}

export default disciplineReducer;



