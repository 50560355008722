/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { GetState } from "../reducers"

export enum FacultyActionList {
    ADD_FACULTY = 'ADD_FACULTY',
    FETCH_FACULTY = 'FETCH_FACULTY',
    UPDATE_FACULTY = 'UPDATE_FACULTY',
    DELETE_FACULTY = 'DELETE_FACULTY',
}

export interface FacultyType {
    id?: number
    code: string
    name: string
    designation: string
    discipline: string
    institute: string
    emailId: string
    contactNumber: string
    experience: number
}

export interface AddFacultyAction {
    type: FacultyActionList.ADD_FACULTY
    data: FacultyType
}

export interface FetchFacultyAction {
    type: FacultyActionList.FETCH_FACULTY
    data: Array<FacultyType>
}

export interface UpdateFacultyAction {
    type: FacultyActionList.UPDATE_FACULTY
    data: FacultyType
}

export interface DeleteFacultyAction {
    type: FacultyActionList.DELETE_FACULTY
    data: number
}

export type FacultyActions = AddFacultyAction | FetchFacultyAction | UpdateFacultyAction | DeleteFacultyAction

export const addFaculty = (data: FacultyType) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.post<APIResponse<FacultyType>>('faculty/', data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<AddFacultyAction>({
                    type: FacultyActionList.ADD_FACULTY,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Faculty Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })
    }
}

export const updateFaculty = (data: FacultyType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.put<APIResponse<FacultyType>>(`faculty/?id=${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<UpdateFacultyAction>({
                    type: FacultyActionList.UPDATE_FACULTY,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Faculty Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchFaculty = () => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<FacultyType[]>>('faculty/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchFacultyAction>({
                    type: FacultyActionList.FETCH_FACULTY,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteFaculty = (id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.delete<APIResponse<FacultyType>>(`faculty/?id=${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteFacultyAction>({
                    type: FacultyActionList.DELETE_FACULTY,
                    data: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Faculty Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}
