import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { FC, useState } from 'react';
import * as Feather from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from '../components/custom/Card';
import CollapseCard from '../components/custom/CollapseCard';
import PageContainer from '../components/custom/PageContainer';
import { CourseType } from '../redux/actions/courseActions';
import { DegreeType } from '../redux/actions/degreeAction';
import { DisciplineType } from '../redux/actions/disciplineAction';
import { DropDownType } from '../redux/actions/dropDownAction';
import { ExamType } from '../redux/actions/examAction';
import { ExamFacultyType } from '../redux/actions/examFacultyAction';
import { FacultyType } from '../redux/actions/facultyActions';
import { RegulationType } from '../redux/actions/regulationAction';
import { StoreState } from '../redux/reducers';
import { AppDispatch } from '../redux/store';
import imgPath from './img/collegeLogo.png';

interface Props {
    title: string;
}

const ExamFacultyReport: FC<Props> = (props: Props) => {

    const { title } = props;
    const dispatch = useDispatch<AppDispatch>();

    const examFacultyList = useSelector<StoreState, ExamFacultyType[]>(state => state.examFaculty);
    const facultyList = useSelector<StoreState, FacultyType[]>(state => state.faculty);
    const examList = useSelector<StoreState, ExamType[]>(state => state.exam);
    const degreeList = useSelector<StoreState, DegreeType[]>(state => state.degree);
    const disciplineList = useSelector<StoreState, DisciplineType[]>(state => state.discipline);
    const dropDownList = useSelector<StoreState, DropDownType[]>(state => state.dropDown)
    const regulatinList = useSelector<StoreState, RegulationType[]>(state => state.regulation)
    const courseList = useSelector<StoreState, CourseType[]>(state => state.course)

    const [examFacList, setExamFacList] = useState<ExamFacultyType[]>([]);

    const [examId, setExamId] = useState<string>('')
    const [examIdError, setExamIdError] = useState<string>('')

    const [searchPerformed, setSearchPerformed] = useState(false);

    //ID values
    const getDiscipline = (discipline: number) => {
        const disciplineName = disciplineList.find((dl) => dl.id?.toString() === discipline?.toString())?.name
        return disciplineName
    }

    const getExam = (exam: number) => {
        const examName = examList.find((el) => el.id?.toString() === exam?.toString())?.title
        return examName
    }

    const getDegree = (degree: number) => {
        const degreeName = degreeList.find((dg) => dg.id?.toString() === degree?.toString())?.name
        return degreeName
    }
    const getCourse = (course: number) => {
        const courseName = courseList.find((cs) => cs.id?.toString() === course?.toString())?.name
        return courseName
    }

    const getFacultyName = (disciplineCourseId: number) => {
        const disciplineCourse = facultyList.find((dc) => dc.id === disciplineCourseId);
        if (disciplineCourse) {
            return `${disciplineCourse.code} - ${disciplineCourse.name}`;
        }
        return 'N/A';
    }

    const filterStudent = () => {
        if (examId !== '') {
            const filteredExamFacultyList = examFacultyList.filter(item => item.examId === parseInt(examId));

            setExamFacList(filteredExamFacultyList);

            setSearchPerformed(true);
        } else {
            toast.error('Please select all filters');
        }
    };


    const renderDownloadButtons = () => {
        if (searchPerformed) {
            return (
                <div className="text-end mb-3">
                    <button
                        type="button"
                        onClick={handleDownloadPDF}
                        className="m-1 btn btn-sm btn-primary"
                    >
                        <Feather.Download /> PDF
                    </button>
                    {/* <CSVLink
                        data={csvData}
                        filename="details_of_course.csv"
                        className="m-1 btn btn-sm btn-primary"
                    >
                        <Feather.Download /> CSV
                    </CSVLink> */}
                </div>
            );
        }
        return null;
    };

    const handleDownloadPDF = () => {
        const doc = new jsPDF('p', 'mm', 'a4');

        doc.addImage(imgPath, 'PNG', 25, 10, 170, 40);

        doc.setFontSize(14)
        doc.setFont('Arial', 'normal', 'bold');
        doc.setTextColor(0, 0, 0);
        doc.text(title, 110, 53, { align: 'center' });
        doc.text(`${getExam(parseInt(examId))}`, 110, 64, { align: "center" });


        autoTable(doc, {
            startY: 70,
            headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0] },
            bodyStyles: { textColor: [0, 0, 0], lineColor: [0, 0, 0] },
            head: [
                [
                    { content: 'S.No.', styles: { halign: 'center' } },
                    { content: 'Degree', styles: { halign: 'center' } },
                    { content: 'Discipline', styles: { halign: 'center' } },
                    { content: 'Faculty', styles: { halign: 'center' } },
                    { content: 'Type', styles: { halign: 'center' } },
                ],
            ],
            body: examFacultyList.map((pe, index) => [
                { content: index + 1, styles: { halign: 'center' } },
                { content: getDegree(pe.degreeId), styles: { halign: 'center' } },
                { content: getDiscipline(pe.disciplineId), styles: { halign: 'center' } },
                { content: getFacultyName(pe.facultyId), styles: { halign: 'center' } },
                { content: pe.type, styles: { halign: 'center' } }
            ]),
            theme: 'grid',
        });



        // Save the PDF
        doc.save('exam_faculty_report.pdf');
    };



    return (
        <>
            <PageContainer title="Exam Faculty Report">
                <Card title="Filters">
                    <div className="row">
                        <div className="col-md-4">
                            <label className="mt-3 mb-3">Exam <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                value={examId}
                                onChange={(e) => {
                                    setExamId(e.target.value)
                                    setExamIdError('')
                                }}
                            >
                                <option value="">Select Exam</option>
                                {examList.map((ex) => (
                                    <option value={ex.id}>
                                        {ex.title}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-4">
                            <button
                                type="button"
                                className="btn btn-sm btn-primary"
                                onClick={filterStudent}
                                style={{ width: "32%", marginTop: "57px" }}
                            >
                                Search
                            </button>
                        </div>
                    </div>
                </Card>
                <CollapseCard title="Exam Faculty Report">
                    <div className="container-fluid table-container" style={{ width: "150%" }}>
                        <table className="table table-success table-striped" style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <th>S.No.</th>
                                    <th>Exam</th>
                                    <th>Degree</th>
                                    <th>Discipline</th>
                                    <th>Faculty</th>
                                    <th>Type</th>
                                </tr>
                            </thead>
                            <tbody>
                                {examFacList.map((pe, index) => (
                                    <tr key={pe.id}>
                                        <td className="report-td">{index + 1}</td>
                                        <td>{getExam(pe.examId)}</td>
                                        <td>{getDegree(pe.degreeId)}</td>
                                        <td>{getDiscipline(pe.disciplineId)}</td>
                                        <td>{getFacultyName(pe.facultyId)}</td>
                                        <td>{pe.type}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div>
                        {renderDownloadButtons()}
                    </div>
                </CollapseCard>
            </PageContainer>
        </>
    );
}

export default ExamFacultyReport;


