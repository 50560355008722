import axios from 'axios';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from '../components/custom/Card';
import CollapseCard from '../components/custom/CollapseCard';
import PageContainer from '../components/custom/PageContainer';
import { apiUrl } from '../config';
import { YearMonthDateFormatDate } from '../config/functions';

import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import * as Feather from 'react-feather';
import imgPath from './img/collegeLogo.png';
import { CSVLink } from 'react-csv';
import { useSelector } from 'react-redux';
import { StoreState } from '../redux/reducers';
import { DisciplineType } from '../redux/actions/disciplineAction';
import { DegreeType } from '../redux/actions/degreeAction';
import { LocalFormatDate } from '../components/common/functions';

const ExamTimetable = () => {
    const [examTimetableList, setExamTimetableList] = useState<ExamTimetableType[]>([]);
    const [studentarrearList, setStudentarrearList] = useState<StudentArrearType[]>([]);


    const [editFlag, setEditFlag] = useState(false);
    const [examId, setExamId] = useState<string>('');
    const [disciplineCourseId, setDisciplineCourseId] = useState('');
    const [date, setDate] = useState<Date | null>(null);
    const [inputDate, setInputDate] = useState<Date | null>(null);
    const [session, setSession] = useState('');
    const [id, setId] = useState<number>(0);
    const [createdBy, setCreatedBy] = useState<string>('');
    const [createTimestamp, setCreateTimestamp] = useState<string>('');
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [apiErrorMessage, setApiErrorMessage] = useState('');

    const [examIdError, setExamIdError] = useState('');
    const [disciplineCourseIdError, setDisciplineCourseIdError] = useState('');
    //for exam id
    const [selectedExamId, setSelectedExamId] = useState('');
    const [selectedExam, setSelectedExam] = useState<Exam[]>([]);
    //for decipline course
    const [disciplineCourseList, setDisciplineCourseList] = useState<DisciplineCourseType[]>([]);
    const [filteredDisciplineCourseList, setFilteredDisciplineCourseList] = useState<DisciplineCourseType[]>([]);
    //other name dropdown
    const [selectedDegreeId, setSelectedDegreeId] = useState(''); // Default to empty
    const [selectedDisciplineId, setSelectedDisciplineId] = useState(''); // Default to empty
    const [selectedCourseId, setSelectedCourseId] = useState('');
    const [selectedDiscipline, setSelectedDiscipline] = useState<Discipline[]>([]);
    const [selectedDegree, setSelectedDegree] = useState<Degree[]>([]);
    const [selectedCourse, setSelectedCourse] = useState<Course[]>([]);
    const [semester, setSemester] = useState<string>('');

    //fix date and session in form
    const [dateValues, setDateValues] = useState<(Date | null)[]>([]);
    const [sessionValues, setSessionValues] = useState<string[]>([]);//new Array(disciplineCourseList.length).fill('')
    const [dateValuesError, setDateValuesError] = useState('');
    const [sessionValuesError, setSessionValuesError] = useState('');

    //for edit model
    const [editModalShow, setEditModalShow] = useState(false);
    const [editedId, setEditedId] = useState(0);
    const [editedExamId, setEditedExamId] = useState('');
    const [editedDisciplineCourseId, setEditedDisciplineCourseId] = useState('');
    const [editedDate, setEditedDate] = useState<Date | null>(null);
    const [editedSession, setEditedSession] = useState('');

    const [dateValuesEditError, setDateValuesEditError] = useState('');
    const [sessionValuesEditError, setSessionValuesEditError] = useState('');

    //pdf generation
    const [examTimetableData, setExamTimetableData] = useState<ExamTimetableReportType[]>([]);

    const [examFilter, setExamFilter] = useState<string>('')
    const [degreeFilter, setDegreeFilter] = useState<string>('')
    const [disciplineFilter, setDisciplineFilter] = useState<string>('')
    const [regulationFilter, setRegulationFilter] = useState<string>('')



    const [selectedRegulation, setSelectedRegulation] = useState<string>('');

    const disciplineList = useSelector<StoreState, DisciplineType[]>(state => state.discipline)

    const degreeList = useSelector<StoreState, DegreeType[]>(state => state.degree);

    const [RegulationList, setRegulationList] = useState<Regulation[]>([]);

    const [ExamList, setExamList] = useState<Exam[]>([])

    const [arearChecked, setArearChecked] = useState(true);

    // Checkbox change handler
    const handleArearCheckboxChange = (e:any) => {
        setArearChecked(e.target.checked);
    };

    //to add multiple records in to the table
    const [records, setRecords] = useState<ExamRecord[]>([]);

    useEffect(() => {
        axios.get(`${apiUrl}/discipline/`)
            .then((response) => {
                setSelectedDiscipline(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching discipline', error);
            });
    }, []);

    useEffect(() => {
        axios.get(`${apiUrl}/degree/`)
            .then((response) => {
                setSelectedDegree(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching degree', error);
            });
    }, []);

    useEffect(() => {
        axios.get(`${apiUrl}/course/`)
            .then((response) => {
                setSelectedCourse(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching Course', error);
            });
    }, []);

    useEffect(() => {
        axios.get(`${apiUrl}/exam/`)
            .then((response) => {
                setSelectedExam(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching Course', error);
            });
    }, []);

    useEffect(() => {
        axios.get(`${apiUrl}/regulation/`).then((response) => {
            setRegulationList(response.data.data);
        }).catch((error) => {
            console.error('Error fetching regulation', error);
        });
    }, [])

    useEffect(() => {
        axios.get(`${apiUrl}/regulation/`).then((response) => {
            setRegulationList(response.data.data);
        }).catch((error) => {
            console.error('Error fetching regulation', error);
        });
    }, [])

    useEffect(() => {
        axios.get(`${apiUrl}/studentarrear/`).then((response) => {
            setStudentarrearList(response.data.data);
        }).catch((error) => {
            console.error('Error fetching student arrear', error);
        });
    }, [])

    //add search
    const handleSearch = () => {
        if (selectedDegreeId !== '' && selectedDisciplineId !== '' && semester !== '') {
            let filteredData = disciplineCourseList?.filter(di => di.disciplineId?.toString() === selectedDisciplineId && di.degreeId?.toString() === selectedDegreeId && di.semesterNo?.toString() === semester);
            let filteredDataArrear: DisciplineCourseType[] = [];
            let studentArrearData: number[] = []; 
    
            if (arearChecked) {
                let studentArrearSet = studentarrearList.filter(sa => sa.disciplineId?.toString() === selectedDisciplineId && sa.degreeId?.toString() === selectedDegreeId && sa.status === 'Pending' && sa.semesterNo.toString() !== semester).map(sa => sa.disciplineCourseId);
                
                console.log(studentArrearSet)
                filteredDataArrear = disciplineCourseList?.filter(di => studentArrearSet.includes(di.id));
            } 

            console.log(filteredData );
            console.log(filteredDataArrear);
    
            let mergedFilteredData = arearChecked ? [...filteredData, ...filteredDataArrear] : filteredData;
    
            setFilteredDisciplineCourseList(mergedFilteredData);
        } else {
            setFilteredDisciplineCourseList([]);
        }
    };
    
    
    

    useEffect(() => {
        axios.get(`${apiUrl}/disciplineCourse/`)
            .then((response) => {
                setDisciplineCourseList(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching Course', error);
            });
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        axios.get<{ data: ExamTimetableType[] }>(`${apiUrl}/examTimetable/`)
            .then((response) => {
                setExamTimetableList(response.data.data);
            })
            .catch((error) => console.log(error));
    }

    // useEffect(() => {
    //     // Initialize dateValues array
    //     // setDateValues(new Array(disciplineCourseList.length).fill(null));
    //     setSessionValues(new Array(disciplineCourseList.length).fill(''));
    // }, [disciplineCourseList]);


    const addHandler = async () => {
        let error = false;

        if (selectedExamId.length === 0) {
            setExamIdError('Exam is required');
            error = true;
        }
        // console.log(dateValues.length);
        // console.log(filteredDisciplineCourseList.length);

        const areDatesValid = dateValues?.every((date) => date !== null);
        // console.log(areDatesValid);
        if (dateValues.length !== filteredDisciplineCourseList.length) {
            setDateValuesError('Date is required for all rows');
            error = true;
        }
        else if (!areDatesValid) {
            setDateValuesError('Date is required for all rows');
            error = true;
        }

        console.log(sessionValues);
        const areSessionsValid = sessionValues.every((session) => session.length > 0 && session.length <= 5);
        console.log(areSessionsValid);

        if (sessionValues.length !== filteredDisciplineCourseList.length) {
            setSessionValuesError('Session is required for all rows');
            error = true;
        }
        else if (!areSessionsValid) {
            setSessionValuesError('Session is required for all rows');
            error = true;
        }

        // const getDisciplineCourseId = (courseId: string) => {
        //     const selectedCourseIdInt = courseId;
        //     const course = selectedCourse.find((c) => c.id === parseInt(selectedCourseIdInt));
        //     console.log(course);
        //     if (course) {
        //       const foundCourse = disciplineCourseList.find((disciplineCourse) => parseInt(disciplineCourse.courseId) === course.id && disciplineCourse.degreeId === selectedDegreeId && disciplineCourse.disciplineId === selectedDisciplineId);
        //       console.log(foundCourse);
        //       if (foundCourse) {
        //         return foundCourse.id;
        //       }
        //     }
        //     return 'N/A';
        //   };

        const getDisciplineCourseId = (degreeId: string, disciplineId: string, courseId: string) => {
            const selectedCourseIdInt = parseInt(courseId);
            const selectedDisciplineIdInt = parseInt(disciplineId);
            const selectedDegreeIdInt = parseInt(degreeId);
            const course = selectedCourse.find((c) => c.id === selectedCourseIdInt);
            const discipline = selectedDiscipline.find((c) => c.id === selectedDisciplineIdInt);
            const degree = selectedDegree.find((c) => c.id === selectedDegreeIdInt);
            if (course && discipline && degree) {
                console.log(course);
                const foundCourse = disciplineCourseList.find((disciplineCourse) => parseInt(disciplineCourse.courseId) === course.id && parseInt(disciplineCourse.disciplineId) === discipline.id && parseInt(disciplineCourse.degreeId) === degree.id);
                if (foundCourse) {
                    return foundCourse.id;
                }
            }
            return 'N/A';
        };

        const newRecords = dateValues.map((dateValue, index) => ({
            examId: selectedExamId,
            disciplineCourseId: getDisciplineCourseId(selectedDegreeId, selectedDisciplineId, filteredDisciplineCourseList[index].courseId),
            date: dateValue ? YearMonthDateFormatDate(dateValue?.toString()) : null,
            session: sessionValues[index],
            createdBy: createdBy,
            createTimestamp: createTimestamp,
        }));

        // Clear all the input fields
        setSelectedExamId('');
        setInputDate(null);
        setSession('');
        setCreatedBy('');
        setCreateTimestamp('');
        // setDateValues(new Array(disciplineCourseList.length).fill(null));
        // setSessionValues(new Array(disciplineCourseList.length).fill(''));
        setDateValues([])
        setSessionValues([])
        if (!error) {
            try {
                const response = await axios.post(`${apiUrl}/examTimetable/`, newRecords);

                if (response.data.error) {
                    toast.error(response.data.message, { position: 'top-right' });
                    setApiErrorMessage(response.data.message);
                } else {
                    toast.success(response.data.message);
                    setExamIdError('');
                    setDisciplineCourseIdError('');
                    setDateValuesError('');
                    setSessionValuesError('');
                    fetchData();
                    clearHandler();
                }
            } catch (error: any) {
                toast.error(error.response.data.message);
            }
        }
    };

    const deleteHandler = (id: number) => {
        setId(id);
        openDeleteConfirmation(); // Open the delete confirmation modal
    }

    const confirmDeleteHandler = () => {
        axios
            .delete(`${apiUrl}/examTimetable/?id=${id}`)
            .then((response) => {
                fetchData();
                closeDeleteConfirmation();
                toast.success(response.data.message);
            })
            .catch((error) => {
                console.log(error);
                toast.error(error.response.data.message, { position: 'top-right' });
            });
    };

    // const editHandler = (id: number, examId: string, disciplineCourseId: string, date: Date, session: string, createdBy: string, createTimestamp: string) => {
    //   setEditFlag(true);
    //   setId(id);
    //   setSelectedExamId(examId);
    //   // setDisciplineCourseId(disciplineCourseId);//setSelectedDisciplineCourseId
    //   setDate(date);
    //   setSession(session);
    //   setCreatedBy(createdBy);
    //   setCreateTimestamp(createTimestamp);
    // }

    const openEditModal = (id: number, examId: string, disciplineCourseId: string, date: Date, session: string) => {
        console.log(id);
        setEditedId(id);
        console.log(examId);
        setEditedExamId(examId);
        console.log(disciplineCourseId);
        setEditedDisciplineCourseId(disciplineCourseId);
        console.log(date);
        setEditedDate(date);
        setEditedSession(session);
        setEditModalShow(true);
    };



    // const updateHandler = () => {
    //   if (selectedExamId.length === 0) {
    //     setExamIdError('Exam ID is required');
    //     return;
    //   }
    //   // if (disciplineCourseId.length === 0) {
    //   //   setDisciplineCourseIdError('Discipline Course ID is required');
    //   //   return;
    //   // }
    //   if (inputDate === null) {
    //     setDateValuesError('Date is required');
    //     return;
    //   }
    //   if (session.length === 0) {
    //     setSessionValuesError('Session is required');
    //     return;
    //   }
    //   if (session.length > 5) {
    //     setSessionValuesError('Session must be within 5 characters');
    //     return;
    //   }

    //   const date = inputDate.toLocaleDateString();

    //   const data = { id, examId:selectedExamId, disciplineCourseId:selectedCourseId, date, session, createdBy, createTimestamp };
    //   axios.post(`${apiUrl}/examTimetable/?id=${id}`,data)
    //     .then((response) => {
    //       if (response.data.error) {
    //         toast.error('Failed to add Exam Timetable', { position: 'top-right' });
    //         setApiErrorMessage(response.data.message);
    //     }else{
    //       toast.success('Exam Timetable added successfully');
    //       setExamIdError('');
    //       setDisciplineCourseIdError('');
    //       setDateValuesError('');
    //       setSessionValuesError('');
    //       fetchData();
    //       clearHandler();
    //     }})
    //     .catch((error) => console.log(error));
    // }

    const saveEdit = () => {
        let hasErrors = false;

        if (editedDate === null) {
            setDateValuesEditError('Date is required');
            hasErrors = true;
        } else {
            setDateValuesEditError('');
        }

        if (editedSession.trim() === '') {
            setSessionValuesEditError('Session is required');
            hasErrors = true;
        } else {
            setSessionValuesEditError('');
        }

        if (hasErrors) {
            return;
        }

        const date = editedDate ? YearMonthDateFormatDate(editedDate.toString()) : null;

        const getExamId = (examId: string) => {
            console.log('Exam Name to find:', examId);
            console.log('Selected Exam Array:', selectedExam);
            const exam = selectedExam.find((e) => e.title === examId);
            console.log('Found Exam:', exam);
            return exam ? exam.id : 'N/A';
        };

        // const getCourseId = (courseId: string) => {
        //     const course = selectedCourse.find((c) => c.name === courseId);
        //     return course ? course.id : 'N/A';
        // };

        const editedData = {
            id: editedId,
            examId: getExamId(editedExamId),
           // disciplineCourseId: getCourseId(editedDisciplineCourseId),
            date: date,
            session: editedSession,
        };

        axios
            .put(`${apiUrl}/examTimetable/?id=${editedId}`, editedData)
            .then((response) => {
                if (response.data.error) {
                    toast.error(response.data.message, { position: 'top-right' });
                    setApiErrorMessage(response.data.message);
                } else {
                    toast.success(response.data.message)
                    setEditModalShow(false);
                    setDateValuesError('');
                    setSessionValuesError('');
                    fetchData();
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };



    const clearHandler = () => {
        setSelectedExamId('');
        setSelectedCourseId('');
        setSelectedDegreeId('');
        setSelectedDisciplineId('');
        setSemester('')
        setInputDate(null);
        setSession('');
        // setDateValues(new Array(disciplineCourseList.length).fill(null));
        // setSessionValues(new Array(disciplineCourseList.length).fill(''));
        setDateValues([])
        setSessionValues([])
        setFilteredDisciplineCourseList([])
        setId(0);
        setCreatedBy('');
        setCreateTimestamp('');
        setExamIdError('');
        setSemester('');
        setDisciplineCourseIdError('');
        setDateValuesError('');
        setSessionValuesError('');
        setEditFlag(false);
        setFilteredDisciplineCourseList([])
    }

    const openDeleteConfirmation = () => {
        setShowDeleteConfirmation(true);
    }

    const closeDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    }

    const getDisciplineId = (disciplineCourse: DisciplineCourseType) => {
        const discipline = selectedDiscipline.find((disc) => disc.id === parseInt(disciplineCourse.disciplineId));
        if (discipline) {
            return discipline.name;
        }
        return 'N/A';
    };

    const getDegreeId = (disciplineCourse: DisciplineCourseType) => {
        const degree = selectedDegree.find((disc) => disc.id === parseInt(disciplineCourse.degreeId));
        if (degree) {
            return degree.name;
        }
        return 'N/A';
    };

    const getCourseId = (courseId: string) => {
        const courseName = selectedCourse.find((course) => course.id === parseInt(courseId));
        if (courseName) {
            return courseName.name; // Change this to the property you want to display
        }
        return 'N/A';
    };

    const getExamId = (examId: any) => {
        const examName = selectedExam.find((exam) => exam.id === parseInt(examId));
        if (examName) {
            return examName.title;
        }
        return 'N/A';
    }
    console.log(semester);



    const formatDate = (inputDate: string) => {
        const date = new Date(inputDate);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedMonth = month < 10 ? `0${month}` : month;

        return `${formattedDay}-${formattedMonth}-${year}`;
    };


    const uniqueDegreeDiscipline = examTimetableList.map(m => m.disciplineName).filter(function (v, i, self) {
        // It returns the index of the first
        // instance of each value
        return i === self.indexOf(v);
    });


    const searchHandle = () => {
        if (examFilter !== '' && regulationFilter !== '') {
            const degId = degreeFilter ? degreeFilter : null;
            const disId = disciplineFilter ? disciplineFilter : null;

            axios.get(`${apiUrl}/examTimetable/viewFilter/?examId=${examFilter}&regulation=${regulationFilter}&degreeId=${degId}&disciplineId=${disId}`)
                .then((response) => {
                    setExamTimetableList(response.data.data);
                })
                .catch((error) => {
                    console.error('Error fetching examTimetableData', error);
                });
        } else {
            toast.error('Select Exam and Regulation Filters', { position: 'top-right', autoClose: 3000 });

            setExamTimetableList([])
        }
    }

    const resetData = () => {
        setExamTimetableList([])
        setExamFilter('')
        setRegulationFilter('')
        setDegreeFilter('')
        setDisciplineFilter('')
    }

    const handleDownloadPDF = () => {

        const doc = new jsPDF('p', 'mm', 'a4');
        // const examObj = ExamList.find(f => f.id?.toString() === selectedExamId)
        doc.addImage(imgPath, 'PNG', 20, 10, 170, 40);

        doc.setFont('Arial', 'normal', 'bold');
        doc.setFontSize(10)
        doc.text(`TIME TABLE-B.E. / B.Tech. DEGREE EXAMINATIONS -${getExamId(examFilter)}`, 35, 55)
        doc.text(`(REGULATIONS-2024)`, 80, 62)
        doc.text(`CHOICE BASED CREDIT SYSTEM (CBCS)`, 65, 69)

        // console.log('af');

        for (let d = 0; d < uniqueDegreeDiscipline.length; d++) {
            autoTable(doc, {
                startY: d === 0 ? 78 : (doc as any).lastAutoTable.finalY + 10,
                headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0], fontSize: 8 },
                bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0], fontSize: 8 },

                head: [
                    [
                        { content: `Degree & Discipline Name: ${uniqueDegreeDiscipline[d]}`, colSpan: 5 }
                    ],
                    [
                        { content: "Semester", styles: { halign: 'center' } },
                        { content: "Course Code", styles: { halign: 'center' } },
                        { content: "Course Title", styles: { halign: 'center' } },
                        { content: "Exam Date", styles: { halign: 'center' } },
                        { content: "Session", styles: { halign: 'center' } },
                    ],
                ],

                body: examTimetableList?.filter((tt) => tt.disciplineName === uniqueDegreeDiscipline[d]).map((t) => (
                    [
                        { content: t.semester, styles: { halign: 'center' } },
                        { content: t.courseCode, styles: { halign: 'center' } },
                        { content: t.courseName },
                        { content: t.date?.toString(), styles: { halign: 'center' } },
                        { content: t.session, styles: { halign: 'center' } }
                    ]
                ))
            })

        }

        doc.setFontSize(9)
        doc.setFont('Arial', 'normal', 'bold');
        doc.text("FN: FORENOON (10.00 AM to 01.00 PM)", 15, (doc as any).lastAutoTable.finalY + 10)
        doc.text("AN: AFTERNOON (02.00 PM to 05.00 PM)", 15, (doc as any).lastAutoTable.finalY + 15)

        doc.text('CONTROLLER OF EXAMINATIONS', 132, (doc as any).lastAutoTable.finalY + 35)

        doc.save('exam_timetable_report' + '.pdf');

    }

    const csvData = [
        ['S.No', 'Discipline Name', 'Semester', 'Course Code', 'Course Title', 'Exam Date', 'Session']
    ]

    examTimetableList.forEach((e, i) => {
        csvData.push([(i + 1).toString(), e.disciplineName, e.semester?.toString(), e.courseCode, e.courseName, e.date?.toString(), e.session])
    })


    return (
        <PageContainer title="Exam Timetable">
            <Card title="Filter">
                <div className='row'>
                    <div className="col-md-3">
                        <label className="mt-1 mb-3">Degree <span className="text-danger">*</span> :</label>
                        <select
                            className="form-control"
                            value={selectedDegreeId || ''}
                            onChange={(e) => setSelectedDegreeId(e.target.value)}
                        >
                            <option value="">Select Degrees</option>
                            {selectedDegree.map((course) => (
                                <option key={course.id} value={course.id}>
                                    {course.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-3">
                        <label className="mt-1 mb-3">Discipline <span className="text-danger">*</span> :</label>
                        <select
                            className="form-control"
                            value={selectedDisciplineId || ''}
                            onChange={(e) => setSelectedDisciplineId(e.target.value)}
                        >
                            <option value="">Select Disciplines</option>
                            {selectedDiscipline.map((course) => (
                                <option key={course.id} value={course.id}>
                                    {course.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-3">
                        <label className="mt-1 mb-3">Semester <span className="text-danger">*</span> :</label>
                        <select
                            className="form-control"
                            value={semester}
                            onChange={(e) => setSemester(e.target.value)}
                        >
                            <option value="" >Select Semester</option>
                            <option value="1" >1</option>
                            <option value="2" >2</option>
                            <option value="3" >3</option>
                            <option value="4" >4</option>
                            <option value="5" >5</option>
                            <option value="6" >6</option>
                            <option value="7" >7</option>
                            <option value="8" >8</option>

                        </select>
                    </div>
                    <div className="col-md-3">
                        <label className="mt-1 mb-3">Exam <span className="text-danger">*</span> : </label>
                        <select
                            className="form-control"
                            // style={{ width: "30%", marginRight: "20px", }}
                            name="selectedExam"
                            value={selectedExamId}
                            onChange={(e) => {
                                setSelectedExamId(e.target.value)
                                setExamIdError('')
                            }}
                        >
                            <option value="" selected>Select Exam</option>
                            {selectedExam.map((course) => (
                                <option key={course.id} value={course.id}>
                                    {course.title}
                                </option>
                            ))}
                        </select>
                        {examIdError && <div className="text-danger">{examIdError}</div>}
                    </div>
                    <div className="col-md-3">
                        <label className="m-3">Arrear:</label>
                        <input
                            type="checkbox"
                            checked={arearChecked}
                            onChange={handleArearCheckboxChange}
                        />
                    </div>
                    {/* <div className="d-flex justify-content-end">
                     
                        <div className=" col-12 mt-5 mb-3">
                            <button className='btn btn-sm btn-primary' onClick={handleSearch}>Search</button>
                        </div>
                       
                    </div> */}
                    <div className=" col-12 text-end">
                        <button className='btn btn-sm btn-primary' onClick={handleSearch} style={{ float: "right", width: "10%", marginTop: "30px" }}>Search</button>
                    </div>
                </div>
            </Card>
            <Card title="Add Exam Timetable">
                <div>
                    {/* <div className="col-md-3">
                        <label className="mt-1 mb-3">Exam <span className="text-danger">*</span> : </label>
                        <select
                            className="form-control"
                            // style={{ width: "30%", marginRight: "20px", }}
                            name="selectedExam"
                            value={selectedExamId}
                            onChange={(e) => {
                                setSelectedExamId(e.target.value)
                                setExamIdError('')
                            }}
                        >
                            <option value="" selected>Select Exam</option>
                            {selectedExam.map((course) => (
                                <option key={course.id} value={course.id}>
                                    {course.title}
                                </option>
                            ))}
                        </select>
                        {examIdError && <div className="text-danger">{examIdError}</div>}
                    </div> */}


                    {/* </div> */}
                    <br />
                    <div className='card table-wrapper overflow-auto'>

                        <table className="table table-success table-striped">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Degree</th>
                                    <th>Discipline</th>
                                    <th>Course</th>
                                    <th>Course Head</th>
                                    <th>Course Type</th>
                                    <th>Course Category</th>
                                    <th>Course Sequence No</th>
                                    <th>Semester No</th>
                                    <th>Date</th>
                                    <th>Session</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredDisciplineCourseList.map((disciplineCourse, index) => (
                                    <tr key={disciplineCourse.id}>
                                        <td>{index + 1}</td>
                                        <td>{getDegreeId(disciplineCourse)}</td>
                                        <td>{getDisciplineId(disciplineCourse)}</td>
                                        <td>{getCourseId(disciplineCourse.courseId)}</td>
                                        <td>{disciplineCourse.courseHead}</td>
                                        <td>{disciplineCourse.courseType}</td>
                                        <td>{disciplineCourse.courseCategory}</td>
                                        <td>{disciplineCourse.courseSeqNo}</td>
                                        <td>{disciplineCourse.semesterNo}</td>
                                        <td className='w-100 px-0'>
                                            <DatePicker
                                                className="form-control "
                                                selected={dateValues[index]} // Use dateValues[index] for this row
                                                onChange={(newDate: Date | null) => {
                                                    console.log("Selected Date for Row", index, newDate);
                                                    const newDateValues = [...dateValues];
                                                    newDateValues[index] = newDate;
                                                    console.log("Updated dateValues for Row", index, newDateValues);
                                                    setDateValues(newDateValues);// Update the dateValues state for this row
                                                    setDateValuesError('')
                                                }}
                                                dateFormat='yyyy-MM-dd'
                                                placeholderText="Select a date"
                                            />
                                            {dateValuesError && <div className="text-danger">{dateValuesError}</div>}
                                        </td>
                                        <td className='w-100 px-1'>
                                            <select
                                                className="form-control"
                                                name="session"
                                                style={{ width: "100%" }}
                                                onChange={(e) => {
                                                    const newSessionValues = [...sessionValues];
                                                    newSessionValues[index] = e.target.value;
                                                    setSessionValues(newSessionValues);
                                                    setDisciplineCourseId(disciplineCourse.courseId);
                                                }}
                                                value={sessionValues[index]}
                                            >
                                                <option value="">Select</option>
                                                <option value="AN">AN</option>
                                                <option value="FN">FN</option>
                                            </select>
                                            {sessionValuesError && <div className="text-danger">{sessionValuesError}</div>}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>


                    <div className="m-4 d-flex justify-content-end">
                        <button className="btn btn-sm btn-secondary" onClick={clearHandler} style={{ marginRight: '10px' }}>
                            Clear
                        </button>
                        {/* {editFlag ? <button className="btn btn-success" onClick={updateHandler}>Update</button> :} */}
                        <button className="btn btn-sm btn-success" onClick={addHandler}>Add</button>
                    </div>
                </div>
            </Card>
            <CollapseCard title="ExamTimetable">
                <div className="row">
                    <div className="col-md-4">
                        <label className="mt-3 mb-3">Exam <span className="text-danger">*</span> :</label>
                        <select
                            className="form-control"
                            value={examFilter}
                            onChange={(e) => setExamFilter(e.target.value)}
                        >
                            <option value="">Select Exam</option>
                            {selectedExam.map((ex) => (
                                <option key={ex.id} value={ex.id}>
                                    {ex.title}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-4">
                        <label className="mt-3 mb-3">Regulation <span className="text-danger">*</span> :</label>
                        <select
                            className="form-control"
                            value={regulationFilter}
                            onChange={(e) => setRegulationFilter(e.target.value)}
                        >
                            <option value="">Select Regulation</option>
                            {RegulationList.map((reg) => (
                                <option key={reg.id} value={reg.title}>
                                    {reg.title}

                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-4">
                        <label className="mt-3 mb-3">Degree : </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px" }}
                            name="selectedDegree"
                            value={degreeFilter}
                            onChange={(e) => setDegreeFilter(e.target.value)}
                        >
                            <option value="" selected>Select Degree</option>
                            {degreeList.map((course) => (
                                <option key={course.id} value={course.id}>
                                    {course.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col-md-4">
                        <label className="mt-3 mb-3">Discipline : </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px", }}
                            name="selectedDiscipline"
                            value={disciplineFilter}
                            onChange={(e) => setDisciplineFilter(e.target.value)}
                        >
                            <option value="" selected>Select Discipline</option>
                            {disciplineList.map((course) => (
                                <option key={course.id} value={course.id}>
                                    {course.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    {/* <div className=" col-md-4 ">
                        <button className='btn btn-sm btn-primary' onClick={searchHandle} style={{ width: "32%", marginTop: "60px" }}>Search</button>
                    </div> */}
                    <div className="m-4 d-flex justify-content-end">
                        <button
                            type="button"
                            className="btn btn-sm btn-primary mt-5 mb-4"
                            style={{ marginRight: '10px' }}
                            onClick={searchHandle}
                        >
                            Search
                        </button>

                        <button
                            type="button"
                            className="btn btn-sm btn-secondary mt-5 mb-4"
                            onClick={resetData}
                        >Reset
                        </button>
                    </div>


                </div>
            
                    <div  style={{margin: '0% 5% 0% 0%', float: 'right'}}>
                        {examTimetableList.length > 0 && <div className="text-end mb-3">
                            <button
                                type="button"
                                onClick={handleDownloadPDF}
                                className="m-1 btn btn-sm btn-primary"
                            >
                                <Feather.Download /> PDF
                            </button>
                            <CSVLink
                                data={csvData}
                                filename="exam_timetable.csv"
                                className="m-1 btn btn-sm btn-primary"
                            >
                                <Feather.Download /> CSV
                            </CSVLink>
                        </div>
                        }
                    </div>
                
                <table className="table table-success table-striped m-2">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Exam</th>
                            <th>Degree</th>
                            <th>Discipline</th>
                            <th>Discipline Course</th>
                            <th>Date</th>
                            <th>Session</th>
                            {/* <th>Created By</th>
                            <th>Create Timestamp</th> */}
                            <th>Options</th>
                        </tr>
                    </thead>
                    <tbody>
                    {examTimetableList.map((ett, index) => {
                            // console.log(ett); 
                            return (
                                <tr key={ett.id}>
                                    <td>{index + 1}</td>
                                    <td>{getExamId(ett.examId)}</td>
                                    <td>{ett.degreeName}</td>
                                    <td>{ett.disciplineName}</td>
                                    <td>{ett.courseName}</td>
                                    <td>{formatDate(ett.date ? YearMonthDateFormatDate(ett.date.toString()) : 'N/A')}</td>
                                    <td>{ett.session}</td>
                                    {/* <td>{ett.createdBy}</td>
                                    <td>{ett.createTimestamp}</td> */}
                                    <td>
                                        <button className="btn btn-sm btn-primary" style={{ marginRight: '10px' }} onClick={() => openEditModal(ett.id, getExamId(ett.examId), ett.disciplineName, ett.date, ett.session)}>Edit</button>
                                        <button className="btn btn-sm btn-danger" onClick={() => deleteHandler(ett.id)}>Delete</button>
                                    </td>
                                </tr>
                            );
                        })}

                    </tbody>

                    {/* </div> */}
                </table>
               
            </CollapseCard>
            <Modal show={editModalShow} onHide={() => setEditModalShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Exam Timetable</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-3">
                            <label className="mt-3 mb-3">Exam:</label>
                            <input className="form-control" type="text" value={editedExamId} readOnly />
                        </div>
                        <div className="col-9">
                            <label className="mt-3 mb-3">Discipline Course:</label>
                            <input className="form-control" type="text" value={editedDisciplineCourseId} readOnly />
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-6">
                            <label className="mt-3 mb-3">Date <span className="text-danger">*</span> :</label><br />
                            <DatePicker
                                className="form-control"
                                value={editedDate ? formatDate(editedDate.toString()) : ''}
                                onChange={(newDate: Date | null) => setEditedDate(newDate)}
                                dateFormat="yyyy-MM-dd"
                                placeholderText="Select a date"
                            />
                            {dateValuesEditError && <div className="text-danger">{dateValuesEditError}</div>}
                        </div>
                        <div className="col-6">
                            <label className="mt-3 mb-3">Session <span className="text-danger">*</span> :</label>
                            <select
                                className="form-control"
                                name="session"
                                value={editedSession}
                                onChange={(e) => setEditedSession(e.target.value)}
                            >
                                <option value="">Select</option>
                                <option value="AN">AN</option>
                                <option value="FN">FN</option>
                            </select>
                            {sessionValuesEditError && <div className="text-danger">{sessionValuesEditError}</div>}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setEditModalShow(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={saveEdit}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Deletion Confirmation Modal */}
            <Modal show={showDeleteConfirmation} onHide={closeDeleteConfirmation}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this item?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeDeleteConfirmation}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={confirmDeleteHandler}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </PageContainer >
    );
}

export default ExamTimetable;

interface ExamTimetableType {
    id: number;
    examId: string;
    degreeName: string;
    department: number;
    disciplineCourseId: string;
    courseName: string
    disciplineName: string;
    date: Date;
    session: string;
    semester: number;
    courseCode: string;
    createdBy: string;
    createTimestamp: string;
}

interface DisciplineCourseType {
    id: number;
    disciplineId: string;
    // examId: string;
    degreeId: string;
    courseId: string;
    courseHead: string;
    courseType: string;
    courseCategory: string;
    courseSeqNo: string;
    semesterNo: string;
}

interface Discipline {
    id: number;
    name: string;
}

interface Degree {
    id: number;
    name: string;
}

interface Course {
    id: number;
    name: string;
}

interface Exam {
    id: number;
    title: string;
}

interface ExamRecord {
    examId: string;
    disciplineCourseId: string;
    date: string | null;
    session: string;
    createdBy: string;
    createTimestamp: string;
}

interface Regulation {
    id: number,
    title: number
}

interface ExamTimetableReportType {
    date: string
    session: string
    sem: string
    courseName: string
    courseCode: string
    degreeName: string
    disciplineName: string
}

interface StudentArrearType {
    id?: number;
    disciplineId: number;
    degreeId: number;
    registerNo: string;
    semesterNo: number;
    disciplineCourseId: number;
    attemptCount: number;
    clearedSemester: number;
    status: string;
    createdBy: string;
    createTimestamp: string;
}