import axios from 'axios';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Option from '../components/Option';
import Card from '../components/custom/Card';
import CollapseCard from '../components/custom/CollapseCard';
import FormInput from '../components/custom/FormInput';
import PageContainer from '../components/custom/PageContainer';
import { apiUrl } from '../config';
import { LocalFormatDate, YearMonthDateFormatDate, YearOptions } from '../config/functions';
import { DegreeType } from '../redux/actions/degreeAction';
import { DisciplineType } from '../redux/actions/disciplineAction';
import { ExamType } from '../redux/actions/examAction';
import { HallType, fetchHall } from '../redux/actions/hallAction';
import { StoreState } from '../redux/reducers';
import { AppDispatch } from '../redux/store';

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { CSVLink } from 'react-csv';
import * as Feather from 'react-feather';
import imgPath from './img/collegeLogo.png';


const HallPlan = () => {

    const dispatch = useDispatch<AppDispatch>()

    const [hallPlanList, setHallPlanList] = useState<HallPlan[]>([]);



    const [editFlag, setEditFlag] = useState(false);
    const [examId, setExamId] = useState('');
    const [date, setDate] = useState<Date | null>(null);
    const [inputDate, setInputDate] = useState<Date | null>(null);
    const [session, setSession] = useState('');
    const [hallId, setHallId] = useState('');
    const [disciplineId, setdDisciplineId] = useState('');
    const [degreeId, setDegreeId] = useState('');
    const [batch, setBatch] = useState<string>('');
    const [batchError, setBatchError] = useState<string>('');
    const [semester, setSemester] = useState<number>(1)
    const [startRegisterNo, setStartRegisterNo] = useState('');
    const [endRegisterNo, setEndRegisterNo] = useState('');
    const [count, setCount] = useState('');
    const [id, setId] = useState<number>(0);
    const [createdBy, setCreatedBy] = useState<string>('');
    const [createTimestamp, setCreateTimestamp] = useState<string>('');
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [examIdError, setExamIdError] = useState('');
    const [dateError, setDateError] = useState('');
    const [sessionError, setSessionError] = useState('');
    const [hallIdError, setHallIdError] = useState('');
    const [disciplineIdError, setDisciplineIdError] = useState('');
    const [degreeIdError, setDegreeIdError] = useState('');
    const [startRegisterNoError, setStartRegisterNoError] = useState('');
    const [endRegisterNoError, setEndRegisterNoError] = useState('');
    const [countError, setCountError] = useState('');
    const [apiErrorMessage, setApiErrorMessage] = useState('');

    const examList = useSelector<StoreState, ExamType[]>(state => state.exam)
    const degreeList = useSelector<StoreState, DegreeType[]>(state => state.degree)
    const disciplineList = useSelector<StoreState, DisciplineType[]>(state => state.discipline)
    const hallList = useSelector<StoreState, HallType[]>(state => state.hall)

    // table filter view
    const [selectExam, setSelectExam] = useState<string>('');
    const [selectDegree, setSelectDegree] = useState<string>('');
    const [selectHall, setSelectHall] = useState<string>('');
    const [selectDiscipline, setSelectDiscipline] = useState<string>(''); // Default to empty
    const [selectSemester, setSelectSemester] = useState<string>('')
    const [selectBatch, setSelectBatch] = useState<string>('')
    const [selectDate, setSelectDate] = useState<string>('')
    const [selectSession, setSelectSession] = useState<string>('')


    const [selectedStudents, setSelectedStudents] = useState<string[]>([]);
    const [selectedSeats, setSelectedSeats] = useState<string[]>([]);
    const [studentList, setStudentList] = useState<string[]>([])

    // const assignedStudents = ["2101","2102","2103","2104","2105","2106","2107"];
    const [assignedStudents, setAssignedStudents] = useState<string[]>([])
    //selected hall filled values
    const [hallFilledBy, setHallFilledBy] = useState<string[]>([]);
    const [filledCount, setFilledCount] = useState<number>(0);
    const [seqNo, setSeqNo] = useState<number>(0)
    const yearList = YearOptions();


    const [hallCapacity, setHallCapacity] = useState<number>(0);
    const [seatPlan, setSeatPlan] = useState<string[]>([]);

    useEffect(() => {
        dispatch(fetchHall())
    }, [])


    const onRegisterSelectHandler = (registerNumber: string) => {
        console.log(selectedSeats);
        console.log(selectedSeats.length);
        console.log(filledCount);
        console.log(hallCapacity);
        if (hallId !== '') {

            if ((selectedSeats.length + filledCount) >= hallCapacity) {
                toast.error('Hall filled already!', { position: 'top-right' });
            }
            else if (selectedStudents.indexOf(registerNumber) >= 0) {
                // toast.error('The student selected already!', { position: 'top-right' });
                const removeStudents = selectedStudents.filter(ss => ss !== registerNumber)
                const removeSeats = selectedSeats.filter(ss => ss !== registerNumber)
                setSelectedStudents(removeStudents);
                setSelectedSeats(removeSeats);
            }
            else {
                setSelectedStudents([...selectedStudents, registerNumber]);
                setSelectedSeats([...selectedSeats, registerNumber]);
            }
        } else toast.error('Select Hall', { position: 'top-right' });

    }
    console.log(seatPlan);
    // console.log(selectedStudents);
    // console.log(hallFilledBy);

    // useEffect(() => {
    //     axios.get(`${apiUrl}/exam/`)
    //         .then((response) => {
    //             setSelectedExam(response.data.data);
    //         })
    //         .catch((error) => {
    //             console.error('Error fetching exam id', error);
    //         });
    // }, []);

    // useEffect(() => {
    //     axios.get(`${apiUrl}/hall/`)
    //         .then((response) => {
    //             setSelectedHall(response.data.data);
    //         })
    //         .catch((error) => {
    //             console.error('Error fetching hall', error);
    //         });
    // }, []);

    // useEffect(() => {
    //     axios.get(`${apiUrl}/discipline/`)
    //         .then((response) => {
    //             setSelectedDiscipline(response.data.data);
    //         })
    //         .catch((error) => {
    //             console.error('Error fetching discipline course', error);
    //         });
    // }, [])

    // useEffect(() => {
    //     axios.get(`${apiUrl}/degree/`)
    //         .then((response) => {
    //             setSelectedDegree(response.data.data);
    //         })
    //         .catch((error) => {
    //             console.error('Error fetching discipline course', error);
    //         });
    // }, [])



    // useEffect(() => {
    //     fetchData();
    // }, []);

    useEffect(() => {
        if (disciplineId !== "" && degreeId !== "" && semester.toString() !== "")
            axios.get(`${apiUrl}/student/?degreeId=${degreeId}&disciplineId=${disciplineId}&semester=${semester}`)
                .then((response) => {
                    setStudentList(response.data.data);
                })
                .catch((error) => console.log(error));
    }, [disciplineId, degreeId, semester])

    useEffect(() => {
        if (examId !== "" && session !== "" && inputDate != null && disciplineId !== "" && degreeId !== "" && semester.toString() !== "")
            axios.get(`${apiUrl}/hallSeatingPlan/?degreeId=${degreeId}&disciplineId=${disciplineId}&semester=${semester}&date=${YearMonthDateFormatDate(inputDate.toString())}&session=${session}&examId=${examId}`)
                .then((response) => {
                    if (response.data.data && response.data.data.length > 0) {
                        let studentFilled = [];
                        for (let hs of response.data.data) {
                            studentFilled.push(...hs.registerList.split(','))
                        }
                        setAssignedStudents(studentFilled)
                    }
                    else {
                        setAssignedStudents([])
                    }
                })
                .catch((error) => console.log(error));
    }, [examId, session, inputDate, disciplineId, degreeId, semester])

    useEffect(() => {
        if (examId !== "" && session !== "" && inputDate != null && hallId !== "")
            axios.get(`${apiUrl}/hallSeatingPlan/?hallId=${hallId}&date=${YearMonthDateFormatDate(inputDate.toString())}&session=${session}&examId=${examId}`)
                .then((response) => {
                    console.log(response.data.data);

                    if (response.data.data && response.data.data.length > 0) {
                        setSeqNo(Number(response.data.data[response.data.data.length - 1].seqNo))
                        let count = 0;
                        let hallFilled = [];
                        for (let hs of response.data.data) {
                            count = count + hs.count;
                            hallFilled.push(...hs.studentList.split(','))
                        }
                        setHallFilledBy(hallFilled)
                        setFilledCount(count)
                    }
                    else {
                        setSeqNo(0);
                        setHallFilledBy([])
                        setFilledCount(0)
                        setSelectedSeats([])
                    }
                })
                .catch((error) => console.log(error));
    }, [examId, session, inputDate, hallId])

    useEffect(() => {
        if (hallId !== '') {
            axios.get(`${apiUrl}/hall/?id=${hallId}`)
                .then((response) => {
                    setHallCapacity(response.data.data.seatingCapacity)
                })
                .catch((error) => console.log(error));
        }
    }, [hallId])

    // to prevent multiple courses combine in 1 entry
    useEffect(() => {
        setSelectedStudents([])
    }, [degreeId, disciplineId])

    useEffect(() => {
        const seats = []
        for (let i = 0; i < hallCapacity; i++) {
            seats.push("")
        }
        setSeatPlan(seats)
    }, [hallCapacity])

    const fetchData = () => {
        axios.get<{ data: HallPlan[] }>(`${apiUrl}/hallSeatingPlan/`)
            .then((response) => {
                setHallPlanList(response.data.data);
            })
            .catch((error) => console.log(error));
    }

    useEffect(() => {
        handleSearch()
    }, [])

    const handleSearch = () => {
        if (selectExam !== '' || selectDegree !== '' || selectDiscipline !== '' || selectHall !== '' || selectSemester !== '' || selectBatch !== '' || selectDate !== '' || selectSession !== '') {
            let examId = selectExam !== '' ? selectExam : null
            let degreeId = selectDegree !== '' ? selectDegree : null
            let disciplineId = selectDiscipline !== '' ? selectDiscipline : null
            let hallId = selectHall !== '' ? selectHall : null
            let sem = selectSemester !== '' ? selectSemester : null
            let batch = selectBatch !== '' ? selectBatch : null
            let date = selectDate !== '' ? selectDate : null
            let sess = selectSession !== '' ? selectSession : null

            console.log(batch);
            console.log(sem);
            console.log(sess);

            axios.get<{ data: HallPlan[] }>(`${apiUrl}/hallSeatingPlan/?exam=${examId}&degree=${degreeId}&discipline=${disciplineId}&hall=${hallId}&semester=${sem}&batch=${batch}&date=${date}&session=${sess}`)
                .then((response) => {
                    // console.log(response);
                    setHallPlanList(response.data.data);
                })
                .catch((error) => console.log(error));
        }
        else {
            axios.get<{ data: HallPlan[] }>(`${apiUrl}/hallSeatingPlan/`)
                .then((response) => {
                    setHallPlanList(response.data.data);
                })
                .catch((error) => console.log(error));
        }
    }



    const resetData = () => {
        setSelectExam('')
        setSelectDegree('')
        setSelectDiscipline('')
        setSelectHall('')
        setSelectSemester('')
        setSelectBatch('')
        setSelectDate('')
        setSelectSession('')
        handleSearch();
    }


    const addHandler = () => {
        let error = false

        if (inputDate === null) {
            setDateError('Date is required');
            error = true;
        }
        if (batch === '') {
            setBatchError('Batch required');
            error = true;
        }
        if (session.length === 0) {
            setSessionError('Session is required');
            error = true;
        }
        if (examId.length === 0) {
            setExamIdError('Exam ID is required');
            error = true;
        }

        if (hallId.length === 0) {
            setHallIdError('Total Courses are required');
            error = true;
        }

        if (disciplineId.length === 0) {
            setDisciplineIdError('disciplineId is required');
            error = true;
        }
        if (degreeId.length === 0) {
            setDegreeIdError('degreeId is required');
            error = true;
        }

        // if (startRegisterNo.length === 0) {
        //     setStartRegisterNoError('Start Register No is required');
        //     return;
        // }

        // if (endRegisterNo.length === 0) {
        //     setEndRegisterNoError('End Register No are required');
        //     return;
        // }

        // if (count.length === 0) {
        //   setCountError('Count is required');
        //   return;
        // }

        if (session.length > 5) {
            setSessionError('Session must be at least 5 characters');
            error = true;
        }

        // if (startRegisterNo.length > 15){
        //     setStartRegisterNoError('Start Register No must be at least 15 characters');
        //     return;
        // }

        if (selectedSeats.length === 0) {
            error = true;
        }

        //   if (count.length > 11){
        //     setCountError('Count Id must be at least 11 characters');
        //     return;
        // }

        const date = inputDate ? YearMonthDateFormatDate(inputDate?.toString()) : '';

        const data = {
            examId: examId,
            date,
            session,
            hallId: hallId,
            disciplineId: disciplineId,
            degreeId: degreeId,
            semester,
            seqNo: seqNo + 1,
            registerNoList: selectedSeats,
            count: selectedSeats.length,
            createdBy,
            createTimestamp,
            batch: batch
        };

        if (!error) {
            axios.post(`${apiUrl}/hallSeatingPlan/`, data)
                .then((response) => {
                    console.log(response);
                    // if (response.data.error) {
                    //     toast.error('Failed to add hall plan', { position: 'top-right' });
                    //     setApiErrorMessage(response.data.message);
                    // } else {
                    toast.success(response.data.message, { position: 'top-right' });
                    setExamIdError('');
                    setDateError('');
                    setSessionError('');
                    setHallIdError('');
                    setDisciplineIdError('');
                    setDegreeIdError('');
                    setStartRegisterNoError('');
                    setEndRegisterNoError('');
                    setCountError('');
                    setApiErrorMessage('');
                    setBatch('')
                    // fetchData();
                    clearFormHandler();
                    // }
                })
                .catch((error) => {
                    toast.error(error.response.data.message, { position: 'top-right' });
                    console.log(error)
                });
        }
    }

    const deleteHandler = (id: number) => {
        setId(id);
        openDeleteConfirmation();
    }

    const confirmDeleteHandler = () => {
        axios
            .delete(`${apiUrl}/hallSeatingPlan/?id=${id}`)
            .then((response) => {
                toast.success(response.data.message, { position: 'top-right' });
                fetchData();
                closeDeleteConfirmation();
            })
            .catch((error) => {
                console.log(error);
                toast.error(error.response.data.message, { position: 'top-right' });
                // toast.error('Error deleting hall plan', { position: 'top-right' });
            });
    }

    const editHandler = (id: number, examId: string, date: Date, session: string, hallId: string, disciplineId: string, degreeId: string, startRegisterNo: string, endRegisterNo: string, count: string, createdBy: string, createTimestamp: string, batch: string) => {
        setEditFlag(true);
        setId(id);
        setExamId(examId);
        setDate(date);
        setSession(session);
        setSession(batch);
        setHallId(hallId);
        setdDisciplineId(disciplineId);
        setDegreeId(degreeId);
        setStartRegisterNo(startRegisterNo);
        setEndRegisterNo(endRegisterNo);
        setCount(count);
        setCreatedBy(createdBy);
        setCreateTimestamp(createTimestamp);
    }

    const updateHandler = () => {
        if (inputDate === null) {
            setDateError('Date required');
            return;
        }
        if (session.length === 0) {
            setSessionError('Session required');
            return;
        }
        if (examId.length === 0) {
            setExamIdError('Exam required');
            return;
        }
        if (batch === '') {
            setBatchError('Batch required');
            return;
        }
        if (hallId.length === 0) {
            setHallIdError('Total Courses are required');
            return;
        }

        if (disciplineId.length === 0) {
            setDisciplineIdError('Discipline required');
            return;
        }
        if (degreeId.length === 0) {
            setDegreeIdError('Degree required');
            return;
        }

        if (startRegisterNo.length === 0) {
            setStartRegisterNoError('Start Register No required');
            return;
        }

        if (endRegisterNo.length === 0) {
            setEndRegisterNoError('End Register No are required');
            return;
        }

        if (count.length === 0) {
            setCountError('Count is required');
            return;
        }

        if (session.length > 5) {
            setSessionError('Session must be at least 5 characters');
            return;
        }

        if (startRegisterNo.length > 15) {
            setStartRegisterNoError('Start Register No must be at least 15 characters');
            return;
        }

        if (endRegisterNo.length > 15) {
            setEndRegisterNoError('End Register No must be at least 15 characters');
            return;
        }

        if (count.length > 11) {
            setCountError('Count Id must be at least 11 characters');
            return;
        }

        const date = YearMonthDateFormatDate(inputDate.toString());

        const data = {
            id, examId: examId,
            date,
            session,
            batch,
            hallId: hallId,
            disciplineId: disciplineId,
            degreeId: degreeId,
            startRegisterNo,
            endRegisterNo,
            count,
            createdBy,
            createTimestamp
        };
        axios.put(`${apiUrl}/hallSeatingPlan/?id=${id}`, data)
            .then((response) => {
                // if (response.data.error) {
                //     toast.error('Failed to update hall plan', { position: 'top-right' });
                //     setApiErrorMessage(response.data.message);
                // } else {
                toast.success(response.data.message, { position: 'top-right' });
                setExamIdError('');
                setDateError('');
                setSessionError('');
                setHallIdError('');
                setDisciplineIdError('');
                setDegreeIdError('');
                setStartRegisterNoError('');
                setEndRegisterNoError('');
                setCountError('');
                setApiErrorMessage('');
                // fetchData();
                clearFormHandler();
                // }
            })
            .catch((error) => {
                toast.error(error.response.data.message, { position: 'top-right' });
                console.log(error)
            });
    }

    const clearFormHandler = () => {
        setExamId('');
        setInputDate(null);
        setSession('');
        setHallId('');
        setdDisciplineId('');
        setDegreeId('');
        setStartRegisterNo('');
        setEndRegisterNo('');
        setCount('');
        setId(0);
        setCreatedBy('');
        setCreateTimestamp('');
        setDateError('');
        setSessionError('');
        setExamIdError('');
        setEditFlag(false);
        setSelectedStudents([]);
        setSelectedSeats([]);
        setHallFilledBy([]);
        setFilledCount(0);
        setSeqNo(0);
        setHallCapacity(0);
        setSeatPlan([]);
        setStudentList([]);
    }

    const clearHandler = () => {
        setSelectedStudents([]);
        setSelectedSeats([]);
    }

    const openDeleteConfirmation = () => {
        setShowDeleteConfirmation(true);
    }

    const closeDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    }

    const getExamId = (HallPlan: HallPlan) => {
        const examId = examList.find((exam) => exam.id === parseInt(HallPlan.examId));
        if (examId) {
            return examId.title;
        }
        return 'N/A';
    }

    const getHallId = (HallPlan: HallPlan) => {
        const hallId = hallList.find((hall) => hall.id === parseInt(HallPlan.hallId));
        if (hallId) {
            return hallId.name;
        }
        return 'N/A';
    }

    const getDisciplineId = (HallPlan: HallPlan) => {
        const discipline = disciplineList.find((disc) => disc.id === parseInt(HallPlan.disciplineId));
        if (discipline) {
            return discipline.name;
        }
        return 'N/A';
    };

    const getDegreeId = (HallPlan: HallPlan) => {
        const degree = degreeList.find((disc) => disc.id === parseInt(HallPlan.degreeId));
        if (degree) {
            return degree.name;
        }
        return 'N/A';
    };


    const handleDownloadPDF = () => {
        const doc = new jsPDF('p', 'mm', 'a4');
        doc.addImage(imgPath, 'PNG', 25, 10, 170, 40);

        doc.setFontSize(16);
        doc.setFont('Arial', 'normal', 'bold');
        doc.setTextColor(0, 0, 0);
        doc.text('HALL SEATING PLAN', 100, 55, { align: 'center' });

        autoTable(doc, {
            startY: 60,
            margin: 3,
            headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0] },
            bodyStyles: { textColor: [0, 0, 0], lineColor: [0, 0, 0] },
            head: [
                [
                    { content: 'S.No.', styles: { halign: 'center', cellWidth: 10 } },
                    { content: 'Exam', styles: { halign: 'center', cellWidth: 23 } },
                    { content: 'Date', styles: { halign: 'center', cellWidth: 22 } },
                    { content: 'Session', styles: { halign: 'center', cellWidth: 17 } },
                    { content: 'Hall', styles: { halign: 'center', cellWidth: 15 } },
                    { content: 'Degree', styles: { halign: 'center', cellWidth: 17 } },
                    { content: 'Discipline', styles: { halign: 'center', cellWidth: 30 } },
                    { content: 'Semester', styles: { halign: 'center', cellWidth: 19 } },
                    { content: 'Batch', styles: { halign: 'center', cellWidth: 13 } },
                    { content: 'Register List', styles: { halign: 'center', cellWidth: 37 } }
                ],
            ],
            body: hallPlanList.map((hp, index) => [
                { content: index + 1, styles: { halign: 'center' } },
                { content: hp.examId, styles: { halign: 'center' } },
                { content: hp.date, styles: { halign: 'center' } },
                { content: hp.session, styles: { halign: 'center' } },
                { content: hp.hallId, styles: { halign: 'center' } },
                { content: hp.degreeId, styles: { halign: 'center' } },
                { content: hp.disciplineId, styles: { halign: 'center' } },
                { content: hp.semester, styles: { halign: 'center' } },
                { content: hp.batch, styles: { halign: 'center' } },
                { content: hp.registerList, styles: { halign: 'center' } }
            ]),
            theme: 'grid',
            // columnStyles: {
            //     0: { cellWidth: 8 },
            //     1: { cellWidth: 20 },
            //     2: { cellWidth: 15 },
            //     3: { cellWidth: 16 },
            //     4: { cellWidth: 20 },
            //     5: { cellWidth: 17 },
            //     6: { cellWidth: 25 },
            //     7: { cellWidth: 35 },
            //     8: { cellWidth: 16 },
            //     9: { cellWidth: 16 },
            //     // 10: { cellWidth: 40 }
            // },
        });
        doc.save('hall_plan' + '.pdf');
    }

    const csvData = [
        [
            'S.No.',
            'Exam',
            'Date',
            'Session',
            'Hall',
            'Degree',
            'Discipline',
            'Semester',
            'Batch',
            'Register List'
        ],
        ...hallPlanList.map((hl, i) => [
            i + 1,
            hl.examId,
            hl.date,
            hl.session,
            hl.hallId,
            hl.degreeId,
            hl.disciplineId,
            hl.semester,
            hl.batch,
            hl.registerList
        ])
    ]





    return (
        <PageContainer title="Hall Seating Plan">
            <Card title="Add Hall Seating Plan">
                {/* <div className='container m-0'> */}
                <div className="row">
                    <div className="col-sm-6 col-md-4 col-lg-4">
                        <label className="my-2">Exam <span className="text-danger">*</span> : </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px", }}
                            name="examId"
                            value={examId}
                            onChange={(e) => setExamId(e.target.value)}
                        >
                            <option value="" selected>Select Exam</option>
                            {examList.map((course) => (
                                <option key={course.id} value={course.id}>
                                    {course.title}
                                </option>
                            ))}
                        </select>
                        {examIdError && <div className="text-danger">{examIdError}</div>} </div>

                    <div className="col-sm-6 col-md-2 col-lg-2">
                        <label className="my-2">Date <span className="text-danger">*</span> : </label><br />
                        <DatePicker
                            className="form-control"
                            selected={inputDate}
                            onChange={(newDate: Date | null) => setInputDate(newDate)}

                            dateFormat='dd-MM-yyyy'

                            // dateFormat='dd-mm-yyyy'
                            placeholderText="Select a date"
                        />
                        {dateError && <div className="text-danger">{dateError}</div>}<br /></div>

                    <div className="col-sm-6 col-md-2 col-lg-2">
                        <label className="my-2">Session <span className="text-danger">*</span> : </label>
                        <select className="form-control" name="session" style={{ width: "100%", marginRight: "20px" }} onChange={(e) => setSession(e.target.value)} value={session}>
                            <option value="">Select</option> {/* Default option */}
                            <option value="AN">AN</option>
                            <option value="FN">FN</option>
                        </select>
                        {sessionError && <div className="text-danger">{sessionError}</div>}</div>


                    <div className="col-sm-6 col-md-4 col-lg-4">
                        <label className="my-2">Degree <span className="text-danger">*</span> : </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px" }}
                            name="degreeId"
                            value={degreeId}
                            onChange={(e) => setDegreeId(e.target.value)}
                        >
                            <option value="" selected>Select Degree</option>
                            {degreeList.map((degree) => (
                                <option key={degree.id} value={degree.id}>
                                    {degree.name}
                                </option>
                            ))}
                        </select>
                        {degreeIdError && <div className="text-danger">{degreeIdError}</div>}
                    </div>

                    <div className="col-sm-6 col-md-4 col-lg-4">
                        <label className="my-2">Discipline <span className="text-danger">*</span> : </label><br />
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px", }}
                            name="disciplineId"
                            value={disciplineId}
                            onChange={(e) => setdDisciplineId(e.target.value)}
                        >
                            <option value="" selected>Select Discipline</option>
                            {disciplineList.map((course) => (
                                <option key={course.id} value={course.id}>
                                    {course.name}
                                </option>
                            ))}
                        </select>
                        {disciplineIdError && <div className="text-danger">{disciplineIdError}</div>}</div>

                    {/* <div className="col-4">
          <label className="my-2">Start Register No <span className="text-danger">*</span> : </label>
          <input
            className="form-control"
            placeholder="Enter Start Register No..."
            type="text"
            name="startRegisterNo"
            onChange={(e) => {
              setStartRegisterNo(e.target.value);
              setStartRegisterNoError('');
              setApiErrorMessage(''); 
            }}
            value={startRegisterNo}
          />
          {startRegisterNoError && <div className="text-danger">{startRegisterNoError}</div>}</div>

          <div className="col-4">
          <label className="my-2">End Register No <span className="text-danger">*</span> : </label>
          <input
            className="form-control"
            placeholder="Enter End Register No..."
            type="text"
            name="endRegisterNo"
            onChange={(e) => {
              setEndRegisterNo(e.target.value);
              setEndRegisterNoError('');
              setApiErrorMessage(''); 
            }}
            value={endRegisterNo}
          />
          {endRegisterNoError && <div className="text-danger">{endRegisterNoError}</div>}</div>

          <div className="col-4">
          <label className="my-2">Count <span className="text-danger">*</span> : </label>
          <input
            className="form-control"
            placeholder="Enter Count..."
            type="text"
            name="count"
            onChange={(e) => {
              setCount(e.target.value);
              setCountError('');
              setApiErrorMessage(''); 
            }}
            value={count}
          />
          {countError && <div className="text-danger">{countError}</div>}</div> */}
                    <div className="col-sm-6 col-md-4 col-lg-4">
                        <label className="my-2">Hall <span className="text-danger">*</span> : </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px", }}
                            name="hallId"
                            value={hallId}
                            onChange={(e) => setHallId(e.target.value)}
                        >
                            <option value="" selected>Select Hall</option>
                            {hallList.map((hl) => (
                                <option key={hl.id} value={hl.id}>
                                    {hl.name}
                                </option>
                            ))}
                        </select>
                        {hallIdError && <div className="text-danger">{hallIdError}</div>}
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-4">
                        <label className="my-2">Semester <span className="text-danger">*</span> : </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px", }}
                            name="semester"
                            value={semester}
                            onChange={(e: any) => setSemester(e.target.value)}
                        >
                            <option value={0} selected={semester === 0 ? true : false}>Select Sem</option>
                            <option value={1} selected={semester === 1 ? true : false}>1</option>
                            <option value={2} selected={semester === 2 ? true : false}>2</option>
                            <option value={3} selected={semester === 3 ? true : false}>3</option>
                            <option value={4} selected={semester === 4 ? true : false}>4</option>
                            <option value={5} selected={semester === 5 ? true : false}>5</option>
                            <option value={6} selected={semester === 6 ? true : false}>6</option>
                            <option value={7} selected={semester === 7 ? true : false}>7</option>
                            <option value={8} selected={semester === 8 ? true : false}>8</option>
                        </select>
                        {hallIdError && <div className="text-danger">{hallIdError}</div>}
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-4">
                        <FormInput
                            name="Batch"
                            label="Batch"
                            labelClassName="required"
                            value={batch}
                            onChange={(e) => setBatch(e.target.value)}
                            placeholder="Select Batch"
                            containerClass="mt-1"
                            type="select"
                        >
                            <option value="">Select Batch</option>
                            {yearList.map((y, i) => {
                                return <Option key={i} value={y}>{y}</Option>
                            })
                            }
                        </FormInput>
                        {batchError && <div className="text-danger">{batchError}</div>}

                    </div>
                </div>

                <div className="row m-2">
                    <div className="col-8">
                        <label className="mt-3 mb-3">{studentList.length > 0 ? 'Student:' : ''}</label>
                        <div className="row m-2">
                            {studentList.map((stud, index) => {
                                return (
                                    <div className="col-2 p-1"  >
                                        {assignedStudents.indexOf(stud) >= 0 ?
                                            <div className={`border text-center p-1 bg-red`}> {stud}</div>
                                            :
                                            <div style={{ cursor: "pointer" }} className={`border text-center p-1 ${selectedStudents.indexOf(stud) >= 0 ? 'bg-green' : ''}`} onClick={() => onRegisterSelectHandler(stud)}> {stud}</div>
                                        }
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                    <div className="col-4">
                        <label className="mt-3 mb-3">{seatPlan.length > 0 ? 'Seating:' : ''}</label>
                        <div className="row m-2">
                            {seatPlan?.map((seat, index) => {
                                return (
                                    <div className="col-4 p-1">
                                        {hallFilledBy[index] && index < filledCount
                                            ?
                                            <div className={`border text-center p-1 bg-red`}> {hallFilledBy[index]}</div>
                                            :
                                            (
                                                index >= filledCount && selectedStudents[index - (filledCount)] ?
                                                    <div className={`border text-center p-1 bg-green`}>{selectedStudents[index - (filledCount)]}</div> :
                                                    <div className={`border text-center p-3`}> {" "}</div>
                                            )
                                        }
                                    </div>
                                )
                            })
                            }
                        </div>

                    </div>
                </div>

                <div className="m-3 d-flex justify-content-end">
                    <button className="btn btn-sm btn-secondary" onClick={clearHandler} style={{ marginRight: '10px' }}>Clear</button>
                    {editFlag
                        ? <button className="btn btn-sm btn-success" onClick={updateHandler}>Update</button>
                        : <button className="btn btn-sm btn-success" onClick={addHandler} disabled={selectedSeats.length > 0 ? false : true}>Add</button>
                    }
                </div>
                {/* </div> */}
            </Card>
            <CollapseCard title="Hall Seating Plan">
                <div className="row m-1">
                    <div className="col-3">
                        <label className="mt-2 mb-2">Exam : </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px", }}
                            name="selectExam"
                            value={selectExam}
                            onChange={(e) => setSelectExam(e.target.value)}
                        >
                            <option value="" selected>Select Exam</option>
                            {examList.map((el) => (
                                <option key={el.id} value={el.id}>
                                    {el.title}
                                </option>
                            ))}
                        </select>
                        {/* {examIdError && <div className="text-danger">{examIdError}</div>} */}
                    </div>
                    <div className="col-3">
                        <label className="mt-2 mb-2">Degree : </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px", }}
                            name="selectDegree"
                            value={selectDegree}
                            onChange={(e) => setSelectDegree(e.target.value)}
                        >
                            <option value="" selected>Select Degree</option>
                            {degreeList.map((dl) => (
                                <option key={dl.id} value={dl.id}>
                                    {dl.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-3">
                        <label className="mt-2 mb-2">Discipline : </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px", }}
                            name="selectDiscipline"
                            value={selectDiscipline}
                            onChange={(e) => setSelectDiscipline(e.target.value)}
                        >
                            <option value="" selected>Select Discipline</option>
                            {disciplineList.map((dl) => (
                                <option key={dl.id} value={dl.id}>
                                    {dl.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-3">
                        <label className="mt-2 mb-2">Hall : </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px", }}
                            name="selectHall"
                            value={selectHall}
                            onChange={(e) => setSelectHall(e.target.value)}
                        >
                            <option value="" selected>Select Hall</option>
                            {hallList.map((hl) => (
                                <option key={hl.id} value={hl.id}>
                                    {hl.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-3">
                        <label className="mt-2 mb-2">Semester : </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px", }}
                            name="selectSemester"
                            value={selectSemester}
                            onChange={(e) => setSelectSemester(e.target.value)}
                        >
                            <option value={''}>Select Semester</option>
                            <option value={1} >1</option>
                            <option value={2} >2</option>
                            <option value={3} >3</option>
                            <option value={4} >4</option>
                            <option value={5} >5</option>
                            <option value={6} >6</option>
                            <option value={7} >7</option>
                            <option value={8} >8</option>
                        </select>
                    </div>
                    <div className="col-3">
                        <FormInput
                            name="select Batch"
                            label="Batch"
                            value={selectBatch}
                            className='form-control'
                            onChange={(e) => setSelectBatch(e.target.value)}
                            type="select"
                            labelClassName='mt-2 mb-2'
                        >
                            <option value="">Select Batch</option>
                            {yearList.map((y, i) => {
                                return <Option key={i} value={y}>{y}</Option>
                            })
                            }
                        </FormInput>
                    </div>
                    <div className="col-3">
                        <label className="mt-2 mb-2">Date : </label><br />
                        <input type="date"
                            className='form-control'
                            value={selectDate}
                            onChange={(e) => setSelectDate(e.target.value)} />
                        {/* <DatePicker
                            className="form-control"
                            selected={selectDate}
                            onChange={(newDate: Date | null) => setSelectDate(newDate)}
                            dateFormat='dd-MM-yyyy'
                            // dateFormat='dd-mm-yyyy'
                            placeholderText="Select a date"
                        /> */}
                    </div>
                    <div className="col-3">
                        <label className="mt-2 mb-2">Session : </label>
                        <select className="form-control" name="session" style={{ width: "100%", marginRight: "20px" }} onChange={(e) => setSelectSession(e.target.value)} value={selectSession}>
                            <option value="">Select</option> {/* Default option */}
                            <option value="AN">AN</option>
                            <option value="FN">FN</option>
                        </select>
                    </div>

                    {/* <div className="text-end"> */}
                    {/* <div className="col-lg-12 col-sm-12"> */}
                    {/* <div className=" col-12 text-end mt-5 mb-3"> */}
                    {/* <button className='btn btn-sm btn-primary' onClick={handleSearch}>Search</button> */}
                    {/* </div> */}
                    {/* </div> */}
                    {/* </div> */}

                    <div className="m-1  ml-4 d-flex justify-content-end">
                        <button
                            type="button"
                            className="btn btn-sm btn-primary mt-3 mb-1 mr-1"
                            // style={{ marginRight: '10px' }}
                            onClick={handleSearch}
                        >
                            Search
                        </button>

                        <button
                            type="button"
                            className="btn btn-sm btn-secondary mt-3 mb-1"
                            onClick={resetData}
                        >Reset
                        </button>
                    </div>

                    {/* <div className="col-3">
                        <button className='btn btn-sm btn-primary mt-3 mb-3' onClick={handleSearch}>Search</button>
                    </div> */}
                </div>
                
                        <div style={{margin: '0% 5% 0% 0%', float: 'right'}}>
                            {hallPlanList.length > 0 && <div className="text-end mb-3">
                                <button
                                    type="button"
                                    onClick={handleDownloadPDF}
                                    className="m-1 btn btn-sm btn-primary"
                                >
                                    <Feather.Download /> PDF
                                </button>
                                <CSVLink
                                    data={csvData}
                                    filename="details_of_course.csv"
                                    className="m-1 btn btn-sm btn-primary"
                                >
                                    <Feather.Download /> CSV
                                </CSVLink>
                            </div>
                            }
                        </div>
                    
                <div className="container-fluid mt-3" style={{ width: "150%" }}>
                    <table className="table table-success table-striped">
                        <thead>
                            <tr>
                                <th>S.No.</th>
                                <th>Exam</th>
                                <th>Date</th>
                                <th>Session</th>
                                <th>Hall </th>
                                <th>Degree </th>
                                <th>Discipline</th>
                                <th>Semester</th>
                                <th>Batch</th>
                                <th className=''>Register Lists</th>
                                <th>Count</th>
                                {/* <th>Created By</th> */}
                                {/* <th>Create Timestamp</th> */}
                                <th>Options</th>
                            </tr>
                        </thead>
                        <tbody>
                            {hallPlanList?.map((HallPlan, index) => (
                                <tr key={HallPlan.id}>
                                    <td>{index + 1}</td>
                                    <td>{HallPlan.examId}</td>
                                    <td>{LocalFormatDate(HallPlan.date) ? LocalFormatDate(HallPlan.date) : 'N/A'}</td>
                                    <td>{HallPlan.session}</td>
                                    <td>{HallPlan.hallId}</td>
                                    <td>{HallPlan.degreeId}</td>
                                    <td>{HallPlan.disciplineId}</td>
                                    <td>{HallPlan.semester}</td>
                                    <td>{HallPlan.batch}</td>
                                    <td>{HallPlan.registerList}</td>
                                    <td>{HallPlan.count}</td>
                                    {/* <td>{HallPlan.createdBy}</td> */}
                                    {/* <td>{HallPlan.createTimestamp}</td> */}
                                    <td>
                                        {/* <button className="btn btn-primary" style={{ marginRight: '10px' }} onClick={() => editHandler(HallPlan.id, getExamId(HallPlan), HallPlan.date, HallPlan.session, getHallId(HallPlan), getDisciplineId(HallPlan), getDegreeId(HallPlan), HallPlan.startRegisterNo, HallPlan.endRegisterNo, HallPlan.count, HallPlan.createdBy, HallPlan.createTimestamp)}>Edit</button> */}
                                        <button className="btn btn-sm btn-danger" onClick={() => deleteHandler(HallPlan.id)}>Delete</button>
                                    </td>
                                </tr>

                            ))}
                        </tbody>
                    </table>
                    
                </div>
            </CollapseCard>

            <Modal show={showDeleteConfirmation} onHide={closeDeleteConfirmation}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this item?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeDeleteConfirmation}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={confirmDeleteHandler}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Add the ToastContainer for displaying toasts */}
            <ToastContainer position="top-right" />
        </PageContainer>
    );
}

export default HallPlan;

interface HallPlan {
    id: number;
    examId: string;
    date: string;
    session: string;
    hallId: string;
    disciplineId: string;
    degreeId: string;
    startRegisterNo: string;
    endRegisterNo: string;
    registerList: string
    seqNo: number
    semester: number
    batch: string;
    count: string;
    createdBy: string;
    createTimestamp: string;
}


interface Exam {
    id: number;
    title: string;
}

interface Hall {
    id: number;
    name: string;
}

interface Discipline {
    id: number;
    name: string;
}

interface Degree {
    id: number;
    name: string;
}