
import { RegulationActionList, RegulationType } from "../actions/regulationAction";
import { Action } from "../actions/types";

const initialValue: Array<RegulationType> = [];

const regulationReducer = (state: Array<RegulationType> = initialValue, action: Action) => {
    switch (action.type) {
        case RegulationActionList.ADD_REGULATION:
            return [
                ...state,
                action.data,
            ];

        case RegulationActionList.UPDATE_REGULATION:
            const updated = [...state].slice();
            const index = updated.findIndex(u => u.id === action.data.id);

            if (index > -1) {
                updated[index] = {
                    ...action.data,
                };
            }

            return updated;

        case RegulationActionList.FETCH_REGULATION:
            return action.data;

        case RegulationActionList.DELETE_REGULATION:
            return state.filter(s => s.id !== action.data);

        default:
            return state;
    }
}

export default regulationReducer;



